import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFiles } from "app/main/apps/admin/store/folderSlice";
import Typography from "@mui/material/Typography";
import FolderStructure from "app/main/apps/folder-view/FolderStructure";

export default function FolderStructureModelPopup(props) {
  const dispatch = useDispatch();
  const [showFolderModel, setShowFolderModel] = useState(false);
  useEffect(() => {
    dispatch(setFiles([]));
    setShowFolderModel(true);
  }, [dispatch]);
  return (
    <div>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {props.modelTitle}
      </Typography>
      {showFolderModel && (
        <FolderStructure
          type={props.type}
          id={props.id}
          filePathKey={props.modelScanPath}
          modelTitle={props.modelTitle}
        ></FolderStructure>
      )}
    </div>
  );
}

