
import DemoUserMapBoxImageViewer from "./demoUserMapBox/DemoUserMapBoxImageViewer";
import DemoUserImageViewer from "./demoUserPotree/DemoUserImageViewer";

const DemoUserScansAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'apps/demopanos/:scanId/:index',
      element: <DemoUserImageViewer />
    },
    {
      path: 'apps/demogpspanos/:scanId/:index',
      element: <DemoUserMapBoxImageViewer />
    },
  ],
};

export default DemoUserScansAppConfig;
