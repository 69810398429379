import { makeStyles } from "@mui/styles";

const ToolBarStyles = makeStyles({
    drawerPaper: {
        marginTop: "80px",
        height: 'calc(100vh - 80px)',
        color: '#000000',
        fontWeight: '500'
    }
});

export default ToolBarStyles;
