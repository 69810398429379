import { createSlice } from '@reduxjs/toolkit';

export const setLoaderProgress = (value) => async (dispatch) => {
    dispatch(setLoader(value));
}



const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        state: false,
    },
    reducers: {
        setLoader: (state, action) => {
            state.state = action.payload;
        }
    },
});

export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
