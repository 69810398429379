export const types = [
	{value: 'bug', label: 'Bug',},
	{value: 'task', label: 'Task',},
	{value: 'enhancement', label: 'Enhancement',}
];

export const priorities = [
	{value: 'immediate', label: 'Immediate',},
	{value: 'high', label: 'High',},
	{value: 'medium', label: 'Medium',},
	{value: 'low', label: 'Low',}
];

export const severities = [
	{value: 'minor', label: 'Minor'},
	{value: 'medium', label: 'Medium'},
	{value: 'major', label: 'Major'},
	{value: 'critical', label: 'Critical'}
];