import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const loadAllClassificationProfiles = () => {
  const url =
    APP_CONFIG.api + `v1/siteClassification/getAllClassificationProfiles`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getClassificationProfileInfos = (profileId) => {
  const url =
    APP_CONFIG.api +
    `v1/siteClassification/getClassificationProfileInfos/${profileId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const saveSiteClassification = (saveClassification) => {
  const url = APP_CONFIG.api + `v1/siteClassification/saveSiteClassification`;

  const response = axios.post(url, saveClassification, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateSiteClassification = (updateProfile) => {
  const url = APP_CONFIG.api + `v1/siteClassification/updateSiteClassification`;
  const response = axios.put(url, updateProfile, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const removeProfile = createAsyncThunk(
  "scan/scanApp/removeProfile",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/siteClassification/deleteSiteClassificationProfile/${requestData.profileId}`;

    const request = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(request)
      .then(() => {
        requestData.successCallback();
      })
      .catch(() => {});
  }
);

export const getUserDefaultClassificationSettings = () => {
  const url =
    APP_CONFIG.api +
    `v1/siteClassification/getUserDefaultClassificationSettings`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getDefaultClassificationSettings = () => {
  const url =
    APP_CONFIG.api + `v1/siteClassification/getDefaultClassificationSettings`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getOrganizationClassifications = (organizationId) => {
  const url =
    APP_CONFIG.api +
    `v1/siteClassification/getOrganizationClassifications/${organizationId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateApplicationClassification = (updateProfile) => {
  const url =
    APP_CONFIG.api + `v1/siteClassification/updateApplicationClassification`;
  const response = axios.put(url, updateProfile, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateOrganizationClassifications = (updateProfile) => {
  const url =
    APP_CONFIG.api + `v1/siteClassification/updateOrganizationClassifications`;
  const response = axios.put(url, updateProfile, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateOrganizationCustomClassifications = (
  organizationId,
  isEnabled
) => {
  const url =
    APP_CONFIG.api +
    `v1/organization/updateOrganizationCustomClassificationSettings/${organizationId}`;
  const response = axios.put(url, isEnabled, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
