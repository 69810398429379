import * as React from 'react';
import {
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UPLOAD_TYPE from '../upload/UploadType';


function FileDownloadErrorDailog(props) {

    const { onClose, fileName } = props;

    const getFileName = (name) => {

        if (name == UPLOAD_TYPE.scanoutputs) {
            return "scan"
        }
        if (name == UPLOAD_TYPE.scanpointclouds) {
            return "point cloud";
        }
        if (name == UPLOAD_TYPE.scanortho) {
            return "ortho"
        }

        if (name == UPLOAD_TYPE.track) {
            return "track"
        }
        if (name == UPLOAD_TYPE.scanpanoramas) {
            return "panoramic imagery"
        }

    }

    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <div style={{ width: '420px' }} className='flex flex-col justify-center items-center'>
                    <DialogTitle id="dialog-title" className='text-center' style={{
                        fontSize: 25,
                        fontWeight: 600,
                        letterSpacing: "-0.01em",
                        textAlign: "center",
                        color: "#A1A1A1",
                        paddingTop: '40px',
                        paddingBottom: '40px'
                    }}>
                        <CancelOutlinedIcon style={{ fontSize: "1.73em" }} ></CancelOutlinedIcon> Error
                    </DialogTitle>
                    <DialogContent className='text-center p-0' sx={{ width: 300 }}>

                        <DialogContentText id="alert-dialog-description" style={{ color: '#000000', fontSize: '15px', fontWeight: 400 }}>
                            <div className='flex flex-col' style={{ textAlign: 'left' }}>
                                <span> The {getFileName(fileName)} file is not available.</span>
                                <br></br>
                                <span> Please contact support@looq.ai if you need assistance.</span>
                            </div>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions className='flex items-center justify-center p-0'>
                        <Button style={{
                            width: "97px",
                            height: "41px",
                            borderRadius: "4px",
                            margin: "20px",
                            backgroundColor: "#58BEC9",
                            color: "#ffffff",
                            fontSize: '20px',
                            fontWeight: 400
                        }}
                            onClick={() => { onClose() }}
                        >OK</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )

}
export default FileDownloadErrorDailog;