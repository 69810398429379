export const DISABLE_MASKS_DENSE_PANO = "disable_masks_dense_pano";
export const POI_GEO_JSON = "poi_geo_json";
export const BASE_STATION_SCAN = "base_station_scan";

export const isFlagEnabled = (flagName, flagObj, orgId) => {
  try {
    orgId = typeof orgId === "string" ? parseInt(orgId) : orgId;
    const parsedValue = JSON.parse(flagObj.value);
    console.log(
      `${flagName}: parsedValue: ${JSON.stringify(
        parsedValue
      )}, orgId: ${orgId}`
    );
    return (
      parsedValue.orgIds.includes("*") || parsedValue.orgIds.includes(orgId)
    );
  } catch (ex) {
    console.error(`Unable to parse values for ${flagName}`);
    return false;
  }
};
