import {
    Box,
    Grid,
    Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import SiteCreateOrEdit from "../admin/sites/SiteCreateOrEdit";
import styled from "@emotion/styled";
import UPLOAD_TYPE from "../upload/UploadType";
import { useState } from "react";
import FolderStructure from "../folder-view/FolderStructure";
import { setEditData } from "../admin/editStore/editSlice";
import SiteDetailsMapViewer from "./SiteDetailsMapViewer";


const MapPanel = styled.div`
    border-radius: 4px;
    border: 2px solid #A8A8A8;
`


function SiteDetails(props) {
    const { site } = props;
    const dispatch = useDispatch();


    const onUpdateSite = () => {
        props.onUpdateSite();
    }

    const [openModelPopup, setOpenModelPopup] = useState(false);
    const handleOnBackClick = () => {
        var accountIds = site.accounts.map(a => a.id);
        dispatch(setEditData({ site: site, accountIds: accountIds, accounts: [] }))
        setOpenModelPopup(false);
    }

    return (
        <div className='w-full' style={{ 'marginTop': '60px' }}>
            <Box>
                {!openModelPopup ?
                    <Grid container>

                        <Grid item xs={6}>
                            <div>
                                <SiteCreateOrEdit siteId={site.id} onUpdateSite={onUpdateSite} siteMenuViewer={false} siteViewer={true}></SiteCreateOrEdit>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className='m-16'>
                                <MapPanel>
                                    <SiteDetailsMapViewer
                                        site={site}
                                    />

                                </MapPanel>
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <div className='m-16'>
                                <div className='flex items-center justify-between'>
                                    <div></div>
                                    <div className="pb-8">
                                        <Button variant="outlined" onClick={() => handleOnBackClick()}>Close</Button>
                                    </div>

                                </div>
                                <FolderStructure
                                    type={UPLOAD_TYPE.site}
                                    id={(site != null && site != undefined) ? site.id : 0}
                                >
                                </FolderStructure>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Box>


        </div>
    )
}

export default SiteDetails;