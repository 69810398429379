import React from "react";
import { Box, Button } from "@mui/material";

const StepperStepButtons = ({
  handleNext,
  handleBack,
  nextButtonText,
  backButtonText,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <div>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{
            mt: 1,
            mr: 1,
            border: nextButtonText ? "1px solid #59bec9" : "none",
            backgroundColor:
              nextButtonText === "Skip" ? "rgb(255,255,255)" : "#59bec9",
            color: nextButtonText === "Skip" ? "#59bec9" : "rgb(255, 255, 255)",
          }}
        >
          {nextButtonText}
        </Button>
        <Button
          onClick={handleBack}
          sx={{
            mt: 1,
            mr: 1,
            backgroundColor: "#cfcdc9",
            display: handleBack ? "" : "none",
          }}
        >
          {backButtonText}
        </Button>
      </div>
    </Box>
  );
};

export default StepperStepButtons;
