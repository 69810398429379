// eslint-disable-next-line complexity
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] && a[orderBy]) {
        try {
            if (
                a[orderBy] instanceof Date ||
                b[orderBy] instanceof Date ||
                typeof a[orderBy] == "number" ||
                typeof b[orderBy] == "number"
            ) {
                if (b[orderBy] < a[orderBy]) {
                    return -1;
                }
                if (b[orderBy] > a[orderBy]) {
                    return 1;
                }
            } else {
                if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                    return -1;
                }
                if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                    return 1;
                }
            }
        } catch (error) {
            return 0;
        }
    } else {
        return 0;
    }
};

export const getComparator = (order, orderBy) => {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
