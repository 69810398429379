/* eslint import/no-extraneous-dependencies: off */
import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import APP_CONFIG from "../../AppConfig";

export const loadAccount = () => async (dispatch, getState) => {

    const accountId = getState().auth.customer.defaultAccount;

    const url = APP_CONFIG.api + `v1/accounts/${accountId}`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            return dispatch(setAccount(response.data));
        }
    })
        .catch((error) => {
            console.warn('Cannot retrieve account data', error);
        });

};

const initialState = null;
    /*
{
    id: 1,
    name: "Account 1",
    status: "active",
    subscriptionStatus: "active"
};

     */

const accountSlice = createSlice({
    name: 'auth/account',
    initialState,
    reducers: {
        setAccount: (state, action) => action.payload,
    },
    extraReducers: {},
});

export const {setAccount} = accountSlice.actions;

export default accountSlice.reducer;
