/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  organisationadmin : ['organisationadmin'],
  projectmanager : ['projectmanager'],
  staff: ['staff'],
  user: ['user'],
  demouser : ['demouser'],
  onlyGuest: [],
};

export default authRoles;
