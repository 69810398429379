import { combineReducers } from '@reduxjs/toolkit';
import siteApp from './siteSlice';
import scanApp from './scanSlice';
import accountApp from './accountSlice';
import customerApp from './customerSlice';
import folderApp from './folderSlice';
import userActivityApp from './userActivitySlice';
import scanProcess from './scanProcessSlice';
import deviceApp from './deviceSlice';
import templateApp from './templateSlice';
import socketApp from './socketSlice';
import usageApp from './usageSlice';
import siteUsersApp from './siteUsersSlice';
import inviteUserApp from './inviteUserSlice';
import configApp from './configSlice';
import oraganizationBillingApp from './organizationBillingSlice';
import wifiCaptureApp from './wifiCaptureSlice';
import notificationsApp from './notificationSlice';
import projectApp from './projectSlice';
import tokensApp from './tokensSlice';
import invoiceApp from './projectInvoiceSlice';

const adminReducer = combineReducers({
  siteApp,
  scanApp,
  accountApp,
  customerApp,
  folderApp,
  userActivityApp,
  scanProcess,
  deviceApp,
  templateApp,
  socketApp,
  usageApp,
  siteUsersApp,
  inviteUserApp,
  configApp,
  oraganizationBillingApp,
  wifiCaptureApp,
  notificationsApp,
  projectApp,
  tokensApp,
  invoiceApp
});

export default adminReducer;