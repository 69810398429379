import * as turf from "@turf/turf";
import MapBoxCommon from "../../MapBoxCommon";

class MapBoxAngleMeasure extends MapBoxCommon {
    constructor(viewer, lat, lng, panelRef, checkPointValid, elevation, isDisableMoving) {
        super();
        this.lat = lat;
        this.lng = lng;
        this.viewer = viewer;
        this.panelRef = panelRef;
        this.canvas = this.viewer.getCanvasContainer();
        this.checkPointValid = checkPointValid;

        this.styleLoadEvent = this.onStyleLoad.bind(this);
        this.coords = {
            lng: lng,
            lat: lat
        }
        this.pointClouds = [];

        this.lineId = this.createUUID();
        this.initialPointId = this.createUUID();

        this.currentMovingPointId = this.initialPointId;

        this.addLine(viewer, this.lineId);
        this.addPointLayer(this.lng, this.lat, this.initialPointId);

        this.anglePoints = [];
        this.angleLabels = [];
        this.addAnglePoint(this.lng, this.lat, this.initialPointId, elevation);
        if (!isDisableMoving) {
            var pointId = this.addNewPoint(this.lng, this.lat);
            this.startMoveingPoint(pointId);
        }
    }

    addNewPoint(lng, lat) {
        var pointId = this.createUUID();
        this.addPointLayer(lng, lat, pointId);
        this.addAnglePoint(lng, lat, pointId);
        return pointId;
    }

    startMoveingPoint(id) {
        this.currentMovingPointId = id;
        let onMouseMove = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
                this.onMouseMove(coords.lng, coords.lat);
            }

        }

        let onMouseLeave = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('click', onClick);
            this.viewer.off('contextmenu', onRigthClick);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
        }

        let onClick = (e) => {
            const coords = e.lngLat;

            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds) && this.anglePoints.length < 3) {
                var pointId = this.addNewPoint(coords.lng, coords.lat)
                this.viewer.off('mousemove', onMouseMove);
                this.viewer.off('click', onClick);
                this.viewer.off('contextmenu', onRigthClick);
                this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
                this.startMoveingPoint(pointId);

                if (this.anglePoints.length == 3) {
                    this.anglePoints.forEach(anglePoint => {
                        this.addAngleLabel(anglePoint.lng, anglePoint.lat);
                    });
                }


            }
        }

        let onRigthClick = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('click', onClick);
            this.viewer.off('contextmenu', onRigthClick);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);

            this.update();


        }

        this.viewer.on('mousemove', onMouseMove);
        this.viewer.on('click', onClick);
        this.viewer.once('contextmenu', onRigthClick);
        this.panelRef.current.addEventListener('mouseleave', onMouseLeave);

    }


    addAnglePoint(lng, lat, id, elevation) {
        var anglePoint = {
            id: id,
            lng: lng,
            lat: lat,
            elevation: elevation ? elevation : 0
        }
        // if (this.anglePoints.length == 0) {
        this.anglePoints.push(anglePoint);
        // }
        // else {
        //     this.anglePoints.splice(1, 0, anglePoint);
        // }
    }

    updateAnglePoint(lng, lat, id) {
        var anglePointIndex = this.anglePoints.findIndex(x => x.id == id);
        if (anglePointIndex != -1) {
            this.anglePoints[anglePointIndex].lng = lng;
            this.anglePoints[anglePointIndex].lat = lat;
            this.anglePoints[anglePointIndex].elevation = 0;
        }
    }

    addAngleLabel(lng, lat) {
        var angleLabel = this.addLabel(this.viewer, lng, lat);
        this.angleLabels.push(angleLabel);
    }

    onStyleLoad(e) {
        this.addLine(this.viewer, this.lineId);
        this.anglePoints.forEach(anglePoint => {
            this.addPointLayer(anglePoint.lng, anglePoint.lat, anglePoint.id);
        });
        this.update();
    }

    addPointLayer(lng, lat, id) {
        let onMouseMove = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
                this.onMouseMove(coords.lng, coords.lat);
            }
        }
        let onMouseUp = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);

            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
            }
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }
        let onMouseLeave = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }

        this.addPoint(this.viewer, lng, lat, id);
        this.viewer.on('mouseenter', id, () => {
            this.canvas.style.cursor = 'move';
        });

        this.viewer.on('mouseleave', id, () => {
            this.canvas.style.cursor = '';
        });

        this.viewer.on('mousedown', id, (e) => {
            e.preventDefault();
            this.canvas.style.cursor = 'move';
            this.currentMovingPointId = id;//e.features[0].layer.id;
            this.viewer.on('mousemove', onMouseMove);
            this.panelRef.current.addEventListener('mouseleave', onMouseLeave);
            this.viewer.once('mouseup', onMouseUp);
        });
    }



    onMouseMove(lng, lat) {
        this.updatePoint(this.viewer, lng, lat, this.currentMovingPointId);
        this.updateAnglePoint(lng, lat, this.currentMovingPointId);
        this.update();
    }

    onMouseUp(lng, lat) {
        this.updatePoint(this.viewer, lng, lat, this.currentMovingPointId);
        this.updateAnglePoint(lng, lat, this.currentMovingPointId);
        this.update();
    }

    setPointClouds(pointClouds) {
        this.pointClouds = pointClouds
    }


    update() {
        var lngLats = [];
        this.anglePoints.forEach(anglePoint => {
            var lngLat = [anglePoint.lng, anglePoint.lat];
            lngLats.push(lngLat);
        });

        if (this.anglePoints.length >= 3) {
            var lngLat = [this.anglePoints[0].lng, this.anglePoints[0].lat];
            lngLats.push(lngLat);
        }
        this.updateLine(this.viewer, lngLats, this.lineId);

        if (this.anglePoints.length == 3) {
            let firstPoint = this.anglePoints[0];
            let midPoint = this.anglePoints[1];
            let secondPoint = this.anglePoints[2];

            var midAngle = turf.angle([firstPoint.lng, firstPoint.lat], [midPoint.lng, midPoint.lat], [secondPoint.lng, secondPoint.lat])
            var firstAngle = turf.angle([midPoint.lng, midPoint.lat], [firstPoint.lng, firstPoint.lat], [secondPoint.lng, secondPoint.lat])
            var secondAngle = turf.angle([firstPoint.lng, firstPoint.lat], [secondPoint.lng, secondPoint.lat], [midPoint.lng, midPoint.lat])


            this.updateLabelLngLat(this.angleLabels[0], firstPoint.lng, firstPoint.lat);
            var mainElement1 = this.angleLabels[0].getElement().getElementsByClassName('labelMarker');
            var pointElement1 = this.angleLabels[0].getElement().getElementsByClassName('labelTextMarker');
            if (mainElement1.length > 0 && pointElement1.length > 0) {
                mainElement1[0].style.marginTop = '40px';
                if (firstAngle > 180) {
                    firstAngle = 360 - firstAngle;
                }
                pointElement1[0].innerHTML = `${firstAngle.toFixed(1)}&#xB0;`;
            }

            this.updateLabelLngLat(this.angleLabels[1], midPoint.lng, midPoint.lat);
            var mainElement2 = this.angleLabels[1].getElement().getElementsByClassName('labelMarker');
            var pointElement2 = this.angleLabels[1].getElement().getElementsByClassName('labelTextMarker');
            if (mainElement2.length > 0 && pointElement2.length > 0) {
                mainElement2[0].style.marginTop = '40px';

                if (midAngle > 180) {
                    midAngle = 360 - midAngle;
                }
                pointElement2[0].innerHTML = `${midAngle.toFixed(1)}&#xB0;`;
            }

            this.updateLabelLngLat(this.angleLabels[2], secondPoint.lng, secondPoint.lat);
            var mainElement3 = this.angleLabels[2].getElement().getElementsByClassName('labelMarker');
            var pointElement3 = this.angleLabels[2].getElement().getElementsByClassName('labelTextMarker');
            if (mainElement3.length > 0 && pointElement3.length > 0) {
                mainElement3[0].style.marginTop = '40px';
                if (secondAngle > 180) {
                    secondAngle = 360 - secondAngle;
                }
                pointElement3[0].innerHTML = `${secondAngle.toFixed(1)}&#xB0;`;
            }
        }
    }

    remove() {
        this.anglePoints.forEach(anglePoint => {
            this.removePoint(this.viewer, anglePoint.id);
        });
        this.anglePoints = [];
        this.removeLine(this.viewer, this.lineId);
        this.lineId = '';
        this.angleLabels.forEach(angleLabel => {
            this.removeLabel(angleLabel)
        });
        this.angleLabels = [];
    }

    removePointCloudPoints(scanPointClouds) {
        this.anglePoints = this.anglePoints.filter(point => {
            if (!this.checkPointValid(point.lng, point.lat, scanPointClouds)) {
                this.removePoint(this.viewer, point.id);
                return false;
            } else {
                return true;
            }
        })
        if (this.anglePoints.length == 0) {
            return true;
        } else {
            if (this.anglePoints[0].id != this.initialPointId) {
                this.initialPointId = this.anglePoints[0].id;
            }
            if (this.anglePoints.length < 3) {
                this.angleLabels.forEach(angleLabel => {
                    this.removeLabel(angleLabel)
                });
                this.angleLabels = [];
            }
            this.update();
            return false;
        }
    }


}

export default MapBoxAngleMeasure;