
import { createStyles, makeStyles } from "@mui/styles";

const CommonStyles = makeStyles(() =>
  createStyles({
    primaryColor: {
      color: '#6CCCD7'
    },
    errorColor: {
      color: '#E67260'
    },
    menuPaper: {
      borderRadius: "0px",
      border: '1px solid #000000'
    }
  })
);

export default CommonStyles;
