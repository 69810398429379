

const pointInsertion = {
	showDistances: false,
	showAngles: false,
	showCoordinates: true,
	showArea: false,
	closed: true,
	maxMarkers: 1,
	name: 'Point'
};

const distanceInsertion = {
	showDistances: true,
	showArea: false,
	closed: false,
	name: "Distance"
}

const areaInsertion = {
	showDistances: true,
	showArea: true,
	closed: true,
	name: 'Area'
}

const heightInsertion = {
	showDistances: false,
	showHeight: true,
	showArea: false,
	closed: false,
	maxMarkers: 2,
	name: 'Height'
}

const angleInsertion = {
	showDistances: false,
	showAngles: true,
	showArea: false,
	closed: true,
	maxMarkers: 3,
	name: 'Angle'
}

function getPointInsertion(name){
	return  {
		showDistances: false,
		showAngles: false,
		showCoordinates: true,
		showArea: false,
		closed: true,
		maxMarkers: 1,
		name: name
	}
}

export function getInsertion(type) {
	let insertion = null;
	if (type === 'Point') {
		insertion = getPointInsertion(type);
	} else if (type === "Distance") {
		insertion = distanceInsertion
	} else if (type === "Height") {
		insertion = heightInsertion
	} else if (type === "Angle") {
		insertion = angleInsertion;
	} else if (type === "Area") {
		insertion = areaInsertion;
	} 
	 else {
		throw new Error("Unrecogized measurement type: " + type);
	}

	return insertion;
}
