import MapBoxPointMeasure from "./MapBoxPointMeasure";
import MapBoxDistanceMeasure from "./v2/MapBoxDistanceMeasure";
import MapBoxAngleMeasure from "./v2/MapBoxAngleMeasure";
import MapBoxAreaMeasure from "./v2/MapBoxAreaMeasure";
import { Convert3Dto2D } from "../../CoordinateConverter";
import MapBoxConstants from "../constants/MapBoxConstants";

const AddPointMeasurement = (viewer, position, panelRef, checkPointValid) => {
    var measure = new MapBoxPointMeasure(viewer, position.latitude, position.longitude, panelRef, checkPointValid, position.elevation);
    return measure;
}

const AddDistanceMeasurement = (viewer, positions, panelRef, checkPointValid, unitLength) => {
    if (positions.length > 0) {
        var points = [...positions];
        points.reverse();
        let firstPoint = points[0];
        let measure = null;
        if (MapBoxConstants.version == "v1") {
            measure = new MapBoxDistanceMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, unitLength, checkPointValid, firstPoint.elevation);
        } else if (MapBoxConstants.version == "v2") {
            measure = new MapBoxDistanceMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, unitLength, checkPointValid, firstPoint.elevation, true);
        }
        if (measure) {
            points.splice(0, 1);
            points.reverse();
            points.forEach(point => {
                var pointId = measure.createUUID();
                measure.addPointLayer(point.longitude, point.latitude, pointId);
                measure.addDistancePoint(point.longitude, point.latitude, pointId, point.elevation);
                measure.addDistanceLabel(point.longitude, point.latitude);
            });
            measure.update();
        }

        return measure;
    }
    else {
        return null;
    }
}

const AddAngleMeasurement = (viewer, positions, panelRef, checkPointValid) => {
    if (positions.length > 0) {
        var points = [...positions];
        points.reverse();
        let firstPoint = points[0];
        let measure = null;
        if (MapBoxConstants.version == "v1") {
            measure = new MapBoxAngleMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, checkPointValid, firstPoint.elevation);
        } else if (MapBoxConstants.version == "v2") {
            measure = new MapBoxAngleMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, checkPointValid, firstPoint.elevation, true);
        }

        if (measure) {
            points.splice(0, 1);
            points.reverse();
            points.forEach(point => {
                var pointId = measure.createUUID();
                measure.addPointLayer(point.longitude, point.latitude, pointId);
                measure.addAnglePoint(point.longitude, point.latitude, pointId, point.elevation);
            });
            if (measure.anglePoints.length >= 3) {
                measure.anglePoints.forEach(anglePoint => {
                    measure.addAngleLabel(anglePoint.lng, anglePoint.lat);
                });
            }
            measure.update();
        }
        return measure;
    }
    else {
        return null;
    }
}

const AddAreaMeasurement = (viewer, positions, panelRef, checkPointValid, unitLength) => {
    if (positions.length > 0) {
        var points = [...positions];
        points.reverse();
        let firstPoint = points[0];
        let measure = null;
        if (MapBoxConstants.version == "v1") {
            measure = new MapBoxAreaMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, unitLength, checkPointValid, firstPoint.elevation);
        } else if (MapBoxConstants.version == "v2") {
            measure = new MapBoxAreaMeasure(viewer, firstPoint.latitude, firstPoint.longitude, panelRef, unitLength, checkPointValid, firstPoint.elevation, true);
        }
        if (measure) {
            points.splice(0, 1);
            points.reverse();
            points.forEach(point => {
                var pointId = measure.createUUID();
                measure.addPointLayer(point.longitude, point.latitude, pointId);
                measure.addAreaPoint(point.longitude, point.latitude, pointId, point.elevation);
                measure.addDistanceLabel(point.longitude, point.latitude);
            });
            if (measure.areaPoints.length >= 3) {
                measure.addAreaLabel(firstPoint.longitude, firstPoint.latitude);
                measure.addDistanceLabel(firstPoint.longitude, firstPoint.latitude);
            }
            measure.update();
        }

        return measure;
    }
    else {
        return null;
    }
}



export const AddMeasurementData = (viewer, panelRef, checkPointValid, data, pointClouds, unitLength) => {
    var pointMeasures = [];
    var distanceMeasures = [];
    var angleMeasures = [];
    var areaMeasures = [];
    data.measurements.forEach(measurement => {
        if (measurement.name == 'Point') {
            let position = Convert3Dto2D(measurement.easting, measurement.northing, measurement.elevation,
                measurement.epsg);
            let measure = AddPointMeasurement(viewer, position, panelRef, checkPointValid);
            pointMeasures.push(measure);
        }
        else if (measurement.name == 'Distance') {
            var positions = [];
            measurement.points.forEach(point => {
                let position = Convert3Dto2D(point.easting, point.northing, point.elevation,
                    point.epsg);
                positions.push(position);
            });
            let measure = AddDistanceMeasurement(viewer, positions, panelRef, checkPointValid, unitLength);
            if (measure) {
                distanceMeasures.push(measure);
            }

        }
        else if (measurement.name == 'Area') {
            var positions = [];
            measurement.points.forEach(point => {
                let position = Convert3Dto2D(point.easting, point.northing, point.elevation,
                    point.epsg);
                positions.push(position);
            });
            let measure = AddAreaMeasurement(viewer, positions, panelRef, checkPointValid, unitLength);
            if (measure) {
                areaMeasures.push(measure);
            }

        }
        else if (measurement.name == 'Angle') {
            var positions = [];
            measurement.points.forEach(point => {
                let position = Convert3Dto2D(point.easting, point.northing, point.elevation,
                    point.epsg);
                positions.push(position);
            });
            let measure = AddAngleMeasurement(viewer, positions, panelRef, checkPointValid);
            if (measure) {
                angleMeasures.push(measure);
            }
        }
    });
    let response = {
        pointMeasures: pointMeasures,
        distanceMeasures: distanceMeasures,
        angleMeasures: angleMeasures,
        areaMeasures: areaMeasures
    }
    return response;
}