import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import APP_CONFIG from "../../../../AppConfig";


export const getAllSitesDetailsForUsage = createAsyncThunk(
    'admin/usageApp/getAllSitesDetailsForUsage', async (_, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/site/getAllSitesDetailsForUsage`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);


export const getAllSitesDetailsForUsagePerOrganisation = createAsyncThunk(
    'admin/usageApp/getAllSitesDetailsForUsagePerOrganisation', async (organisationId, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/site/getAllSitesDetailsForUsagePerOrganisation/${organisationId}`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);


export const getActiveUsers = createAsyncThunk(
    'admin/usageApp/getActiveUsers', async (_, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/auditLog/getActiveUsers`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const getActiveUsersPerOrganisation = createAsyncThunk(
    'admin/usageApp/getActiveUsersPerOrganisation', async (organisationId, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/auditLog/getActiveUsersPerOrganisation/${organisationId}`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);



const siteSlice = createSlice({
    name: 'admin/usageApp',
    initialState: {
        sitesDetails: [],
        userUsage: {}
    },

    reducers: {
        setResetUsageData: (state, action) => {
            state.sitesDetails = [];
            state.userUsage = {};
            state.isPaymentAccountLinked = false;
        }

    },
    extraReducers: {
        [getAllSitesDetailsForUsage.fulfilled]: (state, action) => {
            state.sitesDetails = action.payload.sites;
            state.isPaymentAccountLinked = action.payload.isPaymentAccountLinked;

        },
        [getAllSitesDetailsForUsagePerOrganisation.fulfilled]: (state, action) => {
            state.sitesDetails = action.payload.sites;
            state.isPaymentAccountLinked = action.payload.isPaymentAccountLinked;
        },
        [getActiveUsers.fulfilled]: (state, action) => {
            state.userUsage = action.payload;
        },
        [getActiveUsersPerOrganisation.fulfilled]: (state, action) => {
            state.userUsage = action.payload;
        }
    },
});

export const { setResetUsageData } = siteSlice.actions;

export default siteSlice.reducer;
