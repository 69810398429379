class CommonSorting {
  static descendingComparator(a, b, orderBy) {
    const findOrderByValue = (value) => this.findProperty(value, orderBy);
    const orderByA = findOrderByValue(a);
    const orderByB = findOrderByValue(b);

    if (orderByB || orderByA) {
      if (orderByB < a[orderBy]) {
        return -1;
      }
      if (orderByB > orderByA) {
        return 1;
      }
    }
    return -1;
  }

  static findProperty(obj, property) {
    property = property.split(".");
    for (var i = 0; i < property.length; i++) {
      if (obj[property[i]] == undefined) {
        return null;
      }
      obj = obj[property[i]];
    }
    return obj;
  }

  static getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  static stableSort(array, order, orderBy) {
    var comparator = this.getComparator(order, orderBy);
    const stabilizedThis = (array || []).map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

export default CommonSorting;
