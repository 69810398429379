import { Tooltip, IconButton } from "@mui/material";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";

const WizardButton = ({ onClick, disabled }) => {
  return (
    <Tooltip
      placement="right-start"
      title="Cycle through ground control points"
    >
      <IconButton
        disabled={disabled}
        onClick={() => onClick()}
        style={{
          borderRadius: "8px",
          fontSize: "13px",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: "rgb(255, 255, 255)",
          backgroundColor: disabled ? "#EBEBE4" : "rgb(108, 204, 215)",
          fontWeight: 600,
          padding: "5px 16px",
        }}
      >
        ATC Wizard
        <AutoFixNormalIcon style={{ color: "rgb(255, 255, 255)" }} />
      </IconButton>
    </Tooltip>
  );
};

export default WizardButton;
