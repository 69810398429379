import { Typography } from "@mui/material";
import { FaCircleExclamation } from "react-icons/fa6";

const AlignToControlStepperDiff = (props) => {
  const { step } = props;

  const calculateDifference = (a, b) => {
    return a !== undefined && b !== undefined ? (
      (a - b).toFixed(3)
    ) : (
      <FaCircleExclamation color="#59bec9" />
    );
  };

  const differencePadding = (a, b) => {
    if (a === undefined || b === undefined) return "1.2rem";

    const difference = (a - b).toFixed(3);
    return difference < 0 ? "0.675rem" : "1.2rem";
  };

  const xDifference = calculateDifference(step?.model?.x, step?.control?.x);
  const yDifference = calculateDifference(step?.model?.y, step?.control?.y);
  const zDifference = calculateDifference(step?.model?.z, step?.control?.z);

  return (
    <div>
      <Typography
        variant="body1"
        sx={{
          mb: 0.5,
          display: "flex",
        }}
      >
        <strong
          style={{
            paddingRight: differencePadding(step?.model?.x, step?.control?.x),
          }}
        >
          X:{" "}
        </strong>{" "}
        {xDifference}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 0.5,
          display: "flex",
        }}
      >
        <strong
          style={{
            paddingRight: differencePadding(step?.model?.y, step?.control?.y),
          }}
        >
          Y:{" "}
        </strong>{" "}
        {yDifference}
      </Typography>
      <Typography sx={{ display: "flex" }} variant="body1">
        <strong
          style={{
            paddingRight: differencePadding(step?.model?.z, step?.control?.z),
          }}
        >
          Z:{" "}
        </strong>{" "}
        {zDifference}
      </Typography>
    </div>
  );
};

export default AlignToControlStepperDiff;
