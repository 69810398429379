import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const getPendingNotifications = createAsyncThunk(
  "admin/notificationsApp/getPendingNotifications",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/notification/getPendingNotifications`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    const scanPendingNotifications = data.filter(
      (x) => x.notificationType == "SCAN_PENDING"
    );
    const role = getState().auth.user.role;
    const selectedNotification =
      getState().admin.notificationsApp.selectedNotification;
    if (selectedNotification) {
      if (
        scanPendingNotifications.filter(
          (x) => x.scanId == selectedNotification.id
        ).length == 0
      ) {
        dispatch(setShowNotificationScanAlert(false));
        dispatch(updateScanNotificationDialog(false));
      }
    }
    const alertData = scanPendingNotifications.filter((x) => x.showAlert);
    if (role.includes("organisationadmin")) {
      if (alertData.length > 0) {
        if (
          !getState().admin.notificationsApp.openScanNotificationDailog &&
          !getState().admin.notificationsApp.showNotificationScanAlert
        ) {
          dispatch(setSelectedNotification(alertData[0].scan));
          dispatch(setShowNotificationScanAlert(true));
        } else {
          dispatch(updatePendingShowAlert(alertData[0].scan.id));
        }
      }
    }
    dispatch(setNotifications(data));
  }
);

export const getScanForNotification = createAsyncThunk(
  "admin/notificationsApp/getScanForNotification",
  async (scanId, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/scan/getScanDetailsForNotification/${scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;

    if (data) {
      dispatch(setShowNotificationScanAlert(false));
      dispatch(setSelectedNotification(data));
      dispatch(updateScanNotificationDialog(true));
    }
  }
);

export const updatePendingShowAlert = createAsyncThunk(
  "admin/notificationsApp/updatePendingShowAlert",
  async (scanId) => {
    const url =
      APP_CONFIG.api + `v1/notification/updatePendingShowAlert/${scanId}`;
    const response = await axios.put(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    await response.data;
  }
);

export const deleteAlignToControlNotification = createAsyncThunk(
  "admin/notificationsApp/deleteAlignToControlNotification",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/notification/deleteALignToControlNotification/${requestData.id}`;
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    await response.data;
    requestData.successCallback();
  }
);

export const cancelAlignToControl = createAsyncThunk(
  "admin/notificationsApp/cancelAlignToControl",
  async (queueId) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/cancelAlignToControl/${queueId}`;
    await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
);

export const clearAlignToControlNotifications = createAsyncThunk(
  "admin/notificationsApp/clearAllAlignToControlNotification",
  async (requestData) => {
    const url =
      APP_CONFIG.api + `v1/notification/clearAllAlignToControlNotification`;
    await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    requestData.successCallback();
  }
);

export const deleteRevertBackToOriginalNotifications = createAsyncThunk(
  "admin/notificationsApp/deleteRevertBackToOriginalNotifications",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/notification/deleteRevertBackToOriginalNotification/${requestData.id}`;
    await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    requestData.successCallback();
  }
);

const notificationSlice = createSlice({
  name: "admin/notificationsApp",
  initialState: {
    showNotifications: false,
    openScanNotificationDailog: false,
    notifications: [],
    selectedNotification: null,
    showNotificationScanAlert: false,
  },
  reducers: {
    updateShowNotifications: (state, action) => {
      state.showNotifications = action.payload;
    },
    updateScanNotificationDialog: (state, action) => {
      state.openScanNotificationDailog = action.payload;
    },
    setSelectedNotification: (state, action) => {
      state.selectedNotification = action.payload;
    },
    setShowNotificationScanAlert: (state, action) => {
      state.showNotificationScanAlert = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const {
  updateShowNotifications,
  updateScanNotificationDialog,
  setSelectedNotification,
  setShowNotificationScanAlert,
  setNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
