import MapBoxPanoImages from "../../scan/mapboxviews/panoimages/MapBoxPanoImages";
import { getGeoJsonForMapbox, loadAllGpsImageInfos } from "../../scan/potree/Service";

/**
 * Main function to load and manage images
 */
// eslint-disable-next-line complexity
export const loadImagesFromPano = async (
  imageMarkers,
  index,
  scanIds,
  offset,
  limit,
  totalCount,
  mapBoxViewer,
  onClickPanoImage,
  mapperEnabled,
  imageMarkersVisible,
  onMarkersUpdate
) => {
  try {
    const response = await loadAllGpsImageInfos(scanIds[index], offset, limit, totalCount);
    const { imageInfo: imageInfos, totalCount: totalImageFiles } = response.data;
    let updatedMarkers = imageMarkers;

    if (imageMarkersVisible && imageInfos.length > 0) {
      if (updatedMarkers) {
        updatedMarkers.updateScanImageInfos(imageInfos);
        updatedMarkers.setPanoLocationSource(mapperEnabled);
      } else {
        updatedMarkers = new MapBoxPanoImages(
          mapBoxViewer,
          [imageInfos],
          onClickPanoImage
        );
        updatedMarkers.setPanoLocationSource(mapperEnabled);
      }
    }

    if (!imageMarkersVisible) {
      if (updatedMarkers) {
        updatedMarkers.remove();
        updatedMarkers = null;
      }
    }

    let nextBatch = null;
    if (offset <= totalImageFiles) {
      nextBatch = {
        index,
        offset: offset + limit,
        totalCount: totalImageFiles
      };
    } else if (index < scanIds.length - 1) {
      nextBatch = {
        index: index + 1,
        offset: 0,
        totalCount: 0
      };
    }

    if (nextBatch) {
      if (imageMarkersVisible) {
        return loadImagesFromPano(
          updatedMarkers,
          nextBatch.index,
          scanIds,
          nextBatch.offset,
          limit,
          nextBatch.totalCount,
          mapBoxViewer,
          onClickPanoImage,
          mapperEnabled,
          imageMarkersVisible,
          onMarkersUpdate
        );
      } else {
        if (updatedMarkers) {
          updatedMarkers.remove();
          updatedMarkers = null;
        }
      }
    }

    if (imageMarkersVisible) {
      if (updatedMarkers) {
        onMarkersUpdate(updatedMarkers);
      }
    } else {
      if (updatedMarkers) {
        updatedMarkers.remove();
        updatedMarkers = null;
      }
    }

    return updatedMarkers;
  } catch (error) {
    console.error('Error loading images:', error);
    throw error;
  }
};

/**
 * Alternative function to load images via a Geo JSON for Mapbox
 */
export const loadImagesFromGeoJson = async (imageMarkers, index, scanIds, mapBoxViewer, mapperEnabled, imageMarkersVisible, onClickPanoImage, onMarkersUpdate) => {
  try {
    const response = await getGeoJsonForMapbox(scanIds[index]);
    let updatedMarkers = imageMarkers;
    
    if (imageMarkersVisible) {
        updatedMarkers = new MapBoxPanoImages(
          mapBoxViewer,
          [],
          onClickPanoImage
        );
        updatedMarkers.setPanoLocationSource(mapperEnabled, response.data);
    }

    if (!imageMarkersVisible && updatedMarkers) {
      updatedMarkers.remove();
      updatedMarkers = null;
    }

    if (imageMarkersVisible && updatedMarkers) {
      onMarkersUpdate(updatedMarkers);
    }
    return updatedMarkers;

  } catch (error) {
    console.error('Error loading Geo JSON for Mapbox:', error);
    throw error;
  }
};
