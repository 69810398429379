import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { getDemoDefaultClassificationSettings } from '../../store/DashboardSlice';
import { convertClassificationListToObject } from 'app/main/apps/scan/potree/classification/ClassificationConvertor';
import styled from "@emotion/styled";
import Checkbox from '@mui/material/Checkbox';
import { ColorPicker } from 'material-ui-color';
import ClassificationActions from 'app/main/apps/common/ClassificationActions';

const OuterPanel = styled.div`
`;

const VisiblePanel = styled.div`
  width: 250px;
`;

const Classification = styled.div`
	display: flex;
`

function DemoUserSiteClassifications(props) {

    const dispatch = useDispatch();
    const { updateClassifications } = props;
    const [classificationList, setClassificationList] = useState([]);
    const classificationRef = useRef();

    useEffect(() => {
        ClassificationActions.removeBackgroundClass(classificationRef.current);
    }, []);

    useEffect(() => {
        loadDefaultClassifications();
    }, [dispatch]);


    useEffect(() => {
        if (classificationList.length > 0) {
            const updatedObject = convertClassificationListToObject(classificationList);
            updateClassifications(updatedObject);
        }
    }, [classificationList]);


    const loadDefaultClassifications = () => {
        const classificationApi = getDemoDefaultClassificationSettings();
        Promise.resolve(classificationApi)
            .then((response) => {
                if (response.status === 200) {
                    var tempObject = response.data;
                    setClassificationList(tempObject);
                }
            })
            .catch((error) => {
                console.warn("Cannot retrieve data", error);
            });
    }


    const onToggleClassificationVisibility = (index) => {
        try {
            const classification = classificationList[index];
            classification.isChecked = !classification.isChecked;
            const updatedArray = [...classificationList];
            updatedArray[index].isChecked = classification.isChecked;
            setClassificationList(updatedArray);
        } catch (error) {
            console.error(error);
        }
    }

    function changeClassificationColor(color, index) {
        const updatedArray = [...classificationList];
        updatedArray[index].colorCode = color.css.backgroundColor;
        setClassificationList(updatedArray);
    }


    return (
        <OuterPanel ref={classificationRef}>
            {classificationList.map((c, i) => (
                <Classification key={i} className='justify-between'>
                    <VisiblePanel>
                        <Checkbox checked={c.isChecked} sx={{
                            color: '#6CCCD7',
                            '&.Mui-checked': {
                                color: '#6CCCD7',
                            },
                        }}
                            onChange={() => onToggleClassificationVisibility(i)}
                            id={"" + c.key} />
                        <span>{c.classificationName}</span>
                    </VisiblePanel>
                    <ColorPicker
                        className='ml-48'
                        value={c.colorCode}
                        onChange={color => changeClassificationColor(color, i)}
                        hideTextfield={true} />
                </Classification>
            ))}
        </OuterPanel>
    )
}

export default DemoUserSiteClassifications;