import * as turf from "@turf/turf";
import MapBoxCommon from "./MapBoxCommon";

class MapBoxPointCloud extends MapBoxCommon {
    constructor(viewer, pointcloud, isSetBounds = true) {
        super();
        this.viewer = viewer;
        this.id = this.createUUID();
        this.styleLoadEvent = this.onStyleLoad.bind(this);
        this.scanId = pointcloud.scanId;
        this.epsg = pointcloud.epsg;
        this.topLeft = pointcloud.topLeft;
        this.bottomLeft = pointcloud.bottomLeft;
        this.topRight = pointcloud.topRight;
        this.bottomRight = pointcloud.bottomRight;
        this.addBoundryBox();
        if (isSetBounds) {
            this.goToCenter();
            this.setBounds();
        }
    }

    addBoundryBox() {
        this.addLine(this.viewer, this.id, '#039be5');
        var lngLats = this.getLngLatsArray();
        this.updateLine(this.viewer, lngLats, this.id);
    }

    getLngLatsArray() {
        var lngLats = [];
        lngLats.push([this.topLeft.lng, this.topLeft.lat]);
        lngLats.push([this.topRight.lng, this.topRight.lat]);
        lngLats.push([this.bottomRight.lng, this.bottomRight.lat]);
        lngLats.push([this.bottomLeft.lng, this.bottomLeft.lat]);
        lngLats.push([this.topLeft.lng, this.topLeft.lat]);
        return lngLats;
    }

    getCenter() {
        var lngLats = this.getLngLatsArray();
        var areafeatures = turf.points(lngLats);
        var areaCenter = turf.center(areafeatures);
        return areaCenter.geometry.coordinates;
    }


    onStyleLoad(e) {
        this.addBoundryBox();
    }
    goToCenter() {
        this.viewer.setCenter(this.getCenter());
        // this.viewer.flyTo({
        //     center: this.getCenter()
        // });
    }

    zoomIn() {
        this.viewer.setCenter(this.getCenter());
        this.viewer.setZoom(this.viewer.getZoom() + 1);
        // this.viewer.flyTo({
        //     center: this.getCenter(),
        //     zoom: this.viewer.getZoom() + 1
        // });
    }

    zoomOut() {
        this.viewer.setCenter(this.getCenter());
        this.viewer.setZoom(this.viewer.getZoom() - 1);
        // this.viewer.flyTo({
        //     center: this.getCenter(),
        //     zoom: this.viewer.getZoom() - 1
        // });
    }

    checkPointValid(lng, lat) {
        var point = turf.point([lng, lat]);
        var lngLats = [];
        lngLats.push([this.topLeft.lng, this.topLeft.lat]);
        lngLats.push([this.topRight.lng, this.topRight.lat]);
        lngLats.push([this.bottomRight.lng, this.bottomRight.lat]);
        lngLats.push([this.bottomLeft.lng, this.bottomLeft.lat]);
        lngLats.push([this.topLeft.lng, this.topLeft.lat]);
        var polygon = turf.polygon([lngLats]);
        return turf.booleanPointInPolygon(point, polygon);
    }

    setBounds() {
        this.viewer.setCenter(this.getCenter());
        this.viewer.fitBounds([[this.topLeft.lng, this.topLeft.lat], [this.bottomRight.lng, this.bottomRight.lat]], { animate: false })
    }

    remove() {
        this.removeLine(this.viewer, this.id);
    }



}

export default MapBoxPointCloud;