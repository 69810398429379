import { Component } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import history from '@history';
import APP_CONFIG from '../AppConfig';

const onRedirectCallback = (appState) => {
  history.replace(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};

const providerConfig = {
  domain: APP_CONFIG.domain,
  clientId: APP_CONFIG.clientId,
  audience: APP_CONFIG.audience,
  scope: APP_CONFIG.scope,
  redirectUri: window.location.origin,
  cacheLocation: APP_CONFIG.cacheLocation,
  useRefreshTokens: APP_CONFIG.useRefreshTokens,
  onRedirectCallback,
};

class AuthProvider extends Component {

  render() {
    return(<Auth0Provider {...providerConfig}>
      {this.props.children}
    </Auth0Provider>);
  }
}

export  { AuthProvider };
