import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "../../../../AppConfig";

export const getUserActivity = createAsyncThunk('admin/lastActivityApp/getUserActivity', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/auditLog/getUserActivity`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',

        },
    });

    const data = await response.data;
    var newData = data.map(obj => {
        var customer = obj.customer;
        customer = {
            ...customer,
            lastActivityDate: toLocalDateTime(obj.customer.lastActivityDate),
            loginTime: toLocalDateTime(obj.customer.loginTime)
        };
        return { ...obj, customer: customer };
    });
    return newData;
});
const toLocalDateTime = (date) => {
    if (date != undefined && date != null) {
        var t = new Date(date)
        console.log(Math.floor(t / 1000))
        return Math.floor(t / 1000)
    }
    else {
        return 0;
    }
}


const userActivitySlice = createSlice({
    name: 'admin/userActivityApp',
    initialState: {
        userActivityData: [],
        loadingState: '',
    },
    reducers: {
        setUserActivityData: (state, action) => {
            state.userActivityData = action.payload;
        },
    },
    extraReducers: {
        [getUserActivity.fulfilled]: (state, action) => {
            state.userActivityData = action.payload;
            state.loadingState = SERVICE_STATUS.fulfilled;
        },
        [getUserActivity.rejected]: (state, action) => {
            state.loadingState = SERVICE_STATUS.rejected;
        },
        [getUserActivity.pending]: (state, action) => {
            state.loadingState = SERVICE_STATUS.pending;
        },
    },
});

export const { setUserActivityData } = userActivitySlice.actions;

export default userActivitySlice.reducer;