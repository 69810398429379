import React, { useEffect, useState } from "react";
import ScanStatus from "../../constants/ScanStatus";
import CompletedScan from "./CompletedScan";
import RunningScan from "./RunningScan";
import PendingScan from "./PendingScan";

const CommonScans = (props) => {
  const {
    scans,
    loadedScanIds,
    findLoadedScan,
    zoomToPointCloud,
    togglePointCloud,
    currentViewer,
    handleScanDragStart,
    isEnableDrop,
    handleOnScanFolderDrop,
    isFromFolder,
  } = props;

  const [totalScans, setTotalScans] = useState([]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] || a[orderBy]) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const stableSort = (array, comparator) => {
    const dataExistArray = array.filter((x) =>
      checkPropertyExist(x, "processingCompletionDate")
    );
    const dataNotExistArray = array.filter(
      (x) => !checkPropertyExist(x, "processingCompletionDate")
    );
    const stabilizedThis = dataExistArray.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    let finalArray = stabilizedThis.map((el) => el[0]);
    dataNotExistArray.forEach((obj) => {
      finalArray.push(obj);
    });

    return finalArray;
  };

  const checkPropertyExist = (obj, property) => {
    if (obj[property]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (scans) {
      let _completedScans = [
        ...scans.filter((x) =>
          currentViewer == "2D" ? x.mapboxStatus == 2 : x.potreeStatus == 2
        ),
      ];
      _completedScans = stableSort(
        _completedScans,
        getComparator("desc", "processingCompletionDate")
      );
      let _runningScans = [
        ...scans.filter(
          (x) =>
            (currentViewer == "2D"
              ? x.mapboxStatus != 2
              : x.potreeStatus != 2) == true && x.processingStatus == "running"
        ),
      ];
      let _pendingScans = [
        ...scans.filter(
          (x) =>
            (currentViewer == "2D"
              ? x.mapboxStatus != 2
              : x.potreeStatus != 2) == true &&
            x.processingStatus != "running" &&
            !x.isQueued &&
            x.processingStatus != "failed"
        ),
      ];
      let _queuedScans = [
        ...scans.filter(
          (x) =>
            (currentViewer == "2D"
              ? x.mapboxStatus != 2
              : x.potreeStatus != 2) == true && x.isQueued == true
        ),
      ];
      let _failedScans = [
        ...scans.filter(
          (x) =>
            (currentViewer == "2D"
              ? x.mapboxStatus != 2
              : x.potreeStatus != 2) == true &&
            x.processingStatus == "failed" &&
            x.status == "failed"
        ),
      ];
      _failedScans = _failedScans.filter(
        (failedScan) =>
          !_queuedScans.some((queuedScan) => queuedScan.id == failedScan.id)
      );
      for (let i = 0; i < _completedScans.length; i++) {
        let completedScan = { ..._completedScans[i] };
        completedScan.ScanStatus = ScanStatus.COMPLETED;
        _completedScans[i] = completedScan;
      }
      for (let i = 0; i < _runningScans.length; i++) {
        let runningScan = { ..._runningScans[i] };
        runningScan.ScanStatus = ScanStatus.RUNNING;
        _runningScans[i] = runningScan;
      }
      for (let i = 0; i < _pendingScans.length; i++) {
        let pendingScan = { ..._pendingScans[i] };
        pendingScan.ScanStatus = ScanStatus.PENDING;
        _pendingScans[i] = pendingScan;
      }
      for (let i = 0; i < _failedScans.length; i++) {
        let failedScan = { ..._failedScans[i] };
        failedScan.ScanStatus = ScanStatus.FAILED;
        _failedScans[i] = failedScan;
      }
      let _totalScans = _completedScans.concat(
        _runningScans,
        _pendingScans,
        _queuedScans,
        _failedScans
      );

      setTotalScans(_totalScans);
    }
  }, [currentViewer, scans]);

  const handleDragScanStart = (scanId) => {
    handleScanDragStart(scanId);
  };

  const handleOnDrop = (e, scanId) => {
    e.preventDefault();
    if (isEnableDrop) {
      handleOnScanFolderDrop(scanId, "scan");
    }
  };

  return (
    <div>
      {totalScans.map((scan, i) => (
        <React.Fragment key={`${scan.id}-${i}`}>
          {scan.ScanStatus == ScanStatus.COMPLETED && (
            <CompletedScan
              scan={scan}
              index={i}
              handleOnDrop={handleOnDrop}
              handleDragScanStart={handleDragScanStart}
              findLoadedScan={findLoadedScan}
              loadedScanIds={loadedScanIds}
              zoomToPointCloud={zoomToPointCloud}
              togglePointCloud={togglePointCloud}
              currentViewer={currentViewer}
              isFromFolder={isFromFolder}
            />
          )}
          {scan.ScanStatus == ScanStatus.RUNNING && (
            <RunningScan
              scan={scan}
              index={i}
              handleOnDrop={handleOnDrop}
              handleDragScanStart={handleDragScanStart}
              isFromFolder={isFromFolder}
            />
          )}
          {scan.ScanStatus == ScanStatus.PENDING && (
            <PendingScan
              status={"Pending"}
              scan={scan}
              index={i}
              handleOnDrop={handleOnDrop}
              handleDragScanStart={handleDragScanStart}
              isFromFolder={isFromFolder}
            />
          )}
          {scan.isQueued && (
            <PendingScan
              status={"Queued"}
              scan={scan}
              index={i}
              handleOnDrop={handleOnDrop}
              handleDragScanStart={handleDragScanStart}
              isFromFolder={isFromFolder}
            />
          )}
          {scan.ScanStatus == ScanStatus.FAILED && (
            <PendingScan
              status={"Manual Review"}
              scan={scan}
              index={i}
              handleOnDrop={handleOnDrop}
              handleDragScanStart={handleDragScanStart}
              isFromFolder={isFromFolder}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
export default CommonScans;

