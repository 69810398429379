
import { useState } from "react";
import {
    TableContainer,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
    Paper,
    Table,
    TableHead,
    TableSortLabel,
    Box,
    IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CommonSorting from "../../common/CommonSorting";
import StaticStyles from "../../styles/StaticStyles";


function ScanProcessingCloudWatchLogGrid(props) {

    const [collapseId, setCollapseId] = useState(0);
    const { scanCloudWatchLogs, toDateTime } = props

    const getMessageStr = (message) => {
        return (
            <div>
                {message.split("\r").map((i, key) => {
                    return <div key={key}>{i}</div>;
                })}
            </div>
        )
    }

    const getFirstLineMessage = (message) => {
        var messages = message.split("\r");
        if (messages.length == 0) {
            return (
                <div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {messages[0]}
                </div>
            )
        }
    }


    const headCells = [
        { id: 'timestamp', label: 'Time', display: true, width: 250 },
        { id: 'message', label: 'Message', display: true },
    ];

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('timestamp');
    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const handleChangePage = (event, newPage) => {

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <div className="w-full">
                <div className="flex flex-col md:flex-row sm:p-8" >
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="flex justify-center max-w-full relative">
                            <div className="flex flex-1 max-w-full">
                                <div className="flex flex-1 justify-center max-w-full">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                    <TableRow style={{ height: 30 }}>
                                                        <TableCell width={60} style={{ 'backgroundColor': '#cfcdc9' }}></TableCell>
                                                        {headCells.map((headCell) => (
                                                            <TableCell
                                                                key={headCell.id}
                                                                sortDirection={orderBy === headCell.id ? order : false}
                                                                width={headCell.width == undefined ? null : headCell.width}
                                                                style={{ 'backgroundColor': '#cfcdc9' }}
                                                            ><b>
                                                                    <TableSortLabel
                                                                        active={orderBy === headCell.id}
                                                                        direction={orderBy === headCell.id ? order : 'asc'}
                                                                        onClick={handleRequestSort(headCell.id)}
                                                                    >
                                                                        {headCell.label}
                                                                        {orderBy === headCell.id ? (
                                                                            <Box component="span" style={StaticStyles.visuallyHidden}>
                                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                            </Box>
                                                                        ) : null}
                                                                    </TableSortLabel>
                                                                </b>
                                                            </TableCell>
                                                        ))}

                                                    </TableRow>
                                                </TableHead>
                                                {CommonSorting.stableSort(scanCloudWatchLogs, order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((r, i) => (
                                                    <TableBody key={i}>
                                                        <TableRow key={i} height={30}>
                                                            <TableCell width={60}>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        if (collapseId == i + 1) {
                                                                            setCollapseId(0)
                                                                        }
                                                                        else {
                                                                            setCollapseId(i + 1)
                                                                        }
                                                                    }}
                                                                >
                                                                    {collapseId == i + 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell width={150}>{toDateTime(r.timestamp)}</TableCell>
                                                            <TableCell>
                                                                <span>{getFirstLineMessage(r.message)}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                        {collapseId == i + 1 ?
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell>{getMessageStr(r.message)}</TableCell>
                                                            </TableRow>

                                                            : null}
                                                    </TableBody>
                                                ))}
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={scanCloudWatchLogs.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanProcessingCloudWatchLogGrid;