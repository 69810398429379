
import * as THREE from "three";

export const hexToRgba = (hexValue) => {
    const hex = hexValue.replace(/^#/, ''); 
    const r = parseInt(hex.substring(0, 2), 16) / 255;
    const g = parseInt(hex.substring(2, 4), 16) / 255;
    const b = parseInt(hex.substring(4, 6), 16) / 255;
    return[Number(r.toFixed(1)), Number(g.toFixed(1)),Number(b.toFixed(1)),Number(1.0)];
  };

  export const  toHex = (threeColor) => {
    const c = new THREE.Color(threeColor[0], threeColor[1], threeColor[2]);
    return "#" + c.getHexString();
}
