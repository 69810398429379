import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    FormControl,
    TextField,
    DialogTitle,
    Button,
    DialogActions,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@mui/material";

function ClassificationSave(props) {

    const { role } = useSelector(({ auth }) => auth.user);
    const { handleOnClose, handleOnClickSave, handleOnUpdateClick, updateProfile, isUpdate } = props;
    const [isValid, setIsValid] = useState(false);
    const [profilesData, setProfilesData] = useState({
        name: '',
        isShare: false
    })


    useEffect(() => {
        if (isUpdate) {
            setProfilesData({ ...profilesData, name: updateProfile.name, isShare: updateProfile.isShare });
        }
    }, [isUpdate, profilesData, updateProfile])


    const handleOnChangeValues = (e) => {
        try {
            setProfilesData({ ...profilesData, [e.target.name]: e.target.value });
        } catch (error) {
            console.error(error);
        }
    }
    const validateForm = () => {
        try {
            if (profilesData.name) {
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }

    }

    const handleOnClickSaveOrUpdate = () => {
        setIsValid(false);
        if (validateForm()) {
            setIsValid(false);
            if (isUpdate) {
                handleOnUpdateClick(profilesData);
            } else {
                handleOnClickSave(profilesData);
            }

        }
        else {
            setIsValid(true);
        }

    }



    return (
        <Dialog open={true}
            onClose={() => handleOnClose()}
            maxWidth="md">

            <DialogTitle className="flex flex-col items-center justify-center w-full pb-4">Save Profile</DialogTitle>
            <div>
                <DialogContent className="flex flex-col items-center justify-center w-full py-0">
                    <FormControl className='mx-0 mt-12' sx={{ m: 1, minWidth: 300 }}>
                        <TextField
                            id="outlined-basic"
                            label="Profile"
                            name="name"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => handleOnChangeValues(e)}
                            value={profilesData.name}
                            error={(isValid && profilesData.name == '')}
                        />
                    </FormControl>
                    <FormControl className='w-full'>
                        <FormLabel id="demo-controlled-radio-buttons-group">Share with</FormLabel>
                        <RadioGroup row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name='isShare'
                            value={profilesData.isShare}
                            onChange={(e) => handleOnChangeValues(e)}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Me" />
                            <FormControlLabel className='ml-12' value={true} control={<Radio />} label={role.includes("admin") ? "Others" : "Organization"} />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </div>

            <DialogActions className="flex flex-row items-center justify-center w-full">
                <Button color="error" variant='outlined'
                    onClick={() => handleOnClose()}
                    style={{
                        boxSizing: 'border-box',
                        width: '124px',
                        background: '#E67260',
                        border: '1px solid #E67260',
                        borderRadius: '50px',
                        color: '#ffffff',
                        padding: '2px 15px'
                    }}
                >Cancel</Button>
                <Button color="secondary" variant='outlined'
                    onClick={() => handleOnClickSaveOrUpdate()}
                    style={{
                        boxSizing: 'border-box',
                        width: '124px',
                        background: '#6CCCD7',
                        border: '1px solid #6CCCD7',
                        borderRadius: '50px',
                        color: '#ffffff',
                        padding: '2px 15px'
                    }}
                > {isUpdate ? "Update" : "Save"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClassificationSave;