import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Dialog, DialogContent, Typography, DialogTitle } from "@mui/material";
import UploadFileStatus from "./main/apps/upload/UploadFileStatus";
import { useSelector } from "react-redux";

const LoadingScreen = () => {
  const { state } = useSelector(({ fuse }) => fuse.loader);
  const { showUploadPopup } = useSelector(({ admin }) => admin.socketApp);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={showUploadPopup}
        scroll={"paper"}
        fullScreen
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        BackdropProps={{
          classes: {
            root: "rgba(255,255,255,0.2)",
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h4"
            className="text-center mt-0 font-semibold text-18 sm:text-24"
          >
            App updates are going on please wait until upload completes.
          </Typography>
        </DialogTitle>
        <DialogContent dividers={"paper" === "paper"}>
          <UploadFileStatus></UploadFileStatus>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoadingScreen;
