/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Paper,
  Stack,
  Typography,
  Box,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import {
  getFilesById,
  getFilesByKey,
  setFiles,
  setFolderSize,
  createFolder,
  renameFile,
  renameFolder,
  deleteFolders,
  moveFiles,
  copyFiles,
  downloadFiles,
  setUpdateFolderState,
  setUpdateMoveState,
  setUpdateCopyState,
  getCurrentFolderKey,
  setCurrentFolderKey,
  getFolderSize,
} from "../admin/store/folderSlice";
import FolderDialog from "app/shared-components/s3folderdialogs/FolderDialog";
import UploadDialog from "app/shared-components/s3folderdialogs/UploadDialog";
import SERVICE_STATUS from "app/services/ServiceStatus";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import { startUploadFile } from "../upload/store/UploadSlice";
import swal from "sweetalert";
import ImageViewerDialog from "app/shared-components/s3folderdialogs/ImageViewerDialog";
import PDFViewerDialog from "app/shared-components/s3folderdialogs/PDFViewerDialog";
import TableView from "./TableView";
import GridView from "./GridView";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import JSONFileViewer from "app/shared-components/s3folderdialogs/JSONFileViewer";
import TextFileViewer from "app/shared-components/s3folderdialogs/TextFileViewer";
import CSVFileViewer from "app/shared-components/s3folderdialogs/CSVFileViewer";
import CommonStyles from "../styles/CommonStyles";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { MdOutlineFolderCopy } from "react-icons/md";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { RiDragMove2Fill } from "react-icons/ri";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { SIZES } from "app/main/apps/common/FileUtils";

// eslint-disable-next-line complexity
function FolderStructure(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const commonStyles = CommonStyles();
  const { id, type } = Object.keys(props).length == 0 ? params : props;
  const { filePathKey } = Object.keys(props).length == 0 ? "" : props;
  const { modelTitle } = Object.keys(props).length == 0 ? "" : props;
  const showCloseButton = Object.keys(props).length == 0 ? true : false;
  const { scan, site, device, config, project } = useSelector(
    ({ edit }) => edit.edit
  );

  const {
    files,
    updateFolderState,
    updateMoveState,
    updateCopyState,
    currentFolderKey,
    folderSize,
  } = useSelector(({ admin }) => admin.folderApp);
  const { role } = useSelector(({ auth }) => auth.user);

  const [currentCount, setCurrentCount] = useState(1);
  const [foldersList, setFoldersList] = useState([]);
  const [folderViewType, setFolderViewType] = useState("grid");
  const [imageInfo, setImageInfo] = useState({});
  const [allImagesInfo, setAllImageInfo] = useState({});
  const [showFullImage, setShowFullImage] = useState(false);
  const imagesExtensionList = ["jpg", "jpeg", "png"];
  const textExtensionList = ["txt", "log"];

  const [pdfURl, setPdfUrl] = useState("");
  const [showPdfViewer, setShowPdfViewer] = useState(false);

  const [jsonFileURL, setJsonFileURL] = useState("");
  const [showJsonViewer, setShowJsonViewer] = useState(false);

  const [textFileURL, setTextFileURL] = useState("");
  const [showTextViewer, setShowTextViewer] = useState(false);

  const [csvFileURL, setCsvFileURL] = useState("");
  const [showCsvViewer, setShowCsvViewer] = useState(false);

  const [gridPage, setGridPage] = useState(0);
  const [rowsGridPerPage, setRowsGridPerPage] = useState(40);

  const [fileIds, setFileIds] = useState([]);

  const [disableEdit, setDisableEdit] = useState(false);
  const [ctrlKey, setCtrlKey] = useState(false);

  const fetchData = () => {
    setTimeout(() => {
      setRowsGridPerPage(rowsGridPerPage + 40);
    }, 500);
  };

  const checkedFiles = (e, model) => {
    if (e.target.checked) {
      setFileIds((prevArray) => [...prevArray, { id: model.id, model: model }]);
    } else {
      var updateNewObj = [...fileIds];
      var index = updateNewObj.findIndex((x) => x.id == model.id);
      if (index != -1) {
        updateNewObj.splice(index, 1);
        setFileIds(updateNewObj);
      }
    }
  };

  const checkAll = (e) => {
    setFileIds([]);
    var fileObjs = [];
    if (e.target.checked) {
      for (var i = 0; i < files.length; i++) {
        if (
          moveFilesList.filter((x) => x.fileKey == files[i].fileKey).length ==
            0 &&
          copyFilesList.filter((x) => x.fileKey == files[i].fileKey).length == 0
        ) {
          fileObjs.push({ id: files[i].id, model: files[i] });
        }
      }
      setFileIds(fileObjs);
    }
  };

  if (filePathKey && filePathKey != undefined) {
    useEffect(() => {
      setDisableEdit(true);
      getDataFileKey();
    }, [dispatch]);
  } else if (id != undefined && id != null) {
    useEffect(() => {
      dispatch(getCurrentFolderKey({ type: type, id: id }));
      if (scan != undefined && scan.id == id) {
        getData();
      } else if (site != undefined && site.id == id) {
        getData();
      } else if (device != undefined && device.id == id) {
        getData();
      } else if (config != undefined && config.id == id) {
        getData();
      } else if ((project != undefined && project, id == id)) {
        getData();
      } else {
        navigate("/apps/appAdmin/sites");
      }
    }, [dispatch]);
  }

  const onClickImage = (event, imageInfoData) => {
    if (!event.ctrlKey) {
      setFileIds([]);
    } else {
      setCtrlKey(true);
    }
    if (
      moveFilesList.filter((x) => x.fileKey == imageInfoData.fileKey).length ==
        0 &&
      copyFilesList.filter((x) => x.fileKey == imageInfoData.fileKey).length ==
        0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var _length = updateNewObj.length;
        var index = updateNewObj.findIndex((x) => x.id == imageInfoData.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            if (event.ctrlKey) {
              updateNewObj.splice(index, 1);
              setFileIds(updateNewObj);
            } else {
              for (var i = 0; i < _length; i++) {
                if (updateNewObj.length != 1) {
                  updateNewObj.splice(0, updateNewObj.length);
                  setFileIds(updateNewObj);
                }
              }
              if (imageInfoData.id) {
                setIsSelected(true);
                setFileIds((prevArray) => [
                  ...prevArray,
                  { id: imageInfoData.id, model: imageInfoData },
                ]);
              }
            }
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [
              ...prevArray,
              { id: imageInfoData.id, model: imageInfoData },
            ]);
          }
        }
      } else {
        setImageInfo(imageInfoData);
        setAllImageInfo(
          files.filter(
            (x) => x.type == "file" && imagesExtensionList.includes(x.extension)
          )
        );
        setShowFullImage(true);
      }
    }
  };

  const onClickPDFFile = (e, model) => {
    if (!event.ctrlKey) {
      setFileIds([]);
    }
    if (
      moveFilesList.filter((x) => x.fileKey == model.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == model.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var _length = updateNewObj.length;
        var index = updateNewObj.findIndex((x) => x.id == model.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            if (event.ctrlKey) {
              updateNewObj.splice(index, 1);
              setFileIds(updateNewObj);
            } else {
              for (var i = 0; i < _length; i++) {
                if (updateNewObj.length != 1) {
                  updateNewObj.splice(0, updateNewObj.length);
                  setFileIds(updateNewObj);
                }
              }
              if (model.id) {
                setIsSelected(true);
                setFileIds((prevArray) => [
                  ...prevArray,
                  { id: model.id, model: model },
                ]);
              }
            }
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [
              ...prevArray,
              { id: model.id, model: model },
            ]);
          }
        }
      } else {
        const i = Math.floor(Math.log(model.contentLength) / Math.log(1024));
        const exactSize = Math.round(
          model.contentLength / Math.pow(1024, i),
          2
        );
        if (
          (exactSize < 50 && SIZES[i] == "MB") ||
          SIZES[i] == "KB" ||
          SIZES[i] == "Bytes"
        ) {
          setPdfUrl(model.fileUrl);
          setShowPdfViewer(true);
        } else {
          swal(
            "",
            "File size is greater than 50MB, Please download file to view the data",
            "warning"
          );
        }
      }
    }
  };

  const onClickJSONFile = (e, model) => {
    if (!event.ctrlKey) {
      setFileIds([]);
    }
    if (
      moveFilesList.filter((x) => x.fileKey == model.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == model.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var _length = updateNewObj.length;
        var index = updateNewObj.findIndex((x) => x.id == model.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
            if (event.ctrlKey) {
              updateNewObj.splice(index, 1);
              setFileIds(updateNewObj);
            } else {
              for (var i = 0; i < _length; i++) {
                if (updateNewObj.length != 1) {
                  updateNewObj.splice(0, updateNewObj.length);
                  setFileIds(updateNewObj);
                }
              }
              if (model.id) {
                setIsSelected(true);
                setFileIds((prevArray) => [
                  ...prevArray,
                  { id: model.id, model: model },
                ]);
              }
            }
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [
              ...prevArray,
              { id: model.id, model: model },
            ]);
          }
        }
      } else {
        const i = Math.floor(Math.log(model.contentLength) / Math.log(1024));
        const exactSize = Math.round(
          model.contentLength / Math.pow(1024, i),
          2
        );
        if (
          (exactSize < 50 && SIZES[i] == "MB") ||
          SIZES[i] == "KB" ||
          SIZES[i] == "Bytes"
        ) {
          setJsonFileURL(model.fileUrl);
          setShowJsonViewer(true);
        } else {
          swal(
            "",
            "File size is greater than 50MB, Please download file to view the data",
            "warning"
          );
        }
      }
    }
  };

  const onClickTextFile = (e, model) => {
    if (!event.ctrlKey) {
      setFileIds([]);
    }
    if (
      moveFilesList.filter((x) => x.fileKey == model.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == model.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var _length = updateNewObj.length;
        var index = updateNewObj.findIndex((x) => x.id == model.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            if (event.ctrlKey) {
              updateNewObj.splice(index, 1);
              setFileIds(updateNewObj);
            } else {
              for (var i = 0; i < _length; i++) {
                if (updateNewObj.length != 1) {
                  updateNewObj.splice(0, updateNewObj.length);
                  setFileIds(updateNewObj);
                }
              }
              if (model.id) {
                setIsSelected(true);
                setFileIds((prevArray) => [
                  ...prevArray,
                  { id: model.id, model: model },
                ]);
              }
            }
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [
              ...prevArray,
              { id: model.id, model: model },
            ]);
          }
        }
      } else {
        const i = Math.floor(Math.log(model.contentLength) / Math.log(1024));
        const exactSize = Math.round(
          model.contentLength / Math.pow(1024, i),
          2
        );
        if (
          (exactSize < 50 && SIZES[i] == "MB") ||
          SIZES[i] == "KB" ||
          SIZES[i] == "Bytes"
        ) {
          setTextFileURL(model.fileUrl);
          setShowTextViewer(true);
        } else {
          swal(
            "",
            "File size is greater than 50MB, Please download file to view the data",
            "warning"
          );
        }
      }
    }
  };

  const onClickCSVFile = (e, model) => {
    if (!event.ctrlKey) {
      setFileIds([]);
    }
    if (
      moveFilesList.filter((x) => x.fileKey == model.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == model.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var _length = updateNewObj.length;
        var index = updateNewObj.findIndex((x) => x.id == model.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            if (event.ctrlKey) {
              updateNewObj.splice(index, 1);
              setFileIds(updateNewObj);
            } else {
              for (var i = 0; i < _length; i++) {
                if (updateNewObj.length != 1) {
                  updateNewObj.splice(0, updateNewObj.length);
                  setFileIds(updateNewObj);
                }
              }
              if (model.id) {
                setIsSelected(true);
                setFileIds((prevArray) => [
                  ...prevArray,
                  { id: model.id, model: model },
                ]);
              }
            }
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [
              ...prevArray,
              { id: model.id, model: model },
            ]);
          }
        }
      } else {
        const i = Math.floor(Math.log(model.contentLength) / Math.log(1024));
        const exactSize = Math.round(
          model.contentLength / Math.pow(1024, i),
          2
        );
        if (
          (exactSize < 50 && SIZES[i] == "MB") ||
          SIZES[i] == "KB" ||
          SIZES[i] == "Bytes"
        ) {
          setCsvFileURL(model.fileUrl);
          setShowCsvViewer(true);
        } else {
          swal(
            "",
            "File size is greater than 50MB, Please download file to view the data",
            "warning"
          );
        }
      }
    }
  };

  const onCloseImageInfo = () => {
    setShowFullImage(false);
  };
  const onClosePDFViewer = () => {
    setShowPdfViewer(false);
    setPdfUrl("");
  };

  const onCloseJSONViewer = () => {
    setShowJsonViewer(false);
    setJsonFileURL("");
  };

  const onCloseTextFileViewer = () => {
    setShowTextViewer(false);
    textFileURL("");
  };

  const onCloseCSVFileViewer = () => {
    setShowCsvViewer(false);
    setCsvFileURL("");
  };

  useEffect(() => {
    dispatch(setCurrentFolderKey(""));
    dispatch(setFiles([]));
    dispatch(setFolderSize(0));
  }, [dispatch]);

  const getData = async () => {
    dispatch(setFiles([]));
    dispatch(setFolderSize(0));
    setIsSelected(false);
    var name = getName();
    const buttonObj = { name: name, key: "", count: 0 };
    setFoldersList([buttonObj]);
    dispatch(
      getFilesById({ type: type, id: id, callback: getFolderSizeDetails })
    );
  };
  const getName = () => {
    if (scan) {
      if (scan.name) {
        return scan.name;
      } else {
        return `Scan ${scan.id}`;
      }
    } else if (site) {
      if (site.name) {
        return site.name;
      } else {
        return `Site ${site.id}`;
      }
    } else if (device) {
      if (device.name) {
        return device.name;
      } else {
        return `Device ${device.id}`;
      }
    } else if (config) {
      if (config.name) {
        return config.name;
      } else {
        return `Config ${config.id}`;
      }
    } else if (project) {
      if (project.name) {
        return project.name;
      } else {
        return `Config ${project.id}`;
      }
    } else {
      return "";
    }
  };
  const getDataFileKey = () => {
    setIsSelected(false);
    dispatch(setFiles([]));
    dispatch(setFolderSize(0));
    const buttonObj = { name: modelTitle, key: "", count: 0 };
    setFoldersList([buttonObj]);
    dispatch(
      getFilesByKey({
        data: { fileKey: filePathKey, resourceType: type, resourceId: id },
        callback: getFolderSizeDetails,
      })
    );
  };

  const getFileExactSize = (size) => {
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    var exactSize = Math.round(size * 10) / 10 + " " + fSExt[i];
    return exactSize;
  };

  const handleOnFileClickGetData = (model, i) => {
    setIsSelected(false);
    setFileIds([]);
    dispatch(setFiles([]));
    dispatch(setFolderSize(0));
    if (i != 0) {
      const updatedArray = [...foldersList];
      updatedArray.splice(i + 1, updatedArray.length - 1);
      setFoldersList(updatedArray);
    }
    if (foldersList.filter((x) => x.key == model.fileKey).length == 0) {
      const buttonObj = {
        name: model.name,
        key: model.fileKey,
        count: currentCount,
        model: model,
      };
      setFoldersList((prevArray) => [...prevArray, buttonObj]);
      setCurrentCount(currentCount + 1);
    }
    model = { ...model, resourceType: type, resourceId: id };
    dispatch(getFilesByKey({ data: model, callback: getFolderSizeDetails }));
  };

  const [dialogType, setDialogType] = useState("");
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  const onClickCreateFolder = () => {
    setDialogType("createfolder");
    setShowCreateFolder(true);
  };

  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const onClickRename = () => {
    var renameValueText = "";
    if (fileIds.length > 0) {
      if (fileIds[0].model.type == "folder") {
        setDialogType("renamefolder");
        renameValueText = fileIds[0].model.name.substring(
          0,
          fileIds[0].model.name.length - 1
        );
      } else {
        setDialogType("renamefile");
        renameValueText = fileIds[0].model.name;
      }
      setRenameValue(renameValueText);
      setShowRenameDialog(true);
    }
  };

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const onClickDelete = () => {
    setDialogType("delete");
    setShowDeleteDialog(true);
  };

  const onClickDownload = () => {
    var downloadObj = [];
    for (var i = 0; i < fileIds.length; i++) {
      var modelObj = fileIds[i].model;
      modelObj = { ...modelObj, resourceType: type };
      downloadObj.push(modelObj);
    }
    dispatch(downloadFiles(downloadObj));
  };

  const [showUploadDialod, setShowUploadDialog] = useState(false);
  const onClickUpload = () => {
    setShowUploadDialog(true);
  };

  const [isMoveFilesEnabled, setIsMoveFilesEnabled] = useState(false);
  const [moveFilesList, setMoveFilesList] = useState([]);
  const onClickMoveFiles = () => {
    setIsSelected(false);
    var moveObjs = [];
    for (var i = 0; i < fileIds.length; i++) {
      moveObjs.push(fileIds[i].model);
    }
    setMoveFilesList(moveObjs);
    setIsMoveFilesEnabled(true);
    setFileIds([]);
  };

  const onClickCancelMoving = () => {
    setIsMoveFilesEnabled(false);
    setMoveFilesList([]);
  };

  const onGridPageChange = (event, newPage) => {
    fetchData();
  };

  // eslint-disable-next-line complexity
  const moveFilesAction = () => {
    if (moveFilesList.length > 0) {
      var moveFilekey = "";
      var moveFileKeyList = moveFilesList[0].fileKey.split("/");
      if (moveFilesList[0].type == "file") {
        moveFileKeyList.splice(moveFileKeyList.length - 1, 1);
      } else if (moveFilesList[0].type == "folder") {
        moveFileKeyList.splice(moveFileKeyList.length - 2, 2);
      }
      moveFilekey = moveFileKeyList.join("/");
      const index = foldersList.findIndex((x) => x.count == currentCount - 1);
      if (index != -1) {
        const currentFolder = foldersList[index];
        if (currentFolder.key) {
          if (currentFolder.key != moveFilekey + "/") {
            var apiMoveObj = [];
            for (var i = 0; i < moveFilesList.length; i++) {
              var apiObj = moveFilesList[i];
              apiObj = {
                ...apiObj,
                from: moveFilekey + "/",
                to: currentFolder.key,
              };

              apiMoveObj.push(apiObj);
            }
            dispatch(setLoaderProgress(true));
            dispatch(moveFiles(apiMoveObj));
          }
        } else if (filePathKey && filePathKey != undefined) {
          if (filePathKey != moveFilekey + "/") {
            const apiMoveObj = [];
            for (let i = 0; i < moveFilesList.length; i++) {
              let apiObj = moveFilesList[i];
              apiObj = {
                ...apiObj,
                from: moveFilekey + "/",
                to: filePathKey,
              };

              apiMoveObj.push(apiObj);
            }
            dispatch(setLoaderProgress(true));
            dispatch(moveFiles(apiMoveObj));
          }
        } else if (currentFolderKey != moveFilekey) {
          let apiMoveObj = [];
          for (let i = 0; i < moveFilesList.length; i++) {
            let apiObj = moveFilesList[i];
            apiObj = {
              ...apiObj,
              from: moveFilekey + "/",
              to: currentFolderKey,
            };
            apiMoveObj.push(apiObj);
          }
          dispatch(setLoaderProgress(true));
          dispatch(moveFiles(apiMoveObj));
        }
      }
    }
  };

  const [isCopyFilesEnabled, setIsCopyFilesEnabled] = useState(false);
  const [copyFilesList, setCopyFilesList] = useState([]);
  const onClickCopyFiles = () => {
    setIsSelected(false);
    var copyObjs = [];
    for (var i = 0; i < fileIds.length; i++) {
      copyObjs.push(fileIds[i].model);
    }
    setCopyFilesList(copyObjs);
    setIsCopyFilesEnabled(true);
    setFileIds([]);
  };

  const onClickCancelCopying = () => {
    setIsCopyFilesEnabled(false);
    setCopyFilesList([]);
  };

  // eslint-disable-next-line complexity
  const copyFilesAction = () => {
    if (copyFilesList.length > 0) {
      var copyFilekey = "";
      var copyFileKeyList = copyFilesList[0].fileKey.split("/");
      if (copyFilesList[0].type == "file") {
        copyFileKeyList.splice(copyFileKeyList.length - 1, 1);
      } else if (copyFilesList[0].type == "folder") {
        copyFileKeyList.splice(copyFileKeyList.length - 2, 2);
      }
      copyFilekey = copyFileKeyList.join("/");
      const index = foldersList.findIndex((x) => x.count == currentCount - 1);
      if (index != -1) {
        const currentFolder = foldersList[index];
        if (currentFolder.key) {
          if (currentFolder.key != copyFilekey + "/") {
            var apiCopyObj = [];
            for (var i = 0; i < copyFilesList.length; i++) {
              var apiObj = copyFilesList[i];
              apiObj = {
                ...apiObj,
                from: copyFilekey + "/",
                to: currentFolder.key,
              };

              apiCopyObj.push(apiObj);
            }
            dispatch(setLoaderProgress(true));
            dispatch(copyFiles(apiCopyObj));
          }
        } else if (filePathKey && filePathKey != undefined) {
          if (filePathKey != copyFilekey + "/") {
            let apiCopyObj = [];
            for (let i = 0; i < copyFilesList.length; i++) {
              let apiObj = copyFilesList[i];
              apiObj = {
                ...apiObj,
                from: copyFilekey + "/",
                to: filePathKey,
              };

              apiCopyObj.push(apiObj);
            }
            dispatch(setLoaderProgress(true));
            dispatch(copyFiles(apiCopyObj));
          }
        } else if (currentFolderKey != copyFilekey) {
          let apiCopyObj = [];
          for (let i = 0; i < copyFilesList.length; i++) {
            let apiObj = copyFilesList[i];
            apiObj = {
              ...apiObj,
              from: copyFilekey + "/",
              to: currentFolderKey,
            };
            apiCopyObj.push(apiObj);
          }
          dispatch(setLoaderProgress(true));
          dispatch(copyFiles(apiCopyObj));
        }
      }
    }
  };

  const reloadData = () => {
    setFileIds([]);
    const index = foldersList.findIndex((x) => x.count == currentCount - 1);
    if (index != -1) {
      const currentFolder = foldersList[index];
      if (currentFolder.key) {
        dispatch(
          getFilesByKey({
            data: {
              fileKey: currentFolder.key,
              resourceType: type,
              resourceId: id,
            },
            callback: getFolderSizeDetails,
          })
        );
      } else {
        if (filePathKey && filePathKey != undefined) {
          dispatch(
            getFilesByKey({
              data: {
                fileKey: filePathKey,
                resourceType: type,
                resourceId: id,
              },
              callback: getFolderSizeDetails,
            })
          );
        } else {
          dispatch(
            getFilesById({ type: type, id: id, callback: getFolderSizeDetails })
          );
        }
      }
    }
  };

  const getFolderSizeDetails = (fileObj) => {
    dispatch(setFolderSize(0));
    var fileKey = "";
    fileKey = fileObj.fileKey.substring(0, fileObj.fileKey.lastIndexOf("/"));
    if (fileObj.type == "folder") {
      fileKey = fileKey.substring(0, fileKey.lastIndexOf("/"));
    }
    dispatch(getFolderSize({ fileKey: fileKey + "/", resourceType: type }));
  };

  const createFolderAction = (folderName) => {
    setShowCreateFolder(false);
    var folderFileKey = "";
    var uploadId = "";
    if (files.filter((x) => x.name == folderName + "/").length == 0) {
      const index = foldersList.findIndex((x) => x.count == currentCount - 1);
      if (index != -1) {
        const currentFolder = foldersList[index];
        if (currentFolder.key) {
          folderFileKey = currentFolder.key + folderName;
        } else {
          if (filePathKey && filePathKey != undefined) {
            folderFileKey = filePathKey + folderName;
          } else {
            folderFileKey = folderName;
            uploadId = id;
          }
        }
      }
      if (folderFileKey) {
        dispatch(setLoaderProgress(true));
        dispatch(
          createFolder({ id: uploadId, type: type, fileKey: folderFileKey })
        );
      }
    }
  };

  const getFileNameAlreadyExists = (fileName) => {
    var allFiles = [...files];
    return allFiles.filter((x) => x.name == fileName).length;
  };
  const renameFileAction = (newFileName) => {
    setShowRenameDialog(false);
    if (fileIds.length > 0) {
      var renameApiObj = fileIds[0].model;
      var oldFileNameKey = fileIds[0].model.fileKey;
      var fileSplit = fileIds[0].model.fileKey.split("/");
      fileSplit.splice(fileSplit.length - 1, 1);
      fileSplit.push(newFileName);
      var newFileNameKey = fileSplit.join("/");
      dispatch(setLoaderProgress(true));
      renameApiObj = {
        ...renameApiObj,
        from: oldFileNameKey,
        to: newFileNameKey,
        lastModified: "",
      };
      dispatch(renameFile(renameApiObj));
    }
  };
  const getFolderNameAlreadyExists = (fileName) => {
    var allFiles = [...files];
    return allFiles.filter((x) => x.name == fileName + "/").length;
  };
  const renameFolderAction = (newFileName) => {
    setShowRenameDialog(false);
    if (fileIds.length > 0) {
      var oldFileNameKey = fileIds[0].model.fileKey;
      var fileSplit = fileIds[0].model.fileKey.split("/");
      fileSplit.splice(fileSplit.length - 2, 2);
      fileSplit.push(newFileName);
      var newFileNameKey = fileSplit.join("/");
      dispatch(setLoaderProgress(true));
      dispatch(
        renameFolder({ from: oldFileNameKey, to: newFileNameKey + "/" })
      );
    }
  };

  const deleteAction = () => {
    setShowDeleteDialog(false);
    var deleteObj = [];
    for (var i = 0; i < fileIds.length; i++) {
      deleteObj.push(fileIds[i].model);
    }
    dispatch(setLoaderProgress(true));
    dispatch(deleteFolders(deleteObj));
  };

  const getpath = (path) => {
    const str = path.split("/");
    str.splice(0, 2);
    return str.join("/");
  };

  const uploadFilesAction = (files) => {
    var isUploadByKey = false;
    var folderFileKey = "";
    const index = foldersList.findIndex((x) => x.count == currentCount - 1);
    if (index != -1) {
      const currentFolder = foldersList[index];
      if (currentFolder.key) {
        folderFileKey = currentFolder.key;
        isUploadByKey = true;
      } else {
        if (filePathKey && filePathKey != undefined) {
          folderFileKey = filePathKey;
          isUploadByKey = true;
        } else {
          isUploadByKey = false;
        }
      }
    }
    for (var i = 0; i < files.length; i++) {
      var uploadingFile = files[i].file;
      if (uploadingFile != null && uploadingFile != undefined) {
        var uploadPath = uploadingFile.path.includes("/")
          ? getpath(uploadingFile.path)
          : uploadingFile.path;
        var uploadData = {
          typeId: id,
          name: "",
          file: uploadingFile,
          isPrimary: false,
          type: type,
          uploadKey: isUploadByKey
            ? folderFileKey + uploadPath
            : `${currentFolderKey}${uploadPath}`,
        };
        dispatch(startUploadFile(uploadData));
      }
    }
    setShowUploadDialog(false);
  };
  if (updateFolderState == SERVICE_STATUS.fulfilled) {
    if (dialogType == "createfolder") {
      swal("", "Created Folder successfully", "success");
    }
    if (dialogType == "renamefile" || dialogType == "renamefolder") {
      swal("", "Renamed successfully", "success");
    }

    if (dialogType == "delete") {
      swal("", "Deleted successfully", "success");
    }
    dispatch(setUpdateFolderState(""));
    reloadData();
    dispatch(setLoaderProgress(false));
  }
  if (updateFolderState == SERVICE_STATUS.rejected) {
    if (dialogType == "createfolder") {
      swal("", "Failed to Create Folder", "error");
    }
    if (dialogType == "renamefile" || dialogType == "renamefolder") {
      swal("", "Failed to Rename", "error");
    }

    if (dialogType == "delete") {
      swal("", "Failed to Delete", "error");
    }
    dispatch(setUpdateFolderState(""));
    dispatch(setLoaderProgress(false));
  }

  if (updateMoveState == SERVICE_STATUS.fulfilled) {
    swal("", "Moved successfully", "success");
    setMoveFilesList([]);
    setIsMoveFilesEnabled(false);
    dispatch(setUpdateMoveState(""));
    reloadData();
    dispatch(setLoaderProgress(false));
  }
  if (updateMoveState == SERVICE_STATUS.rejected) {
    swal("", "Failed to Move", "error");
    setMoveFilesList([]);
    setIsMoveFilesEnabled(false);
    dispatch(setUpdateMoveState(""));
    reloadData();
    dispatch(setLoaderProgress(false));
  }

  if (updateCopyState == SERVICE_STATUS.fulfilled) {
    swal("", "Copied successfully", "success");
    setCopyFilesList([]);
    setIsCopyFilesEnabled(false);
    dispatch(setUpdateCopyState(""));
    reloadData();
    dispatch(setLoaderProgress(false));
  }
  if (updateCopyState == SERVICE_STATUS.rejected) {
    swal("", "Failed to Copy", "error");
    setCopyFilesList([]);
    setIsCopyFilesEnabled(false);
    dispatch(setUpdateCopyState(""));
    reloadData();
    dispatch(setLoaderProgress(false));
  }

  const onClose = () => {
    setShowCreateFolder(false);
    setShowRenameDialog(false);
    setShowUploadDialog(false);
    setShowDeleteDialog(false);
    setRenameValue("");
  };

  const getCount = () => {
    var count = 0;
    if (moveFilesList.length > 0) {
      var moveFilekey = "";
      var moveFileKeyList = moveFilesList[0].fileKey.split("/");
      if (moveFilesList[0].type == "file") {
        moveFileKeyList.splice(moveFileKeyList.length - 1, 1);
      } else if (moveFilesList[0].type == "folder") {
        moveFileKeyList.splice(moveFileKeyList.length - 2, 2);
      }
      moveFilekey = moveFileKeyList.join("/");
      const index = foldersList.findIndex((x) => x.count == currentCount - 1);
      if (index != -1) {
        const currentFolder = foldersList[index];
        if (currentFolder.key) {
          if (currentFolder.key == moveFilekey + "/") {
            count = count + moveFilesList.length;
          }
        }
      } else {
        if (filePathKey && filePathKey != undefined) {
          if (filePathKey != moveFilekey + "/") {
            count = count + moveFilesList.length;
          }
        } else {
          if (files.length > 0) {
            if (currentFolderKey != moveFilekey) {
              count = count + moveFilesList.length;
            }
          }
        }
      }
    }
    if (copyFilesList.length > 0) {
      var copyFilekey = "";
      var copyFileKeyList = copyFilesList[0].fileKey.split("/");
      if (copyFilesList[0].type == "file") {
        copyFileKeyList.splice(copyFileKeyList.length - 1, 1);
      } else if (copyFilesList[0].type == "folder") {
        copyFileKeyList.splice(copyFileKeyList.length - 2, 2);
      }
      copyFilekey = copyFileKeyList.join("/");
      const index = foldersList.findIndex((x) => x.count == currentCount - 1);
      if (index != -1) {
        const currentFolder = foldersList[index];
        if (currentFolder.key) {
          if (currentFolder.key == copyFilekey + "/") {
            count = count + copyFilesList.length;
          }
        }
      } else {
        if (filePathKey && filePathKey != undefined) {
          if (filePathKey != copyFilekey + "/") {
            count = count + copyFilesList.length;
          }
        } else {
          if (files.length > 0) {
            if (currentFolderKey != copyFilekey) {
              count = count + copyFilesList.length;
            }
          }
        }
      }
    }
    return count;
  };
  const [isSelected, setIsSelected] = useState(false);

  const handelOnFolderSelect = (a) => {
    if (
      moveFilesList.filter((x) => x.fileKey == a.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == a.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var index = updateNewObj.findIndex((x) => x.id == a.id);
        if (isCopyFilesEnabled || isMoveFilesEnabled) {
          handleOnFileClickGetData(a, 0);
        } else {
          if (index != -1 && fileIds.length == 1) {
            setIsSelected(false);
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else if (index != -1) {
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [...prevArray, { id: a.id, model: a }]);
          }
        }
      } else {
        handleOnFileClickGetData(a, 0);
      }
    }
  };

  const handelOnOtherFilesClick = (a) => {
    if (
      moveFilesList.filter((x) => x.fileKey == a.fileKey).length == 0 &&
      copyFilesList.filter((x) => x.fileKey == a.fileKey).length == 0
    ) {
      if (event.detail === 1) {
        var updateNewObj = [...fileIds];
        var index = updateNewObj.findIndex((x) => x.id == a.id);
        if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
          if (index != -1) {
            updateNewObj.splice(index, 1);
            setFileIds(updateNewObj);
          } else {
            setIsSelected(true);
            setFileIds((prevArray) => [...prevArray, { id: a.id, model: a }]);
          }
        }
      }
    }
  };

  const handelOnSelectAllClick = () => {
    if (!isCopyFilesEnabled && !isMoveFilesEnabled) {
      setIsSelected(true);
      setFileIds([]);
      var fileObjs = [];
      for (var i = 0; i < files.length; i++) {
        if (
          moveFilesList.filter((x) => x.fileKey == files[i].fileKey).length ==
            0 &&
          copyFilesList.filter((x) => x.fileKey == files[i].fileKey).length == 0
        ) {
          fileObjs.push({ id: files[i].id, model: files[i] });
        }
      }
      setFileIds(fileObjs);
    }
  };

  const handleOnCloseButton = () => {
    if (window.history.state.idx == 0) {
      navigate("/apps/dashboards/sites");
    } else {
      navigate(-1);
    }
  };

  const disableSelectAllButton = () => {
    if (isMoveFilesEnabled || isCopyFilesEnabled) {
      return true;
    }
    if (files.length != 0 && fileIds.length != files.length - getCount()) {
      return false;
    }
    return false;
  };

  const showSelectedAllCheck = () => {
    if (isMoveFilesEnabled || isCopyFilesEnabled) {
      return false;
    }
    if (files.length == 0) {
      return false;
    }
    if (files.length != 0 && fileIds.length != files.length - getCount()) {
      return false;
    }
    return true;
  };

  const updateFileIds = (_fileIds) => {
    setFileIds(_fileIds);
  };
  const isSetCtrlKey = (type) => {
    setCtrlKey(type);
  };

  return (
    <div className="w-full px-24 -mx-4">
      <div className="flex flex-col md:flex-row sm:p-8">
        <div className="flex flex-1 flex-col min-w-0">
          <div className="flex justify-center max-w-full relative">
            <div className="flex flex-1 max-w-full">
              <div className="flex flex-1 justify-center max-w-full">
                <div style={{ height: "100%", width: "100%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}>
                        <div className="flex items-center justify-center">
                          <Paper
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === "dark"
                                  ? "#1A2027"
                                  : "#fff",
                            }}
                            square
                          >
                            <Stack spacing={2} direction="row">
                              <TableRowsIcon
                                className={`${
                                  folderViewType == "line"
                                    ? commonStyles.primaryColor
                                    : ""
                                }`}
                                onClick={() => {
                                  setFolderViewType("line");
                                }}
                              ></TableRowsIcon>
                              <GridViewIcon
                                className={`${
                                  folderViewType == "grid"
                                    ? commonStyles.primaryColor
                                    : ""
                                }`}
                                onClick={() => {
                                  setFolderViewType("grid");
                                }}
                              ></GridViewIcon>
                            </Stack>
                          </Paper>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        {showCloseButton && (
                          <div className="flex items-center justify-end">
                            <Button
                              variant="outlined"
                              style={{
                                boxSizing: "border-box",
                                background: "#E67260",
                                border: "1px solid #E67260",
                                borderRadius: "50px",
                                color: "#ffffff",
                              }}
                              onClick={() => handleOnCloseButton()}
                              startIcon={<HighlightOffIcon />}
                            >
                              Close
                            </Button>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <div className="flex justify-center items-center align-center w-full"></div>
                  <div className="w-full flex flex-row mb-8">
                    {foldersList.map((a) => (
                      <div className="flex mt-16 flex-row" key={a.name}>
                        <Button
                          variant={
                            currentCount - 1 == a.count
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="w-full mx-auto py-8"
                          aria-label="Register"
                          type="button"
                          onClick={() => {
                            if (currentCount - 1 != a.count) {
                              setFileIds([]);
                              setCurrentCount(a.count + 1);
                              if (a.key) {
                                handleOnFileClickGetData(a.model, a.count);
                              } else {
                                if (filePathKey && filePathKey != undefined) {
                                  getDataFileKey();
                                } else {
                                  getData();
                                }
                              }
                            }
                          }}
                        >
                          {a.name}
                        </Button>
                        {currentCount - 1 != a.count ? (
                          <ArrowRightIcon className="mt-8"></ArrowRightIcon>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-between">
                    <Stack
                      spacing={2}
                      className="mb-8 justify-start items-center"
                      direction="row"
                    >
                      <Typography>
                        <b>Total Size :</b>
                      </Typography>
                      {files.length != 0 && (
                        <>
                          {folderSize != 0 ? (
                            <Typography>
                              <b>{getFileExactSize(folderSize)}</b>
                            </Typography>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => getFolderSizeDetails(files[0])}
                            >
                              Calculate
                            </Button>
                          )}
                        </>
                      )}
                    </Stack>
                    <Stack
                      spacing={2}
                      className="mb-8 justify-end items-center"
                      direction="row"
                    >
                      <div
                        style={{
                          backgroundColor: "#DBDADA",
                          borderRadius: "5px 5px 5px 5px",
                        }}
                      >
                        {folderViewType == "grid" && (
                          <>
                            <Tooltip title="Select All">
                              <IconButton
                                disabled={disableSelectAllButton()}
                                onClick={() => {
                                  showSelectedAllCheck()
                                    ? setFileIds([])
                                    : handelOnSelectAllClick();
                                }}
                                variant="outlined"
                                style={{
                                  color: disableSelectAllButton()
                                    ? "#0000008a"
                                    : "#000000",
                                }}
                              >
                                {showSelectedAllCheck() ? (
                                  <CheckBoxOutlinedIcon />
                                ) : (
                                  <CheckBoxOutlineBlankOutlinedIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {role.includes("admin") && !disableEdit && (
                          <Tooltip title="Upload">
                            <IconButton
                              onClick={() => onClickUpload()}
                              style={{ color: "#000000" }}
                            >
                              <UploadOutlinedIcon />{" "}
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Download">
                          <IconButton
                            disabled={fileIds.length == 0}
                            style={{
                              color:
                                fileIds.length == 0 ? "#0000008a" : "#000000",
                            }}
                            onClick={() => onClickDownload()}
                          >
                            <DownloadOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                        {role.includes("admin") && !disableEdit && (
                          <>
                            <Tooltip title="Delete">
                              <IconButton
                                disabled={fileIds.length == 0}
                                style={{
                                  color:
                                    fileIds.length == 0
                                      ? "#0000008a"
                                      : "#000000",
                                }}
                                onClick={() => onClickDelete()}
                              >
                                <DeleteOutlineOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Create Folder">
                              <IconButton
                                onClick={() => onClickCreateFolder()}
                                style={{ color: "#000000" }}
                              >
                                <CreateNewFolderOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Refresh">
                              <IconButton
                                onClick={() => reloadData()}
                                style={{ color: "#000000" }}
                              >
                                <RefreshOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {role.includes("admin") && !disableEdit && (
                          <>
                            <Tooltip title="Rename">
                              <IconButton
                                disabled={fileIds.length != 1}
                                style={{
                                  color:
                                    fileIds.length != 1
                                      ? "#0000008a"
                                      : "#000000",
                                }}
                                onClick={() => onClickRename()}
                              >
                                <DriveFileRenameOutlineOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>

                            {isCopyFilesEnabled ? (
                              <>
                                <Tooltip title="Paste Here">
                                  <IconButton
                                    onClick={() => copyFilesAction()}
                                    style={{ color: "#000000" }}
                                  >
                                    <ContentPasteGoOutlinedIcon />{" "}
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel Copying">
                                  <IconButton
                                    onClick={() => onClickCancelCopying()}
                                    style={{ color: "#000000" }}
                                  >
                                    <CancelOutlinedIcon />{" "}
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip title="Copy">
                                <IconButton
                                  disabled={fileIds.length == 0}
                                  style={{
                                    color:
                                      fileIds.length == 0
                                        ? "#0000008a"
                                        : "#000000",
                                  }}
                                  onClick={() => onClickCopyFiles()}
                                >
                                  <MdOutlineFolderCopy />{" "}
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {isMoveFilesEnabled ? (
                          <>
                            <Tooltip title="Move Here">
                              <IconButton
                                onClick={() => moveFilesAction()}
                                style={{ color: "#000000" }}
                              >
                                <DriveFileMoveOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel Moving">
                              <IconButton
                                onClick={() => onClickCancelMoving()}
                                style={{ color: "#000000" }}
                              >
                                <CancelOutlinedIcon />{" "}
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <Tooltip title="Move">
                            <IconButton
                              disabled={fileIds.length == 0}
                              style={{
                                color:
                                  fileIds.length == 0 ? "#0000008a" : "#000000",
                              }}
                              onClick={() => onClickMoveFiles()}
                            >
                              <RiDragMove2Fill />{" "}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </Stack>
                  </div>
                  {folderViewType == "line" ? (
                    <>
                      <TableView
                        files={files}
                        isMoveFilesEnabled={isMoveFilesEnabled}
                        isCopyFilesEnabled={isCopyFilesEnabled}
                        fileIds={fileIds}
                        moveFilesList={moveFilesList}
                        copyFilesList={copyFilesList}
                        handleOnFileClickGetData={handleOnFileClickGetData}
                        checkedFiles={checkedFiles}
                        checkAll={checkAll}
                        getFileExactSize={getFileExactSize}
                        getCount={getCount}
                      ></TableView>
                    </>
                  ) : (
                    <>
                      {files.length == 0 && (
                        <div className="justify-center items-center">
                          <h1>No Files</h1>
                        </div>
                      )}

                      <GridView
                        files={files}
                        fileIds={fileIds}
                        moveFilesList={moveFilesList}
                        copyFilesList={copyFilesList}
                        isSelected={isSelected}
                        isCopyFilesEnabled={isCopyFilesEnabled}
                        isMoveFilesEnabled={isMoveFilesEnabled}
                        imagesExtensionList={imagesExtensionList}
                        textExtensionList={textExtensionList}
                        handleOnFileClickGetData={handleOnFileClickGetData}
                        handelOnFolderSelect={handelOnFolderSelect}
                        onClickPDFFile={onClickPDFFile}
                        onClickJSONFile={onClickJSONFile}
                        onClickTextFile={onClickTextFile}
                        onClickCSVFile={onClickCSVFile}
                        onClickImage={onClickImage}
                        handelOnOtherFilesClick={handelOnOtherFilesClick}
                        gridPage={gridPage}
                        rowsGridPerPage={rowsGridPerPage}
                        onGridPageChange={onGridPageChange}
                        handelOnSelectAllClick={handelOnSelectAllClick}
                        onClickCopyFiles={onClickCopyFiles}
                        pasteFilesAction={copyFilesAction}
                        moveFilesAction={moveFilesAction}
                        onClickMoveFiles={onClickMoveFiles}
                        setRowsGridPerPage={setRowsGridPerPage}
                        updateFileIds={updateFileIds}
                        ctrlKey={ctrlKey}
                        isSetCtrlKey={isSetCtrlKey}
                      ></GridView>
                    </>
                  )}
                </div>
                {showCreateFolder && (
                  <FolderDialog
                    type={dialogType}
                    onClose={onClose}
                    onAction={createFolderAction}
                    defaultTextValue=""
                  ></FolderDialog>
                )}
                {showRenameDialog && dialogType == "renamefile" && (
                  <FolderDialog
                    type={dialogType}
                    onClose={onClose}
                    onAction={renameFileAction}
                    defaultTextValue={renameValue}
                    fileNameAlreadyExists={getFileNameAlreadyExists}
                  ></FolderDialog>
                )}
                {showRenameDialog && dialogType == "renamefolder" && (
                  <FolderDialog
                    type={dialogType}
                    onClose={onClose}
                    onAction={renameFolderAction}
                    defaultTextValue={renameValue}
                    fileNameAlreadyExists={getFolderNameAlreadyExists}
                  ></FolderDialog>
                )}
                {showDeleteDialog && (
                  <FolderDialog
                    type={dialogType}
                    onClose={onClose}
                    onAction={deleteAction}
                    defaultTextValue={renameValue}
                  ></FolderDialog>
                )}
                {showUploadDialod && (
                  <UploadDialog
                    onClose={onClose}
                    onAction={uploadFilesAction}
                  ></UploadDialog>
                )}

                {showFullImage && (
                  <ImageViewerDialog
                    imageInfo={imageInfo}
                    onClose={onCloseImageInfo}
                    images={allImagesInfo}
                  ></ImageViewerDialog>
                )}

                {showPdfViewer && (
                  <PDFViewerDialog
                    src={pdfURl}
                    onClose={onClosePDFViewer}
                  ></PDFViewerDialog>
                )}
                {showJsonViewer && (
                  <JSONFileViewer
                    src={jsonFileURL}
                    onClose={onCloseJSONViewer}
                  />
                )}
                {showTextViewer && (
                  <TextFileViewer
                    src={textFileURL}
                    onClose={onCloseTextFileViewer}
                  />
                )}
                {showCsvViewer && (
                  <CSVFileViewer
                    src={csvFileURL}
                    onClose={onCloseCSVFileViewer}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FolderStructure;
