import React, { useEffect, useRef } from 'react';
import { TextField, OutlinedInput, Tooltip, IconButton, InputAdornment, FormControl } from '@mui/material';
import styled from "@emotion/styled";
import { ColorPicker } from 'material-ui-color';
import Checkbox from '@mui/material/Checkbox';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClassificationActions from '../../common/ClassificationActions';



const Classification = styled.div`
	display: flex;
`

function SiteClassificationViewEdit(props) {

	const { classificationList, onToggleClassificationVisibility, handleOnChangeClassificationName, changeClassificationColor,
		handleOnChangeClassificationKey, handleOnClickRemoveClassification, handleOnUpArrowClick, handleOnDownArrowClick } = props;

	const classificationRef = useRef();

	useEffect(() => {
		ClassificationActions.removeBackgroundClass(classificationRef.current);
	}, []);




	return (
		<div ref={classificationRef}>
			{classificationList.map((c, i) => (
				<Classification key={i}>

					<Checkbox checked={c.isChecked} sx={{
						color: '#6CCCD7',
						'&.Mui-checked': {
							color: '#6CCCD7',
						},
					}}
						onChange={() => onToggleClassificationVisibility(i)}
						id={"" + c.key} />
					<TextField
						sx={{ width: { sm: 30, md: 150 }, "& .MuiInputBase-root": { height: 30 } }}
						id={"" + c.key}
						variant="outlined"
						className='mt-4 mr-16'
						disabled={c.key == -1}
						value={c.classificationName}
						error={(c.isError && c.classificationName == '')}
						onChange={(e) => { handleOnChangeClassificationName(e, i) }}
					/>

					<ColorPicker
						value={c.colorCode}
						onChange={colorCode => changeClassificationColor(colorCode, i)}
						hideTextfield={true} />
					{c.key != -1 &&
						<>
							<FormControl variant="outlined">
								<OutlinedInput
									sx={{ width: { sm: 30, md: 90 } }}
									value={c.key}
									onChange={(e) => { handleOnChangeClassificationKey(e, i) }}
									error={c.isDuplicateKey}
									size="small"
									endAdornment={
										<InputAdornment position="end" style={{ 'postion': 'relative' }}>
											<div className='flex flex-col items-center'>

												<IconButton
													aria-label="toggle password visibility"
													edge="end"
													disabled={c.key >= 255}
													onClick={() => { handleOnUpArrowClick(i) }}
													style={{ 'postion': 'absolute', 'right': '0px', 'top': '5px', 'padding': '0px' }}
												>
													{<ArrowDropUpIcon />}
												</IconButton>
												<IconButton
													aria-label="toggle password visibility"
													edge="end"
													disabled={c.key <= 0}
													onClick={() => { handleOnDownArrowClick(i) }}
													style={{ 'postion': 'absolute', 'right': '0px', 'bottom': '5px', 'padding': '0px' }}
												>
													{<ArrowDropDownIcon />}
												</IconButton>
											</div>
										</InputAdornment>
									}
								></OutlinedInput>
							</FormControl>
							<Tooltip title='Remove'>
								<IconButton color="error"
									onClick={() => { handleOnClickRemoveClassification(i) }}
								><DoDisturbOnOutlinedIcon /></IconButton>
							</Tooltip>
						</>
					}

				</Classification>
			))}

		</div>

	)
}
export default SiteClassificationViewEdit;