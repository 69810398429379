import {
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import swal from "sweetalert";
import { makeStyles, createStyles } from "@mui/styles";
import {
  getUsersSiteFoldersDetails,
  saveFolderDetails,
  deleteFolderDetails,
  updateFolderDetails,
  updateFolderScanDetails,
  updateFolderPriorityOrder,
} from "./store/scansSlice";
import CommonScans from "./folderScans/CommonScans";
import FolderScans from "./folderScans/FolderScans";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { arrayMove } from "react-sortable-hoc";
import ErrorMessageConstants from "../constants/ErrorMessageConstants";
import { useDispatch } from "react-redux";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const useStyles = makeStyles(() =>
  createStyles({
    customScroll: {
      "&::-webkit-scrollbar": {
        width: "8px",
      },
    },
    ToolbariconColor: {
      color: "#000000",
    },
    text: {
      fontWeight: "bold",
    },
  })
);
function ToolbarScans(props) {
  const {
    scans,
    loadedScanIds,
    findLoadedScan,
    zoomToPointCloud,
    zoomOutPointCloud,
    togglePointCloud,
    currentViewer,
    siteId,
    selectAllCompletedScans,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAddFolderName, setIsAddFolderName] = useState(false);
  const [allScans, setAllScans] = useState([]);
  const [folders, setFolders] = useState([]);

  const scansRenderRef = useRef();

  const [isEditClick, setIsEditClick] = useState(false);
  const [foldersData, setFoldersData] = useState([]);
  const [folderData, setFolderData] = useState({
    name: "",
  });

  const [draggingScanId, setDraggingScanId] = useState(0);
  const [draggingFolderId, setDraggingFolderId] = useState(0);

  useEffect(() => {
    if (scans) {
      setAllScans(scans);
      manageAllFolders();
    }
  }, [scans]);

  useEffect(() => {
    if (siteId) {
      getFolderDetails();
    }
  }, [siteId]);

  useEffect(() => {
    manageAllFolders();
  }, [folders]);

  const manageAllFolders = () => {
    if (folders.length > 0) {
      let _folders = [...folders];
      let _scans = [...scans];
      let _foldersData = [...foldersData];

      for (const folder of _folders) {
        folder.scans = [];
        for (const scanId of folder.scanIds) {
          const scanIndex = _scans.findIndex((scan) => scan.id == scanId);
          if (scanIndex != -1) {
            folder.scans.push(_scans[scanIndex]);
            _scans.splice(scanIndex, 1);
          }
        }
      }
      _folders.forEach((folder, folderIndex) => {
        var folderDataIndex = _foldersData.findIndex(
          (folderData) => folderData.id == folder.id
        );
        if (folderDataIndex != -1) {
          if (_foldersData[folderDataIndex].isExpand) {
            _folders[folderIndex].isExpand =
              _foldersData[folderDataIndex].isExpand;
          } else {
            _folders[folderIndex].isExpand = false;
          }
        } else {
          _folders[folderIndex].isExpand = false;
        }
      });
      setFoldersData(_folders);
      setAllScans(_scans);
    } else {
      setAllScans(scans);
    }
  };

  const handleOnOpenFolder = (folderId, value) => {
    const _foldersData = [...foldersData];
    let folderIndex = _foldersData.findIndex((folder) => folder.id == folderId);
    if (folderIndex != -1) {
      _foldersData[folderIndex].isExpand = value;
    }
    setFoldersData(_foldersData);
  };

  const handleScanDragStart = (scanId) => {
    setDraggingScanId(scanId);
    setDraggingFolderId(0);
  };

  const handleFolderDragStart = (folderId) => {
    setDraggingScanId(0);
    setDraggingFolderId(folderId);
  };

  const handleOnChangeFolderName = (e) => {
    setFolderData({ ...folderData, [e.target.name]: e.target.value });
  };
  const handleOnChangeFolderNameEdit = (folderName) => {
    try {
      setFolderData({ ...folderData, name: folderName });
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateFolderSuccess = () => {
    getFolderDetails();
    setFolderData({ name: "" });
  };

  const onUpdateFolderFailure = (message) => {
    if (message) {
      swal("", message, "error");
    } else {
      swal("", ErrorMessageConstants.defaultErrormessage, "error");
    }
    setFolderData({ name: "" });
  };

  const handleOnSaveClick = () => {
    try {
      MixPanelIntegration.EventClicks("Add Folder Save Click");
      setIsAddFolderName(false);
      const data = {
        siteId: siteId,
        folderName: folderData.name.trim(),
      };

      if (data.folderName === "") {
        swal("", `Folder name '${data.folderName}' is invalid`, "error");
        return;
      }

      const requestData = {
        data: data,
        successCallback: onUpdateFolderSuccess,
        failureCallback: onUpdateFolderFailure,
      };
      dispatch(saveFolderDetails(requestData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnUpdateFolderClick = (folder) => {
    try {
      MixPanelIntegration.EventClicks("Edit Folder Save Click");
      setIsAddFolderName(false);
      setIsEditClick(false);
      const data = {
        id: folder.id,
        folderName: folderData.name.trim(),
        siteId: siteId,
      };
      if (data.folderName === "") {
        swal("", `Folder name '${data.folderName}' is invalid`, "error");
        return;
      }

      var requestData = {
        data: data,
        successCallback: onUpdateFolderSuccess,
        failureCallback: onUpdateFolderFailure,
      };
      dispatch(updateFolderDetails(requestData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnDeleteFolderClick = (folderId) => {
    try {
      const updateFolder = deleteFolderDetails(folderId);
      Promise.resolve(updateFolder)
        .then((response) => {
          if (response.status == 200) {
            getFolderDetails();
          }
        })
        .catch((error) => {
          console.warn("Cannot retrieve data", error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getFolderDetails = () => {
    const folderInfo = getUsersSiteFoldersDetails(siteId);
    Promise.resolve(folderInfo)
      .then((response) => {
        if (response.status === 200) {
          setFolders(response.data);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  // eslint-disable-next-line complexity
  const handleOnScanFolderDrop = (typeId, dropType) => {
    var _folders = [...folders];
    var _allScans = [...allScans];

    // move up common variables
    let dropingScanFolderId,
      dropingFolderScanIdIndex,
      oldFolderId,
      oldFolderIndex,
      folderScanIndex,
      folderScanIdIndex,
      data;

    if (dropType == "scan") {
      if (draggingScanId != typeId) {
        if (draggingScanId > 0) {
          var scanId = typeId;
          var draggingScanFolderId = 0;
          var draggingFolderScanIdIndex = -1;
          for (let i = 0; i < _folders.length; i++) {
            draggingFolderScanIdIndex = _folders[i].scanIds.findIndex(
              (id) => id == draggingScanId
            );
            if (draggingFolderScanIdIndex != -1) {
              draggingScanFolderId = _folders[i].id;
              break;
            }
          }
          if (draggingScanFolderId > 0) {
            dropingScanFolderId = 0;
            dropingFolderScanIdIndex = -1;
            for (let i = 0; i < _folders.length; i++) {
              dropingFolderScanIdIndex = _folders[i].scanIds.findIndex(
                (id) => id == scanId
              );
              if (dropingFolderScanIdIndex != -1) {
                dropingScanFolderId = _folders[i].id;
                break;
              }
            }
            if (dropingScanFolderId > 0) {
              if (draggingScanFolderId != dropingScanFolderId) {
                oldFolderId = 0;
                folderScanIdIndex = -1;
                var folderIndex = _folders.findIndex(
                  (folder) => folder.id == dropingScanFolderId
                );
                if (folderIndex != -1) {
                  for (let i = 0; i < _folders.length; i++) {
                    folderScanIdIndex = _folders[i].scanIds.findIndex(
                      (scanId) => scanId == draggingScanId
                    );
                    if (folderScanIdIndex != -1) {
                      oldFolderId = _folders[i].id;
                      break;
                    }
                  }
                  if (oldFolderId > 0) {
                    if (dropingScanFolderId != oldFolderId) {
                      oldFolderIndex = _folders.findIndex(
                        (folder) => folder.id == oldFolderId
                      );
                      if (oldFolderIndex != -1) {
                        _folders[oldFolderIndex].scanIds.splice(
                          folderScanIdIndex,
                          1
                        );
                        _folders[folderIndex].scanIds.push(draggingScanId);
                      }
                    }
                  }
                }
              }
            }
          } else {
            dropingScanFolderId = 0;
            dropingFolderScanIdIndex = -1;
            for (let i = 0; i < _folders.length; i++) {
              dropingFolderScanIdIndex = _folders[i].scanIds.findIndex(
                (id) => id == scanId
              );
              if (dropingFolderScanIdIndex != -1) {
                dropingScanFolderId = _folders[i].id;
                break;
              }
            }
            if (dropingScanFolderId > 0) {
              const scanIndex = _allScans.findIndex(
                (scan) => scan.id == draggingScanId
              );
              const folderIndex = _folders.findIndex(
                (folder) => folder.id == dropingScanFolderId
              );
              if (scanIndex != -1) {
                if (folderIndex != -1) {
                  folderScanIndex = _folders[folderIndex].scanIds.findIndex(
                    (id) => id == draggingScanId
                  );
                  if (folderScanIndex === -1) {
                    if (scanIndex != -1) {
                      _allScans.splice(scanIndex, 1);
                      _folders[folderIndex].scanIds.push(draggingScanId);
                    }
                  }
                }
              }
            }
          }
          data = {
            siteId: siteId,
            folders: _folders,
          };
          updateSiteFolderScanDetails(data);
        }
      }
    } else if (dropType == "folder") {
      let folderId = typeId;
      if (draggingScanId > 0) {
        const scanIndex = _allScans.findIndex(
          (scan) => scan.id == draggingScanId
        );
        const folderIndex = _folders.findIndex(
          (folder) => folder.id == folderId
        );
        if (scanIndex != -1) {
          if (folderIndex != -1) {
            folderScanIndex = _folders[folderIndex].scanIds.findIndex(
              (id) => id == draggingScanId
            );
            if (folderScanIndex === -1) {
              if (scanIndex != -1) {
                _allScans.splice(scanIndex, 1);
                _folders[folderIndex].scanIds.push(draggingScanId);
              }
            }
          }
        } else {
          oldFolderId = 0;
          folderScanIdIndex = -1;
          for (let i = 0; i < _folders.length; i++) {
            folderScanIdIndex = _folders[i].scanIds.findIndex(
              (scanId) => scanId == draggingScanId
            );
            if (folderScanIdIndex != -1) {
              oldFolderId = _folders[i].id;
              break;
            }
          }
          if (oldFolderId > 0) {
            if (folderId != oldFolderId) {
              oldFolderIndex = _folders.findIndex(
                (folder) => folder.id == oldFolderId
              );
              if (oldFolderIndex != -1) {
                _folders[oldFolderIndex].scanIds.splice(folderScanIdIndex, 1);
                _folders[folderIndex].scanIds.push(draggingScanId);
              }
            }
          }
        }
        data = {
          siteId: siteId,
          folders: _folders,
        };
        updateSiteFolderScanDetails(data);
      } else if (draggingFolderId > 0 && draggingFolderId != folderId) {
        oldFolderIndex = _folders.findIndex(
          (folder) => folder.id == draggingFolderId
        );
        let newFolderIndex = _folders.findIndex(
          (folder) => folder.id == folderId
        );
        if (
          oldFolderIndex != -1 &&
          newFolderIndex != -1 &&
          oldFolderIndex != newFolderIndex
        ) {
          _folders = arrayMove(_folders, oldFolderIndex, newFolderIndex);
          var folderIds = _folders.map((x) => x.id);
          data = {
            siteId: siteId,
            folders: folderIds,
          };
          updatePriorityOrder(data);
        }
      }
    }
  };

  const handleOnIsEditClick = (e) => {
    setIsEditClick(e);
    setIsAddFolderName(false);
  };

  const updateSiteFolderScanDetails = (data) => {
    let foldersDetails = updateFolderScanDetails(data);
    Promise.resolve(foldersDetails)
      .then((response) => {
        if (response.status == 200) {
          getFolderDetails();
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  const updatePriorityOrder = (data) => {
    let FolderData = updateFolderPriorityOrder(data);
    Promise.resolve(FolderData)
      .then((response) => {
        if (response.status == 200) {
          getFolderDetails();
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  const getScansDisplayListCount = () => {
    let count = allScans.length + foldersData.length;
    let folders = [...foldersData];
    folders.forEach((folder) => {
      if (folder.isExpand) {
        count = count + folder.scans.length;
      }
    });
    return count;
  };

  const getAllCompletedScansCount = () => {
    if (currentViewer === "2D") {
      return scans.filter((scan) => scan.mapboxStatus === 2).length;
    } else {
      return scans.filter((scan) => scan.potreeStatus === 2).length;
    }
  };

  const handleOnFolderClick = () => {
    MixPanelIntegration.EventClicks("Add Folder Click");
    setIsAddFolderName(true);
    scansRenderRef.current.scrollTop = 0;
  };

  const handleOnAddFolderCancelClick = () => {
    MixPanelIntegration.EventClicks("Add Folder Cancel Click");
    setIsAddFolderName(false);
  };

  return (
    <div>
      <div
        className="flex items-center justify-between py-8"
        style={{ backgroundColor: "#D9D9D954" }}
      >
        <div
          className="flex flex-row justify-start items-center"
          style={{
            marginLeft: "30px",
            height: "25px",
          }}
        >
          <Checkbox
            checked={loadedScanIds.length == getAllCompletedScansCount()}
            style={{ color: "#6cccd7", width: "20px" }}
            onChange={selectAllCompletedScans}
            className="p-4 mt-48 ml-16"
          />
          <Typography
            style={{
              marginTop: "50px",
              marginLeft: "8px",
              color: "#0000000",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Captures
          </Typography>
        </div>
        <div
          className="flex"
          style={{ marginTop: "50px", marginRight: "18px" }}
        >
          <Tooltip title="Add Folder">
            {/* <div style={{ position: 'relative', cursor: (isEditClick || isAddFolderName) ? 'default' : 'pointer' }}
                            onClick={() => {
                                setIsAddFolderName(true)
                                scansRenderRef.current.scrollTop = 0
                            }}
                            disabled={isEditClick || isAddFolderName}
                        >
                            <div>
                                <svg width="30" height="20" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="13" />
                                    <g id="3D Page" clip-path="url(#clip0_0_1)">
                                        <rect width="1771" height="1078" transform="translate(-546 -276)" fill="white" />
                                        <rect id="Rectangle 114" x="-292" y="-7" width="357" height="831" fill="#F8F8F8" />
                                        <rect id="Rectangle 680" x="-292" y="-5" width="339" height="25" fill="#D9D9D9" fill-opacity="0.33" />
                                        <g id="Group 101">
                                            <path id="Vector" d="M0 1.625C0 0.73125 0.9 0 2 0H9L11 1.625H18C18.5304 1.625 19.0391 1.7962 19.4142 2.10095C19.7893 2.4057 20 2.81902 20 3.25V11.375C20 11.806 19.7893 12.2193 19.4142 12.524C19.0391 12.8288 18.5304 13 18 13H2C1.46957 13 0.960859 12.8288 0.585786 12.524C0.210714 12.2193 0 11.806 0 11.375V1.625ZM2"
                                                fill={isEditClick || isAddFolderName ? "#00000042" : "#454545"} />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1">
                                            <rect width="1771" height="1078" fill="white" transform="translate(-546 -276)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="flex justify-end items-end">
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: '#ffffff',
                                        marginTop: '-8px',
                                        marginRight: '-1px',
                                        border: `1px solid ${(isEditClick || isAddFolderName) ? "#00000042" : "#454545"}`
                                    }} className="flex justify-center items-center">
                                    <AddOutlinedIcon
                                        sx={{ width: 7, height: 7 }}
                                        style={{ color: (isEditClick || isAddFolderName) ? "#00000042" : "#454545", fontWeight: 700 }}
                                    />
                                </div>
                            </div>
                        </div> */}
            <IconButton
              onClick={() => {
                handleOnFolderClick();
              }}
              disabled={isEditClick || isAddFolderName}
              style={{
                color: isEditClick || isAddFolderName ? "#00000042" : "#454545",
                width: "15px",
                padding: "0px",
              }}
            >
              <CreateNewFolderOutlinedIcon></CreateNewFolderOutlinedIcon>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        ref={scansRenderRef}
        className={`pb-8 ${classes.customScroll}`}
        style={{
          overflowY: "auto",
          height: getScansDisplayListCount() > 4 ? "150px" : "auto",
        }}
      >
        {isAddFolderName && (
          <div className="flex items-center justify-between mt-8">
            <div className="flex">
              <TextField
                name="name"
                placeholder="Folder Name"
                id="outlined-size-small"
                size="small"
                autoFocus
                className="ml-32 pl-4"
                onChange={handleOnChangeFolderName}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleOnSaveClick();
                  }
                }}
              />
            </div>
            <div className="flex">
              <Tooltip title="Save">
                <IconButton
                  style={{ color: "#556A6D" }}
                  onClick={() => {
                    handleOnSaveClick();
                  }}
                >
                  <SaveOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  // color="error"
                  style={{ color: "#E0604C" }}
                  onClick={() => {
                    handleOnAddFolderCancelClick();
                  }}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        {folders.length > 0 && (
          <FolderScans
            folders={foldersData}
            isOpenFolder={handleOnOpenFolder}
            // isCloseFolder ={handleFolderClose}
            handleOnScanFolderDrop={handleOnScanFolderDrop}
            handleOnChangeFolderNameEdit={handleOnChangeFolderNameEdit}
            folderName={folderData.name}
            handleOnChangeFolderName={handleOnChangeFolderName}
            handleOnUpdateFolderClick={handleOnUpdateFolderClick}
            handleOnDeleteFolderClick={handleOnDeleteFolderClick}
            loadedScanIds={loadedScanIds}
            findLoadedScan={findLoadedScan}
            zoomToPointCloud={zoomToPointCloud}
            zoomOutPointCloud={zoomOutPointCloud}
            togglePointCloud={togglePointCloud}
            currentViewer={currentViewer}
            handleScanDragStart={handleScanDragStart}
            handleFolderDragStart={handleFolderDragStart}
            isAddFolderName={isAddFolderName}
            handleOnIsEditClick={handleOnIsEditClick}
          />
        )}

        {allScans.length > 0 && allScans != null && allScans != undefined && (
          <CommonScans
            scans={allScans}
            loadedScanIds={loadedScanIds}
            findLoadedScan={findLoadedScan}
            zoomToPointCloud={zoomToPointCloud}
            zoomOutPointCloud={zoomOutPointCloud}
            togglePointCloud={togglePointCloud}
            currentViewer={currentViewer}
            handleScanDragStart={handleScanDragStart}
            isEnableDrop={false}
            isFromFolder={false}
          />
        )}
      </div>
    </div>
  );
}

export default ToolbarScans;
