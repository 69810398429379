import React from 'react';
import { useRef } from "react";
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Tooltip, IconButton, TextField } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CommonScans from './CommonScans';
import styled from '@emotion/styled';


export const MenuContextContainer = styled.div`
  border: 1px solid #ffffff2d;
  padding: 0px;
  box-sizing: border-box;
  background-color: ${(props) => ((props.highlightFolderIndex != -1 && props.highlightFolderIndex == props.index && props.showHighlightFolderIndex == true) ? '#73DCE8' : props.index % 2 == 0 ? '#F8F8F8' : '#D9D9D954')};
`;

function FolderScan(props) {

    const {
        folder,
        index,
        handleOnRightClick,
        highlightFolderIndex,
        showHighlightFolderIndex,
        setHighlightFolderIndex,
        setShowHighlightFolderIndex,
        handleOnDrop,
        handleFolderDragStart,
        folderId,
        isEditMode,
        editfolderName,
        handleOnChangeFolderName,
        handleOnUpdateClick,
        handleOnCloseClick,
        loadedScanIds,
        findLoadedScan,
        zoomToPointCloud,
        zoomOutPointCloud,
        togglePointCloud,
        currentViewer,
        handleScanDragStart,
        handleOnScanFolderDrop,
        isOpenFolder
    } = props;

    const menuContextContainerRef = useRef();

    const handleOnFolderRightClick = (e) => {
        e.preventDefault();
        handleOnRightClick(e, folder, menuContextContainerRef)
    }
    return (
        <>
            {(folderId == folder.id && isEditMode) ?
                <div className="flex items-center justify-between py-4" style={{ backgroundColor: index % 2 == 0 ? '#F8F8F8' : '#D9D9D954' }}>
                    <div className="flex">
                        <TextField
                            name="name"
                            placeholder="Folder Name"
                            id="outlined-size-small"
                            size="small"
                            autoFocus
                            className="ml-32 pl-4"
                            value={editfolderName}
                            onChange={handleOnChangeFolderName}
                        />
                    </div>
                    <div className="flex">
                        <Tooltip title='Update'>
                            <IconButton
                                style={{ color: '#556A6D' }}
                                onClick={() => { handleOnUpdateClick(folder) }}
                            ><SaveOutlinedIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title='Cancel'>
                            <IconButton
                                style={{ color: '#E0604C' }}
                                onClick={() => { handleOnCloseClick(folder) }}
                            ><CancelOutlinedIcon /></IconButton>
                        </Tooltip>
                    </div>
                </div>
                :

                <MenuContextContainer
                    onContextMenu={(e) => { handleOnFolderRightClick(e) }}
                    index={index}
                    highlightFolderIndex={highlightFolderIndex}
                    showHighlightFolderIndex={showHighlightFolderIndex}
                    ref={menuContextContainerRef}
                    className='py-4'
                >
                    <div key={index} className="flex items-center justify-between ml-8" draggable
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrop={(e) => {
                            setHighlightFolderIndex(-1);
                            setShowHighlightFolderIndex(true);
                            handleOnDrop(e, folder.id)
                        }}
                        onDragStart={() => {
                            setShowHighlightFolderIndex(false);
                            handleFolderDragStart(folder.id)
                        }}
                        onDragOverCapture={() => {
                            setHighlightFolderIndex(index)

                        }}
                        onDragLeaveCapture={() => {
                            setHighlightFolderIndex(-1)
                        }}
                    >

                        <div className="flex items-center">
                            {!folder.isExpand ?
                                <ArrowRightRoundedIcon
                                    sx={{ width: 35, height: 35 }}
                                    style={{ color: '#4F4F4F' }}
                                    onClick={() => isOpenFolder(folder.id, true)} />
                                : <ArrowDropDownRoundedIcon
                                    sx={{ width: 35, height: 35 }}
                                    style={{ color: '#4F4F4F' }}
                                    onClick={() => isOpenFolder(folder.id, false)} />
                            }
                            <span style={{ fontWeight: '400', fontSize: '13px' }} key={index}>
                                {folder.folderName}
                            </span>
                        </div>
                    </div>
                </MenuContextContainer>
            }

            {folder.isExpand && folder.scans.length > 0 &&
                <CommonScans
                    scans={folder.scans}
                    loadedScanIds={loadedScanIds}
                    findLoadedScan={findLoadedScan}
                    zoomToPointCloud={zoomToPointCloud}
                    zoomOutPointCloud={zoomOutPointCloud}
                    togglePointCloud={togglePointCloud}
                    currentViewer={currentViewer}
                    handleScanDragStart={handleScanDragStart}
                    isEnableDrop={true}
                    handleOnScanFolderDrop={handleOnScanFolderDrop}
                    isFromFolder={true}
                />
            }
        </>
    )
}

export default FolderScan;