import { configureStore } from "@reduxjs/toolkit";
import createReducerManager from "./rootReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import { staticReducers, asyncReducers } from "./rootReducer";

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (logEntry) => !logEntry.error });

  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage,
};
const reducerManager = createReducerManager(staticReducers);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, reducerManager.reduce);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

// export const store = createStore(
//   persistedReducer,
//   /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares,thunk)),
// );

export const persistor = persistStore(store);
store.subscribe(() => {
  // const reduxStore = store.getState();
});

store.asyncReducers = {};
store.reducerManager = reducerManager;

// const createAyncReducer = (asyncReducers) => (state, action) => {
//   const combinedReducer = combineReducers({
//     ...asyncReducers,
//   });
//   return combinedReducer(state, action);
// }

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;

  //store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

Object.entries(asyncReducers).forEach(([key, value]) => {
  store.reducerManager.add(key, value);
});

export default { store, persistStore };
