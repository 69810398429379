/* eslint-disable complexity */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Box,
  TableSortLabel,
  TablePagination,
  TableBody,
  Tooltip,
  IconButton,
  Grid,
  Collapse,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateScan, removeScan } from "../admin/store/scanSlice";
import UPLOAD_TYPE from "../upload/UploadType";
import { setEditData } from "../admin/editStore/editSlice";
import SERVICE_STATUS from "app/services/ServiceStatus";
import {
  setRemoveScanState,
  handleUpdateScanState,
} from "../admin/store/scanSlice";
import FolderStructure from "../folder-view/FolderStructure";
import { setFiles } from "../admin/store/folderSlice";
import RestoreIcon from "@mui/icons-material/Restore";
import { revertBackScanPotreeFile } from "../admin/store/scanSlice";
import CommonStyles from "../styles/CommonStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ScanDetailsStyles from "../admin/styles/ScanDetailsStyles";
import {
  checkFilesExistInFolder,
  downloadScanFolder,
  downloadScanFile,
} from "./store/scansSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FileDownloadErrorDailog from "./FileDownloadErrorDailog";
import CommonSorting from "../common/CommonSorting";
import StaticStyles from "../styles/StaticStyles";
import swal from "sweetalert";

function ScanDetails(props) {
  const {
    site,
    reloadSiteData,
    fileName,
    setFileName,
    fileDownloadStatusDialog,
    setFileDownloadStatusDialog,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { role } = useSelector(({ auth }) => auth.user);
  const { updateScanState, removeScanState } = useSelector(
    ({ admin }) => admin.scanApp
  );

  const handleDownload = (scan, type) => {
    const data = {
      key: getFileKey(scan, type),
    };
    const fileDownloadStatusApi = checkFilesExistInFolder(data);
    Promise.resolve(fileDownloadStatusApi)
      .then((response) => {
        if (response.data.isFileExist && response.data.isFileExist == true) {
          if (response.data.fileDownloadLink) {
            const a = document.createElement("a");
            a.href = response.data.fileDownloadLink;
            a.style.display = "none";
            document.body.append(a);
            a.click();

            a.remove();
          } else {
            var downloadRequestData = {
              scanId: scan.id,
              type: type,
            };
            dispatch(downloadScanFile(downloadRequestData));
          }
        } else {
          setFileDownloadStatusDialog(true);
          setFileName(type);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  const commonStyles = CommonStyles();
  const classes = ScanDetailsStyles();
  const [collapseId, setCollapseId] = useState(0);
  const [modelTitle, setModelTitle] = useState("");
  const [modelScanPath, setModelScanPath] = useState("");

  const baseHeadCells = [
    { id: "name", label: "Name", display: true },
    { id: "date", label: "Date", display: true },
    { id: "area", label: "Acres", display: true },
    { id: "processingStatus", label: "Status", display: true },
    { id: "latestProcessingVersion", label: "Version", display: true },
  ];

  const scanHeadCells = role?.includes("admin")
    ? [{ id: "id", label: "ID", display: true }, ...baseHeadCells]
    : baseHeadCells;

  const [scanOrder, setScanOrder] = useState("desc");
  const [scanOrderBy, setScanOrderBy] = useState("date");
  const handleScanRequestSort = (property) => () => {
    const isAsc = scanOrderBy === property && scanOrder === "asc";
    setScanOrder(isAsc ? "desc" : "asc");
    setScanOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showScanDownloadButton = (scan) => {
    const isDownloadAllowed =
      role?.includes("admin") || scan.processingStatus === "completed";

    return (
      <Tooltip title="Download">
        <Button
          style={{
            color: isDownloadAllowed ? "#4D4D4F" : "#A9A9A9",
            minWidth: 38,
            height: 38,
            padding: 0,
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={
            isDownloadAllowed
              ? () => onClickDownload(scan, UPLOAD_TYPE.scanoutputs)
              : null
          }
          disabled={!isDownloadAllowed}
        >
          <DownloadOutlinedIcon />
        </Button>
      </Tooltip>
    );
  };

  const handleScanDelete = (scan) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Do you want to delete the scan?",
        icon: "warning",
        buttons: {
          delete: {
            text: "Delete",
            value: "delete",
          },
          deletePermanently: {
            text: "Delete Permanently",
            value: "deletePermanently",
            visible: role.includes("admin"),
          },
          cancel: true,
        },
      }).then((value) => {
        if (value == "delete") {
          var updateObj = { ...scan };
          updateObj = { ...updateObj, isDeleted: true };
          dispatch(updateScan(updateObj));
        } else if (value == "deletePermanently") {
          dispatch(removeScan(scan.id));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [scanId, setScanId] = useState(0);
  const handleScanFolderClick = (scan) => {
    console.log("clicked folder");
    try {
      dispatch(setFiles([]));
      setScanId(scan.id);
      setOpenModelPopup(true);
      dispatch(setEditData({ scan: scan }));
      if (!role.includes("admin")) {
        setModelTitle(scan.name);
        setModelScanPath(scan.scanPaths.outputsPath);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnEditScanClick = (scan) => {
    dispatch(setEditData({ scan: scan }));
    navigate(`/apps/sites/scanedit/${scan.id}`);
  };

  const handleOnCreateScanClick = () => {
    navigate(`/apps/scan/scancreation/${site.id}`);
  };

  if (
    updateScanState == SERVICE_STATUS.fulfilled ||
    removeScanState == SERVICE_STATUS.fulfilled
  ) {
    try {
      dispatch(setRemoveScanState(""));
      dispatch(handleUpdateScanState(""));
      props.onUpdateSite();
    } catch (error) {
      console.error(error);
    }
  }
  const [openModelPopup, setOpenModelPopup] = useState(false);
  const handleOnBackButtonClick = () => {
    setOpenModelPopup(false);
    setModelTitle("");
    setModelScanPath("");
  };
  const successCallBack = () => {
    reloadSiteData();
  };
  const handleOnScanRevert = (scan) => {
    var requestData = {
      scanId: scan.id,
      successCallBack: successCallBack,
    };
    dispatch(revertBackScanPotreeFile(requestData));
  };

  const handlePointCloudFolderClick = (scan) => {
    try {
      dispatch(setFiles([]));
      setScanId(scan.id);
      setOpenModelPopup(true);
      dispatch(setEditData({ scan: scan }));
      setModelTitle("Point Cloud");
      setModelScanPath(extractDirectory(scan.scanPaths.pointCloudPath));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrthoFolderClick = (scan) => {
    try {
      dispatch(setFiles([]));
      setScanId(scan.id);
      setOpenModelPopup(true);
      dispatch(setEditData({ scan: scan }));
      setModelTitle("Ortho");
      setModelScanPath(scan.scanPaths.orthoPath);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePanoramaFolderClick = (scan) => {
    try {
      dispatch(setFiles([]));
      setScanId(scan.id);
      setOpenModelPopup(true);
      dispatch(setEditData({ scan: scan }));
      setModelTitle("Panorama");
      setModelScanPath(scan.scanPaths.panoramasPath);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlsCaddFolderClick = (scan) => {
    try {
      dispatch(setFiles([]));
      setScanId(scan.id);
      setOpenModelPopup(true);
      dispatch(setEditData({ scan: scan }));
      setModelTitle("Point Cloud");
      setModelScanPath(extractDirectory(scan.scanPaths.pointCloudPath));
    } catch (error) {
      console.error(error);
    }
  };

  const extractDirectory = (filePath) => {
    const parts = filePath.split("/");
    parts.pop();
    return parts.join("/") + "/";
  };

  /** Download folder or file */
  const onClickDownload = async (scan, key) => {
    const data = {
      key: getFileKey(scan, key),
    };
    try {
      const fileDownloadStatusApi = await checkFilesExistInFolder(data);

      if (fileDownloadStatusApi.data.isFileExist) {
        if (fileDownloadStatusApi.data.fileDownloadLink) {
          const response = await fetch(
            fileDownloadStatusApi.data.fileDownloadLink
          );
          if (!response.ok) {
            throw new Error("File download failed");
          }

          const blob = await response.blob();
          const fileURL = URL.createObjectURL(blob);
          const fileName =
            key === "track"
              ? scan.name.replace(/[^a-zA-Z0-9]/g, "_") + ".geojson"
              : key + ".zip";

          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileName;
          a.click();

          URL.revokeObjectURL(fileURL);
        } else {
          const downloadRequestData = {
            scanId: scan.id,
            type: key,
          };
          dispatch(
            key === "track"
              ? downloadScanFile(downloadRequestData)
              : downloadScanFolder(downloadRequestData)
          );
        }
      } else {
        setFileDownloadStatusDialog(true);
        setFileName(key);
      }
    } catch (error) {
      console.error("Error during file download", error);
    }
  };

  const getFileKey = (scan, type) => {
    if (type == UPLOAD_TYPE.scanoutputs) {
      return scan.scanPaths.outputsPath;
    }
    if (type == UPLOAD_TYPE.scanortho) {
      return scan.scanPaths.orthoPath;
    }
    if (type === UPLOAD_TYPE.scanpointclouds) {
      let fileKey = scan.scanPaths.pointCloudPath;
      const fileParts = fileKey.split(".");
      const extension = fileParts.pop(); // Remove and save the file extension
      const updatedFileKey = `${fileParts.join(".")}_${scan.id}.${extension}`;
      return updatedFileKey;
    }
    if (type == UPLOAD_TYPE.track) {
      return scan.scanPaths.geoJsonPath;
    }
    if (type == UPLOAD_TYPE.scanpanoramas) {
      return scan.scanPaths.panoramasPath;
    }
    if (type == UPLOAD_TYPE.plscaddkey) {
      return scan.scanPaths.plsCaddKey;
    }
    if (type == UPLOAD_TYPE.plscaddfile) {
      return scan.scanPaths.plsCaddFile;
    }
  };

  const handleOnCloseDownloadDialog = () => {
    setFileDownloadStatusDialog(false);
  };

  // eslint-disable-next-line complexity
  const getStatusWithIcon = (scan) => {
    if (scan.processingStatus == "completed") {
      return "Completed";
    }
    if (scan.processingStatus == "running") {
      return "Running";
    }
    if (
      scan.processingStatus != "running" &&
      !scan.isQueued &&
      scan.processingStatus != "failed" &&
      scan.mapboxStatus != 2 &&
      scan.potreeStatus != 2
    ) {
      return "Pending";
    }
    if (
      scan.mapboxStatus != 2 &&
      scan.potreeStatus != 2 &&
      scan.isQueued == true
    ) {
      return "Queued";
    }
    if (
      scan.mapboxStatus != 2 &&
      scan.potreeStatus != 2 &&
      scan.processingStatus == "failed" &&
      scan.status == "failed"
    ) {
      // For consistency with 2D and 3D
      return "Manual Review";
    }
  };

  return (
    <div
      className="w-full"
      style={{ marginTop: "80px", paddingLeft: "80px", paddingRight: "80px" }}
    >
      <div className="w-full px-8 my-16">
        <Box>
          <Box>
            <Grid container>
              <Grid item xs={6}>
                <div className="flex">
                  <div className={`${classes.active} mr-8`}>
                    <Button
                      sx={{ width: "135px", borderRadius: 0 }}
                      className={classes.activeButton}
                    >
                      <div style={{ width: "135px" }}>
                        <Typography
                          style={{ fontWeight: "600", color: "#000" }}
                        >
                          Files
                        </Typography>
                      </div>
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  <div className="flex flex-col justify-end items-end">
                    {!openModelPopup ? (
                      <Button
                        variant="outlined"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => handleOnCreateScanClick()}
                        style={{
                          backgroundColor: "#556A6D",
                          color: "#ffffff",
                          width: "115px",
                          border: "none",
                          boxSizing: "border-box",
                          borderRadius: "50px",
                          padding: "5px 20px",
                        }}
                      >
                        Scan
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => handleOnBackButtonClick()}
                        style={{
                          boxSizing: "border-box",
                          width: "124px",
                          background: "#E67260",
                          border: "1px solid #E67260",
                          borderRadius: "50px",
                          color: "#ffffff",
                          padding: "2px 15px",
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{ backgroundColor: "#EFEEEE" }}
            className="sm:p-24 md:p-32"
            style={{
              borderRadius: "0px 7px 7px 7px",
            }}
          >
            <div className="w-full">
              <div className="flex flex-col md:flex-row sm:p-8">
                <div className="flex flex-1 flex-col min-w-0">
                  <div className="flex justify-center max-w-full relative">
                    <div className="flex flex-1 max-w-full">
                      <div className="flex flex-1 justify-center max-w-full">
                        <div style={{ height: "100%", width: "100%" }}>
                          {!openModelPopup ? (
                            <div>
                              <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                  <TableHead>
                                    <TableRow
                                      style={{
                                        backgroundColor: "#cfcdc9",
                                        transparency: "85%",
                                      }}
                                    >
                                      <TableCell width={60}></TableCell>
                                      {scanHeadCells.map(
                                        (headCell) =>
                                          headCell.display && (
                                            <TableCell
                                              key={headCell.id}
                                              sortDirection={
                                                scanOrderBy === headCell.id
                                                  ? scanOrder
                                                  : false
                                              }
                                              style={{
                                                fontWeight: "300",
                                                fontSize: "13px",
                                                color: "#3A3A3A",
                                              }}
                                            >
                                              <TableSortLabel
                                                active={
                                                  scanOrderBy === headCell.id
                                                }
                                                direction={
                                                  scanOrderBy === headCell.id
                                                    ? scanOrder
                                                    : "asc"
                                                }
                                                onClick={handleScanRequestSort(
                                                  headCell.id
                                                )}
                                              >
                                                {headCell.label}
                                                {scanOrderBy === headCell.id ? (
                                                  <Box
                                                    component="span"
                                                    style={
                                                      StaticStyles.visuallyHidden
                                                    }
                                                  >
                                                    {scanOrder === "desc"
                                                      ? "sorted descending"
                                                      : "sorted ascending"}
                                                  </Box>
                                                ) : null}
                                              </TableSortLabel>
                                            </TableCell>
                                          )
                                      )}
                                      <TableCell
                                        style={{
                                          fontWeight: "300",
                                          fontSize: "13px",
                                          color: "#3A3A3A",
                                        }}
                                      >
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {/* eslint-disable-next-line complexity */}
                                  {CommonSorting.stableSort(
                                    site.scans,
                                    scanOrder,
                                    scanOrderBy
                                  ).map((s) => (
                                    <TableBody key={s.id}>
                                      <TableRow key={s.id}>
                                        <TableCell width={60}>
                                          {(role?.includes("admin") ||
                                            s.processingStatus ===
                                              "completed") && (
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={() => {
                                                if (collapseId == s.id) {
                                                  setCollapseId(0);
                                                } else {
                                                  setCollapseId(s.id);
                                                }
                                              }}
                                            >
                                              {collapseId == s.id ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon />
                                              )}
                                            </IconButton>
                                          )}
                                        </TableCell>
                                        {role?.includes("admin") && (
                                          <TableCell>{s.id}</TableCell>
                                        )}
                                        {s.description ? (
                                          <TableCell
                                            style={{
                                              fontWeight: "400",
                                              fontSize: "13px",
                                              color: "#3A3A3A",
                                            }}
                                          >
                                            <Tooltip title={s.description}>
                                              <span>{s.name}</span>
                                            </Tooltip>
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            style={{
                                              fontWeight: "400",
                                              fontSize: "13px",
                                              color: "#3A3A3A",
                                            }}
                                          >
                                            {s.name}
                                          </TableCell>
                                        )}

                                        <TableCell
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            color: "#3A3A3A",
                                          }}
                                        >
                                          {s.date != null
                                            ? s.date.split("T")[0]
                                            : ""}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            color: "#3A3A3A",
                                          }}
                                        >
                                          {s.acres ? s.acres.toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            color: "#3A3A3A",
                                          }}
                                        >
                                          <div className="flex align-center">
                                            {getStatusWithIcon(s)}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            color: "#3A3A3A",
                                          }}
                                        >
                                          {s.latestProcessingVersion
                                            ? s.latestProcessingVersion
                                            : ""}
                                        </TableCell>
                                        <TableCell className="pl-0">
                                          <Tooltip title="Folder">
                                            <IconButton
                                              style={{ color: "#4D4D4F" }}
                                              onClick={() =>
                                                handleScanFolderClick(s)
                                              }
                                            >
                                              <FolderOutlinedIcon />
                                            </IconButton>
                                          </Tooltip>
                                          {showScanDownloadButton(s)}
                                          {(site.siteRole == "admin" ||
                                            site.siteRole == "user") && (
                                            <Tooltip title="Edit">
                                              <IconButton
                                                style={{ color: "#4D4D4F" }}
                                                onClick={() =>
                                                  handleOnEditScanClick(s)
                                                }
                                              >
                                                <ModeEditOutlineOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {(site.siteRole == "admin" ||
                                            site.siteRole == "user") && (
                                            <Tooltip title="Delete">
                                              <IconButton
                                                style={{ color: "#4D4D4F" }}
                                                onClick={() =>
                                                  handleScanDelete(s)
                                                }
                                              >
                                                <DeleteOutlineOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}

                                          {!s.isOriginal &&
                                            role.includes("admin") &&
                                            s.processingStatus == "completed" &&
                                            !s.isQueued && (
                                              <Tooltip title="Restore">
                                                <IconButton
                                                  className={
                                                    commonStyles.primaryColor
                                                  }
                                                  onClick={() =>
                                                    handleOnScanRevert(s)
                                                  }
                                                >
                                                  <RestoreIcon />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                        </TableCell>
                                      </TableRow>
                                      {collapseId == s.id ? (
                                        <TableRow>
                                          <TableCell></TableCell>
                                          <TableCell colSpan={4}>
                                            <Collapse
                                              in={collapseId == s.id}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <TableContainer
                                                component={Paper}
                                                style={{
                                                  border: "1px solid #989898",
                                                  borderRadius: "6px",
                                                  boxShadow: "none",
                                                  transition: "none",
                                                }}
                                              >
                                                <Table
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <TableHead>
                                                    <TableRow
                                                      style={{
                                                        backgroundColor:
                                                          "#cfcdc9",
                                                        transparency: "85%",
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Files
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Action
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow key={s.id}>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Point Cloud
                                                      </TableCell>
                                                      <TableCell>
                                                        <Tooltip title="Folder">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handlePointCloudFolderClick(
                                                                s
                                                              )
                                                            }
                                                          >
                                                            <FolderOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Download">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handleDownload(
                                                                s,
                                                                UPLOAD_TYPE.scanpointclouds
                                                              )
                                                            }
                                                          >
                                                            <DownloadOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow key={s.id}>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Ortho
                                                      </TableCell>
                                                      <TableCell>
                                                        <Tooltip title="Folder">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handleOrthoFolderClick(
                                                                s
                                                              )
                                                            }
                                                          >
                                                            <FolderOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Download">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              onClickDownload(
                                                                s,
                                                                UPLOAD_TYPE.scanortho
                                                              )
                                                            }
                                                          >
                                                            <DownloadOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow key={s.id}>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Panoramic Imagery
                                                      </TableCell>
                                                      <TableCell>
                                                        <Tooltip title="Folder">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handlePanoramaFolderClick(
                                                                s
                                                              )
                                                            }
                                                          >
                                                            <FolderOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Download">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              onClickDownload(
                                                                s,
                                                                UPLOAD_TYPE.scanpanoramas
                                                              )
                                                            }
                                                          >
                                                            <DownloadOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow key={s.id}>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Track
                                                      </TableCell>
                                                      <TableCell>
                                                        <span
                                                          style={{
                                                            display:
                                                              "inline-block",
                                                            width: 38,
                                                          }}
                                                        />
                                                        <Tooltip title="Download">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              onClickDownload(
                                                                s,
                                                                UPLOAD_TYPE.track
                                                              )
                                                            }
                                                          >
                                                            <DownloadOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow key={s.id}>
                                                      <TableCell
                                                        style={{
                                                          fontWeight: "300",
                                                          fontSize: "13px",
                                                          color: "#3A3A3A",
                                                        }}
                                                      >
                                                        Thinned Point Cloud
                                                      </TableCell>
                                                      <TableCell>
                                                        <Tooltip title="Folder">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handlePlsCaddFolderClick(
                                                                s
                                                              )
                                                            }
                                                          >
                                                            <FolderOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Download">
                                                          <IconButton
                                                            style={{
                                                              color: "#4D4D4F",
                                                            }}
                                                            onClick={() =>
                                                              handleDownload(
                                                                s,
                                                                UPLOAD_TYPE.plscaddfile
                                                              )
                                                            }
                                                          >
                                                            <DownloadOutlinedIcon />
                                                          </IconButton>
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </Collapse>
                                          </TableCell>
                                          <TableCell colSpan={3}></TableCell>
                                        </TableRow>
                                      ) : null}
                                    </TableBody>
                                  ))}
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                component="div"
                                count={site.scans.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                              />
                            </div>
                          ) : (
                            <div>
                              <FolderStructure
                                type={UPLOAD_TYPE.scan}
                                id={scanId}
                                filePathKey={modelScanPath}
                                modelTitle={modelTitle}
                              ></FolderStructure>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {fileDownloadStatusDialog && (
                <FileDownloadErrorDailog
                  onClose={handleOnCloseDownloadDialog}
                  fileName={fileName}
                />
              )}
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default ScanDetails;
