
class StaticStyles {


    static visuallyHidden = {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px'
    }



}
export default StaticStyles;