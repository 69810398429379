import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import APP_CONFIG from "../../../../AppConfig";

export const getBillingOrganizations = createAsyncThunk('admin/oraganizationBilling/getBillingOrganizations', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/organizationBilling/getBillingOrganizations`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});

export const createOrUpdateOrganizationBillingUser = createAsyncThunk('admin/oraganizationBilling/createOrUpdateOrganizationBillingUser', async (requestObject, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/organizationBilling/createOrUpdateOrganizationBillingUser`;
    const response = await axios.post(url, requestObject.requestData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    requestObject.successCallback();
});



const oraganizationBillingSlice = createSlice({
    name: 'admin/oraganizationBillingApp',
    initialState: {
        billingLinkedOrganizations: [],
        billingNotLinkedOrganizations: []
    },
    extraReducers: {
        [getBillingOrganizations.fulfilled]: (state, action) => {
            state.billingLinkedOrganizations = action.payload.billingLinkedOrganizations;
            state.billingNotLinkedOrganizations = action.payload.billingNotLinkedOrganizations;
        }
    },
});

export default oraganizationBillingSlice.reducer;