import MapBoxCommon from "../MapBoxCommon";

class MapBoxPointMeasure extends MapBoxCommon {
    constructor(viewer, lat, lng, panelRef, checkPointValid, elevation) {
        super();
        this.lat = lat;
        this.lng = lng;
        this.viewer = viewer;
        this.panelRef = panelRef;
        this.checkPointValid = checkPointValid;
        this.coords = {
            lng: lng,
            lat: lat
        };
        this.elevation = elevation ? elevation : 0;
        this.canvas = this.viewer.getCanvasContainer();
        this.id = this.createUUID();

        this.styleLoadEvent = this.onStyleLoad.bind(this);
        this.pointClouds = [];

        this.labelMarker = this.addLabel(viewer, lng, lat);
        this.updateLabelText(this.labelMarker, `${lng.toFixed(8)}/${lat.toFixed(8)}`);

        var mainElement = this.labelMarker.getElement().getElementsByClassName('labelMarker');
        if (mainElement.length > 0) {
            mainElement[0].style.marginBottom = '40px';
        }
        this.addPointLayer(this.lng, this.lat);

    }


    onStyleLoad(e) {
        this.addPointLayer(this.coords.lng, this.coords.lat)
    }

    addPointLayer(lng, lat) {
        let onMouseMove = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
                this.onMouseMove(this.coords.lng, this.coords.lat);
            }

        }
        let onMouseUp = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);

            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
            }
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }
        let onMouseLeave = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }

        this.addPoint(this.viewer, lng, lat, this.id);
        this.viewer.on('mouseenter', this.id, () => {
            this.canvas.style.cursor = 'move';
        });

        this.viewer.on('mouseleave', this.id, () => {
            this.canvas.style.cursor = '';
        });

        this.viewer.on('mousedown', this.id, (e) => {
            e.preventDefault();
            this.canvas.style.cursor = 'move';
            this.viewer.on('mousemove', onMouseMove);
            this.panelRef.current.addEventListener('mouseleave', onMouseLeave);
            this.viewer.once('mouseup', onMouseUp);
        });
    }

    onMouseMove(lng, lat) {
        this.elevation = 0;
        this.updatePoint(this.viewer, lng, lat, this.id);
        this.updateLabelLngLat(this.labelMarker, lng, lat);
        this.updateLabelText(this.labelMarker, `${lng.toFixed(8)}/${lat.toFixed(8)}`)
    }

    onMouseUp(lng, lat) {
        this.updatePoint(this.viewer, lng, lat, this.id);
        this.updateLabelLngLat(this.labelMarker, lng, lat);
        this.updateLabelText(this.labelMarker, `${lng.toFixed(8)}/${lat.toFixed(8)}`)
    }

    setPointClouds(pointClouds) {
        this.pointClouds = pointClouds
    }

    remove() {
        this.removePoint(this.viewer, this.id);
        this.removeLabel(this.labelMarker);
        this.labelMarker = null;
    }
    removePointCloudPoints(scanPointClouds) {
        if (!this.checkPointValid(this.coords.lng, this.coords.lat, scanPointClouds)) {
            this.removePoint(this.viewer, this.id)
            this.removeLabel(this.labelMarker);
            return true;
        } else {
            return false;
        }
    }

}

export default MapBoxPointMeasure;