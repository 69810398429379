import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Radio,
    RadioGroup,
    FormControlLabel,
    Box,
    Grid,
    FormLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// eslint-disable-next-line complexity
function InviteUser(props) {

    const { role } = useSelector(({ auth }) => auth.user)
    const [accounts, setAccounts] = useState([]);
    const [sites, setSites] = useState([]);
    const [isAddSiteValid, setIsAddSiteValid] = useState(true);
    const [organizationUserSites, setOrganizationUserSites] = useState([]);
    const [allSitesRole, setAllSitesRole] = useState('user');
    const [isEmailValid, setEmailValid] = useState(true);
    const [isValid, setIsValid] = useState(true);
    const [inviteUserData, setInviteUserData] = useState({
        email: '',
        defaultAccount: '',
        applicationroleid: 3,
        accountroleid: 3,
        isAllSitesAccess: '',
        sitesRole: '',
    });

    const [isAllSitesAccess, setIsAllSitesAccess] = useState(true);

    const handleIsAllSitesAccess = (e) => {
        setIsAllSitesAccess((e.target.value == 'true'));
    }

    useEffect(() => {
        setAccounts(props.accounts);
        if (props.accounts.length > 0) {
            setInviteUserData({ ...inviteUserData, defaultAccount: props.accounts[0].id });
            setOrganizationUserSites([]);
            setSites([]);
            var account = props.accounts.filter(x => x.id == props.accounts[0].id);
            if (account.length > 0) {
                setSites(account[0].sites)
            }
        }
        if (role.includes('projectmanager') || role.includes('user')) {
            setIsAllSitesAccess(false);
        }

    }, []);

    const handleOnChangeValues = (e) => {
        try {
            if (e.target.name == "email") {
                const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (re.test(e.target.value)) {
                    setEmailValid(true);
                } else {
                    setEmailValid(false);
                }
                setInviteUserData({ ...inviteUserData, [e.target.name]: e.target.value });

            } else {
                setInviteUserData({ ...inviteUserData, [e.target.name]: e.target.value });
            }

            if (e.target.name == 'defaultAccount') {
                setOrganizationUserSites([]);
                setSites([]);
                var account = accounts.filter(x => x.id == e.target.value);
                if (account.length > 0) {
                    setSites(account[0].sites)
                }
            }
            if (e.target.name == 'accountroleid' && e.target.value == 1) {
                setOrganizationUserSites([]);
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        handleUpdateAllSiteRole();
    }, [organizationUserSites])

    const handleOnChangeAllSitesRole = (e) => {
        try {
            if (inviteUserData.accountroleid == 3 || inviteUserData.accountroleid == 4) {
                setAllSitesRole(e.target.value);
                var _organizationUserSites = [...organizationUserSites];
                for (var i = 0; i < _organizationUserSites.length; i++) {
                    _organizationUserSites[i].siteRole = e.target.value;
                }
                setOrganizationUserSites(_organizationUserSites)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleClose = () => {
        props.handleClose()
    };

    const OnClickAddOrganizationUserSites = () => {
        try {
            if (organizationUserSites.length == 0) {
                setIsAddSiteValid(true)
                const newObj = { ["siteId"]: "", ["siteRole"]: (inviteUserData.accountroleid == 4 || inviteUserData.accountroleid == 3) ? allSitesRole : "user" };
                setOrganizationUserSites(prevArray => [...prevArray, newObj]);
            } else {
                const item = organizationUserSites[organizationUserSites.length - 1];
                if (item.siteId == "" || item.siteRole == "") {
                    setIsAddSiteValid(false)
                    return;
                }
                setIsAddSiteValid(true)
                const newObj = { ["siteId"]: "", ["siteRole"]: (inviteUserData.accountroleid == 4 || inviteUserData.accountroleid == 3) ? allSitesRole : "user" };
                setOrganizationUserSites(prevArray => [...prevArray, newObj]);
            }
            handleUpdateAllSiteRole();
        } catch (error) {
            console.error(error);
        }
    }

    const OnClickAddAllOrganizationUserSites = () => {
        try {

            if (organizationUserSites.length == 0) {
                setIsAddSiteValid(true);
                var _organizationUserSitesDetails = [];
                sites.forEach(site => {
                    const newObj = { ["siteId"]: site.id, ["siteRole"]: inviteUserData.accountroleid == 4 ? allSitesRole : "user" };
                    _organizationUserSitesDetails.push(newObj);
                });
                setOrganizationUserSites(_organizationUserSites);
            } else {
                var _organizationUserSites = [...organizationUserSites];
                const item = _organizationUserSites[_organizationUserSites.length - 1];
                if (item.siteId == "" || item.siteRole == "") {
                    _organizationUserSites = _organizationUserSites.filter((el, i) => i !== (_organizationUserSites.length - 1));
                }
                setIsAddSiteValid(true)
                sites.forEach(site => {
                    if (_organizationUserSites.filter(x => x.siteId == site.id).length == 0) {
                        const newObj = { ["siteId"]: site.id, ["siteRole"]: inviteUserData.accountroleid == 4 ? allSitesRole : "user" };
                        _organizationUserSites.push(newObj);
                    }

                });
                setOrganizationUserSites(_organizationUserSites);
                handleUpdateAllSiteRole();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const OnClickRemoveOrganizationUserSites = (index) => {
        try {
            setOrganizationUserSites(current => current.filter((el, i) => i !== index));
            handleUpdateAllSiteRole();
        } catch (e) {
            console.error(e);
        }
    }
    const handleOnOrganizationUserSiteChange = (e, index) => {
        try {
            const { name, value } = e.target;
            const updatedArray = [...organizationUserSites];
            updatedArray[index][name] = value;
            setOrganizationUserSites(updatedArray);
            handleUpdateAllSiteRole();
        } catch (error) {
            console.error(error);
        }
    }

    const handleUpdateAllSiteRole = () => {
        if (organizationUserSites.length > 0) {
            if (organizationUserSites.length == organizationUserSites.filter(x => x.siteRole == 'admin').length) {
                setAllSitesRole('admin');
            }
            else if (organizationUserSites.length == organizationUserSites.filter(x => x.siteRole == 'user').length) {
                setAllSitesRole('user');
            }
        }
    }

    const checkOrganizationSiteAlreadySelected = (site, index) => {
        var count = organizationUserSites.filter(a => a.siteId == site.id).length;
        var siteIndex = organizationUserSites.findIndex(a => a.siteId == site.id);

        if (count > 0) {
            if (index == siteIndex) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }

    }

    const onClickInviteUser = () => {
        setIsValid(true)
        if (validateInviteForm()) {
            var location = window.location.origin;
            var email = inviteUserData.email;
            var data = {
                email: email.toLowerCase(),
                inviteLink: location,
                applicationRoleId: inviteUserData.applicationroleid,
                accountRoleId: inviteUserData.accountroleid,
                defaultAccount: inviteUserData.defaultAccount,
                inviteUserSites: organizationUserSites.filter(x => x.siteId != '' && x.siteRole != ''),
                isAllSitesAccess: isAllSitesAccess,
                sitesRole: allSitesRole,
                isUploadMailEnable: isUploadEmailEnable
            }
            props.handleInviteUser(data);
        }
        else {
            setIsValid(false)
        }
    }

    const validateInviteForm = () => {
        if (!inviteUserData.email || (inviteUserData.applicationroleid != 1 && !inviteUserData.defaultAccount)) {
            return false;
        }
        else {
            if (isEmailValid) {
                return true;
            } else {
                return false
            }
        }
    }


    const isSiteRoleDisable = () => {
        if (inviteUserData.defaultAccount == '') {
            return true;
        } else if (isAllSitesAccess) {
            return false;
        }
        else if (organizationUserSites.length == 0) {
            return true
        } else {
            return false
        }
    }

    const [isUploadEmailEnable, setIsUploadEmailEnable] = useState(false);
    const handleOnChangeUploadEnable = (e) => {
        setIsUploadEmailEnable(e.target.value)
    }

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                fullWidth
                maxWidth='md'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'paper'}
            >
                <DialogTitle className="flex flex-col items-center justify-center w-full pb-4">Invite User</DialogTitle>
                <div>
                    <DialogContent className="flex flex-col items-center justify-center w-full py-0">
                        {isValid == true ? null : <p className="text-red">All fields are mandatory</p>}
                        {isEmailValid == true ? null : <p className="text-red">Email is not valid</p>}
                        <div className='relative flex flex-row items-center w-full'>
                            <div className='flex flex-col w-full'>
                                <div className='mr-32'>
                                    <FormControl className='mx-0 mt-12 w-full'>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email ID"
                                            name="email"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => handleOnChangeValues(e)}
                                            value={inviteUserData.email}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='ml-32'>
                                    {role.includes("admin") ?
                                        <FormControl className='mx-0 mt-12 w-full'>
                                            <InputLabel id="demo-simple-select-standard-label">Application role</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="applicationroleid"
                                                value={inviteUserData.applicationroleid}
                                                onChange={(e) => handleOnChangeValues(e)}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                label='Application role'
                                                disabled={!role.includes("admin")}
                                            >
                                                <MenuItem value={1}>Admin</MenuItem>
                                                <MenuItem value={3}>User</MenuItem>
                                            </Select>
                                        </FormControl>
                                        : null
                                    }
                                    {
                                        role.includes('projectmanager') &&
                                        <FormControl className='mx-0 mt-12 w-full'>
                                            <InputLabel id="demo-simple-select-standard-label">Default Organization </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                fullWidth
                                                name="defaultAccount"
                                                value={inviteUserData.defaultAccount}
                                                onChange={(e) => handleOnChangeValues(e)}
                                                label="Default Organization"
                                            >
                                                {accounts.map((account) =>
                                                    <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                            </div>
                        </div>
                        {(role.includes('admin') && inviteUserData.applicationroleid == 1) &&
                            <div className='relative flex flex-row items-center w-full mt-8'>
                                <div className='flex flex-col w-full'>
                                    <div className='mr-32'>
                                        <FormControl className="w-full">
                                            <FormLabel id="demo-controlled-radio-buttons-group">Upload Email Notifications</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                value={isUploadEmailEnable}
                                                name='accountingPermission'
                                                onChange={(e) => handleOnChangeUploadEnable(e)}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full'>
                                    <div className='ml-32'>

                                    </div>
                                </div>
                            </div>}
                        {inviteUserData.applicationroleid != 1 &&
                            <>
                                <div className='relative flex flex-row items-center w-full'>
                                    <div className='flex flex-col w-full'>
                                        <div className='mr-32'>
                                            {!role.includes('projectmanager') &&
                                                <FormControl className='mx-0 mt-12 w-full'>
                                                    <InputLabel id="demo-simple-select-standard-label">Default Organization </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        fullWidth
                                                        name="defaultAccount"
                                                        value={inviteUserData.defaultAccount}
                                                        onChange={(e) => handleOnChangeValues(e)}
                                                        label="Default Organization"
                                                    >
                                                        {accounts.map((account) =>
                                                            <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='ml-32'>
                                            {(role.includes("admin") || role.includes("organisationadmin")) &&
                                                <FormControl className='mx-0 mt-12 w-full'>
                                                    <InputLabel id="demo-simple-select-standard-label">Organization role </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        name="accountroleid"
                                                        value={inviteUserData.accountroleid}
                                                        onChange={(e) => handleOnChangeValues(e)}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        label='Organization role'
                                                    >
                                                        <MenuItem value={1}>Admin</MenuItem>
                                                        <MenuItem value={4}>Manager</MenuItem>
                                                        <MenuItem value={3}>User</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {inviteUserData.accountroleid != 1 &&
                                    <>
                                        <Box className='relative flex flex-row items-center w-full'>
                                            <Grid container>
                                                <Grid item xs={6} className='pt-12'>
                                                    <label> Sites</label>
                                                    <div className='relative flex flex-row items-center justify-between w-full'>
                                                        {(role.includes("admin") || role.includes("organisationadmin")) &&
                                                            <FormControl className='w-full'>
                                                                {/* <FormLabel id="demo-controlled-radio-buttons-group">Sites</FormLabel> */}
                                                                <RadioGroup row
                                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                                    name='automaticProjection'
                                                                    value={isAllSitesAccess}
                                                                    onChange={handleIsAllSitesAccess}
                                                                >
                                                                    <FormControlLabel value={true} control={<Radio />} label="All Sites" />
                                                                    <FormControlLabel value={false} control={<Radio />} label="Select Sites" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        }
                                                        {!isAllSitesAccess &&
                                                            <div className='w-full flex items-center'>
                                                                <Button
                                                                    onClick={OnClickAddOrganizationUserSites}
                                                                    variant="outlined"
                                                                    startIcon={<AddIcon />}
                                                                    style={{ 'padding': '5px 10px' }}
                                                                    disabled={inviteUserData.defaultAccount == '' || inviteUserData.accountroleid == 1 || sites.length == organizationUserSites.length}
                                                                >
                                                                    ADD
                                                                </Button>
                                                                <Button
                                                                    onClick={OnClickAddAllOrganizationUserSites}
                                                                    variant="outlined"
                                                                    className='ml-8'
                                                                    startIcon={<AddIcon />}
                                                                    style={{ 'padding': '5px 10px' }}
                                                                    disabled={inviteUserData.defaultAccount == '' || inviteUserData.accountroleid == 1 || sites.length == organizationUserSites.length}
                                                                >
                                                                    ADD ALL
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <div className="ml-32">
                                                        <FormControl className='mx-0 w-full mb-14 mt-12'>
                                                            <InputLabel id="demo-simple-select-standard-label">All Sites Role </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                name="accountRole"
                                                                value={allSitesRole}
                                                                onChange={(e) => handleOnChangeAllSitesRole(e)}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                label="All Sites Role"
                                                                //  disabled={ role.includes('projectmanager') ?  organizationUserSites.length == 0 || inviteUserData.defaultAccount == '' : isAllSitesAccess == false  && ( inviteUserData.accountroleid == 3 || organizationUserSites.length == 0 || inviteUserData.defaultAccount == '')}
                                                                disabled={isSiteRoleDisable()}
                                                            >
                                                                <MenuItem value="admin">Admin</MenuItem>
                                                                <MenuItem value="user">User</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {!isAllSitesAccess &&
                                            <>
                                                {organizationUserSites.length > 0 ? organizationUserSites.map((organizationUserSite, i) => (
                                                    <div className='relative flex flex-row items-center w-full' key={i}>
                                                        <div className='flex flex-col mr-40 w-full'>
                                                            {i == 0 ? <label className='mr-4'>Site </label> : null}
                                                            <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Select Site</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={organizationUserSite.siteId}
                                                                    name="siteId"
                                                                    onChange={(e) => handleOnOrganizationUserSiteChange(e, i)}
                                                                    className='form-control'
                                                                    label="Select Site"
                                                                    error={!isAddSiteValid && organizationUserSite.siteId == ''}
                                                                >
                                                                    {sites.map((site) =>
                                                                        checkOrganizationSiteAlreadySelected(site, i) ? null :
                                                                            <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className="flex flex-col mx-8 w-full">
                                                            {i == 0 ? <label>Role </label> : null}

                                                            <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    name="siteRole"
                                                                    value={organizationUserSite.siteRole}
                                                                    onChange={(e) => handleOnOrganizationUserSiteChange(e, i)}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                    label="Select Role"
                                                                    error={!isAddSiteValid && organizationUserSite.siteRole == ''}
                                                                >
                                                                    <MenuItem value="admin">Admin</MenuItem>
                                                                    <MenuItem value="user">User</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="mt-8 flex flex-row">
                                                            <Tooltip title='Remove'>
                                                                <button onClick={() => OnClickRemoveOrganizationUserSites(i)}>
                                                                    <span className="material-icons notranslate MuiIcon-root MuiIcon-colorAction MuiIcon-fontSizeMedium text-32 text-red muiltr-bded6y" aria-hidden="true">remove_circle_outline</span>
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </DialogContent>
                </div>
                <DialogActions className="flex flex-row items-center justify-center w-full mb-8">
                    <Button color="error" variant='outlined' onClick={() => handleClose()}>Cancel</Button>
                    <Button color="secondary" variant='outlined' onClick={() => onClickInviteUser()}>Invite</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InviteUser;