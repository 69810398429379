import styled from "@emotion/styled";

export const InnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 400px;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const Instruction = styled.div`
  font-size: 14px;
  color: #7A8184;
`;