import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "app/AppConfig";
import swal from "sweetalert";


export const demoAccountUser = () => {
  const url = APP_CONFIG.api + `v1/demoAccount/demoAccountUser`;

  const response = axios.get(url, {
    headers: {
      'Content-Type': 'application/json',

    },

  });
  return response;
};

export const getDemoSiteDetails = (siteId) => {
  const url = APP_CONFIG.api + `v1/demoAccount/getDemoSiteDetails/${siteId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getDemoOrthoData = (scanId) => {
  const url = APP_CONFIG.api + `v1/demoAccount/getDemoScanOrthoData/${scanId}`;
  const response = axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
}
const config = {
  headers: {
    'Content-Type': 'application/json',
  }
};

export function demoLoadAllGpsImageInfos(scanId, offset, limit, totalCount) {
  const url = APP_CONFIG.api + `v1/demoAccount/${scanId}/gpspanoimages?offset=${offset}&limit=${limit}&totalCount=${totalCount}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function demoLoadImageInfos(scanId, offset, limit) {
  const url = APP_CONFIG.api + `v1/demoAccount/${scanId}/images?offset=${offset}&limit=${limit}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
};

export const getDemoDefaultClassificationSettings = () => {
  const url = APP_CONFIG.api + `v1/demoAccount/getDemoDefaultClassificationSettings`;

  const response = axios.get(url, {
    headers: {
      'Content-Type': 'application/json',

    },

  });
  return response;
};

export const getGPSImageScans = (scanIds) => {
  const url = APP_CONFIG.api + `v1/demoAccount/getGPSImageScans`;
  const response = axios.post(url, scanIds, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
}
