import { combineReducers } from "@reduxjs/toolkit";
import auth from "app/auth/store";
import fuse from "./fuse";
import i18n from "./i18nSlice";
import scanApp from "app/main/apps/scan/store";
import admin from "app/main/apps/admin/store";
import files from "app/main/apps/upload/store";
import edit from "app/main/apps/admin/editStore";
import register from "app/main/register/store";
import billing from "app/main/apps/billing/store";
import potree from "app/main/apps/scan/potree/Potree/Store";

//Static Reducers
export const staticReducers = {
  edit,
  scanApp,
};

export const asyncReducers = {
  fuse,
  files,
  auth,
  i18n,
  admin,
  edit,
  scanApp,
  register,
  billing,
  potree,
};

export default function createReducerManager(initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (let key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      if (action.type === "auth/user/userLoggedOut") {
        state = undefined;
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action);
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },
  };
}
