/* eslint-disable no-unused-vars */
import ReactDomServer from "react-dom/server";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

class CustomAlert {
  constructor() {
    CustomAlert.resolve = null;
    CustomAlert.reject = null;
  }

  static show(props = {}) {
    return new Promise((resolve, reject) => {
      var ele = document.getElementById("custom-alert-webapp");
      if (ele) {
        ele.remove();
      }
      CustomAlert.resolve = resolve;
      CustomAlert.reject = reject;
      const markerContents = ReactDomServer.renderToString(
        <CustomAlert.GetDialogContent
          type={props.type}
          message={props.message}
          buttons={props.buttons}
          contentStyle={props.contentStyle}
        />
      );
      document.body.insertAdjacentHTML("beforeend", markerContents);
      CustomAlert.AddClickEventListers(props.type, props.buttons);
    });
  }

  // eslint-disable-next-line complexity
  static AddClickEventListers(type, buttons) {
    if (type == "error") {
      if (buttons && buttons.length > 0) {
        buttons.forEach((button, index) => {
          document
            .getElementById(`custom-alert-error-btn_${index}`)
            .addEventListener("click", () => {
              CustomAlert.Close(buttons, index);
            });
        });
      } else {
        document
          .getElementById("custom-alert-error-btn")
          .addEventListener("click", () => {
            CustomAlert.Close();
          });
      }
    }
    if (type == "success") {
      if (buttons && buttons.length > 0) {
        buttons.forEach((button, index) => {
          document
            .getElementById(`custom-alert-success-btn_${index}`)
            .addEventListener("click", () => {
              CustomAlert.Close(buttons, index);
            });
        });
      } else {
        document
          .getElementById("custom-alert-success-btn")
          .addEventListener("click", () => {
            CustomAlert.Close();
          });
      }
    }
    if (type == "message") {
      document
        .getElementById("custom-alert-message-btn")
        .addEventListener("click", () => {
          CustomAlert.Close();
        });
    }

    if (type == "confirmation") {
      if (buttons && buttons.length > 0) {
        buttons.forEach((button, index) => {
          document
            .getElementById(`custom-alert-confirmation-btn_${index}`)
            .addEventListener("click", () => {
              CustomAlert.Close(buttons, index);
            });
        });
      } else {
        document
          .getElementById("custom-alert-confirmation-btn")
          .addEventListener("click", () => {
            CustomAlert.Close();
          });
      }
    }

    if (type == "deleteconfirmation") {
      if (buttons && buttons.length > 0) {
        buttons.forEach((button, index) => {
          document
            .getElementById(`custom-alert-confirmation-btn_${index}`)
            .addEventListener("click", () => {
              CustomAlert.Close(buttons, index);
            });
        });
      } else {
        document
          .getElementById("custom-alert-deleteconfirmation-btn-yes")
          .addEventListener("click", () => {
            CustomAlert.Close(buttons, -1, "yes");
          });
        document
          .getElementById("custom-alert-deleteconfirmation-btn-no")
          .addEventListener("click", () => {
            CustomAlert.Close(buttons, -1, "no");
          });
      }
    }

    document
      .getElementById("custom-alert-webapp")
      .addEventListener("click", () => {
        CustomAlert.Close();
      });
  }
  static Close(buttons, index, res) {
    if (res) {
      this.resolve(res);
    } else {
      if (buttons && buttons.length > 0) {
        var button = buttons[index];
        if (button.click) {
          button.click();
        } else {
          this.resolve(button);
        }
      } else {
        this.resolve();
      }
    }
    var ele = document.getElementById("custom-alert-webapp");
    if (ele) {
      ele.remove();
    }
  }
  static GetStyles(defaultStyles, customStyles) {
    if (customStyles && Object.keys(customStyles).length > 0) {
      Object.keys(customStyles).forEach((key) => {
        defaultStyles[key] = customStyles[key];
      });
    }
    return defaultStyles;
  }

  static GetMessageBox(type, buttons, message, contentStyle) {
    return (
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "12px",
          flexDirection: "column",
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
        }}
      >
        <DialogContent className="text-center">
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#000000",
              fontSize: "18px",
              margin: "20px",
              fontWeight: "300px",
            }}
          >
            <div className="flex flex-col">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "-0.011em",
                  textAlign: "left",
                }}
              >
                {message}
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex items-center mb-32 justify-center">
          <Button
            style={{
              width: "105px",
              height: "54px",
              padding: "13px, 28px, 13px, 28px",
              borderRadius: "4px",
              margin: "20px",
              backgroundColor: "#58BEC9",
              color: "#ffffff",
              fontSize: "20px",
            }}
            id="custom-alert-message-btn"
            onClick={() => {}}
          >
            OK
          </Button>
        </DialogActions>
      </div>
    );
  }

  static GetErrorDialog(type, buttons, message, contentStyle) {
    return (
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "12px",
          flexDirection: "column",
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
        }}
      >
        <div className="flex">
          <DialogTitle
            id="dialog-title"
            className="text-center"
            style={{
              fontSize: 30,
              fontWeight: 600,
              textAlign: "center",
              color: "#A1A1A1",
              paddingTop: "30px",
            }}
          >
            <CancelOutlinedIcon
              style={{ fontSize: "1.73em" }}
            ></CancelOutlinedIcon>{" "}
            Error
          </DialogTitle>
        </div>
        <DialogContent className="text-center">
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#333333",
              fontSize: "18px",
              margin: "20px",
              fontWeight: "300px",
            }}
          >
            <div className="flex flex-col" style={{ textAlign: "center" }}>
              <span>{message}</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex items-center mb-32 justify-center">
          {buttons && buttons.length == 0 ? (
            <Button
              style={{
                width: "105px",
                height: "54px",
                padding: "13px, 28px, 13px, 28px",
                borderRadius: "4px",
                //  margin: "20px",
                backgroundColor: "#58BEC9",
                color: "#ffffff",
                fontSize: "20px",
              }}
              id="custom-alert-error-btn"
              onClick={() => {}}
            >
              OK
            </Button>
          ) : (
            <div className="flex items-center justify-between">
              {buttons.map((button, index) => (
                <div key={index}>
                  <Button
                    style={CustomAlert.GetStyles(
                      {
                        width: "105px",
                        height: "54px",
                        padding: "13px, 28px, 13px, 28px",
                        borderRadius: "4px",

                        backgroundColor: "#58BEC9",
                        color: "#ffffff",
                        fontSize: "20px",
                      },
                      button.style
                    )}
                    id={`custom-alert-error-btn_${index}`}
                    onClick={() => {}}
                  >
                    {button.text}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </DialogActions>
      </div>
    );
  }

  static GetSuccessDialog(type, buttons, message, contentStyle) {
    return (
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "12px",
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
        }}
      >
        <div className="flex">
          <DialogTitle
            id="dialog-title"
            className="text-center"
            style={{
              fontSize: 30,
              fontWeight: 600,
              textAlign: "center",
              color: "#A1A1A1",
              paddingTop: "30px",
            }}
          >
            <CheckCircleOutlineIcon
              style={{ fontSize: "2em" }}
            ></CheckCircleOutlineIcon>{" "}
          </DialogTitle>
        </div>
        <DialogContent className="text-center">
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color: "#58BEC9",
              fontSize: "15px",
              margin: "20px",
              fontWeight: "300px",
            }}
          >
            <Typography
              style={CustomAlert.GetStyles(
                {
                  fontSize: "25px",
                  fontWeight: 600,
                  lineHeight: "28px",
                  letterSpacing: "-0.01em",
                  textAlign: "center",
                  color: "#58BEC9",
                },
                contentStyle
              )}
            >
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex items-center mb-32 justify-center">
          {buttons.length == 0 ? (
            <Button
              style={{
                width: "105px",
                height: "54px",
                padding: "13px, 28px, 13px, 28px",
                borderRadius: "4px",
                backgroundColor: "#58BEC9",
                color: "#ffffff",
                fontSize: "20px",
              }}
              id="custom-alert-success-btn"
              onClick={() => {
                CustomAlert.Close();
              }}
            >
              OK
            </Button>
          ) : (
            <div>
              {buttons.map((button, index) => {
                <div key={index}>
                  <Button
                    style={CustomAlert.GetStyles(
                      {
                        width: "105px",
                        height: "54px",
                        padding: "13px, 28px, 13px, 28px",
                        borderRadius: "4px",
                        backgroundColor: "#58BEC9",
                        color: "#ffffff",
                        fontSize: "20px",
                      },
                      button.style
                    )}
                    id={`custom-alert-success-btn_${index}`}
                  >
                    {button.text}
                  </Button>
                </div>;
              })}
            </div>
          )}
        </DialogActions>
      </div>
    );
  }

  static GetConfirmationDialog(buttons, message) {
    return (
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "12px",
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
        }}
      >
        <div>
          <div className="flex justify-end pr-8 pt-8">
            <IconButton>
              <HighlightOffIcon
                style={{ color: "#37495759", width: "29px", height: "29px" }}
              />
            </IconButton>
          </div>
          <DialogTitle
            id="dialog-title"
            className="text-center"
            style={{
              fontSize: 30,
              fontWeight: 600,
              letterSpacing: "-0.01em",
              textAlign: "center",
              color: "#58BEC9",
            }}
          >
            Save Changes
          </DialogTitle>
        </div>
        <DialogContent className="text-center">
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#000000", fontSize: "20px" }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex items-center mb-32 justify-center">
          {buttons.map((button, index) => (
            <div key={index}>
              <Button
                style={CustomAlert.GetStyles(
                  {
                    width: "105px",
                    height: "54px",
                    padding: "13px, 28px, 13px, 28px",
                    borderRadius: "4px",

                    backgroundColor: "#58BEC9",
                    color: "#ffffff",
                    fontSize: "20px",
                  },
                  button.style
                )}
                id={`custom-alert-confirmation-btn_${index}`}
                onClick={() => {}}
              >
                {button.text}
              </Button>
            </div>
          ))}
        </DialogActions>
      </div>
    );
  }

  static GetContent(type, buttons, message, contentStyle) {
    if (type == "error") {
      return CustomAlert.GetErrorDialog(type, buttons, message, contentStyle);
    } else if (type == "success") {
      return CustomAlert.GetSuccessDialog(type, buttons, message, contentStyle);
    } else if (type == "message") {
      return CustomAlert.GetMessageBox(type, buttons, message, contentStyle);
    } else if (type == "confirmation") {
      return CustomAlert.GetConfirmationDialog(
        type,
        buttons,
        message,
        contentStyle
      );
    } else if (type == "deleteconfirmation") {
      return CustomAlert.GetDeleteConfirmationDialog(
        type,
        buttons,
        message,
        contentStyle
      );
    }
  }
  static GetDeleteConfirmationDialog(type, buttons, message, contentStyle) {
    return (
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "12px",
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
        }}
      >
        <div>
          <DialogTitle
            id="dialog-title"
            className="text-center"
            style={{
              fontSize: 30,
              fontWeight: 600,
              letterSpacing: "-0.01em",
              textAlign: "center",
              color: "#58BEC9",
            }}
          >
            Confirm Delete
          </DialogTitle>
        </div>
        <DialogContent className="text-center">
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#333333", fontSize: "16px" }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex items-center mb-32 justify-center">
          <Button
            style={{
              width: "105px",
              height: "54px",
              padding: "13px 28px 13px 28px",
              borderRadius: "12px",
              margin: "20px",
              backgroundColor: "#58BEC9",
              color: "#ffffff",
              fontSize: "16px",
            }}
            id="custom-alert-deleteconfirmation-btn-yes"
          >
            Yes
          </Button>

          <Button
            style={{
              width: "105px",
              height: "54px",
              padding: "13px 28px 13px 28px",
              borderRadius: "12px",
              margin: "20px",
              backgroundColor: "#9D9D9D",
              color: "#ffffff",
              fontSize: "16px",
            }}
            id="custom-alert-deleteconfirmation-btn-no"
          >
            No
          </Button>
        </DialogActions>
      </div>
    );
  }

  static GetDialogContent(props) {
    const { buttons, type, message, contentStyle } = props;
    return (
      <div
        id="custom-alert-webapp"
        style={{
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "10000",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "420px",
              height: "320px",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            {CustomAlert.GetContent(type, buttons, message, contentStyle)}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomAlert;
