import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import APP_CONFIG from 'app/AppConfig';


export const getAllTokens = createAsyncThunk('admin/tokensApp/getAllTokens', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/organizationTokens/getAllTokens`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});


export const manageToken = createAsyncThunk(
    'admin/tokensApp/createConfig',
    async (requestData, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/organizationTokens/addOrganizationsTokens/${requestData.data.organizationId}`;
        const response = await axios.put(url, requestData.data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        Promise.resolve(response).then((res) => {
            if (res.status == 200) {
                requestData.successCallback();
            }
        }).catch((error) => {

        });
    }
);


const tokensSlice = createSlice({
    name: 'admin/tokensApp',
    initialState: {
        tokens: []
    },
    extraReducers: {
        [getAllTokens.fulfilled]: (state, action) => {
            state.tokens = action.payload;
        }
    }
});

export const { } = tokensSlice.actions;

export default tokensSlice.reducer;