import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";
import {  createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const sendSnap = (scanName, toUsers, file) => async (dispatch, getState) => {
    //const token = localStorage.getItem('access_token');

    const url = APP_CONFIG.api + `v1/scan/scans/sendScanSnap`;


    var formData = new FormData();
    formData.append("scanName", scanName);
    formData.append("toUsers", toUsers);
    formData.append("file", file);

    const response = axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
   

    Promise.resolve(response).then((response) => {
        
    }).catch((error) => {
        console.error(error);
    });
};
export const sendPotreeErrorMail = createAsyncThunk(
    'sendPotreeErrorMail',
    async (errorDetails, { dispatch }) => {
       
        const url = APP_CONFIG.api + `v1/scan/scans/sendPotreeErrorMail`;

        const response = await axios.post(url, errorDetails, {
            headers: {
                'Content-Type': 'application/json',
            
            },

        });
        
        const data = await response.data;
        return data;
    }
);
