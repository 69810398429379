import * as React from 'react';
import styled from "@emotion/styled";
import { useEffect, useState, useRef } from "react";
import { demoLoadImageInfos } from '../../store/DashboardSlice';
import { Tooltip } from "@mui/material";

import { ZoomIn, ZoomOut, Fullscreen, ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import * as Pannellum from "pannellum";
import { useParams } from 'react-router-dom';
import axios from "axios";

const IMAGE_INFOS_LIMIT = 50000;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Spacer = styled.div`
  width: 50px;
  display: inline-block;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-bottom: 3px;
  height: 60px;
  color: #ffffff;
  font-size: 28px;
  border-radius: 5px;
`;
const Control = styled.div`
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 10px 10px;
  width: 40px;
  height: 40px;
`;
function DemoUserImageViewer(props) {

    const hiddenAddBeforeUnloadEventButtonInput = useRef();
    let { scanId, index } = useParams();

    const [viewer, setViewer] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSrc, setImageSrc] = useState(null);

    const [imageInfos, setImageInfos] = useState([]);
    const [broadcastChannel, setBroadcastChannel] = useState(null);


    useEffect(() => {
        const channel = new BroadcastChannel(window.location.origin);
        setBroadcastChannel(channel);
        setTimeout(() => {
            hiddenAddBeforeUnloadEventButtonInput.current.click();
        }, 1000)
        demoLoadImageInfos(scanId, 0, IMAGE_INFOS_LIMIT)
            .then((response) => {
                const _imageInfos = response.data;
                setImageInfos(_imageInfos);
                setCurrentIndex(index);
                const imageUrl = _imageInfos[index].imageUrl;
                loadPanorama(imageUrl);
                sendImageMessage(index, _imageInfos[index], channel);

            }).catch((error) => console.log(error));

    }, []);


    const addBeforeUnloadEvent = () => {
        window.addEventListener('beforeunload', beforeUnloadEventFunc);
    }
    const beforeUnloadEventFunc = (event) => {
        // event.preventDefault();
        if (broadcastChannel) {
            sendCloseMessage(broadcastChannel);
        }

    }
    const sendImageMessage = (index, imageInfo, channel) => {
        if (channel) {
            var data = {
                isClosed: false,
                index: index,
                imageInfo: imageInfo
            }
            channel.postMessage(data);
        }
    }
    const sendCloseMessage = (channel) => {
        if (channel) {
            var data = {
                isClosed: true
            }
            channel.postMessage(data);
        }
    }

    function loadPanorama(url) {
        console.log("Loading panorama: " + url);
        loadMedia(url)
            .then((response) => {
                const objectUrl = URL.createObjectURL(response.data);
                setImageSrc(objectUrl);
                setViewer(pannellum.viewer('panorama', {
                    "type": "equirectangular",
                    "panorama": objectUrl,
                    "autoLoad": true,
                    "showControls": false
                }));

            }).catch((error) => console.error(error));
    }

    function loadMedia(src) {
        const response = axios.get(src, {
            headers: {},
            responseType: 'blob',
        });

        return Promise.resolve(response);
    };

    function onPrevious() {
        if (currentIndex > 0) {
            const index = currentIndex - 1;
            setCurrentIndex(index);

            const url = imageInfos[index].imageUrl;
            loadPanorama(url);
            sendImageMessage(index, imageInfos[index], broadcastChannel);
        }
    }

    function onNext() {
        if (currentIndex < imageInfos.length - 1) {
            const index = Number(currentIndex) + 1;
            setCurrentIndex(index);
            const url = imageInfos[index].imageUrl;
            loadPanorama(url);
            sendImageMessage(index, imageInfos[index], broadcastChannel);
        }
    }

    return (
        <Container id="panorama">
            <input
                ref={hiddenAddBeforeUnloadEventButtonInput}
                type="button"
                onClick={() => addBeforeUnloadEvent()}
                style={{ display: 'none' }}
            />
            <img src={imageSrc} width="100%" height="100%" />
            <Spacer />
            <Controls id="controls">
                <Tooltip title="Pan Up">
                    <Control onClick={() => viewer.setPitch(viewer.getPitch() + 10)}>&#9650;</Control>
                </Tooltip>
                <Tooltip title="Pan Down">
                    <Control onClick={() => viewer.setPitch(viewer.getPitch() - 10)}>&#9660;</Control>
                </Tooltip>
                <Tooltip title="Pan Left">
                    <Control onClick={() => viewer.setYaw(viewer.getYaw() - 10)}>&#9664;</Control>
                </Tooltip>
                <Tooltip title="Pan Right">
                    <Control onClick={() => viewer.setYaw(viewer.getYaw() + 10)}>&#9654;</Control>
                </Tooltip>
                <Tooltip title="Zoom In">
                    <Control onClick={() => viewer.setHfov(viewer.getHfov() - 10)}><ZoomIn fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Zoom Out">
                    <Control onClick={() => viewer.setHfov(viewer.getHfov() + 10)}><ZoomOut fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Toggle Full Screen">
                    <Control onClick={() => viewer.toggleFullscreen()}><Fullscreen fontSize="large" /></Control>
                </Tooltip>
                <Spacer />
                <Tooltip title="Previous Panorama">
                    <Control onClick={() => onPrevious()}><ArrowCircleLeft fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Next Panorama">
                    <Control onClick={() => onNext()}><ArrowCircleRight fontSize="large" /></Control>
                </Tooltip>
            </Controls>
            <Spacer />
        </Container>
    );
}


export default DemoUserImageViewer;