import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { PiCrosshairFill } from "react-icons/pi";

const AlignToControlCompareCoordinates = ({
  buttonFunction,
  reset,
  stepName,
}) => {
  const buttonIcon = () =>
    reset ? (
      <RestartAltIcon className="scale-85" style={{ color: "rcfcdc9" }} />
    ) : (
      <PiCrosshairFill className="scale-85" style={{ color: "#59bec9" }} />
    );

  return (
    <Tooltip
      placement="right-start"
      title="Align the view to the selected ground control point"
    >
      <IconButton
        variant="outlined"
        style={{
          border: "1px solid #59bec9",
          borderRadius: "20px",
          width: "60px",
          height: "35px",
          marginTop: "7px",
          backgroundColor: "#F2F2F2",
          color: "#59bec9",
        }}
        onClick={() => buttonFunction(stepName)}
      >
        {buttonIcon()}
      </IconButton>
    </Tooltip>
  );
};

export default AlignToControlCompareCoordinates;
