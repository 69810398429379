import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APP_CONFIG from "app/AppConfig";
import axios from "axios";
import swal from "sweetalert";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import ErrorMessageConstants from "../../constants/ErrorMessageConstants";



export const getCustomerPortalLink = createAsyncThunk('billing/BillingApp/customerPortalLink', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/billing/customerPortalLink`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.data;
    window.open(data, 'billing', "height=1600,width=1000,left=600,top=20");
});


export const getCustomerPortalLinkById = createAsyncThunk('billing/BillingApp/getCustomerPortalLinkById', async (organisationId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/billing/customerPortalLinkById/${organisationId}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',

        },
    });
    const data = await response.data;
    if (data) {
        window.open(data, 'billing', "height=1600,width=1000,left=600,top=20");
    }
    else {
        swal("", "The organization has not added payment details or an error occurred while accessing. Please try again or contact support.", "warning")
    }
});

const billingSlice = createSlice({
    name: 'billing/billingApp',
    initialState: {
        organisationActiveSubscription: null
    },

    reducers: {
        resetData: (state, action) => {
            state.organisationActiveSubscription = null;
        },
    },
    extraReducers: {
    },
});

export const { resetData } = billingSlice.actions;

export default billingSlice.reducer;