import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "../../../../AppConfig";

export const deleteFailedUpload = createAsyncThunk('admin/wifiCaptureApp/deleteFailedUpload', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/wificapture/deleteFailedUpload/${requestData.id}`;
    const response = await axios.delete(url, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
    Promise.resolve(response).then((response) => {
        if (response.status == 200) {
            requestData.successCallback();
        }
    }).catch((error) => {

    });
});


export const restoreFailedUpload = createAsyncThunk('admin/wifiCaptureApp/restoreFailedUpload', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/wificapture/restoreFailedUpload/${requestData.id}`;
    const response = await axios.put(url, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
    Promise.resolve(response).then((response) => {
        if (response.status == 200) {
            requestData.successCallback();
        }
    }).catch((error) => {

    });
});

export const reprocessFailedUpload = createAsyncThunk('admin/wifiCaptureApp/reprocessFailedUpload', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/wificapture/reprocessFailedUpload/${requestData.id}`;
    const response = await axios.put(url, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
    Promise.resolve(response).then((response) => {
        if (response.status == 200) {
            requestData.successCallback();
        }
    }).catch((error) => {

    });
});

export const downloadFailedUploadLog = createAsyncThunk('admin/wifiCaptureApp/downloadFailedUploadLog', async (id, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/wificapture/downloadFailedUploadLog/${id}`;
    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            if (response.data) {
                const a = document.createElement('a');
                a.download = "test.bin";
                a.href = response.data;
                a.style.display = 'none';
                document.body.append(a);
                a.click();
                a.remove();
            }
            else {
                swal('', 'Log File Not Found', 'warning');
            }
        }
    }).catch((error) => {
        swal("", "Failed to download", "error");
    });
});


const wifiCaptureAppSlice = createSlice({
    name: 'admin/wifiCaptureApp',
    initialState: {
    },
    reducers: {
    },
    extraReducers: {
    },
});


export const { } = wifiCaptureAppSlice.actions;

export default wifiCaptureAppSlice.reducer;