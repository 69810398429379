import React from "react";

import PropTypes from "prop-types";
import { Dialog } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import CloseIcon from "@mui/icons-material/Close";
import SinglePointEditor from "./SinglePointEditor";
import { OuterPanel, Close } from "./CoordinateEditor.style";

function CoordinateCreator(props) {
  const { onAddCoordinate, onClose } = props;

  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth="md">
      <Close>
        <CloseIcon onClick={onClose} />
      </Close>
      <OuterPanel>
        <TabContext value={"single"}>
          <SinglePointEditor onDone={onAddCoordinate} />
        </TabContext>
      </OuterPanel>
    </Dialog>
  );
}

CoordinateCreator.propTypes = {
  onAddCoordinate: PropTypes.func,
  onAddCoordinates: PropTypes.func,
  onUpdateCoordinate: PropTypes.func,
  coordinate: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default CoordinateCreator;
