/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import * as THREE from "three";
import styled from "@emotion/styled";
import {
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Slider,
  Switch,
} from "@mui/material";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ToggleSwitch from "app/shared-components/toggleSwitch/ToggleSwitch";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

const Section = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
`;

const VegetationSlider = styled(Slider)((props) => ({
  height: 9,
  "& .MuiSld-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 44,
    height: 44,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#6CCCD7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

const VegetationRange = ({ viewer, unit }) => {
  const [range, setRange] = useState([-1, 400]);
  const [vegetationRangeExpanded, setVegetationRangeExpanded] = useState(false);
  const [enableGradient, setEnableGradient] = useState(false);
  const [gradientFullColor, setGradientFullColor] = useState(true);

  const handleOnChangeAccordion = () => {
    setVegetationRangeExpanded(!vegetationRangeExpanded);
  };

  const toggleGradientMode = () => {
    setGradientFullColor(!gradientFullColor);
    viewer.scene.pointclouds[0].material.gradient = !gradientFullColor
      ? [
          [0.0, new THREE.Color(0.44, 0.5, 0.56)],
          [0.143, new THREE.Color(1.0, 0.0, 0.0)],
          [0.286, new THREE.Color(1.0, 0.5, 0.0)],
          [0.429, new THREE.Color(1.0, 1.0, 0.0)],
          [0.571, new THREE.Color(0.0, 1.0, 0.0)],
          [0.714, new THREE.Color(0.0, 0.5, 1.0)],
          [0.857, new THREE.Color(0.29, 0.0, 0.51)],
          [1.0, new THREE.Color(0.0, 0.5, 0.0)],
        ]
      : [
          [0.0, new THREE.Color(0.44, 0.5, 0.56)],
          [0.255, new THREE.Color(1.0, 0.0, 0.0)],
          [0.63, new THREE.Color(0.29, 0.0, 0.51)],
        ];
  };

  const onChangVegetationRange = (e) => {
    setRange(e.target.value);
    viewer.scene.pointclouds[0].pcoGeometry.pointAttributes.attributes[11].range =
      e.target.value;
  };

  const toggleGradient = () => {
    setEnableGradient(!enableGradient);
    if (enableGradient) {
      viewer.scene.pointclouds[0].material.activeAttributeName = "rgba";
    } else {
      viewer.scene.pointclouds[0].material.activeAttributeName = "Veg Distance";
      viewer.scene.pointclouds[0].material.gradient = [
        [0.0, new THREE.Color(0.44, 0.5, 0.56)],
        [0.143, new THREE.Color(1.0, 0.0, 0.0)],
        [0.286, new THREE.Color(1.0, 0.5, 0.0)],
        [0.429, new THREE.Color(1.0, 1.0, 0.0)],
        [0.571, new THREE.Color(0.0, 1.0, 0.0)],
        [0.764, new THREE.Color(0.0, 0.5, 1.0)],
        [0.957, new THREE.Color(0.29, 0.0, 0.51)],
        [1.0, new THREE.Color(0.0, 0.5, 0.0)],
      ];

      viewer.scene.pointclouds[0].pcoGeometry.pointAttributes.attributes[11].range =
        range;
      viewer.scene.pointclouds[0].children[0].geometry.attributes[
        "Veg Distance"
      ].needsUpdate = true;
    }
  };

  return (
    <Accordion
      className="m-0"
      expanded={vegetationRangeExpanded}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          vegetationRangeExpanded ? (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel6bh-content"
        id="panel6"
        className="m-0"
      >
        <TypographyStyles>Vegetation Encroachment</TypographyStyles>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <Section>
          <FormGroup>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 600,
                },
              }}
              control={
                <Switch checked={enableGradient} onChange={toggleGradient} />
              }
              label="Visualize Heatmap"
            />
          </FormGroup>
          <div className="mt-24">
            <ToggleSwitch
              checked={gradientFullColor}
              onToggleChange={toggleGradientMode}
              yes={"Full Spectrum"}
              no={"Dual Color"}
            ></ToggleSwitch>
          </div>
          <div
            style={{
              marginTop: "28px",
              pointerEvents: !enableGradient ? "none" : "auto",
            }}
          >
            <span className="font-boldi mt-12">Encroachment Range</span>
            <VegetationSlider
              step={20}
              min={0}
              max={1000}
              marks
              onChange={onChangVegetationRange}
              value={range}
              valueLabelDisplay="auto"
            />
          </div>
        </Section>
      </AccordionDetails>
    </Accordion>
  );
};

export default VegetationRange;
