/* eslint-disable no-undef */
/* eslint-disable complexity */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CoordinateSave from "../../coordinate-viewer/CoordinateSave";
import FileImportParser from "./FileImportParser";
import {
  Button,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  IconButton,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
  InputLabel,
  TableRow,
  Drawer,
  Paper,
  TableFooter,
  Select,
  FormControl,
  Divider,
  Tooltip,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import CoordinateCreator from "../../coordinate-viewer/CoordinateCreator";
import * as THREE from "three";
import Box from "@mui/material/Box";
import {
  getSiteCoordinates,
  updateSiteCoordinates,
} from "../../../store/sitesSlice";
import {
  Error,
  Header,
  Row,
  Buttons,
  CloseButton,
  Spacer,
  Units,
  OuterPanel,
} from "../Styles/AlignToControl.style";
import CoordinateEditor from "../../coordinate-viewer/CoordinateEditor";
import {
  startProcessingAlignToControlScan,
  checkGPSposesFileExistForScan,
  getScanAlignToControlStatus,
} from "app/main/apps/admin/store/scanProcessSlice";
import { revertBackScanPotreeFile } from "app/main/apps/admin/store/scanSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Menu from "@mui/material/Menu";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import AlignToControlConfirmDialog from "app/shared-components/dialogs/AlignToControlConfirmDialog";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import { RiDeleteBinLine } from "react-icons/ri";
import CommonStyles from "app/main/apps/styles/CommonStyles";
import { createStyles, makeStyles } from "@mui/styles";
import { hexToRgba } from "../../classification/ClassificationColorConvertor";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import NewConfirmationDialogBox from "app/shared-components/dialogs/NewConfirmationDialogBox";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import NewErrorDialogBox from "app/shared-components/dialogs/NewErrorDialogBox";
import KeyEventListeners from "../../coordinate-viewer/KeyEventListeners";
import ConvertCoordinateValue from "app/main/apps/common/ConvertCoordinateValue";
import CustomAlert from "app/main/apps/common/CustomAlert";
import { getRevertToOriginalInProgress } from "app/main/apps/admin/store/scanSlice";
import swal from "sweetalert";
import AlignToControlImport from "./AlignToControlImport";
import WizardButton from "./WizardButton";

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      marginTop: "80px",
      height: "calc(100vh - 80px)",
      color: "#000000",
      fontWeight: "500",
    },
    active: {
      borderRadius: "24px",
      padding: "5px 12px",
      textTransform: "none",
      backgroundColor: "#ffffff !important",
    },
    inactive: {
      borderRadius: "24px",
      padding: "5px 12px",
      textTransform: "none",
      border: "none",
      backgroundColor: "transparent !important",
    },
    tableBody: {
      "&:hover": {
        backgroundColor: "#ededed !important",
        cursor: "pointer !important",
      },
    },
    buttonEnable: {
      padding: "2px",
      color: "#3a3a3a",
    },
    buttonDisable: {
      padding: "2px",
      color: "#afa9a9",
    },
    alignToControlEnable: {
      color: "#58BEC9",
      borderColor: "#58BEC9",
      padding: "5px 8px",
      fontSize: "10px",
    },
    alignToControlDisable: {
      color: "rgb(175 205 208) !important",
      borderColor: "rgb(186 214 217) !important",
      padding: "5px 8px",
      fontSize: "10px",
    },
  })
);

const TWEEN = window.TWEEN;

const AlignToControlViewer = forwardRef((props, ref) => {
  const [siteCoordinates, setSiteCoordinates] = useState([]);

  const dispatch = useDispatch();
  const commonStyles = CommonStyles();

  const {
    onClose,
    viewer,
    siteId,
    scene,
    setScene,
    site,
    rightDrawerOpen,
    cleanCoordinates,
    addCoordinateSphere,
    retrieveSiteCoordinatesInfo,
    removeCoordinateSphere,
    removeCoordinateSingleSphere,
    updateSpheres,
    lastMeasure,
    setLastMeasure,
    showSelectedMarker,
    isVisible,
    setShowSelectedMarker,
    handleOnShowSelectedMarker,
    selectedMarkerIndex,
    setSelectedMarkerIndex,
    dataPanel,
    isMarkerAdd,
    setIsMarkerAdd,
    loadedScanIds,
    toggleDrawer,
    toggleLeftDrawer,
    reloadSiteData,
    unloadPointClouds,
    setOpenEditCoordinate,
    openEditCoordinate,
    coordinates,
    setEnableAlignToControl,
    labelsRef,
    addModelCoordinate,
    setAddModelCoordinate,
    setCoordinates,
    oldCoordinates,
    setOldCoordinates,
    selectedCoordinates,
    markersRef,
    historyCountRef,
    labels,
    setLabels,
    positionLabel,
    setPositionLabel,
    transformationErrors,
    setTransformationErrors,
    previous,
    addDataToCoordinates,
    setPrevious,
    coordinatesRef,
    historyCount,
    setHistoryCount,
    handleControlPointWizardClick,
    markers,
    setMarkers,
  } = props;

  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    clearModelCoordinate(index) {
      return clearModelCoordinate(index);
    },
    importCoordinates() {
      importUTM_coord();
    },
    handleOnSelectAll(status) {
      handleOnSelectAll(status);
    },
    retrieveSiteCoordinatesInfo(id) {
      return retrieveSiteCoordinatesInfo(viewer.scene.scene, id);
    },
    getIsCoordinateSave() {
      return isCoordinateDataModified();
    },
    startCoordinateSelection(index) {
      return startCoordinateSelection(index);
    },
    handleOnAlignToControlClick() {
      return handleOnAlignToControlClick();
    },
    updateCoordinates() {
      onClickUpdateCoordinates();
      return true;
    },
    undoAddedMarker() {
      if (addModelCoordinate) {
        handleOnUndoClick();
      }
    },
    redoAddedMarker() {
      if (addModelCoordinate) {
        handleOnRedo();
      }
    },
    checkIsInEditMode() {
      return getIsInEditMode();
    },
    exitFromAddMode() {
      if (addModelCoordinate) {
        handleOnExitAddMode();
      }
    },
    updateCoordinateLabels() {
      setTimeout(function () {
        addOrUpdateCoordinateTextLables();
      }, 200);
    },
    cleanCoordinatesRef() {
      return cleanCoordinates();
    },
  }));

  const tableRef = useRef();

  const [unit, setUnit] = useState("utm");
  const [coordCreatorVisible, setCoordCreatorVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  // const [scene, setScene] = useState(null);
  useState();
  const [coordSaveVisible, setCoordSaveVisible] = useState(false);
  const [siteCoordinateId, setSiteCoordinateId] = useState();
  const [coordinatesModified, setCoordinatesModified] = useState(false);

  useEffect(() => {
    if (isCoordinateDataModified()) {
      setCoordinatesModified(true);
    }
  }, [coordinates, oldCoordinates]);

  // const [positionLabel, setPositionLabel] = useState(null);
  const { scanAlignToControlStatus } = useSelector(
    ({ admin }) => admin.scanProcess
  );

  useEffect(() => {
    var label = addLabel({ x: 0, y: 0, z: 0 }, "");
    label.visible = false;
    setPositionLabel(label);
    viewer.scene.scene.add(label);
  }, []);

  const RED = { r: 1, g: 0, b: 1 };

  useEffect(() => {
    if (siteCoordinateId) {
      const selectedSite = siteCoordinates?.find(
        (site) => site.id === siteCoordinateId
      );
      if (selectedSite) {
        setSiteCoordinateId(selectedSite.id);
      }
    }
  }, [siteCoordinates, siteCoordinateId]);

  useEffect(() => {
    const _scene = viewer.scene.scene;
    setScene(_scene);
    retrieveSiteCoordinates();
  }, []);

  useEffect(() => {
    if (markers.length > 0) {
      addCoordinateSphere(markers);
    }
  }, [markers]);

  // const coordinatesRef = useRef();
  useEffect(() => {
    coordinatesRef.current = coordinates;

    if (coordinates.length > 0) {
      addOrUpdateCoordinateTextLables();
    } else {
      if (viewer) {
        labels.forEach((label) => {
          viewer.scene.scene.remove(label);
        });
      }
    }
  }, [coordinates, viewer]);

  // const markersRef = useRef();
  // const labelsRef = useRef();
  useEffect(() => {
    labelsRef.current = labels;
    markersRef.current = markers;
  }, [labels, markers]);

  useEffect(() => {
    return () => {
      let _markers = [...markersRef.current];
      let _labels = [...labelsRef.current];

      if (viewer) {
        _labels.forEach((label) => {
          viewer.scene.scene.remove(label);
        });

        if (lastMeasure) {
          lastMeasure.removeMarker(0);
          viewer.scene.removeMeasurement(lastMeasure);
        }
        viewer.scene.scene.remove(positionLabel);
      }
      removeCoordinateSphere(_markers);
      if (addModelCoordinate && isMarkerAdd) {
        KeyEventListeners.removeEventListener();
        handleOnExitAddMode();
      }
    };
  }, []);

  useEffect(() => {
    if (loadedScanIds[0]) {
      dispatch(getScanAlignToControlStatus(loadedScanIds[0]));
      getRevertToOriginalInProgres(loadedScanIds[0]);
    }
  }, []);

  const getRevertToOriginalInProgres = (scanId) => {
    Promise.resolve(getRevertToOriginalInProgress(scanId))
      .then((res) => {
        if (res.data == true) {
          setEnableAlignToControl(true);
        } else {
          setEnableAlignToControl(false);
        }
      })
      .catch(() => {});
  };

  const addOrUpdateCoordinateTextLables = () => {
    var _labels = labelsRef.current || [];
    let validLables = coordinates.filter(
      (coordinate, index) => coordinate.name && isVisible(index)
    );
    if (validLables && validLables.length > _labels.length) {
      let addCount = validLables.length - _labels.length;
      for (let i = 0; i < addCount; i++) {
        try {
          // eslint-disable-next-line no-undef
          let coordinateLabel = new Potree.TextSprite();
          coordinateLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
          coordinateLabel.setBackgroundColor({ r: 89, g: 134, b: 201, a: 1.0 });

          coordinateLabel.fontsize = 16;
          coordinateLabel.material.depthTest = false;
          coordinateLabel.material.opacity = 1;
          coordinateLabel.visible = true;
          viewer.scene.scene.add(coordinateLabel);
          _labels.push(coordinateLabel);
        } catch (error) {
          console.error(error);
        }
      }
    } else if (validLables.length < _labels.length) {
      let removeCount = _labels.length - validLables.length;
      for (var i = 0; i < removeCount; i++) {
        viewer.scene.scene.remove(_labels[_labels.length - 1]);
        _labels.splice(_labels.length - 1, 1);
      }
    }

    if (validLables.length == _labels.length) {
      for (var j = 0; j < validLables.length; j++) {
        let coord = new THREE.Vector3(
          validLables[j].control
            ? validLables[j].control.x
            : validLables[j].model.x,
          validLables[j].control
            ? validLables[j].control.y
            : validLables[j].model.y,
          validLables[j].control
            ? validLables[j].control.z
            : validLables[j].model.z
        );
        const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
        let clientWidth = renderAreaSize.width;
        let clientHeight = renderAreaSize.height;
        let camera = viewer.scene.getActiveCamera();
        let distance = camera.position.distanceTo(coord);
        let pr = projectedRadius(
          1,
          camera,
          distance,
          clientWidth,
          clientHeight
        );

        _labels[j].setText(validLables[j].name);

        let screenPos = coord.clone().project(camera);
        screenPos.x = Math.round(((screenPos.x + 1) * clientWidth) / 2);
        screenPos.y = Math.round(((-screenPos.y + 1) * clientHeight) / 2);
        screenPos.z = 0;
        screenPos.y -= 30;
        let labelPos = new THREE.Vector3(
          (screenPos.x / clientWidth) * 2 - 1,
          -(screenPos.y / clientHeight) * 2 + 1,
          0.5
        );
        labelPos.unproject(camera);
        if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
          let direction = labelPos.sub(camera.position).normalize();
          labelPos = new THREE.Vector3().addVectors(
            camera.position,
            direction.multiplyScalar(distance)
          );
        }
        _labels[j].position.copy(labelPos);
        let labelscale = 70 / pr;
        _labels[j].scale.set(labelscale, labelscale, labelscale);
      }
    }

    setLabels(_labels);
  };

  function updateInfo(index, name, code) {
    const _coordinates = [...coordinates];
    const _coordinate = _coordinates[index];
    _coordinate.name = name;
    _coordinate.code = code;
    setCoordinates(_coordinates);
  }

  function clearModelCoordinate(index) {
    var _markers = [...markers];
    var _coordinates = [...coordinates];
    if (_markers[index].model) {
      _markers[index].model.visible = false;
    }
    removeCoordinateSingleSphere(_markers[index].model);
    delete _coordinates[index].model;
    delete _markers[index].model;
    setMarkers(_markers);
    setCoordinates(_coordinates);
  }

  // function addOrUpdateControlCoordinate(coordinate, index) {
  //   const _coordinates = [...coordinates];
  //   const _markers = [...markers];
  //
  //   if (index !== undefined && index >= 0) {
  //     const marker = _markers[index];
  //     const sphere = marker.control || addMarker(coordinate.control);
  //     moveMarker(
  //       sphere,
  //       coordinate.control.x,
  //       coordinate.control.y,
  //       coordinate.control.z
  //     );
  //     marker.control = sphere;
  //     _coordinates[index] = coordinate;
  //   } else {
  //     coordinate.id = createUUID();
  //     const sphere = addMarker(coordinate.control);
  //     _coordinates.unshift(coordinate);
  //     _markers.unshift({ control: sphere, coordinateId: coordinate.id });
  //   }
  //   scene.add(sphere);
  //   setCoordinates(_coordinates);
  //   setMarkers(_markers);
  // }

  function addOrUpdateControlCoordinate(coordinate, index) {
    const _coordinates = [...coordinates];
    const _markers = [...markers];

    if (index !== undefined && index >= 0) {
      const marker = _markers[index];
      if (!marker.control) {
        const sphere = addMarker(coordinate.control);
        marker.control = sphere;
        scene.add(sphere);
      } else {
        moveMarker(
          marker.control,
          coordinate.control.x,
          coordinate.control.y,
          coordinate.control.z
        );
      }
      _coordinates[index] = coordinate;
    } else {
      coordinate.id = createUUID();
      const sphere = addMarker(coordinate.control);
      _coordinates.unshift(coordinate);

      _markers.unshift({ control: sphere, coordinateId: coordinate.id });
      scene.add(sphere);
    }
    setCoordinates(_coordinates);
    setMarkers(_markers);
    setCoordCreatorVisible(false);
    setEditingIndex(-1);
  }

  function addMarker(coordinate, color) {
    const geometry = new THREE.SphereGeometry(0.4);
    const material = new THREE.MeshBasicMaterial({
      color: color ? color : 0xe55846,
    });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.x = coordinate.x;
    sphere.position.y = coordinate.y;
    sphere.position.z = coordinate.z;
    const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
    let clientWidth = renderAreaSize.width;
    let clientHeight = renderAreaSize.height;
    let camera = viewer.scene.getActiveCamera();
    let distance = camera.position.distanceTo(
      sphere.getWorldPosition(new THREE.Vector3())
    );
    let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);
    let scale = 15 / pr;
    sphere.scale.set(scale, scale, scale);
    return sphere;
  }

  function projectedRadius(
    radius,
    camera,
    distance,
    screenWidth,
    screenHeight
  ) {
    if (camera.type == "OrthographicCamera") {
      return projectedRadiusOrtho(
        radius,
        camera.projectionMatrix,
        screenWidth,
        screenHeight
      );
    } else if (camera.type == "PerspectiveCamera") {
      return projectedRadiusPerspective(
        radius,
        (camera.fov * Math.PI) / 180,
        distance,
        screenHeight
      );
    } else {
      throw new Error("invalid parameters");
    }
  }

  function projectedRadiusOrtho(radius, proj, screenWidth, screenHeight) {
    let p1 = new THREE.Vector4(0);
    let p2 = new THREE.Vector4(radius);

    p1.applyMatrix4(proj);
    p2.applyMatrix4(proj);
    p1 = new THREE.Vector3(p1.x, p1.y, p1.z);
    p2 = new THREE.Vector3(p2.x, p2.y, p2.z);
    p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
    p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
    p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
    p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
    return p1.distanceTo(p2);
  }

  function projectedRadiusPerspective(radius, fov, distance, screenHeight) {
    let projFactor = 1 / Math.tan(fov / 2) / distance;
    projFactor = (projFactor * screenHeight) / 2;

    return radius * projFactor;
  }

  function moveMarker(marker, x, y, z) {
    marker.position.x = x;
    marker.position.y = y;
    marker.position.z = z;
  }

  function getCoordinateError(coordinate, axis) {
    if (!coordinate.model || !coordinate.control) {
      return "";
    }
    const control = coordinate.control[axis];
    const model = coordinate.model[axis];
    return Math.abs(control - model).toFixed(3);
  }

  function getTotalErrorRMSValue() {
    if (
      !transformationErrors ||
      Object.keys(transformationErrors).length === 0
    ) {
      return "";
    }

    const sumOfSquares = Object.values(transformationErrors).reduce(
      (sum, error) => sum + Math.pow(error, 2),
      0
    );

    const rms = Math.sqrt(
      sumOfSquares / Object.keys(transformationErrors).length
    ).toFixed(3);

    return rms;
  }

  function handleOnEditCoordinateClose() {
    let _editIndex = editingIndex;
    if (!coordinates[_editIndex].model && !coordinates[_editIndex].control) {
      deleteMarker(_editIndex);
    }
    setEditingIndex(-1);
  }

  function editMarker(index) {
    setEditingIndex(index);
  }

  function toggleMarker(index) {
    const _markers = [...markers];

    if (_markers[index].control) {
      _markers[index].control.visible = !_markers[index].control.visible;
    }

    if (_markers[index].model) {
      _markers[index].model.visible = !_markers[index].model.visible;
    }

    if (_markers[index].line) {
      _markers[index].line.visible = !_markers[index].line.visible;
    }

    setMarkers(_markers);
  }

  const handleOnShowHideMarker = () => {
    selectedMarkerIndex.forEach((index) => {
      toggleMarker(index);
    });
  };

  function deleteMarker(index) {
    const _markers = markersRef.current;
    //const _markers =[...markers];

    if (_markers[index].control) {
      _markers[index].control.visible = false;
    }

    if (_markers[index].model) {
      _markers[index].model.visible = false;
    }

    if (_markers[index].line) {
      _markers[index].line.visible = false;
    }
    removeCoordinateSphere([_markers[index]]);
    _markers.splice(index, 1);
    setMarkers(_markers);

    const _coordinates =
      coordinatesRef.current != undefined ? coordinatesRef.current : [];
    // const _coordinates = [...coordinates];
    _coordinates.splice(index, 1);
    setCoordinates(_coordinates);
  }

  function zoomInAndOutMarker(radius, location) {
    let targetRadius = radius;
    let d = viewer.scene.view.direction.multiplyScalar(-1);
    let cameraTargetPosition = new THREE.Vector3().addVectors(
      location,
      d.multiplyScalar(targetRadius)
    );
    let animationDuration = 600;
    let easing = TWEEN.Easing.Quartic.Out;
    let value = { x: 0 };
    let tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);
    tween.easing(easing);
    viewer.controls.tweens.push(tween);
    let startPos = viewer.scene.view.position.clone();
    let targetPos = cameraTargetPosition.clone();
    let startRadius = viewer.scene.view.radius;
    let targetRadius1 = cameraTargetPosition.distanceTo(location);
    tween.onUpdate(() => {
      let t = value.x;
      viewer.scene.view.position.x = (1 - t) * startPos.x + t * targetPos.x;
      viewer.scene.view.position.y = (1 - t) * startPos.y + t * targetPos.y;
      viewer.scene.view.position.z = (1 - t) * startPos.z + t * targetPos.z;

      viewer.scene.view.radius = (1 - t) * startRadius + t * targetRadius1;
      viewer.setMoveSpeed(viewer.scene.view.radius / 3.5);
    });

    tween.onComplete(() => {
      viewer.controls.tweens = viewer.controls.tweens.filter(
        (e) => e !== tween
      );
      updateSpheres();
    });
    tween.start();
  }

  // const [lastMeasure, setLastMeasure] = useState(null);

  function startCoordinateSelection(index) {
    var measure = viewer.measuringTool.startAlignToControlInsertion();
    setLastMeasure(measure);
    measure.addEventListener("marker_dropped", (markerDroppedEvent) => {
      const position = markerDroppedEvent.target.points[0].position;
      const coordinate = {
        x: Number(position.x.toFixed(3)),
        y: Number(position.y.toFixed(3)),
        z: Number(position.z.toFixed(3)),
      };

      var _coordinates = coordinatesRef.current;
      var _markers = [...markersRef.current];

      if (coordinate.x != 0 && coordinate.y != 0 && coordinate.z != 0) {
        const marker = addMarker(coordinate, 0x34e1eb);
        if (index !== undefined && index >= 0) {
          _coordinates = [...coordinates];
          _markers = [...markers];
          if (_markers[index].model) {
            removeCoordinateSingleSphere(_markers[index].model);
            delete _markers[index].model;
          }
          if (_coordinates[index].model) {
            delete _coordinates[index].model;
          }

          _coordinates[index].model = coordinate;
          _markers[index].model = marker;
        } else {
          var name = 1000 + _coordinates.length + 1;

          const _coordinate = {
            model: coordinate,
            name: name.toString(),
            isAddModeEnabled: addModelCoordinate,
            id: createUUID(),
          };
          _coordinates.unshift(_coordinate);
          _markers.unshift({ model: marker, coordinateId: _coordinate.id });
          manageSphareDragging(marker, _coordinate.id);
        }

        scene.add(marker);
        viewer.scene.removeMeasurement(markerDroppedEvent.target);
      }

      setMarkers(_markers);
      setCoordinates(_coordinates);

      if (addModelCoordinate && isMarkerAdd) {
        setPrevious([]);
        var _historyCount = historyCountRef.current;
        _historyCount = _historyCount + 1;
        setHistoryCount(_historyCount);
        setIsMarkerAdd(false);
      }
    });
  }

  function onMouseEnter(index) {
    if (markers[index].control) {
      markers[index].control.material.color = RED;
    } else if (markers[index].model) {
      markers[index].model.material.color = RED;
    }
  }

  function onMouseLeave(index) {
    if (markers[index].control) {
      markers[index].control.material.color = {
        r: hexToRgba("#e55846")[0],
        g: hexToRgba("#e55846")[1],
        b: hexToRgba("#e55846")[2],
      };
    } else if (markers[index].model) {
      markers[index].model.material.color = {
        r: hexToRgba("#58BEC9")[0],
        g: hexToRgba("#58BEC9")[1],
        b: hexToRgba("#58BEC9")[2],
      }; //  GREEN;
    }
  }

  const getScansCoordinatesId = () => {
    return site.scans.find((x) => x.id == loadedScanIds[0]).coordinatesId;
  };
  // eslint-disable-next-line no-unused-vars
  const [currentScanCoordinateId, setCurrentScanCoordinateId] = useState(
    getScansCoordinatesId
  );

  const onClickUpdateCoordinates = () => {
    try {
      if (isCoordinateDataModified() || coordinatesModified) {
        const saveCoordinatesInfo = coordinates.map((element) => {
          return {
            name: element.name || null,
            code: element.code || null,
            xmodelCoordinate: element.model ? element.model.x : null,
            ymodelCoordinate: element.model ? element.model.y : null,
            zmodelCoordinate: element.model ? element.model.z : null,
            xcontrolCoordinate: element.control ? element.control.x : null,
            ycontrolCoordinate: element.control ? element.control.y : null,
            zcontrolCoordinate: element.control ? element.control.z : null,
          };
        });
        const saveObj = {
          siteId: siteId,
          id: currentScanCoordinateId,
          siteCoordinatesInfos: saveCoordinatesInfo,
        };

        dispatch(
          updateSiteCoordinates({
            scanId: loadedScanIds[0],
            data: saveObj,
            successCallBack: updateSiteCoordinatesSuccessCallback,
          })
        );
        setOldCoordinates(JSON.parse(JSON.stringify(coordinates)));
      } else {
        CustomAlert.show({
          type: "error",
          message: "Coordinates have not been modified!",
          buttons: [],
        });
      }
    } catch (error) {
      console.error("Error in save coordinates", error);
    }
  };

  const updateSiteCoordinatesSuccessCallback = (response) => {
    CustomAlert.show({
      type: "success",
      message: "Updated Successfully",
      buttons: [],
    }).then(() => {
      retrieveSiteCoordinates();
      if (response) {
        setCurrentScanCoordinateId(response);
      }
      var _isAligntoControlClick = isAlignToControlClickedRef.current;

      if (_isAligntoControlClick) {
        setShowAlignToControlDialog(true);
      }
    });
  };

  function retrieveSiteCoordinates() {
    Promise.resolve(getSiteCoordinates(siteId))
      .then((response) => {
        if (response.status === 200) {
          const savedFiles = new Map();

          response.data.forEach((item) => {
            if (!savedFiles.has(item.siteCoordinateName)) {
              savedFiles.set(item.siteCoordinateName, item);
            }
          });
          const uniqueList = Array.from(savedFiles.values());
          setSiteCoordinates(uniqueList);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  }

  const handleOnDeleteAll = () => {
    var _coordinates = [...coordinates];
    var _markers = [...markers];
    var _selectedMarkerIndex = [...selectedMarkerIndex];

    var _selectedCoordinates = _coordinates.filter((_, index) =>
      _selectedMarkerIndex.includes(index)
    );
    var selectedMarkers = _markers.filter((_, index) =>
      _selectedMarkerIndex.includes(index)
    );

    var deletedAddModePoints = _selectedCoordinates.filter(
      (x) => x.isAddModeEnabled == true
    ).length;
    removeCoordinateSphere(selectedMarkers);

    _coordinates = _coordinates.filter(
      (_, index) => !_selectedMarkerIndex.includes(index)
    );
    _markers = _markers.filter(
      (_, index) => !_selectedMarkerIndex.includes(index)
    );

    setCoordinates(_coordinates);
    setMarkers(_markers);
    setSelectedMarkerIndex([]);
    setHistoryCount(historyCount - deletedAddModePoints);
  };

  // function onClickDeleteAllCoordinates() {
  //   CustomAlert.show({
  //     type: "deleteconfirmation",
  //     message: "Do you want to delete all the coordinates?",
  //     buttons: [],
  //   }).then((res) => {
  //     if (res == "yes") {
  //       cleanCoordinates();
  //       Promise.resolve(deleteSiteCoordinates(siteCoordinateId))
  //         .then(() => {
  //           retrieveSiteCoordinates();
  //         })
  //         .catch((error) => {
  //           console.warn("Cannot delete data", error);
  //         });
  //     }
  //   });
  // }

  //download csv
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  // Download as UTM
  const downloadAsUTM_Csv = (e) => {
    setOpenDownloadSelect(false);
    e.preventDefault();
    // let headers = "";

    let headers = [
      [
        "Model Id",
        "Model Easting",
        "Model Northing",
        "Model Elevation",
        "Control Id",
        "Control Easting",
        "Control Northing",
        "Control Elevation",
        "Residual Error",
        "Code",
      ],
    ];
    coordinates.forEach((coord) => {
      var rowData = [];

      rowData.push(coord.name ? coord.name : "");
      rowData.push(
        coord.model
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.model.x)
          : ""
      );
      rowData.push(
        coord.model
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.model.y)
          : ""
      );
      rowData.push(
        coord.model
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.model.z)
          : ""
      );
      rowData.push(coord.name ? coord.name : "");
      rowData.push(
        coord.control
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.control.x)
          : ""
      );
      rowData.push(
        coord.control
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.control.y)
          : ""
      );
      rowData.push(
        coord.control
          ? ConvertCoordinateValue.getConvertedCoordinatedValue(coord.control.z)
          : ""
      );
      rowData.push(
        Object.keys(transformationErrors).length != 0 &&
          transformationErrors[coord.name] != -1 &&
          coord.name
          ? transformationErrors[coord.name]
          : ""
      );
      rowData.push(coord.code ? coord.code : "");
      headers.push(rowData);
    });

    downloadFile({
      data: [headers.join("\n")],
      fileName: `${site.name}_UTM.csv`,
      fileType: "text/csv",
    });
  };

  // import file as csv
  const utmFile = React.useRef(null);
  const importUTM_coord = () => {
    // utmFile.current.click();
    handleOpenModal();
  };

  const uploadUTM = (csvFile) => {
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      if (checkFileFormat(text, "utm")) {
        // processCSV(text, "utm");
      } else {
        setShowErrorAlert(true);
        setErrorMessage("Please import correct format file");
        setErrorDescription("");
        setTextAlign("center");
      }
    };

    setSiteCoordinateId();
    reader.readAsText(file);
  };

  const handleFileData = (data) => {
    setImportedData(data);
    setModalOpen(false);

    if (isCoordinateDataModified() == false) {
      cleanCoordinates();
      processCSVFromParser(data);
    } else if (coordinates.length > 0 && isCoordinateDataModified() == true) {
      const onYesClick = () => {
        setShowOnCloseAlert(false);
        onClickUpdateCoordinates();

        processCSVFromParser(data);
      };
      const onNoClick = () => {
        setShowOnCloseAlert(false);

        processCSVFromParser(data);
      };
      // showAlert(onYesClick, onNoClick);
      setOnYes(() => onYesClick);
      setOnNo(() => onNoClick);
      setShowOnCloseAlert(true);
      setDialogTitle("Save Changes");
      setDialogMessage(
        "Do you want to apply these changes to Coordinate Tools before closing?"
      );
    } else {
      processCSVFromParser(data);
    }
  };

  const handleOnUTMFileChange = (e) => {
    KeyEventListeners.removeEventListener();
    const fileUploaded = e.target.files[0];
    utmFile.current.value = "";

    setSiteCoordinateId(null);

    if (isCoordinateDataModified() == false) {
      cleanCoordinates();
      uploadUTM(fileUploaded);
    } else if (coordinates.length > 0 && isCoordinateDataModified() == true) {
      const onYesClick = () => {
        setShowOnCloseAlert(false);
        onClickUpdateCoordinates();
        uploadUTM(fileUploaded);
      };
      const onNoClick = () => {
        setShowOnCloseAlert(false);
        uploadUTM(fileUploaded);
      };
      // showAlert(onYesClick, onNoClick);
      setOnYes(() => onYesClick);
      setOnNo(() => onNoClick);
      setShowOnCloseAlert(true);
      setDialogTitle("Save Changes");
      setDialogMessage(
        "Do you want to apply these changes to Coordinate Tools before closing?"
      );
    } else {
      uploadUTM(fileUploaded);
    }
  };

  const checkFileFormat = (data, unit) => {
    const csvdata = data.split("\n");
    var headerData = [];
    if (csvdata.length > 0) {
      var headdata = csvdata[0].split(",");
      if (headdata.length > 0) {
        headerData.push(headdata[0]);
      } else {
        headerData.push("");
      }
      if (headdata.length > 1) {
        headerData.push(headdata[1]);
      } else {
        headerData.push("");
      }
      if (headdata.length > 2) {
        headerData.push(headdata[2]);
      } else {
        headerData.push("");
      }
      if (headdata.length > 3) {
        headerData.push(headdata[3]);
      } else {
        headerData.push("");
      }
      if (headdata.length > 4) {
        headerData.push(headdata[4]);
      } else {
        headerData.push("");
      }
    }
    if (headerData.length > 0) {
      if (unit == "utm") {
        if (
          headerData[1].toLowerCase() == "easting" &&
          headerData[2].toLowerCase() == "northing" &&
          headerData[3].toLowerCase() == "elevation"
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const processCSVFromParser = (data) => {
    console.log("DATA");
    console.log(data);
    if (data) {
      var importMappings = [];
      data.selectedRows.forEach((row) => {
        let dataObj = {
          name: row.name || "",
          xcontrolCoordinate: convertStringToInt(row.easting.trim()) || 0,
          ycontrolCoordinate: convertStringToInt(row.northing.trim()) || 0,
          zcontrolCoordinate: convertStringToInt(row.elevation.trim()) || 0,
          xmodelCoordinate: 0,
          ymodelCoordinate: 0,
          zmodelCoordinate: 0,
          code: row.description || "",
        };
        importMappings.push(dataObj);
      });
      addDataToCoordinates(importMappings, scene);
      handleOnSelectAll(true);
      setSiteCoordinateId();
      setCoordinatesModified(true);
    }
  };

  const convertStringToInt = (value) =>
    isNaN(value) ? parseFloat(0) : parseFloat(value);

  const isControlOrModelChanged = (oldItem, newItem, type) => {
    if (oldItem[type] || newItem[type]) {
      if (!oldItem[type] || !newItem[type]) {
        return true;
      }
      const { x: oldX, y: oldY, z: oldZ } = oldItem[type];
      const { x: newX, y: newY, z: newZ } = newItem[type];
      return oldX !== newX || oldY !== newY || oldZ !== newZ;
    }
    return false;
  };

  const isCoordinateChanged = (oldCoord, newCoord) => {
    if (oldCoord.name !== newCoord.name || oldCoord.code !== newCoord.code) {
      return true;
    }
    if (
      isControlOrModelChanged(oldCoord, newCoord, "control") ||
      isControlOrModelChanged(oldCoord, newCoord, "model")
    ) {
      return true;
    }
    return false;
  };

  const isCoordinateDataModified = () => {
    if (oldCoordinates.length !== coordinates.length) {
      return true;
    }
    return oldCoordinates.some((oldCoord, index) =>
      isCoordinateChanged(oldCoord, coordinates[index])
    );
  };

  const handelOnClose = () => {
    KeyEventListeners.removeEventListener();
    if (isCoordinateDataModified() == false && coordinates.length > 0) {
      cleanCoordinates();
      if (labels.length > 0) {
        labels.forEach((label) => {
          viewer.scene.scene.remove(label);
        });
      }
      onClose();
      lastMeasure.removeMarker(0);
      viewer.scene.removeMeasurement(lastMeasure);
    } else if (coordinates.length == 0) {
      onClose();
      lastMeasure.removeMarker(0);
      viewer.scene.removeMeasurement(lastMeasure);
    } else {
      const yes = () => {
        setShowOnCloseAlert(false);
        onClickUpdateCoordinates();
        cleanCoordinates();
        onClose();
      };
      const no = () => {
        setCoordSaveVisible(false);
        cleanCoordinates();
        onClose();
        lastMeasure.removeMarker(0);
        viewer.scene.removeMeasurement(lastMeasure);
        setShowOnCloseAlert(false);
      };
      // showAlert(yes, no);
      setOnYes(() => yes);
      setOnNo(() => no);
      setShowOnCloseAlert(true);
      setDialogTitle("Save Changes");
      setDialogMessage(
        "Do you want to apply these changes to Coordinate Tools before closing?"
      );
    }
  };
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedSiteCoordinateName, setSelectedSiteCoordinateName] =
    useState();

  const [showOnCloseAlert, setShowOnCloseAlert] = useState(false);
  const [onYes, setOnYes] = useState();
  const [onNo, setOnNo] = useState();

  const gridRef = useRef();
  useEffect(() => {
    if (gridRef.current != undefined) {
      let topHeight = gridRef.current.getBoundingClientRect().top + 90;
      gridRef.current.style.maxHeight = `calc(100vh - ${topHeight}px)`;
    }
  }, []);

  const alignToControlSuccessCallback = (response) => {
    if (response) {
      if (response.isScanRunning) {
        CustomAlert.show({
          type: "error",
          message: "Scan is already running",
          buttons: [],
        });
        unloadPointClouds();
        reloadSiteData();
      } else if (response.isScanInQueue) {
        CustomAlert.show({
          type: "error",
          message: "Scan is already in Queue",
          buttons: [],
        });
        reloadSiteData();
      } else if (response.isSuccess) {
        const scan = site.scans.find((x) => x.id == loadedScanIds[0]);
        CustomAlert.show({
          type: "message",
          message:
            "Align to Control has been started for " +
            `${scan.name}` +
            ". You will receive a notification accessible through the bell icon in the top right corner of your screen when it is complete. This process can be cancelled at any time, or reverted after it is complete.",
          buttons: [],
        });
        unloadPointClouds();

        toggleDrawer();
        toggleLeftDrawer(true);

        reloadSiteData();
      }
    }
  };

  const handleOnAlignToControl = () => {
    setShowAlignToControlDialog(false);
    var saveCoordinates = [];
    coordinates.forEach((element) => {
      if (element.model && element.control) {
        var dataObj = {
          name: element.name ? element.name : null,
          code: element.code ? element.code : null,
          xmodelCoordinate: element.model ? element.model.x : null,
          ymodelCoordinate: element.model ? element.model.y : null,
          zmodelCoordinate: element.model ? element.model.z : null,
          xcontrolCoordinate: element.control ? element.control.x : null,
          ycontrolCoordinate: element.control ? element.control.y : null,
          zcontrolCoordinate: element.control ? element.control.z : null,
          error:
            Object.keys(transformationErrors).length != 0 &&
            element.name &&
            transformationErrors[element.name] != -1
              ? transformationErrors[element.name]
              : null,
          siteCoordinateId: currentScanCoordinateId
            ? currentScanCoordinateId
            : null,
        };
        saveCoordinates.push(dataObj);
      }
    });
    const requestData = {
      data: saveCoordinates,
      scanId: loadedScanIds[0],
      successCallback: alignToControlSuccessCallback,
    };

    dispatch(startProcessingAlignToControlScan(requestData));
    dispatch(checkGPSposesFileExistForScan(requestData));
    reloadSiteData();
  };

  const [showAlignToControlDialog, setShowAlignToControlDialog] =
    useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [textAlign, setTextAlign] = useState("left");
  const [isAlignToControlClicked, setIsAlignToControlClicked] = useState(false);
  const isAlignToControlClickedRef = useRef();

  useEffect(() => {
    if (loadedScanIds[0]) {
      setCurrentScanCoordinateId(getScansCoordinatesId);
    }
  }, [loadedScanIds, isAlignToControlClicked]);

  useEffect(() => {
    isAlignToControlClickedRef.current = isAlignToControlClicked;
  }, [isAlignToControlClicked]);

  const handleOnAlignToControlClick = () => {
    if (coordinates.filter((x) => x.model && x.control).length < 3) {
      setShowErrorAlert(true);
      setErrorMessage(
        "You must have at least 3 points to align scan to control."
      );
      setErrorDescription("Please select more points and try again");
      setTextAlign("left");
    } else {
      toggleLeftDrawer(false);
      if (isCoordinateDataModified() == false) {
        setShowAlignToControlDialog(true);
      } else {
        setIsAlignToControlClicked(true);
        const onYesClick = () => {
          if (isCoordinateDataModified()) {
            onClickUpdateCoordinates();
            setShowOnCloseAlert(false);
          }
        };
        const onNoClick = () => {
          setShowAlignToControlDialog(true);
          setShowOnCloseAlert(false);
        };
        setOnYes(() => onYesClick);
        setOnNo(() => onNoClick);
        setShowOnCloseAlert(true);
        setDialogTitle("Save Changes");
        setDialogMessage(
          "Do you want to apply these changes to Coordinate Tools before closing?"
        );
      }
    }
  };

  const handelOnCloseErrorAlert = () => {
    setShowErrorAlert(false);
    setErrorMessage("");
    setTextAlign("left");
  };

  const OnCloseConfirmationAlert = () => {
    setShowOnCloseAlert(false);
  };
  const successCallBack = () => {
    // unloadPointClouds();
    reloadSiteData();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnAddButtonClick = (e) => {
    setCoordCreatorVisible(true);
    setAnchorEl(e.currentTarget);
  };

  const [openDownloadSelect, setOpenDownloadSelect] = useState(false);

  const handleOnSelectAll = (checked) => {
    setSelectedMarkerIndex([]);
    var _selectedMarkerIndex = [];

    if (checked) {
      coordinates.forEach((value, index) => {
        _selectedMarkerIndex.push(index);
      });
      setSelectedMarkerIndex(_selectedMarkerIndex);
    } else {
      setSelectedMarkerIndex([]);
    }
  };

  const handleOnCheckBoxChange = (checked, index) => {
    var markerIndexData = [...selectedMarkerIndex];
    if (checked) {
      markerIndexData.push(index);
    } else {
      var _index = selectedMarkerIndex.findIndex((x) => x == index);
      if (_index != -1) {
        markerIndexData.splice(_index, 1);
      }
    }
    setSelectedMarkerIndex(markerIndexData);
  };

  useEffect(() => {
    historyCountRef.current = historyCount;
  }, [historyCount]);

  const handleOnUndoClick = () => {
    var _historyCount = historyCount;
    if (_historyCount > 0) {
      if (coordinates.length > 0) {
        var _previous = [...previous];
        _previous.push(coordinates[0]);
        setPrevious(_previous);
        deleteMarker(0);
      }

      _historyCount = _historyCount - 1;
      setHistoryCount(_historyCount);
    }

    setKeyPressType("");
  };

  const handleOnRedo = () => {
    if (previous.length > 0) {
      const _markers = [...markers];
      const marker = addMarker(previous[previous.length - 1].model, 0x34e1eb);
      scene.add(marker);
      _markers.unshift({ model: marker });
      setMarkers(_markers);
      const _coordinates = [...coordinates];
      var coordinate = previous[previous.length - 1];
      _coordinates.unshift(coordinate);
      manageSphareDragging(marker, coordinate.id);
      setCoordinates(_coordinates);
      var _previous = [...previous];
      _previous.splice(previous.length - 1, 1);
      setPrevious(_previous);
      var _historyCount = historyCount;
      _historyCount = _historyCount + 1;
      setHistoryCount(_historyCount);
      setKeyPressType("");
    }
  };

  useEffect(() => {
    if (addModelCoordinate) {
      if (isMarkerAdd) {
        startCoordinateSelection();
      } else {
        setIsMarkerAdd(true);
      }
    }
  }, [isMarkerAdd]);

  const [keyPressType, setKeyPressType] = useState("");

  useEffect(() => {
    if (keyPressType) {
      if (keyPressType == "undo") {
        handleOnUndoClick();
      }
      if (keyPressType == "redo") {
        handleOnRedo();
      }
      if (keyPressType == "save") {
        handleOnExitAddMode();
      }
    }
  }, [keyPressType]);

  // const handleOnKeyPressCallback = (type) => {
  //   setKeyPressType(type);
  // };

  const handleOnExitAddMode = () => {
    setAddModelCoordinate(false);
    lastMeasure.removeMarker(0);
    viewer.scene.removeMeasurement(lastMeasure);
    viewer.inputHandler.alignToControlDrag = null;
    setHistoryCount(0);
    setPrevious([]);

    setSelectedMarkerIndex([]);
    setIsMarkerAdd(false);
    var _coordinates =
      coordinatesRef.current != undefined
        ? coordinatesRef.current
        : [...coordinates];
    _coordinates.forEach((coord) => {
      if (coord.model && coord.isAddModeEnabled) {
        coord.isAddModeEnabled = false;
      }
    });
    setCoordinates(_coordinates);
    setKeyPressType("");
    KeyEventListeners.removeEventListener();
    //window.removeEventListener('keydown',handleonkeypress);
  };

  const getIsInEditMode = () => {
    if (addModelCoordinate) {
      if (isMarkerAdd) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    handleOnShowSelectedMarker();
  }, [showSelectedMarker, selectedMarkerIndex]);

  const toggelShowSelectedMarker = () => {
    if (showSelectedMarker) {
      setShowSelectedMarker(false);
    } else {
      setShowSelectedMarker(true);
    }
  };
  // const handleOnShowSelectedMarker = () => {
  //   const _markers = [...markers];
  //   _markers.forEach((marker) => {
  //     var markerIndex = markers.indexOf(marker);
  //     if (markerIndex != -1) {
  //       if (showSelectedMarker) {
  //         if (selectedMarkerIndex.includes(markerIndex)) {
  //           if (_markers[markerIndex].control) {
  //             _markers[markerIndex].control.visible = true;
  //           }
  //
  //           if (_markers[markerIndex].model) {
  //             _markers[markerIndex].model.visible = true;
  //           }
  //         } else {
  //           if (_markers[markerIndex].control) {
  //             _markers[markerIndex].control.visible = false;
  //           }
  //           if (_markers[markerIndex].model) {
  //             _markers[markerIndex].model.visible = false;
  //           }
  //         }
  //       } else {
  //         if (_markers[markerIndex].control) {
  //           _markers[markerIndex].control.visible = true;
  //         }
  //
  //         if (_markers[markerIndex].model) {
  //           _markers[markerIndex].model.visible = true;
  //         }
  //       }
  //     }
  //   });
  //   setMarkers(_markers);
  // };

  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [importedData, setImportedData] = useState(null);

  useEffect(() => {
    if (importedData) {
      handleOnSelectAll(true);
    }
  }, [importedData]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleOnEditCoordinateClick = (e) => {
    setOpenEditCoordinate(true);
    setAnchorEl(e.currentTarget);
  };

  const handelOnEditModelCoordinate = () => {
    startCoordinateSelection(parseInt(selectedMarkerIndex[0]));
    setOpenEditCoordinate(false);
  };

  const onSphereDrag = (e, sphere, coordinateId) => {
    let I = Potree.Utils.getMousePointCloudIntersection(
      e.drag.end,
      e.viewer.scene.getActiveCamera(),
      e.viewer,
      e.viewer.scene.pointclouds,
      { pickClipped: true }
    );

    if (isModelLabelExist(coordinateId)) {
      const index = getModelLableIndex(coordinateId);
      if (index != -1) {
        var label = labelsRef.current[index];
        label.visible = false;
      }
    }

    if (I) {
      if (I.location) {
        sphere.position.x = I.location.x;
        sphere.position.y = I.location.y;
        sphere.position.z = I.location.z;
        var name =
          sphere.position.x.toFixed(2) +
          "/" +
          sphere.position.y.toFixed(2) +
          "/" +
          sphere.position.z.toFixed(2);
        positionLabel.visible = true;
        updateLabel(positionLabel, sphere.position, name);
      }
    }
  };

  const onSphereDrop = (e, sphere, coordinateId) => {
    let I = Potree.Utils.getMousePointCloudIntersection(
      e.drag.end,
      e.viewer.scene.getActiveCamera(),
      e.viewer,
      e.viewer.scene.pointclouds,
      { pickClipped: true }
    );
    if (I) {
      if (I.location) {
        if (isModelLabelExist(coordinateId)) {
          const index = getModelLableIndex(coordinateId);
          if (index != -1) {
            var label = labelsRef.current[index];
            label.visible = true;
          }
        }

        sphere.position.x = I.location.x;
        sphere.position.y = I.location.y;
        sphere.position.z = I.location.z;
        positionLabel.visible = false;
        updateModelCoordinate(sphere.position, coordinateId);
      }
    }
  };

  const updateModelCoordinate = (position, coordinateId) => {
    var _coordinates = [...coordinatesRef.current];
    var index = _coordinates.findIndex((x) => x.id == coordinateId);
    if (index != -1) {
      delete _coordinates[index].model;

      _coordinates[index].model = {
        x: Number(position.x.toFixed(3)),
        y: Number(position.y.toFixed(3)),
        z: Number(position.z.toFixed(3)),
      };
      setCoordinates(_coordinates);
    }
  };
  const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const addLabel = (position, text) => {
    const coord = new THREE.Vector3(position.x, position.y, position.z);
    let coordinateLabel = new Potree.TextSprite();
    coordinateLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });

    coordinateLabel.setBackgroundColor({ r: 89, g: 134, b: 201, a: 1.0 });
    // coordinateLabel.setBackgroundColor({ r: 71, g: 92, b: 95, a: 1.0 });
    coordinateLabel.fontsize = 16;
    coordinateLabel.material.depthTest = false;
    coordinateLabel.material.opacity = 1;
    const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
    let clientWidth = renderAreaSize.width;
    let clientHeight = renderAreaSize.height;
    let camera = viewer.scene.getActiveCamera();
    let distance = camera.position.distanceTo(coord);
    let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);

    coordinateLabel.setText(text);

    let screenPos = coord.clone().project(camera);
    screenPos.x = Math.round(((screenPos.x + 1) * clientWidth) / 2);
    screenPos.y = Math.round(((-screenPos.y + 1) * clientHeight) / 2);
    screenPos.z = 0;
    screenPos.y -= 30;
    let labelPos = new THREE.Vector3(
      (screenPos.x / clientWidth) * 2 - 1,
      -(screenPos.y / clientHeight) * 2 + 1,
      0.5
    );
    labelPos.unproject(camera);
    if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
      let direction = labelPos.sub(camera.position).normalize();
      labelPos = new THREE.Vector3().addVectors(
        camera.position,
        direction.multiplyScalar(distance)
      );
    }
    coordinateLabel.position.copy(labelPos);
    let labelscale = 70 / pr;
    coordinateLabel.scale.set(labelscale, labelscale, labelscale);
    return coordinateLabel;
  };

  const updateLabel = (label, position, text) => {
    const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
    let clientWidth = renderAreaSize.width;
    let clientHeight = renderAreaSize.height;
    let camera = viewer.scene.getActiveCamera();
    let distance = camera.position.distanceTo(position);
    let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);

    label.setText(text);

    let screenPos = position.clone().project(camera);
    screenPos.x = Math.round(((screenPos.x + 1) * clientWidth) / 2);
    screenPos.y = Math.round(((-screenPos.y + 1) * clientHeight) / 2);
    screenPos.z = 0;
    screenPos.y -= 30;
    let labelPos = new THREE.Vector3(
      (screenPos.x / clientWidth) * 2 - 1,
      -(screenPos.y / clientHeight) * 2 + 1,
      0.5
    );
    labelPos.unproject(camera);
    if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
      let direction = labelPos.sub(camera.position).normalize();
      labelPos = new THREE.Vector3().addVectors(
        camera.position,
        direction.multiplyScalar(distance)
      );
    }
    label.position.copy(labelPos);
    let labelscale = 70 / pr;
    label.scale.set(labelscale, labelscale, labelscale);
  };

  const isModelLabelExist = (coordinateId) => {
    var marker = markersRef.current.filter(
      (x) => x.coordinateId == coordinateId
    );
    if (marker.length > 0) {
      if (marker[0].control && marker[0].model) {
        return false;
      } else if (marker[0].control) {
        return false;
      } else if (marker[0].model) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getModelLableIndex = (coordinateId) => {
    let validLables = coordinatesRef.current.filter(
      (coordinate) => coordinate.name
    );
    return validLables.findIndex((x) => x.id == coordinateId);
  };

  const manageSphareDragging = (sphere, coordinateId) => {
    sphere.addEventListener("drag", (e) =>
      onSphereDrag(e, sphere, coordinateId)
    );
    sphere.addEventListener("drop", (e) =>
      onSphereDrop(e, sphere, coordinateId)
    );
  };

  useEffect(() => {
    if (tableRef.current != undefined) {
      let topHeight = tableRef.current.getBoundingClientRect().top + 40;
      tableRef.current.style.maxHeight = `calc(100vh - ${topHeight}px)`;
    }
  }, []);

  const handleOnZoomMultiplePoint = () => {
    var _coordinates = [...coordinates];
    var _selectedMarkerIndex = [...selectedMarkerIndex];
    _coordinates = _coordinates.filter((_, index) =>
      _selectedMarkerIndex.includes(index)
    );

    var x = _coordinates.map((x) => (x.model ? x.model.x : x.control.x));
    var y = _coordinates.map((x) => (x.model ? x.model.y : x.control.y));
    var z = _coordinates.map((x) => (x.model ? x.model.z : x.control.z));

    var min = {
      x: Math.min.apply(null, x),
      y: Math.min.apply(null, y),
      z: Math.min.apply(null, z),
    };

    var max = {
      x: Math.max.apply(null, x),
      y: Math.max.apply(null, y),
      z: Math.max.apply(null, z),
    };

    let vertices = [
      new THREE.Vector3(min.x, min.y, min.z),
      new THREE.Vector3(min.x, min.y, min.z),
      new THREE.Vector3(max.x, min.y, min.z),
      new THREE.Vector3(min.x, max.y, min.z),
      new THREE.Vector3(min.x, min.y, max.z),
      new THREE.Vector3(min.x, max.y, max.z),
      new THREE.Vector3(max.x, max.y, min.z),
      new THREE.Vector3(max.x, min.y, max.z),
      new THREE.Vector3(max.x, max.y, max.z),
    ];
    if (_coordinates.length > 1) {
      let box = new THREE.Box3();
      box.setFromPoints(vertices);
      let node = new THREE.Object3D();
      node.boundingBox = box;

      viewer.zoomTo(node, 1, 0);
      viewer.controls.stop();
      updateSpheres();
    } else if (_coordinates.length == 1) {
      var mainPoint = _coordinates[0].control
        ? new THREE.Vector3(
            _coordinates[0].control.x,
            _coordinates[0].control.y,
            _coordinates[0].control.z
          )
        : new THREE.Vector3(
            _coordinates[0].model.x,
            _coordinates[0].model.y,
            _coordinates[0].model.z
          );
      //  var rightDirection = new THREE.Vector3(1,0,0);//(-1,0,0)--Left (0,1,0)--Top (0,-1,0)--Down
      //  var topDirection = new THREE.Vector3(0,1,0);
      //  var distance = 1;
      //  var zoomPoint = new THREE.Vector3().addVectors(mainPoint,rightDirection.multiplyScalar(distance));
      //  var zoomPoint1 = new THREE.Vector3().addVectors(zoomPoint,topDirection.multiplyScalar(1));
      // //  var camera = viewer.scene.getActiveCamera();

      // //  let distance1 = camera.position;
      zoomInAndOutMarker(2.5, mainPoint);
    }
  };

  const showRevertButton = () => {
    const scan = site.scans.find((x) => x.id == loadedScanIds[0]);
    if (scan) {
      if (
        scan.isOriginal == false &&
        scan.isQueued == false &&
        scanAlignToControlStatus == true
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const failurCallBack = () => {
    CustomAlert.show({
      type: "error",
      message: "File Not Found",
      buttons: [],
    }).then(() => {
      // var _isAligntoControlClick = isAlignToControlClickedRef.current;
      // if (_isAligntoControlClick) {
      //   setShowAlignToControlDialog(true);
      // }
    });
  };

  const handelOnRestoreClick = () => {
    try {
      swal({
        text: "Are you sure you want to revert?",
        icon: "warning",
        buttons: {
          Yes: {
            text: "Yes",
            value: "yes",
          },
          NO: {
            text: "NO",
            value: "no",
          },
        },
      }).then((value) => {
        if (value == "yes") {
          var requestData = {
            scanId: loadedScanIds[0],
            successCallBack: successCallBack,
            failurCallBack: failurCallBack,
          };
          dispatch(revertBackScanPotreeFile(requestData));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handelOnCloseSelect = () => {
    if (openAddSelect) {
      setOpenAddSelect(false);
    }
    if (openDownloadSelect) {
      setOpenDownloadSelect(false);
    }
    if (openUploadSelect) {
      setOpenUploadSelect(false);
    }
    if (openEditCoordinate) {
      setOpenEditCoordinate(false);
    }
    setAnchorEl(null);
  };

  const handleOnClearModelCoordinate = () => {
    setOpenEditCoordinate(false);
    const _selectedMarkerIndex = [...selectedMarkerIndex];
    const _coordinates = [...coordinatesRef.current];
    const _markers = [...markersRef.current];
    if (_coordinates[_selectedMarkerIndex[0]]) {
      if (
        _coordinates[_selectedMarkerIndex[0]].model &&
        _coordinates[_selectedMarkerIndex[0]].control == undefined
      ) {
        _coordinates.splice(_selectedMarkerIndex[0], 1);

        removeCoordinateSingleSphere(_markers[_selectedMarkerIndex[0]].model);
        _markers.splice(_selectedMarkerIndex[0], 1);
        _selectedMarkerIndex.splice(0, 1);
      } else if (
        _coordinates[_selectedMarkerIndex[0]].model &&
        _coordinates[_selectedMarkerIndex[0]].control
      ) {
        delete _coordinates[_selectedMarkerIndex[0]].model;
        removeCoordinateSingleSphere(_markers[_selectedMarkerIndex[0]].model);
        delete _markers[_selectedMarkerIndex[0]].model;
      }
    }
    setSelectedMarkerIndex(_selectedMarkerIndex);
    setMarkers(_markers);
    setCoordinates(_coordinates);
  };

  const handleOnClearControlCoordinate = () => {
    setOpenEditCoordinate(false);

    const _selectedMarkerIndex = [...selectedMarkerIndex];
    const _coordinates = [...coordinatesRef.current];
    const _markers = [...markersRef.current];
    if (_coordinates[_selectedMarkerIndex[0]]) {
      if (
        _coordinates[_selectedMarkerIndex[0]].model == undefined &&
        _coordinates[_selectedMarkerIndex[0]].control
      ) {
        _coordinates.splice(_selectedMarkerIndex[0], 1);
        if (_markers[_selectedMarkerIndex[0]].control) {
          removeCoordinateSingleSphere(
            _markers[_selectedMarkerIndex[0]].control
          );
          _markers.splice(_selectedMarkerIndex[0], 1);
          _selectedMarkerIndex.splice(0, 1);
        }
      } else if (
        _coordinates[_selectedMarkerIndex[0]].model &&
        _coordinates[_selectedMarkerIndex[0]].control
      ) {
        if (_markers[_selectedMarkerIndex[0]].control) {
          delete _coordinates[_selectedMarkerIndex[0]].control;
          removeCoordinateSingleSphere(
            _markers[_selectedMarkerIndex[0]].control
          );
          delete _markers[_selectedMarkerIndex[0]].control;
        }
      }
    }
    setSelectedMarkerIndex(_selectedMarkerIndex);
    setMarkers(_markers);
    setCoordinates(_coordinates);
  };

  const handleCoordinatesFileDropDown = (e) => {
    setSiteCoordinateId(e.target.value);
    if (e.target.value) {
      // const siteCoordinate = siteCoordinates.find(
      //   (x) => x.id == e.target.value
      // );
      dispatch(getSiteCoordinatesData(e.target.value));
    } else {
      setCoordinates([]);
    }
  };

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="right"
      open={rightDrawerOpen}
      onClose={() => setRightDrawerOpen(!rightDrawerOpen)}
      variant="persistent"
      ModalProps={{ keepMounted: true }}
    >
      {rightDrawerOpen && (
        <OuterPanel>
          <Header>
            <CloseButton onClick={toggleDrawer}>
              <ChevronRight />
            </CloseButton>
            <div>
              {dataPanel == "alignToControl" ? (
                <div>
                  <span>Align to Control</span>
                  <span style={{ fontStyle: "italic" }}> (Beta)</span>
                </div>
              ) : (
                <div>
                  <span>Point Selector</span>
                  <span style={{ fontStyle: "italic" }}> (Beta)</span>
                </div>
              )}
            </div>
            <Spacer />

            <Button size="medium" onClick={() => handelOnClose()}>
              <DisabledByDefaultOutlinedIcon style={{ color: "#FFFFFF" }} />
            </Button>
          </Header>
          {dataPanel != "alignToControl" && (
            <Row>
              <Units style={{ backgroundColor: "#DDDDDD" }}>
                <FormControl
                  disabled={addModelCoordinate}
                  className="mx-0 ml-8"
                  sx={{ m: 1, minWidth: 204 }}
                  size="small"
                  style={{
                    backgroundColor: "#FEFEFE8F",
                    borderRadius: "5px",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Saved Files
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="Saved Files"
                    value={siteCoordinateId}
                    onChange={(e) => handleCoordinatesFileDropDown(e)}
                    label="Saved Files"
                    size="small"
                  >
                    <MenuItem key={-1} value={""}>
                      Select
                    </MenuItem>
                    <MenuItem key={-2} value={siteCoordinateId}>
                      {siteCoordinateId}
                    </MenuItem>
                    {siteCoordinates.map((siteCoordinate, index) => (
                      <MenuItem key={index} value={siteCoordinate.id}>
                        {siteCoordinate.siteCoordinateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Units>
            </Row>
          )}
          <FileImportParser
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onFileData={handleFileData}
          />
          <div>
            <Box
              className="flex flex-1 items-center sm:justify-center bg-white"
              sx={{ "& > *": { m: 1 } }}
            >
              {!addModelCoordinate ? (
                <Buttons
                  className="flex-1 items-center"
                  style={{
                    margin: "8px",
                    columnGap: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {dataPanel === "alignToControl" && (
                      <div className="mr-8">
                        <WizardButton
                          disabled={
                            !selectedMarkerIndex ||
                            selectedMarkerIndex.length < 3
                          }
                          onClick={handleControlPointWizardClick}
                        />
                      </div>
                    )}
                    <div className="mr-64">
                      <AlignToControlImport
                        importCoordinates={importUTM_coord}
                      />
                    </div>
                    {dataPanel == "alignToControl" && showRevertButton() && (
                      <div>
                        <Tooltip title="Revert to original">
                          <IconButton
                            style={{
                              margin: "3px",
                              border: "1px solid black",
                              borderRadius: "2px",
                              padding: "2px",
                              backgroundColor: "#F2F2F2",
                              color: "#3a3a3a",
                            }}
                            onClick={() => {
                              handelOnRestoreClick();
                            }}
                          >
                            <RestoreOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    <div>
                      <Tooltip title="Download Projected Coordinates">
                        <IconButton
                          disabled={coordinates.length == 0}
                          style={{
                            margin: "px",
                            border: "1px solid black",
                            borderRadius: "2px",
                            padding: "2px",
                            backgroundColor: "#F2F2F2",
                            color:
                              coordinates.length == 0 ? "#afa9a9" : "#3a3a3a",
                          }}
                          aria-controls={true}
                          aria-haspopup="true"
                          aria-expanded={
                            openDownloadSelect ? "true" : undefined
                          }
                          onClick={(e) => downloadAsUTM_Csv(e)}
                        >
                          <FileDownloadOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title="Save Coordinates">
                        <IconButton
                          onClick={() => onClickUpdateCoordinates()}
                          style={{
                            margin: "3px",
                            border: "1px solid black",
                            borderRadius: "2px",
                            padding: "2px",
                            backgroundColor: "#F2F2F2",
                            color:
                              coordinatesModified == false
                                ? "#afa9a9"
                                : "#3a3a3a",
                          }}
                        >
                          <SaveOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <div>
                      <Tooltip title="Add Control Coordinate">
                        <IconButton
                          aria-controls={true}
                          aria-haspopup={true}
                          aria-expanded={true}
                          onClick={(e) => handleOnAddButtonClick(e)}
                          style={{
                            border: "1px solid black",
                            borderRadius: "2px",
                            padding: "2px",
                            backgroundColor: "#F2F2F2",
                            color: "#3a3a3a",
                            margin: "3px",
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  {dataPanel == "pointImport" && (
                    <ToggleButtonGroup
                      size="small"
                      value={unit}
                      exclusive
                      onChange={(event) => setUnit(event.currentTarget.value)}
                      style={{ background: "#DDDDDD", borderRadius: "22px" }}
                    >
                      <ToggleButton
                        value="utm"
                        className={
                          unit == "utm" ? classes.active : classes.inactive
                        }
                      >
                        Projected
                      </ToggleButton>
                      <ToggleButton
                        value="wgs"
                        className={
                          unit == "wgs" ? classes.active : classes.inactive
                        }
                      >
                        Geographic
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}

                  <div
                    style={{ backgroundColor: "#F4F4F4", borderRadius: "5px" }}
                    className="flex items-center justify-content-between"
                  >
                    <Tooltip title="Show Selected">
                      <IconButton
                        disabled={selectedMarkerIndex.length < 0}
                        onClick={() => toggelShowSelectedMarker()}
                        // style={{ padding: '2px', color: '#3a3a3a' }}
                        className={
                          selectedMarkerIndex.length > 0
                            ? classes.buttonEnable
                            : classes.buttonDisable
                        }
                      >
                        {" "}
                        {showSelectedMarker ? (
                          <CheckBoxOutlinedIcon />
                        ) : (
                          <CheckBoxOutlineBlankOutlinedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom">
                      <IconButton
                        disabled={false}
                        onClick={() => {
                          handleOnZoomMultiplePoint();
                        }}
                        //style={{ padding: '2px', color: '#3a3a3a' }}
                        className={
                          selectedMarkerIndex.length > 0
                            ? classes.buttonEnable
                            : classes.buttonDisable
                        }
                      >
                        <ZoomInMapIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Show/Hide">
                      <IconButton
                        onClick={() => handleOnShowHideMarker()}
                        //style={{ padding: '2px', color: '#3a3a3a' }}
                        className={
                          selectedMarkerIndex.length > 0
                            ? classes.buttonEnable
                            : classes.buttonDisable
                        }
                      >
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <div>
                      <Tooltip title="Edit">
                        <IconButton
                          disabled={selectedMarkerIndex.length != 1}
                          onClick={(e) => handleOnEditCoordinateClick(e)}
                          className={
                            selectedMarkerIndex.length != 1
                              ? classes.buttonDisable
                              : classes.buttonEnable
                          }
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openEditCoordinate}
                        onClose={handelOnCloseSelect}
                        classes={{ paper: commonStyles.menuPaper }}
                      >
                        <MenuItem
                          onClick={() => {
                            handelOnEditModelCoordinate();
                          }}
                          style={{ paddingBottom: "0px" }}
                        >
                          Edit Model Coordinate
                        </MenuItem>
                        <Divider style={{ borderColor: "#000000" }} />
                        <MenuItem
                          onClick={() => {
                            editMarker(parseInt(selectedMarkerIndex[0]));
                            setOpenEditCoordinate(false);
                          }}
                        >
                          Edit Control Coordinate
                        </MenuItem>
                        {coordinates[selectedMarkerIndex[0]] &&
                          coordinates[selectedMarkerIndex[0]].model && (
                            <>
                              <Divider style={{ borderColor: "#000000" }} />
                              <MenuItem
                                onClick={() => {
                                  handleOnClearModelCoordinate();
                                }}
                              >
                                Clear Model Coordinate
                              </MenuItem>
                            </>
                          )}
                        {coordinates[selectedMarkerIndex[0]] &&
                          coordinates[selectedMarkerIndex[0]].control && (
                            <>
                              <Divider style={{ borderColor: "#000000" }} />
                              <MenuItem
                                onClick={() => {
                                  handleOnClearControlCoordinate();
                                  setOpenEditCoordinate(false);
                                }}
                              >
                                Clear Control Coordinate
                              </MenuItem>
                            </>
                          )}
                      </Menu>
                    </div>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          console.log("Delete");
                          CustomAlert.show({
                            type: "deleteconfirmation",
                            message:
                              "Do you want to delete the selected point(s)?",
                            buttons: [],
                          }).then((res) => {
                            if (res == "yes") {
                              handleOnDeleteAll();
                            }
                          });
                        }}
                        //style={{ padding: '2px', color: '#3a3a3a' }}
                        className={
                          selectedMarkerIndex.length > 0
                            ? classes.buttonEnable
                            : classes.buttonDisable
                        }
                      >
                        <RiDeleteBinLine />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Buttons>
              ) : (
                <div className="w-full m-0">
                  <div className="flex items-center justify-between">
                    <div></div>
                    <div>
                      <Buttons className="flex-1 items-center">
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => handleOnExitAddMode()}
                          style={{
                            border: "1px solid #A1A1A1",
                            borderRadius: "24px",
                            padding: "5px 30px",
                            backgroundColor: "#F2F2F2",
                            color: "#3a3a3a",
                          }}
                        >
                          Exit Add Mode
                        </Button>

                        <div>
                          <Tooltip title="Undo">
                            <IconButton
                              onClick={() => handleOnUndoClick()}
                              style={{
                                border: "1px solid black",
                                borderRadius: "2px",
                                padding: "3px",
                                backgroundColor: "#F2F2F2",
                                color: "#3a3a3a",
                              }}
                            >
                              <UndoOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip title="Redo">
                            <IconButton
                              onClick={() => handleOnRedo()}
                              style={{
                                border: "1px solid black",
                                borderRadius: "2px",
                                padding: "3px",
                                backgroundColor: "#F2F2F2",
                                color: "#3a3a3a",
                              }}
                            >
                              <RedoOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Buttons>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </div>

          <TableContainer
            ref={tableRef}
            component={Paper}
            style={{
              borderRadius: "0px",
              boxShadow: "none",
              overflowY: "auto",
            }}
          >
            <Table
              aria-label="collapsible table"
              style={{ borderCollapse: "separate" }}
            >
              <TableHead style={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ backgroundColor: "#DDDDDD" }}>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      textAlign: "left",
                      borderBottom: "1px solid #000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                      color: "#000000",
                    }}
                  >
                    {selectedMarkerIndex.length > 0 &&
                    selectedMarkerIndex.length == coordinates.length ? (
                      <IconButton
                        style={{ color: "#5ecae5" }}
                        onClick={() => handleOnSelectAll(false)}
                      >
                        <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleOnSelectAll(true)}>
                        <CheckBoxOutlineBlankOutlinedIcon></CheckBoxOutlineBlankOutlinedIcon>
                      </IconButton>
                    )}
                    <b> Name</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                      color: "#000000",
                    }}
                  >
                    <b>Type</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                      color: "#000000",
                    }}
                  >
                    <b>{"X"}</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                      color: "#000000",
                    }}
                  >
                    <b>{"Y"}</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      color: "#000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                    }}
                  >
                    <b>{"Z"}</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "0px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      borderTop: "1px solid #000000",
                      padding: "8px",
                      color: "#000000",
                    }}
                  >
                    <b>Residual Error</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              {coordinates.map(
                (coordinate, index) =>
                  (!showSelectedMarker ||
                    (showSelectedMarker &&
                      selectedMarkerIndex.filter((x) => x == index).length >
                        0)) && (
                    <TableBody
                      className={classes.tableBody}
                      onMouseEnter={() => onMouseEnter(index)}
                      onMouseLeave={() => onMouseLeave(index)}
                      key={"" + index}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          rowSpan="3"
                          style={{
                            borderRight: "1px solid #C9C9C9",
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <div className="flex items-center">
                            {selectedMarkerIndex.filter((x) => x == index)
                              .length > 0 ? (
                              <IconButton
                                style={{ color: "#5ecae5" }}
                                onClick={() =>
                                  handleOnCheckBoxChange(false, index)
                                }
                              >
                                <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleOnCheckBoxChange(true, index)
                                }
                              >
                                <CheckBoxOutlineBlankOutlinedIcon></CheckBoxOutlineBlankOutlinedIcon>
                              </IconButton>
                            )}
                            <div
                              className="flex items-center"
                              style={{ flexDirection: "column" }}
                            >
                              <div>
                                <b>
                                  Name: {coordinate.name ? coordinate.name : ""}
                                </b>
                              </div>
                              <div>
                                <b>Code: {coordinate.code}</b>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                          }}
                        >
                          <CircleIcon fontSize="8" sx={{ color: "#E55846" }} />
                          {` Control`}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                          }}
                        >
                          {coordinate.control
                            ? ConvertCoordinateValue.getConvertedCoordinatedValue(
                                coordinate.control.x
                              )
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                          }}
                        >
                          {" "}
                          {coordinate.control
                            ? ConvertCoordinateValue.getConvertedCoordinatedValue(
                                coordinate.control.y
                              )
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                          }}
                        >
                          {" "}
                          {coordinate.control
                            ? ConvertCoordinateValue.getConvertedCoordinatedValue(
                                coordinate.control.z
                              )
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          rowSpan="3"
                          style={{
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          {Object.keys(transformationErrors).length != 0 &&
                          transformationErrors[coordinate.name] != -1 &&
                          coordinate.name
                            ? transformationErrors[coordinate.name]
                            : ""}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                          }}
                        >
                          <CircleIcon fontSize="8" sx={{ color: "#58BEC9" }} />
                          {` Model`}
                        </TableCell>
                        {coordinate.model ? (
                          <>
                            <TableCell
                              align="center"
                              style={{
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                padding: "8px",
                                color: "#000000",
                              }}
                            >
                              {ConvertCoordinateValue.getConvertedCoordinatedValue(
                                coordinate.model.x
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                padding: "8px",
                                color: "#000000",
                              }}
                            >
                              {coordinate.model
                                ? ConvertCoordinateValue.getConvertedCoordinatedValue(
                                    coordinate.model.y
                                  )
                                : ""}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                padding: "8px",
                                color: "#000000",
                              }}
                            >
                              {coordinate.model
                                ? ConvertCoordinateValue.getConvertedCoordinatedValue(
                                    coordinate.model.z
                                  )
                                : ""}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell
                            align="center"
                            colSpan={3}
                            style={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                              padding: "8px",
                              color: "#000000",
                            }}
                          >
                            {""}
                          </TableCell>
                        )}
                      </TableRow>

                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <p style={{ marginLeft: "17px" }}>Offset</p>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          {getCoordinateError(coordinate, "x")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          {getCoordinateError(coordinate, "y")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "8px",
                            color: "#000000",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          {getCoordinateError(coordinate, "z")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
              )}

              <TableFooter
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#ffffff",
                  zIndex: 9999,
                }}
              >
                <TableRow style={{ backgroundColor: "#DDDDDD" }}>
                  <Tooltip title="Root mean square error calculated from transformation residuals at 1 sigma">
                    <TableCell
                      align="center"
                      colSpan={5}
                      style={{
                        borderRight: "1px solid #C9C9C9",
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      <b>RMS Error</b>
                    </TableCell>
                  </Tooltip>
                  <TableCell
                    align="center"
                    style={{
                      // borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      padding: "8px",
                      color: "#000000",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    {getTotalErrorRMSValue()}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <Box
            className="flex flex-1 items-center sm:justify-center"
            sx={{ "& > *": { m: 1 } }}
          >
            <Buttons>
              <input
                type="file"
                accept=".csv"
                id="csvFile"
                onChange={(e) => {
                  handleOnUTMFileChange(e);
                }}
                ref={utmFile}
                style={{ display: "none" }}
              ></input>
            </Buttons>
          </Box>
        </OuterPanel>
      )}

      {coordCreatorVisible && (
        <CoordinateCreator
          onAddCoordinate={addOrUpdateControlCoordinate}
          onAddCoordinates={addOrUpdateControlCoordinate}
          onClose={() => setCoordCreatorVisible(false)}
        />
      )}

      {coordSaveVisible && (
        <CoordinateSave
          onSaveCoordinate={onClickUpdateCoordinates}
          onClose={() => setCoordSaveVisible(false)}
          siteCoordinates={siteCoordinates}
        />
      )}

      {editingIndex >= 0 && (
        <CoordinateEditor
          addOrUpdateControlCoordinate={addOrUpdateControlCoordinate}
          clearModelCoordinate={clearModelCoordinate}
          updateInfo={updateInfo}
          deleteMarker={deleteMarker}
          coordinate={coordinates[editingIndex]}
          onClose={handleOnEditCoordinateClose}
          index={editingIndex}
        />
      )}
      {showAlignToControlDialog && (
        <AlignToControlConfirmDialog
          transformationErrors={transformationErrors}
          selectedCoordinates={selectedCoordinates}
          setTransformationErrors={setTransformationErrors}
          onYesClick={handleOnAlignToControl}
          onNoClick={() => {
            setShowAlignToControlDialog(false);
          }}
          coordinates={coordinates}
          unit={unit}
        />
      )}
      {showOnCloseAlert && (
        <NewConfirmationDialogBox
          handleOnYesClick={onYes}
          handleOnNoClick={onNo}
          dialogTitle={dialogTitle}
          dialogMessage={dialogMessage}
          OnCloseConfirmationAlert={OnCloseConfirmationAlert}
        />
      )}
      {showErrorAlert && (
        <NewErrorDialogBox
          onOkClick={handelOnCloseErrorAlert}
          errorMessage={errorMessage}
          errorDescription={errorDescription}
          textAlign={textAlign}
        ></NewErrorDialogBox>
      )}
    </Drawer>
  );
});

AlignToControlViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default AlignToControlViewer;
