import { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const TitlePanel = styled.div`
  display: flex;
`;

const IconButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	 cursor: pointer;
  }
`;

const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;

const TestFileStyles = makeStyles({

  ulText: {
    'listStyle': 'decimal',
    'marginLeft': '20px',
    'whiteSpace': 'break-spaces',
    'wordBreak': 'break-all',
  }
});


function TextFileViewer(props) {
  const { src, onClose } = props;
  const fileName = src.split('/')[src.split('/').length - 1];
  const [fileContent, setFileContent] = useState('');
  const classes = TestFileStyles();
  const textFileRef = useRef();


  useEffect(() => {
    fetch(src)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            async function pump() {
              const { done, value } = await reader.read();
              if (!done) {
                var file = new FileReader();
                file.onloadend = (evt) => {
                  addData(evt.target.result);
                };
                file.readAsText(new Blob([value]));
              }
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              return pump();
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        setFileContent(blob);
      })
      .catch((err) => console.error(err));
  }, [src]);


  const addData = (data) => {
    var lines = data.split("\n");
    for (var i = 0; i < lines.length; i++) {
      var line = clenUpString(lines[i]);
      var finalLines = line.split("\r");
      for (var j = 0; j < finalLines.length; j++) {
        var span = document.createElement("li");
        span.innerText = finalLines[j];
        textFileRef.current.appendChild(span);
      }
    }
  }

  const clenUpString = (str) => {
    if (str.substring(str.length - 1, str.length) == '\r') {
      str = str.substring(0, str.length - 1);
      str = clenUpString(str);
    }
    return str;
  }


  const downloadFile = () => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(fileContent);
    a.style.display = 'none';
    document.body.append(a);
    a.click();
    a.remove();
  }


  return (
    <div>
      <Dialog
        fullScreen
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" open>
        <DialogTitle>
          <TitlePanel className='flex flex-row justify-between'>
            <div>{fileName}</div>
            <Spacer space={5} />
            {fileContent &&
              <IconButton onClick={() => downloadFile()}>
                <FileDownloadIcon fontSize="inherit" />
              </IconButton>
            }
            <IconButton onClick={onClose}><Close fontSize="inherit" /></IconButton>
          </TitlePanel>
        </DialogTitle>
        <DialogContent style={{ 'paddingLeft': '100px' }}>
          <pre>
            <ul ref={textFileRef} className={`${classes.ulText}`}>
            </ul>
          </pre>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default TextFileViewer;