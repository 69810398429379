import styled from "@emotion/styled";
import {
    Typography,
    Card,
    LinearProgress,
    IconButton,
    Button
} from '@mui/material';
import { withStyles } from '@mui/styles';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { cancelRevertBackToOrirginal } from "../admin/store/scanSlice";
import { deleteRevertBackToOriginalNotifications } from "../admin/store/notificationSlice";
import { useDispatch } from "react-redux";

const Description = styled.div`
  font-size: 15px;
`

const PercentageLinearProgressBar = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
        width: '100%',
        backgroundColor: '#000000'
    },
    colorPrimary: {
        backgroundColor: '#D3D3D3'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#59BEC9",
    },
    transform: [{ rotate: "90deg" }],
}))(LinearProgress);

function RevertToOriginalNotificationCard(props) {
    const { notification } = props;
    const dispatch = useDispatch();

    const date = notification.revertToOriginalProgress.createTime;
    const dateObject = new Date(date);

    const dateTime = dateObject.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });


    const handleOnCancelClick = (scanId) => {
        var requestData = {
            successCallback: handleRefreshNotifications,
            scanId: scanId
        }
        dispatch(cancelRevertBackToOrirginal(requestData));

    }

    const handleOnDeleteClick = (id) => {
        var requestData = {
            successCallback: handleRefreshNotifications,
            id: id
        }
        dispatch(deleteRevertBackToOriginalNotifications(requestData));

    }

    const handleRefreshNotifications = () => {
        props.handleRefreshNotifications();
    }

    const getStatusMessage = () => {

        if (notification.revertToOriginalProgress.isCanceled) {
            return "is canceled";
        } else if (notification.revertToOriginalProgress.status == 0) {
            return "is in progress";
        } else if (notification.revertToOriginalProgress.status == 1) {
            return "has completed";
        }
    }


    return (
        <Card
            className='flex items-center relative w-full rounded-16 pl-20 pb-20 min-h-64 shadow space-x-8 mb-8'
            elevation={0}
        >
            <div className="flex flex-col flex-auto">
                <div className="flex flex-row justify-end">
                    <IconButton
                        onClick={() => {
                            handleOnDeleteClick(notification.revertToOriginalProgress.id)
                        }
                        }
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </div>
                <div className="pr-20">
                    <>
                        <Description> Reverting Align to control for  scan <span style={{ fontWeight: 600 }}>  {notification.revertToOriginalProgress.scan.name} </span> {getStatusMessage()}</Description>
                        {notification.revertToOriginalProgress.status == 0 &&
                            <div className='flex flex-row items-center'>
                                <PercentageLinearProgressBar
                                    variant="determinate"
                                    value={notification.revertToOriginalProgress.progress}
                                />
                                <p className="ml-4">
                                    {`${notification.revertToOriginalProgress.progress.toFixed(1)}%`}
                                </p>
                            </div>}
                    </>
                    <Typography className="mt-8 text-sm leading-none " color="text.secondary">
                        {dateTime}
                    </Typography>
                    {(!notification.revertToOriginalProgress.isCanceled && notification.revertToOriginalProgress.status == 0) &&
                        <div className="mt-4 flex justify-end">
                            <Button variant="outlined" style={{
                                boxSizing: 'border-box',
                                width: '110px',
                                background: '#E67260',
                                border: '1px solid #E67260',
                                borderRadius: '50px',
                                color: '#ffffff',
                                padding: '2px 15px'
                            }}
                                onClick={() => handleOnCancelClick(notification.revertToOriginalProgress.scanId)}
                            >Cancel</Button>
                        </div>
                    }
                </div>
            </div>
        </Card>
    )
}
export default RevertToOriginalNotificationCard;