import {
  Typography,
  Card,
  CardContent,
  Button,
  Checkbox
} from '@mui/material';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { demoregisterUser } from './store/registerSlice';
import { useDispatch } from 'react-redux';
import { setLoaderProgress } from 'app/store/fuse/loaderSlice'
import swal from 'sweetalert';
import ErrorMessageConstants from '../apps/constants/ErrorMessageConstants';
import { useNavigate } from 'react-router-dom';

function DemoRegister() {
  const dispatch = useDispatch();
  const [isValidForm, setIsValidForm] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    isTermsAndConditions: false
  })


  const handleOnChangeValues = (e) => {
    try {
      if (e.target.name == "email") {
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (re.test(e.target.value)) {
          setEmailValid(true);
        } else {
          setEmailValid(false);
        }
        setUserData({ ...userData, [e.target.name]: e.target.value });

      } else if (e.target.name == "isTermsAndConditions") {
        setUserData({ ...userData, ["isTermsAndConditions"]: !userData.isTermsAndConditions });
      } else {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOnContinueClick = () => {
    var _data = {
      name: userData.name,
      email: userData.email.toLowerCase()
    }
    setIsValidForm(true);
    if (validateForm()) {
      var requestData = {
        data: _data,
        successCallback: onRegisterSuccess,
        failureCallback: onRegisterFailure
      }
      const demoRegisterApi = demoregisterUser(requestData);
      Promise.resolve(demoRegisterApi)
        .then((response) => {

          if (response.status === 200) {
            onRegisterSuccess(response.data);
          }
        })
        .catch((error) => {
          var errorMessage = "";
          if (error.response.data && error.response && error.response.data && typeof (error.response.data) == "string") {
            errorMessage = error.response.data;
          }
          onRegisterFailure(errorMessage);
        });

    }
    else {
      setIsValidForm(false)
    }
  }

  const onRegisterSuccess = (token) => {
    if (token) {
      sessionStorage.setItem('demousertoken', token)
      setTimeout(() => {
        window.location.replace(`${window.location.origin}/apps/dashboard/demousersites`);
      }, 500)

    }
  }

  const onRegisterFailure = (message) => {
    if (message) {
      swal("", message, "error");
      dispatch(setLoaderProgress(false));
    } else {
      swal("", ErrorMessageConstants.defaultErrormessage, "error");
      dispatch(setLoaderProgress(false));
    }

  }

  const validateForm = () => {
    if (!userData.name || !userData.email || !userData.isTermsAndConditions) {
      return false;
    }
    else {
      if (isEmailValid) {
        return true;
      } else {
        return false;
      }

    }
  }

  return (

    <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
      <div className="flex flex-col items-center justify-center w-full">
        <Card className="w-full max-w-384">
          <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
            <Typography variant="h6" className="mt-0 mb-24 font-semibold text-18 sm:text-24 text-center">
              Demo Registration
            </Typography>
            {!isValidForm ? <p className="text-red mb-16 text-center">All fields are mandatory</p> : null}
            {isEmailValid == true ? null : <p className="text-red">Email is not valid</p>}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Email ID"
              name="email"
              fullWidth
              onChange={(e) => handleOnChangeValues(e)}
              value={userData.email}
              className="mb-16"
              error={!isValidForm && userData.email == ""}

            />
            <TextField
              id="outlined-basic"
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              onChange={(e) => handleOnChangeValues(e)}
              className="mb-16"
              value={userData.name}
              error={!isValidForm && userData.name == ""}
            />
            <div className='flex flex-row w-full items-center'>
              <Checkbox
                checked={userData.isTermsAndConditions}
                name='isTermsAndConditions'
                onChange={(e) => handleOnChangeValues(e)}
                sx={{
                  color: '#6CCCD7',
                  '&.Mui-checked': {
                    color: '#6CCCD7',
                  },
                }} />
              <span>I agree to the &nbsp;</span>
              <a href='https://www.looq.ai/Looq Privacy Policy.pdf' style={{
                background: 'none',
                border: '0px',
                textDecoration: 'underline',
                color: 'blue'
              }} target="_blank">Privacy Policy</a>
            </div>
            <div className="flex flex-row items-center mt-16 justify-center w-full">
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-18 py-8 w-full mr-36 ml-36"
                onClick={() => handleOnContinueClick()}
              >
                Register
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default DemoRegister;
