import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@mui/material';
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import FileViewer from 'react-file-viewer';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const TitlePanel = styled.div`
  display: flex;
`;

const IconButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	 cursor: pointer;
  }
`;
const ProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
`;

const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;

function CSVFileViewer(props) {
  const { src, onClose } = props;
  const fileName = src.split('/')[src.split('/').length - 1];
  const [csvFileUrl, setCsvFileUrl] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setShowLoader(true);
    let isMounted = true;
    loadFile(src)
      .then((response) => {
        if (isMounted) {
          setShowLoader(false);
          var newBlob = response.data;
          newBlob.name = src.split('/')[src.split('/').length - 1]
          setCsvFileUrl(window.URL.createObjectURL(newBlob));
        }
      })
      .catch((error) => console.error(error));
    return () => {
      isMounted = false;
    };
  }, [src]);

  function loadFile(src) {
    const response = axios.get(src, {
      headers: {
      },
      responseType: 'blob',
    });

    return Promise.resolve(response);
  };

  const downloadFile = () => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = csvFileUrl
    a.style.display = 'none';
    document.body.append(a);
    a.click();
    a.remove();
  }

  return (
    <div>
      <Dialog
        fullScreen
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open
      >
        <DialogTitle>
          <TitlePanel className='flex flex-row justify-between'>
            <div>{fileName}</div>
            <Spacer space={5} />
            {!showLoader &&
              <IconButton onClick={() => downloadFile()}>
                <FileDownloadIcon fontSize="inherit" />
              </IconButton>
            }
            <IconButton onClick={onClose}><Close fontSize="inherit" /></IconButton>
          </TitlePanel>
        </DialogTitle>
        <DialogContent style={{ 'paddingLeft': '100px' }}>
          {showLoader ?
            <ProgressBar>
              <CircularProgress color="inherit" />
            </ProgressBar>
            :
            <>
              {csvFileUrl &&
                <FileViewer
                  fileType='csv'
                  filePath={csvFileUrl} />
              }
            </>
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default CSVFileViewer;