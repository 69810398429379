import * as React from 'react';
import {ChevronRight, ChevronLeft} from '@mui/icons-material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import CustomImage from '../image/CustomImage';

const OuterPanel = styled.div`
  cursor: pointer;
  display: flex;
`
const NavButton = styled.div`
  padding: 22px 4px;
  font-size: 32px;
`

function ImageViewerBar(props) {

	const {imageInfos, onPrevious, onNext, onShowImage} = props;

	return (
		<OuterPanel>
			<NavButton onClick={onPrevious}><ChevronLeft fontSize="inherit" /></NavButton>
			<ImageList cols={5} >
				{imageInfos.map((imageInfo, index) => (
					<ImageListItem key={imageInfo.id}>
                        <CustomImage src={imageInfo.fileUrl}  loading="lazy" onClick={() => onShowImage(imageInfo)} id={imageInfo.id} />
						<ImageListItemBar title={imageInfo.name} />
					</ImageListItem>
				))}
			</ImageList>
			<NavButton onClick={onNext}><ChevronRight fontSize="inherit" /></NavButton>
		</OuterPanel>
	);
}

ImageViewerBar.propTypes = {
	imageInfos: PropTypes.array.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	onShowImage: PropTypes.func.isRequired
};

export default ImageViewerBar;
