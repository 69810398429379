const ELEVATION_MIN = -100;
const ELEVATION_MAX = 3000;

export const isLatLongValid = (lat, long) => {
  if (lat === null || long === null) return false;

  lat = parseFloat(lat);
  long = parseFloat(long);

  try {
    return (
      isFinite(lat) &&
      isFinite(long) &&
      lat >= -90 &&
      lat <= 90 &&
      long >= -180 &&
      long <= 180
    );
  } catch (ex) {
    return false;
  }
};

export const isElevationValid = (elevation) => {
  try {
    const elevFloat =
      typeof elevation === "string" ? parseFloat(elevation) : elevation;
    return elevFloat >= ELEVATION_MIN && elevFloat <= ELEVATION_MAX;
  } catch (ex) {
    return false;
  }
};

// eslint-disable-next-line complexity
export const validateCoordinates = (field, value, baseStationMetadata) => {
  const hasValue = (val) => val?.trim()?.length > 0;

  const latitude = field === "latitude" ? value : baseStationMetadata?.latitude;
  const longitude =
    field === "longitude" ? value : baseStationMetadata?.longitude;

  const hasLatitude = hasValue(latitude);
  const hasLongitude = hasValue(longitude);

  const bothEdited =
    hasValue(value) ||
    hasValue(
      baseStationMetadata?.[field === "latitude" ? "longitude" : "latitude"]
    );

  if (field === "latitude" || field === "longitude") {
    // Case 1: Both fields are empty after editing
    if (bothEdited && !hasLatitude && !hasLongitude) {
      return "Both latitude and longitude are required";
    }

    // Case 2: If one of them has a value, validate the pair
    if (hasLatitude || hasLongitude) {
      return isLatLongValid(latitude, longitude)
        ? null
        : "Invalid latitude and longitude pair";
    }
  }

  if (field === "ellipsoidalHeight") {
    return !isElevationValid(value)
      ? `Elevation must be between ${ELEVATION_MIN} and ${ELEVATION_MAX}`
      : null;
  }

  return null;
};
