import * as React from 'react';
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {Collapse, FormControl, FormHelperText, MenuItem, TextField, Tooltip} from '@mui/material';

import {ChevronRight, ExpandLess, ExpandMore, BugReport, Circle, Engineering, AutoFixHigh} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {getIssues, updateIssueAttribute} from './Service';

import {types, severities, priorities} from "./IssueAttributes";
import * as THREE from "three";
import { useRef, useImperativeHandle,forwardRef } from 'react';
import _ from 'lodash';

const OuterPanel = styled.div`
  width: 700px;
  background-color: #F8F8F8;
  height: 100%;
`;

const Header = styled.div`
  color: #FFFFFF;
  background-color: #9AA1A4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 14px;`;

const Spacer = styled.div`
  flex-grow: 3;
`;

const CloseButton = styled.div`
  cursor: pointer;
  padding-right: 10px;
`;
const IssuesPanel = styled.div`
  display: flex;
  flex-direction: column;
  color: #7A8184;

`;
const IssuePanel = styled.div`
  display: flex;
  flex-direction: column;
`;
const IssueHeader = styled.div`
  display: flex;
`;
const IssueBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
`;
const Opener = styled.div`
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
`;
const Id = styled.div`
  padding: 15px 10px 10px 10px;
  font-size: 18px;
`;
const Type = styled.div`
  padding: 10px;
`;
const Summary = styled.div`
  display: flex;
  padding: 10px;
  font-size: 12px;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  column-gap: 15px;
  margin: 15px 10px;
`;

const PAGE_SIZE = 20;

const IssueFinder = forwardRef((props,ref) => {

	const {onClose, loadedScanIds, viewer} = props;

	useImperativeHandle(ref, () => ({
		updateIssueCoordinates() {
		  setTimeout(function () {
			addOrUpdateIssueCoordinates();
		  }, 200)
		}
	  }));

	const [issues, setIssues] = useState([]);
	const [collapse, setCollapse] = useState({});
	const [data, setData] = useState([]);
	const [labels, setLabels] = useState([]);
	const [markers, setMarkers] = useState([]);

	useEffect(() => {
		var issuesData = [];
		findIssues(issuesData,0);
		
	}, [loadedScanIds]);

	const findIssues = (issuesData,index) =>{
	         getIssues(loadedScanIds[index], 0, PAGE_SIZE)
				.then((response) => {
					issuesData = issuesData.concat(response.data);
					if (index < loadedScanIds.length - 1) {
						findIssues(issuesData, index + 1);
					} else {
						setData(issuesData);
					}
				}).catch((error) => console.log(error));	
	}

	const markersRef = useRef();
	const labelsRef = useRef();
	useEffect(() => {
	  labelsRef.current = labels;
	  markersRef.current = markers;
  
	}, [labels, markers]);
  
	useEffect(() => {
	  return () => {
		let _markers = [...markersRef.current];
		let _labels = [...labelsRef.current];
		removeIssueMarkers(_markers, _labels);
	  };
	}, []);
	
	useEffect(() =>{
	
		if(data.length >0){
			addOrUpdateIssueCoordinates()
		}else{
			let _markers = [...markersRef.current];
			let _labels = [...labelsRef.current];
			removeIssueMarkers(_markers, _labels);
			setLabels([]);
			setMarkers([]);
		}
		
	},[data])
		
	
	function getIcon(type) {
		let icon;
		if (type === 'bug') {
			icon = <Tooltip title="Bug"><BugReport /></Tooltip>
		} else if (type === 'task') {
			icon = <Tooltip title="Task"><Engineering /></Tooltip>;
		} else if (type === 'enhancement') {
			icon = <Tooltip title="Enhancement"><AutoFixHigh /></Tooltip>;
		} else {
			icon = <Tooltip title="Unknown"><Circle /></Tooltip>;
		}
		return icon;
	}

	function toggle(index) {
		const status = collapse[index];
		const _collapse = {...collapse};
		if(status) {
			delete _collapse[index];
		} else {
			_collapse[index] = true;
		}
		setCollapse(_collapse);
	}

	function updateAttribute(index, attribute, value) {
		updateIssueAttribute(data[index].id, attribute, value)
			.then((response) => {
				updateIssue(index, attribute, value);
			}).catch((error) => console.log(error));
	}

	function updateIssue(index, attribute, value) {
		const _issues = [...data];
		_issues[index][attribute] = value;
		setData(_issues);
	}

	const removeIssueMarkers =(markers, labels) =>{
		if(viewer){
			markers.forEach(marker =>{
				viewer.scene.scene.remove(marker);
			})
			labels.forEach(label =>{
				viewer.scene.scene.remove(label);
			})
		}
	}

	function addMarker(color) {
		const geometry = new THREE.SphereGeometry(0.4);
		const material = new THREE.MeshBasicMaterial({
		  color: color ? color : 0xff0000,
		});
		const sphere = new THREE.Mesh(geometry, material);
		return sphere;
	  }

	const addLabel =() =>{
		let coordinateLabel = new Potree.TextSprite();
		coordinateLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
		coordinateLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
		coordinateLabel.fontsize = 16;
		coordinateLabel.material.depthTest = false;
		coordinateLabel.material.opacity = 1;
		coordinateLabel.visible = true;
		return coordinateLabel;
	
	}



	function projectedRadius(
		radius,
		camera,
		distance,
		screenWidth,
		screenHeight
	  ) {
		if (camera.type == "OrthographicCamera") {
		  return projectedRadiusOrtho(
			radius,
			camera.projectionMatrix,
			screenWidth,
			screenHeight
		  );
		} else if (camera.type == "PerspectiveCamera") {
		  return projectedRadiusPerspective(
			radius,
			(camera.fov * Math.PI) / 180,
			distance,
			screenHeight
		  );
		} else {
		  throw new Error("invalid parameters");
		}
	  }
	
	  function projectedRadiusOrtho(radius, proj, screenWidth, screenHeight) {
		let p1 = new THREE.Vector4(0);
		let p2 = new THREE.Vector4(radius);
	
		p1.applyMatrix4(proj);
		p2.applyMatrix4(proj);
		p1 = new THREE.Vector3(p1.x, p1.y, p1.z);
		p2 = new THREE.Vector3(p2.x, p2.y, p2.z);
		p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
		p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
		p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
		p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
		return p1.distanceTo(p2);
	  }
	
	  function projectedRadiusPerspective(radius, fov, distance, screenHeight) {
		let projFactor = 1 / Math.tan(fov / 2) / distance;
		projFactor = (projFactor * screenHeight) / 2;
	
		return radius * projFactor;
	  }

	  const addOrUpdateIssueCoordinates=() =>{
		var _labels = [...labels];
		var _markers = [...markers];
		let _data = [...data];
		
		if (_data.length > _labels.length) {
			let addCount = _data.length - _labels.length
			for (var i = 0; i < addCount; i++) {
			  try {
				let label = addLabel();
				viewer.scene.scene.add(label);
				_labels.push(label);
			  } catch (error) {
				console.error(error);
			  }
			}
		  }
		  else if (_data.length < _labels.length) {
			let removeCount = _labels.length - _data.length
			for (var i = 0; i < removeCount; i++) {
			  viewer.scene.scene.remove(_labels[_labels.length - 1]);
			  _labels.splice(_labels.length - 1, 1);
			}
		  }

		  if (_data.length > _markers.length) {
			let addCount = _data.length - _markers.length
			for (var i = 0; i < addCount; i++) {
			  try {
				let sphere = addMarker();
				viewer.scene.scene.add(sphere);
				_markers.push(sphere);
			  } catch (error) {
				console.error(error);
			  }
			}
		  }
		  else if (_data.length < _markers.length) {
			let removeCount = _markers.length - _data.length
			for (var i = 0; i < removeCount; i++) {
			  viewer.scene.scene.remove(_markers[_markers.length - 1]);
			  _markers.splice(_markers.length - 1, 1);
			}
		  }

		// removeIssueMarkers(_markers, _labels);
		// _labels = [];
		// _markers = [];
		if(_labels.length == _markers.length){
			_data.forEach((item,i) => {
				let x = item.positionX;
				let y = item.positionY;
				let z = item.positionZ;
				let name = item.summary;
  
				var sphere = _markers[i];
				var label = _labels[i];
  
				sphere.position.x = x;
				sphere.position.y = y;
				sphere.position.z = z;
				const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
				let clientWidth = renderAreaSize.width;
				let clientHeight = renderAreaSize.height;
				let camera = viewer.scene.getActiveCamera();
				let distance = camera.position.distanceTo(
					sphere.getWorldPosition(new THREE.Vector3())
				);
				let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);
				let scale = 15 / pr;
				sphere.scale.set(scale, scale, scale);
			  
				let coord = new THREE.Vector3(
				  x,
				  y,
				  z
				)
			   
		
				label.setText(name);
		
				let screenPos = coord.clone().project(camera);
				screenPos.x = Math.round((screenPos.x + 1) * clientWidth / 2);
				screenPos.y = Math.round((-screenPos.y + 1) * clientHeight / 2);
				screenPos.z = 0;
				screenPos.y -= 30;
				let labelPos = new THREE.Vector3(
				  (screenPos.x / clientWidth) * 2 - 1,
				  -(screenPos.y / clientHeight) * 2 + 1,
				  0.5);
				labelPos.unproject(camera);
				if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
				  let direction = labelPos.sub(camera.position).normalize();
				  labelPos = new THREE.Vector3().addVectors(
					camera.position, direction.multiplyScalar(distance));
				}
				label.position.copy(labelPos);
				let labelscale = (70 / pr);
				label.scale.set(labelscale, labelscale, labelscale);
				
				// viewer.scene.scene.add(sphere)
				// viewer.scene.scene.add(label)
				// _labels.push(label);
				// _markers.push(sphere);
			})
		}
		
		  setLabels(_labels);
		  setMarkers(_markers);

	   }

	return (
		<OuterPanel>
			<Header>
				<CloseButton onClick={onClose}><ChevronRight /></CloseButton>
				<div>Issue Finder</div>
				<Spacer/>
			</Header>
			<IssuesPanel>
				{ data.map((issue, index) => (
					<IssuePanel key={'' + index}>
						<IssueHeader>
							<Opener onClick={() => toggle(index) }>
								{collapse[index]
									? <ExpandLess />
									: <ExpandMore/>
								}
							</Opener>
							<Type>{getIcon(issue.type)}</Type>
							<Id>[{issue.id}]</Id>
							<Summary>
								<TextField value={issue.summary}
										   onChange={(event) => updateIssue(index, 'summary', event.target.value)}
										   onBlur={(event) => updateAttribute(index, 'summary', event.target.value)}
										   size="small" label="Summary" fullWidth />
							</Summary>
						</IssueHeader>
						<Collapse in={collapse[index]} timeout="auto" unmountOnExit>
							<IssueBody>
								<Row>
									<TextField value={issue.description}
											   onChange={(event) => updateIssue(index, 'description', event.target.value)}
											   onBlur={(event) => updateAttribute(index, 'description', event.target.value)}
											   size="small" label="Description" variant="outlined" minRows="5" maxRows="5" multiline fullWidth />
								</Row>
								<Row>
									<FormControl size="small">
										<TextField value={issue.type}
												   onChange={(event) => updateAttribute(index, 'type', event.target.value)}
												   size="small" label="Type" variant="outlined" select fullWidth>
											{types.map((type) => (
												<MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
											))}
										</TextField>
										<FormHelperText>Select the type of issue.</FormHelperText>
									</FormControl>
									<FormControl size="small">
										<TextField value={issue.priority}
												   onChange={(event) => updateAttribute(index, 'priority', event.target.value)}
												   size="small" label="Priority" variant="outlined" select fullWidth>
											{priorities.map((priority) => (
												<MenuItem key={priority.value} value={priority.value}>{priority.label}</MenuItem>
											))}
										</TextField>
										<FormHelperText>Select priority for this issue.</FormHelperText>
									</FormControl>
									<FormControl size="small">
										<TextField value={issue.severity}
												   onChange={(event) => updateAttribute(index, 'severity', event.target.value)}
												   size="small" label="Severity" variant="outlined" select fullWidth>
											{severities.map((severity) => (
												<MenuItem key={severity.value} value={severity.value}>{severity.label}</MenuItem>
											))}
										</TextField>
										<FormHelperText>Select the severity of this issue.</FormHelperText>
									</FormControl>
								</Row>
							</IssueBody>
						</Collapse>
					</IssuePanel>
				))}
			</IssuesPanel>
		</OuterPanel>
	);
})

IssueFinder.propTypes = {
	onClose: PropTypes.func.isRequired,
	//scanId: PropTypes.string.isRequired
};

export default IssueFinder;
