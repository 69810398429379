import React, { useState } from "react";
import styled from "@emotion/styled";
import { Download, Public, Flight, Loop, CameraAlt } from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { useSelector } from "react-redux";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";
import PointCloudViewSelection from "./PointCloudViewSelection";

const Item = styled.div`
  padding: 5px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
  color: ${(props) => (props.selected ? "#565656" : "#ffffff")};
`;

const Section = styled.div`
  padding-left: 20px;
  display: flex;
  padding-bottom: 10px;
`;

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

function ScreenshotAndNavigation(props) {
  const {
    selectNavigation,
    getSnap,
    isOrthographicCamera,
    onChangeCameraProjection,
    navigation,
    currentViewer,
  } = props;

  const { role } = useSelector(({ auth }) => auth.user);

  const [screenShotMinimize, setScreenShotMinimize] = useState(false);

  const handleOnChangeAccordion = () => {
    setScreenShotMinimize(!screenShotMinimize);
    if (screenShotMinimize) {
      MixPanelIntegration.EventClicks("Screenshot Close Click");
    } else {
      MixPanelIntegration.EventClicks("Screenshot Expand Click");
    }
  };

  const handleOnScreenCapture = () => {
    MixPanelIntegration.EventClicks("Screen Capture Click");
    getSnap("email");
  };

  const handleOnDownload = () => {
    MixPanelIntegration.EventClicks("Download Click");
    getSnap("download");
  };

  return (
    <Accordion
      className="m-0"
      expanded={screenShotMinimize}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          screenShotMinimize ? (
            <IconButton onClick={handleOnChangeAccordion}>
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleOnChangeAccordion}>
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel1bh-content"
        id="panel1"
        className="m-0"
      >
        <TypographyStyles>Screenshot & Navigation</TypographyStyles>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <Section className="pl-0">
                {!role.includes("demouser") && (
                  <Tooltip title="Screen Capture">
                    <Item
                      onClick={() => {
                        handleOnScreenCapture();
                      }}
                    >
                      <CameraAlt />
                    </Item>
                  </Tooltip>
                )}
                <Tooltip title="Download">
                  <Item
                    onClick={() => {
                      handleOnDownload();
                    }}
                  >
                    <Download />
                  </Item>
                </Tooltip>
              </Section>
            </div>
            {currentViewer == "3D" && (
              <div className="flex flex-col" style={{ paddingRight: "20px" }}>
                <Section className="pl-0">
                  <Tooltip title="Orbit">
                    <Item
                      onClick={selectNavigation}
                      selected={navigation === "orbit"}
                      id="orbit"
                    >
                      <Loop />
                    </Item>
                  </Tooltip>
                  <Tooltip title="Earth">
                    <Item
                      onClick={selectNavigation}
                      selected={navigation === "earth"}
                      id="earth"
                    >
                      <Public />
                    </Item>
                  </Tooltip>
                  <Tooltip title="Flight">
                    <Item
                      onClick={selectNavigation}
                      selected={navigation === "flight"}
                      id="flight"
                    >
                      <Flight />
                    </Item>
                  </Tooltip>
                </Section>
              </div>
            )}
          </div>
          {currentViewer == "3D" && (
            <PointCloudViewSelection
              isOrthographicCamera={isOrthographicCamera}
              onChangeCameraProjection={onChangeCameraProjection}
            />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
export default ScreenshotAndNavigation;
