import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "../../../../AppConfig";


const addLog = createAsyncThunk('apps/auditLogs/addLog', async (log, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/auditLog/addLog`;

    const response = await axios.post(url, log, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});

export default addLog;