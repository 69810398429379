import MapBoxCommon from "../MapBoxCommon";

class MapBoxPanoImages extends MapBoxCommon {
  constructor(viewer, imagesInfo, onClickPanoImage) {
    super();
    this.viewer = viewer;
    this.imagesInfo = imagesInfo;
    this.onClickPanoImage = onClickPanoImage;
    this.canvas = this.viewer.getCanvasContainer();
    this.mapperEnabled = true;

    this.styleLoadEvent = this.onStyleLoad.bind(this);
    this.features = this.getPointFeatures();
    this.pointsGeoJson = {
      type: "FeatureCollection",
      features: this.features,
    };
    this.id = this.createUUID();
    this.addPointsLayer();
    this.hoveredId = null;

    this.currentSelectedFeature = null;

    let onMouseEnter = (e) => {
      if (e.features.length > 0) {
        this.onMouseEnter(e.features[0].id);
        this.canvas.style.cursor = "pointer";
      }
    };
    let onMouseMove = (e) => {
      if (e.features.length > 0) {
        this.onMouseEnter(e.features[0].id);
        this.canvas.style.cursor = "pointer";
      }
    };
    let onMouseLeave = () => {
      this.onMouseLeave();
      this.canvas.style.cursor = "";
    };
    let onPointClick = (e) => {
      if (e.features.length > 0) {
        const imageInfo = JSON.parse(e.features[0].properties.imageInfo);
        const index = e.features[0].properties.index;
        this.updateCurrentSelectedFeature(imageInfo);
        this.onClickPanoImage(imageInfo, index);
      }
    };
    this.viewer.on("mouseenter", this.id, onMouseEnter);
    this.viewer.on("mousemove", this.id, onMouseMove);
    this.viewer.on("mouseleave", this.id, onMouseLeave);
    this.viewer.on("click", this.id, onPointClick);
  }

  onMouseLeave() {
    if (this.hoveredId != null) {
      this.viewer.setFeatureState(
        { source: this.id, id: this.hoveredId },
        { hover: false }
      );
    }
    this.hoveredId = null;
  }
  onMouseEnter() {
    if (this.hoveredId != null) {
      this.viewer.setFeatureState(
        { source: this.id, id: this.hoveredId },
        { hover: false }
      );
    }
    // dots turn black when hovering
    // this.hoveredId = id;
    this.viewer.setFeatureState(
      { source: this.id, id: this.hoveredId },
      { hover: true }
    );
  }

  addPointsLayer() {
    this.addPointWithData(this.viewer, this.pointsGeoJson, this.id);
  }

  updatePointsLayer() {
    this.updatePointWithData(this.viewer, this.pointsGeoJson, this.id);
  }

  // eslint-disable-next-line no-unused-vars
  onStyleLoad(e) {
    this.addPointsLayer();
  }

  getPointFeatures() {
    let features = [];
    let mainIndex = 0;
    this.imagesInfo.forEach((infos) => {
      infos.forEach((imageInfo, index) => {
        const feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: this.mapperEnabled
              ? [imageInfo.longitude, imageInfo.latitude]
              : [imageInfo.longitudeGps, imageInfo.latitudeGps],
          },
          properties: {
            index: index,
            imageInfo,
          },
          id: mainIndex,
        };
        features.push(feature);
        mainIndex = mainIndex + 1;
      });
    });

    return features;
  }

  setPanoLocationSource(mapperEnabled, serverGeneratedGeoJson=null) {
    this.mapperEnabled = mapperEnabled;
    if (serverGeneratedGeoJson) {
      this.pointsGeoJson = serverGeneratedGeoJson;
    } else {
      this.features = this.getPointFeatures();
      this.pointsGeoJson = {
        type: "FeatureCollection",
        features: this.features,
      };
    }
    this.updatePointsLayer();
  }

  remove() {
    if (this.viewer.getLayer(this.id) != undefined) {
      this.viewer.removeLayer(this.id);
    }
    if (this.viewer.getSource(this.id) != undefined) {
      this.viewer.removeSource(this.id);
    }
  }

  removeScanImages(scanId) {
    this.imagesInfo = this.imagesInfo.filter((x) =>
      x.length > 0 ? x[0].scanId != scanId : false
    );
    this.features = this.getPointFeatures();
    this.pointsGeoJson = {
      type: "FeatureCollection",
      features: this.features,
    };
    this.updatePointsLayer();
  }

  removeAllImages() {
    this.imagesInfo = [];
    this.features = this.getPointFeatures();
    this.pointsGeoJson = {
      type: "FeatureCollection",
      features: this.features,
    };
    this.updatePointsLayer();
  }

  updateScanImages(scanImageInfos) {
    if (scanImageInfos.length > 0) {
      this.imagesInfo.push(scanImageInfos);
      this.features = this.getPointFeatures();
      this.pointsGeoJson = {
        type: "FeatureCollection",
        features: this.features,
      };
      this.updatePointsLayer();
    }
  }

  updateScanImageInfos(scanImageInfos) {
    let isExists = false;
    if (scanImageInfos.length > 0) {
      for (let i = 0; i < this.imagesInfo.length; i++) {
        if (
          this.imagesInfo[i].length > 0 &&
          this.imagesInfo[i][0].scanId == scanImageInfos[0].scanId
        ) {
          this.imagesInfo[i].push(...scanImageInfos);
          isExists = true;
          break;
        }
      }
      if (!isExists) {
        this.imagesInfo.push(scanImageInfos);
      }
      this.features = this.getPointFeatures();
      this.pointsGeoJson = {
        type: "FeatureCollection",
        features: this.features,
      };
      this.updatePointsLayer();
    }
  }

  updateCurrentSelectedFeature(imageInfo) {
    if (this.currentSelectedFeature) {
      this.viewer.setFeatureState(
        { source: this.id, id: this.currentSelectedFeature.id },
        { isSelected: false }
      );
    }
    if (imageInfo) {
      const currentPanoInfo = this.features.find(
        (x) => x.properties.imageInfo.id === imageInfo.id
      );
      if (currentPanoInfo) {
        this.viewer.setFeatureState(
          { source: this.id, id: currentPanoInfo.id },
          { isSelected: true }
        );
        this.currentSelectedFeature = currentPanoInfo;
      }
    }
  }
}

export default MapBoxPanoImages;
