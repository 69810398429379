import React from "react";
// import CustomAlert from "app/main/apps/common/CustomAlert";

const AlignToControl = ({ children, ...props }) => {
  // const [wizardActive, setWizardActive] = useState(false);
  //
  //   const handleMissingDataAlerts = () => {
  //   if (!coordinates) {
  //     CustomAlert.show({
  //       type: "error",
  //       message: "Please select a saved file",
  //       buttons: [],
  //     });
  //     return;
  //   }
  //   if (selectedMarkerIndex?.length < 3) {
  //     CustomAlert.show({
  //       type: "error",
  //       message: "Please select at least 3 points",
  //       buttons: [],
  //     });
  //     return;
  //   }
  // };
  //
  //     const toggleDrawers = (val) => {
  //   toggleDrawer();
  //   toggleLeftDrawer(val);
  // };
  //
  //   const handleControlPointWizardClick = () => {
  //     setWizardActive(!wizardActive);
  //     toggleDrawers();
  //
  //     handleMissingDataAlerts();
  //
  //     const selectedCoordinates = selectedMarkerIndex.map(
  //       (index) => coordinates[index]
  //     );
  //     setSelectedCoordinates(selectedCoordinates);
  //     handleOnZoomMultiplePoint();
  // };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, props) : child
      )}
    </>
  );
};

export default AlignToControl;
