import { lazy } from 'react';
import ConfigCreateOrEdit from './config/ConfigCreateOrEdit';
import ScanProcessingDetails from './scanProcessing/ScanProcessingDetails';
const FolderStructure = lazy(() => import('../folder-view/FolderStructure'));
const AccountCreateOrEdit = lazy(() => import('./organization/AccountCreateOrEdit'));
const SiteCreateOrEdit = lazy(() => import('./sites/SiteCreateOrEdit'));
const ScanCreateOrEdit = lazy(() => import('./scans/ScanCreateOrEdit'));
const CustomerCreateOrEdit = lazy(() => import('./user/CustomerCreateOrEdit'));
const ProcessScan = lazy(() => import('./scanProcessing/ProcessScan'));
const DeletedSites = lazy(() => import('./sites/DeletedSites'));
const DeletedCustomers = lazy(() => import('./user/DeletedCustomers'));
const DeletedAccounts = lazy(() => import('./organization/DeletedAccounts'));
const DeviceCreateOrEdit = lazy(() => import('./device/DeviceCreateOrEdit'));
const DeletedDevices = lazy(() => import('./device/DeletedDevices'));
const DeletedConfigs = lazy(() => import('./config/DeletedConfigs'));
const AccountSettings = lazy(() => import('../organization/AccountSettings'));
const AdminTabApp = lazy(() => import('./AdminTabApp'));
const ProjectCreateOrEdit = lazy(() => import('./Projects/ProjectCreateOrEdit'));

const AdminAppConfig = {

  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'apps/admin/folder/:type/:id',
      element: <FolderStructure />,
    },
    {
      path: 'apps/admin/organisationcreateoredit',
      element: <AccountCreateOrEdit />,
    },
    {
      path: 'apps/admin/organisationcreateoredit/:id',
      element: <AccountCreateOrEdit />,
    },
    {
      path: 'apps/admin/usercreateoredit',
      element: <CustomerCreateOrEdit />
    },
    {
      path: 'apps/admin/usercreateoredit/:id',
      element: <CustomerCreateOrEdit />
    },
    {
      path: 'apps/admin/usercreatebyorganisation/:accountid',
      element: <CustomerCreateOrEdit />
    },
    {
      path: 'apps/admin/sitecreateoredit',
      element: <SiteCreateOrEdit />
    },
    {
      path: 'apps/admin/dashboardsitecreation',
      element: <SiteCreateOrEdit />
    },
    {
      path: 'apps/site/sitecreation',
      element: <SiteCreateOrEdit />
    },
    {
      path: 'apps/admin/sitecreateoredit/:siteId',
      element: <SiteCreateOrEdit />
    },
    {
      path: 'apps/admin/scancreateoredit',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/scan/scancreation',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/scan/scancreation/:siteid',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/admin/scancreateoredit/:id',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/sites/scanedit/:id',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/admin/scancreatebysite/:siteid',
      element: <ScanCreateOrEdit />
    },
    {
      path: 'apps/admin/processscan/:scanid',
      element: <ProcessScan />
    },
    {
      path: 'apps/admin/deletedsites',
      element: <DeletedSites />
    },
    {
      path: 'apps/admin/scanprocessdetails/:scanprocessid',
      element: <ScanProcessingDetails />
    },
    {
      path: 'apps/admin/devicecreateoredit',
      element: <DeviceCreateOrEdit />
    },
    {
      path: 'apps/admin/configcreateoredit',
      element: <ConfigCreateOrEdit />
    },
    {
      path: 'apps/admin/devicecreateoredit/:id',
      element: <DeviceCreateOrEdit />
    },
    {
      path: 'apps/admin/configcreateoredit/:id',
      element: <ConfigCreateOrEdit />
    },
    {
      path: 'apps/admin/deletedDevices',
      element: <DeletedDevices />
    },
    {
      path: 'apps/admin/deletedConfigs',
      element: <DeletedConfigs />
    },
    {
      path: 'apps/admin/deletedcustomers',
      element: <DeletedCustomers />
    },
    {
      path: 'apps/admin/deletedorgnizations',
      element: <DeletedAccounts />
    },
    {
      path: 'apps/admin/scanprocessdetails/:scanprocessid',
      element: <ScanProcessingDetails />
    },
    {
      path: 'apps/admin/organizationsettings',
      element: <AccountSettings />
    },
    {
      path: 'apps/appAdmin/:adminMenu',
      element: <AdminTabApp />
    },
    {
      path: 'apps/admin/projectcreateoredit',
      element: <ProjectCreateOrEdit />
    },
    {
      path: 'apps/admin/projectcreateoredit/:id',
      element: <ProjectCreateOrEdit />
    }
  ],
};

export default AdminAppConfig;
