import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Matrix, SingularValueDecomposition, determinant } from "ml-matrix";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useScreenshot } from "use-react-screenshot";
import { useFlags } from 'flagsmith/react';
import * as THREE from "three";

import CustomAlert from "app/main/apps/common/CustomAlert";
import { getMarkersForPotree } from "../../scan/potree/Service";
import { POI_GEO_JSON, isFlagEnabled } from 'app/main/apps/flags';
import { findAccountId } from "app/main/apps/common/OrgUtils";

import { Typography, Box, Button, Drawer } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import APP_CONFIG from "app/AppConfig";
import addLog from "app/main/apps/auditlog/store/auditLogSlice";
import { downloadScanLazFile } from "../store/downloadSlice";
import AlignToControlViewer from "./AlignToControl/Components/AlignToControlViewer";
import {
  OuterPanel,
  MainPanel,
  PotreePanel,
  Header,
  HeaderAlignToControl,
} from "./Potree/Styles/Potree.style";
import Toolbar from "./Toolbar";
import { getSiteCoordinatesInfo } from "../store/sitesSlice";
import IssueFinder from "./IssueFinder";
import IssueCreator from "./IssueCreator";
import { getInsertion } from "./Insertions";
import ScanSnap from "./ScanSnap";
import { loadImageInfos } from "./Service";
import { DEFAULT_CLASSIFICATIONS } from "./SiteClassificationView";
import swal from "sweetalert";
import { loadScans } from "../store/scansSlice";
import { Get3DMeasurements } from "../CoordinateConverter";
import { AddMeasurementData } from "./AddPotreeMeasurements";
import { sendPotreeErrorMail } from "../store/snapSlice";
import ToolBarStyles from "../../admin/styles/ToolBarStyles";
import KeyEventListeners from "./coordinate-viewer/KeyEventListeners";
import { CameraMode } from "../../common/CameraMode";
import { loadJsonFileData } from "../store/scansSlice";
import UnexpectedErrorDailog from "../mapboxviews/UnexpectedErrorDailog";
import AlignToControlWizard from "./AlignToControl/Components/AlignToControlWizard";
import useZoomFunctions from "./Potree/Hooks/useZoomFunctions";
import AlignToControl from "./AlignToControl/Components/AlignToControl";

let Potree;
const IMAGE_INFOS_LIMIT = 10000;
const TWEEN = window.TWEEN;

const PotreeViewer = forwardRef((props, ref) => {
  const {
    selectedSite,
    toggleLeftDrawer,
    onToggleCurrentViewer,
    measurementData,
    updateMeasurementData,
    reloadSiteData,
  } = props;

  const coordinateToolRef = useRef();
  const [enableAlignToControl, setEnableAlignToControl] = useState(false);

  const clearModelCoordinate = () => {
    if (coordinateToolRef.current != undefined) {
      coordinateToolRef.current.clearModelCoordinate();
    }
  };

  useImperativeHandle(ref, () => ({
    getIsCoordinateSave() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.getIsCoordinateSave();
      } else {
        return false;
      }
    },
    updateCoordinates() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.updateCoordinates();
      } else {
        return true;
      }
    },

    undoAddedMarkers() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.undoAddedMarker();
      }
    },
    redoAddedMarker() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.redoAddedMarker();
      }
    },
    exitFromAddMode() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.exitFromAddMode();
      }
    },
    checkIsInEditMode() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.checkIsInEditMode();
      } else {
        return false;
      }
    },
    cleanCoordinatesref() {
      if (coordinateToolRef.current != undefined) {
        return coordinateToolRef.current.cleanCoordinatesRef();
      }
    },
    getMeasurementsData() {
      return Get3DMeasurements(viewer, viewer.scene.pointclouds);
    },
  }));

  let potreePanelRef = useRef();
  let hoveredIntersects = [];

  const classes = ToolBarStyles();

  const dispatch = useDispatch();
  const params = useParams();

  const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
  const [dataPanel, setDataPanel] = useState("data");

  const [loadedScanIds, setLoadedScanIds] = useState([]);
  const [viewer, setViewer] = useState();

  const [measuring, setMeasuring] = useState(false);

  const [clippingStart, setClippingStart] = useState(false);
  const [imageMarkersVisible, setImageMarkersVisible] = useState(false);
  const [feetLengthSwitch, setFeetLengthSwitch] = useState(false);
  const [coordinateSystem, setCoordinateSystem] = useState("");
  const [classifications, setClassifications] = useState(null);
  const [activeAttribute, setActiveAttribute] = useState("rgba");

  const [image, takeScreenshot] = useScreenshot();
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [scanName, setScanName] = useState("");
  const [type, setType] = useState("");
  const [currentModelScanId, setCurrentModelScanId] = useState(0);
  const [currentModelScan, setCurrentModelScan] = useState(null);
  const [showToolbarItems, setShowToolbarItems] = useState(false);

  const { role } = useSelector(({ auth }) => auth.user);
  const customer = useSelector(({ auth }) => auth.customer);

  const [crossSection, setCrossSection] = useState(false);
  const [crossSectionLineWidth, setCrossSectionLineWidth] = useState("1");
  const [showCrossSectionProfileButton, setShowCrossSectionProfileButton] =
    useState(false);

  const [siteUTMProjection, setSiteUTMProjection] = useState("");

  const navigate = useNavigate();

  const [coordinateSpheres, setCoordinateSpheres] = useState([]);
  const [imageSpheres, setImageSpheres] = useState([]);

  const [isOrthographicCamera, setIsOrthographicCamera] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [transformationErrors, setTransformationErrors] = useState({});

  const [enableEyeDomeLighting, setEnableEyeDomeLighting] = useState(false);
  const [edlRadius, setEdlRadius] = useState(1.0);
  const [edlStrength, setEdlStrength] = useState(0.0);
  const [edlOpacity, setEdlOpacity] = useState(0);
  const [useHighQuality, setUseHighQuality] = useState(false);
  const [pointSize, setPointSize] = useState(0.5);
  const [brightness, setBrightness] = useState(0);
  const [gamma, setGamma] = useState(1.0);
  const [contrast, setContrast] = useState(0);
  const [oldCoordinates, setOldCoordinates] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);

  const [openUnexpectedErrorDailog, setOpenUnexpectedErrorDailog] =
    useState(false);

  const [coordinates, setCoordinates] = useState([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState([]);
  // const [selectedMarkers, setSelectedMarkers] = useState([]);

  useEffect(() => {
    if (image != null) {
      if (type === "download") {
        const blob = b64toBlob(image);
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = selectedSite.name + ".jpeg";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setshowConfirmation(true);
      }
    }
  }, [image]);

  useEffect(() => {
    toggleLeftDrawer(leftDrawerOpen);
    return () => {
      toggleLeftDrawer(false);
    };
  }, [leftDrawerOpen]);

  // eslint-disable-next-line no-unused-vars
  //   const handleFileImportData = (data) => {
  //   console.log("Imported Data:", data); // Do something with the imported data
  //   setImportedData(data);
  // };

  const getSnap = async (type) => {
    setType(type);
    takeScreenshot(potreePanelRef.current);
    if (currentModelScanId !== 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${type} screenshot of the scan`,
          resourcetype: "scanscreenshot",
          action: type,
          resourcename: scanName,
        })
      );
    }
  };

  const handleOnScanCreationClick = () => {
    navigate(`/apps/scan/scancreation/${siteId}`);
  };

  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
  }
  const handleClose = () => {
    setshowConfirmation(false);
  };

  const siteId = parseInt(params.siteId);

  useEffect(() => {
    loadPotree();

    return () => {
      delete window.Potree;
      var script = document.getElementById("potree_lib_script");
      if (script != null) {
        document.head.removeChild(script);
      }
      KeyEventListeners.removeEventListener();
    };
  }, []);
  useEffect(() => {
    dispatch(loadScans());
  }, []);

  /**
   * Called after viewer is set on state.
   */
  useEffect(() => {
    if (viewer) {
      if (isPotreeRefresh) {
        let loadedScans = selectedSite.scans.filter((x) =>
          loadedScanIds.includes(x.id)
        );
        var _measurementData = {};
        _measurementData.scans = loadedScans;
        _measurementData.measurements = [];
        loadScansPointClouds(loadedScans, _measurementData);
        setIsPotreeRefresh(false);
      } else {
        if (measurementData) {
          let scanIds = measurementData.scans.map((x) => x.id);
          const measurementDataScans = scans.filter(
            (x) => scanIds.includes(x.id) && x.potreeStatus == 2
          );
          if (
            measurementDataScans.length > 0 &&
            measurementDataScans.length == scanIds.length
          ) {
            loadScansPointClouds(measurementData.scans, measurementData);
          } else {
            loadRecentModel();
          }
        } else {
          loadRecentModel();
        }
      }
    }
  }, [viewer]);

  //Start Switching data
  const loadScansPointClouds = (scansData, _measurementData) => {
    loadScansPointCloud(0, scansData, _measurementData);
  };
  const loadScansPointCloud = (index, scansData, _measurementData) => {
    const scan = scans.find(
      (scan) => scan.id === scansData[index].id && scan.potreeStatus == 2
    );
    if (scan) {
      if (selectedSite && selectedSite.coordinateSystem) {
        setCoordinateSystem(selectedSite.coordinateSystem.units);
        if (selectedSite.coordinateSystem.units == "ft") {
          setFeetLengthSwitch(true);
        }
      }

      const modelUrl = getModelUrl(scan);
      Potree.loadPointCloud(modelUrl, scan.name).then(
        (e) => {
          let pointcloud = e.pointcloud;
          let material = pointcloud.material;
          material.size = 0.5;
          material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
          material.shape = Potree.PointShape.CIRCLE;
          material.activeAttributeName = activeAttribute;

          pointcloud.name = scan.name;
          pointcloud.scanId = scan.id;
          pointcloud.scale.set(1, 1, 1);

          viewer.scene.addPointCloud(pointcloud);
          for (
            var i = 0;
            i < document.getElementsByClassName("sp-container").length;
            i++
          ) {
            document.getElementsByClassName("sp-container")[i].style.display =
              "none";
          }

          if (loadedScanIds.length === 0) {
            viewer.fitToScreen();
          }

          if (index < scansData.length - 1) {
            loadScansPointCloud(index + 1, scansData, _measurementData);
          } else {
            viewer.fitToScreen();
            let scanIds = _measurementData.scans.map((x) => x.id);
            const measurementDataScans = scans.filter(
              (x) => scanIds.includes(x.id) && x.potreeStatus == 2
            );
            setLoadedScanIds(measurementDataScans.map((x) => x.id));
            if (measurementDataScans.length == scansData.length) {
              loadScansMeasurements(_measurementData);
            }
          }
        },
        (e) => console.err("ERROR: ", e)
      );
    }
  };

  const loadScansMeasurements = (_measurementData) => {
    AddMeasurementData(
      viewer,
      Potree,
      _measurementData,
      viewer.scene.pointclouds
    );
    updateMeasurementData(null);
  };
  //End Switching data

  useEffect(() => {
    updateSpheres();
  }, [coordinateSpheres, imageSpheres]);

  useEffect(() => {
    loadedScanIdsRef.current = loadedScanIds;
    if (loadedScanIds.length === 0) {
      setShowToolbarItems(false);
      setImageMarkersVisible(false);
      setDataPanel("data");
      if (viewer) {
        onReset("measurement");
        onReset("clipping");
      }
    } else {
      setShowToolbarItems(true);
    }
  }, [loadedScanIds]);

  useEffect(() => {
    if (imageMarkersVisible) {
      if (loadedScanIds.length > 0) {
        var _imageSpheres = [...imageSpheres];
        loadImages(_imageSpheres, 0);
      }
    } else {
      removeImageMarkers();
    }
  }, [imageMarkersVisible]);

  // for flags
  const { accounts } = useSelector(({ admin }) => admin.accountApp);
  const selectedOrgId = useMemo(() => (selectedSite ? findAccountId(accounts, selectedSite?.id) : 0), [accounts, selectedSite]);
  const flags = useFlags([POI_GEO_JSON]);
  const useServerGeneratedGeoJson = useMemo(() => isFlagEnabled(POI_GEO_JSON, flags[POI_GEO_JSON], selectedOrgId), [selectedOrgId]);

  const loadImages = (_imageSpheres, index) => {
    const loadWithFallback = () => {
      console.log(`Fetching images for Potree, using server-generated Geo JSON: ${useServerGeneratedGeoJson}, loadedScanIds: ${loadedScanIds}`);
      if (useServerGeneratedGeoJson) {
        // Try getMarkersForPotree first, fall back to loadImageInfos if it fails
        return getMarkersForPotree(loadedScanIds[index])
          .catch(error => {
            console.warn("Failed to get markers from Potree, falling back to loadImageInfos:", error);
            return loadImageInfos(loadedScanIds[index], 0, IMAGE_INFOS_LIMIT);
          });
      } else {
        // Directly use loadImageInfos if useServerGeneratedGeoJson is false
        return loadImageInfos(loadedScanIds[index], 0, IMAGE_INFOS_LIMIT);
      }
    };

    return loadWithFallback()
      .then((response) => {
        const imageInfos = response.data;
        const _imageSpheress = showMarkers(
          _imageSpheres,
          imageInfos,
          loadedScanIds[index]
        );

        if (index < loadedScanIds.length - 1) {
          return loadImages(_imageSpheress, index + 1);
        } else {
          setImageSpheres(_imageSpheress);
          return Promise.resolve(_imageSpheress);
        }
      })
      .catch((error) => {
        console.error("Error loading images (both primary and fallback failed):", error);
        throw error;
      });
  };

  const [startLoadingTime, setStartLoadingTime] = useState(new Date());
  const [potreeError, setPotreeError] = useState("");

  function loadPotree() {
    const script = document.createElement("script");
    script.src = "potree_libs_1.8.1/potree/potree.js";
    script.id = "potree_lib_script";
    document.head.appendChild(script);
    script.addEventListener("load", function () {
      Potree = window.Potree;

      const viewerLocal = createViewer();
      window.viewer = viewerLocal;
      var date = new Date();
      setStartLoadingTime(date);
      setViewer(viewerLocal);
      initClassifications(viewerLocal);
    });
  }

  useEffect(() => {
    if (potreeError) {
      const os = getCurrentOS();
      const browser = getCurrentUsingBrowser();
      const timeTaken = getStartedAfter(startLoadingTime, new Date());
      const webgl = detectWebGLContext();
      const scanNames = selectedSite.scans
        .filter((x) => loadedScanIds.includes(x.id))
        .map((x) => x.name)
        .join(",");
      const errorMessageDetails = {
        osName: os,
        browserName: browser,
        isWebglEnable: webgl,
        siteId: selectedSite.id,
        siteName: selectedSite.name,
        scanNames: scanNames,
        userName: customer.name,
        userEmail: customer.email,
        timeTaken: timeTaken,
        error: potreeError,
      };
      dispatch(sendPotreeErrorMail(errorMessageDetails));
    }
    setPotreeError("");
  }, [potreeError]);

  const loadedScanIdsRef = useRef();
  const [isPotreeRefresh, setIsPotreeRefresh] = useState(false);

  useEffect(() => {
    if (isPotreeRefresh) {
      potreePanelRef.current.innerHTML = "";
      delete window.Potree;
      var script = document.getElementById("potree_lib_script");
      if (script != null) {
        document.head.removeChild(script);
      }
      loadPotree();
      setCoordinateSpheres([]);
      setImageSpheres([]);
      setImageMarkersVisible(false);
      setDataPanel("data");
      onReset("measurement");
      onReset("clipping");
    }
  }, [isPotreeRefresh]);

  const onPotreeRefresh = () => {
    setIsPotreeRefresh(true);
  };

  const [isJustCrashed, setIsJustCrashed] = useState(false);

  const onPotreeCrash = (error) => {
    setPotreeError(error.stack);
    setIsJustCrashed(true);
  };

  const getStartedAfter = (startTime, endTime) => {
    var date1 = new Date(startTime);
    var date2 = new Date(endTime);
    var res = Math.abs(date2 - date1) / 1000;

    var minutes = Math.floor(res / 60);
    var seconds = res % 60;
    if (minutes >= 1) {
      return (minutes > 10 ? minutes : "0" + minutes) + " min";
    } else {
      return (
        (seconds.toFixed(3) > 10
          ? seconds.toFixed(3)
          : "0" + seconds.toFixed(3)) + " sec"
      );
    }
  };

  function createViewer() {
    const viewerElem = potreePanelRef.current;
    const viewer = new Potree.Viewer(
      viewerElem,
      {},
      onPotreeCrash,
      onPotreeRefresh
    );

    viewer.setEDLEnabled(false); // Clipping tools need EDL enabled, or resize / reposition controls don't show up
    viewer.setEDLRadius(1.0);
    viewer.setEDLStrength(0.0);
    // viewer.setMinNodeSize(0);
    viewer.setFOV(60);
    viewer.setBackground("white");
    if (getCurrentUsingBrowser() == "firefox") {
      viewer.setPointBudget(5000000);
    } else {
      viewer.setPointBudget(10000000);
    }
    if (window.screen.width <= 480) {
      viewer.useHQ = false;
      viewer.setControls(viewer.orbitControls);
    } else if (window.screen.width <= 1000) {
      viewer.useHQ = false;
      viewer.setControls(viewer.earthControls);
    } else {
      viewer.useHQ = true;
      viewer.setControls(viewer.earthControls);
    }

    viewer.loadSettingsFromURL();
    viewerElem.addEventListener(
      "DOMMouseScroll",
      () => {
        updateSpheres();
      },
      false
    );
    viewerElem.addEventListener("wheel", () => {
      updateSpheres();
    });

    // viewer.setControls(viewer.earthControls);
    if (selectedSite && selectedSite.coordinateSystem) {
      setSiteUTMProjection(selectedSite.coordinateSystem.projectionValue);
      if (selectedSite.coordinateSystem.units == "ft") {
        viewer.setLengthUnit("ftUS");
      } else {
        viewer.setLengthUnit("m");
      }
    } else {
      viewer.setLengthUnit("m");
    }

    viewer.useHQ = true;

    document.getElementById("potree_quick_buttons").style.display = "none";
    for (
      var i = 0;
      i < document.getElementsByClassName("sp-container").length;
      i++
    ) {
      document.getElementsByClassName("sp-container")[i].style.display = "none";
    }
    viewer.loadGUI(() => {
      viewer.setLanguage("en");
      viewer.setBackground("#F7F7F7");
      document.getElementById("potree_quick_buttons").style.display = "none";
      for (
        var i = 0;
        i < document.getElementsByClassName("sp-container").length;
        i++
      ) {
        document.getElementsByClassName("sp-container")[i].style.display =
          "none";
      }
      document.getElementById("profile_window").style.height = "35%";
      document.getElementById("profile_window").style.backgroundColor =
        "rgba( 25, 40, 44, 1)";

      document.getElementById("profile_titlebar").style.backgroundColor =
        "rgb(60, 80, 85)";
      document.getElementById("profile_titlebar").style.color = "#fff";
      document.getElementById("profile_titlebar").style.textShadow =
        "1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000";
      document.getElementById("profile_titlebar").style.fontSize = "1em";
      document.getElementById("profile_titlebar").style.fontWeight = "bold";

      document.getElementById("profile_window_title").style.position =
        "absolute";
      document.getElementById("profile_window_title").style.margin = "5px";

      const element = document.getElementById("profile_window");
      element.remove();
      document.getElementById("profile_window_new").append(element);
    });
    setViewerEDLValues(viewer);
    viewer.setCameraMode(CameraMode.PERSPECTIVE);
    return viewer;
  }

  function getCurrentUsingBrowser() {
    let userAgent = navigator.userAgent;
    var browserName = "";
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    return browserName;
  }

  function getCurrentOS() {
    var platform = window.navigator.platform;
    var macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    var windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    var iosPlatforms = ["iPhone", "iPad", "iPod"];
    var os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  function detectWebGLContext() {
    const canvas = document.createElement("canvas");
    const gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

    if (gl instanceof WebGLRenderingContext) {
      return true;
    } else {
      return false;
    }
  }
  const issueFinderRef = useRef();
  const issueCreaterRef = useRef();

  const updateSpheres = () => {
    updateCoordianateSpheres();
    updateImageSpheres();
    if (coordinateToolRef.current != undefined) {
      coordinateToolRef.current.updateCoordinateLabels();
    }
    if (issueFinderRef.current != undefined) {
      issueFinderRef.current.updateIssueCoordinates();
    }
    if (issueCreaterRef.current != undefined) {
      issueCreaterRef.current.updateIssuesMarkers();
    }
  };

  const { zoomToPoint, zoomToMultiplePoints } = useZoomFunctions(
    viewer,
    updateSpheres
  );

  const removeImageMarkers = () => {
    //var _imageSpheres = [...imageSpheres];
    // _imageSpheres.forEach(sphere => {
    // //sphere.visible = false;
    if (viewer != null) {
      viewer.scene.scene.children = viewer.scene.scene.children.filter(
        (x) => x.name.split("-")[0] != "pano"
      );
    }
    //});
    setImageSpheres([]);
  };
  const updateImageSpheres = () => {
    var _imageSpheres = [...imageSpheres];
    if (_imageSpheres.length > 0) {
      setTimeout(function () {
        if (_imageSpheres.length > 0) {
          const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
          let clientWidth = renderAreaSize.width;
          let clientHeight = renderAreaSize.height;
          let camera = viewer.scene.getActiveCamera();

          _imageSpheres.forEach((sphere) => {
            let distance = camera.position.distanceTo(
              sphere.getWorldPosition(new THREE.Vector3())
            );
            let pr = projectedRadius(
              1,
              camera,
              distance,
              clientWidth,
              clientHeight
            );
            let scale = 18 / pr;
            sphere.scale.set(scale, scale, scale);
          });
          // viewer.scene.scene.children.filter(x => x.name.split('-')[0] == 'pano').forEach(sphere => {
          // let distance = camera.position.distanceTo(sphere.getWorldPosition(new THREE.Vector3()));
          // let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);
          // let scale = (18 / pr);
          // sphere.scale.set(scale, scale, scale);
          // });
        }
      }, 200);
    }
  };
  function updateCoordianateSpheres() {
    var _coordinateSpheres = [...coordinateSpheres];
    if (_coordinateSpheres.length > 0) {
      setTimeout(function () {
        if (_coordinateSpheres.length > 0) {
          const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
          let clientWidth = renderAreaSize.width;
          let clientHeight = renderAreaSize.height;
          let camera = viewer.scene.getActiveCamera();

          _coordinateSpheres.forEach((sphere) => {
            let distance = camera.position.distanceTo(
              sphere.getWorldPosition(new THREE.Vector3())
            );
            let pr = projectedRadius(
              1,
              camera,
              distance,
              clientWidth,
              clientHeight
            );
            let scale = 13 / pr;
            sphere.scale.set(scale, scale, scale);
          });
        }
      }, 200);
    }
  }

  const addCoordinateSphere = (spheres) => {
    var _coordinateSpheres = [...coordinateSpheres];
    spheres.forEach((sphere) => {
      if (sphere.model) {
        _coordinateSpheres = _coordinateSpheres.filter(
          (x) => x.uuid != sphere.model.uuid
        );
      }
      if (sphere.control) {
        _coordinateSpheres = _coordinateSpheres.filter(
          (x) => x.uuid != sphere.control.uuid
        );
      }
      if (sphere.model) {
        _coordinateSpheres.push(sphere.model);
      }
      if (sphere.control) {
        _coordinateSpheres.push(sphere.control);
      }
      // _coordinateSpheres.push(sphere);
    });

    setCoordinateSpheres(_coordinateSpheres);
  };

  const removeCoordinateSphere = (spheres) => {
    if (spheres.length > 0) {
      var _coordinateSpheres = [...coordinateSpheres];
      spheres.forEach((sphere) => {
        if (sphere.model) {
          _coordinateSpheres = _coordinateSpheres.filter(
            (x) => x.uuid != sphere.model.uuid
          );
          viewer.scene.scene.remove(sphere.model);
          //viewer.scene.scene.children = viewer.scene.scene.children.filter(x => x.uuid != sphere.model.uuid)
        }
        if (sphere.control) {
          _coordinateSpheres = _coordinateSpheres.filter(
            (x) => x.uuid != sphere.control.uuid
          );
          viewer.scene.scene.remove(sphere.control);
          //viewer.scene.scene.children = viewer.scene.scene.children.filter(x => x.uuid != sphere.control.uuid)
        }
      });
      setCoordinateSpheres(_coordinateSpheres);
    } else {
      let _coordinateSpheres = [...coordinateSpheres];
      _coordinateSpheres.forEach((sphere) => {
        viewer.scene.scene.remove(sphere);
        //viewer.scene.scene.children = viewer.scene.scene.children.filter(x => x.uuid != sphere.uuid)
      });
      setCoordinateSpheres([]);
    }
  };

  const removeCoordinateSingleSphere = (sphere) => {
    if (sphere) {
      var _coordinateSpheres = [...coordinateSpheres];
      _coordinateSpheres = _coordinateSpheres.filter(
        (x) => x.uuid != sphere.uuid
      );
      viewer.scene.scene.remove(sphere);
      setCoordinateSpheres(_coordinateSpheres);
    }
  };

  function projectedRadius(
    radius,
    camera,
    distance,
    screenWidth,
    screenHeight
  ) {
    if (camera.type == "OrthographicCamera") {
      return projectedRadiusOrtho(
        radius,
        camera.projectionMatrix,
        screenWidth,
        screenHeight
      );
    } else if (camera.type == "PerspectiveCamera") {
      return projectedRadiusPerspective(
        radius,
        (camera.fov * Math.PI) / 180,
        distance,
        screenHeight
      );
    } else {
      throw new Error("invalid parameters");
    }
  }

  function projectedRadiusOrtho(radius, proj, screenWidth, screenHeight) {
    let p1 = new THREE.Vector4(0);
    let p2 = new THREE.Vector4(radius);

    p1.applyMatrix4(proj);
    p2.applyMatrix4(proj);
    p1 = new THREE.Vector3(p1.x, p1.y, p1.z);
    p2 = new THREE.Vector3(p2.x, p2.y, p2.z);
    p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
    p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
    p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
    p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
    return p1.distanceTo(p2);
  }

  function projectedRadiusPerspective(radius, fov, distance, screenHeight) {
    let projFactor = 1 / Math.tan(fov / 2) / distance;
    projFactor = (projFactor * screenHeight) / 2;

    return radius * projFactor;
  }

  function initClassifications(viewer) {
    viewer.setClassifications(DEFAULT_CLASSIFICATIONS);
    setClassifications(DEFAULT_CLASSIFICATIONS);
  }

  const updateClassifications = (calssifications) => {
    viewer.setClassifications(calssifications);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] || a[orderBy]) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const dataExistArray = array.filter((x) =>
      checkPropertyExist(x, "processingCompletionDate")
    );
    const dataNotExistArray = array.filter(
      (x) => !checkPropertyExist(x, "processingCompletionDate")
    );
    const stabilizedThis = dataExistArray.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    var finalArray = stabilizedThis.map((el) => el[0]);
    dataNotExistArray.forEach((obj) => {
      finalArray.push(obj);
    });

    return finalArray;
  }

  function checkPropertyExist(obj, property) {
    if (obj[property]) {
      return true;
    } else {
      return false;
    }
  }

  function loadRecentModel() {
    var completedScans = scans.filter(
      (scan) =>
        scan.modelFormat === "ply" &&
        scan.potreeStatus === 2 &&
        (role.includes("admin") ||
          scan.latestInvoice ||
          scan.isFree ||
          scan.scanUsageCharge)
    );
    if (completedScans.length > 0) {
      completedScans = stableSort(
        completedScans,
        getComparator("desc", "processingCompletionDate")
      );
      addPointCloud(completedScans[0], false);
      setShowToolbarItems(true);
    } else {
      setShowToolbarItems(false);
    }
    // const scan = scans.find(
    //   (scan) =>
    //     scan.modelFormat === "ply" &&
    //     scan.potreeStatus === 2 &&
    //     (role.includes("admin") ||
    //       scan.latestInvoice ||
    //       scan.isFree ||
    //       scan.scanUsageCharge)
    // );
    // if (scan) {
    //   addPointCloud(scan);
    //   setShowToolbarItems(true);
    // } else {
    //   setShowToolbarItems(false);
    // }
  }

  function onTogglePointCloud(model, checked) {
    if (checked) {
      if (isJustCrashed) {
        loadedScanIdsRef.current.push(model.id);
        setIsPotreeRefresh(true);
        setIsJustCrashed(false);
        setShowToolbarItems(true);
      } else {
        addPointCloud(model, true);
        setShowToolbarItems(true);
      }
    } else {
      removePointCloud(model, true);
    }
  }

  function zoomInAndOutMarker(radius, location) {
    let targetRadius = radius;
    let d = viewer.scene.view.direction.multiplyScalar(-1);
    let cameraTargetPosition = new THREE.Vector3().addVectors(
      location,
      d.multiplyScalar(targetRadius)
    );
    let animationDuration = 600;
    let easing = TWEEN.Easing.Quartic.Out;
    let value = { x: 0 };
    let tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);
    tween.easing(easing);
    viewer.controls.tweens.push(tween);
    let startPos = viewer.scene.view.position.clone();
    let targetPos = cameraTargetPosition.clone();
    let startRadius = viewer.scene.view.radius;
    let targetRadius1 = cameraTargetPosition.distanceTo(location);
    tween.onUpdate(() => {
      let t = value.x;
      viewer.scene.view.position.x = (1 - t) * startPos.x + t * targetPos.x;
      viewer.scene.view.position.y = (1 - t) * startPos.y + t * targetPos.y;
      viewer.scene.view.position.z = (1 - t) * startPos.z + t * targetPos.z;

      viewer.scene.view.radius = (1 - t) * startRadius + t * targetRadius1;
      viewer.setMoveSpeed(viewer.scene.view.radius / 2.5);
    });

    tween.onComplete(() => {
      viewer.controls.tweens = viewer.controls.tweens.filter(
        (e) => e !== tween
      );
      updateSpheres();
    });
    tween.start();
  }
  function zoomToPointCloud(name) {
    let selectedScanName = name;
    if (!name) {
      selectedScanName = selectedSite.scans
        .filter((x) => loadedScanIds[0] === x.id)
        .map((x) => x.name)[0];
    }
    var pointclouds = viewer.scene.pointclouds.filter(
      (x) => x.name == selectedScanName
    );
    if (pointclouds.length > 0) {
      var location = new THREE.Vector3(
        pointclouds[0].position.x + pointclouds[0].boundingSphere.center.x,
        pointclouds[0].position.y + pointclouds[0].boundingSphere.center.y,
        pointclouds[0].position.z
      );
      zoomInAndOutMarker(viewer.scene.view.radius / 4, location);
    }
  }

  // eslint-disable-next-line no-unused-vars
  function zoomToPoints() {
    var pointclouds = viewer.scene.pointclouds.filter(
      (x) => x.scanId == loadedScanIds[0]
    );
    if (pointclouds.length > 0) {
      var location = new THREE.Vector3(
        pointclouds[0].position.x + pointclouds[0].boundingSphere.center.x,
        pointclouds[0].position.y + pointclouds[0].boundingSphere.center.y,
        pointclouds[0].position.z
      );
      zoomInAndOutMarker(viewer.scene.view.radius / 4, location);
    }
  }

  function zoomOutPointCloud(name) {
    let selectedScanName = name;
    if (!name) {
      selectedScanName = selectedSite.scans
        .filter((x) => loadedScanIds[0] === x.id)
        .map((x) => x.name)[0];
    }
    var pointclouds = viewer.scene.pointclouds.filter(
      (x) => x.name == selectedScanName
    );

    if (pointclouds.length > 0) {
      var location = new THREE.Vector3(
        pointclouds[0].position.x + pointclouds[0].boundingSphere.center.x,
        pointclouds[0].position.y + pointclouds[0].boundingSphere.center.y,
        pointclouds[0].position.z
      );
      zoomInAndOutMarker(viewer.scene.view.radius * 4, location);
    }
  }

  function addPointCloud(model, isFromCheckBox) {
    if (dataPanel == "alignToControl") {
      loadedScanIds.forEach((id) => {
        var scan = scans.find((x) => x.id == id);
        if (scan) {
          removePointCloud(scan, false);
        }
      });
    }
    dispatch(
      addLog({
        resourceid: model.id,
        message: "Added Scan to point cloud viewer",
        resourcetype: "scanpointcloud",
        action: "pointcloud",
        resourcename: model.name,
      })
    );
    setCurrentModelScanId(model.id);
    setScanName(model.name);
    setCurrentModelScan(model);
    if (selectedSite && selectedSite.coordinateSystem) {
      setCoordinateSystem(selectedSite.coordinateSystem.units);
      if (selectedSite.coordinateSystem.units == "ft") {
        setFeetLengthSwitch(true);
      }
    }

    const modelUrl = getModelUrl(model);
    const loadPointCloudApi = loadJsonFileData(modelUrl);
    Promise.resolve(loadPointCloudApi)
      .then(() => {
        Potree.loadPointCloud(modelUrl, model.name).then(
          (e) => {
            let pointcloud = e.pointcloud;
            let material = pointcloud.material;

            material.size = 0.5;
            material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
            material.shape = Potree.PointShape.CIRCLE;
            material.activeAttributeName = activeAttribute;

            pointcloud.name = model.name;
            pointcloud.scanId = model.id;
            pointcloud.scale.set(1, 1, 1);

            viewer.scene.addPointCloud(pointcloud);
            for (
              var i = 0;
              i < document.getElementsByClassName("sp-container").length;
              i++
            ) {
              document.getElementsByClassName("sp-container")[i].style.display =
                "none";
            }

            if (loadedScanIds.length === 0) {
              viewer.fitToScreen();
            }
            if (dataPanel == "alignToControl") {
              setLoadedScanIds([model.id]);
            } else {
              addToLoadedScanIds(model.id);
            }
          },
          (e) => {
            console.err("ERROR: ", e);
          }
        );
      })
      .catch(() => {
        if (isFromCheckBox) {
          setOpenUnexpectedErrorDailog(true);
        }
      });
  }

  function getModelUrl(model) {
    return APP_CONFIG.api + "v1/files/downloadFileChunk/" + model.modelUrl3D;
  }

  function addToLoadedScanIds(id) {
    const modifiedLoadedScanIds = [...loadedScanIds];
    modifiedLoadedScanIds.push(id);
    setLoadedScanIds(modifiedLoadedScanIds);
  }

  function removeFromLoadedScanIds(id) {
    const modifiedLoadedScanIds = [...loadedScanIds];
    const index = modifiedLoadedScanIds.findIndex(
      (currentId) => currentId === id
    );

    modifiedLoadedScanIds.splice(index, 1);
    setLoadedScanIds(modifiedLoadedScanIds);
  }

  function removePointCloud(model, isUpdateState) {
    viewer.scene.pointclouds.forEach((pc, index) => {
      if (pc.name === model.name) {
        // viewer.scene.pointclouds[index].visible = false;
        viewer.scene.scenePointCloud.remove(pc);
        viewer.scene.pointclouds.splice(index, 1);
        if (isUpdateState) {
          removeFromLoadedScanIds(model.id);
        }
      }
    });
  }

  const scans = selectedSite.scans;
  function onSelectNavigation(type) {
    const controls = {
      orbit: "orbitControls",
      earth: "earthControls",
      flight: "fpControls",
    };
    if (currentModelScanId != 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${type} navigation of the scan`,
          resourcetype: "scannavigation",
          action: type,
          resourcename: scanName,
        })
      );
    }
    viewer.setControls(viewer[controls[type]]);
  }

  function onSelectMeasurement(type) {
    const insertion = getInsertion(type);
    if (currentModelScanId !== 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${type} measurement of the scan`,
          resourcetype: "scanmeasurement",
          action: type,
          resourcename: scanName,
        })
      );
    }
    viewer.measuringTool.startInsertion(insertion);
    setMeasuring(true);
  }

  function onSelectClipping(type) {
    viewer.setClipTask(Potree.ClipTask.HIGHLIGHT);
    if (type === "volume") {
      viewer.volumeTool.startInsertion({ clip: true });
    } else if (type === "polygon") {
      viewer.clippingTool.startInsertion({ type: "polygon" });
    }
    if (currentModelScanId !== 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${type} clipping of the scan`,
          resourcetype: "scancliping",
          action: type,
          resourcename: scanName,
        })
      );
    }
    setClippingStart(true);
  }

  function onClip(clipTask, type) {
    if (clipTask === "inside") {
      viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    } else if (clipTask === "outside") {
      viewer.setClipTask(Potree.ClipTask.SHOW_OUTSIDE);
    } else {
      throw new Error("Invalid clip task: " + clipTask);
    }
    if (currentModelScanId != 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${clipTask} clipping of the scan`,
          resourcetype: "scancliping",
          action: type,
          resourcename: scanName,
        })
      );
    }
    if (type === "volume") {
      viewer.volumeTool.startInsertion({ clip: true });
      setClippingStart(false);
    }
  }

  function onReset(type) {
    if (type === "measurement") {
      //viewer.scene.removeAllMeasurements();
      var measurements = viewer.scene.measurements.filter(
        (x) => x.name != "IssuePoint"
      );
      measurements.forEach((measurement) => {
        viewer.scene.removeMeasurement(measurement);
      });
      // while (measurements.length > 0) {
      //   viewer.scene.removeMeasurement(measurements[0]);
      // }
      if (
        selectedSite &&
        selectedSite.coordinateSystem &&
        selectedSite.coordinateSystem.units == "ft"
      ) {
        viewer.setLengthUnit("ftUS");
        setFeetLengthSwitch(true);
      } else {
        setFeetLengthSwitch(false);
        viewer.setLengthUnit("m");
      }
    } else if (type === "clipping") {
      viewer.scene.removeAllClipVolumes();
      // let profile = viewer.scene.profiles[0];
      // viewer.scene.removeProfile(profile);
      // document.getElementById("profile_window").style.display = "none";
      viewer.setClipTask(Potree.ClipTask.HIGHLIGHT);
      // setCrossSection(false);
      // setCrossSectionLineWidth("1");
      // setShowCrossSectionProfileButton(false);
    } else if (type === "crossSection") {
      let profile = viewer.scene.profiles[0];
      viewer.scene.removeProfile(profile);
      document.getElementById("profile_window").style.display = "none";
      setCrossSection(false);
      setCrossSectionLineWidth("1");
      setShowCrossSectionProfileButton(false);
    } else {
      console.error("Unrecognized type for reset = " + type);
    }
  }

  // eslint-disable-next-line no-unused-vars
  function getAllPointsOfPointCloud(pointCloud) {
    var list = [];
    pointCloud.visibleNodes.forEach((node) => {
      var array = node.geometryNode.geometry.attributes.position.array;
      var index = 0;
      for (var i = 0; i < array.length; i = i + 3) {
        var x = array[i + 0];
        var y = array[i + 1];
        var z = array[i + 2];
        let position = new THREE.Vector3(x, y, z);
        position.applyMatrix4(pointCloud.matrixWorld);
        list[index] = position;
        index++;
      }
    });

    return list;
  }

  const [unit, setUnit] = useState("meters");

  function onToggleFeetLength() {
    const value = !feetLengthSwitch;
    setFeetLengthSwitch(value);
    if (value) {
      if (
        selectedSite &&
        selectedSite.coordinateSystem &&
        selectedSite.coordinateSystem.units == "ft"
      ) {
        viewer.setLengthUnit("ftUS");
      } else {
        viewer.setLengthUnit("ft");
      }
      setUnit("feet");
      if (currentModelScanId != 0) {
        dispatch(
          addLog({
            resourceid: currentModelScanId,
            message: `Used Feet length measurement of the scan`,
            resourcetype: "scanmeasurement",
            action: type,
            resourcename: scanName,
          })
        );
      }
    } else {
      if (
        selectedSite &&
        selectedSite.coordinateSystem &&
        selectedSite.coordinateSystem.units == "ft"
      ) {
        viewer.setLengthUnit("mUS");
      } else {
        viewer.setLengthUnit("m");
      }
      setUnit("meters");
      if (currentModelScanId != 0) {
        dispatch(
          addLog({
            resourceid: currentModelScanId,
            message: `Used meter length measurement of the scan`,
            resourcetype: "scanmeasurement",
            action: type,
            resourcename: scanName,
          })
        );
      }
    }
  }

  function showMarkers(_imageSpheres, imageList, scanId) {
    imageList.forEach((image, index) => {
      const geometry = new THREE.SphereGeometry(image.featureType === "POI" ? 0.6 : 0.4);
      
      // Set POI points to green
      const color = image.featureType === "POI" ? 0x66ff00 : 0xff0000;
      const material = new THREE.MeshBasicMaterial({ color });

      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.x = image.coordinateX;
      sphere.position.y = image.coordinateY;
      sphere.position.z = image.coordinateZ;
      sphere.name = "pano-" + scanId + "-" + index;
      sphere.featureType = image.featureType

      _imageSpheres.push(sphere);
      viewer.scene.scene.add(sphere);
    });

    return _imageSpheres;
  }

  function onModelMouseUp() {
    if (measuring) {
      setMeasuring(false);
    }

    if (clippingStart) {
      setClippingStart(false);
    }
    if (crossSection) {
      setCrossSection(false);
      let profile = viewer.scene.profiles[0];
      profile.setWidth(crossSectionLineWidth);
    }
  }

  function onModelMouseDown(event) {
    const intersects = getIntersects(event.clientX, event.clientY);
    if (intersects.length > 0) {
      const name = intersects[intersects.length - 1].object.name;
      const nameParts = name.split("-");
      const category = nameParts[0];
      const scanId = nameParts[1];
      const lastIndex = nameParts[2];

      resetHoveredIntersects();

      if (category === "pano") {
        addBroadcastChannel();
        updateCurrentViewPanoImage(intersects[intersects.length - 1].object);
        // change to yellow on click
        intersects[intersects.length - 1].object.material.color = { r: 1, g: 1, b: 0 };
        window.open(
          "apps/panos/" + scanId + "/" + lastIndex,
          "pano",
          "height=1600,width=1000,left=600,top=20"
        );
      }
    }
  }

  const [broadcastChannel, setBroadcastChannel] = useState(null);
  const [currentViewPanoImage, setCurrentViewPanoImage] = useState(null);
  const prevCurrentViewPanoImageRef = useRef();
  const addBroadcastChannel = () => {
    if (!broadcastChannel) {
      var channel = new BroadcastChannel(window.location.origin);
      addRecieveEventListener(channel);
      setBroadcastChannel(channel);
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      addRecieveEventListener(broadcastChannel);
    }
    return () => {
      removeRecieveEventListener(broadcastChannel);
    };
  }, [broadcastChannel]);

  useEffect(() => {
    if (currentViewPanoImage) {
      prevCurrentViewPanoImageRef.current = currentViewPanoImage;
      currentViewPanoImage.material.color = { r: 1, g: 1, b: 0 };
    } else {
      if (prevCurrentViewPanoImageRef.current) {
        prevCurrentViewPanoImageRef.current.material.color = {
          r: 1,
          g: 0,
          b: 0,
        };
      }
    }
  }, [currentViewPanoImage]);

  const addRecieveEventListener = (channel) => {
    if (channel) {
      channel.addEventListener("message", onReceiveBroadcastMessage);
    }
  };
  const removeRecieveEventListener = (channel) => {
    if (channel) {
      channel.removeEventListener("message", onReceiveBroadcastMessage);
      setBroadcastChannel(null);
    }
  };

  const onReceiveBroadcastMessage = (event) => {
    let receivedData = event.data;
    if (receivedData.isClosed) {
      //removeRecieveEventListener();
      setCurrentViewPanoImage(null);
    } else {
      var imageSphere = imageSpheres.find(
        (x) =>
          x.name.split("-")[0] == "pano" &&
          x.name.split("-")[1] == receivedData.imageInfo.scanId &&
          x.name.split("-")[2] == receivedData.index
      );
      if (imageSphere) {
        updateCurrentViewPanoImage(imageSphere);
      }
    }
  };
  const updateCurrentViewPanoImage = (imageSphere) => {
    if (prevCurrentViewPanoImageRef.current) {
      prevCurrentViewPanoImageRef.current.material.color = { r: 1, g: 0, b: 0 };
    }
    imageSphere.material.color = { r: 1, g: 1, b: 0 };
    setCurrentViewPanoImage(imageSphere);
  };

  const plyScans = scans.filter((scan) => scan.modelFormat === "ply");

  function onMouseMove(event) {
    const intersects = getIntersects(event.clientX, event.clientY);

    if (intersects.length > 0) {
      potreePanelRef.current.style.cursor = "pointer";

      resetHoveredIntersects();

      intersects.forEach((intersect) => {
        const name = intersects[intersects.length - 1].object.name;
        const nameParts = name.split("-");
        const category = nameParts[0];
        if (category === "pano") {
          const scanId = nameParts[1];
          const lastIndex = nameParts[2];
          if (
            currentViewPanoImage &&
            currentViewPanoImage.name.split("-")[1] == scanId &&
            currentViewPanoImage.name.split("-")[2] == lastIndex
          ) {
            currentViewPanoImage.material.color = { r: 1, g: 1, b: 0 };
          } else {
            // change dot to yellow on hover
            intersect.object.material.color = { r: 1, g: 1, b: 0 };
            hoveredIntersects.push(intersect);
          }
        }
      });
    } else {
      resetHoveredIntersects();
      potreePanelRef.current.style.cursor = "default";
    }
  }

  const resetHoveredIntersects = () => {
    hoveredIntersects.forEach((intersect) => {
      const name = intersect.object.name;
      const nameParts = name.split("-");
      const scanId = nameParts[1];
      const lastIndex = nameParts[2];
      if (
        currentViewPanoImage &&
        currentViewPanoImage.name.split("-")[1] == scanId &&
        currentViewPanoImage.name.split("-")[2] == lastIndex
      ) {
        intersect.object.material.color = { r: 1, g: 1, b: 0 };
      } else {
        intersect.object.material.color = intersect.object.featureType === "POI" ? {r: 102, g: 255, b: 0} : { r: 1, g: 0, b: 0 };
      }
    });
    hoveredIntersects = [];
  };

  /**
   * Given an (x,y) coordinate this function returns the list of intersects
   * of objects with that point.
   *
   * @param x
   * @param y
   */
  function getIntersects(x, y) {
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    const viewerElem = potreePanelRef.current;
    const rect = viewerElem.getBoundingClientRect();

    pointer.x = ((x - rect.left) / (rect.right - rect.left)) * 2 - 1;
    pointer.y = -((y - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    const camera = viewer.scene.getActiveCamera();
    raycaster.setFromCamera(pointer, camera);

    return raycaster.intersectObjects(viewer.scene.scene.children);
  }

  function onToggleClassificationVisibility(key) {
    const classification = classifications[key];
    classification.visible = !classification.visible;

    const _classifications = { ...classifications };
    _classifications[key] = classification;
    setClassifications(_classifications);
    viewer.setClassifications(_classifications);
  }

  function onChangeClassificationColor(color, key) {
    const classification = classifications[key];
    classification.color = [
      color.rgb[0] / 255,
      color.rgb[1] / 255,
      color.rgb[2] / 255,
      1,
    ];

    const _classifications = { ...classifications };
    _classifications[key] = classification;
    setClassifications(_classifications);
  }

  function onActiveAttribute(event, attribute) {
    setActiveAttribute(attribute);
    if (currentModelScanId !== 0) {
      dispatch(
        addLog({
          resourceid: currentModelScanId,
          message: `Used ${attribute} classification of the scan`,
          resourcetype: "scanclassification",
          action: attribute,
          resourcename: scanName,
        })
      );
    }
    viewer.scene.pointclouds.forEach((pc) => {
      console.log(attribute);
      console.log(
        typeof pc.material.activeAttributeName,
        pc.material.activeAttributeName
      );
      pc.material.activeAttributeName = attribute;
    });
  }

  function downloadLazFile(downloadScanId) {
    dispatch(downloadScanLazFile(downloadScanId));
  }

  function getClassificationAvailable() {
    if (currentModelScan != null) {
      return currentModelScan.classificationAvailable;
    } else {
      return selectedSite.classificationAvailable;
    }
  }

  // eslint-disable-next-line complexity
  function onClickDataPanel(value) {
    const coordinatesId = scans
      ?.filter((scan) => loadedScanIds?.includes(scan.id))
      .map((scan) => scan.coordinatesId);

    if (coordinatesId)
      retrieveSiteCoordinatesInfo(viewer.scene.scene, coordinatesId[0]);

    KeyEventListeners.removeEventListener();
    if (coordinateToolRef.current != undefined) {
      coordinateToolRef.current.cleanCoordinatesRef();
      coordinateToolRef.current.exitFromAddMode();
    }
    if (
      (dataPanel == "alignToControl" && value == "pointImport") ||
      (dataPanel == "pointImport" && value == "alignToControl")
    ) {
      if (value == "alignToControl") {
        if (loadedScanIds.length == 1) {
          setDataPanel(value);
        } else {
          swal(
            "",
            "Align to Control tool can only be used on one scan at a time.",
            "warning"
          );
        }
      } else {
        setDataPanel(value);
      }
    } else if (dataPanel == "alignToControl" || dataPanel == "pointImport") {
      if (coordinateToolRef.current != undefined) {
        if (coordinateToolRef.current.getIsCoordinateSave() == false) {
          setDataPanel(value);
        } else {
          swal({
            title: "",
            text: "Do you want to save your changes to the Coordinate Tools before closing?",
            icon: "warning",
            buttons: {
              Yes: {
                text: "Yes",
                value: "Yes",
              },
              No: {
                text: "No",
                value: "No",
              },
            },
          }).then((value) => {
            switch (value) {
              case "Yes":
                if (coordinateToolRef.current != undefined) {
                  if (coordinateToolRef.current.updateCoordinates() == true) {
                    setDataPanel(value);
                  }
                } else {
                  setDataPanel(value);
                }
                break;
              case "No":
                setDataPanel(value);
                break;
            }
          });
        }
      } else {
        setDataPanel(value);
      }
    } else if (value == "alignToControl") {
      if (loadedScanIds.length == 1) {
        setDataPanel(value);
      } else {
        swal(
          "",
          "Align to Control tool can only be used on one scan at a time.",
          "warning"
        );
      }
    } else {
      setDataPanel(value);
    }
  }

  function onSelectCrossSection() {
    document.getElementById("profile_window").style.display = "none";
    viewer.scene.removeProfile(viewer.scene.profiles[0]);
    viewer.profileTool.startInsertion();
    let profile = viewer.scene.profiles[0];
    profile.name = "Elevation Profile";
    profile.setWidth(crossSectionLineWidth);
    setCrossSection(true);
    setShowCrossSectionProfileButton(true);
  }

  function onClickCrossSectionShowProfile() {
    viewer.profileWindow.show();
    viewer.profileWindowController.setProfile(viewer.scene.profiles[0]);
    document.getElementById("profile_window").style.height = "388px";
    document.getElementById("profile_window").style.width = "90%";
    document.getElementById("profile_window").style.top = "calc(100vh - 488px)";
    document.getElementById("profile_window").style.left = "calc(100% - 95%)";
  }

  function onChangeCrossSectionLineWidth(value) {
    let profile = viewer.scene.profiles[0];
    if (profile != undefined) {
      profile.name = "Elevation Profile";
      profile.setWidth(value);
    }
    setCrossSectionLineWidth(value);
  }

  const unloadPointClouds = () => {
    loadedScanIds.forEach((scanId) => {
      var _scans = selectedSite.scans.filter((x) => x.id == scanId);
      if (_scans.length > 0) {
        removePointCloud(_scans[0], false);
      }
    });
    setLoadedScanIds([]);
  };

  const setViewerEDLValues = (viewer) => {
    setEnableEyeDomeLighting(viewer.getEDLEnabled());
    setEdlRadius(viewer.getEDLRadius());
    setEdlStrength(viewer.getEDLStrength());
    setEdlOpacity(viewer.getEDLOpacity());
    setUseHighQuality(viewer.useHQ);
  };

  const onChangeEdlRadius = (value) => {
    setEdlRadius(value);
    viewer.setEDLRadius(value);
  };
  const onChangeEdlStrength = (value) => {
    setEdlStrength(value);
    viewer.setEDLStrength(value);
  };

  const onChangeEdlOpacity = (value) => {
    setEdlOpacity(value);
    viewer.setEDLOpacity(value);
  };

  const onToggleEyeDomeLighting = (e) => {
    if (e.target.checked) {
      viewer.setEDLEnabled(true);
      setEnableEyeDomeLighting(true);
    } else {
      viewer.setEDLEnabled(false);
      setEnableEyeDomeLighting(false);
    }
  };

  const onToggleUseHighQuality = (e) => {
    if (e.target.checked) {
      viewer.useHQ = true;
      setUseHighQuality(true);
      // setIsOrthographicCamera(false);
      // viewer.setCameraMode(CameraMode.PERSPECTIVE);
    } else {
      viewer.useHQ = false;
      setUseHighQuality(false);
    }
  };

  const onResetEDLSettings = () => {
    if (window.screen.width <= 480) {
      viewer.useHQ = false;
    } else if (window.screen.width <= 1000) {
      viewer.useHQ = false;
    } else {
      viewer.useHQ = true;
    }
    viewer.setEDLRadius(1.0);
    viewer.setEDLStrength(0.0);
    viewer.setEDLOpacity(1.0);
    viewer.setEDLEnabled(false);
    setViewerEDLValues(viewer);
    viewer.scene.pointclouds.forEach((pointCloud) => {
      pointCloud.material.size = 0.5;
      setPointSize(0.5);
      pointCloud.material.pointSizeType = 2;
      setIsFixed(false);
      pointCloud.material.rgbBrightness = 0;
      setBrightness(0);
      setGamma(1.0);
      pointCloud.material.rgbGamma = 1.0;
      setContrast(0);
      pointCloud.material.rgbContrast = 0;
    });
  };

  const [rightDrawerOpen, setRightDrawerOpen] = useState(true);

  const onChangeCameraProjection = () => {
    const value = !isOrthographicCamera;
    setIsOrthographicCamera(value);
    if (value) {
      viewer.setCameraMode(CameraMode.ORTHOGRAPHIC);
      viewer.useHQ = false;
      setUseHighQuality(false);
    } else {
      viewer.setCameraMode(CameraMode.PERSPECTIVE);
      viewer.useHQ = true;
      setUseHighQuality(true);
    }
  };

  const onChangeFixed = () => {
    const value = !isFixed;
    setIsFixed(value);
    viewer.scene.pointclouds.forEach((pointCloud) => {
      if (value) {
        pointCloud.material.pointSizeType = 0;
      } else {
        pointCloud.material.pointSizeType = 2;
      }
    });
  };

  const onChangePointSize = (value) => {
    setPointSize(value);
    if (viewer) {
      viewer.scene.pointclouds.forEach((pointCloud) => {
        pointCloud.material.size = value;
      });
    }
  };

  const onChangeBrightness = (value) => {
    setBrightness(value);
    if (viewer) {
      viewer.scene.pointclouds.forEach((pointCloud) => {
        pointCloud.material.rgbBrightness = value;
      });
    }
  };

  const onChangeGamma = (value) => {
    setGamma(value);
    if (viewer) {
      viewer.scene.pointclouds.forEach((pointCloud) => {
        pointCloud.material.rgbGamma = value;
      });
    }
  };
  const onChangeContrast = (value) => {
    setContrast(value);
    if (viewer) {
      viewer.scene.pointclouds.forEach((pointCloud) => {
        pointCloud.material.rgbContrast = value;
      });
    }
  };

  const selectAllScans = (isChecked) => {
    let loadedScans = selectedSite.scans.filter(
      (scan) => scan.potreeStatus === 2
    );
    if (isChecked) {
      var _measurementData = {};
      _measurementData.scans = loadedScans;
      _measurementData.measurements = [];
      loadScansPointClouds(loadedScans, _measurementData);
    } else {
      viewer.scene.pointclouds.forEach((pc) => {
        viewer.scene.scenePointCloud.remove(pc);
      });
      viewer.scene.pointclouds = [];
      setLoadedScanIds([]);
    }
  };

  const handleOnClickDailog = () => {
    setOpenUnexpectedErrorDailog(false);
  };

  const handleOnClickContactSupport = () => {
    setOpenUnexpectedErrorDailog(false);
    navigate("/apps/user/support");
  };

  const [openEditCoordinate, setOpenEditCoordinate] = useState(false);

  const handleClearModelWizardAction = (name) => {
    if (coordinates.length > 0) {
      for (let coordinate of coordinates) {
        if (coordinate.name === name) {
          handleOnClearModelCoordinateWizard(coordinates.indexOf(coordinate));
        }
      }
    }
  };

  const handleOnClearModelCoordinateWizard = (index) => {
    setOpenEditCoordinate(false);
    const _selectedMarkerIndex = [index];
    const _coordinates = [...coordinatesRef.current];
    const _markers = [...markersRef.current];
    if (_coordinates[_selectedMarkerIndex[0]]) {
      if (
        _coordinates[_selectedMarkerIndex[0]].model &&
        _coordinates[_selectedMarkerIndex[0]].control == undefined
      ) {
        _coordinates.splice(_selectedMarkerIndex[0], 1);

        removeCoordinateSingleSphere(_markers[_selectedMarkerIndex[0]].model);
        _markers.splice(_selectedMarkerIndex[0], 1);
        _selectedMarkerIndex.splice(0, 1);
      } else if (
        _coordinates[_selectedMarkerIndex[0]].model &&
        _coordinates[_selectedMarkerIndex[0]].control
      ) {
        delete _coordinates[_selectedMarkerIndex[0]].model;
        removeCoordinateSingleSphere(_markers[_selectedMarkerIndex[0]].model);
        delete _markers[_selectedMarkerIndex[0]].model;
      }
    }
    // setSelectedMarkerIndex(_selectedMarkerIndex);
    setMarkers(_markers);
    setCoordinates(_coordinates);
    // handleOnSelectAll(true);
  };

  const [markers, setMarkers] = useState([]);
  const [unselectedMarkerIndex, setUnselectedMarkerIndex] = useState([]);

  const [lastMeasure, setLastMeasure] = useState(null);

  const handleStartModelSelectionWizardAction = (name) => {
    if (coordinates.length > 0) {
      for (let coordinate of coordinates) {
        if (coordinate.name === name) {
          startCoordinateSelection(coordinates.indexOf(coordinate));
        }
      }
    }
  };

  function startCoordinateSelection(index) {
    var measure = viewer.measuringTool.startAlignToControlInsertion();
    setLastMeasure(measure);
    measure.addEventListener("marker_dropped", (markerDroppedEvent) => {
      const position = markerDroppedEvent.target.points[0].position;
      const coordinate = {
        x: Number(position.x.toFixed(3)),
        y: Number(position.y.toFixed(3)),
        z: Number(position.z.toFixed(3)),
      };

      var _coordinates = coordinatesRef.current;
      var _markers = [...markersRef.current];

      if (coordinate.x != 0 && coordinate.y != 0 && coordinate.z != 0) {
        const marker = addMarker(coordinate, 0x34e1eb);
        if (index !== undefined && index >= 0) {
          _coordinates = [...coordinates];
          _markers = [...markers];
          if (_markers[index].model) {
            removeCoordinateSingleSphere(_markers[index].model);
            delete _markers[index].model;
          }
          if (_coordinates[index].model) {
            delete _coordinates[index].model;
          }

          _coordinates[index].model = coordinate;
          _markers[index].model = marker;
        } else {
          var name = 1000 + _coordinates.length + 1;

          const _coordinate = {
            model: coordinate,
            name: name.toString(),
            isAddModeEnabled: addModelCoordinate,
            id: createUUID(),
          };
          _coordinates.unshift(_coordinate);
          _markers.unshift({ model: marker, coordinateId: _coordinate.id });
          manageSphareDragging(marker, _coordinate.id);
        }

        scene.add(marker);
        viewer.scene.removeMeasurement(markerDroppedEvent.target);
        // handleOnSelectAll(true);
      }

      setMarkers(_markers);
      setCoordinates(_coordinates);

      // handleOnSelectAll(true);
      if (addModelCoordinate && isMarkerAdd) {
        setPrevious([]);
        var _historyCount = historyCountRef.current;
        _historyCount = _historyCount + 1;
        setHistoryCount(_historyCount);
        setIsMarkerAdd(false);
      }
    });
  }

  const [scene, setScene] = useState(null);
  const [previous, setPrevious] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [isMarkerAdd, setIsMarkerAdd] = useState(false);
  const [addModelCoordinate, setAddModelCoordinate] = useState(false);

  // const startCoordinateSelection = (index) => {
  //   if (coordinateToolRef.current) {
  //     coordinateToolRef.current.startCoordinateSelection(index);
  //   }
  // };

  const handleOnAlignToControlClick = () => {
    if (coordinateToolRef.current) {
      coordinateToolRef.current.handleOnAlignToControlClick();
    }
  };

  function retrieveSiteCoordinatesInfo(_scene, siteCoordinateId) {
    if (siteCoordinateId == undefined || siteCoordinateId == null) {
      addDataToCoordinates([], _scene);
      return;
    }
    Promise.resolve(getSiteCoordinatesInfo(siteCoordinateId))
      .then((response) => {
        if (response.status === 200) {
          addDataToCoordinates(response.data, _scene);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  }

  const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const [labels, setLabels] = useState([]);

  function cleanCoordinates() {
    const _markers = [...markers];
    if (viewer) {
      labels.forEach((label) => {
        viewer.scene.scene.remove(label);
      });
    }
    removeCoordinateSphere(_markers);
    setMarkers([]);
    setCoordinates([]);
  }

  const getSimilarityTransformation = useCallback(
    (fromPoints, toPoints, allowReflection = false) => {
      try {
        const dimensions = fromPoints.rows;
        const numPoints = fromPoints.columns;

        const covarianceMatrix = getSimilarityTransformationCovariance(
          fromPoints,
          toPoints
        );

        const { svd, mirrorIdentityForSolution } =
          getSimilarityTransformationSvdWithMirrorIdentities(
            covarianceMatrix,
            allowReflection
          );

        const rotation = svd.U.mmul(
          Matrix.diag(mirrorIdentityForSolution)
        ).mmul(svd.V.transpose());

        const fromVariance = fromPoints
          .variance("row", { unbiased: false })
          .reduce((sum, elem) => sum + elem);

        let trace = 0;
        for (let dimension = 0; dimension < dimensions; dimension++) {
          const mirrorEntry = mirrorIdentityForSolution[dimension];
          trace += svd.diagonal[dimension] * mirrorEntry;
        }
        const scale = trace / fromVariance;

        const fromMean = Matrix.columnVector(fromPoints.mean("row"));
        const toMean = Matrix.columnVector(toPoints.mean("row"));
        const translation = Matrix.sub(
          toMean,
          Matrix.mul(rotation.mmul(fromMean), scale)
        );

        // 4. Transform the points.
        const transformedPoints = Matrix.add(
          Matrix.mul(rotation.mmul(fromPoints), scale),
          translation.repeat({ columns: numPoints })
        );

        return transformedPoints;
      } catch (err) {
        return null;
      }
    },
    []
  );

  function getSimilarityTransformationError(transformedPoints, toPoints) {
    if (
      !transformedPoints ||
      !toPoints ||
      transformedPoints.rows !== toPoints.rows ||
      transformedPoints.columns !== toPoints.columns
    ) {
      throw new Error(
        "Matrices must have the same dimensions for subtraction."
      );
    }
    const difference = Matrix.sub(toPoints, transformedPoints);
    return difference.norm("frobenius");
  }

  const getSimilarityTransformationCovariance = (fromPoints, toPoints) => {
    const dimensions = fromPoints.rows;
    const numPoints = fromPoints.columns;
    const fromMean = Matrix.columnVector(fromPoints.mean("row"));
    const toMean = Matrix.columnVector(toPoints.mean("row"));

    const covariance = Matrix.zeros(dimensions, dimensions);

    for (let pointIndex = 0; pointIndex < numPoints; pointIndex++) {
      const fromPoint = fromPoints.getColumnVector(pointIndex);
      const toPoint = toPoints.getColumnVector(pointIndex);
      const outer = Matrix.sub(toPoint, toMean).mmul(
        Matrix.sub(fromPoint, fromMean).transpose()
      );

      covariance.addM(Matrix.div(outer, numPoints));
    }

    return covariance;
  };

  const getSimilarityTransformationSvdWithMirrorIdentities = (
    covarianceMatrix,
    allowReflection
  ) => {
    const dimensions = covarianceMatrix.rows;
    const svd = new SingularValueDecomposition(covarianceMatrix);

    let mirrorIdentityForErrorBound = Array(svd.diagonal.length).fill(1);
    let mirrorIdentityForSolution = Array(svd.diagonal.length).fill(1);
    if (!allowReflection) {
      if (determinant(covarianceMatrix) < 0) {
        const lastIndex = mirrorIdentityForErrorBound.length - 1;
        mirrorIdentityForErrorBound[lastIndex] = -1;
      }

      mirrorIdentityForSolution = mirrorIdentityForErrorBound;
      if (svd.rank === dimensions - 1) {
        mirrorIdentityForSolution = Array(svd.diagonal.length).fill(1);
        if (determinant(svd.U) * determinant(svd.V) < 0) {
          const lastIndex = mirrorIdentityForSolution.length - 1;
          mirrorIdentityForSolution[lastIndex] = -1;
        }
      }
    }

    return {
      svd,
      mirrorIdentityForErrorBound,
      mirrorIdentityForSolution,
    };
  };

  const getTransformedError = useCallback(() => {
    const fromPoints = [];
    const toPoints = [];

    coordinates.forEach((coordinate) => {
      if (coordinate.model && coordinate.control) {
        fromPoints.push([
          coordinate.model.x,
          coordinate.model.y,
          coordinate.model.z,
        ]);
        toPoints.push([
          coordinate.control.x,
          coordinate.control.y,
          coordinate.control.z,
        ]);
      }
    });

    if (fromPoints.length === 0 || toPoints.length === 0) {
      return "";
    }
    const fromMatrix = new Matrix(fromPoints).transpose();
    const toMatrix = new Matrix(toPoints).transpose();

    const transformedPoints = getSimilarityTransformation(fromMatrix, toMatrix);

    let currIdx = 0;
    if (transformedPoints) {
      const errors = {};
      for (let i = 0; i < coordinates.length; i++) {
        if (coordinates[i].model && coordinates[i].control) {
          const transformedPoint = new Matrix([
            transformedPoints.getColumn(currIdx),
          ]).transpose();

          const targetPoint = new Matrix([
            toMatrix.getColumn(currIdx),
          ]).transpose();

          const error = getSimilarityTransformationError(
            transformedPoint,
            targetPoint
          );
          errors[coordinates[i].name] = error.toFixed(3);
          currIdx++;
        } else {
          errors[coordinates[i].name] = -1;
        }
      }
      setTransformationErrors(errors);

      return errors;
    } else {
      return "Error";
    }
  }, [
    coordinates,
    getSimilarityTransformation,
    getSimilarityTransformationError,
  ]);

  useEffect(() => {
    if (coordinates) {
      getTransformedError();
    }
  }, [coordinates]);

  function addDataToCoordinates(data, _scene) {
    cleanCoordinates();
    const _coordinates = [];
    const _markers = [];
    // eslint-disable-next-line complexity
    data.forEach((element) => {
      const dataObj = {
        ...(element.name && { name: element.name }),
        ...(element.code && { code: element.code }),
        ...((element.xmodelCoordinate !== 0 ||
          element.ymodelCoordinate !== 0 ||
          element.zmodelCoordinate !== 0) && {
          model: {
            x: element.xmodelCoordinate,
            y: element.ymodelCoordinate,
            z: element.zmodelCoordinate,
          },
        }),
        ...((element.xcontrolCoordinate !== 0 ||
          element.ycontrolCoordinate !== 0 ||
          element.zcontrolCoordinate !== 0) && {
          control: {
            x: element.xcontrolCoordinate,
            y: element.ycontrolCoordinate,
            z: element.zcontrolCoordinate,
          },
        }),
      };
      if (dataObj.model && dataObj.control) {
        const marker = addMarker(dataObj.model, 0x34e1eb);
        const coordinateMarker = addMarker(dataObj.control);

        _coordinates.unshift(dataObj);
        _markers.unshift({ model: marker, control: coordinateMarker });
        _scene.add(marker);
        _scene.add(coordinateMarker);
        viewer.scene.removeAllMeasurements();
      } else if (dataObj.model) {
        const marker = addMarker(dataObj.model, 0x34e1eb);
        dataObj.id = createUUID();
        _coordinates.unshift(dataObj);
        manageSphareDragging(marker, dataObj.id);

        _markers.unshift({ model: marker, coordinateId: dataObj.id });
        _scene.add(marker);
        viewer.scene.removeAllMeasurements();
      } else if (dataObj.control) {
        const sphere = addMarker(dataObj.control);
        dataObj.id = createUUID();
        _coordinates.unshift(dataObj);

        _markers.unshift({ control: sphere, coordinateId: dataObj.id });
        _scene.add(sphere);
      }
    });
    setOldCoordinates(JSON.parse(JSON.stringify(_coordinates)));
    setCoordinates(_coordinates);
    setMarkers(_markers);
    handleOnSelectAll(true);
  }

  const handleOnSelectAll = (isChecked) => {
    if (coordinateToolRef.current) {
      coordinateToolRef.current.handleOnSelectAll(isChecked);
    }
  };

  const onSphereDrop = (e, sphere, coordinateId) => {
    let I = Potree.Utils.getMousePointCloudIntersection(
      e.drag.end,
      e.viewer.scene.getActiveCamera(),
      e.viewer,
      e.viewer.scene.pointclouds,
      { pickClipped: true }
    );
    if (I) {
      if (I.location) {
        if (isModelLabelExist(coordinateId)) {
          const index = getModelLableIndex(coordinateId);
          if (index != -1) {
            var label = labelsRef.current[index];
            label.visible = true;
          }
        }

        sphere.position.x = I.location.x;
        sphere.position.y = I.location.y;
        sphere.position.z = I.location.z;
        positionLabel.visible = false;
        updateModelCoordinate(sphere.position, coordinateId);
      }
    }
  };

  const updateModelCoordinate = (position, coordinateId) => {
    var _coordinates = [...coordinatesRef.current];
    var index = _coordinates.findIndex((x) => x.id == coordinateId);
    if (index != -1) {
      delete _coordinates[index].model;

      _coordinates[index].model = {
        x: Number(position.x.toFixed(3)),
        y: Number(position.y.toFixed(3)),
        z: Number(position.z.toFixed(3)),
      };
      setCoordinates(_coordinates);
    }
  };

  const isModelLabelExist = (coordinateId) => {
    var marker = markersRef.current.filter(
      (x) => x.coordinateId == coordinateId
    );
    if (marker.length > 0) {
      if (marker[0].control && marker[0].model) {
        return false;
      } else if (marker[0].control) {
        return false;
      } else if (marker[0].model) {
        return true;
      } else {
        return false;
      }
    }
  };

  const coordinatesRef = useRef();

  const getModelLableIndex = (coordinateId) => {
    let validLables = coordinatesRef.current.filter(
      (coordinate) => coordinate.name
    );
    return validLables.findIndex((x) => x.id == coordinateId);
  };

  const markersRef = useRef();
  const manageSphareDragging = (sphere, coordinateId) => {
    sphere.addEventListener("drag", (e) =>
      onSphereDrag(e, sphere, coordinateId)
    );
    sphere.addEventListener("drop", (e) =>
      onSphereDrop(e, sphere, coordinateId)
    );
  };

  const [positionLabel, setPositionLabel] = useState(null);
  const labelsRef = useRef();

  // eslint-disable-next-line no-unused-vars
  const onSphereDrag = (e, sphere, coordinateId) => {
    console.log("drag");
    let I = Potree.Utils.getMousePointCloudIntersection(
      e.drag.end,
      e.viewer.scene.getActiveCamera(),
      e.viewer,
      e.viewer.scene.pointclouds,
      { pickClipped: true }
    );

    if (isModelLabelExist(coordinateId)) {
      const index = getModelLableIndex(coordinateId);
      if (index != -1) {
        var label = labelsRef.current[index];
        label.visible = false;
      }
    }

    if (I) {
      if (I.location) {
        sphere.position.x = I.location.x;
        sphere.position.y = I.location.y;
        sphere.position.z = I.location.z;
        var name =
          sphere.position.x.toFixed(2) +
          "/" +
          sphere.position.y.toFixed(2) +
          "/" +
          sphere.position.z.toFixed(2);
        positionLabel.visible = true;
        updateLabel(positionLabel, sphere.position, name);
      }
    }
  };

  const updateLabel = (label, position, text) => {
    const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
    let clientWidth = renderAreaSize.width;
    let clientHeight = renderAreaSize.height;
    let camera = viewer.scene.getActiveCamera();
    let distance = camera.position.distanceTo(position);
    let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);

    label.setText(text);

    let screenPos = position.clone().project(camera);
    screenPos.x = Math.round(((screenPos.x + 1) * clientWidth) / 2);
    screenPos.y = Math.round(((-screenPos.y + 1) * clientHeight) / 2);
    screenPos.z = 0;
    screenPos.y -= 30;
    let labelPos = new THREE.Vector3(
      (screenPos.x / clientWidth) * 2 - 1,
      -(screenPos.y / clientHeight) * 2 + 1,
      0.5
    );
    labelPos.unproject(camera);
    if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
      let direction = labelPos.sub(camera.position).normalize();
      labelPos = new THREE.Vector3().addVectors(
        camera.position,
        direction.multiplyScalar(distance)
      );
    }
    label.position.copy(labelPos);
    let labelscale = 70 / pr;
    label.scale.set(labelscale, labelscale, labelscale);
  };

  // const [positionLabel, setPositionLabel] = useState(null);

  const handleOnShowSelectedMarker = () => {
    const _markers = [...markers];
    _markers.forEach((marker) => {
      var markerIndex = markers.indexOf(marker);
      if (markerIndex != -1) {
        if (showSelectedMarker) {
          if (selectedMarkerIndex.includes(markerIndex)) {
            if (_markers[markerIndex].control) {
              _markers[markerIndex].control.visible = true;
            }

            if (_markers[markerIndex].model) {
              _markers[markerIndex].model.visible = true;
            }
          } else {
            if (_markers[markerIndex].control) {
              _markers[markerIndex].control.visible = false;
            }
            if (_markers[markerIndex].model) {
              _markers[markerIndex].model.visible = false;
            }
          }
        } else {
          if (_markers[markerIndex].control) {
            _markers[markerIndex].control.visible = true;
          }

          if (_markers[markerIndex].model) {
            _markers[markerIndex].model.visible = true;
          }
        }
      }
    });
    setMarkers(_markers);
  };

  const [showSelectedMarker, setShowSelectedMarker] = useState(false);

  function addMarker(coordinate, color) {
    const geometry = new THREE.SphereGeometry(0.4);
    const material = new THREE.MeshBasicMaterial({
      color: color ? color : 0xe55846,
    });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.x = coordinate.x;
    sphere.position.y = coordinate.y;
    sphere.position.z = coordinate.z;
    const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
    let clientWidth = renderAreaSize.width;
    let clientHeight = renderAreaSize.height;
    let camera = viewer.scene.getActiveCamera();
    let distance = camera.position.distanceTo(
      sphere.getWorldPosition(new THREE.Vector3())
    );
    let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);
    let scale = 13 / pr;
    sphere.scale.set(scale, scale, scale);
    return sphere;
  }

  function isVisible(index) {
    if (markers.length > 0) {
      if (markers[index]?.control) {
        return markers[index].control.visible;
      } else if (markers[index]?.model) {
        return markers[index].model.visible;
      } else {
        return false;
      }
    }
  }

  const [wizardActive, setWizardActive] = useState(false);

  const handleMissingDataAlerts = () => {
    if (!coordinates) {
      CustomAlert.show({
        type: "error",
        message: "Please select a saved file",
        buttons: [],
      });
      return;
    }
    if (selectedMarkerIndex?.length < 3) {
      CustomAlert.show({
        type: "error",
        message: "Please select at least 3 points",
        buttons: [],
      });
      return;
    }
  };

  // useEffect(() => {
  //   if (markers.length > 0) {
  //     let allIndices = [...Array(coordinates.length).keys()];
  //
  //     let unselectedMarkerIndices = allIndices.filter(
  //       (index) => !selectedMarkerIndex.includes(index)
  //     );
  //
  //     const unSelectedMarkers = unselectedMarkerIndices.map(
  //       (index) => markers[index]
  //     );
  //
  //     removeCoordinateSphere(unSelectedMarkers);
  //   }
  // }, [markers]);

  const toggleDrawers = (desiredSate) => {
    setRightDrawerOpen(desiredSate);
    setLeftDrawerOpen(desiredSate);
  };

  const handleControlPointWizardClick = () => {
    handleMissingDataAlerts();

    setWizardActive(!wizardActive);
    toggleDrawers(false);

    const selectedMarkers = selectedMarkerIndex.map(
      (index) => coordinates[index]
    );

    let allIndices = [...Array(coordinates.length).keys()];

    let unselectedMarkerIndices = allIndices.filter(
      (index) => !selectedMarkerIndex.includes(index)
    );
    setUnselectedMarkerIndex(unselectedMarkerIndices);
    setSelectedCoordinates(selectedMarkers);
    zoomToPointCloud(scanName);
  };

  const historyCountRef = useRef();

  useEffect(() => {
    if (viewer) {
      let _selectedMarkerIndex = [...selectedMarkerIndex];

      let allIndices = [...Array(coordinates.length).keys()];
      let unselectedMarkerIndices = allIndices.filter(
        (index) => !_selectedMarkerIndex.includes(index)
      );
      for (let index of unselectedMarkerIndices) {
        toggleMarker(index);
      }
    }
  }, [selectedMarkerIndex]);

  function toggleMarker(index) {
    const _markers = [...markers];

    if (_markers[index].control) {
      _markers[index].control.visible = !_markers[index].control.visible;
    }

    if (_markers[index].model) {
      _markers[index].model.visible = !_markers[index].model.visible;
    }

    if (_markers[index].line) {
      _markers[index].line.visible = !_markers[index].line.visible;
    }

    setMarkers(_markers);
  }

  return (
    <div>
      <OuterPanel>
        <div id="profile_window_new" style={{ marginTop: "114px" }}></div>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="right"
          variant="persistent"
          open={dataPanel !== null}
          onClose={() => setDataPanel(null)}
        >
          {dataPanel === "issueFinder" && (
            <IssueFinder
              scanId={"" + loadedScanIds[0]}
              scans={plyScans}
              loadedScanIds={loadedScanIds}
              onClose={() => setDataPanel("data")}
              viewer={viewer}
              ref={issueFinderRef}
            />
          )}
          {(dataPanel === "alignToControl" || dataPanel === "pointImport") && (
            <AlignToControl
              leftDrawerOpen={leftDrawerOpen}
              toggleLeftDrawer={(setClosed) => setLeftDrawerOpen(setClosed)}
              toggleDrawer={() => setRightDrawerOpen(!rightDrawerOpen)}
              rightDrawerOpen={rightDrawerOpen}
              viewer={viewer}
              coordinates={coordinates}
              selectedMarkerIndex={selectedMarkerIndex}
              isOrthographicCamera={isOrthographicCamera}
              enableAlignToControl={enableAlignToControl}
              onChangeCameraProjection={onChangeCameraProjection}
              handleControlPointWizardClick={handleControlPointWizardClick}
              selectedCoordinates={selectedCoordinates}
              setSelectedCoordinates={setSelectedCoordinates}
              wizardActive={wizardActive}
            >
              <AlignToControlViewer
                unloadPointClouds={unloadPointClouds}
                reloadSiteData={reloadSiteData}
                ref={coordinateToolRef}
                siteId={siteId}
                lastMeasure={lastMeasure}
                setLastMeasure={setLastMeasure}
                transformationErrors={transformationErrors}
                setTransformationErrors={setTransformationErrors}
                viewer={viewer}
                labelsRef={labelsRef}
                markersRef={markersRef}
                openEditCoordinate={openEditCoordinate}
                setOpenEditCoordinate={setOpenEditCoordinate}
                retrieveSiteCoordinatesInfo={retrieveSiteCoordinatesInfo}
                cleanCoordinates={cleanCoordinates}
                setMarkers={setMarkers}
                markers={markers}
                showSelectedMarker={showSelectedMarker}
                setShowSelectedMarker={setShowSelectedMarker}
                selectedCoordinates={selectedCoordinates}
                handleOnShowSelectedMarker={handleOnShowSelectedMarker}
                oldCoordinates={oldCoordinates}
                setOldCoordinates={setOldCoordinates}
                scene={scene}
                setScene={setScene}
                site={selectedSite}
                siteUTMProjection={siteUTMProjection}
                historyCountRef={historyCountRef}
                onClose={() => setDataPanel("data")}
                addCoordinateSphere={addCoordinateSphere}
                isMarkerAdd={isMarkerAdd}
                labels={labels}
                setLabels={setLabels}
                setIsMarkerAdd={setIsMarkerAdd}
                removeCoordinateSphere={removeCoordinateSphere}
                previous={previous}
                setPrevious={setPrevious}
                removeCoordinateSingleSphere={removeCoordinateSingleSphere}
                historyCount={historyCount}
                addDataToCoordinates={addDataToCoordinates}
                setHistoryCount={setHistoryCount}
                setSelectedMarkerIndex={setSelectedMarkerIndex}
                addModelCoordinate={addModelCoordinate}
                setAddModelCoordinate={setAddModelCoordinate}
                updateSpheres={updateSpheres}
                isVisible={isVisible}
                dataPanel={dataPanel}
                setCoordinates={setCoordinates}
                loadedScanIds={loadedScanIds}
                setEnableAlignToControl={setEnableAlignToControl}
                positionLabel={positionLabel}
                setPositionLabel={setPositionLabel}
                coordinatesRef={coordinatesRef}
              />
              <AlignToControlWizard
                handleStartModelSelectionWizardAction={
                  handleStartModelSelectionWizardAction
                }
                handleClearModelWizardAction={handleClearModelWizardAction}
                clearModelCoordinate={clearModelCoordinate}
                zoomToPoint={zoomToPoint}
                startCoordinateSelection={startCoordinateSelection}
                updateSpheres={updateSpheres}
                handleOnAlignToControlClick={handleOnAlignToControlClick}
                zoomOutPointCloud={zoomOutPointCloud}
                handleOnZoomMultiplePoint={zoomToMultiplePoints}
                wizardActive={wizardActive}
                viewer={viewer}
                zoomToPointCloud={(scanName) => zoomToPointCloud(scanName)}
                setWizardActive={setWizardActive}
                unselectedMarkerIndex={unselectedMarkerIndex}
                markers={markers}
                setMarkers={setMarkers}
                coordinates={coordinates}
                zoomToPoints={zoomToPoints}
                handleOnClearModelCoordinateWizard={
                  handleOnClearModelCoordinateWizard
                }
              />
            </AlignToControl>
          )}

          {dataPanel === "issueCreator" && (
            <IssueCreator
              viewer={viewer}
              siteUTMProjection={siteUTMProjection}
              scans={plyScans}
              onClose={() => setDataPanel("data")}
              dataPanel={dataPanel}
              ref={issueCreaterRef}
            />
          )}
        </Drawer>

        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          open={leftDrawerOpen}
          onClose={() => setLeftDrawerOpen(!leftDrawerOpen)}
          variant="persistent"
        >
          <Toolbar
            scans={plyScans}
            viewer={viewer}
            retrieveSiteCoordinatesInfo={retrieveSiteCoordinatesInfo}
            currentViewer={props.currentViewer}
            loadedScanIds={loadedScanIds}
            toggleDrawer={() => setLeftDrawerOpen(!leftDrawerOpen)}
            onSelectNavigation={onSelectNavigation}
            onSelectMeasurement={onSelectMeasurement}
            onSelectClipping={onSelectClipping}
            onClip={onClip}
            onTogglePointCloud={onTogglePointCloud}
            imageMarkersVisible={imageMarkersVisible}
            onToggleImageMarkers={() =>
              setImageMarkersVisible(!imageMarkersVisible)
            }
            unit={unit}
            onToggleFeetLength={onToggleFeetLength}
            onChangeClassificationColor={onChangeClassificationColor}
            onToggleClassificationVisibility={onToggleClassificationVisibility}
            onActiveAttribute={onActiveAttribute}
            onReset={onReset}
            measuring={measuring}
            getSnap={getSnap}
            clippingStart={clippingStart}
            feetLengthSwitch={feetLengthSwitch}
            classifications={classifications}
            activeAttribute={activeAttribute}
            downloadLazFile={downloadLazFile}
            showClassification={getClassificationAvailable()}
            coordinateSystem={coordinateSystem}
            showToolbarItems={showToolbarItems}
            onClickDataPanel={onClickDataPanel}
            crossSection={crossSection}
            onSelectCrossSection={onSelectCrossSection}
            onChangeCrossSectionLineWidth={onChangeCrossSectionLineWidth}
            crossSectionLineWidth={crossSectionLineWidth}
            showCrossSectionProfileButton={showCrossSectionProfileButton}
            onClickCrossSectionShowProfile={onClickCrossSectionShowProfile}
            handleOnScanCreationClick={handleOnScanCreationClick}
            selectedSite={selectedSite}
            zoomToPointCloud={zoomToPointCloud}
            zoomOutPointCloud={zoomOutPointCloud}
            addToLoadedScanIds={addToLoadedScanIds}
            removeFromLoadedScanIds={removeFromLoadedScanIds}
            onToggleCurrentViewer={onToggleCurrentViewer}
            updateClassifications={updateClassifications}
            dataPanel={dataPanel}
            role={role}
            enableEyeDomeLighting={enableEyeDomeLighting}
            edlRadius={edlRadius}
            edlStrength={edlStrength}
            edlOpacity={edlOpacity}
            useHighQuality={useHighQuality}
            onChangeEdlRadius={onChangeEdlRadius}
            onChangeEdlStrength={onChangeEdlStrength}
            onChangeEdlOpacity={onChangeEdlOpacity}
            onToggleEyeDomeLighting={onToggleEyeDomeLighting}
            onToggleUseHighQuality={onToggleUseHighQuality}
            onResetEDLSettings={onResetEDLSettings}
            checkIsInEditMode={() => {
              if (coordinateToolRef.current != undefined) {
                return coordinateToolRef.current.checkIsInEditMode();
              } else {
                return false;
              }
            }}
            isOrthographicCamera={isOrthographicCamera}
            onChangeCameraProjection={onChangeCameraProjection}
            isFixed={isFixed}
            onChangeFixed={onChangeFixed}
            pointSize={pointSize}
            onChangePointSize={onChangePointSize}
            brightness={brightness}
            onChangeBrightness={onChangeBrightness}
            selectAllScans={selectAllScans}
            gamma={gamma}
            onChangeGamma={onChangeGamma}
            contrast={contrast}
            onChangeContrast={onChangeContrast}
          />
        </Drawer>

        <MainPanel open={leftDrawerOpen}>
          <Header>
            <>
              {!leftDrawerOpen && (
                <Button onClick={() => setLeftDrawerOpen(!leftDrawerOpen)}>
                  <Menu sx={{ color: "#ffffff" }} />
                  <span style={{ color: "#ffffff" }}>Tools</span>
                </Button>
              )}
            </>
            <></>
          </Header>
          <HeaderAlignToControl>
            {!rightDrawerOpen && (
              <Button
                style={{ right: 0 }}
                onClick={() => setRightDrawerOpen(!rightDrawerOpen)}
              >
                <Menu sx={{ color: "#ffffff" }} />
                <span style={{ color: "#ffffff" }}>
                  {dataPanel === "alignToControl"
                    ? "Align To Control"
                    : "Point Selector"}
                </span>
                <span style={{ color: "#ffffff", fontStyle: "italic" }}>
                  {" "}
                  (Beta)
                </span>
              </Button>
            )}
          </HeaderAlignToControl>

          {scans.length === scans.filter((x) => x.potreeStatus !== 2).length ? (
            <div
              className="flex flex-row h-full w-full items-center justify-center"
              style={{ height: "100vh" }}
            >
              <Typography variant="h1" color="#6CCCD7" className="text-28">
                <Box sx={{ fontWeight: "bold", m: 1 }}>
                  Processing in Progress
                </Box>
              </Typography>
            </div>
          ) : null}

          <PotreePanel
            ref={potreePanelRef}
            onWheel={updateSpheres}
            onDoubleClick={updateSpheres}
            onMouseUp={onModelMouseUp}
            onMouseDown={onModelMouseDown}
            onMouseMove={onMouseMove}
          />

          <div id="potree_sidebar_container" style={{ display: "none" }}></div>
          {showConfirmation && (
            <ScanSnap
              showConfirmation={showConfirmation}
              src={image}
              handleClose={() => handleClose()}
              scanName={selectedSite.name}
              siteId={siteId}
            />
          )}
          <UnexpectedErrorDailog
            openUnexpectedErrorDailog={openUnexpectedErrorDailog}
            handleOnClick={handleOnClickDailog}
            handleOnClickContactSupport={handleOnClickContactSupport}
          />
        </MainPanel>
      </OuterPanel>
    </div>
  );
});

PotreeViewer.propTypes = {
  onError: PropTypes.func,
  scans: PropTypes.array,
};

export default PotreeViewer;
