import {
  useEffect,
  useMemo,
  useState,
  useRef,
  forwardRef,
  createRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useFlags } from 'flagsmith/react';

import { insertImages } from "../../admin/store/scanPanoramaSlice";
import { loadImagesFromPano, loadImagesFromGeoJson } from "./ImageLoaderUtils";
import { POI_GEO_JSON, isFlagEnabled } from 'app/main/apps/flags';

import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "!mapbox-gl";
import { render } from "react-dom";
import { Typography, Button, Drawer, Snackbar, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import MapBoxToolbar from "./MapBoxToolbar";
import MapBoxPointCloud from "./MapBoxPointCloud";
import MapBoxDistanceMeasure from "./measurements/v2/MapBoxDistanceMeasure";
import { downloadScanLazFile } from "../store/downloadSlice";
import MapBoxAreaMeasure from "./measurements/v2/MapBoxAreaMeasure";
import MapBoxAngleMeasure from "./measurements/v2/MapBoxAngleMeasure";
import MapBoxPointMeasure from "./measurements/MapBoxPointMeasure";
import ScanSnap from "../potree/ScanSnap";
import * as htmlToImage from "html-to-image";
import MapBoxPointLabelMarker from "./markers/MapBoxPointLabelMarker";
import { loadJsonFileData, getOrthoData } from "../store/scansSlice";
import { Get2DMeasurements } from "../CoordinateConverter";
import { AddMeasurementData } from "./measurements/AddMapBoxMeasurements";
import MapboxOrtho from "./ortho/MapBoxOrtho";
import APP_CONFIG from "app/AppConfig";
import MapboxVector from "./vectortiles/MapBoxVector";
import MapBoxConstants from "./constants/MapBoxConstants";
import ToolBarStyles from "../../admin/styles/ToolBarStyles";
import * as turf from "@turf/turf";
import UnexpectedErrorDailog from "./UnexpectedErrorDailog";
import { useNavigate } from "react-router-dom";
import { findAccountId } from "app/main/apps/common/OrgUtils";
import { OuterPanel, MapBoxPanel, MainPanel, Header } from "./MapBoxViewer.style";

mapboxgl.accessToken = MapBoxConstants.accessToken;

const MapBoxViewer = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    selectedSite,
    onToggleCurrentViewer,
    measurementData,
    updateMeasurementData,
  } = props;

  useImperativeHandle(ref, () => ({
    getMeasurementsData() {
      return Get2DMeasurements(
        pointMeasures,
        distanceMeasures,
        areaMeasures,
        angleMeasures,
        scanPointClouds
      );
    },
  }));

  const { role } = useSelector(({ auth }) => auth.user);
  const vertical = "bottom";
  const horizontal = "right";

  let mapboxPanelRef = useRef();
  const classes = ToolBarStyles();

  const [loadedScanIds, setLoadedScanIds] = useState([]);
  const [scanPointClouds, setScanPointClouds] = useState([]);

  const [mapperEnabled, setMapperEnabled] = useState(true);
  const [feetLengthSwitch, setFeetLengthSwitch] = useState(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
  const [mapBoxViewer, setMapBoxViewer] = useState();
  const [measuring, setMeasuring] = useState(false);
  const [showToolbarItems, setShowToolbarItems] = useState(false);

  // for flags
  const { accounts } = useSelector(({ admin }) => admin.accountApp);
  const selectedOrgId = useMemo(() => (selectedSite ? findAccountId(accounts, selectedSite?.id) : 0), [accounts, selectedSite]);
  const flags = useFlags([POI_GEO_JSON]);
  const useServerGeneratedGeoJson = useMemo(() => isFlagEnabled(POI_GEO_JSON, flags[POI_GEO_JSON], selectedOrgId), [selectedOrgId]);

  //Scan Screenshot Start
  const [snapImage, setSnapImage] = useState(null);
  const [showSnapDialog, setShowSnapDialog] = useState(false);
  const [snapType, setSnapType] = useState("");

  const [openUnexpectedErrorDailog, setOpenUnexpectedErrorDailog] =
    useState(false);
  const navigate = useNavigate();

  const loadImagesWithParams = async (initialMarkers, startIndex, scanIdList) => {
    try {
      if (useServerGeneratedGeoJson) {
        return await loadImagesFromGeoJson(
          initialMarkers,
          startIndex,
          scanIdList,
          mapBoxViewer,
          mapperEnabledRef.current,
          imageMarkersVisible,
          onClickPanoImage,
          setImageMarkers
        );
      }
    } catch (error) {
      console.error("Error in loadImagesFromGeoJson:", error);
      // Fallback to pre-existing loadImagesFromPano
    }

  return loadImagesFromPano(
      initialMarkers,
      startIndex,
      scanIdList,
      0,
      10000,
      0,
      mapBoxViewer,
      onClickPanoImage,
      mapperEnabledRef.current,
      imageMarkersVisible,
      setImageMarkers
    );
  };

  useEffect(() => {
    if (loadedScanIds.length > 0) {
      dispatch(insertImages(loadedScanIds[0]));
    }
  }, [loadedScanIds]);

  useEffect(() => {
    if (loadedScanIds.length === 0) {
      // setImageMarkersVisible(false);
      removeImageMarkers();
      setShowOrtho(false);
      setshowContours(false);
      setShowToolbarItems(false);
      if (mapBoxViewer) {
        onReset("measurement");
      }
    } else {
      setShowToolbarItems(true);
    }
  }, [loadedScanIds]);

  // const onToggleGpsCoordinateFile = () => {
  //   try {
  //     setMapperEnabled((prev) => !prev);
  //     if (imageMarkersVisible) {
  //       if (loadedScanIds.length > 0) {
  //         removeImageMarkers();
  //         loadImages(null, 0, loadedScanIds, 0, 10000, 0);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (snapImage != null) {
      if (snapType === "download") {
        const blob = b64toBlob(snapImage);
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = selectedSite.name + ".jpeg";
        a.click();
        window.URL.revokeObjectURL(url);
        setSnapImage(null);
      } else {
        setShowSnapDialog(true);
      }
    }
  }, [snapImage]);

  const handleClose = () => {
    setShowSnapDialog(false);
    setSnapImage(null);
  };

  const getSnap = async (type) => {
    setSnapType(type);
    htmlToImage
      .toJpeg(mapboxPanelRef.current)
      .then(function (dataUrl) {
        setSnapImage(dataUrl);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  function b64toBlob(dataURI) {
    const base64Data = dataURI.split(",")[1];
    const byteString = Buffer.from(base64Data, "base64");
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
  }
  //Scan Screenshot End

  //Image Markers Start
  const [imageMarkersVisible, setImageMarkersVisible] = useState(true);
  const [imageMarkers, setImageMarkers] = useState(null);

  const mapperEnabledRef = useRef(true);

  const onToggleGpsCoordinateFile = () => {
    try {
      // Toggle the reference value manually
      mapperEnabledRef.current = !mapperEnabledRef.current;
      setMapperEnabled((prev) => !prev);

      if (imageMarkersVisible) {
        if (loadedScanIds.length > 0) {
          removeImageMarkers();
          loadImagesWithParams(null, 0, loadedScanIds);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (imageMarkersVisible) {
      if (loadedScanIds.length > 0) {
        removeImageMarkers();
        loadImagesWithParams(null, 0, loadedScanIds);
      }
    } else {
      removeImageMarkers();
    }
  }, [imageMarkersVisible]);


  // const loadIndividualScanImages = (scanId, offset, limit, imageMarkers, totalCount) => {

  //     loadAllGpsImageInfos(scanId, offset, limit, totalCount)
  //         .then((response) => {
  //             const imageInfos = response.data.imageInfo;
  //             const totalImageFiles = response.data.totalCount;
  //             offset = offset + limit;
  //             if (imageMarkersVisible) {
  //                 if (imageMarkers) {
  //                     imageMarkers.updateScanImageInfos(imageInfos);
  //                 }
  //                 else {
  //                     imageMarkers = new MapBoxPanoImages(mapBoxViewer, [imageInfos], onClickPanoImage);
  //                 }
  //             }else{
  //                 if (imageMarkers) {
  //                     imageMarkers.remove();
  //                     imageMarkers = null;
  //                 }
  //             }
  //             if (offset <= totalImageFiles) {
  //                 if (imageMarkersVisible) {
  //                     loadIndividualScanImages(scanId, offset, limit, imageMarkers, totalImageFiles);
  //                 } else {
  //                     if (imageMarkers) {
  //                         imageMarkers.remove();
  //                         imageMarkers = null;
  //                     }
  //                 }
  //             }
  //             else {
  //                 if (imageMarkersVisible && imageMarkers) {
  //                     setImageMarkers(imageMarkers);
  //                 }
  //                 else {
  //                     if (imageMarkers) {
  //                         imageMarkers.remove();
  //                         imageMarkers = null;
  //                     }
  //                 }
  //             }
  //         }).catch((error) => console.log(error));

  // }

  const onClickPanoImage = (imageInfo, index) => {
    addBroadcastChannel();
    window.open(
      "apps/gpspanos/" + imageInfo.scanId + "/" + index,
      "pano",
      "height=1600,width=1000,left=600,top=20"
    );
  };

  const removeImageMarkers = () => {
    if (imageMarkers) {
      imageMarkers.remove();
    }
    setImageMarkers(null);
  };

  const [broadcastChannel, setBroadcastChannel] = useState(null);
  const [currentViewPanoImage, setCurrentViewPanoImage] = useState(null);
  const addBroadcastChannel = () => {
    if (!broadcastChannel) {
      const channel = new BroadcastChannel(window.location.origin);
      addReceiveEventListener(channel);
      setBroadcastChannel(channel);
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      addReceiveEventListener(broadcastChannel);
    }
    return () => {
      removeReceiveEventListener(broadcastChannel);
      if (broadcastChannel) {
        broadcastChannel.close();
      }
    };
  }, [broadcastChannel]);

  useEffect(() => {
    if (currentViewPanoImage) {
      if (imageMarkers) {
        imageMarkers.updateCurrentSelectedFeature(currentViewPanoImage);
      }
    } else {
      if (imageMarkers) {
        imageMarkers.updateCurrentSelectedFeature(null);
      }
    }
  }, [currentViewPanoImage]);

  const addReceiveEventListener = (channel) => {
    if (channel) {
      channel.addEventListener("message", onReceiveBroadcastMessage);
    }
  };
  const removeReceiveEventListener = (channel) => {
    if (channel) {
      channel.removeEventListener("message", onReceiveBroadcastMessage);
      setBroadcastChannel(null);
    }
  };

  const onReceiveBroadcastMessage = (event) => {
    let receivedData = event.data;
    if (receivedData.isClosed) {
      setCurrentViewPanoImage(null);
    } else {
      setCurrentViewPanoImage(receivedData.imageInfo);
    }
  };

  //Image Markers End

  //Ortho Start
  const [showOrtho, setShowOrtho] = useState(false);
  const [mapTilesOrthos, setMapTilesOrthos] = useState([]);
  useEffect(() => {
    if (showOrtho) {
      if (loadedScanIds.length > 0) {
        let _mapTilesOrthos = [...mapTilesOrthos];
        loadScanOrthos(0, _mapTilesOrthos);
      }
    } else {
      removeMapTilesOrtho();
    }
  }, [showOrtho]);

  const loadScanOrthos = (index, _mapTilesOrthos) => {
    const scan = scans.find((x) => x.id == loadedScanIds[index]);
    const loadOrthoJsonApi = getOrthoData(scan.id);
    // const loadOrthoJsonApi = loadJsonFileData(scan.mapTilesOrthoJsonUrl)
    Promise.resolve(loadOrthoJsonApi).then((response) => {
      if (response.data) {
        let orthoInfo = getOrthoJsonData(response.data, scan);
        var mapboxOrtho = new MapboxOrtho(mapBoxViewer, orthoInfo, scan.id);
        _mapTilesOrthos.push(mapboxOrtho);
        if (index < loadedScanIds.length - 1) {
          loadScanOrthos(index + 1, _mapTilesOrthos);
        } else {
          var countour = vectorTilesContours.find((x) => x.showingVector);
          if (showContours && countour) {
            let ortho = _mapTilesOrthos.find(
              (x) => x.scanId == countour.scanId
            );
            if (ortho) {
              ortho.showOrtho();
            }
          } else {
            let mapCenter = mapBoxViewer.getCenter();
            var isValid = false;
            var currentPointCloud = null;
            scanPointClouds.forEach((pointCloud) => {
              if (!isValid) {
                isValid = checkPointValid(mapCenter.lng, mapCenter.lat, [
                  pointCloud,
                ]);
                if (isValid) {
                  currentPointCloud = pointCloud;
                }
              }
            });
            if (isValid && currentPointCloud) {
              let ortho = _mapTilesOrthos.find(
                (x) => x.scanId == currentPointCloud.scanId
              );
              if (ortho) {
                ortho.showOrtho();
              }
            } else {
              _mapTilesOrthos[_mapTilesOrthos.length - 1].showOrtho();
            }
          }
          setMapTilesOrthos(_mapTilesOrthos);
        }
      }
    });
  };

  const loadIndividualOrtho = (scanId) => {
    let _mapTilesOrthos = [...mapTilesOrthos];
    var scan = scans.find((x) => x.id == scanId);
    //const loadOrthoJsonApi = loadJsonFileData(scan.mapTilesOrthoJsonUrl)
    const loadOrthoJsonApi = getOrthoData(scan.id);
    Promise.resolve(loadOrthoJsonApi).then((response) => {
      if (response.data) {
        let orthoInfo = getOrthoJsonData(response.data, scan);
        var mapboxOrtho = new MapboxOrtho(mapBoxViewer, orthoInfo, scan.id);
        _mapTilesOrthos
          .filter((x) => x.showingOrtho)
          .forEach((x) => {
            x.hideOrtho();
          });
        mapboxOrtho.showOrtho();
        _mapTilesOrthos.push(mapboxOrtho);
        setMapTilesOrthos(_mapTilesOrthos);
      }
    });
  };

  const removeMapTilesOrtho = () => {
    mapTilesOrthos.forEach((mapTileOrtho) => {
      mapTileOrtho.remove();
    });
    setMapTilesOrthos([]);
    if (mapBoxViewer && !showContours) {
      mapBoxViewer.setMaxBounds(null);
    }
  };

  const getOrthoJsonData = (data) => {
    let maxZoom = parseInt(data.maxZoom);
    let minZoom = parseInt(data.minZoom);
    var maxBounds = [];
    // if (data.bounds.length == 4) {
    let southWest = [data.maxLong, data.maxLat];
    let northEast = [data.minLong, data.minLat];
    maxBounds = [southWest, northEast];
    // }
    let orthoInfoData = {
      maxZoom: maxZoom,
      minZoom: minZoom,
      maxBounds: maxBounds,
      orthoPath:
        APP_CONFIG.api + "v1/files/downloadFileChunk/" + data.orthoPath + "/",
      isGdal2Tiles: data.isGdal2Tiles,
    };
    return orthoInfoData;
  };
  //Ortho end

  //Vector Start
  const [showContours, setshowContours] = useState(false);
  const [vectorTilesContours, setVectorTilesContours] = useState([]);
  useEffect(() => {
    if (showContours) {
      if (loadedScanIds.length > 0) {
        let _vectorTilesCountours = [...vectorTilesContours];
        loadVectorTilesCounters(0, _vectorTilesCountours);
      }
    } else {
      removeVectorTilesContours();
    }
  }, [showContours]);

  const loadVectorTilesCounters = (index, _vectorTilesCountours) => {
    var scan = scans.find((x) => x.id == loadedScanIds[index]);
    const loadvectorJsonApi = loadJsonFileData(scan.vectorTilesContoursJsonUrl);
    Promise.resolve(loadvectorJsonApi).then((response) => {
      let vectorInfo = getContoursVectorJsonData(response.data, scan);
      var mapboxVector = new MapboxVector(mapBoxViewer, vectorInfo, scan.id);
      _vectorTilesCountours.push(mapboxVector);
      if (index < loadedScanIds.length - 1) {
        loadVectorTilesCounters(index + 1, _vectorTilesCountours);
      } else {
        var ortho = mapTilesOrthos.find((x) => x.showingOrtho);
        if (showOrtho && ortho) {
          let vectorCounter = _vectorTilesCountours.find(
            (x) => x.scanId == ortho.scanId
          );
          if (vectorCounter) {
            vectorCounter.showVector();
          }
        } else {
          let mapCenter = mapBoxViewer.getCenter();
          var isValid = false;
          var currentPointCloud = null;
          scanPointClouds.forEach((pointCloud) => {
            if (!isValid) {
              isValid = checkPointValid(mapCenter.lng, mapCenter.lat, [
                pointCloud,
              ]);
              if (isValid) {
                currentPointCloud = pointCloud;
              }
            }
          });
          if (isValid && currentPointCloud) {
            let vectorCounter = _vectorTilesCountours.find(
              (x) => x.scanId == currentPointCloud.scanId
            );
            if (vectorCounter) {
              vectorCounter.showVector();
            }
          } else {
            _vectorTilesCountours[
              _vectorTilesCountours.length - 1
            ].showVector();
          }
        }
        setVectorTilesContours(_vectorTilesCountours);
      }
    });
  };

  const loadIndividualVectorTilesCounters = (scanId) => {
    let _vectorTilesCountours = [...vectorTilesContours];
    var scan = scans.find((x) => x.id == scanId);
    const loadvectorJsonApi = loadJsonFileData(scan.vectorTilesContoursJsonUrl);
    Promise.resolve(loadvectorJsonApi).then((response) => {
      let vectorInfo = getContoursVectorJsonData(response.data, scan);
      var mapboxVector = new MapboxVector(mapBoxViewer, vectorInfo, scan.id);
      mapboxVector.showVector();
      _vectorTilesCountours.push(mapboxVector);
      setVectorTilesContours(_vectorTilesCountours);
    });
  };

  const removeVectorTilesContours = () => {
    vectorTilesContours.forEach((vectorTile) => {
      vectorTile.remove();
    });
    setVectorTilesContours([]);
    if (mapBoxViewer && !showOrtho) {
      mapBoxViewer.setMaxBounds(null);
    }
  };

  const getContoursVectorJsonData = (data, scan) => {
    let maxZoom = parseInt(data.maxzoom);
    let minZoom = parseInt(data.minzoom);
    var maxBounds = [];
    if (data.bounds.length == 4) {
      let southWest = [data.bounds[0], data.bounds[1]];
      let northEast = [data.bounds[2], data.bounds[3]];
      maxBounds = [southWest, northEast];
    }
    let vectorInfoData = {
      maxZoom: maxZoom,
      minZoom: minZoom,
      maxBounds: maxBounds,
      vectorPath:
        APP_CONFIG.api +
        "v1/files/downloadFileChunk/" +
        scan.vectorTilesContoursPath +
        "/",
    };
    return vectorInfoData;
  };
  //Vector end

  useEffect(() => {
    props.toggleLeftDrawer(leftDrawerOpen);
    if (mapBoxViewer) {
      mapBoxViewer.resize();
    }
    return () => {
      props.toggleLeftDrawer(false);
    };
  }, [leftDrawerOpen]);

  useEffect(() => {
    if (mapBoxViewer) {
      mapBoxViewer.resize();
      // mapBoxViewer.on('style.load',onStyleLoadEvent);
      if (measurementData) {
        let scanIds = measurementData.scans.map((x) => x.id);
        const measurementDataScans = scans.filter(
          (x) => scanIds.includes(x.id) && x.mapboxStatus == 2
        );
        if (
          measurementDataScans.length > 0 &&
          measurementDataScans.length == scanIds.length
        ) {
          loadScansPointClouds(measurementData.scans);
        }
      } else if (props.scanId) {
        const scan = scans.find(
          (scan) =>
            scan.id == props.scanId &&
            scan.modelFormat === "ply" &&
            scan.mapboxStatus === 2 &&
            (role.includes("admin") ||
              scan.latestInvoice ||
              scan.isFree ||
              scan.scanUsageCharge)
        );
        if (scan) {
          addPointCloud(scan, false);
        }
        props.resetScanId();
      } else {
        loadRecentModel();
      }
    }
  }, [mapBoxViewer]);

  //Start Switching data
  const loadScansPointClouds = (scansData) => {
    var scanPointCloudsData = [...scanPointClouds];
    loadScansPointCloud(0, scansData, scanPointCloudsData, []);
  };
  const loadScansPointCloud = (
    index,
    scansData,
    scanPointCloudsData,
    loadedScans
  ) => {
    const scan = scans.find(
      (scan) => scan.id === scansData[index].id && scan.mapboxStatus == 2
    );
    if (scan) {
      const modelUrl = getModelUrl(scan);
      const loadPointCloudApi = loadJsonFileData(modelUrl);
      Promise.resolve(loadPointCloudApi).then((response) => {
        var pointCloudData = getPointCloudData(response.data);
        if (checkIsValidPointCloudData(pointCloudData)) {
          pointCloudData.scanId = scan.id;
          var pointCloud = new MapBoxPointCloud(mapBoxViewer, pointCloudData);
          scanPointCloudsData.push(pointCloud);
          loadedScans.push(scan.id);
        }
        if (index < scansData.length - 1) {
          loadScansPointCloud(
            index + 1,
            scansData,
            scanPointCloudsData,
            loadedScans
          );
        } else {
          // setLoadedScanIds(scansData.map(x => x.id));
          setLoadedScanIds(loadedScans);
          setScanPointClouds(scanPointCloudsData);
          let scanIds = measurementData.scans.map((x) => x.id);
          const measurementDataScans = scans.filter(
            (x) => scanIds.includes(x.id) && x.mapboxStatus == 2
          );
          if (measurementDataScans.length == scansData.length) {
            loadScansMeasurements(scanPointCloudsData);
          }
          if (imageMarkersVisible) {
            loadImagesWithParams(null, 0, scansData.map((x) => x.id));
          }
        }
      });
    }
  };
  const loadAllScansPointCloud = (
    index,
    scansData,
    scanPointCloudsData,
    loadedScans
  ) => {
    const scan = scans.find(
      (scan) => scan.id === scansData[index].id && scan.mapboxStatus == 2
    );
    if (scan) {
      const modelUrl = getModelUrl(scan);
      const loadPointCloudApi = loadJsonFileData(modelUrl);
      Promise.resolve(loadPointCloudApi).then((response) => {
        var pointCloudData = getPointCloudData(response.data);
        if (checkIsValidPointCloudData(pointCloudData)) {
          pointCloudData.scanId = scan.id;
          var pointCloud = new MapBoxPointCloud(
            mapBoxViewer,
            pointCloudData,
            false
          );
          scanPointCloudsData.push(pointCloud);
          loadedScans.push(scan.id);
        }
        if (index < scansData.length - 1) {
          loadAllScansPointCloud(
            index + 1,
            scansData,
            scanPointCloudsData,
            loadedScans
          );
        } else {
          var _loadedScanIds = [...loadedScanIds];
          loadedScans.forEach((x) => {
            _loadedScanIds.push(x);
          });

          var _scanPointClouds = [...scanPointClouds];
          scanPointCloudsData.forEach((x) => {
            _scanPointClouds.push(x);
          });
          setLoadedScanIds(_loadedScanIds);
          setScanPointClouds(_scanPointClouds);
          loadImagesWithParams(imageMarkers, 0, loadedScanIds);
          setPointcloudsBoundingBox(_scanPointClouds);
        }
      });
    }
  };

  const loadScansMeasurements = (scanPointCloudsData) => {
    var measurements = AddMeasurementData(
      mapBoxViewer,
      mapboxPanelRef,
      checkPointValid,
      measurementData,
      scanPointCloudsData,
      feetLengthSwitch ? "ft" : "m"
    );
    var pointMeasuresData = [...pointMeasures];
    var distanceMeasuresData = [...distanceMeasures];
    var angleMeasuresData = [...angleMeasures];
    var areaMeasuresData = [...areaMeasures];
    measurements.pointMeasures.forEach((pointMeasure) => {
      pointMeasuresData.push(pointMeasure);
    });
    measurements.distanceMeasures.forEach((distanceMeasure) => {
      distanceMeasuresData.push(distanceMeasure);
    });
    measurements.angleMeasures.forEach((angleMeasure) => {
      angleMeasuresData.push(angleMeasure);
    });
    measurements.areaMeasures.forEach((areaMeasure) => {
      areaMeasuresData.push(areaMeasure);
    });
    setPointMeasures(pointMeasuresData);
    setDistanceMeasures(distanceMeasuresData);
    setAngleMeasures(angleMeasuresData);
    setAreaMeasures(areaMeasuresData);
    updateMeasurementData(null);
  };

  //End Switching data

  useEffect(() => {
    if (mapboxPanelRef.current != undefined) {
      const map = new mapboxgl.Map({
        container: mapboxPanelRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [-74.5, 40],
        zoom: 14,
        maxZoom: 26,
        attributionControl: false,
        preserveDrawingBuffer: true,
        transformRequest: (url) => {
          if (url.includes(APP_CONFIG.api)) {
            return {
              url: url,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                limartoken: localStorage.getItem("limartoken"),
              },
            };
          } else {
            return {
              url: url,
            };
          }
        },
      });

      map.addControl(new mapboxgl.NavigationControl());
      setMapBoxViewer(map);

      return () => {
        map.remove();
      };
    }
  }, []);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] || a[orderBy]) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const stableSort = (array, comparator) => {
    const dataExistArray = array.filter((x) =>
      checkPropertyExist(x, "processingCompletionDate")
    );
    const dataNotExistArray = array.filter(
      (x) => !checkPropertyExist(x, "processingCompletionDate")
    );
    const stabilizedThis = dataExistArray.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    var finalArray = stabilizedThis.map((el) => el[0]);
    dataNotExistArray.forEach((obj) => {
      finalArray.push(obj);
    });

    return finalArray;
  };

  function checkPropertyExist(obj, property) {
    if (obj[property]) {
      return true;
    } else {
      return false;
    }
  }

  function loadRecentModel() {
    var completedScans = scans.filter(
      (scan) =>
        scan.modelFormat === "ply" &&
        scan.mapboxStatus === 2 &&
        (role.includes("admin") ||
          scan.latestInvoice ||
          scan.isFree ||
          scan.scanUsageCharge)
    );
    if (completedScans.length > 0) {
      completedScans = stableSort(
        completedScans,
        getComparator("desc", "processingCompletionDate")
      );
      addPointCloud(completedScans[0], false);
    }
  }

  function onTogglePointCloud(model, checked) {
    if (checked) {
      addPointCloud(model, true);
    } else {
      removePointCloud(model);
    }
  }

  function addPointCloud(model, isFromCheckBox) {
    const modelUrl = getModelUrl(model);
    const loadPointCloudApi = loadJsonFileData(modelUrl);
    Promise.resolve(loadPointCloudApi)
      .then((response) => {
        var pointCloudData = getPointCloudData(response.data);
        if (checkIsValidPointCloudData(pointCloudData)) {
          pointCloudData.scanId = model.id;
          var pointCloud = new MapBoxPointCloud(mapBoxViewer, pointCloudData);
          var scanPointCloudsData = [...scanPointClouds];
          scanPointCloudsData.push(pointCloud);
          setScanPointClouds(scanPointCloudsData);
          addToLoadedScanIds(model.id);
        } else {
          if (isFromCheckBox) {
            setOpenUnexpectedErrorDailog(true);
          }
        }
      })
      .catch(() => {
        if (isFromCheckBox) {
          setOpenUnexpectedErrorDailog(true);
        }
      });
  }

  function removePointCloud(model) {
    var scanPointCloudsData = [...scanPointClouds];
    const index = scanPointCloudsData.findIndex((x) => x.scanId === model.id);
    scanPointCloudsData[index].remove();
    scanPointCloudsData.splice(index, 1);

    var distenceMeasuresData = distanceMeasures.filter((distance) => {
      if (distance.removePointCloudPoints(scanPointCloudsData)) {
        distance.remove();
        return false;
      } else {
        return true;
      }
    });
    setDistanceMeasures(distenceMeasuresData);

    var pointMeasuresData = pointMeasures.filter((point) => {
      if (point.removePointCloudPoints(scanPointCloudsData)) {
        point.remove();
        return false;
      } else {
        return true;
      }
    });
    setPointMeasures(pointMeasuresData);

    var areaMeasuresData = areaMeasures.filter((area) => {
      if (area.removePointCloudPoints(scanPointCloudsData)) {
        area.remove();
        return false;
      } else {
        return true;
      }
    });
    setAreaMeasures(areaMeasuresData);

    var angleMeasuresData = angleMeasures.filter((angle) => {
      if (angle.removePointCloudPoints(scanPointCloudsData)) {
        angle.remove();
        return false;
      } else {
        return true;
      }
    });
    setAngleMeasures(angleMeasuresData);

    setScanPointClouds(scanPointCloudsData);
    removeFromLoadedScanIds(model.id);
  }

  function addToLoadedScanIds(id) {
    const modifiedLoadedScanIds = [...loadedScanIds];
    modifiedLoadedScanIds.push(id);
    setLoadedScanIds(modifiedLoadedScanIds);
    if (showOrtho || showContours) {
      mapBoxViewer.setMaxBounds(null);
    }
    if (showOrtho) {
      let _mapTilesOrthos = [...mapTilesOrthos];
      _mapTilesOrthos
        .filter((x) => x.showingOrtho)
        .forEach((y) => {
          y.hideOrtho();
        });
      loadIndividualOrtho(id);
      setMapTilesOrthos(_mapTilesOrthos);
    }
    if (showContours) {
      let _vectorTilesCountours = [...vectorTilesContours];
      _vectorTilesCountours
        .filter((x) => x.showingVector)
        .forEach((y) => {
          y.hideVector();
        });
      loadIndividualVectorTilesCounters(id);
      setVectorTilesContours(_vectorTilesCountours);
    }
    if (imageMarkersVisible) {
      let scanIds = [id];
      loadImagesWithParams(imageMarkers, 0, scanIds);
      //  loadIndividualScanImages(id,0,10000, imageMarkers, 0);
    }
  }

  function removeFromLoadedScanIds(id) {
    const modifiedLoadedScanIds = [...loadedScanIds];
    const index = modifiedLoadedScanIds.findIndex(
      (currentId) => currentId === id
    );
    modifiedLoadedScanIds.splice(index, 1);
    setLoadedScanIds(modifiedLoadedScanIds);
    if (showOrtho) {
      let _maptilesorthos = [...mapTilesOrthos];
      _maptilesorthos
        .filter((x) => x.scanId == id && x.showingOrtho)
        .forEach((x) => {
          x.hideOrtho();
        });
      const index = _maptilesorthos.findIndex((y) => y.scanId == id);
      if (index != -1) {
        _maptilesorthos[index].remove();
        _maptilesorthos.splice(index, 1);
      }
      setMapTilesOrthos(_maptilesorthos);
    }
    if (showContours) {
      let _vectorTilesCountours = [...vectorTilesContours];
      _vectorTilesCountours
        .filter((x) => x.scanId == id && x.showingVector)
        .forEach((x) => {
          x.hideVector();
        });
      const index = _vectorTilesCountours.findIndex((x) => x.scanId == id);
      if (index != -1) {
        _vectorTilesCountours[index].remove();
        _vectorTilesCountours.splice(index, 1);
      }
      setVectorTilesContours(_vectorTilesCountours);
    }
    if (imageMarkersVisible) {
      imageMarkers?.removeScanImages(id);
    }
  }

  function getPointCloudData(data) {
    var pointCloud = {};
    if (data["top left"]) {
      pointCloud.topLeft = {
        lng: data["top left"][0],
        lat: data["top left"][1],
      };
    } else {
      pointCloud.topLeft = {
        lng: 0,
        lat: 0,
      };
    }
    if (data["top right"]) {
      pointCloud.topRight = {
        lng: data["top right"][0],
        lat: data["top right"][1],
      };
    } else {
      pointCloud.topRight = {
        lng: 0,
        lat: 0,
      };
    }
    if (data["bottom right"]) {
      pointCloud.bottomRight = {
        lng: data["bottom right"][0],
        lat: data["bottom right"][1],
      };
    } else {
      pointCloud.bottomRight = {
        lng: 0,
        lat: 0,
      };
    }
    if (data["bottom left"]) {
      pointCloud.bottomLeft = {
        lng: data["bottom left"][0],
        lat: data["bottom left"][1],
      };
    } else {
      pointCloud.bottomLeft = {
        lng: 0,
        lat: 0,
      };
    }
    if (data["epsg"]) {
      pointCloud.epsg = data["epsg"];
    } else {
      pointCloud.epsg = 0;
    }
    return pointCloud;
  }

  function checkIsValidPointCloudData(data) {
    if (!isValidLatLng(data.topLeft.lng)) {
      return false;
    }
    if (!isValidLatLng(data.topLeft.lat)) {
      return false;
    }
    if (!isValidLatLng(data.topRight.lng)) {
      return false;
    }
    if (!isValidLatLng(data.topRight.lat)) {
      return false;
    }
    if (!isValidLatLng(data.bottomRight.lng)) {
      return false;
    }
    if (!isValidLatLng(data.bottomRight.lat)) {
      return false;
    }
    if (!isValidLatLng(data.bottomLeft.lng)) {
      return false;
    }
    if (!isValidLatLng(data.bottomRight.lat)) {
      return false;
    }

    return true;
  }

  function isValidLatLng(value) {
    try {
      if (value != 0 && parseInt(value) != -1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  function getModelUrl(model) {
    let modelUrl = model.modelUrl2D;
    return modelUrl;
  }

  function zoomToPointCloud(scanId) {
    if (showOrtho || showContours) {
      if (showOrtho) {
        let _mapTilesOrthos = [...mapTilesOrthos];
        var obj = _mapTilesOrthos.find(
          (x) => x.scanId == scanId && x.showingOrtho
        );
        if (obj) {
          obj.setFitBounds();
          //obj.zoomIn();
        } else {
          _mapTilesOrthos
            .filter((x) => x.showingOrtho)
            .forEach((x) => {
              x.hideOrtho();
            });
          _mapTilesOrthos
            .filter((x) => x.scanId == scanId)
            .forEach((x) => {
              x.showOrtho();
            });
        }
        setMapTilesOrthos(_mapTilesOrthos);
      }
      if (showContours) {
        let _vectorTilesCountours = [...vectorTilesContours];
        let obj = _vectorTilesCountours.find(
          (x) => x.scanId == scanId && x.showingVector
        );
        if (obj) {
          obj.zoomIn();
        } else {
          _vectorTilesCountours
            .filter((x) => x.showingVector)
            .forEach((x) => {
              x.hideVector();
            });
          _vectorTilesCountours
            .filter((x) => x.scanId == scanId)
            .forEach((x) => {
              x.showVector();
            });
        }
        setVectorTilesContours(_vectorTilesCountours);
      }
    } else {
      if (scanPointClouds.length > 0) {
        const index = scanPointClouds.findIndex((x) => x.scanId === scanId);

        scanPointClouds[index].setBounds();
        //}
      }
    }
  }
  function zoomOutPointCloud(scanId) {
    if (showOrtho) {
      let _mapTilesOrthos = [...mapTilesOrthos];
      var obj = _mapTilesOrthos.find(
        (x) => x.scanId == scanId && x.showingOrtho
      );
      if (obj) {
        obj.zoomOut();
      } else {
        _mapTilesOrthos
          .filter((x) => x.showingOrtho)
          .forEach((x) => {
            x.hideOrtho();
          });
        _mapTilesOrthos
          .filter((x) => x.scanId == scanId)
          .forEach((x) => {
            x.showOrtho();
          });
      }
      setMapTilesOrthos(_mapTilesOrthos);
    }
    if (showContours && vectorTilesContours) {
      let _vectorTilesCountours = [...vectorTilesContours];
      let obj = _vectorTilesCountours.find(
        (x) => x.scanId == scanId && x.showingVector
      );
      if (obj) {
        obj.zoomOut();
      } else {
        _vectorTilesCountours
          .filter((x) => x.showingVector)
          .forEach((x) => {
            x.hideVector();
          });
        _vectorTilesCountours
          .filter((x) => x.scanId == scanId)
          .forEach((x) => {
            x.showVector();
          });
      }
      setVectorTilesContours(_vectorTilesCountours);
    }
    if (scanPointClouds.length > 0) {
      const index = scanPointClouds.findIndex((x) => x.scanId === scanId);
      var center = mapBoxViewer.getCenter();
      if (scanPointClouds[index].checkPointValid(center.lng, center.lat)) {
        scanPointClouds[index].zoomOut();
      } else {
        scanPointClouds[index].setBounds();
      }
    }
  }

  const scans = selectedSite.scans;
  const plyScans = scans.filter((scan) => scan.modelFormat === "ply");

  function downloadLazFile(downloadScanId) {
    dispatch(downloadScanLazFile(downloadScanId));
  }

  const [currentMeasureName, setCurrentMeasureName] = useState("");
  const [pointMarker, setPointMarker] = useState(null);

  useEffect(() => {
    if (pointMarker) {
      mapBoxViewer.on("mousemove", onPointMouseMove);
      mapboxPanelRef.current.addEventListener("mouseleave", onPointMouseLeave);
      mapBoxViewer.once("mouseup", onPointMouseUp);
    }
  }, [pointMarker]);

  const currentMeasureNameRef = useRef();
  useEffect(() => {
    currentMeasureNameRef.current = currentMeasureName;
  }, [currentMeasureName]);

  useEffect(() => {
    if (currentMeasureName) {
      const ref = createRef();
      ref.current = document.createElement("div");
      ref.current.color = "#FFFFFF";
      /* eslint-disable react/no-deprecated */
      render(<MapBoxPointLabelMarker />, ref.current);
      var marker = new mapboxgl.Marker({ element: ref.current })
        .setLngLat([0, 0])
        .addTo(mapBoxViewer);
      setPointMarker(marker);
    }
  }, [currentMeasureName]);

  const onReset = (type) => {
    setMeasuring(false);
    if (type === "measurement") {
      //Remove Point Layer if exist
      removePointLayer();

      //Remove measurement points
      pointMeasures.forEach((pointMeasure) => {
        pointMeasure.remove();
      });
      setPointMeasures([]);

      //Remove measurement distances
      distanceMeasures.forEach((distanceMeasure) => {
        distanceMeasure.remove();
      });
      setDistanceMeasures([]);

      //Remove measurement areas
      areaMeasures.forEach((areaMeasure) => {
        areaMeasure.remove();
      });
      setAreaMeasures([]);

      //Remove measurement angles
      angleMeasures.forEach((angleMeasure) => {
        angleMeasure.remove();
      });
      setAngleMeasures([]);
    } else {
      console.error("Unrecognized type for reset = " + type);
    }
  };

  //Measurement Point Tool
  const onSelectMeasurement = (type) => {
    setCurrentMeasureName(type);
    setMeasuring(true);
  };

  const [pointMeasures, setPointMeasures] = useState([]);
  const [distanceMeasures, setDistanceMeasures] = useState([]);
  const [areaMeasures, setAreaMeasures] = useState([]);
  const [angleMeasures, setAngleMeasures] = useState([]);

  useEffect(() => {
    updateMeasurmentPointclouds();
  }, [
    scanPointClouds,
    pointMeasures,
    areaMeasures,
    angleMeasures,
    distanceMeasures,
  ]);

  useEffect(() => {
    onStyleLoadEventListener();
  }, [mapTilesOrthos, vectorTilesContours]);

  const removePointLayer = () => {
    if (pointMarker) {
      pointMarker.remove();
      setPointMarker(null);
    }
  };

  const onPointMouseMove = (e) => {
    const canvas = mapBoxViewer.getCanvasContainer();
    const coords = e.lngLat;
    if (checkPointValidDefault(coords.lng, coords.lat, scanPointClouds)) {
      canvas.style.cursor = "pointer";
      if (pointMarker) {
        var element = pointMarker
          .getElement()
          .getElementsByClassName("labelTextMarker");
        if (element.length > 0) {
          element[0].innerHTML = `${coords.lng.toFixed(8)}/${coords.lat.toFixed(
            8
          )}`;
        }
        pointMarker.setLngLat([coords.lng, coords.lat]);
      }
    }
  };

  const onPointMouseLeave = () => {
    mapBoxViewer.off("mouseup", onPointMouseUp);
    mapBoxViewer.off("mousemove", onPointMouseMove);
    mapboxPanelRef.current.removeEventListener("mouseleave", onPointMouseLeave);
    const canvas = mapBoxViewer.getCanvasContainer();
    const coords = pointMarker.getLngLat();
    canvas.style.cursor = "";
    removePointLayer();
    if (currentMeasureNameRef.current == "Point") {
      addMeasurementPoint(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Distance") {
      addDistanceMeasurement(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Area") {
      addAreaMeasurement(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Angle") {
      addAngleMeasurement(coords.lng, coords.lat);
    }
    setCurrentMeasureName("");
  };

  const onPointMouseUp = (e) => {
    setMeasuring(false);
    mapBoxViewer.off("mouseup", onPointMouseUp);
    mapBoxViewer.off("mousemove", onPointMouseMove);
    mapboxPanelRef.current.removeEventListener("mouseleave", onPointMouseLeave);
    const canvas = mapBoxViewer.getCanvasContainer();
    var coords = {};
    if (checkPointValidDefault(e.lngLat.lng, e.lngLat.lat, scanPointClouds)) {
      coords = e.lngLat;
    } else {
      coords = pointMarker.getLngLat();
    }
    canvas.style.cursor = "";
    removePointLayer();
    if (currentMeasureNameRef.current == "Point") {
      addMeasurementPoint(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Distance") {
      addDistanceMeasurement(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Area") {
      addAreaMeasurement(coords.lng, coords.lat);
    } else if (currentMeasureNameRef.current == "Angle") {
      addAngleMeasurement(coords.lng, coords.lat);
    }
    setCurrentMeasureName("");
  };

  const checkPointValid = (lng, lat, pointclouds) => {
    var isPointValid = false;
    pointclouds.forEach((scanPointCloud) => {
      if (!isPointValid) {
        isPointValid = scanPointCloud.checkPointValid(lng, lat);
      }
    });
    return isPointValid;
  };
  const checkPointValidDefault = () => {
    return true;
  };
  const addMeasurementPoint = (lng, lat) => {
    var pointMeasure = new MapBoxPointMeasure(
      mapBoxViewer,
      lat,
      lng,
      mapboxPanelRef,
      checkPointValidDefault
    );
    const pointMeasuresData = [...pointMeasures];
    pointMeasuresData.push(pointMeasure);
    setPointMeasures(pointMeasuresData);
  };

  const addDistanceMeasurement = (lng, lat) => {
    var distanceMeasure = new MapBoxDistanceMeasure(
      mapBoxViewer,
      lat,
      lng,
      mapboxPanelRef,
      feetLengthSwitch ? "ft" : "m",
      checkPointValidDefault
    );
    var distanceMeasuresData = [...distanceMeasures];
    distanceMeasuresData.push(distanceMeasure);
    setDistanceMeasures(distanceMeasuresData);
  };

  const addAreaMeasurement = (lng, lat) => {
    var areaMeasure = new MapBoxAreaMeasure(
      mapBoxViewer,
      lat,
      lng,
      mapboxPanelRef,
      feetLengthSwitch ? "ft" : "m",
      checkPointValidDefault
    );
    var areaMeasuresData = [...areaMeasures];
    areaMeasuresData.push(areaMeasure);
    setAreaMeasures(areaMeasuresData);
  };

  const addAngleMeasurement = (lng, lat) => {
    var angleMeasure = new MapBoxAngleMeasure(
      mapBoxViewer,
      lat,
      lng,
      mapboxPanelRef,
      checkPointValidDefault
    );
    var angleMeasuresData = [...angleMeasures];
    angleMeasuresData.push(angleMeasure);
    setAngleMeasures(angleMeasuresData);
  };

  //Map Style update
  const [mapStyle, setMapStyle] = useState("street");
  const handleOnMapStyleClick = () => {
    if (mapStyle == "street") {
      mapBoxViewer.setStyle("mapbox://styles/mapbox/satellite-streets-v12");
      setMapStyle("satellite");
    } else {
      mapBoxViewer.setStyle("mapbox://styles/mapbox/streets-v12");
      setMapStyle("street");
    }
  };

  function onToggleFeetLength() {
    const value = !feetLengthSwitch;
    setFeetLengthSwitch(value);
    if (value) {
      distanceMeasures.forEach((distanceMeasure) => {
        distanceMeasure.setUnitLength("ft");
      });
      areaMeasures.forEach((areaMeasure) => {
        areaMeasure.setUnitLength("ft");
      });
    } else {
      distanceMeasures.forEach((distanceMeasure) => {
        distanceMeasure.setUnitLength("m");
      });
      areaMeasures.forEach((areaMeasure) => {
        areaMeasure.setUnitLength("m");
      });
    }
  }

  const updateMeasurmentPointclouds = () => {
    distanceMeasures.forEach((distanceMeasure) => {
      distanceMeasure.setPointClouds(scanPointClouds);
    });
    pointMeasures.forEach((pointMeasure) => {
      pointMeasure.setPointClouds(scanPointClouds);
    });
    areaMeasures.forEach((areaMeasure) => {
      areaMeasure.setPointClouds(scanPointClouds);
    });
    angleMeasures.forEach((angleMeasure) => {
      angleMeasure.setPointClouds(scanPointClouds);
    });
  };

  const onStyleLoadEventListener = useCallback(() => {
    scanPointClouds.forEach((pointCloud) => {
      pointCloud.onStyleLoad();
    });

    mapTilesOrthos
      .filter((x) => x.showingOrtho)
      .forEach((mapTiles) => {
        mapTiles.onStyleLoad();
      });

    vectorTilesContours
      .filter((x) => x.showingVector)
      .forEach((vectorTilesContour) => {
        vectorTilesContour.onStyleLoad();
      });

    pointMeasures.forEach((measure) => {
      measure.onStyleLoad();
    });

    distanceMeasures.forEach((measure) => {
      measure.onStyleLoad();
    });

    areaMeasures.forEach((measure) => {
      measure.onStyleLoad();
    });

    angleMeasures.forEach((measure) => {
      measure.onStyleLoad();
    });

    if (imageMarkers) {
      imageMarkers.onStyleLoad();
    }
  }, [
    scanPointClouds,
    mapTilesOrthos,
    vectorTilesContours,
    pointMeasures,
    distanceMeasures,
    areaMeasures,
    angleMeasures,
    imageMarkers,
  ]);

  useEffect(() => {
    if (mapBoxViewer) {
      mapBoxViewer.on("style.load", onStyleLoadEventListener);
    }
    return () => {
      if (mapBoxViewer) {
        mapBoxViewer.off("style.load", onStyleLoadEventListener);
      }
    };
  }, [mapBoxViewer, onStyleLoadEventListener]);

  const selectAllScans = (isChecked) => {
    if (isChecked) {
      let loadedScans = selectedSite.scans.filter(
        (x) => !loadedScanIds.includes(x.id) && x.mapboxStatus == 2
      );
      let scanPointCloudsData = [];
      loadAllScansPointCloud(0, loadedScans, scanPointCloudsData, []);
    } else {
      scanPointClouds.forEach((x) => {
        x.remove();
      });
      distanceMeasures.forEach((x) => {
        x.remove();
      });
      pointMeasures.forEach((x) => {
        x.remove();
      });
      areaMeasures.forEach((x) => {
        x.remove();
      });
      angleMeasures.forEach((x) => {
        x.remove();
      });

      setDistanceMeasures([]);
      setPointMeasures([]);
      setAreaMeasures([]);
      setAngleMeasures([]);
      setScanPointClouds([]);
      setLoadedScanIds([]);
    }
  };

  const setPointcloudsBoundingBox = (pointclouds) => {
    const data = [];
    pointclouds.forEach((pointCloud) => {
      data.push([pointCloud.topLeft.lng, pointCloud.topLeft.lat]);
      data.push([pointCloud.topRight.lng, pointCloud.topRight.lat]);
      data.push([pointCloud.bottomLeft.lng, pointCloud.bottomLeft.lat]);
      data.push([pointCloud.bottomRight.lng, pointCloud.bottomRight.lat]);
    });
    var line = turf.lineString(data);
    var bbox = turf.bbox(line);

    mapBoxViewer.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      { animate: false }
    );
  };

  const handleOnClickDailog = () => {
    setOpenUnexpectedErrorDailog(false);
  };

  const handleOnClickContactSupport = () => {
    setOpenUnexpectedErrorDailog(false);
    navigate("/apps/user/support");
  };

  return (
    <div>
      <OuterPanel>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          open={leftDrawerOpen}
          onClose={() => setLeftDrawerOpen(!leftDrawerOpen)}
          variant="persistent"
        >
          <MapBoxToolbar
            scans={plyScans}
            currentViewer={props.currentViewer}
            loadedScanIds={loadedScanIds}
            toggleDrawer={() => setLeftDrawerOpen(!leftDrawerOpen)}
            onTogglePointCloud={onTogglePointCloud}
            downloadLazFile={downloadLazFile}
            zoomToPointCloud={zoomToPointCloud}
            zoomOutPointCloud={zoomOutPointCloud}
            onSelectMeasurement={onSelectMeasurement}
            onReset={onReset}
            onToggleFeetLength={onToggleFeetLength}
            feetLengthSwitch={feetLengthSwitch}
            mapperEnabled={mapperEnabled}
            onToggleGpsCoordinateFile={onToggleGpsCoordinateFile}
            getSnap={getSnap}
            measuring={measuring}
            imageMarkersVisible={imageMarkersVisible}
            onToggleImageMarkers={() =>
              setImageMarkersVisible(!imageMarkersVisible)
            }
            showToolbarItems={showToolbarItems}
            onToggleCurrentViewer={onToggleCurrentViewer}
            showOrtho={showOrtho}
            onToggleOrtho={() => setShowOrtho(!showOrtho)}
            showContours={showContours}
            onToggleContours={() => setshowContours(!showContours)}
            selectedSite={selectedSite}
            role={role}
            selectAllScans={selectAllScans}
          />
        </Drawer>

        <MainPanel open={leftDrawerOpen}>
          <Header>
            {!leftDrawerOpen && (
              <Button onClick={() => setLeftDrawerOpen(!leftDrawerOpen)}>
                <Menu sx={{ color: "#ffffff" }} />{" "}
                <span style={{ color: "#ffffff" }}>Tools</span>
              </Button>
            )}
          </Header>
          {scans.length === scans.filter((x) => x.mapboxStatus != 2).length ? (
            <div
              className="flex flex-row w-full items-center justify-center"
              style={{ height: "100vh" }}
            >
              <Typography variant="h1" color="#6CCCD7" className="text-28">
                <Box sx={{ fontWeight: "bold", m: 1 }}>
                  Processing in Progress
                </Box>
              </Typography>
            </div>
          ) : (
            <>
              <MapBoxPanel open={leftDrawerOpen} ref={mapboxPanelRef} />

              {/* {!showOrtho && !showContours && */}
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={true}
                sx={{ width: 100, height: 100 }}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
              >
                <Button
                  onClick={() => handleOnMapStyleClick()}
                  style={{
                    padding: "0px",
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      backgroundImage:
                        mapStyle == "street"
                          ? "url(/assets/images/mapbox/sattlite-view.png"
                          : "url(/assets/images/mapbox/streets-view.png",
                      height: "100px",
                      width: "100px",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100px 100px",
                      borderRadius: "6px",
                      boxSizing: "border-box",
                      border: "1px solid #000000",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "700",
                        fontSize: "11px",
                        fontStyle: "normal",
                        color: "#FFFFFF",
                        marginTop: "10px",
                        background: "#000",
                        WebkitBackgroundClip: "text",
                        WebkitTextStroke: "2px transparent",
                      }}
                    >
                      {mapStyle == "street" ? "Satellite Imagery" : "Streets"}
                    </Typography>
                  </div>
                </Button>
              </Snackbar>
              {/* } */}
            </>
          )}
          {showSnapDialog && (
            <ScanSnap
              showConfirmation={showSnapDialog}
              src={snapImage}
              handleClose={() => handleClose()}
              scanName={selectedSite.name}
              siteId={selectedSite.id}
            />
          )}
          <UnexpectedErrorDailog
            openUnexpectedErrorDailog={openUnexpectedErrorDailog}
            handleOnClick={handleOnClickDailog}
            handleOnClickContactSupport={handleOnClickContactSupport}
          />
        </MainPanel>
      </OuterPanel>
    </div>
  );
});

export default MapBoxViewer;
