import {
    useState,
    useEffect
} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";

function SiteAccess(props) {
    const { user } = props;

    const [open, setOpen] = useState(true);
    const [siteRole, setSiteRole] = useState('user');

    useEffect(() => {
        setSiteRole(user.siteRole);
    }, []);

    const handleClose = () => {
        props.handleClose()
    };

    const handleOnSiteRoleChange = (e) => {
        setSiteRole(e.target.value)
    }

    const handleOnAssignClick = () => {
        if (siteRole) {
            if (siteRole != user.siteRole) {
                var data = {
                    siteRole: siteRole,
                    userId: user.id
                }
                props.onClickAssignUserSiteAccess(data);
            }
            else {
                handleClose();
            }
        }
        else {
            handleClose();
        }

    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
            >
                <DialogTitle className="flex flex-col items-center justify-center w-full pb-4">Edit Site Access</DialogTitle>

                <div>
                    <DialogContent className="flex flex-col items-center justify-center w-full py-0">
                        <FormControl className='mx-0' sx={{ m: 1, width: 250 }}>
                            <InputLabel id="demo-simple-select-standard-label">Site Role </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name="accountRole"
                                value={siteRole}
                                onChange={(e) => handleOnSiteRoleChange(e)}
                                inputProps={{ 'aria-label': 'Without label' }}
                                label="Site Role"
                            >
                                {user.organizationRole == 'manager' &&
                                    <MenuItem value="admin">Admin</MenuItem>
                                }
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                        </FormControl>

                    </DialogContent>
                </div>

                <DialogActions className="flex flex-row items-center justify-center w-full mb-8">
                    <Button color="error" variant='outlined' onClick={() => handleClose()}>Cancel</Button>
                    <Button color="secondary" variant='outlined' onClick={() => handleOnAssignClick()}>Assign</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SiteAccess;