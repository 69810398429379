import { createStyles, makeStyles } from "@mui/styles";

const ScanDetailsStyles = makeStyles(() =>
  createStyles({
    active: {
      background: "#EFEEEE",
      width: "135px",
      color: "#000000",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      left: "26px",
    },
    inactive: {
      width: "135px",
      color: "#000000",
      left: "0px",
      zIndex: "999",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      background: "#D9E4F6",
    },
    transformOverride: {
      transform: "scale(1, 1) perspective(0.3em) rotateX(-3deg)",
    },
    activeButton: {
      "&:hover": {
        backgroundColor: "#EFEEEE",
        color: "#000000",
      },
    },
    inactiveButton: {
      "&:hover": {
        backgroundColor: "#D9E4F6",
        color: "#000000",
      },
    },
  })
);

export default ScanDetailsStyles;
