
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CommonTable from "../../common/CommonTable";


function ScanProcessingHistoryGrid(props) {

    const { scanProcessingHistory, toDateTime, handleOnInfoClick } = props

    const getStringFormate = (str) => {
        var _str = str.replace(/([A-Z])/g, ' $1').trim()
        return _str;
    }

    const getStartedAfter = (startTime, endTime) => {
        var date1 = new Date(startTime);
        var date2 = new Date(endTime);
        var res = Math.abs(date1 - date2) / 1000;
        var days = Math.floor(res / 86400);
        var hours = Math.floor(res / 3600) % 24;
        var minutes = Math.floor(res / 60) % 60;
        var seconds = res % 60;
        return ((hours > 10 ? hours : "0" + hours) + ":" + (minutes > 10 ? minutes : "0" + minutes) + ":" + (seconds.toFixed(3) > 10 ? seconds.toFixed(3) : "0" + seconds.toFixed(3)));

    }

    const RenderTimeStampCell = (row) => {
        return toDateTime(row.timestamp);
    }

    const RenderTypeCell = (row) => {
        return getStringFormate(row.type);
    }

    const RenderStartAfterCell = (row) => {
        return getStartedAfter(scanProcessingHistory[0].timestamp, row.timestamp);
    }

    const RenderActionCell = (row) => {
        return (
            <Tooltip title='Details'>
                <IconButton color="secondary" onClick={() => handleOnInfoClick(row)}><InfoIcon /></IconButton>
            </Tooltip>
        )
    }

    const getTableConfig = () => {
        return [
            { id: 'timestamp', label: 'Time', display: true, isSortable: true, contentType: 'custom', content: RenderTimeStampCell },
            { id: 'type', label: 'Execution State', display: true, isSortable: true, contentType: 'custom', content: RenderTypeCell },
            { id: 'startAfter', label: 'Start After', display: true, isSortable: true, contentType: 'custom', content: RenderStartAfterCell },
            { id: 'action', label: 'Action', display: true, isSortable: false, contentType: 'custom', content: RenderActionCell },
        ];
    }


    return (
        <div>
            <div className="w-full">
                <div className="flex flex-col md:flex-row sm:p-8" >
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="flex justify-center max-w-full relative">
                            <div className="flex flex-1 max-w-full">
                                <div className="flex flex-1 justify-center max-w-full">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <CommonTable
                                            renderData={scanProcessingHistory}
                                            dataConfig={getTableConfig()}
                                            defaultOrderBy={'timestamp'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanProcessingHistoryGrid;