import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinearProgressWithLabel from "app/shared-components/progressbarlabel/LinearProgressWithLabel";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TableRow,
  TablePagination,
  TableContainer,
  IconButton,
  Typography,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import ConfirmationDialog from "app/shared-components/dialogs/confirmationBox";
import {
  cancelLargeFileUpload,
  removeUploadObject,
  cancelSmallFileUpload,
} from "./store/UploadSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function UploadFileStatus() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getFileExactSize = (size) => {
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    var exactSize = Math.round(size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  const getTimeRemaining = (uploadObject) => {
    const loadedChunks = uploadObject.currentChunkCount;
    const totalChunks = uploadObject.totalChunkCount;
    const timeElapsedInSeconds =
      (new Date().getTime() - uploadObject.timeStart.getTime()) / 1000;

    // Avoid division by zero
    if (timeElapsedInSeconds <= 0 || loadedChunks <= 0) {
      return "Calculating...";
    }

    // Calculate bytes per second
    const bytesPerSecond = loadedChunks / timeElapsedInSeconds;

    // Estimate remaining bytes or chunks
    const remainingChunks = totalChunks - loadedChunks;
    const secondsRemaining = remainingChunks / bytesPerSecond;

    return secondsToDhms(secondsRemaining);
  };

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    if (!isFinite(seconds)) {
      return "N/A";
    }
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFileObject, setSelectedFileObject] = useState(null);
  const {
    currentLargeUploadingFile,
    largeUploadingFiles,
    smallUploadingFiles,
    currentSmallUploadingFile,
    uploadSpeed,
    uploadedChunk,
    filesTimeStart,
    filesTotalChunk,
  } = useSelector(({ files }) => files.uploadApp);
  const { showUploadPopup } = useSelector(({ admin }) => admin.socketApp);

  const handleOnCancelClick = (obj) => {
    try {
      setSelectedFileObject(obj);
      setShowConfirmation(true);
      setMessage("Are you sure you want to cancel?");
    } catch (error) {
      console.error(error);
    }
  };
  const handleOnOkButtonClick = () => {
    setShowConfirmation(false);
    if (selectedFileObject != null) {
      dispatch(cancelLargeFileUpload(selectedFileObject));
    }
  };

  const handleOnConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const joinObjects = (obj1, obj2) => {
    return obj1
      .filter((x) => x.status == "Pending")
      .concat(obj2.filter((x) => x.status == "Pending"));
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTimeRemainingForAdmin = (
    chunkUploaded,
    totalFilesChunk,
    timeFileUploadStart
  ) => {
    const loadedChunks = chunkUploaded;
    const totalChunks = totalFilesChunk;

    const secondsElapsed =
      (new Date().getTime() - timeFileUploadStart.getTime()) / 1000;

    // Avoid division by zero
    if (secondsElapsed <= 0 || loadedChunks <= 0) {
      return "Calculating...";
    }

    const chunksPerSecond = loadedChunks / secondsElapsed;

    const remainingChunks = totalChunks - loadedChunks;
    const secondsRemaining = remainingChunks / chunksPerSecond;

    // Return formatted time or fallback for edge cases
    return secondsRemaining > 0
      ? secondsToDhms(secondsRemaining)
      : "Almost done!";
  };

  const handleOnCloseButton = () => {
    if (window.history.state.idx == 0) {
      navigate("/apps/dashboards/sites");
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="w-full px-24 -mx-4">
      <div className="md:flex-row sm:p-8">
        <div className="flex-col min-w-0">
          <div className="justify-center max-w-full relative">
            <div className="max-w-full">
              <div className="justify-center max-w-full">
                {largeUploadingFiles?.length == 0 &&
                smallUploadingFiles?.length == 0 ? (
                  <div className="flex justify-between">
                    <h2>No Files Uploading</h2>
                    <Button
                      variant="outlined"
                      style={{
                        boxSizing: "border-box",
                        width: "100px",
                        background: "#E67260",
                        border: "1px solid #E67260",
                        borderRadius: "50px",
                        color: "#ffffff",
                      }}
                      onClick={() => handleOnCloseButton()}
                      startIcon={<HighlightOffIcon />}
                    >
                      Close
                    </Button>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <Button
                      variant="outlined"
                      style={{
                        boxSizing: "border-box",
                        width: "100px",
                        background: "#E67260",
                        border: "1px solid #E67260",
                        borderRadius: "50px",
                        color: "#ffffff",
                      }}
                      onClick={() => handleOnCloseButton()}
                      startIcon={<HighlightOffIcon />}
                    >
                      Close
                    </Button>
                  </div>
                )}
                {showUploadPopup ? (
                  largeUploadingFiles.length == 0 &&
                  smallUploadingFiles.length == 0 ? null : (
                    <div className="text-center">
                      <h6>
                        Time Remaining -{" "}
                        {getTimeRemainingForAdmin(
                          uploadedChunk,
                          filesTotalChunk,
                          filesTimeStart
                        )}{" "}
                        | Upload Speed - {uploadSpeed} |{" "}
                        {largeUploadingFiles.length +
                          smallUploadingFiles.length}{" "}
                        Files Uploading
                      </h6>
                    </div>
                  )
                ) : null}

                {currentLargeUploadingFile.length > 0 ||
                currentSmallUploadingFile.length > 0 ? (
                  <div>
                    <h2>
                      <b>Files</b>
                    </h2>
                    <Typography
                      color="error"
                      style={{
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <Warning /> Do not close this window while uploading is in
                      progress. This will render scans unprocessable.
                    </Typography>
                    {currentLargeUploadingFile.map((el, i) => (
                      <div className="mb-24" key={i}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-between">
                            <h6>{el.fileName + " (" + el.typeName + ")"}</h6>
                            <h6 className="ml-12">
                              {getFileExactSize(el.beginningOfTheChunk)} /{" "}
                              {getFileExactSize(el.file.size)}
                            </h6>
                          </div>
                          <div className="flex items-center justify-between">
                            <h6 className="mr-12">
                              Time Remaining - {getTimeRemaining(el)}
                            </h6>
                            <h6 className="mr-12">
                              Upload Speed - {el.uploadSpeed}
                            </h6>
                            <Button
                              disabled={el.isPrimary}
                              className="mr-48"
                              color="error"
                              variant="outlined"
                              onClick={() => handleOnCancelClick(el)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <LinearProgressWithLabel
                          value={
                            (el.currentChunkCount / el.totalChunkCount) * 100
                          }
                        ></LinearProgressWithLabel>
                      </div>
                    ))}
                    {currentSmallUploadingFile.map((el, i) => (
                      <div className="mb-24" key={i}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-between">
                            <h6>{el.fileName + " (" + el.name + ")"}</h6>
                            <h6 className="ml-12">
                              {" "}
                              0/ {getFileExactSize(el.file.size)}
                            </h6>
                          </div>
                          <div className="flex items-center justify-between">
                            <Button
                              className="mr-48"
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                dispatch(cancelSmallFileUpload(el));
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>

                        <LinearProgressWithLabel
                          value={0}
                        ></LinearProgressWithLabel>
                      </div>
                    ))}
                  </div>
                ) : null}

                {smallUploadingFiles.filter((x) => x.status == "Pending")
                  .length == 0 &&
                largeUploadingFiles.filter((x) => x.status == "Pending")
                  .length == 0 ? null : (
                  <>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>File Name</b>
                            </TableCell>
                            <TableCell>
                              <b>Size</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>

                        {joinObjects(largeUploadingFiles, smallUploadingFiles)
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((file, index) =>
                            file.status == "Pending" ? (
                              <TableBody key={index}>
                                <TableRow key={index}>
                                  <TableCell>{file.fileName}</TableCell>
                                  <TableCell>
                                    {getFileExactSize(file.file.size)}
                                  </TableCell>
                                  <TableCell>{file.status}</TableCell>
                                  <TableCell>
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        dispatch(removeUploadObject(file));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : null
                          )}
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      component="div"
                      count={
                        smallUploadingFiles.filter((x) => x.status == "Pending")
                          .length +
                        largeUploadingFiles.filter((x) => x.status == "Pending")
                          .length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ConfirmationDialog
          showConfirmation={showConfirmation}
          handleOnOkClick={handleOnOkButtonClick}
          message={message}
          handleClose={handleOnConfirmationClose}
        ></ConfirmationDialog>
      </div>
    </div>
  );
}

export default UploadFileStatus;
