import { createSlice ,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "app/AppConfig";




export const getIsMultiFactorEnabled = createAsyncThunk('auth/multifactor/getIsMultiFactorEnabled', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/user/getIsMultiFactorEnabled`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',

        },
    });

    const data = await response.data;
    return data;
});

export const updateMultiFactor = createAsyncThunk('auth/multifactor/updateMultiFactor', async (isMultiFactor, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/user/updateMultiFactor`;
    var formData = new FormData();
    formData.append("isMultiFactor", isMultiFactor);
    
    const response = await axios.post(url,formData, {
        headers: {
            'Content-Type': 'multipart/form-data',

        },
    });

    const data = await response.data;
    if(isMultiFactor == true){
        window.open(data, 'multifactor', "height=1600,width=1000,left=600,top=20");
    }
});


const initialState = {
    isMFAEnabled : false,
    isOrganisationEnabled : false

};

const multifactorSlice = createSlice({
    name: 'auth/multifactor',
    initialState,
    reducers: {
        setMultiFactor: (state, action) => {
           state.isMFAEnabled =  action.payload
        }
    },
    extraReducers: {
        [getIsMultiFactorEnabled.fulfilled]: (state, action) => {
            state.isMFAEnabled = action.payload.isEnabled;
            state.isOrganisationEnabled = action.payload.isOrganisationEnabled;
        },
    },
});

export const { setMultiFactor } = multifactorSlice.actions;

export default multifactorSlice.reducer;