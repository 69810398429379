import {
    useState,
    useEffect
} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    Typography
} from '@mui/material';
import styled from "@emotion/styled";
import swal from 'sweetalert';

const TitlePanel = styled.div`
  display: flex;
`;

function FolderDialog(props) {

    const { type, onClose, onAction, defaultTextValue, fileNameAlreadyExists } = props;
    const [textInput, setTextInput] = useState('')
    const [fileExtension, setFileExtension] = useState('')

    const [isValid, setIsValid] = useState(true)


    useEffect(() => {
        if (defaultTextValue) {
            if (type === 'renamefile') {
                var fileNameSplit = defaultTextValue.split('.');
                setFileExtension('.' + fileNameSplit[fileNameSplit.length - 1]);
                fileNameSplit.splice(fileNameSplit.length - 1, 1);
                setTextInput(fileNameSplit.join('.'));
            }
            else {
                setTextInput(defaultTextValue);
            }
        }

    }, [defaultTextValue, type]);

    const onClickAction = () => {
        setIsValid(true)
        if (textInput) {
            if (type == 'delete') {
                if (textInput == 'permanently delete') {
                    onAction();
                }
                else {
                    setIsValid(false);
                }
            }
            else {
                if (textInput.includes('\/')) {
                    setIsValid(false)
                }
                else {
                    if (type == 'createfolder') {
                        onAction(textInput);
                    }
                    else if (type == 'renamefile') {
                        var fileName = textInput + fileExtension;
                        if (defaultTextValue == fileName) {
                            onClose();
                        }
                        else {
                            var count = fileNameAlreadyExists(fileName);
                            if (count == 0) {
                                onAction(fileName);
                            }
                            else {
                                swal({
                                    text: `File ${fileName} already exists. Click Ok to overwrite the existing file with the renamed file.`,
                                    buttons: true,
                                }).then((willOverWrite) => {
                                    if (willOverWrite) {
                                        onAction(fileName);
                                    }
                                    else {
                                        onClose();
                                    }
                                })
                            }
                        }

                    }
                    else if (type == 'renamefolder') {
                        var fileName = textInput;
                        if (defaultTextValue == fileName) {
                            onClose();
                        }
                        else {
                            const count = fileNameAlreadyExists(textInput);
                            if (count === 0) {
                                onAction(fileName);
                            }
                            else {
                                swal({
                                    text: `Folder ${textInput}${fileExtension} already exists. Click Ok to overwrite the existing folder with the renamed folder.`,
                                    buttons: true,
                                }).then((willOverWrite) => {
                                    if (willOverWrite) {
                                        onAction(fileName);
                                    }
                                    else {
                                        onClose();
                                    }
                                })
                            }
                        }

                    }

                }
            }
        }
        else {
            setIsValid(false)
        }
    }

    const getTypographyType = (type) => {
        const typographyMap = {
            'createfolder': 'Create Folder',
            'renamefile': 'Rename File',
            'renamefolder': 'Rename Folder'
        };

        return typographyMap[type] || '';
    }

    const getButtonType = (type) => {
        const buttonMap = {
            'createfolder': 'Create',
            'renamefile': 'Rename',
            'renamefolder': 'Rename',
            'delete': 'Delete'
        };

        return buttonMap[type] || '';
    }

    return (
        <div>
            <Dialog
                fullWidth
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" open>

                <DialogTitle>
                    <TitlePanel className='flex flex-row justify-center'>
                        <Typography variant="h6" className="text-center mt-0 font-semibold text-18 sm:text-24">
                            {getTypographyType(type)}
                        </Typography>
                    </TitlePanel>
                </DialogTitle>
                <DialogContent>
                    <div className='flex flex-col'>
                        {type == 'renamefile' ?
                            <div className='flex flex-row w-full'>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    className="mb-8"
                                    value={textInput}
                                    placeholder='File Name'
                                    onChange={(e) => { setTextInput(e.target.value) }}
                                    error={!isValid && (textInput == '' || textInput.includes('\/'))}
                                />
                                <span className='mt-16'>{fileExtension}</span>
                            </div>
                            :

                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className="mb-8"
                                value={textInput}
                                onChange={(e) => { setTextInput(e.target.value) }}
                                placeholder={type === 'delete' ? 'permanently delete' : (type === 'createfolder' || type === 'renamefolder') ? 'Folder Name' : null}
                                error={type === 'delete' ? !isValid && (textInput === '' || textInput != 'permanently delete') : !isValid && (textInput === '' || textInput.includes('\/'))}
                            />
                        }
                        {(type == 'createfolder' || type == 'renamefolder') ?
                            <Typography variant="h6" style={{ 'fontSize': 'small' }} className="text-red mt-0 font-semibold text-8">
                                Folder names can't contain "/"
                            </Typography>
                            : type == 'renamefile' ?
                                <Typography variant="h6" style={{ 'fontSize': 'small' }} className="text-red mt-0 font-semibold text-8">
                                    File names can't contain "/"
                                </Typography>
                                :
                                type == 'delete' ?
                                    <Typography variant="h6" style={{ 'fontSize': 'small' }} className="text-red mt-0 font-semibold text-8">
                                        To confirm deletion, type 'permanently delete' in the text input field.
                                    </Typography>
                                    : null
                        }
                        <div className='flex flex-row justify-end'>
                            <Button onClick={onClose} color='error' variant="outlined">Close</Button>
                            <Button onClick={() => onClickAction()} className='ml-8' variant="contained">
                                {getButtonType(type)}
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default FolderDialog;