import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import APP_CONFIG from "../../AppConfig";

export const loadCustomer = () => async (dispatch, getState) => {

    const email = getState().auth.user.data.email;

    const url = APP_CONFIG.api + `v1/customers?email=${email}`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            localStorage.setItem('app_roleId', response.data[0].applicationroleid);
            return dispatch(setCustomer(response.data[0]));
        }
    })
        .catch((error) => {
            console.warn('Cannot retrieve customer data', error);
        });

};


export const getLoginUserDetails = (user) => {

    var formData = new FormData();
    formData.append("email", user.email);
    formData.append("authid", user.sub);
    formData.append("name", user.nickname);

    const url = APP_CONFIG.api + `v1/user/getLoginUserDetails`;

    const response = axios.post(url, formData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response;
}





const initialState = {
    id: 0,
    name: 'John Doe',
    status: 'active',
    email: 'johndoe@withinpixels.com',
    defaultAccount: 0,
    applicationroleid: 0,

};

const customerSlice = createSlice({
    name: 'auth/customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => action.payload,

    },
    extraReducers: {},
});

export const { setCustomer, setVerificationToken } = customerSlice.actions;

export default customerSlice.reducer;
