import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Grid,
  Paper,
  Tooltip,
  Radio,
  RadioGroup,
  Box
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomer,
  updateCustomer
} from '../store/customerSlice';
import { setLoaderProgress } from 'app/store/fuse/loaderSlice';
import ErrorMessageConstants from '../../constants/ErrorMessageConstants';
import swal from '@sweetalert/with-react';

// eslint-disable-next-line complexity
function CustomerCreateOrEdit(props) {

  const siteViewer = Object.keys(props).length == 0 ? false : props.siteViewer;
  const params = useParams()
  const dispatch = useDispatch();
  const customerId = Object.keys(props).length == 0 ? params.id : props.customerId;
  const accountId = params.accountid;
  const [isEmailValid, setEmailValid] = useState(true);
  const [isPasswordComfirmPasswordValid, setPasswordComfirmPasswordValid] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValide, setValidation] = useState(false);
  const [organizationUserSites, setOrganizationUserSites] = useState([]);
  const [isAddSiteValid, setIsAddSiteValid] = useState(true);
  const [sites, setSites] = useState([]);


  const { role } = useSelector(({ auth }) => auth.user)
  const { customer, customerOtherAccounts, accounts } = useSelector(({ edit }) => edit.edit)
  const [isAllSitesAccess, setIsAllSitesAccess] = useState(true);

  const handleIsAllSitesAccess = (e) => {
    setIsAllSitesAccess((e.target.value == 'true'));
  }

  const handleClose = () => {
    props.handleClose()
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    try {
      if (customerId) {
        let _customerId = parseInt(customerId, 10);
        if (customer && customer.id === _customerId) {
          setcustomerData({
            name: customer.name,
            email: customer.email,
            status: customer.status,
            defaultAccount: customer.defaultAccount,
            accountroleid: customer.accountroleid,
            applicationroleid: customer.applicationroleid,
            accountingPermission: customer.accountingPermission
          })
          setIsAllSitesAccess(customer.isAllSitesAccess);
          setAllSitesRole(customer.allSitesRole);
          setIsUploadMailEnable(customer.isUploadMailEnable);
          var organizationData = {};

          for (var i = 0; i < customerOtherAccounts.length; i++) {
            if (customerOtherAccounts[i].id != 0) {
              organizationData = { "accountId": customerOtherAccounts[i].accountId, "accountRole": customerOtherAccounts[i].accountRole };
              setUserOrganizationData(organizationData);
              var account = accounts.filter(x => x.id == customerOtherAccounts[i].accountId);
              if (account.length > 0) {
                var organizationSites = customer.organizationUserSites.filter(x => x.organizationId == account[0].id);
                var sitesData = [];
                for (var j = 0; j < organizationSites.length; j++) {
                  var siteData = { "siteId": organizationSites[j].siteId, "siteRole": organizationSites[j].siteRole };
                  sitesData.push(siteData);
                }
                setOrganizationUserSites(sitesData)
                if (sitesData.length > 0) {
                  if (sitesData.length == sitesData.filter(x => x.siteRole == 'admin').length) {
                    setAllSitesRole('admin');
                  }
                  else if (sitesData.length == sitesData.filter(x => x.siteRole == 'user').length) {
                    setAllSitesRole('user');
                  }
                }
                setSites(account[0].sites)
              }
              break;
            }
          }
        }
        else {
          navigate('/apps/appAdmin/users');
        }
      } else {
        if (accountId > 0) {
          setUserOrganizationData({ ...userOrganizationData, accountId: accountId });
          setOrganizationUserSites([]);
          setSites([]);
          var accountData = accounts.filter(x => x.id == accountId);
          if (accountData.length > 0) {
            setSites(accountData[0].sites)
          }
        } else if (accounts.length > 0) {
          setUserOrganizationData({ ...userOrganizationData, accountId: accounts[0].id });
          setOrganizationUserSites([]);
          setSites([]);
          var _account = accounts.filter(x => x.id == accounts[0].id);
          if (_account.length > 0) {
            setSites(_account[0].sites)
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

  }, [accountId, accounts, customerId, customer, customerOtherAccounts]);


  useEffect(() => {
    handleUpdateAllSiteRole();
  }, [organizationUserSites])


  const navigate = useNavigate();
  const [customerData, setcustomerData] = useState({
    name: '',
    status: 'active',
    email: '',
    password: '',
    confirmpassword: '',
    applicationroleid: 3,
    accountroleid: 3,
    accountingPermission: false
  });
  const handleOnChangeValues = (e) => {
    try {
      setcustomerData({ ...customerData, [e.target.name]: e.target.value });

    } catch (error) {
      console.error(error);
    }

  }
  const [isUploadMailEnable, setIsUploadMailEnable] = useState(false);
  const handleOnChangeUploadEnable = (e) => {
    setIsUploadMailEnable(e.target.value)
  }

  const [userOrganizationData, setUserOrganizationData] = useState({
    accountId: '',
    customerId: '',
    accountRole: 'user'
  })

  const [allSitesRole, setAllSitesRole] = useState('user');

  const handleOnChangeOrganizationDataValues = (e) => {
    try {
      setUserOrganizationData({ ...userOrganizationData, [e.target.name]: e.target.value });
      if (e.target.name == 'accountId') {
        setOrganizationUserSites([]);
        setSites([]);
        var account = accounts.filter(x => x.id == e.target.value);
        if (account.length > 0) {
          setSites(account[0].sites)
        }
      }
      if (e.target.name == 'accountRole' && e.target.value == 'admin') {
        setOrganizationUserSites([]);
      }

    } catch (error) {
      console.error(error);
    }

  }

  const handleOnChangeAllSitesRole = (e) => {
    try {
      if (userOrganizationData.accountRole == 'manager' || userOrganizationData.accountRole == 'user') {
        setAllSitesRole(e.target.value);
        var _organizationUserSites = [...organizationUserSites];
        for (var i = 0; i < _organizationUserSites.length; i++) {
          _organizationUserSites[i].siteRole = e.target.value;
        }
        setOrganizationUserSites(_organizationUserSites)
      }
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line complexity
  const handleCreateOrUpdate = () => {
    try {
      setEmailValid(true);
      setPasswordComfirmPasswordValid(true);
      setIsAddSiteValid(true);
      setValidation(false);
      setIsValidPassword(true)
      if (validateForm()) {

        if (customerId == undefined || customerId == null) {
          dispatch(setLoaderProgress(true));
          let data = {
            name: customerData.name,
            email: customerData.email.toLowerCase(),
            password: customerData.password,
            applicationroleid: customerData.applicationroleid,
            status: customerData.status,
            defaultAccount: 0,
            accountroleid: customerData.accountroleid,
            accountingPermission: customerData.accountingPermission,
            isAllSitesAccess: isAllSitesAccess,
            allSitesRole: allSitesRole,
            isUploadMailEnable: isUploadMailEnable
          }
          if (userOrganizationData.accountId != "" && userOrganizationData.accountRole != "") {
            data = {
              ...data,
              accountCustomers: [userOrganizationData],
              organizationUserSites: organizationUserSites.filter(x => x.siteId != '' && x.siteRole != '')
            }
          }
          if (siteViewer == true) {
            props.handleAddUser(data)
          } else {
            var requestData = {
              data: data,
              successCallback: onCreateCustomerSuccess,
              failureCallback: onCreateCustomerFailure
            }
            dispatch(createCustomer(requestData))
          }
        }
        else {
          var updateNewCustomer = customer;
          updateNewCustomer = {
            ...updateNewCustomer,
            name: customerData.name,
            email: customerData.email.toLowerCase(),
            password: customerData.password,
            applicationroleid: customerData.applicationroleid,
            status: customerData.status,
            defaultAccount: 0,
            accountroleid: customerData.accountroleid,
            accountingPermission: customerData.accountingPermission,
            isAllSitesAccess: isAllSitesAccess,
            allSitesRole: allSitesRole,
            isFormEditPage: true,
            isUploadMailEnable: isUploadMailEnable
          }

          if (userOrganizationData.accountId != "" && userOrganizationData.accountRole != "") {
            updateNewCustomer = {
              ...updateNewCustomer,
              accountCustomers: [userOrganizationData],
              organizationUserSites: organizationUserSites.filter(x => x.siteId != '' && x.siteRole != '')
            }
          }
          dispatch(setLoaderProgress(true));
          if (siteViewer == true) {
            props.handleUpdateUser(updateNewCustomer);
          } else {
            var requestedData = {
              data: updateNewCustomer,
              successCallback: onUpdateCustomerSuccess,
              failureCallback: onUpdateCustomerFailure
            }
            dispatch(updateCustomer(requestedData));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onCreateCustomerSuccess = () => {
    dispatch(setLoaderProgress(false));
    swal("", "Successfully created", "success")
      .then(() => {
        navigate(-1);
      });
  }

  const onCreateCustomerFailure = (message) => {
    if (message) {
      swal("", message, "error");
      dispatch(setLoaderProgress(false));
    } else {
      swal("", ErrorMessageConstants.defaultErrormessage, "error");
      dispatch(setLoaderProgress(false));
    }
  }

  const onUpdateCustomerFailure = (message) => {
    if (message) {
      swal("", message, "error");
      dispatch(setLoaderProgress(false));
    } else {
      swal("", ErrorMessageConstants.defaultErrormessage, "error");
      dispatch(setLoaderProgress(false));
    }
  }


  const onUpdateCustomerSuccess = () => {
    dispatch(setLoaderProgress(false));
    swal("", "Updated Successfully", "success")
      .then(() => {
        navigate(-1);
      });
  }

  const validateForm = () => {
    try {
      if (customerData.name && customerData.email && customerData.status && customerData.applicationroleid || customerData.password || customerData.confirmpassword) {
        setValidation(false);
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (re.test(customerData.email)) {
          setEmailValid(true)

          return true;

        } else {
          setEmailValid(false);
          return false;
        }
      } else {
        setValidation(true);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const OnClickAddOrganizationUserSites = () => {
    try {
      if (organizationUserSites.length == 0) {
        setIsAddSiteValid(true)
        const newObj = {
          siteId: "",
          siteRole: ["manager", "user"].includes(userOrganizationData.accountRole) ? allSitesRole : "user"
        };
        setOrganizationUserSites(prevArray => [...prevArray, newObj]);
      } else {
        const item = organizationUserSites[organizationUserSites.length - 1];
        if (item.siteId == "" || item.siteRole == "") {
          setIsAddSiteValid(false)
          return;
        }
        setIsAddSiteValid(true)
        const newObj = {
          siteId: "",
          siteRole: ["manager", "user"].includes(userOrganizationData.accountRole) ? allSitesRole : "user"
        };
        setOrganizationUserSites(prevArray => [...prevArray, newObj]);
      }
      handleUpdateAllSiteRole();
    } catch (error) {
      console.error(error);
    }
  }

  const OnClickAddAllOrganizationUserSites = () => {
    try {

      if (organizationUserSites.length == 0) {
        setIsAddSiteValid(true);
        var _organizationUserSitesDetails = [];
        sites.forEach(site => {
          const newObj = { ["siteId"]: site.id, ["siteRole"]: userOrganizationData.accountRole == 'manager' ? allSitesRole : "user" };
          _organizationUserSitesDetails.push(newObj);
        });
        setOrganizationUserSites(_organizationUserSitesDetails);
      } else {
        var _organizationUserSites = [...organizationUserSites];
        const item = _organizationUserSites[_organizationUserSites.length - 1];
        if (item.siteId == "" || item.siteRole == "") {
          _organizationUserSites = _organizationUserSites.filter((el, i) => i !== (_organizationUserSites.length - 1));
        }
        setIsAddSiteValid(true)
        sites.forEach(site => {
          if (_organizationUserSites.filter(x => x.siteId == site.id).length == 0) {
            const newObj = { ["siteId"]: site.id, ["siteRole"]: userOrganizationData.accountRole == 'manager' ? allSitesRole : "user" };
            _organizationUserSites.push(newObj);
          }

        });
        setOrganizationUserSites(_organizationUserSites);
        handleUpdateAllSiteRole();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const OnClickRemoveOrganizationUserSites = (index) => {
    try {
      setOrganizationUserSites(current => current.filter((el, i) => i !== index));
      handleUpdateAllSiteRole();
    } catch (e) {
      console.error(e);
    }
  }
  const handleOnOrganizationUserSiteChange = (e, index) => {
    try {
      const { name, value } = e.target;
      const updatedArray = [...organizationUserSites];
      updatedArray[index][name] = value;
      setOrganizationUserSites(updatedArray);
      handleUpdateAllSiteRole();
    } catch (error) {
      console.error(error);
    }
  }


  const handleUpdateAllSiteRole = () => {
    if (organizationUserSites.length > 0) {
      if (organizationUserSites.length == organizationUserSites.filter(x => x.siteRole == 'admin').length) {
        setAllSitesRole('admin');
      }
      else if (organizationUserSites.length == organizationUserSites.filter(x => x.siteRole == 'user').length) {
        setAllSitesRole('user');
      }
    }
  }

  const checkOrganizationSiteAlreadySelected = (site, index) => {
    var count = organizationUserSites.filter(a => a.siteId == site.id).length;
    var siteIndex = organizationUserSites.findIndex(a => a.siteId == site.id);

    if (count > 0) {
      if (index == siteIndex) {
        return false;
      }
      return true;
    }
    else {
      return false;
    }

  }

  const handleOnCancelButton = () => {
    if (window.history.state.idx == 0) {
      navigate('/apps/dashboards/sites');
    } else {
      navigate(-1);
    }
  }

  const isSiteRoleDisable = () => {
    if (userOrganizationData.accountId == '') {
      return true;
    } else if (isAllSitesAccess) {
      return false;
    }
    else if (organizationUserSites.length == 0) {
      return true
    } else {
      return false
    }
  }


  return (
    <Paper
      sx={{
        p: siteViewer == true ? 0 : 2,
        margin: siteViewer == true ? '' : 'auto',
        maxHeight: siteViewer == true ? '' : 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        borderRadius: siteViewer == true ? 0 : '',
      }}
      style={{
        width: siteViewer == true ? '100%' : '70%',
        'boxShadow': siteViewer == true ? '0px 0px 0px' : '',
      }}
      className={`items-center justify-center ${siteViewer == true ? "m-16" : "my-16"} sm:p-24 md:p-32`}
    >
      <Grid container className='items-center justify-center'>
        <div className="w-full">
          <Typography variant="h6" className="text-center mt-0 mb-24 font-semibold text-18 sm:text-24">
            {(customerId == null || customerId == undefined) ? " Add User " : "Update User"}
          </Typography>
          {isValide ? <p className="text-red mb-16 text-center">All fields are mandatory</p> : null}
          {isEmailValid ? null : <p className="text-red mb-16 text-center">Email is not valid</p>}
          {isPasswordComfirmPasswordValid ? null : <p className="text-red mb-16 text-center">Password and Confirm Password should be same</p>}
          {isValidPassword ? null : <p className="text-red mb-16 text-center">Password should contain a minimum of 8 characters and at least 1 uppercase letter, number, and special character.</p>}
          <div className='relative flex flex-row items-center w-full'>
            <div className='flex flex-col w-full'>
              <div className='mr-32'>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  name="name"
                  variant="outlined"
                  fullWidth
                  onChange={handleOnChangeValues}
                  className="mb-16"
                  value={customerData.name}
                  error={isValide && customerData.name == ''}
                />
              </div>
            </div>
            <div className='flex flex-col w-full'>
              <div className='ml-32'>
                <TextField
                  id="outlined-basic-email"
                  variant="outlined"
                  label="Email ID"
                  name="email"
                  fullWidth
                  onChange={handleOnChangeValues}
                  className="mb-16"
                  value={customerData.email}
                  error={(isValide && customerData.email == '') || !isEmailValid}
                />
              </div>
            </div>
          </div>
          <div className='relative flex flex-row items-center w-full'>
            <div className='flex flex-col w-full'>
              <div className='mr-32'>
                {role.includes('admin') ?
                  <FormControl className='mx-0 w-full' sx={{ m: 1 }}>

                    <InputLabel id="demo-simple-select-standard-label">Application role</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="applicationroleid"
                      value={customerData.applicationroleid}
                      onChange={handleOnChangeValues}
                      inputProps={{ 'aria-label': 'Without label' }}
                      label='Application role'
                      error={isValide && customerData.applicationroleid == ''}
                      disabled={!role.includes('admin')}
                    >
                      <MenuItem value={1}>Admin</MenuItem>
                      <MenuItem value={3}>User</MenuItem>
                    </Select>
                  </FormControl>
                  : null
                }

              </div>
            </div>
            <div className='flex flex-col w-full'>
              <div className='ml-32'>
                {role.includes('admin') &&
                  <div className='relative flex flex-row items-center w-full'>
                    <div className='flex flex-col w-full'>
                      <div className='mr-32'>
                        <div className='relative flex flex-row items-center w-full'>
                          <div className='flex flex-col w-full'>
                            <FormControl className="w-full">
                              <FormLabel id="demo-controlled-radio-buttons-group">Accounting Permission</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                value={customerData.accountingPermission}
                                name='accountingPermission'
                                onChange={handleOnChangeValues}
                                defaultValue={customerData.status == "active" ? "active" : "inactive"}
                              >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {(role.includes('admin') && customerData.applicationroleid == 1) &&
                            <div className='flex flex-col w-full'>
                              <FormControl className="w-full">
                                <FormLabel id="demo-controlled-radio-buttons-group">Upload Email Notifications</FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  value={isUploadMailEnable}
                                  name='accountingPermission'
                                  onChange={(e) => handleOnChangeUploadEnable(e)}
                                >
                                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                  <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                              </FormControl>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {customerData.applicationroleid != 1 &&
            <>
              <div className='relative flex flex-row items-center w-full'>
                <div className='flex flex-col w-full'>
                  <div className='mr-32'>
                    <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                      <InputLabel id="demo-simple-select-standard-label">Select Organization</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={userOrganizationData.accountId}
                        name="accountId"
                        onChange={(e) => handleOnChangeOrganizationDataValues(e)}
                        className='form-control'
                        label="Select Organization"
                      >
                        {accounts.map((account) =>
                          <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='flex flex-col w-full'>
                  <div className='ml-32'>
                    {(role.includes("admin") || role.includes("organisationadmin")) &&
                      <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label">Organization Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="accountRole"
                          value={userOrganizationData.accountRole}
                          onChange={(e) => handleOnChangeOrganizationDataValues(e)}
                          inputProps={{ 'aria-label': 'Without label' }}
                          label="Organization Role"
                        >
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="manager">Manager</MenuItem>
                          <MenuItem value="user">User</MenuItem>
                        </Select>
                      </FormControl>
                    }
                  </div>
                </div>
              </div>
              {userOrganizationData.accountRole != "admin" && <>
                <Box className='relative flex flex-row items-center w-full'>
                  <Grid container>
                    <Grid item xs={6} className='pt-12'>
                      <label> Sites</label>
                      <div className='relative flex flex-row items-center justify-between w-full'>
                        {(role.includes("admin") || role.includes("organisationadmin")) &&
                          <FormControl className='w-full'>
                            <RadioGroup row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name='automaticProjection'
                              value={isAllSitesAccess}
                              onChange={handleIsAllSitesAccess}
                            >
                              <FormControlLabel value={true} control={<Radio />} label="All Sites" />
                              <FormControlLabel value={false} control={<Radio />} label="Select Sites" />
                            </RadioGroup>
                          </FormControl>
                        }
                        {!isAllSitesAccess &&
                          <div className='w-full flex items-center'>
                            <Button
                              onClick={OnClickAddOrganizationUserSites}
                              variant="outlined"
                              startIcon={<AddIcon />}
                              style={{ 'padding': '5px 10px' }}
                              disabled={userOrganizationData.accountId == '' || userOrganizationData.accountRole == 'admin' || sites.length == organizationUserSites.length}
                            >
                              ADD
                            </Button>
                            <Button
                              onClick={OnClickAddAllOrganizationUserSites}
                              variant="outlined"
                              className='ml-8'
                              startIcon={<AddIcon />}
                              style={{ 'padding': '5px 10px' }}
                              disabled={userOrganizationData.accountId == '' || userOrganizationData.accountRole == 'admin' || sites.length == organizationUserSites.length}
                            >
                              ADD ALL
                            </Button>
                          </div>
                        }
                      </div>
                    </Grid>
                    <Grid item xs={6} >
                      <div className="ml-32">
                        <FormControl className='mx-0 w-full mb-14 mt-12'>
                          <InputLabel id="demo-simple-select-standard-label">All Sites Role </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name="accountRole"
                            value={allSitesRole}
                            onChange={(e) => handleOnChangeAllSitesRole(e)}
                            inputProps={{ 'aria-label': 'Without label' }}
                            label="All Sites Role"
                            disabled={isSiteRoleDisable()}
                          >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="user">User</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {!isAllSitesAccess &&
                  <>
                    {organizationUserSites.length > 0 ? organizationUserSites.map((organizationUserSite, i) => (
                      <div className='relative flex flex-row items-center w-full' key={i}>
                        <div className='flex flex-col mr-40 w-full'>
                          {i == 0 ? <label className='mr-4'>Site </label> : null}
                          <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                            <InputLabel id="demo-simple-select-standard-label">Select Site</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={organizationUserSite.siteId}
                              name="siteId"
                              onChange={(e) => handleOnOrganizationUserSiteChange(e, i)}
                              className='form-control'
                              label="Select Site"
                              error={!isAddSiteValid && organizationUserSite.siteId == ''}
                            >
                              {sites.map((site) =>
                                checkOrganizationSiteAlreadySelected(site, i) ? null :
                                  <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="flex flex-col mx-8 w-full">
                          {i == 0 ? <label>Role </label> : null}
                          <FormControl className='w-full mx-0' sx={{ m: 1 }}>
                            <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="siteRole"
                              value={organizationUserSite.siteRole}
                              onChange={(e) => handleOnOrganizationUserSiteChange(e, i)}
                              inputProps={{ 'aria-label': 'Without label' }}
                              label="Select Role"
                              error={!isAddSiteValid && organizationUserSite.siteRole == ''}
                            >
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="user">User</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="mt-8 flex flex-row">
                          <Tooltip title='Remove'>
                            <button onClick={() => OnClickRemoveOrganizationUserSites(i)}>
                              <span className="material-icons notranslate MuiIcon-root MuiIcon-colorAction MuiIcon-fontSizeMedium text-32 text-red muiltr-bded6y" aria-hidden="true">remove_circle_outline</span>
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    )) : null}
                  </>}
              </>}
            </>
          }
          {siteViewer == true ?
            <div className='relative py-12 flex flex-row items-center justify-center w-full'>
              <Button color="error" variant='outlined' onClick={() => handleClose()}>Cancel</Button>
              <Button color="primary" variant='contained' className='ml-8' onClick={() => handleCreateOrUpdate()}>{(customerId == null || customerId == undefined) ? "Create" : "Update"}</Button>
            </div>
            :
            <div className="flex flex-row items-center justify-center w-full">
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-16 py-8 w-full mr-32"
                aria-label="Submit"
                type="button"
                onClick={() => handleCreateOrUpdate()}
              >
                {(customerId == null || customerId == undefined) ? "Create" : "Update"}
              </Button>
              <Button
                variant='contained'
                color="primary"
                className="mx-auto mt-16 font-bold py-8 w-full ml-32"
                style={{ backgroundColor: '#E67260' }}
                aria-label="Register"
                type="button"
                onClick={handleOnCancelButton}
              >
                {"Cancel"}
              </Button>
            </div>
          }
        </div>
      </Grid>
    </Paper>
  );
}


export default CustomerCreateOrEdit;