import { useEffect, useState, useRef } from 'react';
import {
    TablePagination,
    Stack,
    Grid,
    Typography,
    Box,
    Tooltip
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CustomImage from 'app/shared-components/image/CustomImage';
import FolderStructureStyles from './styles/FolderStructureStyles';
import CommonStyles from '../styles/CommonStyles';
import KeyBoardConstants from '../constants/KeyBoardConstants';
import HandleKeyBoardSelect from '../common/HandleKeyBoardSelect';

function GridView(props) {
    const gridRef = useRef();
    const contentGridRef = useRef();
    const commonStyles = CommonStyles();
    const classes = FolderStructureStyles();

    useEffect(() => {
        if (gridRef.current != undefined) {
            let topHeight = gridRef.current.getBoundingClientRect().top + 120;
            gridRef.current.style.maxHeight = `calc(100vh - ${topHeight}px)`;
            gridRef.current.style.height = `calc(100vh - ${topHeight}px)`;
        }
    }, [])

    const maskFileName = (str) => {
        if (str.length > 20) {
            return str.substr(0, 8) + '...' + str.substr(str.length - 8, str.length);
        }
        return str;
    }

    const { fileIds, files, moveFilesList, copyFilesList, isSelected, imagesExtensionList, gridPage, rowsGridPerPage, setRowsGridPerPage, textExtensionList, updateFileIds, ctrlKey, isSetCtrlKey } = props;

    const [isFetching, setIsFetchIng] = useState(false);
    const [firstSelectedKey, setFirstSelectedKey] = useState('');
    var measurementLength = 140;
    useEffect(() => {
        setIsFetchIng(false);
        handleScroll();
    }, [])

    useEffect(() => {
        if (!isFetching) return;
        fetchData();
        setIsFetchIng(false);
    }, [isFetching])

    const handleScroll = () => {
        if (gridRef.current != undefined) {
            const element = gridRef.current;
            let lastScrollTop = 0;
            element.onscroll = (e) => {
                if (element.scrollTop < lastScrollTop) {
                    return;
                }
                lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;

                if (element.scrollTop + element.offsetHeight >= element.scrollHeight && !isFetching) {
                    setIsFetchIng(true);
                }
            }
        }
    }
    const handleKeyDown = (event) => {
        event.preventDefault();
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.shiftKey && charCode === '&') {
            handleShiftKeyPress(KeyBoardConstants.up)
        } else if (event.shiftKey && charCode === '(') {
            handleShiftKeyPress(KeyBoardConstants.down)
        } else if (event.shiftKey && charCode === '%') {
            handleShiftKeyPress(KeyBoardConstants.left)
        } else if (event.shiftKey && charCode === "'") {
            handleShiftKeyPress(KeyBoardConstants.right)
        } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
            if (isSelected) {
                props.onClickCopyFiles();
            }
        } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
            if (moveFilesList.length > 0) {
                props.moveFilesAction();
            }
            if (copyFilesList.length > 0) {
                props.pasteFilesAction();
            }
        } else if ((event.ctrlKey || event.metaKey) && charCode === 'a') {
            props.handelOnSelectAllClick();
        } else if ((event.ctrlKey || event.metaKey) && charCode === 'x') {
            if (isSelected) {
                props.onClickMoveFiles();
            }
        }

    }

    const handleShiftKeyPress = (type) => {
        if (contentGridRef.current && files.length > 0 && fileIds.length > 0) {
            var _fileIds = [...fileIds];
            var firstFieldValue = _fileIds[_fileIds.length - 1];
            var rowListLength = parseInt(contentGridRef.current.getBoundingClientRect().width / measurementLength);
            var noOfRows = Math.ceil(files.length / rowListLength);
            if (rowListLength && rowListLength > 0) {
                if (_fileIds.length == 1) {
                    setFirstSelectedKey(type);
                    isSetCtrlKey(false);
                }
                else if (ctrlKey === true) {
                    setFirstSelectedKey(type);
                    isSetCtrlKey(false);
                }
                else if (_fileIds.length > 1) {
                    if (firstSelectedKey == KeyBoardConstants.left || firstSelectedKey == KeyBoardConstants.right) {
                        if (type == KeyBoardConstants.up && Math.ceil(_fileIds[0].id / rowListLength) > 1) {
                            setFirstSelectedKey(type);
                        }
                        else if (type == KeyBoardConstants.down && Math.ceil(_fileIds[0].id / rowListLength) != noOfRows) {
                            setFirstSelectedKey(type);
                        }
                    }
                }
                //for ShiftUpArrow
                if (type === KeyBoardConstants.up) {
                    var res = HandleKeyBoardSelect.HandleUpKeyPress(type, files, _fileIds, firstSelectedKey, 'id', rowListLength, firstFieldValue);
                    updateFileIds(res.selectedFiles);
                    if (res.setFirstSelectedKey == true) {
                        setFirstSelectedKey(type);
                    }
                }
                //for ShiftDownArrow
                else if (type === KeyBoardConstants.down) {
                    var res = HandleKeyBoardSelect.HandleDownKeyPress(type, files, _fileIds, firstSelectedKey, 'id', rowListLength, noOfRows);
                    updateFileIds(res.selectedFiles);
                    if (res.setFirstSelectedKey == true) {
                        setFirstSelectedKey(type);
                    }
                }
                //for ShiftLeftArrow
                else if (type === KeyBoardConstants.left) {
                    var res = HandleKeyBoardSelect.HandleLeftKeyPress(type, files, _fileIds, firstSelectedKey, 'id');
                    updateFileIds(res.selectedFiles);
                }
                //for ShiftRightArrow
                else if (type === KeyBoardConstants.right) {
                    var res = HandleKeyBoardSelect.HandleRightKeyPress(type, files, _fileIds, firstSelectedKey, 'id');
                    updateFileIds(res.selectedFiles);
                }
            }
        }
    }

    const fetchData = () => {
        setTimeout(() => {
            setRowsGridPerPage(rowsGridPerPage + 40);
        }, 500);
    }

    const handleOnFilesClick = (event, a) => {
        if (a.type == 'folder') {
            props.handelOnFolderSelect(a);
        } else if (imagesExtensionList.includes(a.extension)) {
            props.onClickImage(event, a);
        } else if (textExtensionList.includes(a.extension)) {
            props.onClickTextFile(event, a);
        } else if (a.extension == 'csv') {
            props.onClickCSVFile(event, a);
        } else if (a.extension == 'pdf') {
            props.onClickPDFFile(event, a)
        } else if (a.extension == 'json' || a.extension == 'geojson') {
            props.onClickJSONFile(event, a)
        } else {
            props.handelOnOtherFilesClick(a)
        }
    }

    const getFilesIcon = (a) => {
        if (a.type == 'folder') {
            return (<FolderIcon style={{ width: 100, height: 100 }} className={commonStyles.primaryColor}></FolderIcon>)
        } else if (imagesExtensionList.includes(a.extension)) {
            return (<CustomImage src={a.fileUrl} style={{ width: 100, height: 100 }} ></CustomImage>)
        } else if (textExtensionList.includes(a.extension)) {
            if (a.extension == 'txt') {
                return (<img src='assets/images/icons/txticon.png' style={{ 'width': '100px', 'height': 'auto' }}></img>)
            } else {
                return (<img src='assets\images\icons\logicon.png' style={{ 'width': '100px', 'height': 'auto' }}></img>)
            }
        } else if (a.extension == 'csv') {
            return (<img src='assets\images\icons\csvicon.png' style={{ 'width': '100px', 'height': 'auto' }}></img>)
        } else if (a.extension == 'pdf') {
            return (<PictureAsPdfIcon style={{ width: 100, height: 100 }} ></PictureAsPdfIcon>)
        } else if (a.extension == 'json' || a.extension == 'geojson') {
            if (a.extension == 'json') {
                return (<img src='assets\images\icons\jsonicon.png' style={{ 'width': '100px', 'height': 'auto' }}></img>)
            } else {
                return (<img src='assets\images\icons\geojsonicon.png' style={{ 'width': '100px', 'height': 'auto' }}></img>)
            }
        } else {
            return (<InsertDriveFileIcon style={{ width: 100, height: 100 }} ></InsertDriveFileIcon>)
        }
    }

    const getFileTextColor = (a) => {
        if (isSelected && fileIds.filter(x => x.id == a.id).length > 0) {
            return "#ffffff";
        } else {
            if (a.type == 'folder') {
                return "#6CCCD7"
            } else {
                return "#000000"
            }
        }
    }

    return (
        <>
            <div ref={gridRef} onKeyDown={handleKeyDown} suppressContentEditableWarning={true} contentEditable={true} style={{
                'caretColor': 'transparent',
                "outline": "0px solid transparent",
                'overflowY': 'auto',
                'marginTop': '5px'
            }} spellCheck="false">
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container ref={contentGridRef} justifyContent="start" spacing={{ xs: 2, md: 3 }}>  {/* onmousedown={handleKeyDown} */}
                            {files.slice(gridPage * rowsGridPerPage, gridPage * rowsGridPerPage + rowsGridPerPage).map((a) => (
                                <Grid key={a.id} item
                                    style={{
                                        height: measurementLength,
                                        width: measurementLength,
                                    }}>
                                    <div>
                                        <Box sx={{ p: 2, border: (isSelected && fileIds.filter(x => x.id == a.id).length > 0) ? '1px' : '0px', backgroundColor: (isSelected && fileIds.filter(x => x.id == a.id).length > 0) ? '#69b4d936' : '' }}
                                            style={{ 'opacity': (moveFilesList.filter(x => x.fileKey == a.fileKey).length != 0 || copyFilesList.filter(x => x.fileKey == a.fileKey).length != 0) ? '0.5' : '1' }}
                                        >
                                            <Stack direction='column' className="justify-center align-center items-center"
                                                onClick={(e) => handleOnFilesClick(e, a)}
                                            >
                                                <>{getFilesIcon(a)}</>
                                                <Tooltip title={a.name} style={{ 'zIndex': '9999' }}>
                                                    <Typography className={`h5 ${classes.fileNameDisplayText}`} style={{ backgroundColor: (isSelected && fileIds.filter(x => x.id == a.id).length > 0) ? '#2c8af8' : '' }} color={getFileTextColor(a)}>
                                                        {maskFileName(a.name)}
                                                    </Typography>
                                                </Tooltip>
                                            </Stack>
                                        </Box>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

            </div >
            <TablePagination
                rowsPerPageOptions={[-1]}
                component="div"
                count={files.length}
                rowsPerPage={rowsGridPerPage}
                page={gridPage}
                onPageChange={props.onGridPageChange}
            />
        </>
    );
}

export default GridView;