import {
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';
import ReactJson from 'react-json-view'
import styled from "@emotion/styled";
import {Close} from "@mui/icons-material";

const TitlePanel = styled.div`
  display: flex;
`;

const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	cursor: pointer;
  }
`;

function JsonViewer(props) {

    const {jsonObject, onClose,name} = props;
    return (
        <div>
            <Dialog
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" open>

                <DialogTitle>
                    <TitlePanel className='flex flex-row justify-between'>
                        <div></div>
                        <CloseButton  onClick={onClose}><Close fontSize="inherit" /></CloseButton>
                    </TitlePanel>
                </DialogTitle>
                <DialogContent>
                   <ReactJson displayArrayKey={false} quotesOnKeys={false} displayDataTypes={false} enableClipboard={false} name={name} src={JSON.parse(jsonObject)} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default JsonViewer;