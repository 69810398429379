import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    useEffect,
    useState
} from "react";
import {
    Button,
    Typography,
    Box,
    Grid
} from '@mui/material';
import {
    useParams,
    useNavigate
} from 'react-router-dom';
import {
    getScanProcessDetails,
    resetScanProcessState,
    stopScanProcess,
    getScanProcessingStatusUpdateLog
} from "../store/scanProcessSlice";
import SERVICE_STATUS from "app/services/ServiceStatus";
import JsonViewer from "app/shared-components/dialogs/JsonViewer";
import { downloadLogFileForScan } from "../store/scanProcessSlice";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ScanProcessingStatusGrid from "./ScanProcessingStatusGrid";
import ScanProcessingHistoryGrid from "./ScanProcessingHistoryGrid";
import ScanProcessingCloudWatchLogGrid from "./ScanProcessingCloudWatchLogGrid";
import ScanProcessingDetailsGrid from "./ScanProcessingDetailsGrid";
import CachedIcon from '@mui/icons-material/Cached';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ScanProcessingStyles from "../styles/ScanProcessingStyles";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ScanProcessingControlCoordinateGrid from "./ScanProcessingControlCoordinateGrid";


function ScanProcessingDetails() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const scanProcessId = params.scanprocessid;

    const [activeTab, setActiveTab] = useState("details");
    const classes = ScanProcessingStyles();

    useEffect(() => {
        dispatch(resetScanProcessState())
        onPageload();
    }, [dispatch]);


    const onPageload = () => {
        dispatch(getScanProcessDetails(scanProcessId));
    }

    const handleRefresh = () => {
        onPageload();
    }

    const [showInput, setShowInput] = useState(false);
    const [showOutput, setShowOutPut] = useState(false);
    const [jsonViewerMessage, setJsonViewerMessage] = useState("")

    const onClose = () => {
        setShowInput(false);
        setShowOutPut(false);
        setShowHistoryEventDetail(false);
        setJsonObj(null);
        setJsonViewerMessage("");
    }

    const { scanCloudWatchLogs, scanProcessDetails, stopScanProcessState, scanProcessingHistory, scanProcessingTaskStatusHistories, sites, scanProcessingCoordinates } = useSelector(({ admin }) => admin.scanProcess);

    const toDateTime = (secs) => {
        var t = new Date(secs)
        return t.toLocaleString();
    }

    const toLocalDateTime = (utcDateTime) => {
        if (utcDateTime) {
            var date = new Date(utcDateTime);
            return date.toLocaleString();
        }
        else {
            return '';
        }
    }

    const handleStop = () => {
        dispatch(stopScanProcess(scanProcessDetails.scanId));
    }
    if (stopScanProcessState == SERVICE_STATUS.fulfilled) {
        dispatch(resetScanProcessState())
        onPageload();
    }


    const getStatusProcessingIcon = (status) => {
        if (status == 'SUCCEEDED') {
            return (
                <CheckBoxIcon style={{ fontSize: '16px', stroke: '#1d8102', strokeWidth: '1px' }} />
            )
        } else if (status == 'FAILED') {
            return (
                <CancelOutlinedIcon style={{ fontSize: '16px', stroke: '#d13212', strokeWidth: '1px' }} />
            )
        } else if (status == 'RUNNING') {
            return (
                <QueryBuilderIcon style={{ fontSize: '16px', stroke: '#0073bb', strokeWidth: '1px' }} />
            )
        } else if (status == 'ABORTED') {
            return (
                <RemoveCircleOutlineOutlinedIcon style={{ fontSize: '16px', stroke: '#687078', strokeWidth: '1px' }} />
            )

        } else {
            return (
                <div></div>
            )
        }
    }
    const getProcessingStatusColor = (status) => {
        if (status == 'SUCCEEDED') {
            return "#1d8102";
        } else if (status == 'FAILED') {
            return "#d13212";
        } else if (status == 'RUNNING') {
            return "#0073bb";
        } else if (status == 'ABORTED') {
            return "#687078"

        } else {
            return null;
        }
    }
    const handleOnDownloadClick = (id) => {
        dispatch(downloadLogFileForScan(id));
    }

    const handleOnDownloadUpdateLogClick = (id) => {
        var getScanProcessingStatusUpdateLogApi = getScanProcessingStatusUpdateLog(id);
        Promise.resolve(getScanProcessingStatusUpdateLogApi).then((response) => {
            if (response.status == 200 && response.data != '') {
                downloadScanProcessingStatusUpdateLog(response.data);
            }
        })
    }

    const convertUTCtoLacalDateTime = (date) => {
        try {
            return new Date(date).toLocaleString()
        } catch (error) {

        }
        return date;
    }

    const downloadScanProcessingStatusUpdateLog = (data) => {
        var rows = [
            ["Scan Id", "Scan Name", "Step", "Status", "Modified Time"]
        ];

        data.forEach((statusObj) => {
            var rowData = [];
            rowData.push(scanProcessDetails.scan ? scanProcessDetails.scan.id : '');
            rowData.push(scanProcessDetails.scan ? scanProcessDetails.scan.name : '');
            rowData.push(statusObj.statusType ? statusObj.statusType : "");
            rowData.push(statusObj.status ? statusObj.status : "");
            rowData.push(statusObj.receivedDateTime ? convertUTCtoLacalDateTime(statusObj.receivedDateTime) : statusObj.receivedTime ? statusObj.receivedTime : '');
            rows.push(rowData);
        })
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        let date = new Date();
        link.setAttribute("download", `${scanProcessDetails.scan.name}_Update_Log_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}.csv`);
        link.click();
        link.remove();
    }


    const handleOnCloseButton = () => {
        if (window.history.state.idx == 0) {
            navigate('/apps/dashboards/sites');
        } else {
            navigate(-1);
        }
    }

    const [showHistoryEventDetail, setShowHistoryEventDetail] = useState(false);
    const [jsonObj, setJsonObj] = useState(null);
    const handleOnInfoClick = (historyEvent) => {
        try {
            for (var key in historyEvent) {
                if (historyEvent[key] && typeof (historyEvent[key]) == "object") {
                    var historyEventObj = { ...historyEvent[key] };
                    historyEventObj = getInternalJsonObject(historyEventObj);
                    setJsonObj(JSON.stringify(historyEventObj));
                    setJsonViewerMessage('History Event Details');
                    setShowHistoryEventDetail(true);
                    break;
                }
            }
        } catch (e) {

        }
    }

    const getInternalJsonObject = (jsonObj) => {
        var _jsonObj = { ...jsonObj };
        for (var key in _jsonObj) {
            if (_jsonObj[key] && typeof (_jsonObj[key]) == "string") {
                if (isJsonObject(_jsonObj[key])) {
                    var parsedObj = JSON.parse(_jsonObj[key]);
                    if (Array.isArray(parsedObj)) {
                        _jsonObj[key] = getInternalArrayJsonObject(parsedObj);
                    } else {
                        var _orgJson = getInternalJsonObject(parsedObj);
                        _jsonObj[key] = _orgJson;
                    }
                }
            }
            else if (_jsonObj[key] && typeof (_jsonObj[key]) == "object") {
                if (Array.isArray(parsedObj)) {
                    _jsonObj[key] = getInternalArrayJsonObject(parsedObj);
                }
                else {
                    var _orgJson = getInternalJsonObject(_jsonObj[key]);
                    _jsonObj[key] = _orgJson;
                }
            }
        }
        return _jsonObj;
    }

    const getInternalArrayJsonObject = (arrayObj) => {
        var _arrayObj = [...arrayObj];
        _arrayObj.forEach((obj, index) => {
            if (typeof obj == "string") {
                if (isJsonObject(obj)) {
                    var parsedObj = JSON.parse(obj);
                    if (Array.isArray(parsedObj)) {
                        _arrayObj[index] = getInternalArrayJsonObject(parsedObj);
                    }
                    else {
                        var _orgJson = getInternalJsonObject(parsedObj);
                        _arrayObj[index] = _orgJson;
                    }
                }
            }
            else if (typeof (obj) == "object") {
                if (Array.isArray(parsedObj)) {
                    _arrayObj[index] = getInternalArrayJsonObject(parsedObj);
                }
                else {
                    var _orgJson = getInternalJsonObject(parsedObj);
                    _arrayObj[index] = _orgJson;
                }
            }
        });
        return _arrayObj;
    }

    const isJsonObject = (str) => {
        try {
            let jsonData = JSON.parse(str);
            if (typeof jsonData == 'object') {
                return true;
            }
            else {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    const handleOnShowInputClick = () => {
        setJsonViewerMessage("Input");
        setJsonObj(scanProcessDetails.inputValue);
        setShowInput(true);
    }

    const handleOnShowOutPutClick = () => {
        setJsonViewerMessage("Output");
        setJsonObj(scanProcessDetails.outputValue);
        setShowOutPut(true);
    }

    const [showAlignToControlCoordinateTab, setShowAlignToControlCoordinateTab] = useState(false);

    useEffect(() => {
        if (scanProcessingCoordinates.length > 0) {
            setShowAlignToControlCoordinateTab(true);
        } else {
            setShowAlignToControlCoordinateTab(false)
        }
    }, [scanProcessingCoordinates])


    return (
        <div className="w-full px-24 -mx-4">
            <Box>
                <Grid container>
                    <Grid item xs={6} >
                        <div className="flex flex-row items-center justify-left">
                            <Typography className="h1 text-30 my-16 font-700 text-center" color="textPrimary">
                                {scanProcessDetails.scan ? scanProcessDetails.scan.name : ''}
                            </Typography>
                            {scanProcessDetails.processingDisplayStatus &&
                                <>
                                    <Typography
                                        className="flex flex-row items-center"
                                        style={{
                                            color: getProcessingStatusColor(scanProcessDetails.processingDisplayStatus)
                                        }}
                                    >
                                        <Typography className="ml-4"> {getStatusProcessingIcon(scanProcessDetails.processingDisplayStatus)}</Typography>&nbsp;
                                        <Typography className="mt-2" style={{
                                            'fontSize': '14px',
                                            'fontWeight': '600'
                                        }}>
                                            {scanProcessDetails.processingDisplayStatus ? `${scanProcessDetails.processingDisplayStatus.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                                                return letter.toUpperCase();
                                            })}` : ''}
                                        </Typography>
                                    </Typography>
                                </>
                            }

                        </div>
                    </Grid>


                    <Grid item xs={6} >
                        <div className="justify-end items-center flex my-16 ">
                            {Object.keys(scanProcessDetails).length > 0 &&
                                <>
                                    {
                                        (scanProcessDetails.status == 'RUNNING' || scanProcessDetails.status == '') ?
                                            <Button
                                                startIcon={<CachedIcon />}
                                                className='mr-12'
                                                style={{
                                                    'fontStyle': 'normal',
                                                    'fontWeight': '400',
                                                    'fontSize': '14px'
                                                }}
                                                onClick={() => handleRefresh()}
                                            >
                                                Refresh
                                            </Button>
                                            : null
                                    }
                                    <Button
                                        className="mr-12"
                                        startIcon={<InputOutlinedIcon />}
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '100px',
                                            background: '#6CCCD7',
                                            border: '1px solid #6CCCD7',
                                            borderRadius: '50px',
                                            color: '#ffffff',
                                            padding: '2px 15px'
                                        }}
                                        onClick={() => handleOnShowInputClick()}
                                    >Input</Button>
                                    {(scanProcessDetails.status != 'RUNNING' && scanProcessDetails.status != '') ?
                                        <Button
                                            className="mr-12"
                                            startIcon={<InputOutlinedIcon />}
                                            style={{
                                                boxSizing: 'border-box',
                                                width: '100px',
                                                background: '#6CCCD7',
                                                border: '1px solid #6CCCD7',
                                                borderRadius: '50px',
                                                color: '#ffffff',
                                                padding: '2px 15px'
                                            }}
                                            onClick={() => handleOnShowOutPutClick()}
                                        >Output</Button>
                                        : null
                                    }
                                    <Button
                                        className="mr-12"
                                        startIcon={<DownloadForOfflineIcon />}
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '100px',
                                            background: '#6CCCD7',
                                            border: '1px solid #6CCCD7',
                                            borderRadius: '50px',
                                            color: '#ffffff',
                                            padding: '2px 15px'
                                        }}
                                        onClick={() => handleOnDownloadClick(scanProcessDetails.id)}
                                    >Download</Button>
                                    <Button
                                        className="mr-12"
                                        startIcon={<DownloadForOfflineIcon />}
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '130px',
                                            background: '#6CCCD7',
                                            border: '1px solid #6CCCD7',
                                            borderRadius: '50px',
                                            color: '#ffffff',
                                            padding: '2px 15px'
                                        }}
                                        onClick={() => handleOnDownloadUpdateLogClick(scanProcessDetails.id)}
                                    >Update Log</Button>
                                    {(scanProcessDetails.status == 'RUNNING' || scanProcessDetails.status == '') ?
                                        <Button className='mr-12'
                                            startIcon={<StopCircleIcon />}
                                            onClick={() => handleStop()}
                                            style={{
                                                boxSizing: 'border-box',
                                                width: '100px',
                                                background: '#E67260',
                                                border: '1px solid #E67260',
                                                borderRadius: '50px',
                                                color: '#ffffff',
                                                padding: '2px 15px'
                                            }}
                                        >
                                            Stop
                                        </Button> :
                                        null
                                    }
                                </>
                            }
                            <Button variant="outlined"
                                style={{
                                    boxSizing: 'border-box',
                                    width: '100px',
                                    background: '#E67260',
                                    border: '1px solid #E67260',
                                    borderRadius: '50px',
                                    color: '#ffffff',
                                    padding: '2px 15px'
                                }}
                                onClick={() => handleOnCloseButton()}
                                startIcon={<HighlightOffIcon />}>
                                Close
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Box>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="flex">
                                <div className={`${activeTab != "details" ? classes.inactive : classes.active} mr-8`} >
                                    <Button
                                        sx={{ width: "150px", borderRadius: 0 }}
                                        className={`${activeTab == "details"
                                            ? classes.activeButton
                                            : classes.inactiveButton
                                            }`}
                                        onClick={() => {
                                            setActiveTab("details");
                                        }}
                                    >
                                        <div sx={{ width: "150px" }}>
                                            <Typography style={{ fontWeight: "600", color: "#000" }}>
                                                Details
                                            </Typography>
                                        </div>
                                    </Button>
                                </div>
                                <div className={`${activeTab != "processingStatus" ? classes.inactive : classes.active} mr-8`} >
                                    <Button
                                        sx={{ width: "150px", borderRadius: 0 }}
                                        className={`${activeTab == "processingStatus"
                                            ? classes.activeButton
                                            : classes.inactiveButton
                                            }`}
                                        onClick={() => {
                                            setActiveTab("processingStatus");
                                        }}
                                    >
                                        <div sx={{ width: "150px" }}>
                                            <Typography style={{ fontWeight: "600", color: "#000" }}>
                                                Processing Status
                                            </Typography>
                                        </div>
                                    </Button>
                                </div>
                                <div className={`${activeTab != "history" ? classes.inactive : classes.active} mr-8`} >
                                    <Button
                                        sx={{ width: "150px", borderRadius: 0 }}
                                        className={`${activeTab == "history"
                                            ? classes.activeButton
                                            : classes.inactiveButton
                                            }`}
                                        onClick={() => {
                                            setActiveTab("history");
                                        }}
                                    >
                                        <div style={{ width: "150px" }}>
                                            <Typography style={{ fontWeight: "600", color: "#000" }}>
                                                History
                                            </Typography>
                                        </div>
                                    </Button>
                                </div>
                                <div className={`${activeTab != "cloudWatchLogs" ? classes.inactive : classes.active} mr-8`} >
                                    <Button
                                        sx={{ width: "150px", borderRadius: 0 }}
                                        className={`${activeTab == "cloudWatchLogs"
                                            ? classes.activeButton
                                            : classes.inactiveButton
                                            }`}
                                        onClick={() => {
                                            setActiveTab("cloudWatchLogs");
                                        }}
                                    >
                                        <div sx={{ width: "150px" }}>
                                            <Typography style={{ fontWeight: "600", color: "#000" }}>
                                                CloudWatch Logs
                                            </Typography>
                                        </div>
                                    </Button>
                                </div>
                                {showAlignToControlCoordinateTab &&
                                    <div className={`${activeTab != "controlCoordinate" ? classes.inactive : classes.active}`} >
                                        <Button
                                            sx={{ width: "150px", borderRadius: 0 }}
                                            className={`${activeTab == "controlCoordinate"
                                                ? classes.activeButton
                                                : classes.inactiveButton
                                                }`}
                                            onClick={() => {
                                                setActiveTab("controlCoordinate");
                                            }}
                                        >
                                            <div sx={{ width: "150px" }}>
                                                <Typography style={{ fontWeight: "600", color: "#000" }}>
                                                    Control Coordinate
                                                </Typography>
                                            </div>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                </Box>
                <Box sx={{ backgroundColor: "#EFEEEE" }} className="sm:p-24 md:p-32">
                    {activeTab == "details" &&
                        <ScanProcessingDetailsGrid
                            scanProcessDetails={scanProcessDetails}
                            toLocalDateTime={toLocalDateTime}
                            getProcessingStatusColor={getProcessingStatusColor}
                            getStatusProcessingIcon={getStatusProcessingIcon}
                        />}

                    {activeTab == "processingStatus" &&
                        <ScanProcessingStatusGrid
                            scanProcessingTaskStatusHistories={scanProcessingTaskStatusHistories}
                            toLocalDateTime={toLocalDateTime}
                        />}

                    {activeTab == "history" &&
                        <ScanProcessingHistoryGrid
                            scanProcessingHistory={scanProcessingHistory}
                            toDateTime={toDateTime}
                            handleOnInfoClick={handleOnInfoClick}
                        />}
                    {activeTab == "cloudWatchLogs" &&
                        <ScanProcessingCloudWatchLogGrid
                            scanCloudWatchLogs={scanCloudWatchLogs}
                            toDateTime={toDateTime}
                        />}
                    {activeTab == "controlCoordinate" &&
                        <ScanProcessingControlCoordinateGrid
                            scanProcessingCoordinates={scanProcessingCoordinates}
                            toDateTime={toDateTime}
                        />}
                </Box>
            </Box>
            {
                (showInput || showOutput || showHistoryEventDetail) &&
                <JsonViewer name={jsonViewerMessage} jsonObject={jsonObj} onClose={onClose}></JsonViewer>
            }
        </div >
    );
}

export default ScanProcessingDetails;