import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TfiImport } from "react-icons/tfi";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

function DropZone({ handleFileChange, fileName }) {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: { "text/csv": [".csv"] },
    });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    console.log(acceptedFiles);
    if (acceptedFiles.length > 0) handleFileChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Box
      style={{
        backgroundColor: isDragActive ? "rgb(89, 190, 201, 0.2)" : "#FBFCF8",
      }}
      sx={{
        border: fileName
          ? "2px dashed #59bec9 !important"
          : "2px dashed #eeeeee !important",
        padding: "24px",
        borderRadius: "10px",
        textAlign: "center",
        transition: "border-color 0.2s ease-in-out",
        width: "100%",
        "&:hover": {
          borderColor: "#3aa6b1",
        },
      }}
      {...getRootProps({
        className: "dropzone",
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    >
      <input {...getInputProps()} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "50px",
            height: "50px",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              backgroundColor: "#59bec9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
            }}
            animate={{
              scale: isHovered || isDragActive ? 1.2 : 1,
            }}
            transition={{ duration: 0.3 }}
          >
            <TfiImport size={30} color="white" />
          </motion.div>
        </Box>
        <Typography
          variant="body1"
          sx={{
            cursor: "pointer",
            fontSize: "1.4rem",
            fontWeight: 550,
            marginTop: "8px",
            color: "black",
          }}
        >
          Drag & drop files here, or{" "}
          <span style={{ textDecoration: "underline" }}>browse</span> to select
          a csv file
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "black", fontWeight: 300 }}>
          Supports only CSV file types{" "}
        </Typography>
      </Box>
    </Box>
  );
}

export default DropZone;
