import * as turf from "@turf/turf";
import MapBoxCommon from "../MapBoxCommon";

class MapboxVector extends MapBoxCommon {
    constructor(viewer, vectorInfo, scanId) {
        super();
        this.viewer = viewer;
        this.vectorInfo = vectorInfo;
        this.scanId = scanId;
        this.id = this.createUUID();
        this.labelsId = this.createUUID();
        this.showingVector = false;
        this.styleLoadEvent = this.onStyleLoad.bind(this);
    }

    showVector() {
        this.addMapVectorTiles();
        // if (!this.viewer.getMaxBounds()) {
        this.viewer.fitBounds(this.vectorInfo.maxBounds, { animate: false });
        // this.viewer.setMaxBounds(this.vectorInfo.maxBounds);

        // this.viewer.setZoom(this.vectorInfo.minZoom == 1 ? 16 : this.vectorInfo.minZoom);
        this.viewer.setCenter(this.getCenter());
        // }
        this.showingVector = true;
    }

    hideVector() {
        this.remove();
        this.showingVector = false;
        // this.viewer.setMaxBounds(null);
    }


    onStyleLoad(e) {
        this.addMapVectorTiles();
    }

    zoomIn() {
        this.viewer.setZoom(this.viewer.getZoom() + 1);
        // this.viewer.flyTo({
        //     zoom: this.viewer.getZoom() + 1
        // });
    }

    zoomOut() {
        this.viewer.setZoom(this.viewer.getZoom() - 1);
        // this.viewer.flyTo({
        //     zoom: this.viewer.getZoom() - 1
        // });
    }

    getCenter() {
        var features = turf.points(this.vectorInfo.maxBounds);
        var centerPoint = turf.center(features);
        return centerPoint.geometry.coordinates;
    }

    addMapVectorTiles() {
        if (this.viewer.getLayer(this.id) != undefined) {
            this.viewer.removeLayer(this.id);
        }
        if (this.viewer.getLayer(this.labelsId) != undefined) {
            this.viewer.removeLayer(this.labelsId);
        }

        if (this.viewer.getSource(this.id) != undefined) {
            this.viewer.removeSource(this.id);
        }
        this.viewer.addSource(this.id, {
            'type': 'vector',
            'tiles': [
                `${this.vectorInfo.vectorPath}{z}/{x}/{y}.pbf`
            ],
            'bounds': [this.vectorInfo.maxBounds[0][0], this.vectorInfo.maxBounds[0][1], this.vectorInfo.maxBounds[1][0], this.vectorInfo.maxBounds[1][1]],
            'minzoom': this.vectorInfo.minZoom,
            'maxzoom': this.vectorInfo.maxZoom
        });
        this.viewer.addLayer(
            {
                'id': this.id,
                'type': 'line',
                'source': this.id,
                'source-layer': `contours`,
                //'source-layer': this.scanId == 129 ? `contours_118` : `contours_79`,
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#C46A51',
                    'line-width': 1
                }
            }
        );

        // Adding contour elevation labels to map
        this.viewer.addLayer({
            'id': this.labelsId,
            'type': 'symbol',
            'source': this.id,
            'source-layer': `contours`,
            //'source-layer': this.scanId == 129 ? `contours_118` : `contours_79`,
            'layout': {
                'symbol-placement': 'line',
                'text-justify': 'center',
                'text-field' : [
                    'case',
                    ['==' , ['number-format',['get','z'],{ 'min-fraction-digits': 1}],'-0.0'],'0.0',
                    ['number-format',['get','z'],{ 'min-fraction-digits': 1}]
                ] 
            }
        });
    }

    setFitBounds() {
        this.viewer.fitBounds(this.vectorInfo.maxBounds);
        // this.viewer.setMaxBounds(this.vectorInfo.maxBounds);
    }

    remove() {
        if (this.viewer.getLayer(this.id) != undefined) {
            this.viewer.removeLayer(this.id);
        }
        if (this.viewer.getLayer(this.labelsId) != undefined) {
            this.viewer.removeLayer(this.labelsId);
        }

        if (this.viewer.getSource(this.id) != undefined) {
            this.viewer.removeSource(this.id);
        }
    }
}

export default MapboxVector;