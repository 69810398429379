import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip,
  TableSortLabel,
  Box,
} from "@mui/material";
import StaticStyles from "../styles/StaticStyles";
import CommonSorting from "./CommonSorting";

function CommonTable(props) {
  const { renderData, dataConfig, defaultOrder, defaultOrderBy, screen } =
    props;
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    if (defaultOrderBy) {
      setOrderBy(defaultOrderBy);
    }
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // eslint-disable-next-line complexity
  const RenderHeadCell = (headCell) => {
    if (headCell.display) {
      if (headCell.isSortable) {
        return (
          <TableCell
            key={headCell.id}
            className={headCell.className ? headCell.className : ""}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "13px",
              color: "#D6D3CD !important",
            }}
          >
            <b>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={handleRequestSort(headCell.id)}
              >
                {headCell.customHeadCell === true
                  ? headCell.headContent(headCell)
                  : headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" style={StaticStyles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </b>
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={headCell.id}
            className={headCell.className ? headCell.className : ""}
            width={headCell.width ? headCell.width : ""}
            style={{
              fontSize: "13px",
            }}
          >
            <b>
              {headCell.customHeadCell
                ? headCell.headContent(headCell)
                : headCell.label}
            </b>
          </TableCell>
        );
      }
    }
  };

  const RenderRowCell = (row) => {
    return (
      <>
        {dataConfig?.map((headCell, index) => (
          <>
            {headCell.display && (
              <Tooltip
                title={
                  ["scanName", "siteId", "ownerOrganization", "step"].includes(
                    headCell.id
                  )
                    ? headCell.contentType === "text"
                      ? row[headCell.id]
                      : headCell?.content(row)
                    : ""
                }
              >
                <TableCell
                  key={index}
                  className={headCell.overflow ? headCell.overflow : ""}
                  sx={{
                    fontSize: "13px",
                    fontWeight: headCell.id === "scanName" ? "bold" : "normal",
                  }}
                >
                  {(!row.hideColumns ||
                    !row.hideColumns.includes(headCell.id)) && (
                    <>
                      {headCell.contentType === "text"
                        ? row[headCell.id]
                        : headCell.content(row)}
                    </>
                  )}
                </TableCell>
              </Tooltip>
            )}
          </>
        ))}
      </>
    );
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ tableLayout: screen === "processing" && "fixed" }}
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#CFCDC9",
                transparency: "85%",
              }}
            >
              {dataConfig.map((headCell) => (
                <>{RenderHeadCell(headCell)}</>
              ))}
            </TableRow>
          </TableHead>
          {!renderData || renderData?.length === 0 ? (
            <TableRow>
              <TableCell
                className="text-center"
                style={{ fontWeight: "600" }}
                colSpan={9}
              >
                No Records
              </TableCell>
            </TableRow>
          ) : (
            <>
              {CommonSorting.stableSort(renderData, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableBody key={index}>
                    <TableRow key={index}>{RenderRowCell(row)}</TableRow>
                  </TableBody>
                ))}
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={renderData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
export default CommonTable;
