import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVICE_STATUS from "app/services/ServiceStatus";
import axios from "axios";
import APP_CONFIG from "../../AppConfig";

export const verifyOTP = createAsyncThunk(
  "auth/verification/verifyOTP",
  async (_data) => {
    const url = APP_CONFIG.api + `v1/user/validateOTP`;
    const response = await axios.post(url, _data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    var data = await response.data;
    return data;
  }
);
export const resendOtp = createAsyncThunk(
  "auth/verification/resendOTP",
  async () => {
    const url = APP_CONFIG.api + `v1/user/resendOtp`;
    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  }
);

export const sendOtp = createAsyncThunk(
  "auth/verification/sendOTP",
  async (_data, { dispatch, getState }) => {
    if (
      getState().auth.verification.verifyToken.length == 0 &&
      getState().auth.verification.isSendOtp
    ) {
      dispatch(setIsSendOtp(false));

      const url = APP_CONFIG.api + `v1/user/resendOtp`;
      const response = axios.post(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      Promise.resolve(response)
        .then((response) => {
          if (response.status == 200) {
            dispatch(setVerificationToken(response.data));
            dispatch(setIsSendOtp(true));
          }
        })
        .catch(() => {
          dispatch(setIsSendOtp(true));
        });
    }
  }
);

const initialState = {
  isVerified: true,
  isSendOtp: true,
  verifyToken: [],
  otpvalidate: false,
  otpValidatestate: "",
  isRestrictMailSending: false,
};

const verificationSlice = createSlice({
  name: "auth/verification",
  initialState,
  reducers: {
    setVerificationToken: (state, action) => {
      state.verifyToken.push(action.payload);
    },
    setIsVerified: (state, action) => {
      state.isVerified = action.payload;
    },
    setIsSendOtp: (state, action) => {
      state.isSendOtp = action.payload;
    },
    setOtpValidate: (state, action) => {
      state.otpvalidate = action.payload;
    },
    setOtpValidateState: (state, action) => {
      state.otpValidatestate = action.payload;
    },
    setIsRestrictMailSending: (state, action) => {
      state.isRestrictMailSending = action.payload;
    },
  },
  extraReducers: {
    [verifyOTP.fulfilled]: (state, action) => {
      state.otpvalidate = action.payload.otpvalid;
      state.verifyToken = action.payload.tokens;
      state.otpValidatestate = SERVICE_STATUS.fulfilled;
    },
    [verifyOTP.rejected]: (state, action) => {
      state.otpvalidate = action.payload;
      state.otpValidatestate = SERVICE_STATUS.rejected;
    },
    [resendOtp.fulfilled]: (state, action) => {
      state.verifyToken.push(action.payload);
    },
  },
});

export const {
  setVerificationToken,
  setIsVerified,
  setOtpValidate,
  setOtpValidateState,
  setIsSendOtp,
  setIsRestrictMailSending,
} = verificationSlice.actions;

export default verificationSlice.reducer;

