import * as proj4 from "proj4";
import COORDINATE_SYSTEM from "../admin/store/CoordinateSystem"

const utmProjection = "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
const wgsProjection = "+proj=longlat +datum=WGS84 +no_defs +type=crs";

export const Convert3Dto2D = (easting, northing, elevation, epsg) => {
    let coordinateSystem = GetCoordinateSystemByEpsg(epsg);
    const result = proj4.default(coordinateSystem ? coordinateSystem : utmProjection, wgsProjection, [easting, northing, elevation]);
    if (!isNaN(result[0]) && !isNaN(result[1]) && !isNaN(result[2])) {
        const longitude = Number(result[0].toFixed(8));
        const latitude = Number(result[1].toFixed(8));
        const pelevation = Number(result[2].toFixed(3));
        let point = {
            longitude: longitude,
            latitude: latitude,
            elevation: pelevation
        }
        return point;
    }
    else {
        return null;
    }
}

export const Convert2Dto3D = (longitude, latitude, elevation, epsg, pointCloud) => {
    let coordinateSystem = GetCoordinateSystemByEpsg(epsg);
    const result = proj4.default(wgsProjection, coordinateSystem ? coordinateSystem : utmProjection, [longitude, latitude]);
    if (!isNaN(result[0]) && !isNaN(result[1])) {
        const easting = Number(result[0].toFixed(3));
        const northing = Number(result[1].toFixed(3));
        const pelevation = GetElevationFromPointCloud(pointCloud);
        let point = {
            easting: easting,
            northing: northing,
            elevation: (elevation && elevation != 0) ? elevation : pelevation
        }
        return point;
    }
    else {
        return null;
    }
}

const GetCoordinateSystemByEpsg = (epsg) => {
    var projection = "";
    Object.keys(COORDINATE_SYSTEM).forEach(key => {
        let coordinateSystem = COORDINATE_SYSTEM[key];
        if (coordinateSystem.epsg == epsg) {
            projection = coordinateSystem.value
        }
    });
    return projection;
}
const GetCoordinateSystemEpsg = (projection) => {
    var epsg = 6340;
    if (projection) {
        let coordinateSystem = COORDINATE_SYSTEM[projection];
        epsg = coordinateSystem.epsg
    }
    return epsg;
}

export const Get2DMeasurements = (pointMeasures, distanceMeasures, areaMeasures, angleMeasures, pointClouds) => {
    var scans = [];
    pointClouds.forEach(pointCloud => {
        var scan = {
            id: pointCloud.scanId,
            epsg: pointCloud.epsg
        }
        scans.push(scan);
    });

    var measurements = [];
    // pointMeasures.forEach(pointMeasure => {
    //     var measurement = {
    //         longitude: pointMeasure.coords.lng,
    //         latitude: pointMeasure.coords.lat,
    //         elevation: pointMeasure.elevation,
    //         pointCloud: GetPointCloudData(pointMeasure.coords.lng, pointMeasure.coords.lat, pointClouds),
    //         name: 'Point'
    //     }
    //     measurements.push(measurement);
    // });

    // distanceMeasures.forEach(distanceMeasure => {
    //     var points = [];
    //     distanceMeasure.distancePoints.forEach(distancePoint => {
    //         const point = {
    //             longitude: distancePoint.lng,
    //             latitude: distancePoint.lat,
    //             elevation: distancePoint.elevation,
    //             pointCloud: GetPointCloudData(distancePoint.lng, distancePoint.lat, pointClouds)
    //         }
    //         points.push(point);
    //     });
    //     var measurement = {
    //         points: points,
    //         name: 'Distance'
    //     }
    //     measurements.push(measurement);
    // });

    // areaMeasures.forEach(areaMeasure => {
    //     var points = [];
    //     areaMeasure.areaPoints.forEach(areaPoint => {
    //         const point = {
    //             longitude: areaPoint.lng,
    //             latitude: areaPoint.lat,
    //             elevation: areaPoint.elevation,
    //             pointCloud: GetPointCloudData(areaPoint.lng, areaPoint.lat, pointClouds)
    //         }
    //         points.push(point);
    //     });
    //     var measurement = {
    //         points: points,
    //         name: 'Area'
    //     }
    //     measurements.push(measurement);
    // });

    // angleMeasures.forEach(angleMeasure => {
    //     var points = [];
    //     angleMeasure.anglePoints.forEach(anglePoint => {
    //         const point = {
    //             longitude: anglePoint.lng,
    //             latitude: anglePoint.lat,
    //             elevation: anglePoint.elevation,
    //             pointCloud: GetPointCloudData(anglePoint.lng, anglePoint.lat, pointClouds)
    //         }
    //         points.push(point);
    //     });
    //     var measurement = {
    //         points: points,
    //         name: 'Angle'
    //     }
    //     measurements.push(measurement);
    // });

    var data = {
        scans: scans,
        measurements: measurements
    };
    return data;
}

export const Get3DMeasurements = (viewer, pointClouds) => {
    var scans = [];
    pointClouds.forEach(pointCloud => {
        var scan = {
            id: pointCloud.scanId
        }
        scans.push(scan);
    });
    var measurements = []

    // let epsg = GetCoordinateSystemEpsg(coordinateSystem);
    // viewer.scene.measurements.forEach(measure => {
    //     if (measure.showDistances && !measure.showArea) {
    //         var points = [];
    //         measure.points.forEach(distancePoint => {
    //             let easting = distancePoint.position.x;
    //             let northing = distancePoint.position.y;
    //             let elevation = distancePoint.position.z;
    //             if (points.filter(x => x.easting == easting && x.northing == northing && x.elevation == elevation).length == 0) {
    //                 const point = {
    //                     easting: easting,
    //                     northing: northing,
    //                     elevation: elevation,
    //                     epsg: epsg
    //                 }
    //                 points.push(point);
    //             }
    //         });
    //         var measurement = {
    //             points: points,
    //             name: 'Distance'
    //         }
    //         measurements.push(measurement);
    //     }
    //     else if (measure.showAngles) {
    //         var points = [];
    //         measure.points.forEach(anglePoint => {
    //             let easting = anglePoint.position.x;
    //             let northing = anglePoint.position.y;
    //             let elevation = anglePoint.position.z;
    //             if (points.filter(x => x.easting == easting && x.northing == northing && x.elevation == elevation).length == 0) {
    //                 const point = {
    //                     easting: easting,
    //                     northing: northing,
    //                     elevation: elevation,
    //                     epsg: epsg
    //                 }
    //                 points.push(point);
    //             }
    //         });
    //         var measurement = {
    //             points: points,
    //             name: 'Angle'
    //         }
    //         measurements.push(measurement);
    //     }
    //     else if (measure.showArea) {
    //         var points = [];
    //         measure.points.forEach(areaPoint => {
    //             let easting = areaPoint.position.x;
    //             let northing = areaPoint.position.y;
    //             let elevation = areaPoint.position.z;
    //             if (points.filter(x => x.easting == easting && x.northing == northing && x.elevation == elevation).length == 0) {
    //                 const point = {
    //                     easting: easting,
    //                     northing: northing,
    //                     elevation: elevation,
    //                     epsg: epsg
    //                 }
    //                 points.push(point);
    //             }
    //         });
    //         var measurement = {
    //             points: points,
    //             name: 'Area'
    //         }
    //         measurements.push(measurement);
    //     }
    //     else if (measure.closed && measure.maxMarkers == 1 &&
    //         !measure.showHeight && measure.points.length == 1) {
    //         var measurement = {
    //             easting: measure.points[0].position.x,
    //             northing: measure.points[0].position.y,
    //             elevation: measure.points[0].position.z,
    //             epsg: epsg,
    //             name: 'Point'
    //         }
    //         measurements.push(measurement);
    //     }
    // });
    var data = {
        scans: scans,
        measurements: measurements
    };
    return data;
}

const GetElevationFromPointCloud = (pointCloud) => {
    try {
        if (pointCloud.getAttributes().attributes.filter(x => x.name == 'position').length > 0) {
            const range = pointCloud.getAttributes().attributes.filter(x => x.name == 'position')[0].range;
            if (range.length > 1) {
                return ((range[0][2] + range[1][2]) / 2)
            }
            else if (range.length == 1) {
                return range[0][3];
            }
        }
    }
    catch (error) {

    }
    return null;
}

export const GetPointCloudData = (longitude, latitude, pointClouds) => {
    var isPointValid = false;
    var data = null
    pointClouds.forEach(pointCloud => {
        if (!isPointValid) {
            if (pointCloud.checkPointValid(longitude, latitude)) {
                isPointValid = true;
                data = {
                    scanId: pointCloud.scanId,
                    epsg: pointCloud.epsg
                }
            }
        }
    });

    return data;
}