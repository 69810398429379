const UPLOAD_TYPE = {
  scan: "scan",
  site: "site",
  siteprofileimage: "siteprofileimage",
  userprofileimage: "userprofileimage",
  accountprofileimage: "accountprofileimage",
  scanraw: "scanraw",
  scanpanoramas: "scanpanoramas",
  scanpotree: "scanpotree",
  scanpointclouds: "scanpointclouds",
  scanpanoramapose: "panorama_poses.txt",
  scangpspanoramapose: "gps_parsing.csv",
  scanuavimages: "scanuavimages",
  scanlidar: "scanlidar",
  sitegeoref: "sitegeoref",
  sitecad: "sitecad",
  siteplans: "siteplans",
  basestation: "basestation",
  devicefile: "devicefile",
  templatefile: "templatefile",
  encryptedfile: "encryptedfile",
  deviceconfigfilename: "config_latest.json",
  deviceprivatekeyfilename: "private_key.pem",
  devicepublickeyfilename: "public_key.pem",
  configfile: "configfile",
  configfilename: "config.json",
  sitefiles: "sitefiles",
  projectfiles: "projectfiles",
  project: "project",
  scanortho: "scanortho",
  scanoutputs: "scanoutputs",
  track: "track",
  plscaddfile: "plscaddfile",
  plscaddkey: "plscaddkey",
};
export default UPLOAD_TYPE;
