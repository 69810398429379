import {
    useEffect,
    useState
} from "react";
import {
    useSelector,
    useDispatch
} from "react-redux";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Box,
    TableSortLabel,
    TablePagination,
    TableBody,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Button,
    Dialog,
    DialogContent
} from "@mui/material";
import { getSiteAccessUsers, setSiteAccessToUser, removeSiteAccessToUser } from "../admin/store/siteUsersSlice";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import SiteAccess from "./SiteAccess";
import ShareIcon from '@mui/icons-material/Share';
import { loadAllAdminAccounts } from "../admin/store/accountSlice";
import InviteUser from "../admin/InviteUser";
import { setinviteUserState, inviteUser } from "../admin/store/customerSlice";
import {
    createCustomer,
    setcreateCustomerState,
    setcreateCustomerFailure
} from "../admin/store/customerSlice";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import SERVICE_STATUS from "app/services/ServiceStatus";
import CustomerCreateOrEdit from "../admin/user/CustomerCreateOrEdit"
import { setEditData } from "../admin/editStore/editSlice";
import CommonSorting from "../common/CommonSorting";
import StaticStyles from "../styles/StaticStyles";
import CommonOkDialog from "../common/CommonOkDialog";


function SiteUserDetails(props) {
    const dispatch = useDispatch();
    const { site } = props;
    const { users } = useSelector(({ admin }) => admin.siteUsersApp);
    const { role } = useSelector(({ auth }) => auth.user);
    const { accounts } = useSelector(({ admin }) => admin.accountApp);
    const { inviteUserState, inviteUserfailure } = useSelector(({ admin }) => admin.customerApp);
    const { createCustomerState, createCustomerFailure } = useSelector(({ admin }) => admin.customerApp);

    const [editSiteAccess, setEditSiteAccess] = useState(false);

    const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [openMessageDialog, setOpenMessaageDialog] = useState(false);

    useEffect(() => {
        dispatch(getSiteAccessUsers(site.id))
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadAllAdminAccounts());
    }, [dispatch])

    const handleEditSiteAccessClose = () => {
        setEditSiteAccess(false);
        setSelectedUser(null);
    }

    const userHeadCells = [
        { id: 'name', label: 'Name', display: true },
        { id: 'email', label: 'Email', display: true },
        { id: 'createdDate', label: 'Created Date', display: true },
        { id: 'siteRole', label: 'Site Role', display: true }
    ];

    const [userOrder, setUserOrder] = useState('');
    const [userOrderBy, setUserOrderBy] = useState('');
    const handleUserRequestSort = (property) => (event) => {
        const isAsc = userOrderBy === property && userOrder === 'asc';
        setUserOrder(isAsc ? 'desc' : 'asc');
        setUserOrderBy(property);
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickEditUserSiteAccess = (user) => {
        setSelectedUser(user);
        setEditSiteAccess(true);
    }

    const onClickAssignUserSiteAccess = (data) => {
        data = {
            ...data,
            siteId: site.id
        };
        setEditSiteAccess(false);
        setSelectedUser(null);
        dispatch(setSiteAccessToUser(data));

    }

    const onClickRemoveUserSiteAccess = (user) => {
        try {
            swal({
                title: "Are you sure?",
                text: "Do you want to remove site access to the user?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willRemove) => {
                    if (willRemove) {
                        var data = {
                            userId: user.id,
                            siteId: site.id
                        }
                        dispatch(removeSiteAccessToUser(data));
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }


    const handleOnInviteOpen = () => {
        try {
            setOpenInvitationDialog(true);
        } catch (error) {
            console.error(error);
        }
    }


    const handleInviteUser = (inviteUserData) => {
        dispatch(inviteUser(inviteUserData));
        setOpenInvitationDialog(false);
        dispatch(setLoaderProgress(true));
    }

    if (inviteUserState == SERVICE_STATUS.fulfilled) {
        dispatch(setLoaderProgress(false));
        dispatch(setinviteUserState(''));
        swal("", "Invited successfully", "success")
    }




    if (inviteUserState == SERVICE_STATUS.rejected && inviteUserfailure == "Request failed with status code 409") {
        try {
            dispatch(setinviteUserState(''));
            dispatch(setLoaderProgress(false));
            setOpenMessaageDialog(true);
        } catch (error) {
            console.error(error);
        }
    }

    if (inviteUserState == SERVICE_STATUS.rejected && inviteUserfailure != "Request failed with status code 409") {
        try {
            dispatch(setinviteUserState(''));
            dispatch(setLoaderProgress(false));
            swal("", "Something went wrong. Please try again", "error")
        } catch (error) {
            console.error(error);
        }
    }


    const handleOnAddUserOpen = () => {
        try {
            dispatch(setEditData({ accounts: accounts }));
            setOpenAddUserDialog(true);
        } catch (error) {
            console.error(error);
        }
    }

    const handleAddUser = (addUserData) => {
        dispatch(createCustomer(addUserData));
        setOpenAddUserDialog(false);
        dispatch(setLoaderProgress(true));
    }

    const handleOnAddUserClose = () => {
        setOpenAddUserDialog(false);
    }


    if (createCustomerState == SERVICE_STATUS.fulfilled) {
        try {
            dispatch(setcreateCustomerState(''))
            dispatch(setcreateCustomerFailure(""))
            dispatch(setLoaderProgress(false));
            swal("", "Successfully created", "success")
                .then(() => {
                    dispatch(getSiteAccessUsers(site.id));
                });

        } catch (error) {
            console.error(error);
        }
    }
    if ((createCustomerState == SERVICE_STATUS.rejected && createCustomerFailure != "Request failed with status code 409")) {

        try {
            dispatch(setcreateCustomerState(''))
            dispatch(setcreateCustomerFailure(""))
            dispatch(setLoaderProgress(false));
            swal("", "Something went wrong. Please try again", "error")
        } catch (error) {
            console.error(error);
        }
    }
    if ((createCustomerFailure == "Request failed with status code 409" && createCustomerState == SERVICE_STATUS.rejected)) {
        try {
            dispatch(setcreateCustomerState(''))
            dispatch(setcreateCustomerFailure(''))
            dispatch(setLoaderProgress(false));
            setOpenMessaageDialog(true);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="w-full px-24 -mx-4" style={{ 'marginTop': '60px' }}>
            <div className='flex items-center justify-between'>
                <Typography className="h1 text-30 my-16 font-700" color="textPrimary">
                    Users
                </Typography>
                <div>
                    {/* {(role.includes('admin') || role.includes('organisationadmin') || role.includes('projectmanager')) &&
                        <Button className='mr-12' style={{'backgroundColor':'#e26050ed','color':'#f0f8ff'}} onClick={() => handleOnAddUserOpen()}>
                            Add User
                        </Button>
                    } */}
                    {(role.includes('admin') || role.includes('organisationadmin') || role.includes('projectmanager')) &&
                        <Button className='mr-12' style={{ 'backgroundColor': '#e26050ed', 'color': '#f0f8ff' }} onClick={() => handleOnInviteOpen()}>
                            Invite User
                        </Button>
                    }
                </div>
            </div>
            <div className="flex flex-col md:flex-row sm:p-8" >
                <div className="flex flex-1 flex-col min-w-0">
                    <div className="flex justify-center max-w-full relative">
                        <div className="flex flex-1 max-w-full">
                            <div className="flex flex-1 justify-center max-w-full">
                                <div style={{ height: '100%', width: '100%' }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow style={{ 'backgroundColor': '#cfcdc9', 'transparency': '85%' }}>
                                                    {userHeadCells.map((headCell) => (
                                                        headCell.display &&
                                                        <TableCell
                                                            key={headCell.id}
                                                            sortDirection={userOrderBy === headCell.id ? userOrder : false}
                                                        ><b>
                                                                <TableSortLabel
                                                                    active={userOrderBy === headCell.id}
                                                                    direction={userOrderBy === headCell.id ? userOrder : 'asc'}
                                                                    onClick={handleUserRequestSort(headCell.id)}
                                                                >
                                                                    {headCell.label}
                                                                    {userOrderBy === headCell.id ? (
                                                                        <Box component="span" style={StaticStyles.visuallyHidden}>
                                                                            {userOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                        </Box>
                                                                    ) : null}
                                                                </TableSortLabel>
                                                            </b>
                                                        </TableCell>
                                                    ))}
                                                    <TableCell ><b>Action</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {CommonSorting.stableSort(users, userOrder, userOrderBy).map((user) => (
                                                <TableBody key={user.id}>
                                                    <TableRow key={user.id}>
                                                        <TableCell>{user.name}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.createdDate.split("T")[0]}</TableCell>
                                                        <TableCell>{user.siteRole}</TableCell>
                                                        <TableCell className='pl-0'>
                                                            {!user.isAllSitesAccess && <>
                                                                {site.siteRole == 'admin' &&
                                                                    user.organizationRole != 'admin' &&
                                                                    <>
                                                                        {((user.organizationRole != 'user') || (user.organizationRole == 'user' && user.siteRole != 'user')) &&
                                                                            <Tooltip title='Edit Site Role'>
                                                                                <IconButton color="secondary"
                                                                                    onClick={() => onClickEditUserSiteAccess(user)}
                                                                                ><ShareIcon /></IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                        {user.siteRole &&
                                                                            <Tooltip title='Remove Site Role'>
                                                                                <IconButton color="error"
                                                                                    onClick={() => onClickRemoveUserSiteAccess(user)}
                                                                                ><DeleteIcon /></IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                    </>

                                                                }
                                                            </>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        component="div"
                                        count={users.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editSiteAccess &&
                <SiteAccess
                    handleClose={handleEditSiteAccessClose}
                    user={selectedUser}
                    onClickAssignUserSiteAccess={onClickAssignUserSiteAccess}
                ></SiteAccess>
            }
            <div>
                {openInvitationDialog &&
                    <InviteUser accounts={accounts} handleClose={() => setOpenInvitationDialog(false)} handleInviteUser={handleInviteUser}></InviteUser>
                }
                {openMessageDialog &&
                    <CommonOkDialog
                        openMessageDialog={openMessageDialog}
                        onClose={() => { setOpenMessaageDialog(false) }}
                        message={"Email Already Exists"}
                    />

                }
            </div>
            <div>
                <Dialog
                    open={openAddUserDialog}
                    onClose={handleOnAddUserClose}
                    fullWidth
                    maxWidth='md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'paper'}
                >
                    <div >
                        <DialogContent className="flex flex-col items-center justify-center w-full py-0">
                            <CustomerCreateOrEdit
                                accounts={accounts}
                                handleClose={() => handleOnAddUserClose(false)}
                                handleAddUser={handleAddUser}
                                siteViewer={true}
                            ></CustomerCreateOrEdit>
                        </DialogContent>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default SiteUserDetails;