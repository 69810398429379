import { Grid } from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ErrorIcon from "@mui/icons-material/Error";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";

function PendingScan(props) {
  const {
    status,
    scan,
    index,
    handleOnDrop,
    handleDragScanStart,
    isFromFolder,
  } = props;

  return (
    <div
      style={{ backgroundColor: index % 2 == 0 ? "#F8F8F8" : "#D9D9D954" }}
      className="py-4"
    >
      <div
        className="flex flex-row justify-start items-center pl-4"
        style={{
          height: "25px",
          marginLeft: isFromFolder ? "48px" : "42px",
        }}
        draggable
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => handleOnDrop(e, scan.id)}
        onDragStart={() => {
          handleDragScanStart(scan.id);
        }}
      >
        <Grid container>
          <Grid
            item
            xs={8}
            className="flex flex-row justify-start items-center"
          >
            {status == "Queued" ? (
              <PlaylistAddCheckIcon
                sx={{ width: 20, height: 20 }}
                style={{ color: "#BBBBBB", transform: "scale(2.1)" }}
                className="p-4 mr-8"
              />
            ) : status == "Manual Review" ? (
              <ErrorIcon
                sx={{ width: 20, height: 20 }}
                style={{ color: "#BBBBBB", transform: "scale(2.1)" }}
                className="p-4 mr-8"
              />
            ) : (
              <AvTimerOutlinedIcon
                sx={{ width: 20, height: 20 }}
                style={{ color: "#BBBBBB", transform: "scale(2.1)" }}
                className="p-4 mr-8"
              />
            )}
            <span
              style={{
                color: "#BBBBBB",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              {scan.name}
            </span>
          </Grid>
          <Grid
            item
            xs={4}
            className="flex flex-row justify-start items-center"
          >
            <span
              style={{
                color: "#BBBBBB",
                fontSize: "13px",
                fontWeight: "300",
              }}
            >
              {" "}
              {status}
            </span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default PendingScan;
