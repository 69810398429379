import ToggleSwitch from "app/shared-components/toggleSwitch/ToggleSwitch";

const PointCloudViewSelection = (props) => {
  const { isOrthographicCamera, onChangeCameraProjection } = props;

  return (
    <div className="flex flex-row">
      <div className="p-8">
        <ToggleSwitch
          checked={!isOrthographicCamera}
          onToggleChange={onChangeCameraProjection}
          yes={"Perspective"}
          no={"Orthographic"}
        ></ToggleSwitch>
      </div>
    </div>
  );
};

export default PointCloudViewSelection;
