import HORIZONTAL_DATUM from "./HorizontalDatum";

const COORDINATE_SYSTEM = {
    'NAD83(2011) UTM 10N EPSG:6339': {
        value: '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6339,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 11N EPSG:6340': {
        value: '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6340,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 12N EPSG:6341': {
        value: '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6341,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 13N EPSG:6342': {
        value: '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6342,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 14N EPSG:6343': {
        value: '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6343,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 15N EPSG:6344': {
        value: '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6344,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 16N EPSG:6345': {
        value: '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6345,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 17N EPSG:6346': {
        value: '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6346,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 18N EPSG:6347': {
        value: '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6347,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) UTM 19N EPSG:6348': {
        value: '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6348,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 1 (ftUS) EPSG:6416': {
        value: '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=41.6666666666667 +lat_2=40 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6416,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 2 (ftUS) EPSG:6418': {
        value: '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=39.8333333333333 +lat_2=38.3333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6418,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 3 (ftUS) EPSG:6420': {
        value: '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=38.4333333333333 +lat_2=37.0666666666667 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6420,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 4 (ftUS) EPSG:6422': {
        value: '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=37.25 +lat_2=36 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6422,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 5 (ftUS) EPSG:6424': {
        value: '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6424,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / California zone 6 (ftUS) EPSG:6426': {
        value: '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6426,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / Texas North (ftUS) EPSG:6582': {
        value: '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=36.1833333333333 +lat_2=34.65 +x_0=200000.0001016 +y_0=999999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6582,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / Texas North Central (ftUS) EPSG:6584': {
        value: '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=33.9666666666667 +lat_2=32.1333333333333 +x_0=600000 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6584,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / Texas Central (ftUS) EPSG:6578': {
        value: '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=31.8833333333333 +lat_2=30.1166666666667 +x_0=699999.9998984 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6578,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / Texas South Central (ftUS) EPSG:6588': {
        value: '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=30.2833333333333 +lat_2=28.3833333333333 +x_0=600000 +y_0=3999999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6588,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2011) / Texas South (ftUS) EPSG:6586': {
        value: '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=27.8333333333333 +lat_2=26.1666666666667 +x_0=300000 +y_0=5000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6586,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'Idaho West (ftUS) EPSG: 6453': {
        value: '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6453,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'Idaho East (ftUS) EPSG: 6451': {
        value: '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6451,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'Idaho Central (ftUS) EPSG: 6449': {
        value: '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6449,
        horizontalDatum: HORIZONTAL_DATUM[1].value
    },
    'NAD83(2007) UTM 10N EPSG:3717': {
        value: '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3717,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 11N EPSG:3718': {
        value: '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3718,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 12N EPSG:3719': {
        value: '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3719,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 13N EPSG:3720': {
        value: '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3720,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 14N EPSG:3721': {
        value: '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3721,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 15N EPSG:3722': {
        value: '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3722,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 16N EPSG:3723': {
        value: '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3723,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) UTM 17N EPSG:3724': {
        value: '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3724,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / UTM 18N EPSG:3725': {
        value: '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3725,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / UTM 19N EPSG:3726': {
        value: '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3726,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 1 (ftUS) EPSG:3490': {
        value: '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=41.6666666666667 +lat_2=40 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3490,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 2 (ftUS) EPSG:3492': {
        value: '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=39.8333333333333 +lat_2=38.3333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3492,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 3 (ftUS) EPSG:3494': {
        value: '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=38.4333333333333 +lat_2=37.0666666666667 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3494,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 4 (ftUS) EPSG:3496': {
        value: '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=37.25 +lat_2=36 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3496,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 5 (ftUS) EPSG:3498': {
        value: '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=27.8333333333333 +lat_2=26.1666666666667 +x_0=300000 +y_0=5000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3498,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'NAD83(2007) / California Zone 6 (ftUS) EPSG:3500': {
        value: '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 3500,
        horizontalDatum: HORIZONTAL_DATUM[2].value
    },
    'WGS84 / UTM 1N EPSG:32601': {
        value: '+proj=utm +zone=1 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32601,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 2N EPSG:32602': {
        value: '+proj=utm +zone=2 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32602,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 3N EPSG:32603': {
        value: '+proj=utm +zone=3 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32603,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 4N EPSG:32604': {
        value: '+proj=utm +zone=4 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32604,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 5N EPSG:32605': {
        value: '+proj=utm +zone=5 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32605,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 6N EPSG:32606': {
        value: '+proj=utm +zone=6 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32606,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 7N EPSG:32607': {
        value: '+proj=utm +zone=7 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32607,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 8N EPSG:32608': {
        value: '+proj=utm +zone=8 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32608,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 9N EPSG:32609': {
        value: '+proj=utm +zone=9 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32609,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 10N EPSG:32610': {
        value: '+proj=utm +zone=10 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32610,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 11N EPSG:32611': {
        value: '+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32611,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 12N EPSG:32612': {
        value: '+proj=utm +zone=12 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32612,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 13N EPSG:32613': {
        value: '+proj=utm +zone=13 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32613,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 14N EPSG:32614': {
        value: '+proj=utm +zone=14 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32614,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 15N EPSG:32615': {
        value: '+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32615,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 16N EPSG:32616': {
        value: '+proj=utm +zone=16 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32616,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 17N EPSG:32617': {
        value: '+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32617,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 18N EPSG:32618': {
        value: '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32618,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 19N EPSG:32619': {
        value: '+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32619,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 20N EPSG:32620': {
        value: '+proj=utm +zone=20 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32620,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 21N EPSG:32621': {
        value: '+proj=utm +zone=21 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32621,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 22N EPSG:32622': {
        value: '+proj=utm +zone=22 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32622,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 23N EPSG:32623': {
        value: '+proj=utm +zone=23 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32623,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 24N EPSG:32624': {
        value: '+proj=utm +zone=24 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32624,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 25N EPSG:32625': {
        value: '+proj=utm +zone=25 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32625,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 26N EPSG:32626': {
        value: '+proj=utm +zone=26 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32626,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 27N EPSG:32627': {
        value: '+proj=utm +zone=27 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32627,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 28N EPSG:32628': {
        value: '+proj=utm +zone=28 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32628,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 29N EPSG:32629': {
        value: '+proj=utm +zone=29 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32629,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 30N EPSG:32630': {
        value: '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32630,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 31N EPSG:32631': {
        value: '+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32631,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 32N EPSG:32632': {
        value: '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32632,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 33N EPSG:32633': {
        value: '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32633,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 34N EPSG:32634': {
        value: '+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32634,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 35N EPSG:32635': {
        value: '+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32635,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 36N EPSG:32636': {
        value: '+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32636,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 37N EPSG:32637': {
        value: '+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32637,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 38N EPSG:32638': {
        value: '+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32638,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 39N EPSG:32639': {
        value: '+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32639,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'WGS84 / UTM 40N EPSG:32640': {
        value: '+proj=utm +zone=40 +datum=WGS84 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 32640,
        horizontalDatum: HORIZONTAL_DATUM[0].value
    },
    'NAD83(CSRS) / UTM 7N EPSG:3154': {
        value: '+proj=utm +zone=7 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3154,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 8N EPSG:3155': {
        value: '+proj=utm +zone=8 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3155,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 9N EPSG:3156': {
        value: '+proj=utm +zone=9 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3156,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 10N EPSG:3157': {
        value: '+proj=utm +zone=10 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3157,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 11N EPSG:2955': {
        value: '+proj=utm +zone=11 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2955,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 12N EPSG:2956': {
        value: '+proj=utm +zone=12 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2956,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 13N EPSG:2957': {
        value: '+proj=utm +zone=13 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2957,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 14N EPSG:3158': {
        value: '+proj=utm +zone=14 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3158,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 15N EPSG:3159': {
        value: '+proj=utm +zone=15 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3159,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 16N EPSG:3160': {
        value: '+proj=utm +zone=16 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 3160,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 17N EPSG:2958': {
        value: '+proj=utm +zone=17 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2958,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 18N EPSG:2959': {
        value: '+proj=utm +zone=18 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2959,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 19N EPSG:2960': {
        value: '+proj=utm +zone=19 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2960,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 20N EPSG:2961': {
        value: '+proj=utm +zone=20 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2961,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(CSRS) / UTM 21N EPSG:2962': {
        value: '+proj=utm +zone=21 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2962,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 3 EPSG:2945': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2945,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 4 EPSG:2946': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2946,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 5 EPSG:2947': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2947,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 6 EPSG:2948': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2948,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 7 EPSG:2949': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-70.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2949,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 8 EPSG:2950': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2950,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 9 EPSG:2951': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2951,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'MTM Zone 10 EPSG:2952': {
        value: '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2952,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'New Brunswick Stereographic EPSG:2953': {
        value: '+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=2500000 +y_0=7500000 +ellps=GRS80 +towgs84=-0.991,1.9072,0.5129,-1.25033e-07,-4.6785e-08,-5.6529e-08,0 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 2953,
        horizontalDatum: HORIZONTAL_DATUM[4].value
    },
    'NAD83(PA11) / Hawaii Zone 3 (ftUS) EPSG:6633': {
        value: '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs +type=crs',
        units: 'ft',
        epsg: 6633,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / Hawaii Zone 1 EPSG:6628': {
        value: '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6628,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / Hawaii Zone 2 EPSG:6629': {
        value: '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6629,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / Hawaii Zone 3 EPSG:6630': {
        value: '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6630,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / Hawaii Zone 4 EPSG:6631': {
        value: '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6631,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / Hawaii Zone 5 EPSG:6632': {
        value: '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6632,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / UTM 4N EPSG: 6634': {
        value: '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6634,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    },
    'NAD83(PA11) / UTM 5N EPSG: 6635': {
        value: '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs +type=crs',
        units: 'm',
        epsg: 6635,
        horizontalDatum: HORIZONTAL_DATUM[3].value
    }
}
export default COORDINATE_SYSTEM;