import { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "@emotion/styled";

const ImageTag = styled.img`
  cursor: pointer;
`;

function CustomImage(props) {
  const [imageBlobUrl, setImageBlobUrl] = useState("");

  const imageRef = useRef();

  const {
    src,
    alt,
    loading,
    onClick,
    id,
    width,
    sx,
    style,
    resize,
    renderWidth,
    renderHeight,
  } = props;

  useEffect(() => {
    let isMounted = true;
    var CancelToken = axios.CancelToken;
    var cancelSource = null;
    var cancelToken = new CancelToken(function executor(c) {
      cancelSource = c;
    });
    const response = axios.get(src, {
      headers: {},
      responseType: "blob",
      cancelToken: cancelToken,
    });

    Promise.resolve(response)
      .then((res) => {
        cancelSource = null;
        if (isMounted) {
          const imageUrl = URL.createObjectURL(res.data);
          setImageBlobUrl(imageUrl);
        }
      })
      .catch(() => {
        cancelSource = null;
      });

    return () => {
      if (cancelSource != null) {
        cancelSource();
      }
      isMounted = false;
    };
  }, [src]);

  useEffect(() => {
    if (imageBlobUrl) {
      resizeImage(imageBlobUrl);
    }
  }, [imageBlobUrl]);

  const resizeImage = async (url) => {
    if (resize && resize == true && renderWidth && renderHeight) {
      const img = await getImageSize(url);
      if (imageRef.current) {
        let viewerHeight = renderHeight;
        let viewerWidth = renderWidth;

        var resizeWidth = viewerWidth;
        var resizeHeight = viewerHeight;

        let naturalWidth = img.naturalWidth;
        let naturalHeight = img.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;

        if (viewerHeight * aspectRatio > viewerWidth) {
          resizeHeight = viewerWidth / aspectRatio;
        } else {
          resizeWidth = viewerHeight * aspectRatio;
        }
        var initialCoverage = Math.max(0, Math.min(1, 0.9));
        initialCoverage =
          typeof initialCoverage === "number" && !isNaN(initialCoverage)
            ? initialCoverage
            : 0.9;
        resizeWidth = Math.min(resizeWidth * initialCoverage, naturalWidth);
        resizeHeight = Math.min(resizeHeight * initialCoverage, naturalHeight);

        imageRef.current.style.width = resizeWidth + "px";
        imageRef.current.style.height = resizeHeight + "px";
      }
    }
  };

  const getImageSize = async (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  return (
    <ImageTag
      ref={imageRef}
      style={style}
      src={!imageBlobUrl ? "" : imageBlobUrl}
      alt={alt}
      loading={loading}
      onClick={onClick}
      id={id}
      width={width}
      sx={sx}
    />
  );
}

export default CustomImage;
