const MapBoxLabelMarker = () => {
    return (
        <div className='flex flex-col justify-center items-center flex-col labelMarker'>
            <p className='labelTextMarker'
                style={{
                    'fontSize': '10px',
                    'backgroundColor': 'black',
                    'color': 'white',
                    'fontWeight': '600',
                    'borderRadius': '2px',
                    'paddingLeft': '3px',
                    'paddingRight': '3px'
                }}
            ></p>
        </div>
    );
};

export default MapBoxLabelMarker;