import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import APP_CONFIG from "../../../../AppConfig";
import swal from "sweetalert";

export const getAllDevices = createAsyncThunk(
  "admin/deviceApp/loadDevice",
  async () => {
    const url = APP_CONFIG.api + `v1/device/getAllDevices`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const downloadDeviceFiles = createAsyncThunk(
  "admin/deviceApp/getDeviceDownloadURL",
  async (deviceId) => {
    const url = APP_CONFIG.api + `v1/device/getDeviceDownloadURL/${deviceId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.resolve(response)
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            const a = document.createElement("a");
            a.download = "test.bin";
            a.href = response.data;
            a.style.display = "none";
            document.body.append(a);
            a.click();
            a.remove();
          }
        }
      })
      .catch(() => {});
  }
);

export const createDevice = createAsyncThunk(
  "admin/deviceApp/createDevice",
  async (createDevice) => {
    const url = APP_CONFIG.api + `v1/device/createDevice`;
    const response = await axios.post(url, createDevice, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const updateDevice = createAsyncThunk(
  "admin/deviceApp/updateDevice",
  async (updateDevice) => {
    const url = APP_CONFIG.api + `v1/device/updatedevice/${updateDevice.id}`;
    const response = await axios.put(url, updateDevice, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;

    return data;
  }
);

export const removeDevice = createAsyncThunk(
  "admin/deviceApp/removeDevice",
  async (deviceId) => {
    const url = APP_CONFIG.api + `v1/device/deleteDevice/${deviceId}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const getDeletedDevice = createAsyncThunk(
  "admin/deviceApp/loadDeletedDevice",
  async () => {
    const url = APP_CONFIG.api + `v1/device/getAllDeletedDevices`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const getDeviceFilesPath = createAsyncThunk(
  "admin/deviceApp/getDeviceFilesPath",
  async (deviceId) => {
    const url = APP_CONFIG.api + `v1/device/getDeviceFilesPathById/${deviceId}`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;

    return data;
  }
);

// export const calibrateDevice = createAsyncThunk(
//     'admin/deviceApp/calibrateDevice',
//     async (scanId, { dispatch }) => {
//         const url = APP_CONFIG.api + `v1/device/calibrateDevice/${scanId}`;

//         const response = await axios.get(url, {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         });
//         if (response.status == 200) {
//             swal({
//                 text: 'Running Calibration',
//                 button: {
//                     text: "Ok",
//                 },
//             })
//         }

//         const data = await response.data;

//         return data;
//     }
// );

export const calibrateDevice = (scanId) => {
  const url = APP_CONFIG.api + `v1/device/calibrateDevice/${scanId}`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const updateDeviceOrganisation = createAsyncThunk(
  "admin/deviceApp/updateDeviceOrganisation",
  async (updateDevice, { dispatch }) => {
    const url =
      APP_CONFIG.api +
      `v1/device/updateDeviceOrganisation/${updateDevice.data.id}`;
    const response = await axios.put(url, updateDevice.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        if (data.status == 200) {
          dispatch(getAllDevices());
          swal("", "Organization updated successfully", "success");
        }
      })
      .catch(() => {});
  }
);

export const updatecreateDeviceState = (value) => async (dispatch) => {
  dispatch(setcreateDeviceState(value));
};
export const updateupdateDeviceState = (value) => async (dispatch) => {
  dispatch(setupdateDeviceState(value));
};

export const updateremoveDeviceState = (value) => async (dispatch) => {
  dispatch(setremoveDeviceState(value));
};

const deviceSlice = createSlice({
  name: "admin/deviceApp",
  initialState: {
    devices: [],
    loadingState: "",
    createDeviceState: "",
    updateDeviceState: "",
    removeDeviceState: "",
    uploadeDeviceId: 0,
    deletedDevice: [],
    deviceFilePath: {},
    updateDeviceFailure: "",
    createDeviceFailure: "",
  },

  reducers: {
    setcreateDeviceState: (state, action) => {
      state.createDeviceState = action.payload;
    },
    setupdateDeviceState: (state, action) => {
      state.updateDeviceState = action.payload;
    },
    setremoveDeviceState: (state, action) => {
      state.removeDeviceState = action.payload;
    },
    setDeviceFilePath: (state, action) => {
      state.deviceFilePath = action.payload;
    },
    setUpdateDeviceFaiure: (state, action) => {
      state.updateDeviceFailure = action.payload;
    },
    setCreateDeviceFaiure: (state, action) => {
      state.createDeviceFailure = action.payload;
    },
  },

  extraReducers: {
    [getAllDevices.fulfilled]: (state, action) => {
      state.devices = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [getAllDevices.rejected]: (state) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [getAllDevices.pending]: (state) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [createDevice.fulfilled]: (state, action) => {
      state.deviceFilePath = action.payload;
      state.createDeviceState = SERVICE_STATUS.fulfilled;
    },
    [createDevice.pending]: (state) => {
      state.createDeviceState = SERVICE_STATUS.pending;
    },
    [createDevice.rejected]: (state, action) => {
      state.createDeviceState = SERVICE_STATUS.rejected;
      state.createDeviceFailure = action.error.message;
    },
    [updateDevice.fulfilled]: (state) => {
      state.updateDeviceState = SERVICE_STATUS.fulfilled;
    },
    [updateDevice.pending]: (state) => {
      state.updateDeviceState = SERVICE_STATUS.pending;
    },
    [updateDevice.rejected]: (state, action) => {
      state.updateDeviceState = SERVICE_STATUS.rejected;
      state.updateDeviceFailure = action.error.message;
    },
    [removeDevice.fulfilled]: (state) => {
      state.removeDeviceState = SERVICE_STATUS.fulfilled;
    },
    [removeDevice.pending]: (state) => {
      state.removeDeviceState = SERVICE_STATUS.pending;
    },
    [removeDevice.rejected]: (state) => {
      state.removeDeviceState = SERVICE_STATUS.rejected;
    },
    [getDeletedDevice.fulfilled]: (state, action) => {
      state.deletedDevice = action.payload;
    },
    [getDeletedDevice.pending]: (state) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [getDeletedDevice.rejected]: (state) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [getDeviceFilesPath.fulfilled]: (state, action) => {
      state.deviceFilePath = action.payload;
    },
  },
});

export const {
  setcreateDeviceState,
  setupdateDeviceState,
  setremoveDeviceState,
  setDeviceFilePath,
  setUpdateDeviceFaiure,
  setCreateDeviceFaiure,
} = deviceSlice.actions;

export default deviceSlice.reducer;
