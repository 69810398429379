import styled from "@emotion/styled";

export const OuterPanel = styled.div`
  margin: 40px;
`;
export const Close = styled.div`
  font-size: 14px;
  color: #7A8184;
  position: absolute;
  top: 20px;
  right: 40px;
  cursor: pointer;
`;