import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

function CommonOkDialog(props) {

    const { onClose, openMessageDialog, message } = props;

    return (
        <div>
            <Dialog
                open={openMessageDialog}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ width: '420px', height: '230px' }}>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <DialogContent className="text-center">
                            <DialogContentText
                                id="alert-dialog-description"
                                style={{
                                    color: "#000000",
                                    fontSize: "18px",
                                    margin: "20px",
                                    fontWeight: "300px",
                                }}
                            >
                                <div className="flex flex-col">
                                    <span
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            lineHeight: "24px",
                                            letterSpacing: "-0.011em",
                                            textAlign: "left",
                                        }}
                                    >
                                        {message}
                                    </span>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="flex items-center mb-16 justify-center">
                            <Button
                                style={{
                                    width: "105px",
                                    height: "54px",
                                    padding: "13px 28px",
                                    borderRadius: "4px",
                                    margin: "20px",
                                    backgroundColor: "#58BEC9",
                                    color: "#ffffff",
                                    fontSize: "20px",
                                }}
                                id="custom-alert-message-btn"
                                onClick={onClose}
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default CommonOkDialog;