import React, { useState, useEffect } from "react";
import * as THREE from "three";
import CustomAlert from "app/main/apps/common/CustomAlert";
import AlignToControlStepper from "./AlignToControlStepper";
import PropTypes from "prop-types";

const AlignToControlWizard = (props) => {
  const [currentControlPointIndex, setCurrentControlPointIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false);
  const LEFT_DRAWER_WIDTH = 400;
  const [zoomDisabled, setZoomDisabled] = useState(false);

  const {
    toggleDrawer,
    rightDrawerOpen,
    leftDrawerOpen,
    toggleLeftDrawer,
    selectedCoordinates,
    setSelectedCoordinates,
    selectedMarkerIndex,
    startCoordinateSelection,
    zoomToPoint,
    zoomOutPointCloud,
    handleOnZoomMultiplePoint,
    viewer,
    clearModelCoordinate,
    setWizardActive,
    updateSpheres,
    wizardActive,
    isOrthographicCamera,
    handleOnAlignToControlClick,
    handleOnClearModelCoordinateWizard,
    handleStartModelSelectionWizardAction,
    handleClearModelWizardAction,
    onChangeCameraProjection,
  } = props;

  useEffect(() => {
    if (
      currentControlPointIndex > -1 &&
      currentControlPointIndex < selectedCoordinates?.length &&
      !zoomDisabled
    ) {
      zoomToCoordinate();
    }
  }, [currentControlPointIndex, selectedCoordinates?.length]);

  const toggleDrawers = (desiredState) => {
    toggleDrawer();
    toggleLeftDrawer(desiredState);
  };

  const zoomToCoordinate = () => {
    console.log(
      selectedMarkerIndex,
      currentControlPointIndex,
      selectedCoordinates
    );
    if (currentControlPointIndex < selectedMarkerIndex?.length) {
      let location = new THREE.Vector3(
        selectedCoordinates[currentControlPointIndex]?.control?.x,
        selectedCoordinates[currentControlPointIndex]?.control?.y,
        selectedCoordinates[currentControlPointIndex]?.control?.z
      );
      zoomToPoint(viewer.scene.view.radius / 4, location);
    }
  };

  const handleNextControlPointClick = () => {
    if (currentControlPointIndex >= selectedCoordinates?.length) {
      setIsDisabled(true);
      return;
    }
    setCurrentControlPointIndex(currentControlPointIndex + 1);
  };

  const handleResetControlPointClick = () => {
    setCurrentControlPointIndex(0);
  };

  const handlePreviousControlPointClick = () => {
    if (currentControlPointIndex <= 0) {
      CustomAlert.show({
        type: "error",
        message: "Already at first selected point!",
        buttons: [],
      });
      return;
    }
    setCurrentControlPointIndex(currentControlPointIndex - 1);
  };

  return (
    <>
      {wizardActive && (
        <AlignToControlStepper
          selectedCoordinates={selectedCoordinates}
          setSelectedCoordinates={setSelectedCoordinates}
          handleNextControlPointClick={handleNextControlPointClick}
          handleOnAlignToControlClick={handleOnAlignToControlClick}
          handleStartModelSelectionWizardAction={
            handleStartModelSelectionWizardAction
          }
          handlePreviousControlPointClick={handlePreviousControlPointClick}
          leftDrawerOpen={leftDrawerOpen}
          drawerWidth={LEFT_DRAWER_WIDTH}
          rightDrawerOpen={rightDrawerOpen}
          handleResetControlPointClick={handleResetControlPointClick}
          currentControlPointIndex={currentControlPointIndex}
          setCurrentControlPointIndex={setCurrentControlPointIndex}
          zoomToCoordinate={zoomToCoordinate}
          zoomOutPointCloud={zoomOutPointCloud}
          zoomToPoint={zoomToPoint}
          toggleDrawers={toggleDrawers}
          handleOnZoomMultiplePoint={handleOnZoomMultiplePoint}
          wizardActive={wizardActive}
          clearModelCoordinate={clearModelCoordinate}
          setWizardActive={setWizardActive}
          updateSpheres={updateSpheres}
          isOrthographicCamera={isOrthographicCamera}
          onChangeCameraProjection={onChangeCameraProjection}
          zoomDisabled={zoomDisabled}
          handleClearModelWizardAction={handleClearModelWizardAction}
          setZoomDisabled={setZoomDisabled}
          startCoordinateSelection={startCoordinateSelection}
          selectedMarkerIndex={selectedMarkerIndex}
          handleOnClearModelCoordinateWizard={
            handleOnClearModelCoordinateWizard
          }
        />
      )}
    </>
  );
};

AlignToControlWizard.propTypes = {
  toggleDrawer: PropTypes.func,
  rightDrawerOpen: PropTypes.bool,
  leftDrawerOpen: PropTypes.bool,
  siteCoordinateId: PropTypes.string,
  coordinates: PropTypes.array,
  zoomToPoint: PropTypes.func,
  viewer: PropTypes.object,
};

export default AlignToControlWizard;
