import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Content = styled.div`
  margin: 25px;
  font-size: 16px;
`;

function IssueConfirm(props) {

	const {onClose, issue} = props;

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>
				Confirmation
			</DialogTitle>
			<DialogContent>
				<Content>"{issue.summary}" successfully created.</Content>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} autoFocus variant="outlined">Ok</Button>
			</DialogActions>
		</Dialog>
	);
}

IssueConfirm.propTypes = {
	issue: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired
};

export default IssueConfirm;