import { useState,useCallback,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Close} from "@mui/icons-material";
import CustomImage from '../image/CustomImage';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {ChevronRight, ChevronLeft} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ImageViewerBar from './ImageViewerBar';


const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;

const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	cursor: pointer;
  }
`;

const TitlePanel = styled.div`
  display: flex;
`;

const PAGE_SIZE = 5;
function ImageViewerDialog(props) {

	const {imageInfo, onClose,images} = props;

	const [isZoomed, setIsZoomed] = useState(false)

	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentOffset, setCurrentOffset] = useState(0);
	const [currentImageInfo, setCurrentImageInfo] = useState({})
	const [barImageInfos, setBarImageInfos] = useState([]);

	

	useEffect(() => {
		setCurrentImageInfo(imageInfo);
		var index = images.findIndex(x => x.id == imageInfo.id);
		setBarImageInfos(images.slice(index, PAGE_SIZE));
	},[])
	useEffect(() => {
		if(!isZoomed){
			document.body.style.zoom = '100%'
		}
        
    }, [isZoomed])

  const handleZoomChange = useCallback(shouldZoom => {
	
    setIsZoomed(shouldZoom)
  }, [])

  const onClickNext = () => {
	var index = images.findIndex(x => x.id == currentImageInfo.id);
	if(index < images.length - 1){
		var currentImageId = images[index+1].id;
		setCurrentImageInfo(images[index+1])
		if(barImageInfos.filter(x => x.id == currentImageId).length == 0){
			onNextPage();
		}

	}
  }

  const onClickPrevious = () => {
	var index = images.findIndex(x => x.id == currentImageInfo.id);
	if(index != 0){
		var currentImageId = images[index-1].id;
		setCurrentImageInfo(images[index-1])
		if(barImageInfos.filter(x => x.id == currentImageId).length == 0){
			onPreviousPage();
		}
	}
  }


  function onPreviousPage() {
	let offset;
	if(currentOffset >= PAGE_SIZE) {
		offset = currentOffset - PAGE_SIZE;
	} else {
		offset = 0;
	}

	setCurrentOffset(offset);
	setBarImageInfos(images.slice(offset, offset + PAGE_SIZE));
}

function onNextPage() {
	let offset
	if(currentOffset < images.length - PAGE_SIZE - 1) {
		offset = currentOffset + PAGE_SIZE;
	} else {
		offset = images.length - 1;
	}

	setCurrentOffset(offset);
	setBarImageInfos(images.slice(offset, offset + PAGE_SIZE));
}

function onShowImage(showImageInfo) {
	var index = images.findIndex(x => x.id == currentImageInfo.id) + currentOffset;
    setCurrentIndex(index);
	setCurrentImageInfo(showImageInfo)
}
	
	return (
		<div>
			<Dialog
					onClose={onClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description" fullWidth open>
				<DialogTitle>
					<TitlePanel>
						<div>{currentImageInfo.name}</div>
						<Spacer space={5}/>
						<CloseButton onClick={onClose}><Close fontSize="inherit" /></CloseButton>
					</TitlePanel>
				</DialogTitle>
				<DialogContent>
					<div className='flex flex-row justify-between items-center mt-24'>
						<IconButton onClick={()=> onClickPrevious()}
						 disabled={images.findIndex(x => x.id == currentImageInfo.id) == 0}
						>
						<ChevronLeft style={{'fontSize' : '50px'}}></ChevronLeft>
						</IconButton>
					   <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
						<CustomImage src={currentImageInfo.fileUrl} loading="lazy"  />
						</ControlledZoom>
						<IconButton onClick={()=> onClickNext()} disabled={images.findIndex(x => x.id == currentImageInfo.id) >= images.length-1}>
						<ChevronRight style={{'fontSize' : '50px'}}></ChevronRight>
						</IconButton>
						</div>
						<ImageViewerBar imageInfos={barImageInfos} onPrevious={onPreviousPage} onNext={onNextPage} onShowImage={onShowImage} />
				</DialogContent>
			</Dialog>
		</div>
	);
}

ImageViewerDialog.propTypes = {
	imageInfo: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired
};

export default ImageViewerDialog;
