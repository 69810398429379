import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  updateScanNotificationDialog,
  setSelectedNotification,
} from "app/main/apps/admin/store/notificationSlice";
import { useNavigate } from "react-router-dom";
import { setEditData } from "app/main/apps/admin/editStore/editSlice";
import { calibrateDevice } from "app/main/apps/admin/store/deviceSlice";
import { useState } from "react";
import CalibrateDailog from "./CalibrateDailog";
const TitlePanel = styled.div`
  display: flex;
`;
const Spacer = styled.div`
  flex-grow: ${(props) => props.space};
`;
const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
    cursor: pointer;
  }
`;
function ScanUpdateNotificationDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [runningCalibrationDialog, setrunningCalibrationDialog] =
    useState(false);

  const { openScanNotificationDailog, selectedNotification } = useSelector(
    ({ admin }) => admin.notificationsApp
  );
  const { role } = useSelector(({ auth }) => auth.user);

  const handleClose = () => {
    dispatch(updateScanNotificationDialog(false));
    dispatch(setSelectedNotification(null));
  };

  const handleOnNewSiteClick = () => {
    dispatch(updateScanNotificationDialog(false));
    dispatch(setEditData({ scan: selectedNotification }));
    const redirect = `/apps/admin/scancreateoredit/${selectedNotification.id}?notification=true`;
    navigate(
      `/apps/admin/sitecreateoredit/?scanId=${
        selectedNotification.id
      }&redirect=${encodeURIComponent(redirect)}`
    );
  };

  const handleOnExistingSiteClick = () => {
    dispatch(updateScanNotificationDialog(false));
    dispatch(setEditData({ scan: selectedNotification }));
    navigate(
      `/apps/admin/scancreateoredit/${selectedNotification.id}?notification=true`
    );
  };

  const handleOnCalibrateDeviceClick = () => {
    dispatch(updateScanNotificationDialog(false));
    // dispatch(calibrateDevice(selectedNotification.id));
    const scanExportDataApi = calibrateDevice(selectedNotification.id);
    Promise.resolve(scanExportDataApi).then((response) => {
      if (response.status === 200) {
        setrunningCalibrationDialog(true);
      }
    });
  };

  const handelOnCalibrationDailogClick = () => {
    setrunningCalibrationDialog(false);
  };

  return (
    <>
      {openScanNotificationDailog && (
        <Dialog open={true} onClose={handleClose}>
          <div className="m-8">
            <TitlePanel>
              <div></div>
              <Spacer space={5} />
              <CloseButton onClick={() => handleClose()}>
                <Close fontSize="inherit" />
              </CloseButton>
            </TitlePanel>
            <DialogContent className="flex flex-col items-center justify-center w-full py-0 mb-28">
              <Button
                color="primary"
                variant="contained"
                className="mx-auto mt-8 py-8"
                sx={{ width: 200 }}
                onClick={() => handleOnNewSiteClick()}
              >
                New Site
              </Button>

              <Button
                color="primary"
                variant="contained"
                className="mx-auto mt-16 py-8"
                sx={{ width: 200 }}
                onClick={() => handleOnExistingSiteClick()}
              >
                Existing Site
              </Button>
              {role.includes("admin") && (
                <Button
                  color="primary"
                  variant="contained"
                  className="mx-auto mt-16 py-8"
                  style={{
                    backgroundColor: "#58BEC9",
                    color: "#ffffff",
                  }}
                  sx={{ width: 200 }}
                  onClick={() => handleOnCalibrateDeviceClick()}
                >
                  Calibrate
                </Button>
              )}
            </DialogContent>
          </div>
        </Dialog>
      )}
      {runningCalibrationDialog && (
        <CalibrateDailog handleOnClick={handelOnCalibrationDailogClick} />
      )}
    </>
  );
}

export default ScanUpdateNotificationDialog;
