import * as React from 'react';
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { ChevronRight } from "@mui/icons-material";
import { types, severities, priorities } from "./IssueAttributes";
import { createIssue } from "./Service";
import { useEffect, useState } from "react";
import { FormControl, MenuItem, TextField, Button, FormHelperText } from '@mui/material';
import * as proj4 from "proj4";
import { getInsertion } from "./Insertions";
import IssueConfirm from "./IssueConfirm";
import { toWgs } from './coordinate-viewer/UnitConverter';
import * as THREE from "three";
import { useRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

const OuterPanel = styled.div`
  width: 600px;
  background-color: #F8F8F8;
  height: 100%;
`;

const Header = styled.div`
  color: #FFFFFF;
  background-color: #9AA1A4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 14px;
`;

const Spacer = styled.div`
  flex-grow: 3;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const CloseButton = styled.div`
  cursor: pointer;
  padding-right: 10px;
`;
const Form = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Label = styled.div`
  color: #7A8184;
  font-size: 14px;
  width: 120px;
`;

const CoordinatesLabel = styled.div`
  color: #7A8184;
  font-size: 12px;
`;

const Message = styled.div`
  color: #7A8184;
  font-size: 14px;
`;

// const fromProjection = "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
// const toProjection = "+proj=longlat +datum=WGS84 +no_defs +type=crs";

const IssueCreator = forwardRef((props, ref) => {

	useImperativeHandle(ref, () => ({
		updateIssuesMarkers() {
		  setTimeout(function () {
			addIssuesMarkers();
		  }, 200)
		}
	  }));

	const { viewer, onClose, siteUTMProjection } = props;
	const [issue, setIssue] = useState(null);
	const [coordinate, setCoordinate] = useState(null);
	const [summary, setSummary] = useState('');
	const [description, setDescription] = useState('');
	const [type, setType] = useState('');
	const [priority, setPriority] = useState('');
	const [severity, setSeverity] = useState('');
	const [scanId, setScanId] = useState(0);
	const [lastMesurment, setLastMesurment] = useState(null);
	// let lastMesurment = null;

	useEffect(() => {
		if (coordinate === null) {
			startCoordinateSelection();
		}
	}, [coordinate]);

	const [labels, setLabels] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [issues, setIssues] = useState([]);

	useEffect(() => {
		if (issues) {
			addIssuesMarkers();
		}
	}, [issues]);

	const markersRef = useRef();
	const labelsRef = useRef();
	useEffect(() => {
		labelsRef.current = labels;
		markersRef.current = markers;

	}, [labels, markers]);

	useEffect(() => {
		return () => {
			var measurements = viewer.scene.measurements.filter(x => x.name == "IssuePoint");
			measurements.forEach((measurement) => {
				viewer.scene.removeMeasurement(measurement);
			})
			let _markers = [...markersRef.current];
			let _labels = [...labelsRef.current];
			removeIssueMarkers(_markers, _labels);
		}
	}, [])

	const removeIssueMarkers = (markers, labels) => {
		if (viewer) {
			markers.forEach(marker => {
				viewer.scene.scene.remove(marker);
			})
			labels.forEach(label => {
				viewer.scene.scene.remove(label);
			})
		}
	}


	const addIssuesMarkers = () => {
		var _labels = [...labels];
		var _markers = [...markers];
		let _data = [...issues];

		if (_data.length > _labels.length) {
			let addCount = _data.length - _labels.length
			for (var i = 0; i < addCount; i++) {
				try {
					let label = addLabel();
					viewer.scene.scene.add(label);
					_labels.push(label);
				} catch (error) {
					console.error(error);
				}
			}
		}
		else if (_data.length < _labels.length) {
			let removeCount = _labels.length - _data.length
			for (var i = 0; i < removeCount; i++) {
				viewer.scene.scene.remove(_labels[_labels.length - 1]);
				_labels.splice(_labels.length - 1, 1);
			}
		}

		if (_data.length > _markers.length) {
			let addCount = _data.length - _markers.length
			for (var i = 0; i < addCount; i++) {
				try {
					let sphere = addMarker();
					viewer.scene.scene.add(sphere);
					_markers.push(sphere);
				} catch (error) {
					console.error(error);
				}
			}
		}
		else if (_data.length < _markers.length) {
			let removeCount = _markers.length - _data.length
			for (var i = 0; i < removeCount; i++) {
				viewer.scene.scene.remove(_markers[_markers.length - 1]);
				_markers.splice(_markers.length - 1, 1);
			}
		}

		if (_labels.length == _markers.length) {
			_data.forEach((item, i) => {
				let x = item.positionX;
				let y = item.positionY;
				let z = item.positionZ;
				let name = item.summary;

				var sphere = _markers[i];
				var label = _labels[i];

				sphere.position.x = x;
				sphere.position.y = y;
				sphere.position.z = z;
				const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
				let clientWidth = renderAreaSize.width;
				let clientHeight = renderAreaSize.height;
				let camera = viewer.scene.getActiveCamera();
				let distance = camera.position.distanceTo(
					sphere.getWorldPosition(new THREE.Vector3())
				);
				let pr = projectedRadius(1, camera, distance, clientWidth, clientHeight);
				let scale = 15 / pr;
				sphere.scale.set(scale, scale, scale);

				let coord = new THREE.Vector3(x, y, z)

				label.setText(name);

				let screenPos = coord.clone().project(camera);
				screenPos.x = Math.round((screenPos.x + 1) * clientWidth / 2);
				screenPos.y = Math.round((-screenPos.y + 1) * clientHeight / 2);
				screenPos.z = 0;
				screenPos.y -= 30;
				let labelPos = new THREE.Vector3(
					(screenPos.x / clientWidth) * 2 - 1,
					-(screenPos.y / clientHeight) * 2 + 1,
					0.5);
				labelPos.unproject(camera);
				if (viewer.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
					let direction = labelPos.sub(camera.position).normalize();
					labelPos = new THREE.Vector3().addVectors(
						camera.position, direction.multiplyScalar(distance));
				}
				label.position.copy(labelPos);
				let labelscale = (70 / pr);
				label.scale.set(labelscale, labelscale, labelscale);

			})
		}

		setLabels(_labels);
		setMarkers(_markers);


	}

	function projectedRadius(
		radius,
		camera,
		distance,
		screenWidth,
		screenHeight
	) {
		if (camera.type == "OrthographicCamera") {
			return projectedRadiusOrtho(
				radius,
				camera.projectionMatrix,
				screenWidth,
				screenHeight
			);
		} else if (camera.type == "PerspectiveCamera") {
			return projectedRadiusPerspective(
				radius,
				(camera.fov * Math.PI) / 180,
				distance,
				screenHeight
			);
		} else {
			throw new Error("invalid parameters");
		}
	}

	function projectedRadiusOrtho(radius, proj, screenWidth, screenHeight) {
		let p1 = new THREE.Vector4(0);
		let p2 = new THREE.Vector4(radius);

		p1.applyMatrix4(proj);
		p2.applyMatrix4(proj);
		p1 = new THREE.Vector3(p1.x, p1.y, p1.z);
		p2 = new THREE.Vector3(p2.x, p2.y, p2.z);
		p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
		p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
		p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
		p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
		return p1.distanceTo(p2);
	}

	function projectedRadiusPerspective(radius, fov, distance, screenHeight) {
		let projFactor = 1 / Math.tan(fov / 2) / distance;
		projFactor = (projFactor * screenHeight) / 2;

		return radius * projFactor;
	}

	const addLabel = () => {
		let coordinateLabel = new Potree.TextSprite();
		coordinateLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
		coordinateLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
		coordinateLabel.fontsize = 16;
		coordinateLabel.material.depthTest = false;
		coordinateLabel.material.opacity = 1;
		coordinateLabel.visible = true;
		return coordinateLabel;

	}

	function addMarker(color) {
		const geometry = new THREE.SphereGeometry(0.4);
		const material = new THREE.MeshBasicMaterial({
			color: color ? color : 0xff0000,
		});
		const sphere = new THREE.Mesh(geometry, material);
		return sphere;
	}

	function create() {
		const issue = {
			scanId: parseInt(scanId),
			type: type,
			summary: summary,
			description: description,
			positionX: coordinate.x,
			positionY: coordinate.y,
			positionZ: coordinate.z,
			priority: priority,
			severity: severity
		};
		createIssue(issue)
			.then((response) => {
				setIssue(response.data);
				if (response.status == 201) {
					const issue = [...issues];
					issue.push(response.data)
					setIssues(issue);
				}
			}).catch((error) => console.log(error));
	}

	function toCartesian(coordinate) {
		return `(${coordinate.x.toFixed(3)}, ${coordinate.y.toFixed(3)}, ${coordinate.z.toFixed(3)})`
	}

	// function toWgs(coordinate) {
	// 	const result = proj4.default(fromProjection, siteUTMProjection ? siteUTMProjection : toProjection, [coordinate.x, coordinate.y, coordinate.z]);
	// 	const x = !isNaN(result[0]) ? result[0].toFixed(8) : 'NaN';
	// 	const y = !isNaN(result[1]) ? result[1].toFixed(8) : 'NaN';
	// 	const z = !isNaN(result[2]) ? result[2].toFixed(3) : 'NaN';

	// 	return `(${x}, ${y}, ${z})`

	// }
	const getWGS = (coordinate) => {
		const result = toWgs(coordinate, siteUTMProjection)
		return `(${result.latitude}, ${result.longitude}, ${result.elevation})`
	}



	function startCoordinateSelection() {
		const insertion = getInsertion('Point');
		var measure = viewer.measuringTool.startInsertion(insertion);
		measure.name = "IssuePoint";
		measure.addEventListener('marker_dropped', (markerDroppedEvent) => {
			const position = markerDroppedEvent.target.points[0].position;
			setCoordinate({
				x: Number(position.x.toFixed(3)),
				y: Number(position.y.toFixed(3)),
				z: Number(position.z.toFixed(3))
			});
			setScanId(markerDroppedEvent.target.pointcloud.scanId);
			setLastMesurment(markerDroppedEvent.target);
			// lastMesurment = markerDroppedEvent.target;
		});
	}

	function reset() {
		setSummary('');
		setDescription('');
		setSeverity('');
		setPriority('');
		setType('');
		setIssue(null);
		setCoordinate(null);
		setScanId(0);
		if (lastMesurment != null) {
			viewer.scene.removeMeasurement(lastMesurment);
		}

	}

	return (
		<OuterPanel>
			<Header>
				<CloseButton onClick={onClose}><ChevronRight /></CloseButton>
				<div>Create a new issue</div>
				<Spacer />
			</Header>
			<Form>
				{coordinate
					?
					<FormControl size="small" fullWidth>
						<Row><Label>Cartesian (UTM): </Label><CoordinatesLabel>{toCartesian(coordinate)}</CoordinatesLabel></Row>
						<Row><Label>WGS84: </Label><CoordinatesLabel>{getWGS(coordinate)}</CoordinatesLabel></Row>
					</FormControl>
					:
					<Message>Hover your mouse over the model to select the coordinates for which you need to create an issue.</Message>
				}
				<FormControl size="small" fullWidth>
					<TextField value={summary}
						onChange={(event) => setSummary(event.target.value)}
						size="small"
						label="Summary"
						variant="outlined"
						fullWidth />
					<FormHelperText>Summary of the issue for this coordinate.</FormHelperText>
				</FormControl>
				<FormControl size="small" fullWidth>
					<TextField value={description}
						onChange={(event) => setDescription(event.target.value)}
						size="small"
						label="Description"
						variant="outlined"
						minRows={5}
						maxRows={8}
						multiline fullWidth />
					<FormHelperText>Detailed description of the issue as it relates to this coordinate.</FormHelperText>
				</FormControl>
				<Row>
					<FormControl size="small">
						<TextField value={type}
							onChange={(event) => setType(event.target.value)}
							size="small"
							label="Type"
							variant="outlined"
							select>
							{types.map((type) => (
								<MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
							))}
						</TextField>
						<FormHelperText>Select the type of issue.</FormHelperText>
					</FormControl>
					<Spacer space={1} />
					<FormControl size="small">
						<TextField value={priority}
							onChange={(event) => setPriority(event.target.value)}
							size="small"
							label="Priority"
							variant="outlined"
							select>
							{priorities.map((priority) => (
								<MenuItem key={priority.value} value={priority.value}>{priority.label}</MenuItem>
							))}
						</TextField>
						<FormHelperText>Select priority for this issue.</FormHelperText>
					</FormControl>
					<Spacer space={1} />
					<FormControl size="small">
						<TextField value={severity}
							onChange={(event) => setSeverity(event.target.value)}
							size="small"
							label="Severity"
							variant="outlined"
							select>
							{severities.map((severity) => (
								<MenuItem key={severity.value} value={severity.value}>{severity.label}</MenuItem>
							))}
						</TextField>
						<FormHelperText>Select the severity of this issue.</FormHelperText>
					</FormControl>
				</Row>
				<FormControl size="small">
					<Button onClick={create} variant="outlined" disabled={!(coordinate && type && priority && severity && summary && description)}>Create Issue</Button>
				</FormControl>
			</Form>

			{issue &&
				<IssueConfirm issue={issue} onClose={reset} />
			}
		</OuterPanel>


	);
})

IssueCreator.propTypes = {
	scanId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
};

export default IssueCreator;
