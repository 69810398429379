import mixpanel from "mixpanel-browser";

class MixPanelIntegration {
  static loginUserDetails(userDetails) {
    const url = window.location.origin;

    var projectToken = "";

    if (url.includes("localhost")) {
      projectToken = "aabdb96ea35fd4f958711db34176d04e";
    } else if (url.includes("dev.looq.ai")) {
      projectToken = "4a6416380b747cf43f15568ef5913c32";
    } else if (url.includes("looq.ai")) {
      projectToken = "ed65a3c537f887345f175828ecbcf5a1";
    }

    mixpanel.init(projectToken);
    mixpanel.identify(userDetails.email);
    mixpanel.people.set({
      $name: userDetails.name,
      $email: userDetails.email,
    });
  }

  static EventClicks(message) {
    mixpanel.track(message);
  }
}

export default MixPanelIntegration;
