import { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { alpha } from "@mui/material/styles";
import { setIsTriedToUpload } from "app/main/apps/admin/store/socketSlice";
import { Paper, Typography, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";

const DEFAULT_CAPTION = "Drag and drop file here, or click to select file";

const StyledDropzone = styled(Paper)(({ theme }) => ({
  border: "2px dashed",
  borderColor:
    ({ theme }) =>
    (props) =>
      props["data-error"] ? theme.palette.error.main : theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  backgroundColor:
    ({ theme }) =>
    (props) =>
      props["data-error"]
        ? alpha(theme.palette.error.main, 0.1)
        : theme.palette.background.paper,
  "&:hover": {
    borderColor:
      ({ theme }) =>
      (props) =>
        props["data-error"]
          ? theme.palette.error.dark
          : theme.palette.text.secondary,
  },
  '&[data-has-files="true"]': {
    borderColor: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.1),
  },
  "& .dropzone-text": {
    color:
      ({ theme }) =>
      (props) =>
        props["data-error"]
          ? theme.palette.error.dark
          : theme.palette.text.primary,
  },
}));

const UploadFiles = forwardRef(
  ({ caption = DEFAULT_CAPTION, onError, ...props }, ref) => {
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [error, setError] = useState("");
    const { uploadEnabled } = useSelector(({ admin }) => admin.socketApp);

    useImperativeHandle(ref, () => ({
      resetSelectedFiles() {
        try {
          setSelectedFiles(undefined);
          setError("");
        } catch (error) {
          console.error(error);
        }
      },
      validateHasFiles() {
        return validateHasFiles();
      },
    }));

    const validateHasFiles = () => {
      if (!selectedFiles || selectedFiles?.length === 0) {
        const errorMessage = "No file selected. Please upload a file.";
        setError(errorMessage);
        onError?.(errorMessage);
        return false;
      }
      setError("");
      onError?.(null);
      return true;
    };

    const onDrop = (files) => {
      if (uploadEnabled) {
        const { onDrop } = props;
        onDrop(files);
        if (files.length > 0) {
          setSelectedFiles(files);
          setError("");
          onError?.(null);
        }
      } else {
        dispatch(setIsTriedToUpload(true));
        swal(
          "Uploads are on hold due to site update we will notify once update completes"
        );
      }
    };

    caption = props.disabled ? "Disabled" : caption;

    return (
      <div>
        <Dropzone
          disabled={props.disabled}
          onDrop={onDrop}
          multiple={props.multiple}
          accept={props.accept}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <StyledDropzone
                data-error={Boolean(error)}
                data-has-files={Boolean(selectedFiles?.length)}
                elevation={0}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Typography color={error ? "error" : "textSecondary"}>
                  {selectedFiles && selectedFiles[0].name ? (
                    <span>
                      {selectedFiles.length + (selectedFiles.length > 1
                        ? " files"
                        : " file") + " selected for upload"}
                    </span>
                  ) : (
                    caption
                  )}
                </Typography>
              </StyledDropzone>
            </section>
          )}
        </Dropzone>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </div>
    );
  }
);

export default UploadFiles;
