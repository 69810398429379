const MapBoxPointLabelMarker = () => {
    return (
        <div className='flex flex-col justify-center items-center flex-col'
            style={{
                'marginBottom': '25px'
            }}
        >
            <p className='labelTextMarker'
                style={{
                    'fontSize': '10px',
                    'backgroundColor': 'black',
                    'color': 'white',
                    'fontWeight': '600',
                    'borderRadius': '2px',
                    'paddingLeft': '3px',
                    'paddingRight': '3px',
                    'marginBottom': '5px'
                }}
            ></p>
            <div style={{
                'height': '10px',
                'width': '10px',
                'backgroundColor': '#F84C4C',
                'borderRadius': '50%',
                'display': 'inline-block',
                'border': '1px solid black'
            }} ></div>

        </div>
    );
};

export default MapBoxPointLabelMarker;