import {
    Typography,
    Card,
    CardContent
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import Button from "@mui/material/Button";;
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { verifyOTP, resendOtp, setOtpValidateState, setOtpValidate } from 'app/auth/store/verificationSlice';
import SERVICE_STATUS from 'app/services/ServiceStatus';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useAuth0 } from '@auth0/auth0-react';


function Verification() {
    const dispatch = useDispatch();
    const hiddenAddModelButtonInput = useRef();
    const hiddenRemoveModelButtonInput = useRef();
    const customer = useSelector(({ auth }) => auth.customer);
    const { verifyToken } = useSelector(({ auth }) => auth.verification);
    const { otpvalidate, otpValidatestate } = useSelector(({ auth }) => auth.verification);

    const {
        isAuthenticated,
    } = useAuth0();


    const addBeforeUnloadEvent = () => {
        window.addEventListener('beforeunload', beforeUnloadEventFunc);
    }
    const removeBeforeUnloadEvent = () => {
        window.removeEventListener('beforeunload', beforeUnloadEventFunc);
    }
    const beforeUnloadEventFunc = (event) => {
        if (isAuthenticated) {
            localStorage.setItem("isLogout", true);
        }

    }
    useEffect(() => {
        swal("A Verification Code was sent to your registered email. Please verify before starting the application.");
        setTimeout(() => {
            hiddenAddModelButtonInput.current.click();
        }, 1000)
    }, [])

    const [otp, setOtp] = useState(0);

    const handleOnSubmitClick = () => {
        const data = {
            otp: otp,
            tokens: verifyToken
        }
        dispatch(verifyOTP(data));
    }
    const [isValid, setIsValid] = useState(true);
    if (otpvalidate == true && otpValidatestate == SERVICE_STATUS.fulfilled) {
        hiddenRemoveModelButtonInput.current.click();
        dispatch(setOtpValidateState(''));
        dispatch(setOtpValidate(false));
        swal("", "Successfully verified", "success")
            .then(() => {
                window.location.href = window.location.origin;
                localStorage.setItem('isLogout', false);
            });

    }
    if (otpvalidate == false && otpValidatestate == SERVICE_STATUS.fulfilled) {
        setIsValid(false);
        dispatch(setOtpValidateState(''));
        dispatch(setOtpValidate(true));
        setOtp('');
    }

    const handleOnResendClick = () => {
        const data = {
            authId: customer.auth0userid,
            email: customer.email
        }
        dispatch(resendOtp(data));
    }
    const renderButton = (buttonProps) => {
        return <Button variant='outlined' {...buttonProps}>Resend</Button>;
    };


    return (
        <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
            <div className="flex flex-col items-center justify-center w-full">
                <Card className="w-full max-w-384">
                    <CardContent className="flex flex-col items-center justify-center px-16 sm:px-24 md:px-32">
                        <div className="items-center justify-center">
                            <div className='flex flex-row justify-center items-center w-full'>
                                <img className='mx:auto' width='200px' src='assets/images/logos/looq.png'></img>
                            </div>
                            <Typography variant="h6" className="mt-0 mb-24 font-semibold text-18 sm:text-18 text-center">
                                Please enter Verification Code
                            </Typography>
                        </div>
                        <div className="flex flex-row justify-center items-center w-full mb-4">
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                autoFocus={true}
                                OTPLength={6}
                                otpType="number"
                                placeholder={["0", "0", "0", "0", "0", "0"]}
                                inputStyles={{ "border": "1px solid #c4c4c4", "border-radius": "4px", "margin-left": "10", "margin-right": "10" }}
                            />
                        </div>
                        <div className="flex flex-row w-full">
                            {isValid == false ?
                                <Typography color="error">Invalid Verification Code</Typography> : null}
                        </div>
                        <div className="flex flex-row justify-center items-center w-full justify-between">
                            <ResendOTP maxTime={120} style={{ "display": "contents" }} renderButton={renderButton} onResendClick={() => handleOnResendClick()} />
                        </div>
                        <Button
                            variant="contained"
                            className="w-full mx-auto mt-16 py-8"
                            aria-label="Submit"
                            type="button"
                            onClick={() => handleOnSubmitClick()}
                        >
                            Verify
                        </Button>
                        <input
                            ref={hiddenAddModelButtonInput}
                            type="button"
                            onClick={() => addBeforeUnloadEvent()}
                            style={{ display: 'none' }}
                        />
                        <input
                            ref={hiddenRemoveModelButtonInput}
                            type="button"
                            onClick={() => removeBeforeUnloadEvent()}
                            style={{ display: 'none' }}
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default Verification;