
import styled from "@emotion/styled";
import {
  Typography,
  Card,
  Link
} from '@mui/material';
import { useDispatch, } from "react-redux";
import { getProjectInvoiceDownloadLink } from "../admin/store/projectInvoiceSlice";
import { getMonthString } from "../common/Common";

const Description = styled.div`
  font-size: 15px;
`

function ProjectInvoiceNotificationCard(props) {
  const { notification } = props;
  const dispatch = useDispatch();

  const date = notification.createTime
  const dateObject = new Date(date);
  const dateTime = dateObject.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });


  const handleOnNotificationClick = (id) => {
    dispatch(getProjectInvoiceDownloadLink(id))
  }


  const getDisplayDate = (date) => {
    var d = new Date(date);
    var monthStr = getMonthString(d.getMonth())
    return monthStr + ' ' + d.getDate();
  }


  return (
    <Card
      className='flex items-center relative w-full rounded-16 p-20 min-h-64 shadow space-x-8 mb-8'
      elevation={0}
    >
      <div className="flex flex-col flex-auto">
        <Description>Project <Link onClick={() => handleOnNotificationClick(notification.projectInvoiceId)} style={{ 'color': '#0D99FF', 'textDecoration': 'none', 'cursor': 'pointer' }}>{notification.projectInvoices.project.name}</Link> invoice <b>{getDisplayDate(notification.projectInvoices.fromDate)}-{getDisplayDate(notification.projectInvoices.toDate)}</b> is pending and awaiting payment</Description>
        <Typography className="mt-8 text-sm leading-none " color="text.secondary">
          {dateTime}
        </Typography>
      </div>
    </Card>

  )
}
export default ProjectInvoiceNotificationCard;