import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from "@mui/material";

function ScanProcessingDetailsGrid(props) {

    const { scanProcessDetails, toLocalDateTime, getProcessingStatusColor, getStatusProcessingIcon } = props

    const processingGridListObject = () => {
        var dataObj = [];
        dataObj.push({
            FieldName: "Scan ID",
            FieldValue: scanProcessDetails.scanId,
            isStatusField: false
        });
        dataObj.push({
            FieldName: "Scan",
            FieldValue: scanProcessDetails.scan ? scanProcessDetails.scan.name : '',
            isStatusField: false
        });
        dataObj.push({
            FieldName: "Site",
            FieldValue: scanProcessDetails.scan ? scanProcessDetails.scan.siteName : '',
            isStatusField: false
        });
        dataObj.push({
            FieldName: "Status",
            FieldValue: scanProcessDetails.processingDisplayStatus,
            isStatusField: true
        });
        dataObj.push({
            FieldName: "Step Function Status",
            FieldValue: scanProcessDetails.status,
            isStatusField: false
        });
        dataObj.push({
            FieldName: "Start Date",
            FieldValue: toLocalDateTime(scanProcessDetails.startDate),
            isStatusField: false
        });
        dataObj.push({
            FieldName: "End Date",
            FieldValue: toLocalDateTime(scanProcessDetails.endDate),
            isStatusField: false
        });
        dataObj.push({
            FieldName: "IP Address",
            FieldValue: scanProcessDetails.containerIpaddress,
            isStatusField: false
        });
        dataObj.push({
            FieldName: "Version",
            FieldValue: scanProcessDetails.processingVersion ? scanProcessDetails.processingVersion : "",
            isStatusField: false
        });
        return dataObj;
    }

    return (

        <div>
            <div className="w-full">
                <div className="flex flex-col md:flex-row sm:p-8" >
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="flex justify-center max-w-full relative">
                            <div className="flex flex-1 max-w-full">
                                <div className="flex flex-1 justify-center max-w-full">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="collapsible table">
                                                {processingGridListObject().map((r, i) => (
                                                    <TableBody key={i}>
                                                        <TableRow key={i}>
                                                            <TableCell width={60}></TableCell>
                                                            <TableCell >
                                                                <b>{r.FieldName}</b>
                                                            </TableCell>
                                                            {r.isStatusField ?
                                                                <TableCell
                                                                    className="flex flex-row items-center"
                                                                    style={{
                                                                        color: getProcessingStatusColor(r.FieldValue),
                                                                        'fontSize': '14px',
                                                                        'fontWeight': '600'
                                                                    }}
                                                                >
                                                                    <>
                                                                        {getStatusProcessingIcon(r.FieldValue)}&nbsp;{r.FieldValue ? `${r.FieldValue.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                                                                            return letter.toUpperCase();
                                                                        })}` : ''}
                                                                    </>
                                                                </TableCell>
                                                                : <TableCell>
                                                                    {r.FieldValue}
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    </TableBody>
                                                ))}
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanProcessingDetailsGrid;