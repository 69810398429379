import { combineReducers } from '@reduxjs/toolkit';
import scans from './scansSlice';
import sites from './sitesSlice';
import images from './imagesSlice';

const scanReducer = combineReducers({
  scans,
  sites,
  images
});

export default scanReducer;
