import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { MenuOpen } from "@mui/icons-material";
import {
  IconButton,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import ToggleSwitch from "app/shared-components/toggleSwitch/ToggleSwitch";
import ToolbarScans from "../ToolbarScans";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ScreenshotAndNavigation from "../tools/ScreenshotAndNavigation";
import Measurement from "../tools/Measurement";
import CommonToggle from "../tools/CommonToggle";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";
import { useSelector } from "react-redux";

const OuterPanel = styled.div`
  width: 400px;
  padding-top: 0px;
  background-color: #f8f8f8;
`;

const Header = styled.div`
  color: #ffffff;
  background-color: #b9b9b9;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  position: fixed;
  width: 400px;
  height: 50px;
  z-index: 100;
`;
const Spacer = styled.div`
  flex-grow: 3;
`;
const CloseButton = styled.div`
  cursor: pointer;
`;

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

function MapBoxToolbar(props) {
  const {
    scans,
    loadedScanIds,
    feetLengthSwitch,
    onToggleFeetLength,
    imageMarkersVisible,
    mapperEnabled,
    onToggleImageMarkers,
    onToggleCurrentViewer,
    showOrtho,
    onToggleOrtho,
    showContours,
    onToggleGpsCoordinateFile,
    onToggleContours,
    selectedSite,
    role,
    selectAllScans,
    getSnap,
  } = props;

  const user = useSelector(({ auth }) => auth.user);

  const [measurement, setMeasurement] = useState(null);
  const [imagesMinimize, SetImagesMinimize] = useState(false);
  const [orthosMinimize, SetOrthosMinimize] = useState(false);
  const [contoursMinimize, SetContoursMinimize] = useState(false);

  useEffect(() => {
    if (!props.measuring) {
      setMeasurement(null);
    }
  }, [props.measuring]);

  function togglePointCloud(event) {
    const scanId = event.currentTarget.id;
    const scan = scans.find((x) => x.id == scanId);
    if (scan) {
      const checked = event.target.checked;
      props.onTogglePointCloud(scan, checked);
    }
  }

  function findLoadedScan(scanId) {
    return loadedScanIds.find((loadedScanId) => loadedScanId === scanId);
  }

  function reset(event) {
    const type = event.currentTarget.id;
    if (type === "measurement") {
      setMeasurement(null);
    } else {
      console.error("Unrecognized type for reset = " + type);
    }
    props.onReset(type);
  }

  function selectMeasurement(event) {
    const measurement = event.currentTarget.id;
    setMeasurement(measurement);
    props.onSelectMeasurement(measurement);
  }

  const selectAllCompletedScans = (event) => {
    const checked = event.target.checked;
    selectAllScans(checked);
  };

  const handleOnChangeAccordion = (panelId) => {
    if (panelId == "panel3") {
      SetImagesMinimize(!imagesMinimize);
      if (imagesMinimize) {
        MixPanelIntegration.EventClicks("Images Close Click");
      } else {
        MixPanelIntegration.EventClicks("Images Expand Click");
      }
    }
    if (panelId == "panel4") {
      SetOrthosMinimize(!orthosMinimize);
      if (orthosMinimize) {
        MixPanelIntegration.EventClicks("Orthos Close Click");
      } else {
        MixPanelIntegration.EventClicks("Orthos Expand Click");
      }
    }
    if (panelId == "panel5") {
      SetContoursMinimize(!contoursMinimize);
      if (contoursMinimize) {
        MixPanelIntegration.EventClicks("Contours Close Click");
      } else {
        MixPanelIntegration.EventClicks("Contours Expand Click");
      }
    }
  };

  return (
    <OuterPanel>
      <Header className="items-center">
        <div style={{ color: "#010c16f0" }}>
          {" "}
          <BuildIcon /> Tools
        </div>
        <Spacer />
        <div style={{ marginRight: "10px" }}>
          <ToggleSwitch
            checked={true}
            onToggleChange={onToggleCurrentViewer}
            yes={"2D"}
            no={"3D"}
            style={{
              fontSize: "13px",
            }}
          ></ToggleSwitch>
        </div>
        <CloseButton onClick={props.toggleDrawer}>
          <MenuOpen style={{ color: "#4D4D4F" }} />
        </CloseButton>
      </Header>

      <ToolbarScans
        scans={scans}
        currentViewer={props.currentViewer}
        loadedScanIds={loadedScanIds}
        findLoadedScan={findLoadedScan}
        zoomToPointCloud={props.zoomToPointCloud}
        zoomOutPointCloud={props.zoomOutPointCloud}
        togglePointCloud={togglePointCloud}
        siteId={selectedSite.id}
        selectAllCompletedScans={selectAllCompletedScans}
      />

      <Divider />
      {props.showToolbarItems && (
        <>
          <>
            <ScreenshotAndNavigation
              getSnap={getSnap}
              currentViewer={props.currentViewer}
            />
            <Measurement
              reset={reset}
              selectMeasurement={selectMeasurement}
              measurement={measurement}
              feetLengthSwitch={feetLengthSwitch}
              onToggleFeetLength={onToggleFeetLength}
              currentViewer={props.currentViewer}
            />
            <Accordion
              expanded={imagesMinimize}
              sx={{
                "&.MuiAccordion-root.Mui-expanded": {
                  margin: "1px 0 0px 0px",
                },
                "&:last-of-type": {
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                },
              }}
            >
              <AccordionSummary
                sx={{
                  "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "auto",
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                expandIcon={
                  imagesMinimize ? (
                    <IconButton
                      onClick={() => {
                        handleOnChangeAccordion("panel3");
                      }}
                    >
                      <IndeterminateCheckBoxOutlinedIcon
                        style={{ color: "#BBBBBB" }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        handleOnChangeAccordion("panel3");
                      }}
                    >
                      <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
                    </IconButton>
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3"
              >
                <TypographyStyles>Images</TypographyStyles>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex" }}
                sx={{
                  "& .MuiAccordionDetails-root": {
                    padding: "8px 10px 16px",
                  },
                }}
              >
                <CommonToggle
                  toggleChecked={imageMarkersVisible}
                  handleOnToggleChange={onToggleImageMarkers}
                />
                {user.role && user.role.includes("admin") && (
                  <div style={{ paddingLeft: "15px" }}>
                    <ToggleSwitch
                      checked={mapperEnabled}
                      onToggleChange={onToggleGpsCoordinateFile}
                      yes={"Mapper"}
                      no={"GPS"}
                    ></ToggleSwitch>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            {selectedSite.orthoAvailable && (
              <>
                <Accordion
                  expanded={orthosMinimize}
                  sx={{
                    "&.MuiAccordion-root.Mui-expanded": {
                      margin: "1px 0 0px 0px",
                    },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      "& .MuiAccordionSummary-root.Mui-expanded": {
                        minHeight: "auto",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    expandIcon={
                      orthosMinimize ? (
                        <IconButton
                          onClick={() => {
                            handleOnChangeAccordion("panel4");
                          }}
                        >
                          <IndeterminateCheckBoxOutlinedIcon
                            style={{ color: "#BBBBBB" }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            handleOnChangeAccordion("panel4");
                          }}
                        >
                          <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
                        </IconButton>
                      )
                    }
                    aria-controls="panel4bh-content"
                    id="panel4"
                  >
                    <TypographyStyles>Orthos</TypographyStyles>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      "& .MuiAccordionDetails-root": {
                        padding: "8px 10px 16px",
                      },
                    }}
                  >
                    <CommonToggle
                      toggleChecked={showOrtho}
                      handleOnToggleChange={onToggleOrtho}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {selectedSite.contoursAvailable && role.includes("admin") && (
              <>
                <Accordion
                  expanded={contoursMinimize}
                  sx={{
                    "&.MuiAccordion-root.Mui-expanded": {
                      margin: "1px 0 0px 0px",
                    },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      "& .MuiAccordionSummary-root.Mui-expanded": {
                        minHeight: "auto",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    expandIcon={
                      contoursMinimize ? (
                        <IconButton
                          onClick={() => {
                            handleOnChangeAccordion("panel5");
                          }}
                        >
                          <IndeterminateCheckBoxOutlinedIcon
                            style={{ color: "#BBBBBB" }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            handleOnChangeAccordion("panel5");
                          }}
                        >
                          <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
                        </IconButton>
                      )
                    }
                    aria-controls="panel5bh-content"
                    id="panel5"
                  >
                    <TypographyStyles>Contours</TypographyStyles>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      "& .MuiAccordionDetails-root": {
                        padding: "8px 10px 16px",
                      },
                    }}
                  >
                    <CommonToggle
                      toggleChecked={showContours}
                      handleOnToggleChange={onToggleContours}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </>
        </>
      )}
    </OuterPanel>
  );
}

export default MapBoxToolbar;
