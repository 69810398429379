import {
    TableContainer,
    TableCell,
    TableBody,
    TableRow,
    Paper,
    Table,
    TableHead
} from '@mui/material';
import ConvertCoordinateValue from '../../common/ConvertCoordinateValue';

function ScanProcessingControlCoordinateGrid(props) {
    const { scanProcessingCoordinates } = props;

    const getCoordinateError = (point1, point2) => {
        return Math.abs(point1 - point2).toFixed(3);
    }

    return (
        <div>
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow style={{ 'backgroundColor': '#cfcdc9', 'transparency': '85%' }}>
                                    <TableCell align="center" style={{
                                        'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                    }}><b>Name/Code</b></TableCell>
                                    <TableCell align="center" style={{
                                        'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                    }}><b>Type</b></TableCell>
                                    <TableCell align="center" style={{
                                        'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                    }}><b>X</b></TableCell>
                                    <TableCell align="center" style={{
                                        'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                    }}><b>Y</b></TableCell>
                                    <TableCell align="center" style={{
                                        'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                    }}><b>Z</b></TableCell>
                                    <TableCell align="center" ><b>Error</b></TableCell>
                                </TableRow>
                            </TableHead>
                            {scanProcessingCoordinates.map((coordinate) => (
                                <>
                                    <TableBody key={coordinate.id}>
                                        <TableRow key={coordinate.id}>

                                            <TableCell align="center" style={{
                                                'borderBottom': 'none',
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b>Name:</b> {coordinate.name}</TableCell>

                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b>Control</b></TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.xcontrolCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.ycontrolCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.zcontrolCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderBottom': 'none',
                                            }}></TableCell>
                                        </TableRow>

                                    </TableBody>
                                    <TableBody key={coordinate.id}>
                                        <TableRow key={coordinate.id}>

                                            <TableCell align="center" style={{
                                                'borderBottom': 'none',
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b>Code:</b> {coordinate.code}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b>Model</b></TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.xmodelCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.ymodelCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{ConvertCoordinateValue.getConvertedCoordinatedValue(coordinate.zmodelCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderBottom': 'none',
                                            }}>{coordinate.error}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                    <TableBody key={coordinate.id}>
                                        <TableRow key={coordinate.id}>

                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b></b></TableCell>

                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}><b>Error</b></TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{getCoordinateError(coordinate.xcontrolCoordinate, coordinate.xmodelCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{getCoordinateError(coordinate.ycontrolCoordinate, coordinate.ymodelCoordinate)}</TableCell>
                                            <TableCell align="center" style={{
                                                'borderRight': '1px solid rgba(224, 224, 224, 1)'
                                            }}>{getCoordinateError(coordinate.zcontrolCoordinate, coordinate.zmodelCoordinate)}</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </>
                            ))}
                        </Table>
                    </TableContainer>
                </div>

            </div>
        </div>
    )
}

export default ScanProcessingControlCoordinateGrid;