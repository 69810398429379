import mapboxgl from "!mapbox-gl";
import styled from "@emotion/styled";
import { useEffect, useRef, createRef } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import { render } from "react-dom";
import MapBoxConstants from "./mapboxviews/constants/MapBoxConstants";
import { isLatLongValid } from "app/main/apps/common/GeoUtils";

const MapBoXPanel = styled.div`
  height: calc(100vh - 280px);
  flex-grow: 4;
`;
mapboxgl.accessToken = MapBoxConstants.accessToken;

const MapBoxPointMarker = () => {
  return <PlaceIcon style={{ fontSize: "30px" }} color="error" />;
};

function SiteDetailsMapViewer(props) {
  let mapboxPanelRef = useRef();
  const { site } = props;

  useEffect(() => {
    if (mapboxPanelRef.current != undefined) {
      const map = new mapboxgl.Map({
        container: mapboxPanelRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: isLatLongValid(site.locationLat, site.locationLong)
          ? [site.locationLong, site.locationLat]
          : [-95.7, 37],
        zoom: 4,
        attributionControl: false,
      });
      map.addControl(new mapboxgl.FullscreenControl());
      map.addControl(new mapboxgl.NavigationControl());

      loadSiteMarker(map);
      return () => {
        map.remove();
      };
    }
  }, []);

  const loadSiteMarker = (map) => {
    if (isLatLongValid(site.locationLat, site.locationLong)) {
      const ref = createRef();
      ref.current = document.createElement("div");
      ref.current.color = "#FFFFFF";

      render(
        <MapBoxPointMarker text={site.name} siteId={site.id} />,
        ref.current
      );
      new mapboxgl.Marker({ element: ref.current })
        .setLngLat([site.locationLong, site.locationLat])
        .addTo(map);
    }
  };
  return (
    <div>
      <MapBoXPanel ref={mapboxPanelRef} />
    </div>
  );
}

export default SiteDetailsMapViewer;
