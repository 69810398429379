export const CheckPasswordValid = (password) => {
    try {
        if (password.length > 7) {
            var upperCaseAToZ = "ABCDEHGHIJKLMNOPQRSTUVWXYZ";
            var lowerCaseAToZ = "abcdefghijklmnoqrstuvwxyz";
            var number = "0123456789";
            var specialChar = ["!", "@", "#", "$", "%", "^", "&", "*"];
            var isUpperCaseExist = false;
            var isLowerCaseExist = false;
            var isNumberExist = false;
            var isSpecialCharExist = false;
            for (var i = 0; i <= password.length; i++) {
                var char = password.trim().charAt(i);
                if (!isUpperCaseExist) {
                    if (upperCaseAToZ.includes(char)) {
                        isUpperCaseExist = true;
                    }
                }
                if (!isLowerCaseExist) {
                    if (lowerCaseAToZ.includes(char)) {
                        isLowerCaseExist = true;
                    }
                }
                if (!isNumberExist) {
                    if (number.includes(char)) {
                        isNumberExist = true;
                    }
                }
                if (!isSpecialCharExist) {
                    if (specialChar.filter(x => x == char).length > 0) {
                        isSpecialCharExist = true;
                    }
                }
            }
            if (isNumberExist && isUpperCaseExist && isLowerCaseExist && isSpecialCharExist) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }

}