import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Typography,
  TextField,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Dialog,
  DialogContent,
} from "@mui/material";
import SERVICE_STATUS from "app/services/ServiceStatus";
import swal from "@sweetalert/with-react";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import { getOwnerOrganizationSites } from "../store/siteSlice";
import { loadAllAdminAccounts } from "../store/accountSlice";
import {
  createProject,
  updateProject,
  setUpdateUpdateProjectState,
  setUpdateCreateProjectState,
  getProjectFilesPath,
} from "../store/projectSlice";
import UPLOAD_TYPE from "../../upload/UploadType";
import { startUploadFile } from "../../upload/store/UploadSlice";
import FolderStructureModelPopup from "app/shared-components/dialogs/FolderStructureModelPopup";
import FileUpload from "../../../../shared-components/FileUpload";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { getComparator, stableSort } from "app/main/apps/common/SortUtils";

const Spacer = styled.div`
  flex-grow: ${(props) => props.space};
`;

const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
    cursor: pointer;
  }
`;

const TitlePanel = styled.div`
  display: flex;
`;

// eslint-disable-next-line complexity
function ProjectCreateOrEdit(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectId = params.id;

  const { accounts } = useSelector(({ admin }) => admin.accountApp);
  const { project } = useSelector(({ edit }) => edit.edit);
  const { role } = useSelector(({ auth }) => auth.user);
  const { projectFilePath, createProjectState, updateProjectState } =
    useSelector(({ admin }) => admin.projectApp);
  const {
    organizationId,
    isSiteCreate,
    handleOnCreateProject,
    handleOnCancelProject,
  } = props;

  const [isValid, setValidation] = useState(false);

  const [allValues, setAllValues] = useState({
    name: "",
    organizationId: 0,
    projectManager: 0,
    siteIds: [],
    projectNumber: "",
  });

  const [organizationSite, setOrganizationSite] = useState([]);

  useEffect(() => {
    if (role.includes("admin")) {
      dispatch(loadAllAdminAccounts());
    } else {
      getOrgnizationSite(0);
    }
  }, [role]);

  useEffect(() => {
    if (projectId) {
      if (project && project?.id == projectId) {
        setAllValues({
          name: project.name,
          organizationId: project.organizationId,
          projectManager: project.projectManager,
          siteIds: getsiteIds(),
          projectNumber: project.projectNumber,
        });
      } else {
        handleOnCancelButton();
      }
    }
  }, [projectId, project]);

  useEffect(() => {
    if (projectId && project?.id == projectId) {
      dispatch(getProjectFilesPath(projectId));
    }
  }, [projectId, project?.id]);

  const getsiteIds = () => {
    const siteIds = [];
    if (project.sites.length > 0) {
      project.sites.forEach((site) => {
        siteIds.push(site.id);
      });
    }
    return siteIds;
  };
  useEffect(() => {
    if (organizationId) {
      setAllValues({ ...allValues, organizationId: organizationId });
    }
  }, [organizationId]);

  const handleOnChange = (e) => {
    try {
      setAllValues({ ...allValues, [e.target.name]: e.target.value });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (role.includes("admin")) {
      if (allValues.organizationId > 0) {
        setOrganizationSite([]);
        getOrgnizationSite(allValues.organizationId);
      }
    }
  }, [allValues.organizationId, role]);

  const getOrgnizationSite = (organizationId) => {
    const organizationSite = getOwnerOrganizationSites(organizationId);
    Promise.resolve(organizationSite).then((response) => {
      if (response.status == 200) {
        setOrganizationSite(response.data);
      }
    });
  };

  const handleCreateOrUpdateClick = () => {
    try {
      if (validateForm()) {
        setValidation(false);
        handleCreateOrUpdate();
      } else {
        setValidation(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const validateForm = () => {
    try {
      if (allValues.name) {
        if (role.includes("admin")) {
          if (allValues.organizationId > 0) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCancelButton = () => {
    if (isSiteCreate) {
      handleOnCancelProject();
    } else {
      if (window.history.state.idx == 0) {
        navigate("/apps/dashboards/sites");
      } else {
        navigate(-1);
      }
    }
  };

  const successCallBack = () => {
    dispatch(setLoaderProgress(false));
    swal(
      "",
      projectId != undefined && projectId != null
        ? "Successfully updated"
        : "Successfully created",
      "success"
    ).then(() => {
      handleOnCancelButton();
    });
  };

  const onFailureCallBack = () => {
    dispatch(setLoaderProgress(false));
    swal("", "Something went wrong. Please try again", "error");
  };

  const handleCreateOrUpdate = () => {
    dispatch(setLoaderProgress(true));
    if (projectId != undefined && projectId != null) {
      var updateNewProject = project;
      updateNewProject = {
        ...updateNewProject,
        name: allValues.name,
        organizationId: allValues.organizationId,
        projectManager: allValues.projectManager,
        siteIds: allValues.siteIds,
        projectNumber: allValues.projectNumber,
      };
      var requestData = {
        data: updateNewProject,
        successCallBack: successCallBack,
        onFailureCallBack: onFailureCallBack,
      };
      dispatch(updateProject(requestData));
    } else {
      var data = {
        name: allValues.name,
        organizationId: allValues.organizationId,
        projectManager: allValues.projectManager,
        siteIds: allValues.siteIds,
        projectNumber: allValues.projectNumber,
      };
      const requestData = {
        data: data,
        successCallBack: successCallBack,
        onFailureCallBack: onFailureCallBack,
      };
      if (isSiteCreate) {
        handleOnCreateProject({
          data: data,
          uploadProjectFiles: uploadProjectFiles,
        });
      } else {
        dispatch(createProject(requestData));
      }
    }
  };

  const [uploadProjectFiles, setUploadProjectFiles] = useState([]);
  const handleOnDropProjectFiles = (files) => {
    setUploadProjectFiles([]);
    var filesArray = [];
    for (var i = 0; i < files.length; i++) {
      filesArray.push({ name: files[i].name, file: files[i] });
    }
    filesArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    setUploadProjectFiles(filesArray);
  };

  const getFilepath = (path) => {
    const str = path.split("/");
    str.splice(0, 2);
    return str.join("/");
  };
  if (
    createProjectState == SERVICE_STATUS.fulfilled ||
    updateProjectState == SERVICE_STATUS.fulfilled
  ) {
    dispatch(setUpdateCreateProjectState(""));
    dispatch(setUpdateUpdateProjectState(""));
    if (projectFilePath) {
      for (var i = 0; i < uploadProjectFiles.length; i++) {
        var uploadingFile = uploadProjectFiles[i].file;
        if (uploadingFile != null && uploadingFile != undefined) {
          var uploadData = {
            typeId: projectFilePath["id"],
            name: allValues.name,
            file: uploadingFile,
            isPrimary: false,
            type: UPLOAD_TYPE.projectfiles,
            uploadKey: `${projectFilePath[UPLOAD_TYPE.projectfiles]}${
              uploadingFile.path.includes("/")
                ? getFilepath(uploadingFile.path)
                : uploadingFile.path
            }`,
          };
          dispatch(startUploadFile(uploadData));
        }
      }
    }
    dispatch(setLoaderProgress(false));
    swal(
      "",
      projectId != undefined && projectId != null
        ? "Successfully updated"
        : "Successfully created",
      "success"
    ).then(() => {
      handleOnCancelButton();
    });
  }

  if (createProjectState == SERVICE_STATUS.rejected) {
    dispatch(setUpdateCreateProjectState(""));
    dispatch(setUpdateUpdateProjectState(""));
    dispatch(setLoaderProgress(false));
  }

  const [openModelPopup, setopenModelPopup] = useState(false);
  const [modelTitle, setmodelTitle] = useState("");
  const [modelScanPath, setModelScanPath] = useState("");
  const handleOnModelPopupClose = () => {
    setopenModelPopup(false);
  };

  const _handleOnFilesViewClick = () => {
    setopenModelPopup(true);
    setmodelTitle(allValues.name);
    setModelScanPath(projectFilePath[UPLOAD_TYPE.projectfiles]);
  };

  return (
    <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
      <div className="flex flex-col items-center justify-center w-full">
        <Card
          className="w-full max-w-384"
          style={{
            boxShadow: isSiteCreate == true ? "0px 0px 0px" : "",
          }}
        >
          <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
            <Typography
              variant="h6"
              className="mt-0 mb-24 font-semibold text-18 sm:text-24"
            >
              {projectId != undefined && projectId != null
                ? "Update Project"
                : "Create Project"}
            </Typography>

            {isValid ? (
              <p className="text-red mb-8">All fields are mandatory</p>
            ) : null}
            <TextField
              id="outlined-basic"
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              className="mb-16"
              value={allValues.name}
              error={isValid && allValues.name == ""}
              onChange={handleOnChange}
            />
            {role.includes("admin") && (
              <FormControl className="mx-0" sx={{ m: 1, width: 320 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Organization
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={allValues.organizationId}
                  name="organizationId"
                  onChange={handleOnChange}
                  label="Organization"
                  input={<OutlinedInput label="Organization" />}
                  error={isValid && allValues.organizationId == 0}
                  disabled={isSiteCreate}
                >
                  {stableSort(accounts, getComparator("asc", "name")).map(
                    (account) => (
                      <MenuItem key={account.id} value={account.id}>
                        <ListItemText primary={account.name} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            {!isSiteCreate && (
              <FormControl className="mx-0" sx={{ m: 1, width: 320 }}>
                <InputLabel id="demo-multiple-checkbox-label">Sites</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={allValues.siteIds}
                  name="siteIds"
                  onChange={handleOnChange}
                  label="Sites"
                  input={<OutlinedInput label="Sites" />}
                  error={isValid && allValues.siteIds.length == 0}
                  renderValue={(siteIds) =>
                    organizationSite
                      .filter((o) => siteIds.includes(o.id))
                      .map((a) => a.name + " ")
                      .join(", ")
                  }
                >
                  {stableSort(
                    organizationSite,
                    getComparator("asc", "name")
                  ).map((site) => (
                    <MenuItem key={site.id} value={site.id}>
                      <Checkbox checked={allValues.siteIds.includes(site.id)} />
                      <ListItemText
                        primary={
                          `${site.name}` +
                          `${
                            site.project
                              ? " Project: (" + site.project.name + ")"
                              : ""
                          }`
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              id="outlined-basic"
              label="Project Number"
              name="projectNumber"
              variant="outlined"
              fullWidth
              className="mb-16"
              value={allValues.projectNumber}
              onChange={handleOnChange}
            />
            <div>
              <div className="flex items-center">
                <Typography>Files</Typography>
                {projectId != null || projectId != undefined ? (
                  <Button
                    color="secondary"
                    size="small"
                    variant="text"
                    onClick={() => _handleOnFilesViewClick()}
                  >
                    Files View
                  </Button>
                ) : null}
              </div>
              <div className="w-full">
                <FileUpload
                  onDrop={(files) => handleOnDropProjectFiles(files)}
                ></FileUpload>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center w-full">
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-16 py-8 w-full mr-32"
                aria-label="Register"
                type="button"
                onClick={handleCreateOrUpdateClick}
              >
                {projectId != undefined && projectId != null
                  ? "Update"
                  : "Create"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-16 font-bold py-8 w-full ml-32"
                style={{ backgroundColor: "#E67260" }}
                aria-label="Register"
                type="button"
                onClick={handleOnCancelButton}
              >
                {"Cancel"}
              </Button>
            </div>
          </CardContent>
        </Card>

        <div>
          <Dialog
            open={openModelPopup}
            onClose={handleOnModelPopupClose}
            scroll={"paper"}
            fullScreen
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <TitlePanel>
              <div></div>
              <Spacer space={5} />
              <CloseButton onClick={() => handleOnModelPopupClose()}>
                <Close fontSize="inherit" />
              </CloseButton>
            </TitlePanel>
            <DialogContent dividers={"paper" === "paper"}>
              <FolderStructureModelPopup
                openModelPopup={openModelPopup}
                handleOnModelPopupClose={() => handleOnModelPopupClose()}
                modelTitle={modelTitle}
                modelScanPath={modelScanPath}
                type={UPLOAD_TYPE.projectfiles}
                id={project != null && project != undefined ? project?.id : 0}
              ></FolderStructureModelPopup>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ProjectCreateOrEdit;
