class ConvertCoordinateValue {

  static getConvertedCoordinatedValue = (value) => {
    const decimalStrs = value.toString().split('.');
    if (decimalStrs.length == 2) {
      var decimalPart = decimalStrs[1];
      if (decimalPart.length == 1) {
        return decimalStrs[0] + '.' + decimalStrs[1] + '00';
      }
      if (decimalPart.length == 2) {
        return decimalStrs[0] + '.' + decimalStrs[1] + '0';
      }
      if (decimalPart.length >= 3) {
        return decimalStrs[0] + '.' + decimalStrs[1];
      }
    } else {
      return value.toString() + '.000';
    }
  }
}

export default ConvertCoordinateValue;