import { lazy } from 'react';

const UploadFileStatus = lazy(() => import('./UploadFileStatus'));

const UploadAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/file/uploadstatus',
      element: <UploadFileStatus />,
    },
  ],
};

export default UploadAppConfig;
