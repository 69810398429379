import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function NewConfirmationDialogBox(props) {
  const { handleOnYesClick, handleOnNoClick, dialogTitle, dialogMessage } = props;

  return (
    <div>
      <Dialog
        open={true}
        onClose={props.OnCloseConfirmationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"        
      >
        <div style={{width: '420px'}}>
        <div className="flex flex-row justify-end pr-8 pt-8"><IconButton onClick={props.OnCloseConfirmationAlert}><HighlightOffIcon  style={{ color: "#37495759",width : '29px', height :'29px' }} /></IconButton></div>
        <DialogTitle id="dialog-title" className='text-center' style={{
          fontSize: 30,
          fontWeight: 600,
          letterSpacing: "-0.01em",
          textAlign: "center",
          color: "#58BEC9",
          paddingTop: '50px'          
        }}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent className='text-center'>
          <DialogContentText id="alert-dialog-description" style={{color: '#000000', fontSize: '20px'}}>
          {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='flex items-center mb-32 justify-center'>
          <Button style={{
            width: "105px",
            height: "54px",
            padding: "13px, 28px, 13px, 28px",
            borderRadius: "4px",
            margin: "20px",
            backgroundColor: "#58BEC9",
            color: "#ffffff",
            fontSize: '20px'
          }} onClick={() => { handleOnYesClick() }}>Yes</Button>

          <Button style={{
            width: "105px",
            height: "54px",
            padding: "13px, 28px, 13px, 28px",
            borderRadius: "4px",
            margin: "20px",
            backgroundColor: "#D9D9D9",
            color: "#ffffff",
            fontSize: '20px'
          }} onClick={() => { handleOnNoClick() }}>
            No
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}