import styled from "@emotion/styled";
import { Typography, Card, Link } from "@mui/material";
import {
  updateScanNotificationDialog,
  setSelectedNotification,
} from "../admin/store/notificationSlice";
import { useDispatch } from "react-redux";

const Description = styled.div`
  font-size: 15px;
`;

function ScanNotificationCard(props) {
  const { notification, handleClose } = props;
  const dispatch = useDispatch();

  const date = notification.createTime;
  const dateObject = new Date(date);
  const dateTime = dateObject.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  const handleOnNotificationClick = () => {
    dispatch(updateScanNotificationDialog(true));
    dispatch(setSelectedNotification(notification.scan));
    handleClose();
  };

  return (
    <Card
      className="flex items-center relative w-full rounded-16 p-20 min-h-64 shadow space-x-8 mb-8"
      elevation={0}
    >
      <div className="flex flex-col flex-auto">
        <Description>
          Please assign{" "}
          <Link
            onClick={() => handleOnNotificationClick()}
            style={{
              color: "#0D99FF",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {notification.scan.name}
          </Link>{" "}
          to a site
        </Description>
        <Typography
          className="mt-8 text-sm leading-none "
          color="text.secondary"
        >
          {dateTime}
        </Typography>
      </div>
    </Card>
  );
}
export default ScanNotificationCard;
