import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


export default function NewErrorDialogBox(props) {
  const { onOkClick, errorMessage, errorDescription, textAlign} = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={()=>onOkClick()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"        
      >
        <div style={{width: '420px',}}>
        <DialogTitle id="dialog-title" className='text-center' style={{
          fontSize: 30,
          fontWeight: 600,
          letterSpacing: "-0.01em",
          textAlign: "center",
          color: "#A1A1A1",
          paddingTop: '50px'          
        }}>
        <CancelOutlinedIcon  style={{fontSize: "1.73em"}} ></CancelOutlinedIcon> Error
        </DialogTitle>
        <DialogContent className='text-center'>
          <DialogContentText id="alert-dialog-description" style={{color: '#000000', fontSize: '15px',margin: '20px', fontWeight:'300px'}}>
            <div className='flex flex-col' style={{textAlign: textAlign}}>
            <span>{errorMessage}</span>
            <br></br>
            <span>{errorDescription}</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='flex items-center mb-32 justify-center'>
          <Button style={{
            width: "105px",
            height: "54px",
            padding: "13px, 28px, 13px, 28px",
            borderRadius: "4px",
            margin: "20px",
            backgroundColor: "#58BEC9",
            color: "#ffffff",
            fontSize: '20px'
          }} onClick={() => { onOkClick() }}>OK</Button>

        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}