import { Grid } from '@mui/material';

function RunningScan(props) {
    const {
        scan,
        index,
        handleOnDrop,
        handleDragScanStart,
        isFromFolder
    } = props;

    const getMonthByCount = (count) => {
        var day = "";
        switch (count) {
            case 0:
                day = "Jan";
                break;
            case 1:
                day = "Feb";
                break;
            case 2:
                day = "Mar";
                break;
            case 3:
                day = "Apr";
                break;
            case 4:
                day = "May";
                break;
            case 5:
                day = "Jun";
                break;
            case 6:
                day = "Jul";
                break;
            case 7:
                day = "Aug";
                break;
            case 8:
                day = "Sep";
                break;
            case 9:
                day = "Oct";
                break;
            case 10:
                day = "Nov";
                break;
            case 11:
                day = "Dec";
                break;

        }
        return day;
    }

    const getCurrentDateTime = (utcdate, isAlignToControlRunning) => {
        try {

            var date = new Date(utcdate + ' UTC');
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var month = getMonthByCount(date.getMonth());

            var date1 = date.getDate();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = month + " " + date1 + ', ' + ampm;
            if (isAlignToControlRunning) {
                if (date.getMinutes() < 30) {
                    var hour = hours > 12 ? (hours - 12) : hours;
                    strTime = hour + ":30" + ', ' + ampm;
                } else {
                    var newHours = date.getHours() + 1;
                    if (newHours < 24) {
                        var newampm = newHours >= 12 ? 'PM' : 'AM';
                        var hour = newHours > 12 ? (newHours - 12) : newHours;
                        strTime = hour + ":00" + ', ' + newampm;
                    }
                }
            }
            return strTime
        } catch (error) {
            return "";
        }
    }

    const getProcessingPercentage = (startTime, endTime) => {
        var startUtcDate = new Date(startTime + ' UTC');
        var endUtcDate = new Date(endTime + ' UTC');
        var currentLocalDate = new Date();
        var totalTime = endUtcDate.getTime() - startUtcDate.getTime();
        var completedTime = currentLocalDate.getTime() - startUtcDate.getTime();
        var percentage = (completedTime / totalTime) * 100;
        if (percentage >= 0 && percentage <= 100) {
            return percentage.toFixed(2);
        } else if (percentage >= 100) {
            return 100;
        }
        else {
            return 0;
        }
    }

    return (
        <div style={{ backgroundColor: index % 2 == 0 ? '#F8F8F8' : '#D9D9D954' }} className="py-4">
            <div className='flex flex-row justify-start items-center ml-48 pl-4'
                style={{ height: '25px' }}
                draggable
                onDragOver={(e) => { e.preventDefault() }}
                onDrop={(e) => handleOnDrop(e, scan.id)}
                onDragStart={() => { handleDragScanStart(scan.id) }}
            >
                <Grid container>
                    <Grid xs={8} className='flex flex-row justify-start items-center'>
                        <div
                            className="flex justify-center items-center p-4 mr-8"
                            style={{
                                width: '19px',
                                height: '19px',
                                position: 'relative'
                            }}
                        >
                            <div
                                style={{
                                    width: '19px',
                                    height: '19px',
                                    backgroundColor: '#BBBBBB',
                                    borderRadius: '50%',
                                    position: 'absolute'
                                }}></div>
                            <div
                                style={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: index % 2 == 0 ? '#F8F8F8' : 'rgb(237 237 237)',
                                    borderRadius: '50%',
                                    position: 'absolute'
                                }}
                            ></div>
                            <div style={{
                                width: '12px',
                                height: '12px',
                                content: "",
                                borderRadius: '50%',
                                position: 'absolute',
                                background: `conic-gradient(#BBBBBB calc(${getProcessingPercentage(scan.processingStartDate, scan.processingEstimateDate)}%),#D9D9D954 0)`
                            }}></div>
                        </div>
                        <span style={{
                            color: '#BBBBBB',
                            fontSize: '13px',
                            fontWeight: '400'
                        }}>{scan.name}</span>
                    </Grid>
                    <Grid xs={4} className='flex flex-row justify-start items-center'>
                        <span style={{
                            color: '#BBBBBB',
                            fontSize: '13px',
                            fontWeight: '300'
                        }}>
                            {scan.processingEstimateDate ? getCurrentDateTime(scan.processingEstimateDate, scan.isAlignToControlRunning) : ""}
                        </span>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default RunningScan;