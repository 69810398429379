/* eslint import/no-extraneous-dependencies: off */
import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const loadImages = (scanId, offset, limit) => async (dispatch, getState) => {

    const url = APP_CONFIG.api + `v1/scans/${scanId}/images?offset=${offset}&limit=${limit}`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            return dispatch(setImages(response.data));
        }
    }).catch((error) => {
        console.warn('Cannot retrieve scans data', error);
    });
};

const initialState = [];

const imagesSlice = createSlice({
    name: 'scanApp/scans/images',
    initialState,
    reducers: {
        setImages: (state, action) => action.payload,
    },
    extraReducers: {},
});

export const {setImages} = imagesSlice.actions;

export default imagesSlice.reducer;
