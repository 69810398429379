import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const loadLoginUserSites = () => async (dispatch) => {
  const url = APP_CONFIG.api + `v1/site/getLoginUserSites`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  Promise.resolve(response)
    .then((response) => {
      if (response.status === 200) {
        const sortedSites = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Convert Date String to Date Object to ease of UX
        sortedSites.map((site) => {
          site.startDate = new Date(Date.parse(site.startDate));
          site.completionDate = new Date(Date.parse(site.completionDate));
        });

        return dispatch(setSites(sortedSites));
      }
    })
    .catch((error) => {
      console.warn("Cannot retrieve sites data", error);
    });
};

export const getSiteDetails = (siteId) => {
  const url = APP_CONFIG.api + `v1/site/getSiteDetails/${siteId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const saveSiteCoordinatesInfo = (saveData) => {
  const url = APP_CONFIG.api + `v1/site/saveSiteCoordinatesInfo`;

  const response = axios.post(url, saveData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateSiteCoordinates = createAsyncThunk(
  "scan/scanApp/updateSiteCoordinates",
  async (saveData) => {
    const url = `${APP_CONFIG.api}v1/site/updateSiteCoordinates?scanId=${saveData.scanId}`;

    const response = await axios.post(url, saveData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response.data;
    saveData.successCallBack(response.data);
  }
);

export const getSiteCoordinatesInfo = (siteCoordinateId) => {
  const url =
    APP_CONFIG.api + `v1/site/getSiteCoordinatesInfo/${siteCoordinateId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getSiteCoordinates = (siteId) => {
  const url = APP_CONFIG.api + `v1/site/getSiteCoordinates/${siteId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const deleteSiteCoordinates = (siteCoordinateId) => {
  const url =
    APP_CONFIG.api + `v1/site/deleteSiteCoordinates/${siteCoordinateId}`;
  const response = axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const initialState = [];

const sitesSlice = createSlice({
  name: "scanApp/sites",
  initialState,
  reducers: {
    setSites: (state, action) => action.payload,
  },
  extraReducers: {},
});

export const { setSites } = sitesSlice.actions;

export default sitesSlice.reducer;
