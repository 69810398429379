import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import AlignToControlButton from "./AlignToControlButton";

const StepperReset = ({ handleReset, handleOnAlignToControlClick }) => {
  return (
    <Paper
      square
      elevation={6}
      sx={{
        p: 3,
        borderRadius: "16px",
        background: "linear-gradient(to bottom right, #D9E2EC, white)",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>
        Control points configured!
      </Typography>
      <Typography sx={{ color: "#4e4e50", mt: "4px" }}>
        You can now align the point cloud to the control.
      </Typography>

      <div className="flex mt-24">
        <AlignToControlButton
          handleOnAlignToControlClick={handleOnAlignToControlClick}
        />
        <Button onClick={handleReset} sx={{ mr: 1 }}>
          Reset
        </Button>
      </div>
    </Paper>
  );
};

export default StepperReset;
