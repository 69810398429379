import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import APP_CONFIG from "../../../../AppConfig";


export const getAllTemplate = createAsyncThunk('admin/templateApp/getAllTemplate', async () => {
    const url = APP_CONFIG.api + `v1/template/getTemplate`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});

export const createTemplate = createAsyncThunk(
    'admin/templateApp/createTemplate',
    async (createTemplate) => {
        const url = APP_CONFIG.api + `v1/template/createTemplate`;
        const response = await axios.post(url, createTemplate, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const updateTemplate = createAsyncThunk(
    'admin/templateApp/updateTemplate',
    async (updateTemplate) => {
        const url = APP_CONFIG.api + `v1/template/updateTemplate/${updateTemplate.id}`;
        const response = await axios.put(url, updateTemplate, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;

        return data;
    }
);


export const removeTemplate = createAsyncThunk(
    'admin/templateApp/removeTemplate',
    async (templateId) => {

        const url = APP_CONFIG.api + `v1/template/deleteTemplate/${templateId}`;

        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const getTemplFilesPath = createAsyncThunk(
    'admin/templateApp/getDeviceFilesPath',
    async (templateId) => {
        const url = APP_CONFIG.api + `v1/template/getTemplateFilePathById/${templateId}`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const data = await response.data;
        return data;
    }
);



export const updateTemplateFileUrl = createAsyncThunk(
    'admin/templateApp/updateTemplateFileUrl',
    async (updateTemplate) => {

        const url = APP_CONFIG.api + `v1/template/updateTemplateFileUrl/${updateTemplate.id}`;

        const response = await axios.put(url, updateTemplate, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const updatecreateTemplateState = (value) => async (dispatch) => {
    dispatch(setcreateTemplateState(value));
}
export const updateupdateTemplateState = (value) => async (dispatch) => {
    dispatch(setupdateTemplateState(value));
}

export const updateremoveTemplateState = (value) => async (dispatch) => {
    dispatch(setremoveTemplateState(value));
}

const templateSlice = createSlice({
    name: 'admin/templateApp',
    initialState: {
        template: [],
        loadingState: '',
        createTemplateState: '',
        updateTemplateState: '',
        removeTemplateState: '',
        templateFiles: [],
        templateFilePath: {},

    },

    reducers: {
        setcreateTemplateState: (state, action) => {
            state.createTemplateState = action.payload;
        },
        setupdateTemplateState: (state, action) => {
            state.updateTemplateState = action.payload;
        },
        setremoveTemplateState: (state, action) => {
            state.removeTemplateState = action.payload;
        },
        setTemplateFiles: (state, action) => {
            state.templateFiles = action.payload;
        },
        setTemplateFilePath: (state, action) => {
            state.templateFilePath = action.payload;
        }

    },

    extraReducers: {
        [getAllTemplate.fulfilled]: (state, action) => {
            state.template = action.payload;
            state.loadingState = SERVICE_STATUS.fulfilled;
        },
        [getAllTemplate.rejected]: (state) => {
            state.loadingState = SERVICE_STATUS.rejected;
        },
        [getAllTemplate.pending]: (state) => {
            state.loadingState = SERVICE_STATUS.pending;
        },
        [createTemplate.fulfilled]: (state, action) => {
            state.templateFilePath = action.payload;
            state.createTemplateState = SERVICE_STATUS.fulfilled;
        },
        [createTemplate.pending]: (state) => {
            state.createTemplateState = SERVICE_STATUS.pending;
        },
        [createTemplate.rejected]: (state) => {
            state.createTemplateState = SERVICE_STATUS.rejected;
        },
        [updateTemplate.fulfilled]: (state, action) => {
            state.templateFilePath = action.payload;
            state.updateTemplateState = SERVICE_STATUS.fulfilled;
        },
        [updateTemplate.pending]: (state) => {
            state.updateTemplateState = SERVICE_STATUS.pending;
        },
        [updateTemplate.rejected]: (state) => {
            state.updateTemplateState = SERVICE_STATUS.pending;
        },
        [removeTemplate.fulfilled]: (state) => {
            state.removeTemplateState = SERVICE_STATUS.fulfilled;
        },
        [removeTemplate.pending]: (state) => {
            state.removeTemplateState = SERVICE_STATUS.pending;
        },
        [removeTemplate.rejected]: (state) => {
            state.removeTemplateState = SERVICE_STATUS.rejected;
        },
        [getTemplFilesPath.fulfilled]: (state, action) => {
            state.templateFiles = action.payload;
        },
    },
});

export const { setcreateTemplateState, setupdateTemplateState, setremoveTemplateState, setTemplateFilePath, setTemplateFiles } = templateSlice.actions;

export default templateSlice.reducer;