import SiteViewer from "./SiteViewer";

import ImageViewer from "./potree/ImageViewer";
import MapBoxImageViewer from "./mapboxviews/MapBoxImageViewer";

const ScansAppConfig = {



  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'apps/sites/:siteId',
      element: <SiteViewer />
    },
    {
      path: 'apps/panos/:scanId/:index',
      element: <ImageViewer />
    },
    {
      path: 'apps/gpspanos/:scanId/:index',
      element: <MapBoxImageViewer />
    },
  ],
};

export default ScansAppConfig;
