
import CommonTable from "../../common/CommonTable";


function ScanProcessingStatusGrid(props) {
    const { scanProcessingTaskStatusHistories, toLocalDateTime } = props

    const RenderLastUpdateTimeCell = (row) => {
        return toLocalDateTime(row.lastUpdateTime);
    }

    const getTableConfig = () => {
        return [
            { id: 'step', label: 'Step', display: true, isSortable: true, contentType: 'text', width: 150 },
            { id: 'statusMessage', label: 'Status', display: true, isSortable: true, contentType: 'text' },
            { id: 'lastUpdateTime', label: 'Update Time', display: true, isSortable: true, contentType: 'custom', content: RenderLastUpdateTimeCell }
        ];
    }

    return (
        <div>
            <div className="w-full">
                <div className="flex flex-col md:flex-row sm:p-8" >
                    <div className="flex flex-1 flex-col min-w-0">
                        <div className="flex justify-center max-w-full relative">
                            <div className="flex flex-1 max-w-full">
                                <div className="flex flex-1 justify-center max-w-full">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <CommonTable
                                            renderData={scanProcessingTaskStatusHistories}
                                            dataConfig={getTableConfig()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanProcessingStatusGrid;