import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';



const TitlePanel = styled.div`
  display: flex;
`;

const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	cursor: pointer;
  }
`;

const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;

function PDFViewerDialog(props) {

    const { src, onClose } = props;

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: () => {
                const fileName = src.split('/')[src.split('/').length - 1];
                return `${fileName}`;
            },
        }
    });
    const { Toolbar } = toolbarPluginInstance;
    const renderToolbar = () => {
        return <Toolbar></Toolbar>
    }
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });

    const [pdfURL, setPdfURL] = useState('');
    function loadPDF(src) {
        const response = axios.get(src, {
            headers: {
            },
            responseType: 'blob',
        });

        return Promise.resolve(response);
    };

    useEffect(() => {
        let isMounted = true;
        loadPDF(src)
            .then((response) => {
                if (isMounted) {
                    var newBlob = response.data;
                    newBlob.name = src.split('/')[src.split('/').length - 1]
                    setPdfURL(window.URL.createObjectURL(newBlob));
                }
            })
            .catch((error) => console.error(error));
        return () => {
            isMounted = false;
        };
    }, [src]);
    return (
        <div>
            <Dialog
                fullScreen
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" open>

                <DialogTitle>
                    <TitlePanel className='flex flex-row justify-between'>
                       <div>{src.split('/')[src.split('/').length - 1]}</div>
						<Spacer space={5}/>
                        <CloseButton onClick={onClose}><Close fontSize="inherit" /></CloseButton>
                    </TitlePanel>
                </DialogTitle>
                <DialogContent>
                    {
                        pdfURL &&
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                            <Viewer plugins={[toolbarPluginInstance, defaultLayoutPluginInstance]} fileUrl={pdfURL}></Viewer>
                        </Worker>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default PDFViewerDialog;