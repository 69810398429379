import styled from "@emotion/styled";

const DRAWER_WIDTH = 400;

export const OuterPanel = styled.div`
  display: flex;
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${window.screen.width <= 480
    ? "0px"
    : (props) => (props.open ? DRAWER_WIDTH + "px" : "0px")};
  width: 100%;
`;

export const MapBoxPanel = styled.div`
  height: calc(100vh - 80px);
  width: 100%;
  flex-grow: 4;
`;

export const Header = styled.div`
  color: #ffffff;
  background-color: #9aa1a4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  height: 50px;
  z-index: 1;
  margin-top: 0px;
`;
