import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Button, TextField } from "@mui/material";
import { Row, InnerPanel } from "./SinglePointEditor.style";

function SinglePointEditor(props) {
  const { onDone, updateInfo, coordinate, index, clearModelCoordinate } = props;

  // const [unit, setUnit] = useState("utm");
  const unit = "utm";
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [z, setZ] = useState("");
  const [doneLabel, setDoneLabel] = useState("Add New Coordinate");

  useEffect(() => {
    if (coordinate) {
      setX(coordinate.control ? coordinate.control.x.toString() : "");
      setY(coordinate.control ? coordinate.control.y.toString() : "");
      setZ(coordinate.control ? coordinate.control.z.toString() : "");
      setName(coordinate.name ? coordinate.name : "");
      setCode(coordinate.code ? coordinate.code : "");
      setDoneLabel("Update Coordinate");
    }
  }, [coordinate]);

  function done() {
    if (x && y && z) {
      if (x.trim().length === 0) {
        updateInfo(index, name, code);
      } else {
        onDone(
          {
            name: name,
            code: code,
            visible: true,
            model: coordinate ? coordinate?.model : undefined,
            control: { x: Number(x), y: Number(y), z: Number(z) },
          },
          index
        );
        setName("");
        setCode("");
        setX("");
        setY("");
        setZ("");
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  function handleOnClearModel() {
    clearModelCoordinate(index);
  }

  const isValid = () => {
    if (x.length == 0 || y.length == 0 || z.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkIsDecimalNumber = (number) => {
    const regex = /^-?\d*\.?\d*$/;
    if (regex.test(number)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <InnerPanel>
      <Row>
        <TextField
          value={name}
          onChange={(event) => setName(event.target.value)}
          size="small"
          label="Name"
          variant="outlined"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          value={code}
          onChange={(event) => setCode(event.target.value)}
          size="small"
          label="Code"
          variant="outlined"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          value={x}
          onChange={(event) => {
            if (checkIsDecimalNumber(event.target.value)) {
              setX(event.target.value);
            }
          }}
          size="small"
          label={unit === "utm" ? "X" : "Latitude"}
          variant="outlined"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          value={y}
          onChange={(event) => {
            if (checkIsDecimalNumber(event.target.value)) {
              setY(event.target.value);
            }
          }}
          size="small"
          label={unit === "utm" ? "Y" : "Longitude"}
          variant="outlined"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          value={z}
          onChange={(event) => {
            if (checkIsDecimalNumber(event.target.value)) {
              setZ(event.target.value);
            }
          }}
          size="small"
          label={unit === "utm" ? "Z" : "Elevation"}
          variant="outlined"
          fullWidth
        />
      </Row>
      <div className="flex flex-row items-center justify-center w-full">
        <div>
          <Button
            onClick={done}
            disabled={!isValid()}
            variant="outlined"
            autoFocus
            className=" mt-16 py-8 w-full mr-32"
            style={{ maxWidth: "200px" }}
          >
            {doneLabel}
          </Button>
        </div>
      </div>
    </InnerPanel>
  );
}

SinglePointEditor.propTypes = {
  coordinate: PropTypes.object,
  onDone: PropTypes.func.isRequired,
};

export default SinglePointEditor;
