import FuseUtils from "@fuse/utils";
import appsConfigs from "app/main/apps/appsConfigs";
import RegisterConfig from "app/main/register/RegisterConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import Page404 from "app/main/apps/errors/Page404";

const routeConfigs = [...appsConfigs, RegisterConfig];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, [
    "admin",
    "organisationadmin",
    "projectmanager",
    "staff",
    "user",
    "demouser",
  ]),
  {
    path: "/",
    element: <Navigate to="apps/dashboards/sites" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
