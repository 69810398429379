import axios from "axios";
import APP_CONFIG from "app/AppConfig";

export const sendContent = (text) => async (dispatch, getState) => {

    const url = APP_CONFIG.api + `v1/support/sendSupportEmail`;

    var formData = new FormData();
    formData.append("contentText", text);
    console.log(formData);
    const response = axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    Promise.resolve(response).then((response) => {

    }).catch((error) => {
        console.error(error);
    });
};