import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import FileUpload from "../FileUpload";

const TitlePanel = styled.div`
  display: flex;
`;

function UploadDialog(props) {
  const { onClose, onAction } = props;

  const [uploadFiles, setUploadFiles] = useState([]);
  const handleOnDropFiles = (files) => {
    setUploadFiles([]);
    var filesArray = [];
    for (var i = 0; i < files.length; i++) {
      filesArray.push({ name: files[i].name, file: files[i] });
    }
    filesArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    setUploadFiles(filesArray);
  };

  const onClickAction = () => {
    onAction(uploadFiles);
  };
  return (
    <div>
      <Dialog
        fullWidth
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open
      >
        <DialogTitle>
          <TitlePanel className="flex flex-row justify-center">
            <Typography
              variant="h6"
              className="text-center mt-0 font-semibold text-18 sm:text-24"
            >
              Upload
            </Typography>
          </TitlePanel>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col">
            <FileUpload
              onDrop={(files) => handleOnDropFiles(files)}
            ></FileUpload>
            <div className="flex flex-row justify-end">
              <Button onClick={onClose} color="error" variant="outlined">
                Close
              </Button>
              <Button
                onClick={() => onClickAction()}
                className="ml-8"
                variant="contained"
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UploadDialog;
