import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { MenuOpen } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import ToggleSwitch from "app/shared-components/toggleSwitch/ToggleSwitch";
import ToolbarScans from "../ToolbarScans";
import ScreenshotAndNavigation from "../tools/ScreenshotAndNavigation";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import PointSettings from "../tools/PointSettings";
import ClippingAndCrossSection from "../tools/ClippingAndCrossSection";
import Measurement from "../tools/Measurement";
import CommonToggle from "../tools/CommonToggle";
import GeoTools from "../tools/GeoTools";
import Classification from "../tools/Classification";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";
import VegetationRange from "./VegetationRange";
import { useSelector } from "react-redux";

const OuterPanel = styled.div`
  width: 400px;
  padding-top: 0px;
  background-color: #f8f8f8;
`;

const Header = styled.div`
  color: #ffffff;
  background-color: #b9b9b9;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  position: fixed;
  width: 400px;
  height: 50px;
  z-index: 100;
`;
const Spacer = styled.div`
  flex-grow: 3;
`;
const CloseButton = styled.div`
  cursor: pointer;
`;
// const ResetIcon = styled.div`
// padding: 1px;
// border: 2px solid #565656;
// border-radius: 20px;
// margin: 0px;
// cursor: pointer;
// font-size: 14px;
//   color: ${props => props.selected ? '#ffffff' : '#565656'};
// `;

// const Reset = styled.div`
//   padding-left: 10px;
// `;

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

function Toolbar(props) {
  const {
    scans,
    handleRetrieveSiteCoordinatesInfo,
    loadedScanIds,
    onToggleClassificationVisibility,
    onChangeClassificationColor,
    selectedSite,
    onToggleCurrentViewer,
    currentViewer,
    updateClassifications,
    dataPanel,
    enableEyeDomeLighting,
    edlRadius,
    edlStrength,
    onChangeEdlRadius,
    onChangeEdlStrength,
    onToggleEyeDomeLighting,
    useHighQuality,
    onToggleUseHighQuality,
    onResetEDLSettings,
    checkIsInEditMode,
    imageMarkersVisible,
    onToggleImageMarkers,
    feetLengthSwitch,
    onToggleFeetLength,
    classifications,
    activeAttribute,
    onActiveAttribute,
    isOrthographicCamera,
    onChangeCameraProjection,
    isFixed,
    onChangeFixed,
    onChangePointSize,
    pointSize,
    brightness,
    onChangeBrightness,
    unit,
    selectAllScans,
    getSnap,
    onSelectCrossSection,
    crossSection,
    crossSectionLineWidth,
    onChangeCrossSectionLineWidth,
    showCrossSectionProfileButton,
    onClickCrossSectionShowProfile,
    coordinateSystem,
    onClickDataPanel,
    gamma,
    onChangeGamma,
    contrast,
    onChangeContrast,
    viewer,
    retrieveSiteCoordinatesInfo,
  } = props;

  const [navigation, setNavigation] = useState(null);
  const [clipping, setClipping] = useState(null);
  const [measurement, setMeasurement] = useState(null);
  const [isClipping, setIsClipping] = useState(null);

  const [commonToggleMinimize, SetCommonToggleMinimize] = useState(false);
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    if (!props.measuring) {
      setMeasurement(null);
    }
  }, [props.measuring]);

  useEffect(() => {
    if (!props.clippingStart) {
      setClipping(null);
    }
  }, [props.clippingStart]);

  function togglePointCloud(event) {
    const scanId = event.currentTarget.id;
    const scan = scans.find((x) => x.id == scanId);

    if (!scan) return;

    const checked = event.target.checked;
    props.onTogglePointCloud(scan, checked);

    if (dataPanel != "alignToControl") return;

    const coordinatesId = scans
      ?.filter((scan) => String(scan.id) === scanId)
      .map((scan) => scan.coordinatesId);

    if (coordinatesId[0] != null)
      retrieveSiteCoordinatesInfo(viewer.scene.scene, coordinatesId[0]);
    else retrieveSiteCoordinatesInfo(viewer.scene.scene, null);
  }

  function selectNavigation(event) {
    const navigation = event.currentTarget.id;
    setNavigation(navigation);
    props.onSelectNavigation(navigation);
  }

  function selectClipping(event) {
    const clipping = event.currentTarget.id;
    setClipping(clipping);
    props.onSelectClipping(clipping);
  }

  function selectMeasurement(event) {
    if (!checkIsInEditMode()) {
      const measurement = event.currentTarget.id;
      setMeasurement(measurement);
      props.onSelectMeasurement(measurement);
    }
  }

  function reset(event) {
    const type = event.currentTarget.id;
    if (type === "measurement") {
      setMeasurement(null);
    } else if (type === "clipping" || type === "crossSection") {
      setClipping(null);
      setIsClipping(null);
    } else {
      console.error("Unrecognized type for reset = " + type);
    }
    props.onReset(type);
  }

  function clip(event) {
    setIsClipping(event.currentTarget.id);
    props.onClip(event.currentTarget.id, clipping);
  }

  function findLoadedScan(scanId) {
    return loadedScanIds.find((loadedScanId) => loadedScanId === scanId);
  }

  const selectAllCompletedScans = (event) => {
    const checked = event.target.checked;
    selectAllScans(checked);
  };

  const handleOnChangeAccordion = () => {
    SetCommonToggleMinimize(!commonToggleMinimize);
    if (commonToggleMinimize) {
      MixPanelIntegration.EventClicks("Images Close Click");
    } else {
      MixPanelIntegration.EventClicks("Images Expand Click");
    }
  };

  return (
    <OuterPanel>
      <Header className="items-center">
        <div style={{ color: "#010c16f0" }}>
          {" "}
          <BuildIcon /> Tools
        </div>
        <Spacer />

        <div style={{ marginRight: "10px" }}>
          <ToggleSwitch
            checked={false}
            onToggleChange={onToggleCurrentViewer}
            yes={"2D"}
            no={"3D"}
            style={{
              fontSize: "13px",
            }}
          ></ToggleSwitch>
        </div>

        <CloseButton onClick={props.toggleDrawer}>
          <MenuOpen style={{ color: "#4D4D4F" }} />
        </CloseButton>
      </Header>

      <ToolbarScans
        scans={scans}
        currentViewer={currentViewer}
        loadedScanIds={loadedScanIds}
        findLoadedScan={findLoadedScan}
        zoomToPointCloud={props.zoomToPointCloud}
        zoomOutPointCloud={props.zoomOutPointCloud}
        togglePointCloud={togglePointCloud}
        dataPanel={dataPanel}
        siteId={selectedSite.id}
        selectAllCompletedScans={selectAllCompletedScans}
      />

      {/* </Collapse> */}

      <Divider />
      {props.showToolbarItems && loadedScanIds.length != 0 && (
        <>
          <ScreenshotAndNavigation
            selectNavigation={selectNavigation}
            getSnap={getSnap}
            isOrthographicCamera={isOrthographicCamera}
            onChangeCameraProjection={onChangeCameraProjection}
            navigation={navigation}
            currentViewer={currentViewer}
          />
          <PointSettings
            onResetEDLSettings={onResetEDLSettings}
            isFixed={isFixed}
            onChangeFixed={onChangeFixed}
            onChangePointSize={onChangePointSize}
            pointSize={pointSize}
            useHighQuality={useHighQuality}
            onToggleUseHighQuality={onToggleUseHighQuality}
            onChangeBrightness={onChangeBrightness}
            brightness={brightness}
            enableEyeDomeLighting={enableEyeDomeLighting}
            edlRadius={edlRadius}
            edlStrength={edlStrength}
            onToggleEyeDomeLighting={onToggleEyeDomeLighting}
            isOrthographicCamera={isOrthographicCamera}
            onChangeEdlStrength={onChangeEdlStrength}
            onChangeEdlRadius={onChangeEdlRadius}
            gamma={gamma}
            onChangeGamma={onChangeGamma}
            contrast={contrast}
            onChangeContrast={onChangeContrast}
          />

          <ClippingAndCrossSection
            reset={reset}
            selectClipping={selectClipping}
            clipping={clipping}
            isClipping={isClipping}
            clip={clip}
            onSelectCrossSection={onSelectCrossSection}
            crossSection={crossSection}
            crossSectionLineWidth={crossSectionLineWidth}
            onChangeCrossSectionLineWidth={onChangeCrossSectionLineWidth}
            showCrossSectionProfileButton={showCrossSectionProfileButton}
            onClickCrossSectionShowProfile={onClickCrossSectionShowProfile}
          />

          <Measurement
            reset={reset}
            selectMeasurement={selectMeasurement}
            measurement={measurement}
            coordinateSystem={coordinateSystem}
            feetLengthSwitch={feetLengthSwitch}
            onToggleFeetLength={onToggleFeetLength}
            currentViewer={currentViewer}
          />

          <Accordion
            expanded={commonToggleMinimize}
            sx={{
              "&.MuiAccordion-root.Mui-expanded": {
                margin: "1px 0 0px 0px",
              },
              "&:last-of-type": {
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              },
            }}
          >
            <AccordionSummary
              sx={{
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "auto",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "0px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              expandIcon={
                commonToggleMinimize ? (
                  <IconButton
                    onClick={() => {
                      handleOnChangeAccordion();
                    }}
                  >
                    <IndeterminateCheckBoxOutlinedIcon
                      style={{ color: "#BBBBBB" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      handleOnChangeAccordion();
                    }}
                  >
                    <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
                  </IconButton>
                )
              }
              aria-controls="panel5bh-content"
              id="panel5"
            >
              <TypographyStyles>Images</TypographyStyles>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                "& .MuiAccordionDetails-root": {
                  padding: "8px 10px 16px",
                },
              }}
            >
              <CommonToggle
                toggleChecked={imageMarkersVisible}
                handleOnToggleChange={onToggleImageMarkers}
              />
            </AccordionDetails>
          </Accordion>

          <GeoTools
            handleRetrieveSiteCoordinatesInfo={
              handleRetrieveSiteCoordinatesInfo
            }
            onClickDataPanel={onClickDataPanel}
          />

          <Classification
            activeAttribute={activeAttribute}
            onActiveAttribute={onActiveAttribute}
            classifications={classifications}
            selectedSite={selectedSite}
            onChangeClassificationColor={onChangeClassificationColor}
            onToggleClassificationVisibility={onToggleClassificationVisibility}
            updateClassifications={updateClassifications}
          />
          {user.role && user.role.includes("admin") && (
            <VegetationRange viewer={viewer} unit={unit} />
          )}
        </>
      )}
    </OuterPanel>
  );
}

Toolbar.propTypes = {
  toggleDrawer: PropTypes.func,
  scans: PropTypes.array,
  loadedScanIds: PropTypes.array,
  classifications: PropTypes.object,
  activeAttribute: PropTypes.string,
  onChangeClassificationColor: PropTypes.func,
  onToggleClassificationVisibility: PropTypes.func,
  onSelectNavigation: PropTypes.func,
  onSelectMeasurement: PropTypes.func,
  onSelectClipping: PropTypes.func,
  onTogglePointCloud: PropTypes.func,
  onToggleImageMarkers: PropTypes.func,
  onActiveAttribute: PropTypes.func,
  onClip: PropTypes.func,
  onReset: PropTypes.func,
  measuring: PropTypes.bool,
  imageMarkersVisible: PropTypes.bool,
  clippingStart: PropTypes.bool,
  onClickDataPanel: PropTypes.func,
  crossSection: PropTypes.bool,
  onSelectCrossSection: PropTypes.func,
  onChangeCrossSectionLineWidth: PropTypes.func,
  crossSectionLineWidth: PropTypes.string,
  showCrossSectionProfileButton: PropTypes.bool,
  onClickCrossSectionShowProfile: PropTypes.func,
  role: PropTypes.array,
  handleOnScanCreationClick: PropTypes.func,
  selectedSite: PropTypes.object,
  zoomToPointCloud: PropTypes.func,
  zoomOutPointCloud: PropTypes.func,
};

export default Toolbar;
