import { Checkbox, IconButton } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";

const CompletedScans = ({
  scan,
  togglePointCloud,
  findLoadedScan,
  loadedScanIds,
}) => {
  return (
    <>
      <Checkbox
        checked={!!(loadedScanIds && findLoadedScan(scan.id))}
        style={{ color: "#6cccd7", width: "20px" }}
        onChange={togglePointCloud}
        id={scan.id.toString()}
        className="p-4 mx-8"
      />
      <span
        style={{
          fontSize: "13px",
          fontWeight: "300",
        }}
      >
        {scan.name.substring(0, 50)}
      </span>
    </>
  );
};

const TwoDimensionScansView = ({
  scan,
  loadedScanIds,
  findLoadedScan,
  zoomToPointCloud,
}) => {
  return (
    <IconButton
      disabled={!(loadedScanIds && findLoadedScan(scan.id))}
      onClick={() => {
        zoomToPointCloud(scan.id);
      }}
      className={`py-0`}
      sx={{ width: 18, height: 18 }}
    >
      <ZoomInMapOutlinedIcon
        sx={{ width: 18, height: 18 }}
        style={{
          color: !(loadedScanIds && findLoadedScan(scan.id))
            ? "#D9D9D9"
            : "#454545",
        }}
      />
    </IconButton>
  );
};

const ThreeDimensionScansView = ({
  scan,
  loadedScanIds,
  findLoadedScan,
  zoomToPointCloud,
}) => {
  return (
    <IconButton
      disabled={!(loadedScanIds && findLoadedScan(scan.id))}
      sx={{ width: 18, height: 18 }}
      onClick={() => {
        zoomToPointCloud(scan.name);
      }}
      className={`py-0`}
    >
      <ZoomIn
        sx={{ width: 18, height: 18 }}
        style={{
          color: !(loadedScanIds && findLoadedScan(scan.id))
            ? "#D9D9D9"
            : "#454545",
        }}
      />
    </IconButton>
  );
};

function CompletedScan(props) {
  const {
    scan,
    index,
    handleOnDrop,
    handleDragScanStart,
    findLoadedScan,
    loadedScanIds,
    zoomToPointCloud,
    togglePointCloud,
    currentViewer,
    isFromFolder,
  } = props;
  return (
    <div
      style={{ backgroundColor: index % 2 == 0 ? "#F8F8F8" : "#D9D9D954" }}
      className="py-4"
    >
      <div
        className="flex flex-row justify-start items-center"
        style={{
          marginLeft: isFromFolder ? "25px" : "20px",
          height: "25px",
        }}
        draggable
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => handleOnDrop(e, scan.id)}
        onDragStart={() => {
          handleDragScanStart(scan.id);
        }}
      >
        {currentViewer == "2D" ? (
          <TwoDimensionScansView
            scan={scan}
            loadedScanIds={loadedScanIds}
            findLoadedScan={findLoadedScan}
            zoomToPointCloud={zoomToPointCloud}
          />
        ) : (
          <ThreeDimensionScansView
            scan={scan}
            loadedScanIds={loadedScanIds}
            findLoadedScan={findLoadedScan}
            zoomToPointCloud={zoomToPointCloud}
          />
        )}
        <CompletedScans
          scan={scan}
          togglePointCloud={togglePointCloud}
          findLoadedScan={findLoadedScan}
          loadedScanIds={loadedScanIds}
        />
      </div>
    </div>
  );
}
export default CompletedScan;
