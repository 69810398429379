import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useSelector } from 'react-redux';

function UnexpectedErrorDailog(props) {

    const { handleOnClick, handleOnClickContactSupport, openUnexpectedErrorDailog } = props;
    const { role } = useSelector(({ auth }) => auth.user);

    return (
        <div>
            <Dialog
                open={openUnexpectedErrorDailog}
                onClose={handleOnClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ width: "450px", height: `${role.includes('admin') ? "250px" : "320px"}` }}>
                    <DialogTitle id="dialog-title" className='text-center' style={{
                        fontSize: 25,
                        fontWeight: 600,
                        letterSpacing: "-0.01em",
                        textAlign: "center",
                        color: "#58BEC9",
                        paddingTop: '40px',
                        marginBottom: '0px',
                        paddingBottom: '5px',
                    }}>Unexpected Error
                    </DialogTitle>
                    <DialogContent className='text-center' style={{ paddingBottom: '5px', }}>
                        <DialogContentText id="alert-dialog-description" style={{ color: '#858585', fontSize: '15px', }}>
                            Looq support has been notified
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='flex flex-col items-center mb-32 justify-center'>
                        <Button style={{
                            width: "300px",
                            height: "55px",
                            borderRadius: "4px",
                            margin: "20px 20px 0px 20px",
                            backgroundColor: "#58BEC9",
                            color: "#ffffff",
                            fontSize: '20px'
                        }}
                            onClick={() => { handleOnClick() }}
                        >Ok</Button>
                        {!role.includes('admin') &&
                            <Button style={{
                                width: "300px",
                                height: "55px",
                                borderRadius: "4px",
                                margin: "20px 20px 0px 20px",
                                backgroundColor: "#9D9D9D",
                                color: "#ffffff",
                                fontSize: '20px'
                            }}
                                onClick={() => { handleOnClickContactSupport() }}
                            >
                                Contact Support
                            </Button>
                        }
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default UnexpectedErrorDailog;
