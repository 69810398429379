import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";
import SERVICE_STATUS from "app/services/ServiceStatus";

export const getAllInvitedUserDetails = createAsyncThunk(
  "admin/inviteUserApp/getAllInvitedUserDetails",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/inviteUser/getAllInvitedUserDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const removeInvitedUser = createAsyncThunk(
  "admin/inviteUserApp/removeInvitedUser",
  async (id, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/inviteUser/deleteInviteUser/${id}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

const inviteUserSlice = createSlice({
  name: "admin/inviteUserApp",
  initialState: {
    inviteUsers: [],
    removeInvitedUsersState: "",
  },
  extraReducers: {
    [getAllInvitedUserDetails.fulfilled]: (state, action) => {
      state.inviteUsers = action.payload;
    },
    [removeInvitedUser.fulfilled]: (state, action) => {
      state.removeInvitedUsersState = SERVICE_STATUS.fulfilled;
    },
    [removeInvitedUser.rejected]: (state, action) => {
      state.removeInvitedUsersState = SERVICE_STATUS.rejected;
    },
    [removeInvitedUser.pending]: (state, action) => {
      state.removeInvitedUsersState = SERVICE_STATUS.pending;
    },
  },
});

export default inviteUserSlice.reducer;
