import { useEffect, useMemo, useState, useRef } from "react";

// eslint-disable-next-line no-unused-vars
import * as Pannellum from "pannellum";
import { loadAllGpsImageInfos } from '../potree/Service';
import { Tooltip } from "@mui/material";

import { ZoomIn, ZoomOut, Fullscreen, ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Container, Spacer, Controls, Control } from "./MapBoxImageViewer.style";

function MapBoxImageViewer() {
    const hiddenAddBeforeUnloadEventButtonInput = useRef();

    let { scanId, index } = useParams();

    const [viewer, setViewer] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSrc, setImageSrc] = useState(null);

    const [imageInfos, setImageInfos] = useState([]);

    const broadcastChannel = useMemo(() => new BroadcastChannel(window.location.origin), [window]);

    useEffect(() => {
        //setBroadcastChannel(channel);
        setTimeout(() => {
            hiddenAddBeforeUnloadEventButtonInput.current.click();
        }, 1000)
        let imageMarkers = [];
        loadImage(scanId, 0, 10000, imageMarkers, 0);
        // loadGpsImageInfos(scanId, 0, IMAGE_INFOS_LIMIT)
        //     .then((response) => {
        //         const _imageInfos = response.data;
        //         setImageInfos(_imageInfos);
        //         setCurrentIndex(index);
        //         const imageUrl = _imageInfos[index].imageUrl;
        //         loadPanorama(imageUrl);
        //         sendImageMessage(index, _imageInfos[index], channel);
        //     }).catch((error) => console.log(error));

    }, []);

    const loadImage = (scanId, offset, limit, imageMarkers, totalCount) => {
        loadAllGpsImageInfos(scanId, offset, limit, totalCount)
            .then((response) => {
                const _imageInfos = response.data.imageInfo;
                if (_imageInfos.length > 0) {
                    const imageCount = response.data.totalCount;
                    offset = offset + limit;
                    if (imageMarkers) {
                        imageMarkers.push(..._imageInfos);

                    } else {
                        imageMarkers = _imageInfos;
                    }
                    if (index <= offset && (offset + 10000) > index) {
                        try {
                            setImageInfos(imageMarkers);
                            setCurrentIndex(index);
                            const imageUrl = imageMarkers[index].imageUrl;
                            loadPanorama(imageUrl);
                            sendImageMessage(index, imageMarkers[index], broadcastChannel);
                        } catch (error) {
                            console.error(error)
                        }
                    }
                    if (offset <= imageCount) {
                        loadImage(scanId, offset, limit, imageMarkers, imageCount)
                    } else {
                        setImageInfos(imageMarkers);
                    }
                }
            }).catch((error) => console.log(error));
    }

    const addBeforeUnloadEvent = () => {
        window.addEventListener('beforeunload', beforeUnloadEventFunc);
    }
    const beforeUnloadEventFunc = () => {
        // event.preventDefault();
        if (broadcastChannel) {
            sendCloseMessage(broadcastChannel);
        }

    }
    const sendImageMessage = (index, imageInfo, channel) => {
        if (channel) {
            var data = {
                isClosed: false,
                index: index,
                imageInfo: imageInfo
            }
            channel.postMessage(data);
        }
    }
    const sendCloseMessage = (channel) => {
        if (channel) {
            var data = {
                isClosed: true
            }
            channel.postMessage(data);
        }
    }


    function loadPanorama(url) {
        console.log("Loading panorama: " + url);
        loadMedia(url)
            .then((response) => {
                const objectUrl = URL.createObjectURL(response.data);
                setImageSrc(objectUrl);
                setViewer(pannellum.viewer('panorama', {
                    "type": "equirectangular",
                    "panorama": objectUrl,
                    "autoLoad": true,
                    "showControls": false
                }));

            }).catch((error) => console.error(error));
    }

    function loadMedia(src) {
        const response = axios.get(src, {
            headers: {},
            responseType: 'blob',
        });

        return Promise.resolve(response);
    }

    function onPrevious() {
        if (currentIndex > 0) {
            const index = currentIndex - 1;
            setCurrentIndex(index);

            const url = imageInfos[index].imageUrl;
            loadPanorama(url);
            sendImageMessage(index, imageInfos[index], broadcastChannel);
        }
    }

    function onNext() {
        if (currentIndex < imageInfos.length - 1) {
            const index = Number(currentIndex) + 1;
            setCurrentIndex(index);
            const url = imageInfos[index].imageUrl;
            loadPanorama(url);
            sendImageMessage(index, imageInfos[index], broadcastChannel);
        }
    }

    return (
        <Container id="panorama">
            <input
                ref={hiddenAddBeforeUnloadEventButtonInput}
                type="button"
                onClick={() => addBeforeUnloadEvent()}
                style={{ display: 'none' }}
            />
            <img src={imageSrc} width="100%" height="100%" />
            <Spacer />
            <Controls id="controls">
                <Tooltip title="Pan Up">
                    <Control onClick={() => viewer.setPitch(viewer.getPitch() + 10)}>&#9650;</Control>
                </Tooltip>
                <Tooltip title="Pan Down">
                    <Control onClick={() => viewer.setPitch(viewer.getPitch() - 10)}>&#9660;</Control>
                </Tooltip>
                <Tooltip title="Pan Left">
                    <Control onClick={() => viewer.setYaw(viewer.getYaw() - 10)}>&#9664;</Control>
                </Tooltip>
                <Tooltip title="Pan Right">
                    <Control onClick={() => viewer.setYaw(viewer.getYaw() + 10)}>&#9654;</Control>
                </Tooltip>
                <Tooltip title="Zoom In">
                    <Control onClick={() => viewer.setHfov(viewer.getHfov() - 10)}><ZoomIn fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Zoom Out">
                    <Control onClick={() => viewer.setHfov(viewer.getHfov() + 10)}><ZoomOut fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Toggle Full Screen">
                    <Control onClick={() => viewer.toggleFullscreen()}><Fullscreen fontSize="large" /></Control>
                </Tooltip>
                <Spacer />
                <Tooltip title="Previous Panorama">
                    <Control onClick={() => onPrevious()}><ArrowCircleLeft fontSize="large" /></Control>
                </Tooltip>
                <Tooltip title="Next Panorama">
                    <Control onClick={() => onNext()}><ArrowCircleRight fontSize="large" /></Control>
                </Tooltip>
            </Controls>
            <Spacer />
        </Container>
    );
}

MapBoxImageViewer.propTypes = {
};

export default MapBoxImageViewer;
