/* eslint-disable no-unused-vars */
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import APP_CONFIG from "../../../../AppConfig";
import swal from "sweetalert";

export const loadCustomers = createAsyncThunk(
  "admin/customerApp/loadCustomers",
  async (_, { getState }) => {
    const url = APP_CONFIG.api + `v1/user/getAllCustomersDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;

    return data.filter((x) => x.id != getState().auth.customer.id);
  }
);

export const createCustomer = createAsyncThunk(
  "admin/customerApp/createCustomer",
  (requestedData) => {
    const url = APP_CONFIG.api + `v1/user/createCustomerDetails`;

    const request = axios.post(url, requestedData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(request)
      .then((response) => {
        if (response) {
          requestedData.successCallback();
        }
      })
      .catch((error) => {
        var errorMessage = "";
        if (
          error &&
          error.response &&
          error.response.data &&
          typeof error.response.data == "string"
        ) {
          errorMessage = error.response.data;
        }
        requestedData.failureCallback(errorMessage);
      });
  }
);

export const loadAllDeletedCustomer = createAsyncThunk(
  "admin/customerApp/loadAllDeletedcustomer",
  async () => {
    const url = APP_CONFIG.api + `v1/user/getAllDeleteCustomerDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const updateCustomer = createAsyncThunk(
  "admin/customerApp/updateCustomer",
  (requestedData) => {
    const url =
      APP_CONFIG.api + `v1/user/updateCustomerDetails/${requestedData.data.id}`;

    const request = axios.put(url, requestedData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(request)
      .then((response) => {
        if (response) {
          requestedData.successCallback();
        }
      })
      .catch((error) => {
        var errorMessage = "";
        if (
          error &&
          error.response &&
          error.response.data &&
          typeof error.response.data == "string"
        ) {
          errorMessage = error.response.data;
        }
        requestedData.failureCallback(errorMessage);
      });
  }
);

export const resetMultiFactor = createAsyncThunk(
  "admin/customerApp/resetMultiFactor",
  async (id) => {
    const url = APP_CONFIG.api + `v1/user/resetMultiFactor/${id}`;

    const response = await axios.put(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    if (data == true) {
      swal("", "Resetted multi factor successfully", "success");
    }
  }
);

export const removeCustomer = createAsyncThunk(
  "admin/customerApp/removeCustomer",
  async (customerId) => {
    const url = APP_CONFIG.api + `v1/user/deleteCustomerDetails/${customerId}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;

    return data;
  }
);

export const inviteUser = createAsyncThunk(
  "admin/customerApp/inviteUser",
  async (customers) => {
    const url = APP_CONFIG.api + `v1/inviteUser/inviteuser`;

    const response = await axios.post(url, customers, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const getAllUserEmails = createAsyncThunk(
  "admin/customerApp/getAllUserEmails",
  async (siteId, { getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/user/getAllUserEmails?userId=${
        getState().auth.customer.userId
      }&siteId=${siteId}`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const sendAccessRestrictMail = createAsyncThunk(
  "admin/customerApp/sendAccessRestrictMail",
  async (user) => {
    const url = APP_CONFIG.api + `v1/user/sendRestrictAccessMail/${user.email}`;
    const response = axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = response.data;
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "admin/customerApp/resetPassword",
  async (userId) => {
    const url = APP_CONFIG.api + `v1/user/resetPassword`;
    var formData = new FormData();
    formData.append("userId", userId);
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = await response.data;
    if (data == true) {
      swal("", "Password reset link sent successfully", "success");
    } else {
      swal("", "Failed to send Password reset link", "error");
    }
  }
);

export const getUserActivityById = createAsyncThunk(
  "admin/customerApp/getUserActivityById",
  async () => {
    const url = APP_CONFIG.api + `v1/user/getUserActivityById`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;

    return data;
  }
);

export const getOrganizationUsers = (organizationId) => {
  const url = APP_CONFIG.api + `v1/user/getOrganizationUsers/${organizationId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getUserProjects = (userId) => {
  const url = APP_CONFIG.api + `v1/user/getUserProjects/${userId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const customerSlice = createSlice({
  name: "admin/customerApp",
  initialState: {
    customers: [],
    loadingState: "",
    createCustomerState: "",
    updateCustomerState: "",
    updateCustomerFailure: "",
    removeCustomerState: "",
    createCustomerFailure: "",
    inviteUserState: "",
    inviteUserfailure: "",
    userEmails: [],
    deletedCustomers: [],
    userActivities: [],
    organizationUser: [],
  },

  reducers: {
    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    setcreateCustomerState: (state, action) => {
      state.createCustomerState = action.payload;
    },
    setcreateCustomerFailure: (state, action) => {
      state.createCustomerFailure = action.payload;
    },
    setupdateCustomerState: (state, action) => {
      state.updateCustomerState = action.payload;
    },
    setUpdateCustomerFailure: (state, action) => {
      state.updateCustomerFailure = action.payload;
    },
    setremoveCustomerState: (state, action) => {
      state.removeCustomerState = action.payload;
    },
    setinviteUserState: (state, action) => {
      state.inviteUserState = action.payload;
    },
  },
  extraReducers: {
    [loadCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadCustomers.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadCustomers.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },

    [removeCustomer.fulfilled]: (state, action) => {
      state.removeCustomerState = SERVICE_STATUS.fulfilled;
    },
    [removeCustomer.rejected]: (state, action) => {
      state.removeCustomerState = SERVICE_STATUS.rejected;
    },
    [removeCustomer.pending]: (state, action) => {
      state.removeCustomerState = SERVICE_STATUS.pending;
    },
    [inviteUser.fulfilled]: (state, action) => {
      state.inviteUserState = SERVICE_STATUS.fulfilled;
    },
    [inviteUser.rejected]: (state, action) => {
      state.inviteUserfailure = action.error.message;
      state.inviteUserState = SERVICE_STATUS.rejected;
    },
    [getAllUserEmails.fulfilled]: (state, action) => {
      state.userEmails = action.payload;
    },
    [loadAllDeletedCustomer.fulfilled]: (state, action) => {
      state.deletedCustomers = action.payload;
    },
    [loadAllDeletedCustomer.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [loadAllDeletedCustomer.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [getUserActivityById.fulfilled]: (state, action) => {
      state.userActivities = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [getUserActivityById.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [getUserActivityById.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [getOrganizationUsers.fulfilled]: (state, action) => {
      state.organizationUser = action.payload;
    },
  },
});

export const {
  setLoadingState,
  setcreateCustomerState,
  setupdateCustomerState,
  setremoveCustomerState,
  setcreateCustomerFailure,
  setUpdateCustomerFailure,
  setinviteUserState,
} = customerSlice.actions;

export default customerSlice.reducer;
