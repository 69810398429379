import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogContent,
} from "@mui/material";
import UploadFiles from "app/shared-components/FileUpload";
import swal from "sweetalert";
import { getConfigFilesPath } from "../store/configSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startUploadFile } from "../../upload/store/UploadSlice";
import {
  createConfig,
  updatecreateConfigState,
  updateupdateConfigState,
  updateConfig,
} from "../store/configSlice";
import SERVICE_STATUS from "app/services/ServiceStatus";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import UPLOAD_TYPE from "../../upload/UploadType";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import FolderStructureModelPopup from "app/shared-components/dialogs/FolderStructureModelPopup";

const TitlePanel = styled.div`
  display: flex;
`;
const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
    cursor: pointer;
  }
`;

const Spacer = styled.div`
  flex-grow: ${(props) => props.space};
`;

// eslint-disable-next-line complexity
function ConfigCreateOrEdit() {
  const configFilesRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configId = params.id;
  const {
    createConfigState,
    configFilePath,
    createConfigFailure,
    updateConfigState,
    updateConfigFailure,
  } = useSelector(({ admin }) => admin.configApp);
  const { config } = useSelector(({ edit }) => edit.edit);
  const [uploadConfigFile, setUploadConfigFile] = useState([]);
  const [isValidJson, setIsValidJson] = useState(true);
  const [configData, setConfigData] = useState({
    description: "",
  });

  const handleOnChangeValues = (e) => {
    try {
      setConfigData({ ...configData, [e.target.name]: e.target.value });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (configId) {
      if (config && config.id == configId) {
        setConfigData({
          description: config.description,
        });
      } else {
        navigate(`/apps/appAdmin/config`);
      }
    }
  }, [configId, config]);

  useEffect(() => {
    if (configId) {
      dispatch(getConfigFilesPath(configId));
    }
  }, [configId, dispatch]);

  const handleResetConfigFiles = () => {
    try {
      if (configFilesRef.current != undefined) {
        configFilesRef.current.resetSelectedFiles();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isValid, setIsValid] = useState(false);
  const isValidation = () => {
    try {
      if (!configId) {
        if (uploadConfigFile.length > 0) {
          setIsValid(false);
          return true;
        } else {
          setIsValid(true);
          return false;
        }
      } else {
        setIsValid(false);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateOrUpdate = () => {
    try {
      if (isValidation()) {
        if (configId == null || configId == undefined) {
          let data = {
            description: configData.description,
            isDeleted: false,
          };
          dispatch(setLoaderProgress(true));
          dispatch(createConfig(data));
        } else {
          let data = {
            id: config.id,
            description: configData.description,
            isDeleted: false,
          };
          dispatch(setLoaderProgress(true));
          dispatch(updateConfig(data));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFilePath = (path) => {
    const str = path.split("/");
    str.splice(0, 2);
    return str.join("/");
  };
  const renameFile = (path, fileName) => {
    var str = path.split("/");
    str[str.length - 1] = fileName;
    return str.join("/");
  };

  const handleOnCancelButton = () => {
    if (window.history.state.idx == 0) {
      navigate("/apps/dashboards/sites");
    } else {
      navigate(-1);
    }
  };

  if (
    createConfigState == SERVICE_STATUS.fulfilled ||
    updateConfigState == SERVICE_STATUS.fulfilled
  ) {
    if (Object.keys(configFilePath).length > 0) {
      for (var i = 0; i < uploadConfigFile.length; i++) {
        let uploadingFile = uploadConfigFile[i].file;
        if (uploadingFile != null && uploadingFile != undefined) {
          var uploadData = {
            typeId: configFilePath["id"],
            // name: deviceData.name,
            file: uploadingFile,
            isPrimary: false,
            type: UPLOAD_TYPE.configfile,
            uploadKey: `${configFilePath[UPLOAD_TYPE.configfile]}${
              uploadingFile.path.includes("/")
                ? getFilePath(uploadingFile.path)
                : uploadingFile.path
            }`,
          };
          uploadData.uploadKey = renameFile(
            uploadData.uploadKey,
            UPLOAD_TYPE.configfilename
          );
          dispatch(startUploadFile(uploadData));
        }
      }
    }
    dispatch(updatecreateConfigState(""));
    dispatch(updateupdateConfigState(""));
    dispatch(setLoaderProgress(false));
    swal(
      "",
      configId == null || configId == undefined
        ? "Successfully created"
        : "Successfully updated",
      "success"
    ).then(() => {
      navigate(`/apps/appAdmin/config`);
    });
  }

  if (
    (createConfigState == SERVICE_STATUS.rejected &&
      createConfigFailure != "Request failed with status code 409") ||
    (updateConfigState == SERVICE_STATUS.rejected &&
      updateConfigFailure != "Request failed with status code 409")
  ) {
    try {
      dispatch(updatecreateConfigState(""));
      dispatch(updateupdateConfigState(""));
      dispatch(setLoaderProgress(false));
      swal("", "Something went wrong. Please try again", "error");
    } catch (error) {
      console.error(error);
    }
  }

  const isJsonValid = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      setIsValidJson(false);
      return false;
    }
    setIsValidJson(true);
    return true;
  };

  const readJson = (jsonFile) => {
    const file = jsonFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      isJsonValid(text);
      if (!isJsonValid(text)) {
        handleResetConfigFiles();
      }
    };

    reader.readAsText(file);
  };

  const handleOnDropConfigFile = (files) => {
    readJson(files[0]);
    setUploadConfigFile([]);
    var filesArray = [];
    for (var i = 0; i < files.length; i++) {
      filesArray.push({ name: files[i].name, file: files[i] });
    }
    filesArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    setUploadConfigFile(filesArray);
  };

  const [openModelPopup, setopenModelPopup] = useState(false);
  const [modelTitle, setmodelTitle] = useState("");
  const [modelConfigPath, setModelConfigPath] = useState("");
  const handleOnModelPopupClose = () => {
    setopenModelPopup(false);
  };
  const handleOnConfigFileViewClick = () => {
    setopenModelPopup(true);
    setmodelTitle(config.name ? config.name : `Config ${config.id}`);
    setModelConfigPath(configFilePath[UPLOAD_TYPE.configfile]);
  };

  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        maxHeight: "auto",

        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
      style={{ width: "30%" }}
      className="items-center justify-center my-16 sm:p-24 md:p-32"
    >
      <Grid container className="items-center justify-center">
        <div className="w-full">
          <Typography
            variant="h6"
            className="text-center mt-0 mb-24 font-semibold text-18 sm:text-24"
          >
            {configId == null || configId == undefined
              ? "Create Config"
              : "Update Config"}
          </Typography>
          {isValid && (
            <p className="text-red mb-8 text-center">
              All fields are mandatory
            </p>
          )}
          {!isValidJson && (
            <p className="text-red mb-8 text-center">Invalid Json Format</p>
          )}
          <div className="relative flex flex-row items-center w-full mt-16">
            <div className="flex flex-col w-full">
              <div>
                <TextField
                  id="outlined-basic-email"
                  variant="outlined"
                  label="Description"
                  name="description"
                  fullWidth
                  onChange={handleOnChangeValues}
                  className="mb-16"
                  value={configData.description}
                />
              </div>
            </div>
          </div>
          <div className="relative flex flex-row items-center w-full">
            <div className="flex flex-col w-full">
              <div>
                <div className="flex items-center">
                  <Typography className="mr-4">Config File</Typography>
                  {configId && (
                    <Button
                      color="secondary"
                      size="small"
                      variant="text"
                      onClick={() => handleOnConfigFileViewClick()}
                    >
                      Config File View
                    </Button>
                  )}
                </div>
                <UploadFiles
                  ref={configFilesRef}
                  onDrop={(files) => handleOnDropConfigFile(files)}
                  multiple={false}
                  accept={{ "application/json": [".json"] }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center w-full">
            <Button
              variant="contained"
              color="primary"
              className="mx-auto mt-16 py-8 w-full mr-32"
              aria-label="Register"
              type="button"
              onClick={handleCreateOrUpdate}
            >
              {configId == null || configId == undefined ? "Create" : "Update"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="mx-auto mt-16 font-bold py-8 w-full ml-32"
              style={{ backgroundColor: "#E67260" }}
              aria-label="Register"
              type="button"
              onClick={handleOnCancelButton}
            >
              {"Cancel"}
            </Button>
          </div>
          <Dialog
            open={openModelPopup}
            onClose={handleOnModelPopupClose}
            scroll={"paper"}
            fullScreen
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            BackdropProps={{
              classes: {
                root: "rgba(255,255,255,0.2)",
              },
            }}
          >
            <TitlePanel>
              <div></div>
              <Spacer space={5} />
              <CloseButton onClick={() => handleOnModelPopupClose()}>
                <Close fontSize="inherit" />
              </CloseButton>
            </TitlePanel>
            <DialogContent dividers={"paper" === "paper"}>
              <FolderStructureModelPopup
                openModelPopup={openModelPopup}
                handleOnModelPopupClose={() => handleOnModelPopupClose()}
                modelTitle={modelTitle}
                modelScanPath={modelConfigPath}
                type={UPLOAD_TYPE.configfile}
                id={config != null && config != undefined ? config.id : 0}
              ></FolderStructureModelPopup>
            </DialogContent>
          </Dialog>
        </div>
      </Grid>
    </Paper>
  );
}
export default ConfigCreateOrEdit;
