import { lazy } from 'react';
import { authRoles } from 'app/auth';

const DemoDashboardApp = lazy(() => import('./DemoDashboardApp'));
const DemoSiteViewer = lazy(() => import('./site/DemoSiteViewer'));


const DashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  //auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'apps/dashboard/demousersites',
      element: <DemoDashboardApp />,
    },
    {
      path: 'apps/demosites/:siteId',
      element: <DemoSiteViewer />,
    },
  ],
};

export default DashboardAppConfig;