export const SIZES = ["B", "KB", "MB", "GB", "TB"];

export const getChunkSize = (totalSize) => {
  const baseChunkSize = 1048576; // 1MB
  const sizeCategory = Math.floor(Math.log(totalSize) / Math.log(1024));
  const exactSize = Math.round(totalSize / Math.pow(1024, sizeCategory), 2);

  // Define a dynamic multiplier based on a logarithmic scale
  const getMultiplier = (size) => {
    return Math.max(10, Math.round(Math.log(size) * 10));
  };

  const sizeCategoryName = sizeCategory === 3 ? "GB" : "Other"; // Handle size categories (in GB)

  // If size category is GB, get the corresponding multiplier
  let chunkMultiplier = 10;
  if (sizeCategoryName === "GB") {
    chunkMultiplier = getMultiplier(exactSize);
  }

  return baseChunkSize * chunkMultiplier;
};
