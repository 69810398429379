import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/lab";

import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Paper,
  Dialog,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  DialogContent,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Avatar,
  IconButton,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  ArrowDropDown,
  CameraAlt,
  Close,
  Event,
  AddCircleOutline,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";

import FolderStructureModelPopup from "app/shared-components/dialogs/FolderStructureModelPopup";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import EpochMenuList from "../MenuList/EpochMenuList";
import ProjectCreateOrEdit from "../Projects/ProjectCreateOrEdit";
import UPLOAD_TYPE from "../../upload/UploadType";
import { TitlePanel, CloseButton, Spacer } from "./SiteCreateOrEdit.style";
import AvatarImage from "app/shared-components/image/AvatarImage";

// eslint-disable-next-line
const SiteCreateOrEditForm = ({
  allValues,
  completionDate,
  coordinateSystemId,
  epoch,
  filteredCoordinateSystems,
  filteredVerticalDatums,
  handleClose,
  handleCreateOrUpdate,
  handleIsAutomaticProjection,
  handleOnAddProjectClick,
  handleOnCancelButton,
  handleOnCancelProject,
  handleOnChange,
  handleOnchangeCoordinateSystem,
  handleOnChangeEpochDate,
  handleOnChangeEpochMenuValue,
  handleOnChangeEpochText,
  handleOnCreateProject,
  handleSiteProfileImageChange,
  hiddenSiteProfileImageInput,
  horizontalDatumId,
  horizontalDatums,
  isAutomaticProjection,
  isEnableVerticalDatum,
  isEpochValid,
  isOrgValid,
  isValid,
  modelScanPath,
  modelTitle,
  onClearEpochText,
  organizationProject,
  params,
  setCompletionDate,
  setStartDate,
  showLoader,
  showProjectCreateDialog,
  showProjectEdit,
  site,
  siteId,
  siteMenuViewer,
  siteProfileImage,
  siteViewer,
  startDate,
  verticalDatumId,
}) => {
  const { accounts } = useSelector(({ admin }) => admin.accountApp);
  const { role } = useSelector(({ auth }) => auth.user);
  const [showEpochDatePicker, setShowEpochDatePicker] = useState(false);
  const [epochDate, setEpochDate] = useState(new Date());
  const [epochMenuAnchorEl, setEpochMenuAnchorEl] = useState(null);
  const [openModelPopup, setOpenModelPopup] = useState(false);
  const epochMenuInputRef = useRef();

  const handleOnModelPopupClose = () => {
    setOpenModelPopup(false);
  };

  const handleOnEpochMenuClick = () => {
    if (epochMenuInputRef.current != undefined) {
      setEpochMenuAnchorEl(epochMenuInputRef.current);
    }
  };

  const isEpochDisabled = useMemo(() => {
    return (
      (siteId > 0 && !role.includes("admin")) ||
      [5, 1, 6].includes(horizontalDatumId)
    );
  }, [siteId, role]);

  const filteredSharedAccounts = useMemo(() => {
    return accounts.filter((x) => x.id !== allValues.ownerOrganization) || [];
  }, [accounts, allValues.ownerOrgan]);

  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        maxHeight: "auto",

        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
      style={{
        width: siteMenuViewer ? "100%" : siteViewer ? "90%" : "70%",
        boxShadow: siteMenuViewer ? "0px 0px 0px" : "",
      }}
      className={`items-center justify-center ${
        siteViewer ? "m-16" : "my-16"
      } sm:p-24 md:p-32`}
    >
      <Grid container className="items-center justify-center">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={showLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="w-full">
          <Typography
            variant="h6"
            className="mt-0 mb-12 font-semibold text-18 sm:text-24 text-center"
          >
            {siteId != null || siteId != undefined
              ? "Update Site"
              : "Create Site"}
          </Typography>
          <div className="flex items-center justify-center mb-12 ml-32">
            {siteProfileImage != null ? (
              <Avatar
                className="md:mx-4"
                src={URL.createObjectURL(new Blob([siteProfileImage.data]))}
                sx={{ width: 100, height: 100 }}
              />
            ) : siteId != null && siteId != undefined ? (
              site.siteImageUrl != "" ? (
                <AvatarImage
                  className="md:mx-4"
                  src={site.siteImageUrl}
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <Avatar
                  className="md:mx-4 text-48"
                  sx={{ width: 100, height: 100 }}
                >
                  {allValues.name.toUpperCase().charAt(0)}
                </Avatar>
              )
            ) : (
              <Avatar
                className="md:mx-4"
                src=""
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Avatar
              onClick={() => {
                hiddenSiteProfileImageInput.current.click();
              }}
              sx={{ width: 30, height: 30, bgcolor: green[500] }}
              style={{ top: "30px", right: "30px" }}
            >
              <CameraAlt />
            </Avatar>
            <input
              accept="image/png, image/gif, image/jpeg"
              ref={hiddenSiteProfileImageInput}
              type="file"
              onChange={(e) => handleSiteProfileImageChange(e)}
              style={{ display: "none" }}
            />
          </div>
          {isOrgValid || isValid ? null : (
            <p className="text-red mb-8 text-center">
              All fields are mandatory
            </p>
          )}
          <div
            className={`relative flex ${
              siteViewer ? "flex-col" : "flex-row"
            } items-center w-full`}
          >
            <div className="flex flex-col w-full">
              <div className={`${siteViewer ? "" : "mr-32"}`}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  name="name"
                  variant="outlined"
                  fullWidth
                  className="mb-16"
                  value={allValues.name}
                  onChange={(e) => handleOnChange(e)}
                  error={isValid && allValues.name == ""}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className={`${siteViewer ? "" : "ml-32"}`}>
                <TextField
                  id="outlined-basic"
                  label="Description"
                  name="description"
                  variant="outlined"
                  fullWidth
                  className="mb-16"
                  value={allValues.description}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
            </div>
          </div>
          {role.includes("admin") && (
            <>
              <div
                className={`relative flex ${
                  siteViewer ? "flex-col" : "flex-row"
                } items-center w-full`}
              >
                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "mr-32"}`}>
                    <TextField
                      id="outlined-basic"
                      label="Latitude"
                      name="locationLat"
                      variant="outlined"
                      fullWidth
                      className="mb-16"
                      value={allValues.locationLat}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "ml-32"}`}>
                    <TextField
                      id="outlined-basic"
                      label="Longitude"
                      name="locationLong"
                      variant="outlined"
                      fullWidth
                      className="mb-16"
                      value={allValues.locationLong}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`relative flex ${
                  siteViewer ? "flex-col" : "flex-row"
                } items-center w-full`}
              >
                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "mr-32"}`}>
                    <FormControl className="mx-0 mb-16 w-full" sx={{ m: 1 }}>
                      <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start Date"
                        name="startDate"
                        value={startDate}
                        views={["year", "month", "day"]}
                        format="DD-MM-YYYY"
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "ml-32"}`}>
                    <FormControl className="mx-0 mb-16 w-full" sx={{ m: 1 }}>
                      <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Latest Scan"
                        name="completionDate"
                        value={completionDate}
                        onChange={(newValue) => {
                          setCompletionDate(newValue);
                        }}
                        minDate={startDate}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </>
          )}
          <Box>
            <Grid container>
              {role.includes("admin") && (
                <>
                  <Grid item xs={siteViewer ? 12 : 6}>
                    <div className={`${siteViewer ? "" : "mr-32"}`}>
                      <FormControl className="mx-0 w-full mb-16" sx={{ m: 1 }}>
                        <InputLabel>Owner Organization</InputLabel>
                        <Select
                          fullWidth
                          name="ownerOrganization"
                          value={allValues.ownerOrganization}
                          onChange={(e) => handleOnChange(e)}
                          label="Owner Organization"
                          error={isValid && allValues.ownerOrganization === 0}
                        >
                          {accounts.map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                              <ListItemText primary={account.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={siteViewer ? 12 : 6}>
                    <div className={`${siteViewer ? "" : "ml-32"}`}>
                      <FormControl className="mx-0 w-full mb-16" sx={{ m: 1 }}>
                        <InputLabel>Shared Organizations</InputLabel>
                        <Select
                          fullWidth
                          multiple
                          name="sharedOrganizations"
                          value={
                            Array.isArray(allValues.sharedOrganizations)
                              ? allValues.sharedOrganizations
                              : []
                          }
                          onChange={(e) => handleOnChange(e)}
                          label="Shared Organizations"
                          renderValue={(Account) =>
                            filteredSharedAccounts
                              ?.filter((o) => Account?.includes(o.id))
                              .map((a) => a.name + " ")
                          }
                        >
                          {filteredSharedAccounts?.map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                              {!role.includes("projectmanager") && (
                                <Checkbox
                                  checked={allValues.sharedOrganizations?.includes(
                                    account.id
                                  )}
                                />
                              )}
                              <ListItemText primary={account.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={siteViewer ? 12 : 6}>
                {showProjectEdit && (
                  <div className={`${siteViewer ? "" : "mr-32"}`}>
                    <FormControl className="mx-0 w-full mb-16" sx={{ m: 1 }}>
                      <InputLabel>Project</InputLabel>
                      <Select
                        fullWidth
                        name="projectId"
                        value={allValues.projectId}
                        onChange={(e) => handleOnChange(e)}
                        label="Projects"
                        error={isValid && allValues.projectId == 0}
                        disabled={
                          role.includes("admin") &&
                          allValues.ownerOrganization === 0
                        }
                      >
                        <MenuItem dense>
                          <Button
                            style={{
                              boxSizing: "border-box",
                              width: "124px",
                              background: "#6CCCD7",
                              border: "1px solid #6CCCD7",
                              borderRadius: "50px",
                              color: "#ffffff",
                              padding: "2px 15px",
                            }}
                            startIcon={<AddCircleOutline />}
                            onClick={() => handleOnAddProjectClick()}
                          >
                            Add Project
                          </Button>
                        </MenuItem>
                        {organizationProject?.filter((x) => x.isDefault)
                          .length == 0 ? (
                          <MenuItem key={0} value={0}>
                            <ListItemText>No Project</ListItemText>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            key={
                              organizationProject?.find((x) => x.isDefault).id
                            }
                            value={
                              organizationProject?.find((x) => x.isDefault).id
                            }
                          >
                            <ListItemText>No Project</ListItemText>
                          </MenuItem>
                        )}
                        {organizationProject
                          ?.filter((x) => !x.isDefault)
                          .map((project) => (
                            <MenuItem key={project.id} value={project.id}>
                              <ListItemText primary={project.name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </Grid>
              <Grid item xs={siteViewer ? 12 : 6}>
                <div className={`${siteViewer ? "" : "ml-32"} pl-8`}>
                  <FormControl className="w-full">
                    <FormLabel>Coordinate System</FormLabel>
                    <RadioGroup
                      row
                      name="automaticProjection"
                      value={isAutomaticProjection}
                      onChange={handleIsAutomaticProjection}
                    >
                      <FormControlLabel
                        value={true}
                        disabled={siteId > 0 && !role.includes("admin")}
                        control={<Radio />}
                        label="Auto"
                      />
                      <FormControlLabel
                        value={false}
                        disabled={siteId > 0 && !role.includes("admin")}
                        control={<Radio />}
                        label="Manual"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Box>
          <>
            {!isAutomaticProjection && (
              <>
                <div
                  className={`relative flex ${
                    siteViewer ? "flex-col" : "flex-row"
                  } items-center w-full`}
                >
                  <div className="flex flex-col w-full">
                    <div className={`${siteViewer ? "" : "mr-32"}`}>
                      <FormControl className="mx-0 w-full mb-16" sx={{ m: 1 }}>
                        <InputLabel>Vertical Datum</InputLabel>
                        <Select
                          fullWidth
                          name="verticalDatum"
                          label="Vertical Datum"
                          value={verticalDatumId}
                          disabled={
                            !isEnableVerticalDatum ||
                            (siteId > 0 && !role.includes("admin"))
                          }
                          onChange={(e) => handleOnchangeCoordinateSystem(e)}
                        >
                          {filteredVerticalDatums?.map(
                            (verticalDatum, index) => (
                              <MenuItem key={index} value={verticalDatum.id}>
                                {verticalDatum.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className={`${siteViewer ? "" : "ml-32"}`}>
                      <FormControl className="mx-0 w-full mb-16" sx={{ m: 1 }}>
                        <InputLabel>Horizontal Datum</InputLabel>
                        <Select
                          fullWidth
                          name="horizontalDatum"
                          value={horizontalDatumId}
                          onChange={(e) => handleOnchangeCoordinateSystem(e)}
                          disabled={siteId > 0 && !role.includes("admin")}
                          label="Horizontal Datum"
                          error={isValid && horizontalDatumId == null}
                        >
                          {horizontalDatums?.map((horizontalDatum, index) => (
                            <MenuItem key={index} value={horizontalDatum.id}>
                              {horizontalDatum.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <Box>
                  <Grid container>
                    <Grid item xs={siteViewer ? 12 : 6}>
                      <div className={`${siteViewer ? "" : "mr-32"}`}>
                        <FormControl
                          className="mx-0 w-full mb-16"
                          sx={{ m: 1 }}
                        >
                          <InputLabel>Projection</InputLabel>
                          <Select
                            fullWidth
                            name="projectionName"
                            value={coordinateSystemId}
                            onChange={(e) => handleOnchangeCoordinateSystem(e)}
                            label="Projection"
                            disabled={siteId > 0 && !role.includes("admin")}
                            error={isValid && coordinateSystemId == null}
                          >
                            {filteredCoordinateSystems?.map(
                              (coordinateSystem, index) => (
                                <MenuItem
                                  key={index}
                                  value={coordinateSystem.id}
                                >
                                  {coordinateSystem.projectionName}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={siteViewer ? 12 : 6}>
                      <div className={`${siteViewer ? "" : "ml-32"}`}>
                        <FormControl
                          className="mx-0 w-full mb-16"
                          sx={{ m: 1 }}
                        >
                          <DatePicker
                            value={epochDate}
                            open={showEpochDatePicker}
                            onOpen={() => setShowEpochDatePicker(true)}
                            onClose={() => setShowEpochDatePicker(false)}
                            onChange={(e) => {
                              setEpochDate(e);
                              handleOnChangeEpochDate(e);
                            }}
                            disabled={isEpochDisabled}
                            renderInput={(props) => (
                              <FormControl
                                variant="outlined"
                                className="w-full"
                              >
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Epoch
                                </InputLabel>
                                <OutlinedInput
                                  {...props}
                                  ref={epochMenuInputRef}
                                  value={epoch}
                                  inputProps={{
                                    ...params?.inputProps,
                                    placeholder: "YYYY.YY",
                                  }}
                                  onChange={(e) =>
                                    handleOnChangeEpochText(e.target.value)
                                  }
                                  label="Epoch"
                                  error={!isEpochValid}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        onClick={handleOnEpochMenuClick}
                                        disabled={isEpochDisabled}
                                      >
                                        {<ArrowDropDown />}
                                      </IconButton>
                                      <IconButton
                                        edge="end"
                                        onClick={() => {
                                          setShowEpochDatePicker(
                                            !showEpochDatePicker
                                          );
                                        }}
                                        className="mx-4"
                                        disabled={isEpochDisabled}
                                      >
                                        {<Event />}
                                      </IconButton>
                                      <IconButton
                                        edge="end"
                                        onClick={() => {
                                          onClearEpochText();
                                        }}
                                        className="pl-0"
                                        disabled={isEpochDisabled}
                                      >
                                        {<HighlightOffRoundedIcon />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                ></OutlinedInput>
                              </FormControl>
                            )}
                          />
                          {epochMenuAnchorEl && (
                            <EpochMenuList
                              epochMenuAnchorEl={epochMenuAnchorEl}
                              handleOnEpochMenuClose={setEpochMenuAnchorEl(
                                null
                              )}
                              width={
                                epochMenuInputRef.current.getBoundingClientRect()
                                  .width
                              }
                              handleOnChangeEpochMenuValue={
                                handleOnChangeEpochMenuValue
                              }
                            />
                          )}
                          <div className="relative mb-16 flex flex-row items-center w-full">
                            <h6
                              className="text-red text-8"
                              style={{ fontSize: "smaller" }}
                            >
                              YYYY.YY
                            </h6>
                          </div>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </>
          {role.includes("admin") && (
            <>
              <div
                className={`relative flex ${
                  siteViewer ? "flex-col" : "flex-row"
                } items-center w-full`}
              >
                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "mr-32"} pl-8`}>
                    <FormControl className="mx-0 w-full mb-16">
                      <FormLabel>Ortho</FormLabel>
                      <RadioGroup
                        row
                        name="orthoAvailable"
                        value={allValues.orthoAvailable}
                        onChange={handleOnChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Disable "
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className={`${siteViewer ? "" : "ml-32"}`}>
                    <FormControl className="mx-0 w-full mb-16 pl-8">
                      <FormLabel>Contours</FormLabel>
                      <RadioGroup
                        row
                        name="contoursAvailable"
                        value={allValues.contoursAvailable}
                        onChange={handleOnChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Disable "
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </>
          )}
          {siteMenuViewer ? (
            <div className="relative py-12 flex flex-row items-center justify-center w-full">
              <Button
                color="error"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="ml-8"
                onClick={() => handleCreateOrUpdate()}
              >
                {siteId != undefined && siteId != null ? "Update" : "Create"}
              </Button>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-center w-full">
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-16 py-8 w-full mr-32"
                aria-label="Save"
                type="button"
                onClick={handleCreateOrUpdate}
              >
                {siteId != undefined || siteId != null ? "Update" : "Create"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="mx-auto mt-16 font-bold py-8 w-full ml-32"
                style={{ backgroundColor: "#E67260" }}
                aria-label="Register"
                type="button"
                onClick={handleOnCancelButton}
              >
                {"Cancel"}
              </Button>
            </div>
          )}
        </div>

        <div>
          <Dialog
            open={openModelPopup}
            onClose={handleOnModelPopupClose}
            scroll={"paper"}
            fullScreen
          >
            <TitlePanel>
              <div></div>
              <Spacer space={5} />
              <CloseButton onClick={() => handleOnModelPopupClose()}>
                <Close fontSize="inherit" />
              </CloseButton>
            </TitlePanel>
            <DialogContent dividers={"paper" === "paper"}>
              <FolderStructureModelPopup
                openModelPopup={openModelPopup}
                handleOnModelPopupClose={() => handleOnModelPopupClose()}
                modelTitle={modelTitle}
                modelScanPath={modelScanPath}
                type={UPLOAD_TYPE.site}
                id={site != null && site != undefined ? site.id : 0}
              ></FolderStructureModelPopup>
            </DialogContent>
          </Dialog>

          <Dialog
            open={showProjectCreateDialog}
            onClose={handleOnCancelProject}
          >
            <DialogContent>
              <ProjectCreateOrEdit
                organizationId={allValues.ownerOrganization}
                isSiteCreate={true}
                handleOnCreateProject={handleOnCreateProject}
                handleOnCancelProject={handleOnCancelProject}
              ></ProjectCreateOrEdit>
            </DialogContent>
          </Dialog>
        </div>
      </Grid>
    </Paper>
  );
};

export default SiteCreateOrEditForm;
