import mapboxgl from "!mapbox-gl";
import { createRef } from "react";
import { render } from 'react-dom';
import MapBoxLabelMarker from "./markers/MapBoxLabelMarker";

class MapBoxCommon {
    createUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    addPoint(viewer, lng, lat, id) {
        this.removePoint(viewer, id);
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    }
                }
            ]
        };

        viewer.addSource(id, {
            'type': 'geojson',
            'data': geojson
        });

        viewer.addLayer({
            'id': id,
            'type': 'circle',
            'source': id,
            'paint': {
                'circle-radius': 4.5,
                'circle-stroke-color': '#000',
                'circle-stroke-width': 1,
                'circle-color': '#F84C4C' // red color
            }
        });
    }

    updatePoint(viewer, lng, lat, id) {
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    }
                }
            ]
        };
        if (viewer.getSource(id) != undefined) {
            viewer.getSource(id).setData(geojson);
        }
    }

    addPointWithData(viewer, geojson, id) {
        this.removePoint(viewer, id);
        viewer.addSource(id, {
            'type': 'geojson',
            'data': geojson
        });

        this.viewer.addLayer({
            id: id,
            type: 'circle',
            source: id,
            paint: {
                'circle-radius': [
                    'case',
                    ['==', ['get', 'featureType', ['get', 'imageInfo']], 'POI'],
                    8,  // bigger radius for POI
                    4.5 // default radius for others
                ],
                'circle-stroke-width': [
                    'case',
                    ['==', ['get', 'featureType', ['get', 'imageInfo']], 'POI'],
                    2,  // thicker stroke for POI
                    1   // default stroke for others
                ],
                'circle-stroke-color': '#000',
                'circle-color': [
                    'case',
                    ['boolean', ['feature-state', 'isSelected'], false],
                    '#edff24',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#edff24',
                    ['==', ['get', 'featureType', ['get', 'imageInfo']], 'POI'],
                    '#66FF00',
                    '#F84C4C'
                ]
            }
        });
    }

    updatePointWithData(viewer, geojson, id) {
        if (viewer.getSource(id) != undefined) {
            viewer.getSource(id).setData(geojson);
        }
    }

    removePoint(viewer, id) {
        if (viewer.getLayer(id) != undefined) {
            viewer.removeLayer(id);
        }

        if (viewer.getSource(id) != undefined) {
            viewer.removeSource(id);
        }
    }

    addLine(viewer, id, color) {
        this.removeLine(viewer, id);
        var lineFeatureCollection = {
            'type': 'FeatureCollection',
            'features': []
        };

        viewer.addSource(id, {
            'type': 'geojson',
            'data': lineFeatureCollection
        });

        viewer.addLayer({
            id: id,
            type: 'line',
            source: id,
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': color ? color : '#F84C4C',
                'line-width': 2.5,
            },
            filter: ['in', '$type', 'LineString']
        })
    }

    updateLine(viewer, lngLats, id) {
        var lineFeatureCollection = {
            'type': 'FeatureCollection',
            'features': []
        };

        var lineFeature = {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': []
            }
        };
        lineFeature.geometry.coordinates = lngLats;
        lineFeatureCollection.features.push(lineFeature);
        if (viewer.getSource(id) != undefined) {
            viewer.getSource(id).setData(lineFeatureCollection);
        }
    }

    removeLine(viewer, id) {
        if (viewer.getLayer(id) != undefined) {
            viewer.removeLayer(id);
        }
        if (viewer.getSource(id) != undefined) {
            viewer.removeSource(id);
        }
    }

    addLabel(viewer, lng, lat) {
        const labelRef = createRef();
        labelRef.current = document.createElement("div");
        labelRef.current.color = "#FFFFFF"
        render(
            <MapBoxLabelMarker />,
            labelRef.current
        );
        const labelMarker = new mapboxgl.Marker({ element: labelRef.current })
            .setLngLat([lng, lat])
            .addTo(viewer);
        return labelMarker;
    }

    updateLabelText(labelMarker, text) {
        if (labelMarker) {
            var labelElement = labelMarker.getElement().getElementsByClassName('labelTextMarker');
            if (labelElement.length > 0) {
                labelElement[0].innerHTML = `${text}`;
            }
        }
    }

    updateLabelLngLat(labelMarker, lng, lat) {
        if (labelMarker) {
            labelMarker.setLngLat([lng, lat]);
        }
    }

    removeLabel(labelMarker) {
        if (labelMarker) {
            labelMarker.remove();
        }
    }


}

export default MapBoxCommon;