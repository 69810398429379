import { createSlice } from '@reduxjs/toolkit';

const editSlice = createSlice({
    name: 'edit/editApp',
    initialState: {
    },
    reducers: {
        setEditData: (state, action) => action.payload
    }
});

export const { setEditData } = editSlice.actions;

export default editSlice.reducer;
