import { lazy } from 'react';
import UserSupport from './UserSupport';

const UserProfile = lazy(() => import('./UserProfile'));

const UserAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/user/userprofile',
      element: <UserProfile />,
    },
    {
      path: 'apps/user/support',
      element: <UserSupport />,
    },
  ],
};

export default UserAppConfig;