import React from "react";
import { IconButton, Tooltip } from "@mui/material";

const AlignToControlButton = (props) => {
  const { isDisabled, handleOnAlignToControlClick } = props;

  return (
    <Tooltip
      placement="right-start"
      title="Align the view to the selected ground control points"
    >
      <IconButton
        variant="outlined"
        style={{
          // border: `1px solid ${isDisabled ? "#d3d3d3" : "#59bec9"}`,
          borderRadius: "16px",
          fontSize: "13px",
          padding: "10px 20px",
          fontWeight: 600,
          backgroundColor: isDisabled ? "#f0f0f0" : "#59bec9",
          color: isDisabled ? "#d3d3d3" : "rgb(255, 255, 255)",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        onClick={() => handleOnAlignToControlClick()}
        // className={
        //   enableAlignToControl
        //     ? classes.alignToControlDisable
        //     : classes.alignToControlEnable
        // }
        // disabled={enableAlignToControl}
      >
        Align To Control
      </IconButton>
    </Tooltip>
  );
};

export default AlignToControlButton;
