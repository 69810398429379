import styled from "@emotion/styled";
import {
  Typography,
  Card,
  IconButton,
  Button,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { deleteAlignToControlNotification } from "../admin/store/notificationSlice";
import { useDispatch } from "react-redux";
import { cancelAlignToControl } from "../admin/store/notificationSlice";

const Description = styled.div`
  font-size: 15px;
`;

function AlignToControlNotificationCard(props) {
  const { notification } = props;

  const date = notification.createTime;
  const dispatch = useDispatch();
  const dateObject = new Date(date);
  const dateTime = dateObject.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
  const handleOnDeleteNotification = (id) => {
    var requestData = {
      successCallback: handleRefreshNotifications,
      id: id
  }
    dispatch(deleteAlignToControlNotification(requestData));
  };

  const handleRefreshNotifications = () => {
    props.handleRefreshNotifications();
}

  const getStstusMessage = () => {

    if (notification.alignToControlNotification.queueId) {
      return "is in queue";
    } else if (notification.alignToControlNotification.status == 0 || notification.alignToControlNotification.status == 1) {
      return "is in progress";
    } else if (notification.alignToControlNotification.status == 2) {
      return "has completed";
    } else if (notification.alignToControlNotification.status == 3) {
      return "has failed.Looq support has been notified";
    }
  }

  return (
    <Card
      className="flex items-center relative w-full rounded-16 pl-20 pb-20 min-h-64 shadow space-x-8 mb-8"
      elevation={0}
    >
      <div className="flex flex-col flex-auto">
        <div className="flex flex-row justify-end">
          <IconButton
            onClick={() =>
              handleOnDeleteNotification(
                notification.alignToControlNotification.id
              )
            }
          >
            <HighlightOffIcon />
          </IconButton>

        </div>
        <div className="pr-20">
          <Description>
            Align To Control for Scan <span style={{ fontWeight: 600

            }}>{notification.alignToControlNotification.scan.name}</span> {getStstusMessage()}
          </Description>
          <Typography className="mt-8 text-sm leading-none " color="text.secondary">
            {dateTime}
          </Typography>
         
          {notification.alignToControlNotification.queueId &&
            <div className="mt-4 flex justify-end">
              <Button
                onClick={() => {
                  dispatch(
                    cancelAlignToControl(
                      notification.alignToControlNotification.queueId
                    )
                  );
                }}
                variant="outlined"
                style={{
                  boxSizing: 'border-box',
                  width: '110px',
                  background: '#E67260',
                  border: '1px solid #E67260',
                  borderRadius: '50px',
                  color: '#ffffff',
                  padding: '2px 15px'
                }}
              >
                Cancel
              </Button>
            </div>
          }
        </div>
      </div>
    </Card>
  );
}
export default AlignToControlNotificationCard;
