/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import APP_CONFIG from "../../../../AppConfig";
import swal from "sweetalert";
import { getAllSiteDetails, loadAllSitesDetails } from "./siteSlice";
import { runningProcessScan } from "./scanProcessSlice";

export const loadScans = createAsyncThunk(
  "admin/scanApp/loadScans",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/scan/scansById`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const createScan = createAsyncThunk(
  "admin/scanApp/createScan",
  async (payload) => {
    const url = APP_CONFIG.api + `v1/scan/createscan`;
    payload.baseStationId =
      payload.baseStationId === 0 ? null : payload.baseStationId;
    const response = await axios.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.data;
  }
);

export const createScanAuto = createAsyncThunk(
  "admin/scanApp/createScanAuto",
  async (payload) => {
    const url = APP_CONFIG.api + `v1/scan/createScanAutoProcessing`;
    payload.baseStationId =
      payload.baseStationId === 0 ? null : payload.baseStationId;
    const response = await axios.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.data;
  }
);

export const updateScan = createAsyncThunk(
  "admin/scanApp/updateScan",
  async (payload) => {
    const url = APP_CONFIG.api + `v1/scan/updatescan/${payload.id}`;
    payload.baseStationId =
      payload.baseStationId === 0 ? null : payload.baseStationId;
    const response = await axios.put(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.data;
  }
);

export const updateScanUrl = createAsyncThunk(
  "admin/scanApp/updateScanUrl",
  async (payload) => {
    const url = APP_CONFIG.api + `v1/scan/updateScanUrl/${payload.id}`;
    const response = await axios.put(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.data;
  }
);

export const removeScan = createAsyncThunk(
  "admin/scanApp/removeScan",
  async (scanId, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/deletescan/${scanId}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const getScanFilesPath = createAsyncThunk(
  "admin/scanApp/getScanFilesPath",
  async (scanId, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/getScanFilesPath/${scanId}`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;
    dispatch(setScanFilesPath(data.scanFilePath));
    return data;
  }
);

export const processScan = createAsyncThunk(
  "admin/scanApp/processScan",
  async (scanId, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/getProcessScanStatus?scanId=${scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const startprocessScan = createAsyncThunk(
  "admin/scanApp/startprocessScan",
  async (scanIds, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/startProcessScan?scanId=${scanIds}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status === 200) {
          const processPage = localStorage.getItem("ProcessPage");
          if (processPage !== "ProcessPage") {
            dispatch(processScan(scanIds[0]));
          }
        }
      })
      .catch((error) => {});
  }
);
export const startWaitingProcessScan = createAsyncThunk(
  "admin/scanApp/startprocessScan",
  async (scan, { dispatch }) => {
    const url =
      APP_CONFIG.api +
      `v1/stepfunction/startProcessScanById?scanId=${scan.id}&deviceId=${scan.deviceId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          var processPage = localStorage.getItem("ProcessPage");
          if (processPage == "ProcessPage") {
            dispatch(runningProcessScan());
            localStorage.setItem("ProcessPage", "");
          } else {
            dispatch(processScan(scan.id));
            dispatch(runningProcessScan());
          }
        }
      })
      .catch((error) => {});
  }
);

export const stopprocessScan = createAsyncThunk(
  "admin/scanApp/stopprocessScan",
  async (scanId, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/stopProcessScan?scanId=${scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          dispatch(processScan(scanId));
        }
      })
      .catch((error) => {});
  }
);

export const updateScanArea = createAsyncThunk(
  "admin/scanApp/updateScanArea",
  async (updateScan, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/updateScanArea/${updateScan.id}`;
    const response = await axios.put(url, updateScan, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    swal("", "Acres updated successfully", "success");
    dispatch(loadAllSitesDetails());
    dispatch(getAllSiteDetails());
  }
);

export const updateScanOwnerOrganisation = createAsyncThunk(
  "admin/scanApp/updateScanOwnerOrganisation",
  async (updateScan, { dispatch }) => {
    const url =
      APP_CONFIG.api +
      `v1/scan/updateScanOwnerOrganisation/${updateScan.data.id}`;
    const response = await axios.put(url, updateScan.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        if (data.status == 200) {
          if (updateScan.isScanRequestFromProcessing == true) {
            dispatch(runningProcessScan());
          } else {
            dispatch(loadAllSitesDetails());
          }
          swal("", "Owner Organization updated successfully", "success");
        }
      })
      .catch((error) => {});
  }
);

export const updateScanSite = createAsyncThunk(
  "admin/scanApp/updateScanSite",
  async (updateScan, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/updateScanSite/${updateScan.data.id}`;
    const response = await axios.put(url, updateScan.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.resolve(response)
      .then((data) => {
        updateScan.successCallback();
      })
      .catch((error) => {});
  }
);

export const updateScanDeviceId = createAsyncThunk(
  "admin/scanApp/updateScanDeviceId",
  async (updateScan, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/scan/updateScanDeviceId/${updateScan.data.id}`;
    const response = await axios.put(url, updateScan.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        if (data.status == 200) {
          if (updateScan.successCallback) {
            updateScan.successCallback();
            swal("", "Device id updated successfully", "success");
          }
        }
      })
      .catch((error) => {});
  }
);

export const updateScanIsFree = createAsyncThunk(
  "admin/scanApp/updateScanIsFree",
  async (updateScan, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/updateScanIsFree/${updateScan.id}`;
    const response = await axios.put(url, updateScan, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    swal("", "Free Status updated successfully", "success");
    dispatch(loadAllSitesDetails());
  }
);

export const getScanPanoramaMasksByScanId = (scanId) => {
  const url = APP_CONFIG.api + `v1/scan/getScanPanoramaMasks/${scanId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const updateScanExcludeBilling = createAsyncThunk(
  "admin/scanApp/updateScanExcludeBilling",
  async (scanData, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/scan/updateScanExcludeBilling/${scanData.data.id}`;

    const response = await axios.put(url, scanData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        scanData.successCallBack();
      })
      .catch((error) => {});
  }
);

export const revertBackScanPotreeFile = createAsyncThunk(
  "admin/scanApp/revertBackScanPotreeFile",
  async (requestData, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/scan/revertBackScanPotreeFile/${requestData.scanId}`;

    const response = await axios.put(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        if (data.data == true) {
          requestData.successCallBack();
        } else {
          requestData.failurCallBack();
        }
      })
      .catch((error) => {
        //  requestData.failurCallBack();
      });
  }
);

export const getRevertToOriginalInProgress = (scanId) => {
  const url =
    APP_CONFIG.api + `v1/scan/getRevertToOriginalInProgress/${scanId}`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

// export const cancleRevertBackToOrirginal =(data) =>{
//     const url = APP_CONFIG.api + `v1/scan/cancelRevertBackScanPotreeFile/${data.scanId}`;
//     const response =  axios.get(url, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
//     const data = await response.data;
//     return data;
//     data.successCallBack();
//     return response;
// }

export const cancelRevertBackToOrirginal = createAsyncThunk(
  "admin/scanApp/cancelRevertBackScanPotreeFile",
  async (requestData, { dispatch }) => {
    const url =
      APP_CONFIG.api +
      `v1/scan/cancelRevertBackScanPotreeFile/${requestData.scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    requestData.successCallBack();
    return data;
  }
);

export const getAllScanDetails = (siteId) => {
  const url = APP_CONFIG.api + `v1/scan/getScanDetails/${siteId}`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const handleCreateScanState = (value) => async (dispatch) => {
  dispatch(setCreateScanState(value));
};

export const handleUpdateScanState = (value) => async (dispatch) => {
  dispatch(setUpdateScanState(value));
};

export const handleRemoveScanState = (value) => async (dispatch) => {
  dispatch(setRemoveScanState(value));
};

const scanSlice = createSlice({
  name: "admin/scanApp",
  initialState: {
    scans: [],
    loadingState: "",
    createScanState: "",
    updateScanState: "",
    removeScanState: "",
    scanFilesPath: {},
    processScans: [],
    runningScans: [],
    testRunningScans: [],
    scanPanoramaMasks: [],
    processingStatus: true,
    scanProcessingQueues: [],
  },

  reducers: {
    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    setCreateScanState: (state, action) => {
      state.createScanState = action.payload;
    },
    setUpdateScanState: (state, action) => {
      state.updateScanState = action.payload;
    },
    setRemoveScanState: (state, action) => {
      state.removeScanState = action.payload;
    },
    setProcessScans: (state, action) => {
      state.processScans = action.payload;
    },
    setRunningProcessScan: (state, action) => {
      state.runningScans = action.payload;
    },
    setScanFilesPath: (state, action) => {
      state.scanFilesPath = action.payload;
    },
    setTestingRunningProcessScan: (state, action) => {
      state.testRunningScans = action.payload;
    },
    setProcessingQueues: (state, action) => {
      state.scanProcessingQueues = action.payload;
    },
  },

  extraReducers: {
    [loadScans.fulfilled]: (state, action) => {
      state.scans = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadScans.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadScans.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [createScan.fulfilled]: (state, action) => {
      state.scanFilesPath = action.payload;
      state.createScanState = SERVICE_STATUS.fulfilled;
    },
    [createScan.rejected]: (state, action) => {
      state.createScanState = SERVICE_STATUS.rejected;
    },
    [createScan.pending]: (state, action) => {
      state.createScanState = SERVICE_STATUS.pending;
    },
    [createScanAuto.fulfilled]: (state, action) => {
      state.scanFilesPath = action.payload;
      state.createScanState = SERVICE_STATUS.fulfilled;
    },
    [createScanAuto.rejected]: (state, action) => {
      state.createScanState = SERVICE_STATUS.rejected;
    },
    [createScanAuto.pending]: (state, action) => {
      state.createScanState = SERVICE_STATUS.pending;
    },
    [updateScan.fulfilled]: (state, action) => {
      state.updateScanState = SERVICE_STATUS.fulfilled;
    },
    [updateScan.rejected]: (state, action) => {
      state.updateScanState = SERVICE_STATUS.rejected;
    },
    [updateScan.pending]: (state, action) => {
      state.updateScanState = SERVICE_STATUS.pending;
    },
    [removeScan.fulfilled]: (state, action) => {
      state.removeScanState = SERVICE_STATUS.fulfilled;
    },
    [removeScan.rejected]: (state, action) => {
      state.removeScanState = SERVICE_STATUS.rejected;
    },
    [removeScan.pending]: (state, action) => {
      state.removeScanState = SERVICE_STATUS.pending;
    },
    [getScanFilesPath.fulfilled]: (state, action) => {
      state.scanFilesPath = action.payload.scanFilePath;
      state.scanPanoramaMasks = action.payload.scanPanorama;
      state.processingStatus = action.payload.scanProcessingStatus;
    },
    [processScan.fulfilled]: (state, action) => {
      state.processScans = action.payload.scanProcessings;
      state.scanProcessingQueues = action.payload.scanProcessingQueues;
    },
  },
});

export const {
  setLoadingState,
  setCreateScanState,
  setUpdateScanState,
  setRemoveScanState,
  setProcessScans,
  setRunningProcessScan,
  setScanFilesPath,
  setTestingRunningProcessScan,
  setProcessingQueues,
} = scanSlice.actions;

export default scanSlice.reducer;
