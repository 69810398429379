import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  ListItemButton,
  ListItem,
  Tooltip,
  OutlinedInput,
  Grid,
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import CropIcon from "@mui/icons-material/Crop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const Section = styled.div`
  padding-left: 20px;
  display: flex;
  padding-bottom: 10px;
`;
const Item = styled.div`
  padding: 5px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
  color: ${(props) => (props.selected ? "#565656" : "#ffffff")};
`;

const InsideOutSideButton = styled.div`
  width: 80px;
  padding: 2px 15px;
  border: 1px solid #565656;
  border-radius: 15px;
  margin: 5px;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
  color: ${(props) => (props.selected ? "#565656" : "#ffffff")};
`;

const ResetIcon = styled.div`
  padding: 0px;
  border: 2px solid #565656;
  border-radius: 20px;
  margin: 0px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
`;

const Clip = styled.div`
  padding-right: 10px;
`;

const useStyles = makeStyles({
  text: {
    fontWeight: "bold",
  },
});

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

function ClippingAndCrossSection(props) {
  const {
    reset,
    selectClipping,
    clipping,
    isClipping,
    clip,
    onSelectCrossSection,
    crossSection,
    crossSectionLineWidth,
    onChangeCrossSectionLineWidth,
    showCrossSectionProfileButton,
    onClickCrossSectionShowProfile,
  } = props;

  const [clippingMinimize, setClippingMinimize] = useState(false);

  const handleOnChangeAccordion = () => {
    setClippingMinimize(!clippingMinimize);
    if (clippingMinimize) {
      MixPanelIntegration.EventClicks("Clipping And CrossSection Close Click");
    } else {
      MixPanelIntegration.EventClicks("Clipping And CrossSection Expand Click");
    }
  };

  return (
    <Accordion
      expanded={clippingMinimize}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          clippingMinimize ? (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel3bh-content"
        id="panel3"
      >
        {!clippingMinimize ? (
          <TypographyStyles>Clipping & Cross Section</TypographyStyles>
        ) : (
          <div
            className="flex items-center justify-between"
            style={{ width: "100%" }}
          >
            <div>
              <div className="flex items-center">
                <ListItem disablePadding style={{ width: "auto" }}>
                  <ListItemButton
                    style={{ pointerEvents: "none", paddingLeft: "0px" }}
                  >
                    {/* <ListItemText classes={{ primary: classes.text }} primary="Clipping" /> */}
                    <TypographyStyles>Clipping</TypographyStyles>
                  </ListItemButton>
                </ListItem>
                <div style={{ marginRight: "0px" }}>
                  <Tooltip title="Reset">
                    <ResetIcon onClick={reset} id="clipping">
                      <RestartAltIcon />
                    </ResetIcon>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <ListItem disablePadding style={{ width: "auto" }}>
                  <ListItemButton
                    className="pl-0"
                    style={{ pointerEvents: "none" }}
                  >
                    <TypographyStyles>Cross Section</TypographyStyles>
                  </ListItemButton>
                </ListItem>
                <div style={{ marginRight: "0px" }}>
                  <Tooltip title="Reset">
                    <ResetIcon onClick={reset} id="crossSection">
                      <RestartAltIcon />
                    </ResetIcon>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16} className="pt-16">
              <Grid item xs={8} className="pr-16 pl-8">
                <Section
                  style={{
                    paddingBottom: "0px",
                    paddingLeft: "10px",
                    display: "ruby",
                  }}
                >
                  <Item
                    onClick={selectClipping}
                    selected={clipping === "volume"}
                    id="volume"
                  >
                    <ContentCutIcon />
                  </Item>

                  <div className="flex flex-col">
                    <Section className="py-0">
                      <InsideOutSideButton
                        selected={isClipping == "inside"}
                        id="inside"
                        onClick={clip}
                      >
                        Inside
                      </InsideOutSideButton>
                    </Section>

                    <Section>
                      <Clip onClick={clip} id="outside">
                        <InsideOutSideButton
                          selected={isClipping == "outside"}
                          id="outside"
                        >
                          Outside
                        </InsideOutSideButton>
                      </Clip>
                    </Section>
                  </div>
                </Section>
              </Grid>

              <Grid item xs={8} className="px-16">
                <div className="flex items-center">
                  <Item onClick={onSelectCrossSection} selected={crossSection}>
                    <CropIcon />
                  </Item>

                  <div className="flex flex-col">
                    <Section className="flex items-center pl-4 py-0">
                      <span className="mr-4">
                        <b>Width</b>
                      </span>
                      <OutlinedInput
                        style={{
                          height: "35px",
                          width: "60px",
                        }}
                        className="ml-8 custom-input"
                        inputProps={{ step: "1" }}
                        type="number"
                        placeholder="0"
                        value={crossSectionLineWidth}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            onChangeCrossSectionLineWidth(e.target.value);
                          }
                        }}
                      />
                    </Section>
                  </div>
                </div>
                <div className="float-right">
                  {showCrossSectionProfileButton && (
                    <Tooltip title="Show profile">
                      <Button
                        onClick={onClickCrossSectionShowProfile}
                        variant="outlined"
                        style={{
                          background: "#565656",
                          color: "#ffffff",
                          border: "1px solid #565656",
                          width: "115px",
                        }}
                      >
                        Show
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ClippingAndCrossSection;

