import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "../../../AppConfig";
import swal from "sweetalert";

export const demoregisterUser = (requestedData) => {
    const url = APP_CONFIG.api + `v1/demoRegister/demoRegisterUser`;
    const response = axios.post(url, requestedData.data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response;
}


export const registerUser = createAsyncThunk('register/registerApp/registerUser',
    (requestedData, { dispatch }) => {
       var data ={
        name : requestedData.data.name,
        email : requestedData.data.email,
        password : requestedData.data.password,
       }
        const url = APP_CONFIG.api + `v1/register/registerUserDetails`;
        const request = axios.post(url,data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });

        Promise.resolve(request).then((response) => {
            requestedData.successCallback();
        })
            .catch((error) => {
                var errorMessage = "";
                if (error.response.data && error.response && error.response.data && typeof (error.response.data) == "string") {
                    errorMessage = error.response.data;
                }
                requestedData.failureCallback(errorMessage);
            })

    }
);
export const decodeEmail = createAsyncThunk(
    'register/registerApp/decodeEmail',
    async (email, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/register/inviteuservaild?encodeEmail=${email}`;
        const response = await axios.get(url, email, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
         
        const data = await response.data;
        return data;
    }
);
const registerSlice = createSlice({
    name: 'register',
    initialState: {
        registerUserState: '',
        registerUserFailur: '',
        encodeEmailState: '',
        email: '',
    },

    reducers: {
        setRegisterUserState: (state, action) => {
            state.registerUserState = action.payload;
        },
        setencodeEmailState: (state, action) => {
            state.encodeEmailState = action.payload;
            state.email = action.payload;
        }

    },
    extraReducers: {
        [decodeEmail.fulfilled]: (state, action) => {
            if (action.payload.isCustomerExist == true) {
                swal("", " The link is no longer valid because that account has already been set up.", "error")
                    .then(() => {
                        window.location.href = window.location.origin
                    });
            } else if (action.payload.isInValidRequest == true) {
                swal("", " The link is no longer valid", "error")
                    .then(() => {
                        window.location.href = window.location.origin
                    });
            } else {
                state.email = action.payload.mail;
            }
        },

    },
});

export const { setRegisterUserState, setencodeEmailState } = registerSlice.actions;

export default registerSlice.reducer;