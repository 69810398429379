import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CustomShapeDivider from "./CustomShapeDivider";
import styles from "./Page404.styles";
import { useNavigate } from "react-router-dom";

const LooqImage = ({ source, sx }) => (
  <Box sx={sx}>
    <img src={source} alt="Looq Image" style={styles.logo512Image} />
  </Box>
);

const DualButtons = () => {
  const navigate = useNavigate();

  const handleGoToHomepage = () => {
    navigate("/apps/dashboards/sites");
  };

  const handleEmailSupport = () => {
    navigate("/apps/user/support");
  };

  return (
    <>
      <Button sx={styles.buttonPrimary} onClick={handleGoToHomepage}>
        Go to Homepage
      </Button>
      <Button sx={styles.buttonSecondary} onClick={handleEmailSupport}>
        Email Looq Support
      </Button>
    </>
  );
};

const CallToAction = () => (
  <Box sx={styles.callToActionBox}>
    <Typography
      variant="h1"
      component="div"
      fontWeight="bold"
      sx={styles.title}
    >
      404
    </Typography>
    <Typography variant="subtitle1" sx={styles.subtitle}>
      Oops! The page you&apos;re looking for doesn&apos;t exist.
    </Typography>
    <DualButtons />
  </Box>
);

const Page404 = () => {
  const logo = "/logo.png";

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.topLeftImage}>
        <img
          src="/Looqmain.png"
          alt="Looq Logo"
          style={{ width: "100%", height: "auto" }}
        />
      </Box>

      <CallToAction />
      <LooqImage sx={styles.looqImageContainer} source={logo} />
      <CustomShapeDivider />
    </Box>
  );
};

export default Page404;
