import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "app/auth/store/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import AvatarImage from "app/shared-components/image/AvatarImage";

import { Buttons } from "app/main/apps/scan/potree/coordinate-viewer/CoordinateViewer.style";
import { setSiteActiveViewerTab } from "app/auth/store/customNavigationSlice";
import {
  FolderOpen,
  HeadsetMicOutlined,
  LocationOn,
  Search,
  Share,
} from "@mui/icons-material";

import { updateShowNotifications } from "app/main/apps/admin/store/notificationSlice";
import { makeStyles } from "@mui/styles";
import { GoBell } from "react-icons/go";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const useStyles = makeStyles(() => ({
  badge: {
    top: "20px",
    color: "#ffffff",
    right: "23px",
    backgroundColor: "#E14F38",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
  },
}));

// eslint-disable-next-line complexity
function UserMenu() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const customer = useSelector(({ auth }) => auth.customer);
  const { role } = useSelector(({ auth }) => auth.user);
  const { siteActiveViewerTab, showSiteViewerActions } = useSelector(
    ({ auth }) => auth.customNavigation
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const { notifications } = useSelector(({ admin }) => admin.notificationsApp);
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };
  const handleOnProfileClick = () => {
    userMenuClose();
    navigate(`apps/user/userprofile`);
  };

  const handleOnSupportClick = () => {
    userMenuClose();
    navigate(`apps/user/support`);
  };

  const { largeUploadingFiles, smallUploadingFiles } = useSelector(
    ({ files }) => files.uploadApp
  );

  const onClickChangeViewer = (actionViewer) => {
    dispatch(setSiteActiveViewerTab(actionViewer));
  };

  return (
    <>
      {window.location.pathname.includes(`/apps/sites/`) &&
        showSiteViewerActions && (
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="mx-24"
            style={{ backgroundColor: "#F5F3F3", borderRadius: "8px" }}
          >
            <StyledToggleButtonGroup
              size="small"
              exclusive
              aria-label="text alignment"
            >
              <Buttons>
                <Button
                  className="flex-col"
                  style={{
                    backgroundColor:
                      siteActiveViewerTab === "share" ? "#E1E1E1" : "",
                    borderRadius: "8px",
                  }}
                  size="small"
                  onClick={() => onClickChangeViewer("share")}
                >
                  <Share style={{ color: "#4D4D4F" }} />
                  <Typography style={{ color: "#4D4D4F" }}>
                    <b>Share</b>
                  </Typography>
                </Button>
                <Button
                  className="flex-col"
                  style={{
                    backgroundColor:
                      siteActiveViewerTab === "scans" ? "#E1E1E1" : "",
                    borderRadius: "8px",
                  }}
                  size="small"
                  onClick={() => onClickChangeViewer("scans")}
                >
                  <FolderOpen style={{ color: "#4D4D4F" }} />
                  <Typography style={{ color: "#4D4D4F" }}>
                    <b>Files</b>
                  </Typography>
                </Button>
                <Button
                  className="flex-col"
                  style={{
                    backgroundColor:
                      siteActiveViewerTab === "site" ? "#E1E1E1" : "",
                    borderRadius: "8px",
                  }}
                  size="small"
                  onClick={() => onClickChangeViewer("site")}
                >
                  <LocationOn style={{ color: "#4D4D4F" }} />
                  <Typography style={{ color: "#4D4D4F" }}>
                    <b>Site</b>
                  </Typography>
                </Button>

                <Button
                  className="flex-col"
                  style={{
                    backgroundColor:
                      siteActiveViewerTab === "view" ? "#E1E1E1" : "",
                    borderRadius: "8px",
                  }}
                  size="small"
                  onClick={() => onClickChangeViewer("view")}
                >
                  <Search style={{ color: "#4D4D4F" }} />
                  <Typography style={{ color: "#4D4D4F" }}>
                    <b>View</b>
                  </Typography>
                </Button>
              </Buttons>
            </StyledToggleButtonGroup>
          </Paper>
        )}

      {largeUploadingFiles.length === 0 &&
      smallUploadingFiles.length === 0 ? null : (
        <Tooltip title="File Upload Status">
          <Button
            className="mr-12"
            style={{ color: "#59BEC9" }}
            component={Link}
            to="/apps/file/uploadstatus"
          >
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <CircularProgress color="error" />
              <span>Uploading...</span>
            </div>
          </Button>
        </Tooltip>
      )}

      {notifications.length > 0 && (
        <IconButton
          className="w-40 h-40"
          onClick={() => dispatch(updateShowNotifications(true))}
          size="large"
        >
          <Badge variant="dot" classes={{ badge: classes.badge }}>
            <GoBell style={{ color: "#556A6D" }} />
          </Badge>
        </IconButton>
      )}

      {user.role.includes("demouser") ? (
        <div className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6">
          <div className="hidden md:flex flex-col mx-4 items-end">
            <Avatar
              className="md:mx-4"
              alt="user photo"
              src="/assets/images/avatars/Guest.png"
            />
          </div>
        </div>
      ) : (
        <Button
          className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
          onClick={userMenuClick}
          color="inherit"
        >
          <div className="hidden md:flex flex-col mx-4 items-end">
            <Typography component="span" className="font-semibold flex">
              {user.data.displayName}
            </Typography>
            <Typography
              className="text-11 font-medium capitalize"
              color="textSecondary"
            >
              {user.role.includes("organisationadmin")
                ? "Admin"
                : user.role.includes("admin")
                ? "Admin"
                : user.role.includes("projectmanager")
                ? "Manager"
                : user.role.includes("staff")
                ? "Staff"
                : user.role.includes("user")
                ? "User"
                : "Guest"}
            </Typography>
          </div>

          {customer.userImageUrl ? (
            <AvatarImage
              alt="user photo"
              className="md:mx-4"
              src={customer.userImageUrl}
            />
          ) : user.data.photoURL ? (
            <Avatar
              className="md:mx-4"
              alt="user photo"
              src={user.data.photoURL}
            />
          ) : (
            <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
          )}
        </Button>
      )}

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => handleOnProfileClick()}>
              <ListItemIcon className="min-w-40">
                <Icon>account_box</Icon>
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            {!role.includes("admin") && (
              <MenuItem onClick={() => handleOnSupportClick()}>
                <ListItemIcon className="min-w-40">
                  <HeadsetMicOutlined />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                logout();
                dispatch(logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
