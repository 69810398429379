import { createSlice } from '@reduxjs/toolkit';

const customNavigationSlice = createSlice({
    name: 'auth/customNavigationApp',
    initialState: {
        siteActiveViewerTab:'',
        showSiteViewerActions: false,
        showSiteViewerAddScanButton: false,
        siteViewerAddScanAction: null,
    },
    reducers: {

        resetSiteViewer:(state, action) =>{
            state.siteActiveViewerTab = '';
            state.showSiteViewerActions = false;
            state.showSiteViewerAddScanButton = false;
            state.siteViewerAddScanAction = null;
        },
        setShowSiteViewerActions:(state, action) =>{
            state.showSiteViewerActions = action.payload;
        },
        setSiteActiveViewerTab: (state, action) =>{
            state.siteActiveViewerTab = action.payload;
        },
        setShowSiteViewerAddScanButton: (state, action) =>{
            state.showSiteViewerAddScanButton = action.payload;
        },  
        setSiteViewerAddScanAction: (state, action) =>{
            state.siteViewerAddScanAction = action.payload;
        },
    }
    
});

export const {resetSiteViewer, setShowSiteViewerActions, setSiteActiveViewerTab,setShowSiteViewerAddScanButton,setSiteViewerAddScanAction} = customNavigationSlice.actions;

export default customNavigationSlice.reducer;