const url = window.location.href;

const getAppConfig = function () {
  if (url.includes("https://localhost") || url.includes("http://localhost")) {
    return {
      api: "http://localhost:3010/",
      // domain: 'dev-22jk2n22.us.auth0.com',
      // clientId: 'Uvrv3XWUa9b3YAnRIA3UaFItdRlOnTPE', // looq.ai Localhost
      domain: "limarai-localhost.us.auth0.com",
      clientId: "DcOXyIckGV0K7dlziobl01LppOj2bQGi",
      callbackUrl: "https://localhost:3000/",
      audience: "https://limarai.com/webservice", // limar.ai audience
      // audience: 'https://looq.ai/webservice', // looq.ai audience
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  } else if (url.includes("https://10.200.0.78")) {
    return {
      api: "https://10.200.0.78:3010/",
      domain: "limarai-localhost.us.auth0.com",
      clientId: "DcOXyIckGV0K7dlziobl01LppOj2bQGi",
      callbackUrl: "https://10.200.0.78:3001/",
      audience: "https://limarai.com/webservice",
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  } else if (url.includes("https://app.limar.ai")) {
    return {
      api: "https://api.limar.ai/",
      domain: "dev-22jk2n22.us.auth0.com",
      clientId: "jkoZ2g3xcyuETSuWzMpAOAykVQhrV3un",
      callbackUrl: "https://app.limar.ai/",
      audience: "https://limarai.com/webservice",
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  } else if (url.includes("https://app-dev.limar.ai")) {
    return {
      api: "https://api-dev.limar.ai/",
      domain: "dev-22jk2n22.us.auth0.com",
      clientId: "6boH0mvbNbbdEzNJQFyW2NtxxcUtdJ11",
      callbackUrl: "https://app-dev.limar.ai/",
      audience: "https://limarai.com/webservice",
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  } else if (url.includes("https://app.looq.ai")) {
    return {
      api: "https://api.looq.ai/",
      domain: "dev-22jk2n22.us.auth0.com",
      clientId: "ECPX6rxl0noqcP5FtOKsUDI3ZUZYQBI7",
      callbackUrl: "https://app.looq.ai/",
      audience: "https://looq.ai/webservice",
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  } else if (url.includes("https://app-dev.looq.ai")) {
    return {
      api: "https://api-dev.looq.ai/",
      domain: "limarai-dev.us.auth0.com",
      clientId: "PobzgXfu6INNYjlQJ9Kf6ZhHtBzwkvyF",
      callbackUrl: "https://app-dev.looq.ai/",
      audience: "https://looq.ai/webservice",
      scope: "openid profile email roles offline_access",
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };
  }
};

const APP_CONFIG = getAppConfig();

export default APP_CONFIG;

