import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const getSiteAccessUsers = createAsyncThunk('admin/siteUsers/getSiteAccessUsers', async (siteId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/site/getSiteAccessUsers/${siteId}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.data;
    dispatch(setUsers(data));
    return data;
});

export const setSiteAccessToUser = createAsyncThunk('admin/siteUsers/getSiteAccessUsers', async (siteAccessData, { dispatch, getState }) => {
    var formData = new FormData();
    formData.append("siteId", siteAccessData.siteId);
    formData.append("userId", siteAccessData.userId);
    formData.append("siteRole", siteAccessData.siteRole);
    const url = APP_CONFIG.api + `v1/site/setSiteAccessToUser`;
    const response = axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    Promise.resolve(response).then((response) => {
        if (response.status == 200) {
            dispatch(getSiteAccessUsers(siteAccessData.siteId));
        }

    }).catch((error) => {
        console.error(error);
    });
});

export const removeSiteAccessToUser = createAsyncThunk('admin/siteUsers/removeSiteAccessToUser', async (siteAccessData, { dispatch, getState }) => {
    var formData = new FormData();
    formData.append("siteId", siteAccessData.siteId);
    formData.append("userId", siteAccessData.userId);
    const url = APP_CONFIG.api + `v1/site/removeSiteAccessToUser`;
    const response = axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },

    });
    Promise.resolve(response).then((response) => {
        if (response.status == 200) {
            dispatch(getSiteAccessUsers(siteAccessData.siteId));
        }

    }).catch((error) => {
        console.error(error);
    });
});



const siteUsersSlice = createSlice({
    name: 'admin/siteUsers',
    initialState: {
        users: []
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
    },
});

export const { setUsers } = siteUsersSlice.actions;

export default siteUsersSlice.reducer;
