import axios from "axios";
import APP_CONFIG from "app/AppConfig";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export function loadImageInfos(scanId, offset, limit, coordinateSource) {
  const url =
    APP_CONFIG.api +
    `v1/scans/${scanId}/images?coordinates=${coordinateSource}&offset=${offset}&limit=${limit}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function loadPanoImageInfos(scanId, offset, limit, totalCount) {
  const url =
    APP_CONFIG.api +
    `v1/scans/${scanId}/panoimages?offset=${offset}&limit=${limit}&totalCount=${totalCount}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function loadGpsImageInfos(scanId, offset, limit) {
  const url =
    APP_CONFIG.api +
    `v1/scans/${scanId}/gpsimages?offset=${offset}&limit=${limit}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function loadAllGpsImageInfos(scanId, offset, limit, totalCount) {
  const url =
    APP_CONFIG.api +
    `v1/scans/${scanId}/gpspanoimages?offset=${offset}&limit=${limit}&totalCount=${totalCount}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function loadClosestImageInfos(scanId, x, y, z, offset, limit) {
  const url =
    APP_CONFIG.api +
    `v1/scans/${scanId}/images?x=${x}&y=${y}&z=${z}&offset=${offset}&limit=${limit}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function getGeoJsonForMapbox(scanId) {
  const url = APP_CONFIG.api + `v1/geojson/${scanId}/mapbox`;
  const response = axios.get(url, config);
  return Promise.resolve(response);
}

export function getMarkersForPotree(scanId) {
  const url = APP_CONFIG.api + `v1/geojson/${scanId}/potree`;
  const response = axios.get(url, config);
  return Promise.resolve(response);
}


export async function createIssue(issue) {
  const url = APP_CONFIG.api + `v1/issues`;
  const response = axios.post(url, issue, config);

  return await Promise.resolve(response);
}

export function getIssues(scanId, offset, limit) {
  const url =
    APP_CONFIG.api + `v1/issues/${scanId}?offset=${offset}&limit=${limit}`;
  const response = axios.get(url, config);

  return Promise.resolve(response);
}

export function updateIssueAttribute(id, attributeName, attributeValue) {
  const url = APP_CONFIG.api + `v1/issues/${id}/${attributeName}`;

  const data = {
    value: attributeValue,
  };

  const response = axios.put(url, data, config);

  return Promise.resolve(response);
}
