import { useCallback } from "react";
import * as THREE from "three";

const useZoomFunctions = (viewer, updateSpheres) => {
  const TWEEN = window.TWEEN;

  const zoomToMultiplePoints = useCallback(
    (coordinates, selectedMarkerIndex) => {
      let _coordinates = [...coordinates].filter((_, index) =>
        selectedMarkerIndex.includes(index)
      );

      let x = _coordinates.map((x) => (x.model ? x.model.x : x.control.x));
      let y = _coordinates.map((x) => (x.model ? x.model.y : x.control.y));
      let z = _coordinates.map((x) => (x.model ? x.model.z : x.control.z));

      let min = { x: Math.min(...x), y: Math.min(...y), z: Math.min(...z) };
      let max = { x: Math.max(...x), y: Math.max(...y), z: Math.max(...z) };

      let vertices = [
        new THREE.Vector3(min.x, min.y, min.z),
        new THREE.Vector3(max.x, max.y, max.z),
      ];

      if (_coordinates.length > 1) {
        let box = new THREE.Box3();
        box.setFromPoints(vertices);
        let node = new THREE.Object3D();
        node.boundingBox = box;

        viewer.zoomTo(node, 1, 0);
        viewer.controls.stop();
        updateSpheres();
      } else if (_coordinates.length === 1) {
        let mainPoint = new THREE.Vector3(
          _coordinates[0].control?.x || _coordinates[0].model?.x,
          _coordinates[0].control?.y || _coordinates[0].model?.y,
          _coordinates[0].control?.z || _coordinates[0].model?.z
        );
        zoomToPoint(2.5, mainPoint);
      }
    },
    [viewer, updateSpheres]
  );

  const zoomToPoint = useCallback(
    (radius, location) => {
      let targetRadius = radius;
      let d = viewer.scene.view.direction.clone().multiplyScalar(-1);
      let cameraTargetPosition = new THREE.Vector3().addVectors(
        location,
        d.multiplyScalar(targetRadius)
      );

      let animationDuration = 600;
      let easing = TWEEN.Easing.Quartic.Out;
      let value = { x: 0 };
      let tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);

      tween.easing(easing);
      viewer.controls.tweens.push(tween);

      let startPos = viewer.scene.view.position.clone();
      let startRadius = viewer.scene.view.radius;

      tween.onUpdate(() => {
        let t = value.x;
        viewer.scene.view.position.lerpVectors(
          startPos,
          cameraTargetPosition,
          t
        );
        viewer.scene.view.radius = THREE.MathUtils.lerp(
          startRadius,
          targetRadius,
          t
        );
        viewer.setMoveSpeed(viewer.scene.view.radius / 2.5);
      });

      tween.onComplete(() => {
        viewer.controls.tweens = viewer.controls.tweens.filter(
          (e) => e !== tween
        );
        updateSpheres();
      });

      tween.start();
    },
    [viewer, updateSpheres]
  );

  return { zoomToPoint, zoomToMultiplePoints };
};

export default useZoomFunctions;
