import { createStyles, makeStyles } from "@mui/styles";

const ScanProcessingStyles = makeStyles(() =>
  createStyles({
    boldImportant: {
      fontWeight: "bolder !important",
    },
    headerId: {
      maxWidth: "5ch",
      width: "5ch",
      marginRight: "1em",
      fontWeight: "bolder !important",
    },
    headerConfig: {
      width: "7ch",
      maxWidth: "7ch",
      fontWeight: "bolder !important",
    },
    headerName: {
      width: "20%",
      maxWidth: "20%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginRight: "1em",
      fontWeight: "bolder !important",
    },
    headerSite: {
      width: "12%",
      maxWidth: "12%",
      fontWeight: "bolder !important",
    },
    headerPriority: {
      width: "10ch",
      maxWidth: "10ch",
      fontWeight: "bolder !important",
    },
    headerReorder: {
      width: "5ch",
      maxWidth: "5ch",
    },
    headerStatusInProgress: {
      width: "12ch",
      maxWidth: "12ch",
      fontWeight: "bolder !important",
    },
    headerStatus: {
      width: "20ch",
      maxWidth: "20ch",
      fontWeight: "bolder !important",
    },
    headerOrg: {
      width: "18ch",
      maxWidth: "18ch",
      fontWeight: "bolder !important",
    },
    step: {
      width: "14%",
      maxWidth: "14%",
      fontWeight: "bolder !important",
    },
    processingTaskFailedStatusHistory: {
      width: "25ch",
      maxWidth: "25ch",
      fontWeight: "bolder !important",
    },
    headerIp: {
      width: "12ch",
      maxWidth: "12ch",
      fontWeight: "bolder !important",
    },
    headerDevice: {
      maxWidth: "10ch",
      width: "10ch",
      fontWeight: "bolder !important",
    },
    headerDates: {
      maxWidth: "27ch",
      width: "27ch",
      fontWeight: "bolder !important",
    },
    action: {
      width: "14ch",
      maxWidth: "14ch",
      fontWeight: "bolder !important",
    },
    overflowHidden: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    active: {
      background: "#EFEEEE",
      width: "150px",
      zIndex: "1000",
      color: "#000000",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      left: "26px",
    },
    inactive: {
      width: "150px",
      color: "#000000",
      left: "0px",
      zIndex: "999",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      background: "#D9E4F6",
    },
    processingactive: {
      background: "#EFEEEE",
      width: "125px",
      zIndex: "1000",
      color: "#000000",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      left: "26px",
    },
    processinginactive: {
      width: "125px",
      color: "#000000",
      left: "0px",
      zIndex: "999",
      content: "",
      borderBottom: "none",
      borderRadius: "0.6em 0.6em 0 0",
      background: "#D9E4F6",
    },
    transformOverride: {
      transform: "scale(1, 1) perspective(0.3em) rotateX(-3deg)",
    },
    activeButton: {
      "&:hover": {
        backgroundColor: "#EFEEEE",
        color: "#000000",
        borderRadius: "0.6em 0.6em 0 0",
      },
    },
    inactiveButton: {
      "&:hover": {
        backgroundColor: "#D9E4F6",
        color: "#000000",
        borderRadius: "0.6em 0.6em 0 0",
      },
    },
  })
);

export default ScanProcessingStyles;
