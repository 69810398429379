import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

function CalibrateDailog(props) {

    const { handleOnClick } = props;

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleOnClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ width: '420px', height: '283px' }}>
                    <DialogTitle id="dialog-title" className='text-center' style={{
                        fontSize: 30,
                        fontWeight: 600,
                        letterSpacing: "-0.01em",
                        textAlign: "center",
                        color: "#58BEC9",
                        paddingTop: '50px'
                    }}>
                        Running Calibration
                    </DialogTitle>
                    <DialogContent className='text-center'>
                        <DialogContentText id="alert-dialog-description" style={{ color: '#000000', fontSize: '15px' }}>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='flex items-center mb-32 justify-center'>
                        <Button style={{
                            width: "105px",
                            height: "54px",
                            padding: "13px, 28px, 13px, 28px",
                            borderRadius: "4px",
                            margin: "20px",
                            backgroundColor: "#58BEC9",
                            color: "#ffffff",
                            fontSize: '20px'
                        }}
                            onClick={() => { handleOnClick() }}
                        >Yes</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default CalibrateDailog;