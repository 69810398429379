/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import APP_CONFIG from "../../../../AppConfig";

export const loadAllSitesDetails = createAsyncThunk(
  "admin/siteApp/loadAllSitesDetails",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/site/getAllSitesDetails`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const loadAllAdminSites = createAsyncThunk(
  "admin/siteApp/loadAllSites",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/site/getAllAdminSites`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const loadAllDeletedSiteScans = createAsyncThunk(
  "admin/siteApp/loadAllDeletedSiteScans",
  async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/site/getAllDeleteSitesScan`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const createSite = createAsyncThunk(
  "admin/siteApp/createSite",
  async (createSite, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/site/createSite`;

    const response = await axios.post(url, createSite, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const updateSite = createAsyncThunk(
  "admin/siteApp/updateSite",
  async (updateSite, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/site/updatesiteDeatils/${updateSite.id}`;

    const response = await axios.put(url, updateSite, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const removeSite = createAsyncThunk(
  "admin/siteApp/removeSite",
  async (siteId, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/site/deleteSiteDetails/${siteId}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const getSiteFilesPath = createAsyncThunk(
  "admin/siteApp/getSiteFilesPath",
  async (siteId, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/site/getSiteFilesPath/${siteId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;

    return data;
  }
);
export const getOrganizationSites = (organizationId) => {
  const url = APP_CONFIG.api + `v1/site/getOrganizationSites/${organizationId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getSiteEditInfo = (siteId) => {
  const url = APP_CONFIG.api + `v1/site/getSiteEditInfo/${siteId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getOwnerOrganizationSites = (organizationId) => {
  const url =
    APP_CONFIG.api + `v1/site/getOwnerOrganizationSites/${organizationId}`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const updateSiteExcludeBilling = createAsyncThunk(
  "admin/siteApp/updateSiteExcludeBilling",
  async (updateSite, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/site/updateSiteExcludeBilling/${updateSite.data.id}`;

    const response = await axios.put(url, updateSite.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((data) => {
        updateSite.successCallBack();
      })
      .catch((error) => {});
  }
);

export const getAllSiteDetails = () => {
  const url = APP_CONFIG.api + `v1/site/getSiteDetails`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getHomePageSitesDetails = (data) => {
  const url = APP_CONFIG.api + `v1/site/getHomePageSitesDetails`;
  const response = axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const getHorizontalDatumLookupData = () => {
  const url = APP_CONFIG.api + `v1/coordinateSystem/getHorizontalDatums`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
export const getVerticalDatumLookupData = () => {
  const url = APP_CONFIG.api + `v1/coordinateSystem/getVerticalDatums`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
export const getCoordinateSystemLookupData = () => {
  const url = APP_CONFIG.api + `v1/coordinateSystem/getCoordinateSystems`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getBaseStations = () => {
  const url = APP_CONFIG.api + `v1/site/getBaseStations`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updatecreateSiteState = (value) => async (dispatch) => {
  dispatch(setcreateSiteState(value));
};
export const updateupdateSiteState = (value) => async (dispatch) => {
  dispatch(setupdateSiteState(value));
};

export const updateremoveSiteState = (value) => async (dispatch) => {
  dispatch(setremoveSiteState(value));
};

const siteSlice = createSlice({
  name: "admin/siteApp",
  initialState: {
    sites: [],
    sitesDetails: [],
    deletedSites: [],
    loadingState: "",
    createSiteState: "",
    updateSiteState: "",
    removeSiteState: "",
    siteFilesPath: {},
    ownerOrganizationSite: [],
  },

  reducers: {
    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    setcreateSiteState: (state, action) => {
      state.createSiteState = action.payload;
    },
    setupdateSiteState: (state, action) => {
      state.updateSiteState = action.payload;
    },
    setremoveSiteState: (state, action) => {
      state.removeSiteState = action.payload;
    },
    setSiteFilesPath: (state, action) => {
      state.siteFilesPath = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
  },
  extraReducers: {
    [loadAllAdminSites.fulfilled]: (state, action) => {
      state.sites = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadAllAdminSites.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadAllAdminSites.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [loadAllSitesDetails.fulfilled]: (state, action) => {
      state.sitesDetails = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadAllSitesDetails.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadAllSitesDetails.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [createSite.fulfilled]: (state, action) => {
      state.siteFilesPath = action.payload;
      state.createSiteState = SERVICE_STATUS.fulfilled;
    },
    [createSite.rejected]: (state, action) => {
      state.createSiteState = SERVICE_STATUS.rejected;
    },
    [createSite.pending]: (state, action) => {
      state.createSiteState = SERVICE_STATUS.pending;
    },
    [updateSite.fulfilled]: (state, action) => {
      state.updateSiteState = SERVICE_STATUS.fulfilled;
    },
    [updateSite.rejected]: (state, action) => {
      state.updateSiteState = SERVICE_STATUS.rejected;
    },
    [updateSite.pending]: (state, action) => {
      state.updateSiteState = SERVICE_STATUS.pending;
    },
    [removeSite.fulfilled]: (state, action) => {
      state.removeSiteState = SERVICE_STATUS.fulfilled;
    },
    [removeSite.rejected]: (state, action) => {
      state.removeSiteState = SERVICE_STATUS.rejected;
    },
    [removeSite.pending]: (state, action) => {
      state.removeSiteState = SERVICE_STATUS.pending;
    },
    [loadAllDeletedSiteScans.fulfilled]: (state, action) => {
      state.deletedSites = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadAllDeletedSiteScans.rejected]: (state, action) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadAllDeletedSiteScans.pending]: (state, action) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [getSiteFilesPath.fulfilled]: (state, action) => {
      state.siteFilesPath = action.payload;
    },
    [getOwnerOrganizationSites.fulfilled]: (state, action) => {
      state.ownerOrganizationSite = action.payload;
    },
  },
});

export const {
  setLoadingState,
  setcreateSiteState,
  setupdateSiteState,
  setremoveSiteState,
  setSiteFilesPath,
  setSites,
} = siteSlice.actions;

export default siteSlice.reducer;
