import styled from "@emotion/styled";

export const TitlePanel = styled.div`
  display: flex;
`;

export const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
    cursor: pointer;
  }
`;

export const Spacer = styled.div`
  flex-grow: ${(props) => props.space};
`;
