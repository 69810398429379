import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Tooltip,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
// import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const Section = styled.div`
  padding-left: 20px;
  display: flex;
  padding-bottom: 10px;
`;
const Item = styled.div`
  padding: 5px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
  color: ${(props) => (props.selected ? "#565656" : "#ffffff")};
`;

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;

function GeoTools(props) {
  const { onClickDataPanel } = props;
  const [geoToolsMinimize, setGeoToolsMinimize] = useState(false);

  const handleOnChangeAccordion = () => {
    setGeoToolsMinimize(!geoToolsMinimize);
    if (geoToolsMinimize) {
      MixPanelIntegration.EventClicks("GeoTools Close Click");
    } else {
      MixPanelIntegration.EventClicks("GeoTools Expand Click");
    }
  };

  return (
    <Accordion
      className="m-0"
      expanded={geoToolsMinimize}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          geoToolsMinimize ? (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel6bh-content"
        id="panel6"
        className="m-0"
      >
        <TypographyStyles>Geo Tools</TypographyStyles>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <div>
          <div className="flex flex-col">
            <Section className="pl-0">
              <Tooltip title="Align to Control (Beta)">
                <Item onClick={() => onClickDataPanel("alignToControl")}>
                  <RoomOutlinedIcon />
                </Item>
              </Tooltip>
              {/* <Tooltip title="Point Selector (Beta)"> */}
              {/*   <Item onClick={() => onClickDataPanel("pointImport")}> */}
              {/*     <AddLocationOutlinedIcon /> */}
              {/*   </Item> */}
              {/* </Tooltip> */}
            </Section>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default GeoTools;
