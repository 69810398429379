import React, { useEffect, useState } from 'react';
import {
    ListItem, Tooltip, IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styled from "@emotion/styled";
import { DEFAULT_CLASSIFICATIONS } from "./SiteClassificationView";
import SiteClassificationView from "./SiteClassificationView";
import SiteClassificationViewEdit from "./SiteClassificationViewEdit";
import { useDispatch } from "react-redux";
import SiteClassificationSave from './SiteClassificationSave';
import { loadAllClassificationProfiles, getClassificationProfileInfos, saveSiteClassification, updateSiteClassification, getUserDefaultClassificationSettings } from '../store/SiteClassificationSlice';
import DeleteIcon from "@mui/icons-material/Delete";
import { removeProfile } from '../store/SiteClassificationSlice';
import UpdateIcon from '@mui/icons-material/Update';
import { convertClassificationListToObject, convertClassificationObjectToList } from './classification/ClassificationConvertor';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';


const OuterPanel = styled.div`
`;

const Section = styled.div`
  padding-left: 20px;
  display: flex;  
`;

function SiteClassifications(props) {

    const dispatch = useDispatch();
    const { classifications, onColorChange, onToggleVisiblity, selectedSite, updateClassifications } = props;
    const [isEditable, setIsEditable] = useState(false);
    const [classificationList, setClassificationList] = useState([]);
    const [classificationSaveDailog, setClassificationSaveDailog] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(0);
    const [classificationsProfiles, setClassificationProfiles] = useState([]);
    const { role } = useSelector(({ auth }) => auth.user);
    const customer = useSelector(({ auth }) => auth.customer);

    useEffect(() => {
        getClassificationProfiles();
        loadDefaultClassifications();
        //initializeClassification();
    }, [dispatch]);

    useEffect(() => {
        if (!isEditable) {
            if (selectedProfile > 0) {
                loadedClassificationObject(selectedProfile);
            } else {
                loadDefaultClassifications();
            }
        }
    }, [isEditable])

    useEffect(() => {
        if (classificationList.length > 0) {
            const updatedObject = convertClassificationListToObject(classificationList);
            updateClassifications(updatedObject);
        }
    }, [classificationList]);

    const loadedClassificationObject = (profileId) => {
        setSelectedProfile(profileId);
        const profileInfo = getClassificationProfileInfos(profileId);
        Promise.resolve(profileInfo)
            .then((response) => {
                if (response.status === 200) {
                    setClassificationList(response.data);
                }
            })
            .catch((error) => {
                console.warn("Cannot retrieve data", error);
            });
    }

    const loadDefaultClassifications = () => {
        const classifications = getUserDefaultClassificationSettings();
        Promise.resolve(classifications)
            .then((response) => {
                if (response.status === 200) {
                    var tempObject = response.data;
                    tempObject.forEach(function (classification) {
                        classification.isError = false;
                        classification.isDuplicateKey = false;
                    });
                    setClassificationList(tempObject);
                }
            })
            .catch((error) => {
                console.warn("Cannot retrieve data", error);
            });
    }

    const getClassificationProfiles = () => {
        var getClassifications = loadAllClassificationProfiles();
        Promise.resolve(getClassifications)
            .then((response) => {
                if (response.status === 200) {
                    setClassificationProfiles(response.data);
                }
            })
            .catch((error) => {
                console.warn("Cannot retrieve data", error);
            });
    }

    const initializeClassification = () => {
        const updatedObject = convertClassificationObjectToList(DEFAULT_CLASSIFICATIONS);
        setClassificationList(updatedObject);
    }

    const handleOnAddClick = () => {
        var _classificationObject = classificationList[0];
        _classificationObject.isError = false;
        if (_classificationObject.classificationName == "") {
            const updatedArray = [...classificationList];
            updatedArray[0].isError = true;
            setClassificationList(updatedArray);
            return;
        }
        const newObj = { ["isChecked"]: false, ["classificationName"]: "", ["colorCode"]: "", ["key"]: 0 };
        setClassificationList(prevArray => [newObj, ...prevArray]);
    }


    const onToggleClassificationVisibility = (index) => {
        try {
            const classification = classificationList[index];
            classification.isChecked = !classification.isChecked;
            const updatedArray = [...classificationList];
            updatedArray[index].isChecked = classification.isChecked;
            setClassificationList(updatedArray);
        } catch (error) {
            console.error(error);
        }
    }

    const handleOnChangeClassificationName = (e, index) => {
        try {
            const classification = classificationList[index];
            classification.classificationName = e.target.value;
            const updatedArray = [...classificationList];
            updatedArray[index].classificationName = classification.classificationName;
            setClassificationList(updatedArray);
        } catch (error) {
            console.error(error);
        }
    }

    function changeClassificationColor(color, index) {
        const updatedArray = [...classificationList];
        updatedArray[index].colorCode = color.css.backgroundColor;
        setClassificationList(updatedArray);
    }

    const handleOnChangeClassificationKey = (e, index) => {
        try {
            var key = e.target.value;
            const updatedArray = [...classificationList];
            if (key && key >= 0 && key <= 255) {
                updatedArray[index].key = parseInt(key, 10);
            }
            else if (!key) {
                updatedArray[index].key = 0;
            } else if (key > 255) {
                updatedArray[index].key = 255;
            }
            setClassificationList(updatedArray);
        } catch (error) {
            console.error(error);
        }

    }

    const [isUpdate, setIsUpdate] = useState(false);
    const [updateProfile, setUpdateProfile] = useState();

    const handleOnSaveOrUpdateClick = (isUpdate) => {

        if (isUpdate) {
            const profile = classificationsProfiles.find(x => x.id == selectedProfile);
            setUpdateProfile(profile);

        }
        setIsUpdate(isUpdate);
        var classifications = [...classificationList];
        var dupClassifications = [];
        classifications.forEach((classification, index) => {
            var dupObject = {
                key: classification.key,
                index: index
            };

            var tempList = dupClassifications.filter(x => x.key == classification.key);
            if (tempList.length > 0) {
                tempList.forEach((obj) => {
                    classifications[obj.index].isDuplicateKey = true;
                })
                classifications[index].isDuplicateKey = true;
            } else {
                classifications[index].isDuplicateKey = false;
            }
            dupClassifications.push(dupObject);
        })

        classifications.forEach((classification) => {
            if (classification.classificationName.trim() === '') {
                classification.isError = true;
            } else {
                classification.isError = false;
            }

        })

        setClassificationList(classifications);
        var duplicateCount = classificationList.filter(x => x.isDuplicateKey == true);
        var errorCount = classificationList.filter(x => x.isError == true);
        if (errorCount.length > 0 || duplicateCount.length > 0) {
            setClassificationSaveDailog(false);
        }
        else {
            setClassificationSaveDailog(true);
        }

    }

    const handleOnSaveClassification = (profileData) => {
        try {
            setClassificationSaveDailog(false);
            var classifications = [];
            classificationList.forEach((classification) => {

                var dataObj = {
                    isChecked: classification.isChecked,
                    classificationName: classification.classificationName,
                    colorCode: classification.colorCode,
                    key: classification.key == "DEFAULT" ? -1 : classification.key
                };
                classifications.push(dataObj);
            });
            var saveObj = {
                name: profileData.name,
                isShare: profileData.isShare,
                classifications: classifications

            };
            var saveClassificationData = saveSiteClassification(saveObj);
            Promise.resolve(saveClassificationData)
                .then((response) => {
                    loadedClassificationObject(response.data);
                    getClassificationProfiles();
                    swal("", "Saved Successfully", "success");
                })
        } catch (error) { }
    }

    const handleOnUpdateClassification = (profileData) => {
        try {
            setClassificationSaveDailog(false);
            setIsUpdate(false);
            var classifications = [];
            classificationList.forEach((classification) => {

                var dataObj = {
                    isChecked: classification.isChecked,
                    classificationName: classification.classificationName,
                    colorCode: classification.colorCode,
                    key: classification.key == "DEFAULT" ? -1 : classification.key
                };
                classifications.push(dataObj);
            });
            var saveObj = {
                id: updateProfile.id,
                name: profileData.name,
                isShare: profileData.isShare,
                classifications: classifications

            };
            var saveClassificationData = updateSiteClassification(saveObj);
            Promise.resolve(saveClassificationData)
                .then((response) => {
                    if (response.status === 200) {
                        loadedClassificationObject(updateProfile.id);
                        getClassificationProfiles();
                        swal("", "Updated Successfully", "success");
                    }

                })
        } catch (error) { }
    }

    const onDeleteProfileSuccess = () => {
        setSelectedProfile(0);
        // initializeClassification();
        loadDefaultClassifications();
        getClassificationProfiles();
    }

    const handleOnDeleteClick = () => {
        try {
            var selectedProfileId = selectedProfile;
            swal({
                title: "Are you sure?",
                text: "Do you want to delete the Profile?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        var requestData = {
                            profileId: selectedProfileId,
                            successCallback: onDeleteProfileSuccess,
                        }
                        dispatch(removeProfile(requestData));
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    const handleOnClose = () => {
        setClassificationSaveDailog(false);
        setIsUpdate(false);
    }


    const handleOnClickRemoveClassification = (index) => {
        try {
            setClassificationList(current => current.filter((el, i) => i !== index));
        } catch (e) {
            console.error(e);
        }
    }


    const handleOnUpArrowClick = (index) => {
        const updatedArray = [...classificationList];
        if (updatedArray[index].key >= 0) {
            var key = parseInt(updatedArray[index].key);
            key = key + 1;
            if (key > 255) {
                key = 255;
            }
            updatedArray[index].key = key;
        }
        else {
            updatedArray[index].key = 0;
        }

        setClassificationList(updatedArray);

    }

    const handleOnDownArrowClick = (index) => {
        const updatedArray = [...classificationList];
        if (updatedArray[index].key >= 0) {
            var key = parseInt(updatedArray[index].key);
            key = key - 1;
            if (key < 0) {
                key = 0;
            }
            updatedArray[index].key = key;
        }
        else {
            updatedArray[index].key = 0;
        }

        setClassificationList(updatedArray);

    }

    const handleOnDropDownChange = (e) => {
        var profileId = e.target.value;
        if (profileId > 0) {
            setSelectedProfile(profileId);
        } else {
            setSelectedProfile(profileId);
            loadDefaultClassifications();
            // initializeClassification();
        }

    }

    const isDropDownDisabled = (profile) => {
        if (isEditable) {
            if (profile.userId != customer.id) {
                return true;
            }
        }
        return false;
    }

    const hasEditAccess = (profileId) => {
        if (profileId > 0) {
            const profile = classificationsProfiles.find(x => x.id == profileId);
            if (profile) {
                if (profile.userId != customer.id) {
                    return false;
                }
            }
        }
        return true;
    }

    return (
        <OuterPanel className='w-full'>
            <ListItem disablePadding sx={{ flexGrow: 1 }}>
                {role.includes("admin") &&
                    <ListItem className='px-8 ml-4'>
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel id="demo-simple-select-standard-label">Profiles</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedProfile}
                                name="profile"
                                onChange={(e) => {
                                    handleOnDropDownChange(e)
                                }}
                                className='form-control'
                                label="Profiles"
                                renderValue={(profileId) => {
                                    if (profileId == 0) {
                                        return "Select";
                                    }
                                    const selectedProfile = classificationsProfiles.find(profile => profile.id == profileId);
                                    return selectedProfile ? selectedProfile.name : '';
                                }}
                            >
                                <MenuItem key={0} value={0}>
                                    Select
                                </MenuItem>
                                {classificationsProfiles.map((profile) =>
                                    <MenuItem key={profile.id}
                                        value={profile.id}
                                        onClick={() => { loadedClassificationObject(profile.id) }}
                                        disabled={isDropDownDisabled(profile)}
                                    >{profile.name}
                                        {profile.userId != customer.id && <Typography style={{ color: '#7f7f7f' }}>(Shared)</Typography>}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </ListItem>
                }
                <Section className='p-0'>
                    {isEditable ?
                        <>
                            {selectedProfile > 0 &&
                                <Tooltip title='Update'>
                                    <IconButton className="px-2" style={{ color: '#E67260' }}
                                        onClick={() => {
                                            handleOnSaveOrUpdateClick(true)
                                        }}
                                    ><UpdateIcon /></IconButton>
                                </Tooltip>
                            }
                            <Tooltip title='Save'>
                                <IconButton className="px-2" style={{ color: '#6CCCD7' }}
                                    onClick={() => {
                                        handleOnSaveOrUpdateClick(false)
                                    }}
                                ><SaveOutlinedIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title='Add'>
                                <IconButton className="px-2" style={{ color: '#6CCCD7' }} onClick={() => { handleOnAddClick() }}><AddIcon /></IconButton>
                            </Tooltip>
                            {selectedProfile > 0 &&
                                <Tooltip title='Delete'>
                                    <IconButton className="px-2" color="error"
                                        onClick={() => { handleOnDeleteClick() }}
                                    ><DeleteIcon /></IconButton>
                                </Tooltip>
                            }
                            <Tooltip title='Cancel'>
                                <IconButton className="px-2" color="error"
                                    onClick={() => { setIsEditable(false) }}
                                ><CancelOutlinedIcon /></IconButton>
                            </Tooltip>
                        </>
                        :
                        <>
                            {role.includes("admin") &&
                                <>
                                    {hasEditAccess(selectedProfile) &&
                                        <>
                                            {selectedProfile > 0 &&
                                                <Tooltip title='Delete'>
                                                    <IconButton className="p-4" color="error"
                                                        onClick={() => { handleOnDeleteClick() }}
                                                    ><DeleteIcon /></IconButton>
                                                </Tooltip>
                                            }

                                            <Tooltip title='Edit'>
                                                <IconButton style={{ color: '#6CCCD7' }}
                                                    onClick={() => { setIsEditable(true) }}
                                                ><EditIcon /></IconButton>
                                            </Tooltip>
                                        </>
                                    }


                                </>
                            }
                        </>
                    }
                </Section>
            </ListItem>
            {role.includes("admin") ?
                <>
                    {isEditable ?
                        <SiteClassificationViewEdit
                            classificationList={classificationList}
                            onToggleClassificationVisibility={onToggleClassificationVisibility}
                            handleOnChangeClassificationName={handleOnChangeClassificationName}
                            changeClassificationColor={changeClassificationColor}
                            handleOnClickRemoveClassification={handleOnClickRemoveClassification}
                            profileId={selectedProfile}
                            handleOnChangeClassificationKey={handleOnChangeClassificationKey}
                            handleOnUpArrowClick={handleOnUpArrowClick}
                            handleOnDownArrowClick={handleOnDownArrowClick}
                        /> :
                        <SiteClassificationView
                            classificationList={classificationList}
                            onColorChange={changeClassificationColor}
                            onToggleClassificationVisibility={onToggleClassificationVisibility} />
                    }

                </> :
                <SiteClassificationView
                    classificationList={classificationList}
                    onColorChange={changeClassificationColor}
                    onToggleClassificationVisibility={onToggleClassificationVisibility} />
            }
            {classificationSaveDailog &&
                <SiteClassificationSave
                    handleOnClose={handleOnClose}
                    handleOnClickSave={handleOnSaveClassification}
                    handleOnUpdateClick={handleOnUpdateClassification}
                    updateProfile={updateProfile}
                    isUpdate={isUpdate}
                />
            }

        </OuterPanel>
    )
}

export default SiteClassifications;