import React, { useState } from "react";
import SiteClassifications from "../potree/SiteClassifications";
import DemoUserSiteClassifications from "../../demoUserDashboards/site/demoUserPotree/DemoUserSiteClassifications";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import styled from "@emotion/styled";
import {
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useSelector } from "react-redux";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;
const Reset = styled.div`
  padding-left: 10px;
`;

function Classification(props) {
  const {
    activeAttribute,
    classifications,
    selectedSite,
    onChangeClassificationColor,
    onActiveAttribute,
    onToggleClassificationVisibility,
    updateClassifications,
  } = props;

  const { role } = useSelector(({ auth }) => auth.user);

  const [classificationMinimize, setClassificationMinimize] = useState(false);

  const handleOnChangeAccordion = () => {
    setClassificationMinimize(!classificationMinimize);
    if (classificationMinimize) {
      MixPanelIntegration.EventClicks("Classification Close Click");
    } else {
      MixPanelIntegration.EventClicks("Classification Expand Click");
    }
  };

  return (
    <Accordion
      expanded={classificationMinimize}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          classificationMinimize ? (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleOnChangeAccordion();
              }}
            >
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel7bh-content"
        id="panel7"
      >
        <TypographyStyles>Classification (Beta)</TypographyStyles>
        {classificationMinimize && (
          <Reset style={{ marginRight: "0px" }}>
            <ToggleButtonGroup
              size="small"
              value={activeAttribute}
              exclusive
              onChange={onActiveAttribute}
              aria-label="active attribute"
              style={{ padding: "4px 2px" }}
            >
              <ToggleButton
                value="rgba"
                disabled={activeAttribute === "rgba"}
                aria-label="rgba"
              >
                RGB
              </ToggleButton>
              <ToggleButton
                value="classification"
                disabled={activeAttribute === "classification"}
                aria-label="classification"
              >
                Classes
              </ToggleButton>
            </ToggleButtonGroup>
          </Reset>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <div>
          <div>
            {activeAttribute === "classification" && (
              <>
                {role.includes("demouser") ? (
                  <DemoUserSiteClassifications
                    updateClassifications={(classification) =>
                      updateClassifications(classification)
                    }
                  />
                ) : (
                  <SiteClassifications
                    classifications={classifications}
                    onColorChange={(color, index) =>
                      onChangeClassificationColor(color, index)
                    }
                    onToggleVisiblity={(index) =>
                      onToggleClassificationVisibility(index)
                    }
                    selectedSite={selectedSite}
                    updateClassifications={(classification) =>
                      updateClassifications(classification)
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default Classification;

