import { useState, useEffect, useRef } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomAlert from "app/main/apps/common/CustomAlert";
import AlignToControlStepperDiff from "./AlignToControlStepperDiff";
import StepperReset from "./StepperReset";
import PointCloudViewSelection from "../../../tools/PointCloudViewSelection";
import AlignToControlCompareCoordinates from "../../coordinate-viewer/AlignToControlCompareCoordinates";
import { FaCircleExclamation } from "react-icons/fa6";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  MobileStepper,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import StepperStepButtons from "./StepperStepButtons";

const AlignToControlStepper = (props) => {
  const {
    selectedCoordinates,
    setSelectedCoordinates,
    drawerWidth,
    leftDrawerOpen,
    handleNextControlPointClick,
    handlePreviousControlPointClick,
    handleResetControlPointClick,
    currentControlPointIndex,
    handleOnAlignToControlClick,
    toggleDrawers,
    wizardActive,
    handleStartModelSelectionWizardAction,
    setWizardActive,
    isOrthographicCamera,
    handleClearModelWizardAction,
    handleOnClearModelCoordinateWizard,
    updateSpheres,
    zoomDisabled,
    setZoomDisabled,
    // zoomToPoints,
    zoomOutPointCloud,
    onChangeCameraProjection,
    // setCurrentControlPointIndex,
    zoomToCoordinate,
  } = props;

  const [activeStep, setActiveStep] = useState();
  const [stepperStarted, setStepperStarted] = useState(false);
  const initialStateRef = useRef(selectedCoordinates);
  // const [modelCoordinateSet, setModelCoordinateSet] = useState(false);

  useEffect(() => {
    console.log(initialStateRef.current);

    initialStateRef.current = selectedCoordinates;
  }, []);

  const handleNext = () => {
    handleNextControlPointClick();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStart = () => {
    setStepperStarted(true);
    console.log("currentControlPointIndex", currentControlPointIndex);
    // setCurrentControlPointIndex(0);
    setActiveStep(0);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    zoomToCoordinate();
  };

  // const stepperPagination = () => {
  //   if (selectedCoordinates.length > ) {
  // return (
  //
  // };

  const handleBack = () => {
    handlePreviousControlPointClick();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) =>
    !step?.model?.x || !step?.model?.y || !step?.model?.z;

  const handleReset = () => {
    CustomAlert.show({
      type: "deleteconfirmation",
      message: "Do you want to reset all the coordinates?",
      buttons: [],
    }).then((res) => {
      if (res == "yes") {
        setActiveStep(0);
        setStepperStarted(false);
        for (let i = 0; i < selectedCoordinates.length; i++) {
          handleOnClearModelCoordinateWizard(i);
        }
        handleResetControlPointClick();
        setSelectedCoordinates(initialStateRef.current);
      }
    });
    updateSpheres();
    // zoomToPoints();
    zoomOutPointCloud();
  };

  const handleExit = () => {
    CustomAlert.show({
      type: "deleteconfirmation",
      message: "Do you want to reset all the coordinates?",
      buttons: [],
    }).then((res) => {
      if (res == "yes") {
        toggleDrawers(true);
        setWizardActive(!wizardActive);
        setActiveStep(0);
        setStepperStarted(false);
        handleResetControlPointClick();
        setSelectedCoordinates(initialStateRef.current);
      }
    });
    updateSpheres();
    // zoomToPoints();
    zoomOutPointCloud();
  };

  const stepsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(selectedCoordinates.length / stepsPerPage);

  const handleNextPage = () => {
    console.log("currentControlPointIndex", currentControlPointIndex);

    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
      setActiveStep(0);
    }
    updateSpheres();
  };

  const handleBackPage = () => {
    console.log("currentControlPointIndex", currentControlPointIndex);

    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
      setActiveStep(stepsPerPage - 1);
    }
    updateSpheres();
  };

  const stepsToShow = selectedCoordinates.slice(
    currentPage * stepsPerPage,
    (currentPage + 1) * stepsPerPage
  );

  return (
    <Box
      sx={{
        position: "fixed",
        maxWidth: 400,
        top: 175,
        left: leftDrawerOpen ? `${drawerWidth + 32}px` : "32px",
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          bgcolor: "rgb(255, 255, 255, 0.75)",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          padding: "8px 16px",
        }}
      >
        <Typography
          variant="h5"
          sx={{ position: "relative", marginBottom: "12px", fontWeight: 600 }}
        >
          Align To Control
        </Typography>

        {stepperStarted && (
          <CancelIcon
            style={{ color: "#565656" }}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleExit}
          />
        )}

        {!stepperStarted && (
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: "#4e4e50", mb: "18px" }}>
              Align control points to model coordinates
            </Typography>
            <StepperStepButtons
              handleBack={handleExit}
              handleNext={handleStart}
              nextButtonText="Start"
              backButtonText="Exit"
            />
          </Box>
        )}
      </Box>

      {stepperStarted && (
        <>
          <Stepper
            sx={{
              bgcolor: "rgb(255, 255, 255, 0.75)",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
              padding: "8px 16px",
            }}
            activeStep={activeStep}
            orientation="vertical"
          >
            {selectedCoordinates &&
              // eslint-disable-next-line complexity
              stepsToShow.map((step, index) => {
                const labelProps = {};
                const isSkipped = isStepSkipped(step);
                const currentIndex = currentPage * stepsPerPage + index;

                if (isSkipped && currentControlPointIndex > currentIndex) {
                  labelProps.optional = (
                    <Typography variant="caption" style={{ color: "#565656" }}>
                      Missing Model Coordinates
                    </Typography>
                  );
                  labelProps.alternativeLabel = true;
                  labelProps.error = true;
                  labelProps.completed = false;
                  labelProps.icon = <ErrorOutlineIcon />;
                }

                const nextButtonText = isSkipped ? "Skip" : "Continue";

                return (
                  <Step
                    key={`${step.id}-${currentIndex}`}
                    sx={{
                      "& .Mui-error": {
                        color: "#565656",
                      },
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#59bec9",
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "#A1A1A1",
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "primary",
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "common.white",
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white",
                      },
                    }}
                  >
                    <StepLabel
                      {...labelProps}
                      optional={
                        currentIndex === selectedCoordinates?.length - 1 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : (
                          labelProps.optional
                        )
                      }
                    >
                      {labelProps.alternativeLabel ? (
                        <span
                          style={{ fontWeight: "bolder", color: "#565656" }}
                        >
                          {step?.name}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "bolder" }}>
                          {step?.name}
                        </span>
                      )}
                    </StepLabel>

                    <StepContent>
                      <Typography>{step?.code}</Typography>
                      <Card sx={{ mt: 2, width: "270px" }}>
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "16px",
                            }}
                          >
                            <Typography display="inline" variant="h6">
                              Coordinate
                            </Typography>
                            <Typography display="inline" variant="h6">
                              Offset
                            </Typography>
                          </div>
                          <Divider sx={{ mb: 2 }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "26px",
                              marginTop: "8px",
                            }}
                          >
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ mb: 0.5, display: "flex" }}
                              >
                                <strong
                                  style={{
                                    paddingRight:
                                      step.control?.x < 0
                                        ? "0.675rem"
                                        : "1.2rem",
                                  }}
                                >
                                  X:{" "}
                                </strong>{" "}
                                {!step.control?.x ? (
                                  <FaCircleExclamation color="#59bec9" />
                                ) : (
                                  step.control.x.toFixed(3)
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ mb: 0.5, display: "flex" }}
                              >
                                <strong
                                  style={{
                                    paddingRight:
                                      step.control?.y < 0
                                        ? "0.675rem"
                                        : "1.2rem",
                                  }}
                                >
                                  Y:{" "}
                                </strong>{" "}
                                {!step.control?.y ? (
                                  <FaCircleExclamation color="#59bec9" />
                                ) : (
                                  step.control.y.toFixed(3)
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ display: "flex" }}
                              >
                                <strong
                                  style={{
                                    paddingRight:
                                      step.control?.z < 0
                                        ? "0.675rem"
                                        : "1.2rem",
                                  }}
                                >
                                  Z:{" "}
                                </strong>{" "}
                                {!step.control?.z ? (
                                  <FaCircleExclamation color="#59bec9" />
                                ) : (
                                  step.control.z.toFixed(3)
                                )}
                              </Typography>
                            </div>

                            <AlignToControlStepperDiff step={step} />
                          </div>
                        </CardContent>{" "}
                      </Card>{" "}
                      <div className="flex">
                        <StepperStepButtons
                          handleNext={handleNext}
                          handleBack={currentIndex === 0 ? "" : handleBack}
                          nextButtonText={
                            currentIndex === selectedCoordinates.length - 1
                              ? "Finish"
                              : nextButtonText
                          }
                          backButtonText="Back"
                        />
                        <AlignToControlCompareCoordinates
                          currentControlPointIndex={currentControlPointIndex}
                          reset={!isSkipped}
                          stepName={step.name}
                          buttonFunction={
                            isSkipped
                              ? handleStartModelSelectionWizardAction
                              : handleClearModelWizardAction
                          }
                        />
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
              <Checkbox
                checked={zoomDisabled}
                onChange={() => setZoomDisabled(!zoomDisabled)}
                sx={{
                  color: "#59bec9",
                  "&.Mui-checked": {
                    color: "#59bec9",
                  },
                }}
              />
              <Typography style={{ fontWeight: 500 }}>
                Disable zoom to point
              </Typography>
            </Box>
            <div className="flex mt-6">
              <PointCloudViewSelection
                isOrthographicCamera={isOrthographicCamera}
                onChangeCameraProjection={onChangeCameraProjection}
              />
              <Tooltip title="Zoom to point cloud">
                <IconButton
                  disabled={false}
                  onClick={() => {
                    // zoomToPoints();
                    zoomOutPointCloud();
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
              </Tooltip>
            </div>

            <MobileStepper
              variant="dots"
              steps={totalPages}
              position="static"
              activeStep={currentPage}
              sx={{
                "& .MuiMobileStepper-dotActive": {
                  backgroundColor: "#59bec9",
                },
                maxWidth: 400,
                mt: 2,
                borderRadius: "16px",
              }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages - 1}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBackPage}
                  disabled={currentPage === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </Stepper>
        </>
      )}
      {activeStep + currentPage * stepsPerPage ===
        selectedCoordinates?.length &&
        selectedCoordinates?.length > 0 && (
          <StepperReset
            handleReset={handleReset}
            handleOnAlignToControlClick={handleOnAlignToControlClick}
          />
        )}
    </Box>
  );
};

export default AlignToControlStepper;
