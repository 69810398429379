import React, { useState } from 'react';
import styled from "@emotion/styled";
import {
    GpsFixed, Height, ChangeHistory
} from '@mui/icons-material';
import { Tooltip, IconButton, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ToggleSwitch from 'app/shared-components/toggleSwitch/ToggleSwitch';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MixPanelIntegration from 'app/mixpanel/MixPanelIntegration';

const Section = styled.div`
  padding-left: 20px;
  display: flex;
  padding-bottom: 10px;
`;
const Item = styled.div`
  padding: 5px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin: 5px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#ffffff' : '#565656'};
  color: ${props => props.selected ? '#565656' : '#ffffff'};
`;

const TypographyStyles = styled.div`
	font-style: normal;
	font-weight: 600;	
	color: #565656;
`;
const ResetIcon = styled.div`
    padding: 1px;
    border: 2px solid #565656;
    border-radius: 20px;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    color: ${props => props.selected ? '#ffffff' : '#565656'};
    `;

const Reset = styled.div`
  padding-left: 10px;  
`;

function Measurement(props) {

    const { reset, selectMeasurement, coordinateSystem, feetLengthSwitch, onToggleFeetLength, measurement, currentViewer } = props;

    const [measurementMinimize, setMeasurementMinimize] = useState(false);

    const handleOnChangeAccordion = () => {
        setMeasurementMinimize(!measurementMinimize);
        if (measurementMinimize) {
            MixPanelIntegration.EventClicks("Measurement Close Click");
        } else {
            MixPanelIntegration.EventClicks("Measurement Expand Click");
        }
    }

    return (
        <Accordion expanded={measurementMinimize} sx={{
            '&.MuiAccordion-root.Mui-expanded': {
                margin: '1px 0 0px 0px',
            },
            '&:last-of-type': {
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
            }
        }}>
            <AccordionSummary sx={{
                '& .MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '0px',
                    display: 'flex',
                    alignItems: 'center',
                }
            }}
                expandIcon={measurementMinimize ? <IconButton onClick={() => { handleOnChangeAccordion() }}>
                    <IndeterminateCheckBoxOutlinedIcon style={{ color: '#BBBBBB' }} /></IconButton> :
                    <IconButton onClick={() => { handleOnChangeAccordion() }}><AddBoxOutlinedIcon style={{ color: '#BBBBBB' }} /></IconButton>}
                aria-controls="panel4bh-content"
                id="panel4"
                className='pt-4'
            >
                <TypographyStyles>
                    Measurement
                </TypographyStyles>
                {measurementMinimize &&
                    <Reset style={{ marginRight: '0px' }}>
                        <Tooltip title='Reset'>
                            <ResetIcon onClick={reset} id="measurement"><RestartAltIcon /></ResetIcon>
                        </Tooltip>
                    </Reset>
                }
            </AccordionSummary>
            <AccordionDetails sx={{
                '& .MuiAccordionDetails-root': {
                    padding: '8px 10px 16px',
                }
            }}>
                <div>
                    <Section style={{ paddingLeft: '0px' }}>
                        <Tooltip title="Point">
                            <Item onClick={selectMeasurement} selected={measurement === 'Point'} id="Point"><GpsFixed /></Item>
                        </Tooltip>
                        <Tooltip title="Distance">
                            <Item onClick={selectMeasurement} selected={measurement === 'Distance'} id="Distance"><Height style={{ transform: 'rotate(90deg)' }} /></Item>
                        </Tooltip>
                        {currentViewer == '3D' &&
                            <Tooltip title="Height">
                                <Item onClick={selectMeasurement} selected={measurement === 'Height'} id="Height"><Height /></Item>
                            </Tooltip>
                        }
                        <Tooltip title="Angle">
                            <Item onClick={selectMeasurement} selected={measurement === 'Angle'} id="Angle"><ChangeHistory /></Item>
                        </Tooltip>
                        <Tooltip title="Area">
                            <Item onClick={selectMeasurement} selected={measurement === 'Area'} id="Area"><RectangleOutlinedIcon /></Item>
                        </Tooltip>
                    </Section>
                    {currentViewer == '3D' ?
                        <>
                            {coordinateSystem == 'ft' ?
                                <Section style={{ paddingLeft: '15px' }}>
                                    <ToggleSwitch
                                        checked={feetLengthSwitch}
                                        onToggleChange={onToggleFeetLength}
                                        yes={'Feet'}
                                        no={'Meters'}
                                    ></ToggleSwitch>
                                </Section>
                                :
                                <Section style={{ paddingLeft: '10px' }}>
                                    <ToggleSwitch
                                        checked={!feetLengthSwitch}
                                        onToggleChange={onToggleFeetLength}
                                        yes={'Meters'}
                                        no={'Feet'}
                                    ></ToggleSwitch>
                                </Section>
                            }
                        </>
                        :
                        <Section>
                            <ToggleSwitch
                                checked={!feetLengthSwitch}
                                onToggleChange={onToggleFeetLength}
                                yes={'Meters'}
                                no={'Feet'}
                            ></ToggleSwitch>
                        </Section>

                    }

                </div>
            </AccordionDetails>
        </Accordion >

    )
}

export default Measurement;