const settingsConfig = {
  layout: {
    style: 'layout3', // layout1 layout2 layout3
    config: {
      mode : 'fullWidth'
    }, // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: 'ltr', // rtl, ltr
  theme: {
    main: 'legacy',
    navbar: 'legacy',
    toolbar: 'legacy',
    footer: 'legacy',
  },
  loginRedirectUrl: '/', // Default redirect url for the logged-in user
};

export default settingsConfig;
