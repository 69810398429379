import {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PotreeViewer from "./potree/PotreeViewer";
import swal from "@sweetalert/with-react";
import MapBoxViewer from "./mapboxviews/MapBoxViewer";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const CommonViewer = forwardRef((props, ref) => {
  const {
    selectedSite,
    toggleLeftDrawer,
    leftDrawerOpen,
    reloadSiteData,
    scanId,
    resetScanId,
  } = props;
  const potreeViewerRef = useRef();
  const mapboxViewerRef = useRef();
  useImperativeHandle(ref, () => ({
    getIsCoordinateSave() {
      if (potreeViewerRef.current != undefined) {
        return potreeViewerRef.current.getIsCoordinateSave();
      } else {
        return false;
      }
    },
    updateCoordinates() {
      if (potreeViewerRef.current != undefined) {
        return potreeViewerRef.current.updateCoordinates();
      } else {
        return true;
      }
    },
  }));

  //'https://app.looq.ai'
  const [currentViewer, setCurrentViewer] = useState("2D");
  const [pointCloudData, setPointCloudData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentViewer]);

  const onToggleCurrentViewer = () => {
    if (currentViewer == "2D") {
      MixPanelIntegration.EventClicks("3D Click");
      changeViewer();
    } else {
      MixPanelIntegration.EventClicks("2D Click");
      if (potreeViewerRef.current != undefined) {
        if (potreeViewerRef.current.getIsCoordinateSave() == false) {
          changeViewer();
        } else {
          swal({
            title: "",
            text: "Do you want to save your changes to the Coordinate Tools before closing?",
            icon: "warning",
            buttons: {
              Yes: {
                text: "Yes",
                value: "Yes",
              },
              No: {
                text: "No",
                value: "No",
              },
            },
          }).then((value) => {
            switch (value) {
              case "Yes":
                if (potreeViewerRef.current != undefined) {
                  if (potreeViewerRef.current.updateCoordinates() == true) {
                    changeViewer();
                  }
                } else {
                  changeViewer();
                }
                break;
              case "No":
                changeViewer();
                break;
            }
          });
        }
      } else {
        changeViewer();
      }
    }
  };

  const changeViewer = () => {
    if (currentViewer == "2D") {
      if (mapboxViewerRef.current != undefined) {
        let data = mapboxViewerRef.current.getMeasurementsData();
        if (data.scans.length == 0) {
          setPointCloudData(null);
        } else {
          setPointCloudData(data);
        }
      }
      setCurrentViewer("3D");
    } else {
      if (potreeViewerRef.current != undefined) {
        let data = potreeViewerRef.current.getMeasurementsData();
        if (data.scans.length == 0) {
          setPointCloudData(null);
        } else {
          setPointCloudData(data);
        }
      }
      // setPointCloudData(null)
      setCurrentViewer("2D");
    }
  };

  const updateMeasurementData = (data) => {
    setPointCloudData(data);
  };
  return (
    <>
      {currentViewer == "2D" ? (
        <MapBoxViewer
          ref={mapboxViewerRef}
          currentViewer={currentViewer}
          selectedSite={selectedSite}
          toggleLeftDrawer={toggleLeftDrawer}
          onToggleCurrentViewer={onToggleCurrentViewer}
          measurementData={pointCloudData}
          updateMeasurementData={updateMeasurementData}
          scanId={scanId}
          resetScanId={resetScanId}
        />
      ) : (
        <PotreeViewer
          ref={potreeViewerRef}
          currentViewer={currentViewer}
          selectedSite={selectedSite}
          toggleLeftDrawer={toggleLeftDrawer}
          letfDrawerOpen={leftDrawerOpen}
          onToggleCurrentViewer={onToggleCurrentViewer}
          measurementData={pointCloudData}
          updateMeasurementData={updateMeasurementData}
          reloadSiteData={reloadSiteData}
        />
      )}
    </>
  );
});

export default CommonViewer;
