import {
    Dialog,
    DialogContent,
    Button
} from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from '@emotion/styled';

const TitlePanel = styled.div`
  display: flex;
`;
const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;
const CloseButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	cursor: pointer;
  }
`;
function AddScanDialog(props) {
    const { handleOnCreateSiteClick, handleOnCreateScanClick, handleClose } = props;
    return (
        <Dialog
            open={true}
            onClose={handleClose}
        >
            <div className='m-8'>
                <TitlePanel>
                    <div></div>
                    <Spacer space={5} />
                    <CloseButton onClick={() => handleClose()}><Close fontSize="inherit" /></CloseButton>
                </TitlePanel>
                <DialogContent className="flex flex-col items-center justify-center w-full py-0 mb-28">
                    <Button
                        color="primary"
                        variant='contained'
                        className="mx-auto mt-8 py-8"
                        sx={{ width: 200 }}
                        onClick={() => handleOnCreateSiteClick()}
                    >Create Site</Button>

                    <Button
                        color="primary"
                        variant='contained'
                        className="mx-auto mt-16 py-8"
                        sx={{ width: 200 }}
                        onClick={() => handleOnCreateScanClick()}
                    >Create Scan</Button>

                </DialogContent>
            </div>
        </Dialog>
    )
}

export default AddScanDialog;