import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";
import swal from "sweetalert";

export const downloadScanLazFile = (scanId) => async (dispatch, getState) => {

    const url = APP_CONFIG.api + `v1/files/getDownloadUrlScanLazFile/${scanId}`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            if(response.data){
                const a = document.createElement('a');
                a.download = "test.bin";
                a.href = response.data;
                a.style.display = 'none';
                document.body.append(a);
                a.click();
                a.remove();
            }
            else
            {
                swal("","File does not exist","error");
            }
            
        }
    }).catch((error) => {
        swal("","Failed to download","error");
    });
};


