import { Tooltip, IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const AlignToControlImport = (props) => {
  const { importCoordinates } = props;

  return (
    <Tooltip title="Import">
      <IconButton
        style={{
          borderRadius: "8px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "5px 16px",
          backgroundColor: "rgb(108, 204, 215)",
          color: "rgb(255, 255, 255)",
          zIndex: 1000,
          display: "flex",
          gap: "8px",
          alignItems: "center",
        }}
        onClick={() => {
          importCoordinates();
        }}
      >
        Import
        <FileUploadOutlinedIcon sx={{ pl: 0.5 }} />
      </IconButton>
    </Tooltip>
  );
};

export default AlignToControlImport;
