import { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import axios from "axios";

function AvatarImage(props) {
  const [ImageObj, setImageObj] = useState(null);
  const { src, sx, className, alt } = props;

  useEffect(() => {
    let isMounted = true;
    loadImage(src)
      .then((response) => {
        if (isMounted) {
          setImageObj(response.data);
        }
      })
      .catch((error) => console.error(error));
    return () => {
      isMounted = false;
    };
  }, [src]);

  function loadImage(src) {
    const response = axios.get(src, {
      headers: {},
      responseType: "blob",
    });

    return Promise.resolve(response);
  }
  return (
    <Avatar
      src={ImageObj == null ? "" : URL.createObjectURL(ImageObj)}
      sx={sx}
      className={className}
      alt={alt}
    />
  );
}

export default AvatarImage;
