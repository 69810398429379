import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Grid,
    TextField,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Button,
    FormLabel,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import { sendSnap } from '../store/snapSlice';
import { getAllUserEmails } from '../../admin/store/customerSlice';


export default function SendSnapMail(props) {
    const dispatch = useDispatch();


    const [selectedEmails, setSelectedEmails] = useState([]);
    const [otherEmails, setOtherEmails] = useState('');
    const { userEmails } = useSelector(({ admin }) => admin.customerApp);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setSelectedEmails([]);
        setOtherEmails('');

    }, []);
    useEffect(() => {
		
		dispatch(getAllUserEmails(props.siteId))

	}, []);

    const handleOnChange = (e) => {
        setSelectedEmails(e.target.value)
    }
    const handleOnOtherChange = (e) => {
        setOtherEmails(e.target.value)
    }

    const sendScanSnap = () => {
        if (selectedEmails.length > 0 || otherEmails) {
            setIsValid(true)
            const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            var emails = selectedEmails;
            var otherUserEmails = otherEmails.split(',');
            otherUserEmails = otherUserEmails.filter(x => re.test(x));
            var allEmails = emails.concat(otherUserEmails);
            let uniqueChars = [...new Set(allEmails)];
            if (uniqueChars.length > 0) {
                dispatch(sendSnap(props.scanName, uniqueChars.join(','), b64toBlob(props.src)))
            }
            props.handleClose()
        }
        else {
            setIsValid(false);
        }


    }


    function b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab]);
    }
    return (
        <div>

            <Dialog
                open={props.showConfirmation}
                onClose={props.handleClose}
                fullWidth={'md'}
                maxWidth={'md'}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            //  sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '70%' } }}
            >
                <DialogContent dividers={'paper' === 'paper'}>

                    <Box>
                        <Grid container className='items-center justify-center'>
                            <div className='flex flex-col items-center justify-center'>
                                <Typography variant="h6" className="mt-0 font-semibold text-18 sm:text-24">
                                    Send Mail
                                </Typography>
                                {isValid ? '' : <p className="text-red text-center">Please select or enter email.</p>}
                            </div>

                            <div className='relative flex flex-row items-center w-full'>
                                <div className='flex flex-col w-full'>
                                    <div className='mr-32'>
                                        <FormControl className='mx-0' sx={{ m: 1 ,width : 400}}>
                                            <InputLabel id="demo-multiple-checkbox-label">Emails</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={selectedEmails}
                                                name="Email"
                                                onChange={handleOnChange}
                                                label='Limar Emails'
                                                input={<OutlinedInput label="Select Sites" />}
                                                renderValue={(emails) => emails.join(', ')}
                                            >
                                                {userEmails.map((email) => (
                                                    <MenuItem key={email} value={email}>
                                                        <Checkbox checked={selectedEmails.includes(email)} />
                                                        <ListItemText primary={email} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full mt-24'>
                                    <div className='ml-32'>
                                        <FormControl className='mx-0 w-full'>
                                            <TextField
                                                id="outlined-basic"
                                                label="Other Emails"
                                                name='name'
                                                variant="outlined"
                                                fullWidth
                                                type='text'
                                                value={otherEmails}
                                                multiple
                                                onChange={(e) => handleOnOtherChange(e)}
                                            />
                                            <FormLabel id="demo-row-radio-buttons-group-label" className='mb-8 text-red text-10'>E.g: limarai@outlook.com,limarai@outlook.live</FormLabel>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='relative flex flex-row items-center w-full'>
                            <img className='mx-auto' src={props.src} alt={'Screenshot'} width="50%"/>
                            </div>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        className="w-full mx-auto mt-16 py-8"
                        aria-label="Cancel"
                        type="button"
                        onClick={() => props.handleClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className="w-full mx-auto mt-16 py-8 ml-8"
                        aria-label="Send"
                        type="button"
                        onClick={() => sendScanSnap()}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}