import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import SiteDetails from "./SiteDetails";
import ScanDetails from "./ScanDetails";
import SiteUserDetails from "./SiteUserDetails";
import { setEditData } from "../admin/editStore/editSlice";
import { getSiteDetails } from "./store/sitesSlice";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  setSiteActiveViewerTab,
  setShowSiteViewerActions,
  setShowSiteViewerAddScanButton,
  setSiteViewerAddScanAction,
  resetSiteViewer,
} from "app/auth/store/customNavigationSlice";
import CommonViewer from "./CommonViewer";
import swal from "sweetalert";
import { checkFilesExistInFolder, downloadScanFile } from "./store/scansSlice";
import UPLOAD_TYPE from "../upload/UploadType";

const MainHeader = styled.div`
  // color: #FFFFFF;
  // background-color: #6CCCD7;
  // font-size: 16px;
  // font-weight: bold;
  // padding: 15px;
  // width: ${(props) => (props.open ? "calc(100% - 400px)" : "100%")};
  // position: fixed;
  // height: 50px;
  // z-index: 101;
  // margin-left: ${(props) => (props.open ? "400px" : "0px")};
  // text-align: center;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space:nowrap

  color: #ffffff;
  background-color: #6cccd7;
  font-size: 15px;
  font-weight: 600;
  position: fixed;
  z-index: 101;
  right: 86px;
  top: 90px;
  padding: 10px 20px;
  border-radius: 15px;
  text-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
`;

const PlsCaddKey = styled.div`
  color: #ffffff;
  background-color: #4d4d4f;
  font-size: 15px;
  font-weight: 600;
  position: fixed;
  z-index: 101;
  left: 85px;
  top: 100px;
  padding: 8px 16px;
  border-radius: 15px;
  text-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;

  &:hover {
    background-color: #6cccd7; /* Change background color on hover */
    cursor: pointer; /* Change cursor to "pointer" on hover */
  }
`;

function SiteViewer() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [fileDownloadStatusDialog, setFileDownloadStatusDialog] =
    useState(false);

  const dispatch = useDispatch();

  const siteId = parseInt(params.siteId);
  //var selectedSite = sites.find(({ id }) => id === siteId);

  const [activeViewer, setActiveViewer] = useState("");
  const [selectedSite, setSelectedSite] = useState({});
  const { siteActiveViewerTab } = useSelector(
    ({ auth }) => auth.customNavigation
  );
  const [checkPotreeActions, setCheckPotreeActions] = useState(false);
  const [scanId, setScanId] = useState(null);
  const potreeRef = useRef();

  useEffect(() => {
    dispatch(resetSiteViewer());
    loadSiteDetails();
    return () => {
      dispatch(resetSiteViewer());
    };
  }, []);
  const activeViewerRef = useRef();
  useEffect(() => {
    if (checkPotreeActions == false) {
      if (activeViewerRef.current != siteActiveViewerTab) {
        onClickChangeViewer(siteActiveViewerTab);
      }

      setLeftDrawerOpen(false);
    } else if (checkPotreeActions == true) {
      var activeTab = searchParams.get("activeTab");
      if (activeTab != siteActiveViewerTab && siteActiveViewerTab) {
        setSearchParams({
          activeTab: siteActiveViewerTab,
        });
      }
    }
    setCheckPotreeActions(false);
  }, [siteActiveViewerTab]);

  useEffect(() => {
    activeViewerRef.current = activeViewer;
    var activeTab = searchParams.get("activeTab");
    if (activeTab == null) {
      if (activeViewer) {
        setSearchParams(
          {
            activeTab: activeViewer,
          },
          { replace: true }
        );
      }
    } else {
      if (activeTab != activeViewer && activeViewer) {
        setSearchParams({
          activeTab: activeViewer,
        });
      }
    }
  }, [activeViewer]);

  useEffect(() => {
    var activeTab = searchParams.get("activeTab");
    if (siteActiveViewerTab != activeTab) {
      if (siteActiveViewerTab) {
        dispatch(setSiteActiveViewerTab(activeTab));
      }
    }
  }, [searchParams]);

  const handleOnScanCreationClick = () => {
    navigate(`/apps/scan/scancreation/${siteId}`);
  };
  function resetScanId() {
    setScanId(null);
  }

  const loadSiteDetails = () => {
    if (siteId != 0 && siteId != undefined) {
      var getSiteDetailsApi = getSiteDetails(siteId);
      Promise.resolve(getSiteDetailsApi)
        .then((response) => {
          if (response.status == 200 && response.data != "") {
            setSelectedSite(response.data);
            if (response.data.siteRole == "admin") {
              dispatch(setSiteViewerAddScanAction(handleOnScanCreationClick));
              dispatch(setShowSiteViewerAddScanButton(true));
            }
            var activeTab = searchParams.get("activeTab");
            if (activeTab == null) {
              // setSearchParams({
              // 	activeTab: 'view'
              // });
              dispatch(setSiteActiveViewerTab("view"));
              //onClickChangeViewer('view');

              dispatch(setShowSiteViewerActions(true));
            } else {
              if (activeTab == "site") {
                var accountIds = response.data.accounts.map((a) => a.id);
                dispatch(
                  setEditData({
                    site: response.data,
                    accountIds: accountIds,
                    accounts: [],
                  })
                );
                window.scrollTo(0, 0);
                // setSearchParams({
                // 	activeTab: activeTab
                // });
                setActiveViewer(activeTab);
                dispatch(setSiteActiveViewerTab(activeTab));
                dispatch(setShowSiteViewerActions(true));
              } else {
                // onClickChangeViewer(activeTab);
                dispatch(setSiteActiveViewerTab(activeTab));
                dispatch(setShowSiteViewerActions(true));
              }
            }
          }
        })
        .catch((error) => {
          console.warn("Cannot retrieve data", error);
        });
    }
  };

  const switchTab = (viewerName) => {
    if (viewerName) {
      if (viewerName == "site") {
        var accountIds = selectedSite.accounts.map((a) => a.id);
        dispatch(
          setEditData({
            site: selectedSite,
            accountIds: accountIds,
            accounts: [],
          })
        );
      }
      window.scrollTo(0, 0);
      // setSearchParams({
      // 	activeTab: viewerName
      // });
      setActiveViewer(viewerName);
    }
  };

  const onClickChangeViewer = (viewerName) => {
    if (activeViewer == "view" && potreeRef.current != undefined) {
      if (potreeRef.current.getIsCoordinateSave() == false) {
        switchTab(viewerName);
      } else {
        swal({
          title: "",
          text: "Do you want to save your changes to the Coordinate Tools before closing?",
          icon: "warning",
          buttons: {
            Yes: {
              text: "Yes",
              value: "Yes",
            },
            No: {
              text: "No",
              value: "No",
            },
          },
        }).then((value) => {
          switch (value) {
            case "Yes":
              if (potreeRef.current != undefined) {
                if (potreeRef.current.updateCoordinates() == true) {
                  switchTab(viewerName);
                } else {
                  setCheckPotreeActions(true);
                  dispatch(setSiteActiveViewerTab(activeViewer));
                }
              } else {
                switchTab(viewerName);
              }
              break;
            case "No":
              switchTab(viewerName);
              break;
          }
        });
      }
    } else {
      switchTab(viewerName);
    }
  };

  const onUpdateSite = () => {
    loadSiteDetails();
  };
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
  const toggleLeftDrawer = (value) => {
    setLeftDrawerOpen(value);
  };

  const reloadSiteData = () => {
    var getSiteDetailsApi = getSiteDetails(siteId);
    Promise.resolve(getSiteDetailsApi).then((response) => {
      if (response.status == 200 && response.data != "") {
        setSelectedSite(response.data);
      }
    });
  };

  const getFileKeyFowDownload = (scan, type) => {
    if (type == UPLOAD_TYPE.plscaddkey) {
      return scan.scanPaths.plsCaddKey;
    }
  };

  const handleDownload = (scan, type) => {
    var data = {
      key: getFileKeyFowDownload(scan, type),
    };
    const fileDownloadStatusApi = checkFilesExistInFolder(data);
    Promise.resolve(fileDownloadStatusApi)
      .then((response) => {
        if (response.data.isFileExist && response.data.isFileExist == true) {
          if (response.data.fileDownloadLink) {
            const a = document.createElement("a");
            a.href = response.data.fileDownloadLink;
            a.style.display = "none";
            document.body.append(a);
            a.click();

            a.remove();
          } else {
            var downloadRequestData = {
              scanId: scan.id,
              type: type,
            };
            dispatch(downloadScanFile(downloadRequestData));
          }
        } else {
          setFileDownloadStatusDialog(true);
          setFileName(type);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  return (
    <div>
      <Tooltip title={selectedSite.name || ""}>
        <MainHeader open={leftDrawerOpen} activeViewer={siteActiveViewerTab}>
          {selectedSite.name}
        </MainHeader>
      </Tooltip>
      <div>
        {activeViewer == "view" && (
          <CommonViewer
            reloadSiteData={reloadSiteData}
            ref={potreeRef}
            selectedSite={selectedSite}
            toggleLeftDrawer={toggleLeftDrawer}
            leftDrawerOpen={leftDrawerOpen}
            scanId={scanId}
            resetScanId={resetScanId}
          />
        )}
        {activeViewer == "site" && (
          <SiteDetails site={selectedSite} onUpdateSite={onUpdateSite} />
        )}
        {activeViewer == "scans" && (
          <>
            <PlsCaddKey
              onClick={() =>
                handleDownload(selectedSite.scans[0], UPLOAD_TYPE.plscaddkey)
              }
            >
              <DownloadOutlinedIcon />
              PLS-CADD Import Key
            </PlsCaddKey>
            <ScanDetails
              reloadSiteData={reloadSiteData}
              site={selectedSite}
              onUpdateSite={onUpdateSite}
              fileName={fileName}
              setFileName={setFileName}
              fileDownloadStatusDialog={fileDownloadStatusDialog}
              setFileDownloadStatusDialog={setFileDownloadStatusDialog}
              handleDownload={handleDownload}
            />
          </>
        )}
        {activeViewer == "share" && <SiteUserDetails site={selectedSite} />}
      </div>
    </div>
  );
}

export default SiteViewer;
