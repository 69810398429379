import * as proj4 from "proj4";

const utmProjection = "+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
const wgsProjection = "+proj=longlat +datum=WGS84 +no_defs +type=crs";

export function toWgs(coordinate, siteUTMProjection) {
	const result = proj4.default(siteUTMProjection ? siteUTMProjection : utmProjection, wgsProjection, [coordinate.x, coordinate.y, coordinate.z]);

	const longitude = !isNaN(result[0]) ? result[0].toFixed(8) : 'NaN';
	const latitude = !isNaN(result[1]) ? result[1].toFixed(8) : 'NaN';
	const elevation = !isNaN(result[2]) ? result[2].toFixed(3) : 'NaN';

	return {
		latitude: latitude,
		longitude: longitude,
		elevation: elevation
	};
}

export function toCartesian(coordinate, siteUTMProjection) {
	const result = proj4.default(wgsProjection, siteUTMProjection ? siteUTMProjection : utmProjection, [coordinate.longitude, coordinate.latitude, coordinate.elevation]);

	const x = !isNaN(result[0]) ? Number(result[0].toFixed(3)) : 'NaN';
	const y = !isNaN(result[1]) ? Number(result[1].toFixed(3)) : 'NaN';
	const z = !isNaN(result[2]) ? Number(result[2].toFixed(3)) : 'NaN';

	return {
		x: x,
		y: y,
		z: z
	};
}