import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APP_CONFIG from "../../../../AppConfig";
import SERVICE_STATUS from "app/services/ServiceStatus";

export const getCurrentFolderKey = createAsyncThunk('admin/folderApp/getCurrentFolderKey',
    async (obj, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/folder/getCurrentFolderKey/${obj.type}/${obj.id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }

        });
        const data = await response.data;

        dispatch(setCurrentFolderKey(data));

    });

export const getFilesById = createAsyncThunk('admin/folderApp/getFilesById',
    async (obj, { dispatch, getState }) => {
        const url = APP_CONFIG.api + `v1/folder/getFiles/${obj.type}/${obj.id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const files = await response.data;

        setFileDetails(files, dispatch);

    });

export const getFilesByKey = createAsyncThunk(
    'admin/folderApp/getFilesByKey',
    async (folderObj, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/folder/getFiles`;
        const response = await axios.post(url, folderObj.data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const files = await response.data;
        setFileDetails(files, dispatch);
    }
);


const setFileDetails = (files, dispatch) => {
    dispatch(setFiles(files));

    if (files.length > 0) {
        if (files.length == files.filter(f => f.type == 'file').length) {
            var size = 0;
            files.forEach(file => {
                size = size + file.contentLength;
            });

            dispatch(setFolderSize(size));
        }

    }
}

export const getFolderSize = createAsyncThunk(
    'admin/folderApp/getFolderSize',
    async (folderObj, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/folder/getFolderSize`;
        const response = await axios.post(url, folderObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.data;
        dispatch(setFolderSize(data));
    }
);

export const createFolder = createAsyncThunk(
    'admin/folderApp/createFolder',
    async (folderObj, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/folder/createFolder`;
        var formData = new FormData();
        formData.append("id", folderObj.id);
        formData.append("type", folderObj.type);
        formData.append("fileKey", folderObj.fileKey);
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.data;
        return data;
    }
);

export const renameFile = createAsyncThunk(
    'admin/folderApp/renameFile',
    async (folderObj, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/folder/renameFile`;
        const response = await axios.post(url, folderObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.data;
        return data;
    }
);

export const renameFolder = createAsyncThunk(
    'admin/folderApp/renameFolder',
    async (folderObj, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/folder/renameFolder`;
        var formData = new FormData();
        formData.append("from", folderObj.from);
        formData.append("to", folderObj.to);
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.data;
        return data;
    }
);


export const deleteFolders = createAsyncThunk('admin/folderApp/deleteFolders', async (folders, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/folder/deleteFolders`;

    const response = await axios.post(url, folders, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.data;
    return data;
})

export const downloadFiles = createAsyncThunk('admin/folderApp/downloadFiles', async (folders, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/folder/downloadFiles`;
    const response = await axios.post(url, folders, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.data;
    multiDownload(data);
})


const multiDownload = async (urls) => {
    for (const [index, url] of urls.entries()) {
        download(url);
        await delay(5000)
    }
}
const download = async (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.style.display = 'none';
    document.body.append(a);
    a.click();
    await delay(100);
    a.remove();
}


const delay = milliseconds =>
    new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    })


export const moveFiles = createAsyncThunk('admin/folderApp/moveFiles', async (folders, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/folder/moveFiles`;

    const response = await axios.post(url, folders, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.data;
    return data;
})

export const copyFiles = createAsyncThunk('admin/folderApp/copyFiles', async (folders, { dispatch, getState }) => {

    const url = APP_CONFIG.api + `v1/folder/copyFiles`;
    const response = await axios.post(url, folders, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.data;
    return data;
})


const folderSlice = createSlice({
    name: 'admin/folderApp',
    initialState: {
        files: [],
        updateFolderState: '',
        updateMoveState: '',
        updateCopyState: '',
        currentFolderKey: '',
        folderSize: 0
    },

    reducers: {
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        setUpdateFolderState: (state, action) => {
            state.updateFolderState = action.payload;
        },
        setUpdateMoveState: (state, action) => {
            state.updateMoveState = action.payload;
        },
        setUpdateCopyState: (state, action) => {
            state.updateCopyState = action.payload;
        },
        setCurrentFolderKey: (state, action) => {
            state.currentFolderKey = action.payload;
        },
        setFolderSize: (state, action) => {
            state.folderSize = action.payload;
        }
    },
    extraReducers: {
        [createFolder.fulfilled]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.fulfilled;
        },
        [createFolder.rejected]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.rejected;
        },
        [renameFile.fulfilled]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.fulfilled;
        },
        [renameFile.rejected]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.rejected;
        },
        [renameFolder.fulfilled]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.fulfilled;
        },
        [renameFolder.rejected]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.rejected;
        },
        [deleteFolders.fulfilled]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.fulfilled;
        },
        [deleteFolders.rejected]: (state, action) => {
            state.updateFolderState = SERVICE_STATUS.rejected;
        },
        [moveFiles.fulfilled]: (state, action) => {
            state.updateMoveState = SERVICE_STATUS.fulfilled;
        },
        [moveFiles.rejected]: (state, action) => {
            state.updateMoveState = SERVICE_STATUS.rejected;
        },
        [copyFiles.fulfilled]: (state, action) => {
            state.updateCopyState = SERVICE_STATUS.fulfilled;
        },
        [copyFiles.rejected]: (state, action) => {
            state.updateCopyState = SERVICE_STATUS.rejected;
        },
    },
});

export const { setFiles, setUpdateFolderState, setUpdateMoveState, setUpdateCopyState, setCurrentFolderKey, setFolderSize } = folderSlice.actions;

export default folderSlice.reducer;
