import React from "react";
import styled from "@emotion/styled";

const CustomSwitchBtn = styled.div`
  .toggle {
    background-color: #565656;
    padding: 8px 4px;
    border-radius: 30px;
    display: inline-block;
    transition: 0.3s ease all;
  }

  .toggle input[type="radio"] + label {
    background-color: #565656;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s ease all;
  }

  .toggle input[type="radio"]:checked + label {
    background-color: white;
    color: #565656;
    transition: 0.3s ease all;
  }
`;

function ToggleSwitch(props) {
  const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };
  var name = createUUID();

  return (
    <CustomSwitchBtn>
      <div className="toggle" style={props.style}>
        <input
          type="radio"
          name={name}
          id={`${name}-yes`}
          style={{ display: "none" }}
          checked={props.checked}
          onChange={() => props.onToggleChange(true)}
        />
        <label className="radio-button" htmlFor={`${name}-yes`}>
          {props.yes}
        </label>
        <input
          type="radio"
          name={name}
          id={`${name}-no`}
          style={{ display: "none" }}
          checked={!props.checked}
          onChange={() => props.onToggleChange(false)}
        />
        <label className="radio-button" htmlFor={`${name}-no`}>
          {props.no}
        </label>
      </div>
    </CustomSwitchBtn>
  );
}

export default ToggleSwitch;
