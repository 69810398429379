import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import APP_CONFIG from "../../../../AppConfig";

export const loadAllAccountssDetails = createAsyncThunk(
  "admin/accountApp/loadAllAccountssDetails",
  async () => {
    const url = APP_CONFIG.api + `v1/organization/getAllAccountsDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const loadAllAdminAccounts = createAsyncThunk(
  "admin/accountApp/loadAdminAccounts",
  async () => {
    const url = APP_CONFIG.api + `v1/organization/getAllAdminAccounts`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const getOrganisationDetails = createAsyncThunk(
  "admin/accountApp/getOrganisationDetails",
  async () => {
    const url = APP_CONFIG.api + `v1/organization/getOrganisationDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const getOrganisationDetailsById = createAsyncThunk(
  "admin/accountApp/getOrganisationDetailsById",
  async (organisationId) => {
    const url =
      APP_CONFIG.api +
      `v1/organization/getOrganisationDetailsById/${organisationId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const updateOrganisationDetails = createAsyncThunk(
  "admin/accountApp/updateOrganisationDetails",
  async (updateOrganisation, { dispatch, getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/organization/updateOrganisationDetails/${updateOrganisation.id}`;

    const response = await axios.put(url, updateOrganisation, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    if (data == true) {
      if (getState().auth.user.role.includes("admin")) {
        dispatch(getOrganisationDetailsById(updateOrganisation.id));
      } else {
        dispatch(getOrganisationDetails());
      }
    }
  }
);

export const updateCompanyEditDetails = createAsyncThunk(
  "admin/accountApp/updateCompanyEditDetails",
  async (updateOrganisation, { dispatch, getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/organization/updateCompanyEditDetails/${updateOrganisation.id}`;

    const response = await axios.put(url, updateOrganisation, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    if (data == true) {
      if (getState().auth.user.role.includes("admin")) {
        dispatch(getOrganisationDetailsById(updateOrganisation.id));
      } else {
        dispatch(getOrganisationDetails());
      }
    }
  }
);

export const updateOrganisationInfo = createAsyncThunk(
  "admin/accountApp/updateOrganisationInfo",
  async (updateOrganisation, { dispatch, getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/organization/updateOrganisationInfo/${updateOrganisation.id}`;

    const response = await axios.put(url, updateOrganisation, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    if (data == true) {
      if (getState().auth.user.role.includes("admin")) {
        dispatch(getOrganisationDetailsById(updateOrganisation.id));
      } else {
        dispatch(getOrganisationDetails());
      }
    }
  }
);

export const createAccount = createAsyncThunk(
  "admin/accountApp/createAccount",
  async (createAccount) => {
    const url = APP_CONFIG.api + `v1/organization/createAccountDetails`;

    const response = await axios.post(url, createAccount, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const updateAccount = createAsyncThunk(
  "admin/accountApp/updateAccount",
  async (updateAccount) => {
    var updateObj = { ...updateAccount };
    updateObj = { ...updateObj, customers: [] };

    const url =
      APP_CONFIG.api +
      `v1/organization/updateAccountDetails/${updateAccount.id}`;

    const response = await axios.put(url, updateObj, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const removeAccount = createAsyncThunk(
  "admin/accountApp/removeAccount",
  async (accountId) => {
    const url =
      APP_CONFIG.api + `v1/organization/deleteAccountDetails/${accountId}`;
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  }
);

export const loadAllDeletedAccount = createAsyncThunk(
  "admin/accountApp/loadAllDeletedaccount",
  async () => {
    const url = APP_CONFIG.api + `v1/organization/getAllDeleteAccountDetails`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

const accountSlice = createSlice({
  name: "admin/accountApp",
  initialState: {
    accountsDetails: [],
    accounts: [],
    loadingState: "",
    createAccountState: "",
    updateAccountState: "",
    removeAccountState: "",
    uploadAccountId: "",
    deletedAccount: [],
    organisationDetails: {},
  },

  reducers: {
    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    setcreateAccountState: (state, action) => {
      state.createAccountState = action.payload;
    },
    setupdateAccountState: (state, action) => {
      state.updateAccountState = action.payload;
    },
    setremoveAccountState: (state, action) => {
      state.removeAccountState = action.payload;
    },
    setOrgnizationDetails: (state, action) => {
      state.organisationDetails = action.payload;
    },
  },
  extraReducers: {
    [loadAllAccountssDetails.fulfilled]: (state, action) => {
      state.accountsDetails = action.payload;
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadAllAccountssDetails.rejected]: (state) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadAllAccountssDetails.pending]: (state) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [loadAllAdminAccounts.fulfilled]: (state, action) => {
      state.accounts = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      state.loadingState = SERVICE_STATUS.fulfilled;
    },
    [loadAllAdminAccounts.rejected]: (state) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [loadAllAdminAccounts.pending]: (state) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [createAccount.fulfilled]: (state) => {
      state.createAccountState = SERVICE_STATUS.fulfilled;
    },
    [createAccount.rejected]: (state) => {
      state.createAccountState = SERVICE_STATUS.rejected;
    },
    [createAccount.pending]: (state) => {
      state.createAccountState = SERVICE_STATUS.pending;
    },
    [updateAccount.fulfilled]: (state) => {
      state.updateAccountState = SERVICE_STATUS.fulfilled;
    },
    [updateAccount.rejected]: (state) => {
      state.updateAccountState = SERVICE_STATUS.rejected;
    },
    [updateAccount.pending]: (state) => {
      state.updateAccountState = SERVICE_STATUS.pending;
    },
    [removeAccount.fulfilled]: (state) => {
      state.removeAccountState = SERVICE_STATUS.fulfilled;
    },
    [removeAccount.rejected]: (state) => {
      state.removeAccountState = SERVICE_STATUS.rejected;
    },
    [removeAccount.pending]: (state) => {
      state.removeAccountState = SERVICE_STATUS.pending;
    },
    [loadAllDeletedAccount.fulfilled]: (state, action) => {
      state.deletedAccount = action.payload;
    },
    [loadAllDeletedAccount.pending]: (state) => {
      state.loadingState = SERVICE_STATUS.pending;
    },
    [loadAllDeletedAccount.rejected]: (state) => {
      state.loadingState = SERVICE_STATUS.rejected;
    },
    [getOrganisationDetails.fulfilled]: (state, action) => {
      state.organisationDetails = action.payload;
    },
    [getOrganisationDetailsById.fulfilled]: (state, action) => {
      state.organisationDetails = action.payload;
    },
  },
});

export const {
  setLoadingState,
  setcreateAccountState,
  setupdateAccountState,
  setremoveAccountState,
  updateImageAccount,
  setOrgnizationDetails,
} = accountSlice.actions;

export default accountSlice.reducer;
