
import { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { MenuItem, ListItemIcon, Menu, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const MenuStyles = makeStyles(() =>
    createStyles({
        menuPaper: {
            backgroundColor: "#E8E7E7",
            color: '#626262',
            marginTop: '-20px',
            boxShadow: 'none'
        },
        menuList: {
            paddingTop: '0px'
        },
        menuItemAdd: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            paddingTop: '0px',
            paddingBottom: '10px'
        },

    })
);

const ToolbarAddButton = (props) => {

    const { handleOnCreateSiteClick, handleOnCreateScanClick, handleOnCreateProjectClick } = props;
    const classes = MenuStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <MenuItem style={{
                borderRadius: '10px',
                marginLeft: '8px',
                backgroundColor: anchorEl != null ? '#E8E7E7' : ''
            }} role="button" onClick={handleClick}>
                <div className='flex-col text-center'>
                    <ListItemIcon className="min-w-40" style={{ 'display': 'block' }}>
                        <AddCircleOutlineIcon style={{ 'color': '#626262' }} />
                    </ListItemIcon>
                    <Typography
                        style={{
                            'color': '#626262',
                            'fontWeight': '600',
                            'fontSize': '11px'
                        }}
                    >Add
                    </Typography>
                </div>
            </MenuItem>
            <Menu
                id="card-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}

                classes={{ paper: classes.menuPaper, list: classes.menuList }}
            >
                <MenuItem className={classes.menuItemAdd} style={{ color: '#626262' }}>
                    <div className="flex w-full items-center" style={{ color: '#626262' }}>
                        <p style={{ color: '#626262', fontWeight: '600', fontSize: '11px', marginLeft: '6px' }}>Add</p>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => handleOnCreateScanClick()} >
                    <div className="flex w-full items-center">
                        <p style={{ color: '#626262', fontWeight: '600', fontSize: '11px' }} className="ml-8">New Scan</p>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => handleOnCreateSiteClick()}>
                    <div className="flex w-full items-center">
                        <p style={{ color: '#626262', fontWeight: '600', fontSize: '11px' }} className="ml-8">New Site</p>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => handleOnCreateProjectClick()}>
                    <div className="flex w-full items-center">
                        <p style={{ color: '#626262', fontWeight: '600', fontSize: '11px' }} className="ml-8">New Project</p>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ToolbarAddButton;
