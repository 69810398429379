import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import APP_CONFIG from 'app/AppConfig';
import swal from "sweetalert";
import InvoiceStatusCodeConstants from '../../constants/InvoiceStatusCodeConstants';
import ResponseStatusCodeConstants from '../../constants/ResponseStatusCodeConstants';

export const getAllInvoices = createAsyncThunk('admin/invoiceApp/getProjectInvoices', async (organizationId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/getProjectInvoices/${organizationId}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});


export const getOrganizationProjectInvoices = createAsyncThunk('admin/invoiceApp/getOrganizationProjectInvoices', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/getOrganizationProjectInvoices`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});




export const generateInvoice = createAsyncThunk('admin/invoiceApp/generateInvoice', async (projectInvoice, { dispatch, getState }) => {
    try {
        const url = APP_CONFIG.api + `v1/projectInvoice/generateProjectInvoice/${projectInvoice.projectInvoiceId}`;
        const response = await axios.post(url, projectInvoice.projectInvoiceDiscount, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        if (response.status == 200) {
            if (data.statusCode != null) {
                if (InvoiceStatusCodeConstants.CustomerNotFound == data.statusCode) {
                    swal("", "Organization not linked with payment gateway", "error");
                    projectInvoice.failureCallBack();
                }
                else if (InvoiceStatusCodeConstants.ScansNotFound == data.statusCode) {
                    swal("", "No scans found", "error");
                    projectInvoice.failureCallBack();
                } else if (InvoiceStatusCodeConstants.InvoiceAlreadyFound == data.statusCode) {
                    swal("", "Invoice for this date range already exists", "error");
                    projectInvoice.failureCallBack();
                }
            }
            else if (data.fileUrl) {
                window.open(data.fileUrl);
                projectInvoice.successCallBack();
            }
            else {
                swal("", "Something Went Worng", "error");
                projectInvoice.failureCallBack();
            }
        }
        return data.fileUrl;
    } catch (error) {
        console.error(error);
        projectInvoice.failureCallBack();
    }

});

export const deleteProjectInvoice = createAsyncThunk('admin/invoiceApp/generateInvoice', async (projectInvoice, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/deleteInvoice/${projectInvoice.projectInvoiceId}`;
    const response = await axios.delete(url, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (response.status == 200) {
        projectInvoice.successCallBack();
    } else {
        projectInvoice.failureCallBack();
    }
});

export const getProjectInvoiceReceipt = createAsyncThunk('admin/invoiceApp/getProjectInvoiceReceipt', async (projectInvoiceId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/downloadProjectInvoiceReceipt/${projectInvoiceId}`;

    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.data;
    window.open(data, 'invoice', "height=1600,width=1000,left=600,top=20");
});

export const getProjectInvoiceDownloadLink = createAsyncThunk('admin/invoiceApp/getProjectInvoiceDownloadLink', async (projectInvoiceId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/downloadProjectInvoice/${projectInvoiceId}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',

        },
    });
    const data = await response.data;
    window.open(data);

});

export const updateProjectInvoiceStatus = createAsyncThunk('admin/invoiceApp/updateProjectInvoiceStatus', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/checkProjectInvoiceStatus/${requestData.id}`;

    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.data;
    if (response.status == 200) {
        if (response.data) {
            window.open(data);
        }
        requestData.successCallBack();
    }


});

export const getProjectInvoiceSummary = createAsyncThunk('admin/invoiceApp/getProjectInvoiceSummary', async (projectId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/getProjectInvoiceSummary/${projectId}`;

    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob'
    });
    const data = await response.data;

    var _url = window.URL.createObjectURL(data);
    if (_url) {
        const a = document.createElement('a');
        a.download = "InvoiceSummery.pdf";
        a.href = _url;
        a.style.display = 'none';
        document.body.append(a);
        a.click();
        a.remove();
    }


});

export const addOrganizationSettings = createAsyncThunk('admin/invoiceApp/organizationSettings', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/addInvoiceOrganizationSettings`;

    const response = await axios.post(url, requestData.data, {
        headers: {
            'Content-Type': 'application/json',
        },

    });
    const data = await response.data;
    if (response.status == 200) {

        requestData.successCallBack();
    }


});

export const getOrganizationInvoiceSettings = (organizationId) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/getInvoiceOrganizationSettings/${organizationId}`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },

    });
    return response;

}

export const updateProjectInvoiceDiscount = createAsyncThunk('admin/invoiceApp/updateProjectInvoiceDiscount', async (requestData, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/updateInvoiceDiscount/${requestData.id}`;
    const response = await axios.put(url, requestData.data, {
        headers: {
            'Content-Type': 'application/json',
        },

    });
    const data = await response.data;
    if (response.status == 200) {

        window.open(data);
        requestData.successCallBack();
    }

});

export const getProjectInvoiceDiscount = (projectInvoiceId) => {
    const url = APP_CONFIG.api + `v1/projectInvoice/getProjectInvoiceDiscount/${projectInvoiceId}`;
    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },

    });
    return response;
}


export const getOrganizationTokenByOrganizationId = (organizationId) => {
    const url = APP_CONFIG.api + `v1/organizationTokens/getOrgnizationTokenByOrganizationId/${organizationId}`;
    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },

    });
    return response;
}

export const generateOrganizationInvoice = createAsyncThunk('admin/invoiceApp/generateOrganizationInvoice', async (organizationInvoice, { dispatch, getState }) => {
    try {
        const url = APP_CONFIG.api + `v1/projectInvoice/generateOrganizationInvoice/${organizationInvoice.organizationId}`;
        const response = await axios.post(url, organizationInvoice.projectInvoiceDiscount, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        if (data.statusCode != null) {
            if (data.statusCode == 200) {
                swal("", "Invoices Generated Successfully", "success");
                organizationInvoice.successCallBack();
            } else if (data.statusCode == ResponseStatusCodeConstants.CustomerNotFound) {
                swal("", "Organization not linked with payment gateway", "error");
                organizationInvoice.failureCallBack();
            } else if (data.statusCode == ResponseStatusCodeConstants.ProjectsNotFound) {
                swal("", "No Projects found", "error");
                organizationInvoice.failureCallBack();
            }
        }
        else {
            swal("", "Something Went Worng", "error");
            organizationInvoice.failureCallBack();
        }
    } catch (error) {
        console.error(error);
        swal("", "Something Went Worng", "error");
        organizationInvoice.failureCallBack();
    }

});

const projectInvoiceSlice = createSlice({
    name: 'admin/invoiceApp',
    initialState: {
        invoices: []
    },

    reducers: {
        setInvoices: (state, action) => {
            state.invoices = action.payload;
        },
    },

    extraReducers: {
        [getAllInvoices.fulfilled]: (state, action) => {
            state.invoices = action.payload;
        },
        [getOrganizationProjectInvoices.fulfilled]: (state, action) => {
            state.invoices = action.payload;
        }
    }
});

export const { setInvoices } = projectInvoiceSlice.actions;

export default projectInvoiceSlice.reducer;