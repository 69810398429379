import * as THREE from 'three';
import { Convert2Dto3D } from '../CoordinateConverter';

const AddPointMeasurement = (viewer, Potree, position) => {
    let measure = new Potree.Measure();
    measure.showDistances = false;
    measure.showAngles = false;
    measure.showCoordinates = true;
    measure.showArea = false;
    measure.closed = true;
    measure.maxMarkers = 1;
    measure.name = 'Point';
    measure.addMarker(new THREE.Vector3(position.easting, position.northing, position.elevation));
    viewer.measuringTool.scene.add(measure)
    viewer.scene.addMeasurement(measure);
}

const AddDistanceMeasurement = (viewer, Potree, positions) => {
    var measure = new Potree.Measure();
    measure.showDistances = true;
    measure.showArea = false;
    measure.showAngles = false;
    measure.closed = false;
    measure.name = "Distance";
    AddMeasurement(measure, positions, viewer);
}

const AddAngleMeasurement = (viewer, Potree, positions) => {
    let measure = new Potree.Measure();
    measure.showDistances = false;
    measure.showAngles = true;
    measure.showArea = false;
    measure.closed = true;
    measure.maxMarkers = 3;
    measure.name = 'Angle';
    AddMeasurement(measure, positions, viewer);
}

const AddAreaMeasurement = (viewer, Potree, positions) => {
    let measure = new Potree.Measure();
    measure.showDistances = true;
    measure.showArea = true;
    measure.closed = true;
    measure.name = 'Area';
    AddMeasurement(measure, positions, viewer);
}

const AddMeasurement = (measure, positions, viewer) => {
    let domElement = viewer.renderer.domElement;
    positions.reverse();
    positions.forEach(position => {
        measure.addMarker(new THREE.Vector3(position.easting, position.northing, position.elevation));
    });

    if (measure.name == 'Angle' && measure.points.length < 3) {
        measure.addMarker(measure.points[0].position.clone());
        // let cancel = {
        //     removeLastMarker: measure.maxMarkers > 3,
        //     callback: null
        // };
        // let insertionCallback = (e) => {
        //     if (e.button === THREE.MOUSE.LEFT) {
        //         measure.addMarker(measure.points[measure.points.length - 1].position.clone());

        //         if (measure.points.length >= measure.maxMarkers) {
        //             cancel.callback();
        //         }

        //         viewer.inputHandler.startDragging(
        //             measure.spheres[measure.spheres.length - 1]);
        //     } else if (e.button === THREE.MOUSE.RIGHT) {
        //         cancel.callback();
        //     }
        // };

        // cancel.callback = e => {
        //     if (cancel.removeLastMarker) {
        //         measure.removeMarker(measure.points.length - 1);
        //     }
        //     domElement.removeEventListener('mouseup', insertionCallback, false);
        //     viewer.removeEventListener('cancel_insertions', cancel.callback);
        // };

        // if (measure.maxMarkers > 1) {
        //     viewer.addEventListener('cancel_insertions', cancel.callback);
        //     domElement.addEventListener('mouseup', insertionCallback, false);
        // }

    }
    // if (measure.showAngles) {
    //     if (measure.points.length < 3) {
    //         measure.addMarker(measure.points[measure.points.length - 1].position.clone());
    //     }
    // }
    // else {
    //     measure.addMarker(measure.points[measure.points.length - 1].position.clone());
    // }
    viewer.measuringTool.scene.add(measure)
    viewer.scene.addMeasurement(measure);
}

export const AddMeasurementData = (viewer, Potree, data, pointClouds) => {
    data.measurements.forEach(measurement => {
        if (measurement.name == 'Point') {
            let pointCloud = pointClouds.filter(x => x.scanId == measurement.pointCloud.scanId)[0];
            let position = Convert2Dto3D(measurement.longitude, measurement.latitude, measurement.elevation,
                measurement.pointCloud.epsg, pointCloud);
            AddPointMeasurement(viewer, Potree, position);
        }
        else if (measurement.name == 'Distance') {
            var positions = [];
            measurement.points.forEach(point => {
                let pointCloud = pointClouds.filter(x => x.scanId == point.pointCloud.scanId)[0];
                let position = Convert2Dto3D(point.longitude, point.latitude, point.elevation,
                    point.pointCloud.epsg, pointCloud);
                positions.push(position);
            });
            AddDistanceMeasurement(viewer, Potree, positions);
        }
        else if (measurement.name == 'Area') {
            var positions = [];
            measurement.points.forEach(point => {
                let pointCloud = pointClouds.filter(x => x.scanId == point.pointCloud.scanId)[0];
                let position = Convert2Dto3D(point.longitude, point.latitude, point.elevation,
                    point.pointCloud.epsg, pointCloud);
                positions.push(position);
            });
            AddAreaMeasurement(viewer, Potree, positions);
        }
        else if (measurement.name == 'Angle') {
            var positions = [];
            measurement.points.forEach(point => {
                let pointCloud = pointClouds.filter(x => x.scanId == point.pointCloud.scanId)[0];
                let position = Convert2Dto3D(point.longitude, point.latitude, point.elevation,
                    point.pointCloud.epsg, pointCloud);
                positions.push(position);
            });
            AddAngleMeasurement(viewer, Potree, positions);
        }
    });
}