import React from "react";
import { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import styled from "@emotion/styled";
import FolderScan from "./FolderScan";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

export const ContextMenu = styled.div`
  position: absolute;
  width: 100px;
  background-color: #ffffff;
  border-radius: 0px;
  box-sizing: border-box;
  border: 1px solid #e8e7e7;
  z-index: 9999;
  top: ${(props) => props.top + "px"};
  left: ${(props) => props.left + "px"};
`;
function FolderScans(props) {
  const {
    folders,
    isOpenFolder,
    handleOnScanFolderDrop,
    handleOnChangeFolderNameEdit,
    folderName,
    handleOnChangeFolderName,
    handleOnUpdateFolderClick,
    handleOnDeleteFolderClick,
    loadedScanIds,
    findLoadedScan,
    zoomToPointCloud,
    zoomOutPointCloud,
    togglePointCloud,
    currentViewer,
    handleScanDragStart,
    handleFolderDragStart,
    isAddFolderName,
    handleOnIsEditClick,
  } = props;

  const [completeFolders, setCompleteFolders] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [folderId, setFolderId] = useState(0);
  const [editfolderName, setEditFolderName] = useState(null);
  const [isRightClick, setIsRightClick] = useState(false);
  const [folder, setFolder] = useState([]);

  const [highlightFolderIndex, setHighlightFolderIndex] = useState(-1);
  const [showHighlightFolderIndex, setShowHighlightFolderIndex] =
    useState(true);

  const contextMenuWidth = 100;

  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const handleClick = () => setIsRightClick(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    setCompleteFolders(folders);
  }, [folders]);

  useEffect(() => {
    setEditFolderName(folderName);
  }, [folderName]);

  const handleOnDrop = (e, folderId) => {
    e.preventDefault();
    handleOnScanFolderDrop(folderId, "folder");
  };

  const handleOnFolderEdit = (folder) => {
    MixPanelIntegration.EventClicks("Folder Edit Click");
    setIsEditMode(true);
    setFolderId(folder.id);
    handleOnIsEditClick(true);
    handleOnChangeFolderNameEdit(folder.folderName);
  };

  const handleOnUpdateClick = (folder) => {
    MixPanelIntegration.EventClicks("Folder Update Click");
    setIsEditMode(false);
    setFolderId(0);
    handleOnUpdateFolderClick(folder);
  };

  const handleOnCloseClick = () => {
    MixPanelIntegration.EventClicks("Folder Edit Close Click");
    handleOnIsEditClick(false);
    setIsEditMode(false);
    setFolderId(0);
  };

  const handleOnFolderDelete = (folderId) => {
    MixPanelIntegration.EventClicks("Folder Delete Click");
    handleOnDeleteFolderClick(folderId);
  };

  const handleOnRightClick = (e, _folder, target) => {
    MixPanelIntegration.EventClicks("Folder Right Click");
    setIsRightClick(true);
    setFolder(_folder);
    if (
      contextMenuWidth > 0 &&
      target.current.getBoundingClientRect().width - e.pageX > contextMenuWidth
    ) {
      setPoints({
        x: e.pageX,
        y: e.pageY - 90,
      });
    } else {
      setPoints({
        x: e.pageX - contextMenuWidth,
        y: e.pageY - 90,
      });
    }
  };

  return (
    <>
      {completeFolders?.length > 0 &&
        completeFolders.map((folder, i) => (
          <FolderScan
            key={i}
            folder={folder}
            index={i}
            handleOnRightClick={handleOnRightClick}
            highlightFolderIndex={highlightFolderIndex}
            showHighlightFolderIndex={showHighlightFolderIndex}
            setHighlightFolderIndex={setHighlightFolderIndex}
            setShowHighlightFolderIndex={setShowHighlightFolderIndex}
            handleOnDrop={handleOnDrop}
            handleFolderDragStart={handleFolderDragStart}
            folderId={folderId}
            isEditMode={isEditMode}
            editfolderName={editfolderName}
            handleOnChangeFolderName={handleOnChangeFolderName}
            handleOnUpdateClick={handleOnUpdateClick}
            handleOnCloseClick={handleOnCloseClick}
            loadedScanIds={loadedScanIds}
            findLoadedScan={findLoadedScan}
            zoomToPointCloud={zoomToPointCloud}
            zoomOutPointCloud={zoomOutPointCloud}
            togglePointCloud={togglePointCloud}
            currentViewer={currentViewer}
            handleScanDragStart={handleScanDragStart}
            handleOnScanFolderDrop={handleOnScanFolderDrop}
            isOpenFolder={isOpenFolder}
          />
        ))}
      {isRightClick && (
        <ContextMenu top={points.y} left={points.x}>
          <div>
            {isAddFolderName ? (
              <Button
                disabled={isAddFolderName}
                style={{
                  color: isAddFolderName ? "#00000042" : "#454545",
                  borderRadius: "0px",
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleOnFolderEdit(folder);
                }}
              >
                Edit
              </Button>
            )}
            <Divider />
            <Button
              onClick={() => {
                handleOnFolderDelete(folder.id);
              }}
              style={{ color: "#E0604C", borderRadius: "0px" }}
            >
              Delete
            </Button>
          </div>
        </ContextMenu>
      )}
    </>
  );
}
export default FolderScans;
