import * as turf from "@turf/turf";
import MapBoxCommon from "../MapBoxCommon";

class MapboxOrtho extends MapBoxCommon {
    constructor(viewer, orthoInfo, scanId) {
        super();
        this.viewer = viewer;
        this.orthoInfo = orthoInfo;
        this.scanId = scanId;
        this.id = this.createUUID();
        this.showingOrtho = false;
        this.styleLoadEvent = this.onStyleLoad.bind(this);
    }

    showOrtho() {
        this.addMapTiles();
        this.viewer.fitBounds(this.orthoInfo.maxBounds, { animate: false });
        // this.viewer.setMaxBounds(this.orthoInfo.maxBounds);
        //  this.viewer.setZoom(this.orthoInfo.minZoom == 1 ? 16 : this.orthoInfo.minZoom);
        this.viewer.setCenter(this.getCenter());

        this.showingOrtho = true;
    }

    setFitBounds() {
        this.viewer.fitBounds(this.orthoInfo.maxBounds);
        // this.viewer.setMaxBounds(this.orthoInfo.maxBounds);
    }

    hideOrtho() {
        this.remove();
        this.showingOrtho = false;
        // this.viewer.setMaxBounds(null);
    }


    onStyleLoad(e) {
        this.addMapTiles();
    }

    zoomIn() {
        this.viewer.setZoom(this.viewer.getZoom() + 1);
        // this.viewer.flyTo({
        //     zoom: this.viewer.getZoom() + 1
        // });
    }

    zoomOut() {
        this.viewer.setZoom(this.viewer.getZoom() - 1);
        // this.viewer.flyTo({
        //     zoom: this.viewer.getZoom() - 1
        // });
    }

    getCenter() {
        var features = turf.points(this.orthoInfo.maxBounds);
        var centerPoint = turf.center(features);
        return centerPoint.geometry.coordinates;
    }

    addMapTiles() {
        if (this.viewer.getLayer(this.id) != undefined) {
            this.viewer.removeLayer(this.id);
        }

        if (this.viewer.getSource(this.id) != undefined) {
            this.viewer.removeSource(this.id);
        }
        this.viewer.addSource(this.id, {
            'type': 'raster',
            'tiles': [
                `${this.orthoInfo.orthoPath}{z}/{x}/{y}.png`
            ],
            'tileSize': 256,
            'attribution': '',
           'scheme': this.orthoInfo.isGdal2Tiles ? 'tms': 'xyz',
            'bounds': [this.orthoInfo.maxBounds[0][0], this.orthoInfo.maxBounds[0][1], this.orthoInfo.maxBounds[1][0], this.orthoInfo.maxBounds[1][1]],
            'minzoom': this.orthoInfo.minZoom,
            'maxzoom': this.orthoInfo.maxZoom
        });

        this.viewer.addLayer({
            'id': this.id,
            'type': 'raster',
            'source': this.id
        });
    }

    remove() {
        if (this.viewer.getLayer(this.id) != undefined) {
            this.viewer.removeLayer(this.id);
        }

        if (this.viewer.getSource(this.id) != undefined) {
            this.viewer.removeSource(this.id);
        }
    }
}

export default MapboxOrtho;