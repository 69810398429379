import { Menu, MenuItem } from "@mui/material"
import useStyles from '../styles/SiteCreateOrEditStyles';

const EpochMenuList = (props) => {
    const classes = useStyles(props);
    return (
        <Menu
            id="epochMenu"
            anchorEl={props.epochMenuAnchorEl}
            keepMounted
            open={Boolean(props.epochMenuAnchorEl)}
            onClose={props.handleOnEpochMenuClose}
            onClick={props.handleOnEpochMenuClose}
            className={classes.epochMenuWidth}
        >
            <MenuItem onClick={() => { props.handleOnChangeEpochMenuValue('Current') }}>
                <div className="flex w-full items-center">
                    <p className="ml-8">Current</p>
                </div>
            </MenuItem>
        </Menu>
    )
}

export default EpochMenuList;