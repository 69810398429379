/* eslint-disable complexity */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Tooltip,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Fade,
  TableRow,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function AlignToControlConfirmDialog(props) {
  const {
    onYesClick,
    onNoClick,
    coordinates,
    transformationErrors,
    selectedCoordinates,
  } = props;
  const [savedCoordinate, setSavedCoordinate] = useState([]);

  useEffect(() => {
    if (coordinates) {
      getValidCoordinates();
    }
  }, []);

  const getValidCoordinates = () => {
    var saveCoordinates = [];
    // eslint-disable-next-line complexity
    selectedCoordinates.forEach((element) => {
      if (element.model && element.control) {
        var dataObj = {
          name: element.name ? element.name : null,
          code: element.code ? element.code : null,
          control: {
            x: element.control ? element.control.x : null,
            y: element.control ? element.control.y : null,
            z: element.control ? element.control.z : null,
          },
          model: {
            x: element.model ? element.model.x : null,
            y: element.model ? element.model.y : null,
            z: element.model ? element.model.z : null,
          },
          error:
            Object.keys(transformationErrors).length != 0 &&
            transformationErrors[element.name] != -1 &&
            element.name
              ? transformationErrors[element.name]
              : null,
        };
        saveCoordinates.push(dataObj);
      }
    });
    setSavedCoordinate(saveCoordinates);
  };

  function getCoordinateError(coordinate, axis) {
    if (!coordinate.model || !coordinate.control) {
      return "";
    }
    const control = coordinate.control[axis];
    const model = coordinate.model[axis];
    return Math.abs(control - model).toFixed(3);
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      scroll="paper"
      TransitionComponent={Fade}
      transitionDuration={300}
      sx={{
        "& .MuiDialog-paper": {
          background: "linear-gradient(to bottom right, #D9E2EC, white)",
          position: "relative",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        id="dialog-title"
        className="text-center"
        style={{
          fontSize: 30,
          fontWeight: 600,
          letterSpacing: "-0.01em",
          textAlign: "center",
          color: "#58BEC9",
          paddingTop: "50px",
        }}
      >
        Confirm alignment
      </DialogTitle>
      <DialogContent className="text-center">
        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "#000000",
            fontSize: "20px",
            marginBottom: 2,
            fontWeight: 500,
          }}
        >
          Do you want to apply these changes?
          <Box
            style={{
              borderRadius: "8px",
              boxShadow: "none",
              overflowY: "auto",
              maxHeight: "440px",
              border: "1px solid #000000",
              marginTop: "35px",
              backgroundColor: "#FBFCF8",
            }}
          >
            <Table
              style={{
                borderCollapse: "unset",
              }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#DDDDDD" }}>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      textAlign: "left",
                      borderBottom: "1px solid #000000",

                      padding: "8px",
                      color: "#000000",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    <b> Name</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",

                      padding: "8px",
                      color: "#000000",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    <b>Type</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",

                      padding: "8px",
                      color: "#000000",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    <b> X</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",

                      padding: "8px",
                      color: "#000000",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    <b>Y</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #C9C9C9",
                      borderBottom: "1px solid #000000",
                      color: "#000000",

                      padding: "8px",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#DDDDDD",
                    }}
                  >
                    <b>Z</b>
                  </TableCell>
                  <Tooltip title="Rigid transformation residuals">
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "0px solid #C9C9C9",
                        borderBottom: "1px solid #000000",

                        padding: "8px",
                        color: "#000000",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#DDDDDD",
                      }}
                    >
                      <b>Residual Error</b>
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              {savedCoordinate.map((coordinate, index) => (
                <TableBody key={"" + index}>
                  <TableRow>
                    <TableCell
                      align="center"
                      rowSpan="3"
                      style={{
                        borderRight: "1px solid #C9C9C9",
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <div className="flex items-center">
                        <div
                          className="flex items-center"
                          style={{ flexDirection: "column" }}
                        >
                          <div>
                            <b>
                              Name: {coordinate.name ? coordinate.name : ""}
                            </b>
                          </div>
                          <div>
                            <b>Code: {coordinate.code}</b>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      Control
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                      }}
                    >
                      {coordinate.control ? coordinate.control.x : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                      }}
                    >
                      {" "}
                      {coordinate.control ? coordinate.control.y : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                      }}
                    >
                      {" "}
                      {coordinate.control ? coordinate.control.z : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      rowSpan="3"
                      style={{
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      {Object.keys(transformationErrors).length != 0 &&
                      coordinate.name &&
                      transformationErrors[coordinate.name] != -1
                        ? transformationErrors[coordinate.name]
                        : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      Model
                    </TableCell>

                    <>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          padding: "8px",
                          color: "#000000",
                        }}
                      >
                        {coordinate.model ? coordinate.model.x : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          padding: "8px",
                          color: "#000000",
                        }}
                      >
                        {coordinate.model ? coordinate.model.y : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          padding: "8px",
                          color: "#000000",
                        }}
                      >
                        {coordinate.model ? coordinate.model.z : ""}
                      </TableCell>
                    </>
                  </TableRow>

                  <TableRow>
                    <Tooltip title="Offset between the control and model points">
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                          padding: "8px",
                          color: "#000000",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>Offset</p>
                      </TableCell>
                    </Tooltip>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      {getCoordinateError(coordinate, "x")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      {getCoordinateError(coordinate, "y")}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                        color: "#000000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      {getCoordinateError(coordinate, "z")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </Box>
        </DialogContentText>
      </DialogContent>
      <AlignToControlActions onYesClick={onYesClick} onNoClick={onNoClick} />
    </Dialog>
  );
}

const AlignToControlActions = ({ onYesClick, onNoClick }) => {
  return (
    <DialogActions className="flex items-center mb-32 justify-center">
      <Button
        style={{
          width: "105px",
          height: "54px",
          padding: "13px, 28px, 13px, 28px",
          borderRadius: "4px",
          margin: "20px",
          backgroundColor: "#58BEC9",
          color: "#ffffff",
          fontSize: "20px",
        }}
        onClick={() => {
          onYesClick();
        }}
      >
        Yes
      </Button>

      <Button
        style={{
          width: "105px",
          height: "54px",
          padding: "13px, 28px, 13px, 28px",
          borderRadius: "4px",
          margin: "20px",
          backgroundColor: "#9D9D9D",
          color: "#ffffff",
          fontSize: "20px",
        }}
        onClick={() => {
          onNoClick();
        }}
      >
        No
      </Button>
    </DialogActions>
  );
};
