import { hexToRgba, toHex } from "./ClassificationColorConvertor";


export const convertClassificationListToObject = (classificationList) => {
    var classificationObject = {};
    for (var i = 0; i < classificationList.length; i++) {
       
            classificationObject[classificationList[i].key  == -1 ? 'DEFAULT' : classificationList[i].key] = {
                visible: classificationList[i].isChecked,
                name: classificationList[i].classificationName,
                color: hexToRgba(classificationList[i].colorCode)
            }
        
    }

    return classificationObject;
}

export const convertClassificationObjectToList = (classifications) => {
    var keys = Object.keys(classifications);
    var classificationObject = [];
    for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var classification = classifications[key]
        classificationObject.push(
            {
                key: key == 'DEFAULT' ? -1 : key,
                classificationName: classification.name,
                isChecked: classification.visible,
                colorCode: toHex(classification.color),
                isError: false,
                isDuplicateKey: false
            }
        )
    }
    return classificationObject;
}

