/* eslint-disable no-unused-vars */
import { useMemo, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import {
  createSite,
  updateSite,
  updatecreateSiteState,
  updateupdateSiteState,
  setSiteFilesPath,
  getSiteFilesPath,
  getSiteEditInfo,
  getHorizontalDatumLookupData,
  getVerticalDatumLookupData,
  getCoordinateSystemLookupData,
} from "../store/siteSlice";
import SERVICE_STATUS from "app/services/ServiceStatus";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import SiteCreateOrEditForm from "./SiteCreateOrEditForm";

import { startUploadFile } from "../../upload/store/UploadSlice";
import UPLOAD_TYPE from "../../upload/UploadType";

import { daysInAYear, dayInAYear } from "app/main/apps/common/DateUtils";
import { loadAllAdminAccounts } from "../store/accountSlice";
import { getAllDevices } from "../store/deviceSlice";

import {
  getOrganizationProjects,
  setOrganizationProject,
} from "../store/projectSlice";

import {
  createProject,
  setUpdateCreateProjectState,
} from "../store/projectSlice";

const MAX_WIDTH = 320;
const MAX_HEIGHT = 180;
const QUALITY = 0.7;

const getSitePath = (path) => {
  const str = path.split("/");
  str.splice(0, 2);
  return str.join("/");
};

// eslint-disable-next-line complexity
function SiteCreateOrEdit(props) {
  const siteViewer = Object.keys(props).length == 0 ? false : props.siteViewer;
  const siteMenuViewer =
    Object.keys(props).length == 0 ? false : props.siteMenuViewer;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const hiddenSiteProfileImageInput = useRef();
  const siteId = Object.keys(props).length == 0 ? params.siteId : props.siteId;

  const { createSiteState, updateSiteState, siteFilesPath } = useSelector(
    ({ admin }) => admin.siteApp
  );
  const { organizationProject } = useSelector(({ admin }) => admin.projectApp);
  const { site, accountIds, scan } = useSelector(({ edit }) => edit.edit);
  const { role } = useSelector(({ auth }) => auth.user);
  const customer = useSelector(({ auth }) => auth.customer);
  const { projectFilePath, createProjectState } = useSelector(
    ({ admin }) => admin.projectApp
  );

  const [siteProfileImage, setSiteProfileImage] = useState(null);
  const [uploadSiteFiles, setUploadSiteFiles] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchParams] = useSearchParams();
  const [showProjectEdit, setShowProjectEdit] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [isEnableVerticalDatum, setIsEnableVerticalDatum] = useState(false);
  const [isAutomaticProjection, setIsAutomaticProjection] = useState(true);

  const [allValues, setAllValues] = useState({
    name: "",
    description: "",
    locationLat: "",
    locationLong: "",
    squareFootage: "",
    accounts: [],
    assetowner: "",
    constructiontype: "",
    orthoAvailable: false,
    contoursAvailable: false,
    isArchive: false,
    ownerOrganization: 0,
    sharedOrganizations: [],
    projectId: 0,
    baseStation: null,
  });

  useEffect(() => {
    dispatch(loadAllAdminAccounts());
    dispatch(getAllDevices());
  }, [dispatch]);

  const [horizontalDatums, setHorizontalDatums] = useState([]);
  const [verticalDatums, setVerticalDatums] = useState([]);
  const [coordinateSystems, setCoordinateSystems] = useState([]);

  const [horizontalDatumId, setHorizontalDatumId] = useState(null);
  const [verticalDatumId, setVerticalDatumId] = useState(null);
  const [coordinateSystemId, setCoordinateSystemId] = useState(null);

  useEffect(() => {
    getVerticalDatums();
    getHorizontalDatums();
    getCoordinateSystems();
  }, [dispatch]);

  useEffect(() => {
    if (
      siteId == undefined &&
      siteId == null &&
      horizontalDatums.length > 0 &&
      verticalDatums.length > 0 &&
      coordinateSystems.length > 0
    ) {
      setHorizontalDatumId(horizontalDatums[0].id);
      const tmpVerticalDatums = getDropDownVerticalDatums(
        horizontalDatums[0].id
      );
      if (tmpVerticalDatums.length > 0) {
        setVerticalDatumId(tmpVerticalDatums[0].id);
      }
    }
  }, [horizontalDatums, verticalDatums, coordinateSystems]);

  useEffect(() => {
    if (horizontalDatumId > 0 && horizontalDatums.length > 0) {
      const horizontalDatum = horizontalDatums.find(
        (x) => x.id === horizontalDatumId
      );
      setIsEnableVerticalDatum(horizontalDatum.isEnableVerticalDatum);
    }
  }, [horizontalDatumId, horizontalDatums]);

  const getDropDownVerticalDatums = (id) => {
    let tmpVerticalDatums = verticalDatums.filter(
      (x) => x.horizontalDatumId === id
    );
    if (tmpVerticalDatums.length === 0) {
      tmpVerticalDatums = verticalDatums.filter(
        (x) => x.horizontalDatumId == null
      );
    }
    return tmpVerticalDatums;
  };

  const filteredCoordinateSystems = useMemo(() => {
    return (
      coordinateSystems
        .filter((x) => x.horizontalDatumId === horizontalDatumId)
        ?.sort((a, b) => a.projectionName.localeCompare(b.projectionName)) || []
    );
  }, [horizontalDatumId, coordinateSystems]);

  const getVerticalDatums = () => {
    const getVerticalDatumsApi = getVerticalDatumLookupData();
    Promise.resolve(getVerticalDatumsApi)
      .then((response) => {
        if (response.status === 200) {
          setVerticalDatums(response.data);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  const getHorizontalDatums = () => {
    const getHorizontalDatumApi = getHorizontalDatumLookupData();
    Promise.resolve(getHorizontalDatumApi)
      .then((response) => {
        if (response.status === 200) {
          setHorizontalDatums(
            response.data.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  const getCoordinateSystems = () => {
    const getCoordinateSystemApi = getCoordinateSystemLookupData();
    Promise.resolve(getCoordinateSystemApi)
      .then((response) => {
        if (response.status === 200) {
          setCoordinateSystems(response.data);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve data", error);
      });
  };

  useEffect(() => {
    const _siteId = parseInt(siteId, 10);
    if (_siteId && site.id === _siteId) {
      dispatch(getSiteFilesPath(siteId));
    }
  }, [site, siteId, dispatch]);

  useEffect(() => {
    if (siteId) {
      let _siteId = parseInt(siteId, 10);
      if (site && site.id === _siteId) {
        const siteData = {
          name: site.name,
          description: site.description,
          locationLat: site.locationLat,
          locationLong: site.locationLong,
          squareFootage: site.squareFootage,
          accounts: accountIds,
          assetowner: site.assetOwner,
          constructiontype: site.constructionType,
          projectionName: site.projectionName,
          orthoAvailable: site.orthoAvailable,
          contoursAvailable: site.contoursAvailable,
          isArchive: site.isArchive,
          ownerOrganization: 0,
          sharedOrganizations: [],
          projectId: 0,
        };
        setIsAutomaticProjection(site.automaticProjection);
        setAllValues(siteData);
        setStartDate(site.startDate);
        setCompletionDate(site.completionDate);
        setEpoch(site.epoch);
        setHorizontalDatumId(site.horizontalDatumId);
        setVerticalDatumId(site.verticalDatumId);
        setCoordinateSystemId(site.coordinateSystemId);
        const siteEditData = getSiteEditInfo(siteId);

        Promise.resolve(siteEditData).then((response) => {
          if (response.status == 200) {
            const projectId = response.data.projectId;
            const accountSites = response.data.accountSites;
            const ownerOrganization = accountSites.find((x) => x.isOwner);
            const sharedOrganizations =
              accountSites.filter((x) => !x.isOwner).map((x) => x.accountId) ||
              [];

            setAllValues({
              ...allValues,
              ["projectId"]: projectId,
              ["ownerOrganization"]: ownerOrganization
                ? ownerOrganization.accountId
                : 0,
              ["sharedOrganizations"]: sharedOrganizations,
              name: site.name,
              description: site.description,
              locationLat: site.locationLat,
              locationLong: site.locationLong,
              squareFootage: site.squareFootage,
              accounts: accountIds,
              assetowner: site.assetOwner,
              constructiontype: site.constructionType,
              projectionName: site.projectionName,
              orthoAvailable: site.orthoAvailable,
              contoursAvailable: site.contoursAvailable,
              isArchive: site.isArchive,
            });
            if (!role.includes("admin")) {
              if (customer.accountCustomers.length > 0) {
                if (
                  ownerOrganization.accountId !=
                  customer.accountCustomers[0].accountId
                ) {
                  setShowProjectEdit(false);
                }
              } else {
                setShowProjectEdit(false);
              }
            }
          }
        });
      } else {
        handleOnCancelButton();
      }
    }
  }, []);

  useEffect(() => {
    if (
      role &&
      ["organisationadmin", "projectmanager", "user"].includes(role[0])
    ) {
      setAllValues({ ...allValues, ownerOrganization: 0 });
      dispatch(getOrganizationProjects(0));
    }
  }, [role]);

  useEffect(() => {
    if (organizationProject?.length === 0) {
      setAllValues({ ...allValues, projectId: 0 });
    } else if (!projectFilePath["id"]) {
      const defaultProject = organizationProject?.filter((x) => x.isDefault);
      if (defaultProject?.length > 0) {
        setAllValues({ ...allValues, projectId: defaultProject[0].id });
      }
    }
  }, [organizationProject]);

  useEffect(() => {
    if (siteId == null || siteId == undefined) {
      if (role.includes("admin")) {
        const defaultAccount = searchParams.get("scanId")
          ? scan.ownerOrganisation
          : 0;
        setAllValues({ ...allValues, ["ownerOrganization"]: defaultAccount });
      }
    }
    dispatch(setSiteFilesPath({}));
  }, []);

  useEffect(() => {
    dispatch(setOrganizationProject([]));
    dispatch(getOrganizationProjects(allValues.ownerOrganization));
    const sharedOrganizations = [...allValues.sharedOrganizations];
    setAllValues({
      ...allValues,
      ["sharedOrganizations"]: sharedOrganizations.filter(
        (x) => x != allValues.ownerOrganization
      ),
      ["projectId"]: 0,
    });
  }, [allValues.ownerOrganization]);

  //single state onchange
  const handleOnChange = (e) => {
    try {
      if (e.target.name == "locationLat" || e.target.name == "locationLong") {
        const re = /^[-]?\d*\.?\d{0,9}$/;
        if (re.test(e.target.value)) {
          setAllValues({ ...allValues, [e.target.name]: e.target.value });
        }
      } else if (e.target.name == "squareFootage") {
        const re = /^[0-9\b]+$/;
        if (e.target.value == "" || re.test(e.target.value)) {
          setAllValues({ ...allValues, [e.target.name]: e.target.value });
        }
      } else if (e.target.name == "projectId") {
        if (e.target.value) {
          setAllValues({ ...allValues, [e.target.name]: e.target.value });
        }
      } else {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [isValid, setIsValid] = useState(false);
  const [isEpochValid, setIsEpochValid] = useState(true);
  const [completionDate, setCompletionDate] = useState(new Date());
  const [epoch, setEpoch] = useState("Current");

  if (
    createSiteState == SERVICE_STATUS.fulfilled ||
    updateSiteState == SERVICE_STATUS.fulfilled
  ) {
    try {
      dispatch(updatecreateSiteState(""));
      dispatch(updateupdateSiteState(""));
      if (Object.keys(siteFilesPath).length > 0) {
        for (let i = 0; i < uploadSiteFiles.length; i++) {
          const uploadingFile = uploadSiteFiles[i].file;
          if (uploadingFile != null && uploadingFile != undefined) {
            const uploadData = {
              typeId: siteFilesPath["id"],
              name: allValues.name,
              file: uploadingFile,
              isPrimary: false,
              type: UPLOAD_TYPE.sitefiles,
              uploadKey: `${siteFilesPath[UPLOAD_TYPE.sitefiles]}${
                uploadingFile.path.includes("/")
                  ? getSitePath(uploadingFile.path)
                  : uploadingFile.path
              }`,
            };
            dispatch(startUploadFile(uploadData));
          }
        }
      }
      dispatch(setLoaderProgress(false));
      if (window.location.pathname.includes("/apps/site/sitecreation")) {
        swal({
          title: "Successfully Created Site",
          text: "Do you want to create a scan for this site?",
          icon: "success",
          buttons: {
            Yes: {
              text: "Yes",
              value: "Yes",
            },
            No: {
              text: "No",
              value: "No",
            },
          },
        }).then((value) => {
          switch (value) {
            case "Yes":
              navigate(`/apps/scan/scancreation/${siteFilesPath.id}`);
              break;
            case "No":
              navigate(`/apps/sites/${siteFilesPath.id}`);
              break;
            default:
              navigate(`/apps/sites/${siteFilesPath.id}`);
          }
        });
      } else {
        if (siteViewer) {
          props.onUpdateSite();
        }
        if (siteMenuViewer) {
          props.handleOnAddSiteClose();
        }
        swal(
          "",
          siteId == null || siteId == undefined
            ? "Site successfully created"
            : "Site successfully updated",
          "success"
        ).then(() => {
          if (searchParams.get("redirect")) {
            const redirect = searchParams.get("redirect");
            // -1 is new site, -2 is prior to clicking notification
            navigate(decodeURIComponent(redirect) + "&redirect=-2");
          } else if (!siteViewer && !siteMenuViewer) {
            handleOnCancelButton();
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  if (
    createSiteState == SERVICE_STATUS.rejected ||
    updateSiteState == SERVICE_STATUS.rejected
  ) {
    try {
      dispatch(updatecreateSiteState(""));
      dispatch(updateupdateSiteState(""));
      dispatch(setLoaderProgress(false));
      swal("", "Something went wrong. Please try again", "error");
    } catch (error) {
      console.error(error);
    }
  }

  const isOrgValid = useMemo(() => {
    try {
      if (!allValues.name) return false;

      if (!isAutomaticProjection) {
        if (
          [horizontalDatumId, verticalDatumId, coordinateSystemId].some(
            (id) => id == null
          )
        ) {
          return false;
        }
      }

      if (role.includes("admin") && allValues.ownerOrganization <= 0) {
        return false;
      }

      if (!isAutomaticProjection && epoch.toLowerCase() !== "current") {
        const epochRegex = /^[0-9]{4}(?:[.][0-9]{2})?$/;
        const isValid = epochRegex.test(epoch);
        setIsEpochValid(isValid);
        return isValid;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [
    allValues.name,
    allValues.ownerOrganization,
    isAutomaticProjection,
    horizontalDatumId,
    verticalDatumId,
    coordinateSystemId,
    role,
    epoch,
    setIsEpochValid,
  ]);

  // eslint-disable-next-line complexity
  const handleCreateOrUpdate = () => {
    try {
      setIsEpochValid(true);
      if (isOrgValid) {
        setIsValid(false);
        if (siteId) {
          let updateNewSite = {
            ...site,
            name: allValues.name,
            description: allValues.description,
            locationLat: allValues.locationLat,
            locationLong: allValues.locationLong,
            startDate: startDate,
            completionDate: completionDate,
            squareFootage: allValues.squareFootage,
            accountIds: allValues.accounts,
            assetOwner: allValues.assetowner,
            constructionType: allValues.constructiontype,
            orthoAvailable: allValues.orthoAvailable,
            contoursAvailable: allValues.contoursAvailable,
            isArchive: allValues.isArchive,
            verticalDatumId: isAutomaticProjection
              ? verticalDatums[0].id
              : verticalDatumId,
            horizontalDatumId: isAutomaticProjection ? null : horizontalDatumId,
            epoch: isAutomaticProjection ? horizontalDatums[0].epoch : epoch,
            automaticProjection: isAutomaticProjection,
            ownerOrganization: allValues.ownerOrganization,
            sharedOrganizations: allValues.sharedOrganizations,
            projectId: allValues.projectId,
            isFromEditPage: true,
            coordinateSystemId: isAutomaticProjection
              ? null
              : coordinateSystemId,
          };
          if (siteProfileImage != null) {
            let fileByteArray = [];
            const array = new Uint8Array(siteProfileImage.data);
            fileByteArray.push(...array);
            updateNewSite = {
              ...updateNewSite,
              fileArray: fileByteArray,
              fileName: siteProfileImage.name,
              fileContentType: siteProfileImage.type,
            };
          }
          if (siteMenuViewer) {
            const siteFilesData = {
              uploadSiteFiles: uploadSiteFiles,
            };
            props.handleUpdateSite({
              siteData: updateNewSite,
              siteFilesData: siteFilesData,
            });
          } else {
            dispatch(setLoaderProgress(true));
            dispatch(updateSite(updateNewSite));
          }
        } else {
          let data = {
            name: allValues.name,
            description: allValues.description,
            locationLat: allValues.locationLat,
            locationLong: allValues.locationLong,
            startDate: startDate,
            completionDate: completionDate,
            squareFootage: allValues.squareFootage,
            accountIds: allValues.accounts,
            assetOwner: allValues.assetowner,
            constructionType: allValues.constructiontype,
            isDeleted: false,
            orthoAvailable: allValues.orthoAvailable,
            contoursAvailable: allValues.contoursAvailable,
            isArchive: false,
            verticalDatumId: isAutomaticProjection
              ? verticalDatums[0].id
              : verticalDatumId,
            horizontalDatumId: isAutomaticProjection ? null : horizontalDatumId,
            epoch: isAutomaticProjection ? horizontalDatums[0].epoch : epoch,
            automaticProjection: isAutomaticProjection,
            scanId: searchParams.get("scanId")
              ? searchParams.get("scanId")
              : null,
            ownerOrganization: allValues.ownerOrganization,
            sharedOrganizations: allValues.sharedOrganizations,
            projectId: allValues.projectId,
            coordinateSystemId: isAutomaticProjection
              ? null
              : coordinateSystemId,
          };
          if (siteProfileImage != null) {
            let fileByteArray = [];
            let array = new Uint8Array(siteProfileImage.data);
            for (const a of array) {
              fileByteArray.push(a);
            }
            data = {
              ...data,
              fileArray: fileByteArray,
              fileName: siteProfileImage.name,
              fileContentType: siteProfileImage.type,
            };
          }
          if (siteMenuViewer) {
            let siteFilesData = {
              uploadSiteFiles: uploadSiteFiles,
            };
            props.handleCreateSite({
              siteData: data,
              siteFilesData: siteFilesData,
            });
          } else {
            dispatch(setLoaderProgress(true));
            dispatch(createSite(data));
          }
        }
      } else {
        setIsValid(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSiteProfileImageChange = (e) => {
    try {
      const MIME_TYPE = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      const img = new Image();
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        URL.revokeObjectURL(objectUrl);
        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(
          (blob) => {
            blob.name = fileName;
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = (evt) => {
              if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result;
                const imageData = {
                  data: arrayBuffer,
                  name: fileName,
                  type: blob.type,
                };
                setSiteProfileImage(imageData);
              }
            };
          },
          MIME_TYPE,
          QUALITY
        );
      };
      img.src = objectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }

  const [modelTitle, setModelTitle] = useState("");
  const [modelScanPath, setModelScanPath] = useState("");

  const handleClose = () => {
    props.handleClose();
  };

  const handleOnCancelButton = () => {
    if (window.history.state.idx == 0) {
      navigate("/apps/dashboards/sites");
    } else {
      navigate(-1);
    }
  };

  const handleOnChangeEpochDate = (date) => {
    const days = daysInAYear(date.getFullYear());
    const day = dayInAYear(date);
    const decimalYear = date.getFullYear() + day / days;
    setEpoch(decimalYear.toFixed(2));
  };

  const handleOnChangeEpochMenuValue = (value) => {
    setEpoch(value);
  };

  const handleOnChangeEpochText = (value) => {
    setEpoch(value);
  };

  const onClearEpochText = () => {
    setEpoch("Current");
  };

  const handleIsAutomaticProjection = (e) => {
    setIsAutomaticProjection(e.target.value === "true");
    if (e.currentTarget.value === "false") {
      if (
        horizontalDatums.length > 0 &&
        verticalDatums.length > 0 &&
        coordinateSystems.length > 0
      ) {
        setHorizontalDatumId(horizontalDatums[0].id);
        const tmpVerticalDatums = getDropDownVerticalDatums(
          horizontalDatums[0].id
        );
        if (tmpVerticalDatums.length > 0) {
          setVerticalDatumId(tmpVerticalDatums[0].id);
        }
      }
    }
  };

  // project dropdown
  const [showProjectCreateDialog, setShowProjectCreateDialog] = useState(false);
  const [uploadProjectFiles, setUploadProjectFiles] = useState([]);
  const [projectData, setProjectData] = useState({});

  const handleOnAddProjectClick = () => setShowProjectCreateDialog(true);
  const handleOnProjectCreateSuccess = () => dispatch(setLoaderProgress(true));

  const handleOnCreateProject = (data) => {
    setProjectData(data.data);
    setUploadProjectFiles(data.uploadProjectFiles);

    dispatch(setLoaderProgress(true));
    setShowProjectCreateDialog(false);
    const requestData = {
      data: data.data,
      successCallBack: handleOnProjectCreateSuccess,
    };
    if (siteMenuViewer) {
      setShowLoader(true);
    }

    dispatch(createProject(requestData));
  };

  const handleOnCancelProject = () => {
    setShowProjectCreateDialog(false);
  };

  if (createProjectState == SERVICE_STATUS.fulfilled) {
    dispatch(setUpdateCreateProjectState(""));
    if (projectFilePath) {
      for (let i = 0; i < uploadProjectFiles.length; i++) {
        const uploadingFile = uploadProjectFiles[i].file;
        if (uploadingFile != null && uploadingFile != undefined) {
          const uploadData = {
            typeId: projectFilePath["id"],
            name: projectData.name,
            file: uploadingFile,
            isPrimary: false,
            type: UPLOAD_TYPE.projectfiles,
            uploadKey: `${projectFilePath[UPLOAD_TYPE.projectfiles]}${
              uploadingFile.path.includes("/")
                ? getSitePath(uploadingFile.path)
                : uploadingFile.path
            }`,
          };
          dispatch(startUploadFile(uploadData));
        }
      }
    }
    dispatch(getOrganizationProjects(allValues.ownerOrganization));
    setAllValues({ ...allValues, projectId: projectFilePath["id"] });
    setShowLoader(false);
    dispatch(setLoaderProgress(false));
    setShowProjectCreateDialog(false);
  }

  if (createProjectState == SERVICE_STATUS.rejected) {
    dispatch(setUpdateCreateProjectState(""));
    setShowLoader(false);
    dispatch(setLoaderProgress(false));
    setShowProjectCreateDialog(false);
  }

  // base station section
  const [showBaseStationCreateBox, setShowBaseStationCreateBox] =
    useState(false);
  const [uploadBaseStationFiles, setUploadBaseStationFiles] = useState([]);
  const [baseStationData, setBaseStationData] = useState({});

  const handleOnAddBaseStationClick = () => setShowBaseStationCreateBox(true);
  const handleOnBaseStationCreateSuccess = () =>
    dispatch(setLoaderProgress(true));

  const handleOnchangeCoordinateSystem = (e) => {
    if (e.target.name === "horizontalDatum") {
      setHorizontalDatumId(e.target.value);
      let horizontalDatum = horizontalDatums.find(
        (x) => x.id == e.target.value
      );
      if (verticalDatums.length > 0) {
        const tmpVerticalDatums = getDropDownVerticalDatums(e.target.value);
        if (tmpVerticalDatums.length > 0) {
          setVerticalDatumId(tmpVerticalDatums[0].id);
        }
      }
      if (horizontalDatum) {
        setIsEnableVerticalDatum(horizontalDatum.isEnableVerticalDatum);
        setEpoch(horizontalDatum.epoch);
      }
      setCoordinateSystemId(null);
    }
    if (e.target.name == "verticalDatum") {
      setVerticalDatumId(e.target.value);
    }
    if (e.target.name == "projectionName") {
      setCoordinateSystemId(e.target.value);
    }
  };

  const filteredVerticalDatums = useMemo(() => {
    return getDropDownVerticalDatums(horizontalDatumId) || [];
    // verticalDatums is a dependency of getDropDownVerticalDatums()
  }, [horizontalDatumId, verticalDatums]);

  return (
    <SiteCreateOrEditForm
      allValues={allValues}
      completionDate={completionDate}
      coordinateSystemId={coordinateSystemId}
      epoch={epoch}
      filteredCoordinateSystems={filteredCoordinateSystems}
      filteredVerticalDatums={filteredVerticalDatums}
      handleClose={handleClose}
      handleCreateOrUpdate={handleCreateOrUpdate}
      handleIsAutomaticProjection={handleIsAutomaticProjection}
      handleOnAddBaseStationClick={handleOnAddBaseStationClick}
      handleOnAddProjectClick={handleOnAddProjectClick}
      handleOnCancelButton={handleOnCancelButton}
      handleOnCancelProject={handleOnCancelProject}
      handleOnChange={handleOnChange}
      handleOnchangeCoordinateSystem={handleOnchangeCoordinateSystem}
      handleOnChangeEpochDate={handleOnChangeEpochDate}
      handleOnChangeEpochMenuValue={handleOnChangeEpochMenuValue}
      handleOnChangeEpochText={handleOnChangeEpochText}
      handleOnCreateProject={handleOnCreateProject}
      handleSiteProfileImageChange={handleSiteProfileImageChange}
      hiddenSiteProfileImageInput={hiddenSiteProfileImageInput}
      horizontalDatumId={horizontalDatumId}
      horizontalDatums={horizontalDatums}
      isAutomaticProjection={isAutomaticProjection}
      isEnableVerticalDatum={isEnableVerticalDatum}
      isEpochValid={isEpochValid}
      isOrgValid={isOrgValid}
      isValid={isValid}
      modelScanPath={modelScanPath}
      modelTitle={modelTitle}
      onClearEpochText={onClearEpochText}
      organizationProject={organizationProject}
      params={params}
      setCompletionDate={setCompletionDate}
      setStartDate={setStartDate}
      showLoader={showLoader}
      showProjectCreateDialog={showProjectCreateDialog}
      showProjectEdit={showProjectEdit}
      site={site}
      siteId={siteId}
      siteMenuViewer={siteMenuViewer}
      siteProfileImage={siteProfileImage}
      siteViewer={siteViewer}
      startDate={startDate}
      verticalDatumId={verticalDatumId}
    />
  );
}
export default SiteCreateOrEdit;
