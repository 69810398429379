import React from 'react';
import styled from "@emotion/styled";
import ToggleSwitch from 'app/shared-components/toggleSwitch/ToggleSwitch';

const Section = styled.div`
  padding-left: 20px;
  display: flex;
  padding-bottom: 10px;
`;


function CommonToggle(props) {

    const { toggleChecked, handleOnToggleChange } = props;

    return (
        <div>
            <div className='flex flex-col'>
                <Section style={{ paddingLeft: '15px' }}>
                    <div>
                        <ToggleSwitch
                            checked={toggleChecked}
                            onToggleChange={handleOnToggleChange}
                            yes={'Show'}
                            no={'Hide'}
                        >
                        </ToggleSwitch>
                    </div>
                </Section>
            </div>
        </div>
    )
}

export default CommonToggle;