import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Checkbox,
  ListItemText,
  ListItemButton,
  ListItem,
  Tooltip,
  Grid,
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ToggleSwitch from "app/shared-components/toggleSwitch/ToggleSwitch";
import Slider from "@mui/material/Slider";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

const useStyles = makeStyles({
  text: {
    fontWeight: "bold",
  },
});

const PrettoSlider = styled(Slider)((props) => ({
  color: props.isEnable ? "#6CCCD7" : "#A2A2A2",
  height: 9,
  // width: 120,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#6CCCD7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

const TypographyStyles = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #565656;
`;
const ResetIcon = styled.div`
  padding: 0px;
  border: 2px solid #565656;
  border-radius: 20px;
  margin: 0px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#ffffff" : "#565656")};
`;

const Reset = styled.div`
  padding-left: 10px;
`;

function PointSettings(props) {
  const {
    isFixed,
    onChangeFixed,
    onChangePointSize,
    pointSize,
    useHighQuality,
    onToggleUseHighQuality,
    onChangeBrightness,
    brightness,
    enableEyeDomeLighting,
    edlRadius,
    edlStrength,
    onToggleEyeDomeLighting,
    isOrthographicCamera,
    onChangeEdlStrength,
    onChangeEdlRadius,
    gamma,
    onChangeGamma,
    contrast,
    onChangeContrast,
    onResetEDLSettings,
  } = props;

  const classes = useStyles();

  const [pointSettingsMinimize, setPointSettingsMinimize] = useState(false);

  const handleOnChangeAccordion = () => {
    setPointSettingsMinimize(!pointSettingsMinimize);
    if (pointSettingsMinimize) {
      MixPanelIntegration.EventClicks("PointSettings Close Click");
    } else {
      MixPanelIntegration.EventClicks("PointSettings Expand Click");
    }
  };

  return (
    <Accordion
      expanded={pointSettingsMinimize}
      sx={{
        "&.MuiAccordion-root.Mui-expanded": {
          margin: "1px 0 0px 0px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
        expandIcon={
          pointSettingsMinimize ? (
            <IconButton
              // eslint-disable-next-line no-unused-vars
              onClick={(e) => {
                handleOnChangeAccordion();
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon style={{ color: "#BBBBBB" }} />{" "}
            </IconButton>
          ) : (
            <IconButton
              // eslint-disable-next-line no-unused-vars
              onClick={(e) => {
                handleOnChangeAccordion();
              }}
            >
              <AddBoxOutlinedIcon style={{ color: "#BBBBBB" }} />
            </IconButton>
          )
        }
        aria-controls="panel2bh-content"
        id="panel2"
        className="flex items-center"
      >
        <TypographyStyles>Point Settings</TypographyStyles>
        {pointSettingsMinimize && (
          <Reset style={{ marginRight: "0px" }}>
            <Tooltip title="Reset">
              <ResetIcon onClick={onResetEDLSettings} id="clipping">
                <RestartAltIcon />
              </ResetIcon>
            </Tooltip>
          </Reset>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          "& .MuiAccordionDetails-root": {
            padding: "8px 10px 16px",
          },
        }}
      >
        <div>
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col w-full ml-4">
              <div className="flex flex-col w-full">
                <ListItem disablePadding>
                  <ListItemButton
                    className="py-0 pl-8"
                    style={{ pointerEvents: "none" }}
                  >
                    <ListItemText
                      classes={{ primary: classes.text }}
                      primary="Point Size"
                    />
                  </ListItemButton>
                </ListItem>
                <div className="ml-8">
                  <PrettoSlider
                    step={0.01}
                    max={3}
                    min={0}
                    onChange={(e) => onChangePointSize(e.target.value)}
                    value={pointSize}
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    isEnable={true}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full pl-20 mr-16">
              <ToggleSwitch
                checked={!isFixed}
                onToggleChange={onChangeFixed}
                yes={"Adaptive"}
                no={"Fixed"}
              ></ToggleSwitch>
            </div>
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!isOrthographicCamera && (
                  <div className="flex flex-row w-full items-center">
                    <Checkbox
                      checked={useHighQuality}
                      onChange={(e) => onToggleUseHighQuality(e)}
                      className="mr-8"
                      sx={{
                        color: "#6CCCD7",
                        "&.Mui-checked": {
                          color: "#6CCCD7",
                        },
                      }}
                    />
                    <span>
                      <b>High Quality</b>
                    </span>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "0" }} className="mb-16">
                <div className="flex flex-row w-full items-center">
                  <Checkbox
                    checked={enableEyeDomeLighting}
                    onChange={(e) => onToggleEyeDomeLighting(e)}
                    className="mr-8"
                    sx={{
                      color: "#6CCCD7",
                      "&.Mui-checked": {
                        color: "#6CCCD7",
                      },
                    }}
                  />
                  <span>
                    <b>Point Outline</b>
                  </span>
                </div>
              </Grid>
            </Grid>
          </Box>

          <div className="flex flex-row justify-between pr-8 mb-8">
            <div className="flex flex-col w-full px-16">
              <div className="flex flex-col w-full">
                <div
                  className="flex items-center"
                  style={{ opacity: !enableEyeDomeLighting ? 0.5 : 1 }}
                >
                  <span>
                    <b>Thickness</b>
                  </span>
                </div>
              </div>
              <div
                style={{
                  pointerEvents: !enableEyeDomeLighting ? "none" : "auto",
                }}
              >
                <PrettoSlider
                  step={0.01}
                  max={4.0}
                  min={1.0}
                  onChange={(e) => onChangeEdlRadius(e.target.value)}
                  value={edlRadius}
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  isEnable={enableEyeDomeLighting}
                />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="flex flex-col w-full">
                <div
                  className="flex items-center ml-16"
                  style={{ opacity: !enableEyeDomeLighting ? 0.5 : 1 }}
                >
                  <span>
                    <b>Range</b>
                  </span>
                </div>
              </div>
              <div className="flex flex-row w-full items-center ml-16">
                <PrettoSlider
                  style={{
                    pointerEvents: !enableEyeDomeLighting ? "none" : "auto",
                  }}
                  step={0.01}
                  max={5.0}
                  min={0.0}
                  onChange={(e) => onChangeEdlStrength(e.target.value)}
                  value={edlStrength}
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  isEnable={enableEyeDomeLighting}
                  className="mr-16"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full px-16 pr-8">
            <div className="flex flex-col w-full">
              <div className="flex items-center">
                <span>
                  <b>Brightness</b>
                </span>
              </div>
            </div>
            <div className="mb-8">
              <PrettoSlider
                min={-1}
                max={1}
                step={0.01}
                onChange={(e) => onChangeBrightness(e.target.value)}
                value={brightness}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                isEnable={true}
              />
            </div>
          </div>

          <div className="flex flex-col w-full px-16 pr-8">
            <div className="flex flex-col w-full">
              <div className="flex items-center">
                <span>
                  <b>Contrast</b>
                </span>
              </div>
            </div>
            <div className="mb-8">
              <PrettoSlider
                min={-1}
                max={1}
                step={0.01}
                onChange={(e) => onChangeContrast(e.target.value)}
                value={contrast}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                isEnable={true}
              />
            </div>
          </div>
          <div className="flex flex-col w-full px-16 pr-8">
            <div className="flex flex-col w-full">
              <div className="flex items-center">
                <span>
                  <b>Gamma</b>
                </span>
              </div>
            </div>
            <div>
              <PrettoSlider
                min={0}
                max={4}
                step={0.01}
                onChange={(e) => onChangeGamma(e.target.value)}
                value={gamma}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                isEnable={true}
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default PointSettings;
