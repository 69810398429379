import * as turf from "@turf/turf";
import MapBoxCommon from "../../MapBoxCommon";

class MapBoxDistanceMeasure extends MapBoxCommon {
    constructor(viewer, lat, lng, panelRef, unitLength, checkPointValid, elevation, isDisableMoving) {
        super();
        this.lat = lat;
        this.lng = lng;
        this.viewer = viewer;
        this.checkPointValid = checkPointValid;
        this.unitLength = 'm';
        if (unitLength) {
            this.unitLength = unitLength;
        }
        this.panelRef = panelRef;
        this.canvas = this.viewer.getCanvasContainer();
        this.styleLoadEvent = this.onStyleLoad.bind(this);
        this.coords = {
            lng: lng,
            lat: lat
        }
        this.pointClouds = [];

        this.lineId = this.createUUID();
        this.initialPointId = this.createUUID();

        this.currentMovingPointId = this.initialPointId;

        this.addLine(viewer, this.lineId);
        this.addPointLayer(this.lng, this.lat, this.initialPointId);

        this.distancePoints = [];
        this.distanceLabels = [];
        this.addDistancePoint(this.lng, this.lat, this.initialPointId, elevation);
        if(!isDisableMoving){
            var pointId = this.addNewPoint(this.lng, this.lat);
            this.startMoveingPoint(pointId);
        }
       

    }

    addNewPoint(lng, lat) {
        var pointId = this.createUUID();
        this.addPointLayer(lng, lat, pointId);
        this.addDistancePoint(lng, lat, pointId);
        this.addDistanceLabel(lng, lat);
        return pointId;
    }

    startMoveingPoint(id) {
        this.currentMovingPointId = id;
        let onMouseMove = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
                this.onMouseMove(coords.lng, coords.lat);
            }

        }

        let onMouseLeave = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('click', onClick);
            this.viewer.off('contextmenu', onRigthClick);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
        }

        let onClick = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                var pointId = this.addNewPoint(coords.lng, coords.lat)
                this.viewer.off('mousemove', onMouseMove);
                this.viewer.off('click', onClick);
                this.viewer.off('contextmenu', onRigthClick);
                this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
                this.startMoveingPoint(pointId);


            }
        }

        let onRigthClick = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('click', onClick);
            this.viewer.off('contextmenu', onRigthClick);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
            if(this.distancePoints.length > 1){
                this.removePoint(this.viewer, this.distancePoints[this.distancePoints.length -1].id);
                this.distancePoints.splice(this.distancePoints.length -1, 1);
                this.removeLabel(this.distanceLabels[this.distanceLabels.length-1])
                this.distanceLabels.splice(this.distanceLabels.length-1, 1);
            }
            this.update();
           

        }

        this.viewer.on('mousemove', onMouseMove);
        this.viewer.on('click', onClick);
        this.viewer.once('contextmenu', onRigthClick);
        this.panelRef.current.addEventListener('mouseleave', onMouseLeave);

    }


    addDistancePoint(lng, lat, id, elevation) {
        var distancePoint = {
            id: id,
            lng: lng,
            lat: lat,
            elevation: elevation ? elevation : 0
        }

        this.distancePoints.push(distancePoint);

    }

    updateDistancePoint(lng, lat, id) {
        var distancePointIndex = this.distancePoints.findIndex(x => x.id == id);
        if (distancePointIndex != -1) {
            this.distancePoints[distancePointIndex].lng = lng;
            this.distancePoints[distancePointIndex].lat = lat;
            this.distancePoints[distancePointIndex].elevation = 0;
        }
    }

    addDistanceLabel(lng, lat) {
        var labelMarker = this.addLabel(this.viewer, lng, lat);
        this.distanceLabels.push(labelMarker);
    }

    onStyleLoad(e) {
        this.addLine(this.viewer, this.lineId);
        this.distancePoints.forEach(distancePoint => {
            this.addPointLayer(distancePoint.lng, distancePoint.lat, distancePoint.id);
        });
        this.update();
    }

    addPointLayer(lng, lat, id) {
        let onMouseMove = (e) => {
            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;

                this.onMouseMove(coords.lng, coords.lat);
            }

        }
        let onMouseUp = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);

            const coords = e.lngLat;
            if (this.checkPointValid(coords.lng, coords.lat, this.pointClouds)) {
                this.coords = coords;
            }
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }
        let onMouseLeave = (e) => {
            this.viewer.off('mousemove', onMouseMove);
            this.viewer.off('mouseup', onMouseUp);
            this.panelRef.current.removeEventListener('mouseleave', onMouseLeave);
            this.onMouseUp(this.coords.lng, this.coords.lat);
        }

        this.addPoint(this.viewer, lng, lat, id);
        this.viewer.on('mouseenter', id, () => {
            this.canvas.style.cursor = 'move';
        });

        this.viewer.on('mouseleave', id, () => {
            this.canvas.style.cursor = '';
        });

        this.viewer.on('mousedown', id, (e) => {
            e.preventDefault();
            this.canvas.style.cursor = 'move';
            this.currentMovingPointId = id;//e.features[0].layer.id;
            this.viewer.on('mousemove', onMouseMove);
            this.panelRef.current.addEventListener('mouseleave', onMouseLeave);
            this.viewer.once('mouseup', onMouseUp);
        });
    }



    onMouseMove(lng, lat) {
        this.updatePoint(this.viewer, lng, lat, this.currentMovingPointId);
        this.updateDistancePoint(lng, lat, this.currentMovingPointId);
        this.update();
    }

    onMouseUp(lng, lat) {
        this.updatePoint(this.viewer, lng, lat, this.currentMovingPointId);
        this.updateDistancePoint(lng, lat, this.currentMovingPointId);
        this.update();
    }

    setUnitLength(unitLength) {
        this.unitLength = unitLength;
        this.update();
    }

    setPointClouds(pointClouds) {
        this.pointClouds = pointClouds
    }


    update() {
        var lngLats = [];
        this.distancePoints.forEach(distancePoint => {
            var lngLat = [distancePoint.lng, distancePoint.lat];
            lngLats.push(lngLat);
        });
        this.updateLine(this.viewer, lngLats, this.lineId);

        for (var i = 0; i <= this.distancePoints.length - 2; i++) {
            let firstPoint = this.distancePoints[i];
            let secondPoint = this.distancePoints[i + 1];
            var features = turf.points([
                [firstPoint.lng, firstPoint.lat],
                [secondPoint.lng, secondPoint.lat]
            ]);
            var centerPoint = turf.center(features);
            this.distanceLabels[i].setLngLat(centerPoint.geometry.coordinates);

            var from = turf.point([firstPoint.lng, firstPoint.lat]);
            var to = turf.point([secondPoint.lng, secondPoint.lat]);
            var options = { units: 'miles' };
            var distance = turf.distance(from, to, options);

            var element = this.distanceLabels[i].getElement().getElementsByClassName('labelTextMarker');
            if (element.length > 0) {
                var length = 0;
                if (this.unitLength == 'm') {
                    length = distance * 1609.34;
                }
                else if (this.unitLength == 'ft') {
                    length = distance * 5280;
                }
                element[0].innerHTML = `${length.toFixed(2)}${this.unitLength}`;
            }
        }
    }

    remove() {
        this.distancePoints.forEach(distancePoint => {
            this.removePoint(this.viewer, distancePoint.id);
        });
        this.distancePoints = [];
        this.removeLine(this.viewer, this.lineId);
        this.lineId = '';
        this.distanceLabels.forEach(distanceLabel => {
            this.removeLabel(distanceLabel)
        });
        this.distanceLabels = [];
    }

    removePointCloudPoints(scanPointClouds) {
        this.distancePoints = this.distancePoints.filter(point => {
            if (!this.checkPointValid(point.lng, point.lat, scanPointClouds)) {
                this.removePoint(this.viewer, point.id);
                if (this.distanceLabels.length > 0) {
                    this.removeLabel(this.distanceLabels[this.distanceLabels.length - 1])
                    this.distanceLabels.splice(this.distanceLabels.length - 1, 1);
                }
                return false;
            } else {
                return true;
            }
        })
        if (this.distancePoints.length == 0) {
            return true;
        } else {
            if (this.distancePoints[0].id != this.initialPointId) {
                this.initialPointId = this.distancePoints[0].id;
            }
            this.update();
            return false;
        }
    }


}

export default MapBoxDistanceMeasure;