/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { updateScanUrl } from "../../admin/store/scanSlice";
import UPLOAD_TYPE from "../UploadType";
import APP_CONFIG from "../../../../AppConfig";
import { insertImages } from "../../admin/store/scanPanoramaSlice";
import { deleteOldScanUploadFiles } from "../../admin/store/scanEncryptedSlice";
import { updateTemplateFileUrl } from "../../admin/store/templateSlice";
import { setShowUploadPopup } from "../../admin/store/socketSlice";
import { startProcessingNewUploadedScans } from "../../admin/store/scanProcessSlice";
import { SIZES, getChunkSize } from "app/main/apps/common/FileUtils";
import {
  calculateUploadProgress,
  createFormData,
  createNewUploadObject,
  getChunkData,
} from "./UploadChunks";

const addBeforeUnloadEvent = () => {
  window.addEventListener("beforeunload", beforeUnloadEventFunc);
};
const removeBeforeUnloadEvent = () => {
  window.removeEventListener("beforeunload", beforeUnloadEventFunc);
};
const beforeUnloadEventFunc = (event) => {
  event.preventDefault();
  event.returnValue = "Are you sure you want to leave site?";
  return "Are you sure you want to leave site?";
};

const getFileExactSize = (size) => {
  if (size <= 0) return "0 B";
  const sizeCategory = Math.floor(Math.log(size) / Math.log(1024));
  const exactSize = (size / Math.pow(1024, sizeCategory)).toFixed(2);
  return SIZES[sizeCategory] ? `${exactSize} ${SIZES[sizeCategory]}` : null;
};

const calculateUploadSpeed = (chunkSize, startTime) => {
  const duration = (new Date() - startTime) / 1000;
  const speedBps = (chunkSize / duration).toFixed(2);
  const exactSize = getFileExactSize(speedBps);
  return exactSize ? `${exactSize}ps` : "N/A";
};

const generateUniqueId = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const result = new Array(256);

  for (let i = 0; i < 256; i++) {
    result[i] = characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // More memory-efficient compared to simple concatenation
  return result.join("");
};

export const startUploadFile = createAsyncThunk(
  "files/uploadApp/startUploadFile",
  async (uploadData, { dispatch, getState }) => {
    uploadData.id = generateUniqueId();
    window.onoffline = (event) => {
      dispatch(cancelAllUploadTokens());
    };

    const sizeCategory = Math.floor(
      Math.log(uploadData.file.size) / Math.log(1024)
    );
    const exactSize = Math.round(
      uploadData.file.size / Math.pow(1024, sizeCategory),
      2
    );

    uploadData.fileName = uploadData.file.name;
    uploadData.status = "Pending";

    if (
      (!uploadData.isPrimary &&
        exactSize < 10 &&
        SIZES[sizeCategory] === "MB") ||
      (!uploadData.isPrimary &&
        (SIZES[sizeCategory] === "KB" || SIZES[sizeCategory] === "Bytes"))
    ) {
      dispatch(setSmallUploadingFiles(uploadData));
      if (
        getState().files.uploadApp.smallUploadingFiles.length === 1 &&
        getState().files.uploadApp.largeUploadingFiles.length === 0
      ) {
        dispatch(resetData());
        addBeforeUnloadEvent();
      }

      if (getState().files.uploadApp.smallUploadingFiles.length === 1) {
        let data = getState().files.uploadApp.smallUploadingFiles[0];
        data = { ...data, status: "Uploading" };
        dispatch(updateSmallUploadingFiles(data));
        dispatch(uploadFile(getState().files.uploadApp.smallUploadingFiles[0]));
      }
    } else {
      dispatch(setLargeUploadingFiles(uploadData));
      if (
        getState().files.uploadApp.smallUploadingFiles.length === 0 &&
        getState().files.uploadApp.largeUploadingFiles.length === 1
      ) {
        dispatch(resetData());
        addBeforeUnloadEvent();
      }
      if (getState().files.uploadApp.largeUploadingFiles.length === 1) {
        let data = getState().files.uploadApp.largeUploadingFiles[0];
        data = { ...data, status: "Uploading" };
        dispatch(updateLargeUploadingFiles(data));
        dispatch(
          initializeUpload(getState().files.uploadApp.largeUploadingFiles[0])
        );
      }
    }
    const totalChunk =
      getState().files.uploadApp.filesTotalChunk + uploadData.file.size;
    dispatch(setFilesTotalChunk(totalChunk));
  }
);

export const initializeUpload = createAsyncThunk(
  "files/uploadApp/initializeUpload",
  async (uploadData, { dispatch }) => {
    const chunkSize = getChunkSize(uploadData.file.size);
    const url = APP_CONFIG.api + `v1/upload/initializeUpload`;
    const fileName = uploadData.file.name;

    const formData = new FormData();
    formData.append("uploadKey", uploadData.uploadKey);
    formData.append("objectType", uploadData.type);
    const response = axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    Promise.resolve(response)
      .then((response) => {
        if (response.status === 200) {
          const uploadId = response.data;
          const totalCount =
            uploadData.file.size % chunkSize === 0
              ? uploadData.file.size / chunkSize
              : Math.floor(uploadData.file.size / chunkSize) + 1;
          const uploadObject = {
            id: uploadData.id,
            typeId: uploadData.typeId,
            typeName: uploadData.name,
            type: uploadData.type,
            isPrimary: uploadData.isPrimary,
            file: uploadData.file,
            fileName,
            totalChunkCount: totalCount,
            currentChunkCount: 0,
            beginningOfTheChunk: 0,
            endOfTheChunk: chunkSize,
            partETags: [],
            chunkSize,
            uploadKey: uploadData.uploadKey,
            timeStart: new Date(),
            chunkPartTimeStart: new Date(),
            uploadSpeed: calculateUploadSpeed(chunkSize, new Date()),
            uploadId,
            isUpdate: uploadData.isUpdate,
          };
          dispatch(setCurrentLargeUploadingFile(uploadObject));
          dispatch(uploadChunkPart(uploadObject));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const uploadChunkPart = createAsyncThunk(
  "files/uploadApp/uploadChunkPart",
  async (uploadObject, { dispatch, getState }) => {
    if (
      getState().files.uploadApp.currentLargeUploadingFile.findIndex(
        (x) => x.id === uploadObject.id
      ) !== -1
    ) {
      const currentChunkCount = uploadObject.currentChunkCount;
      const isFinalPart =
        currentChunkCount + 1 === uploadObject.totalChunkCount;

      const chunk = getChunkData(uploadObject, isFinalPart);
      const formData = createFormData(
        uploadObject,
        chunk,
        isFinalPart,
        currentChunkCount
      );

      try {
        const response = await axios.post(
          APP_CONFIG.api + `v1/upload/uploadPart`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: new axios.CancelToken((c) => {
              dispatch(
                setUploadCancelTokens({ id: uploadObject.id, cancelSource: c })
              );
            }),
          }
        );

        dispatch(removeUploadCancelToken(uploadObject.id));

        if (response?.status === 200 && response?.data) {
          const uploadedChunk = calculateUploadProgress(
            uploadObject,
            isFinalPart,
            getState().files.uploadApp.uploadedChunk
          );

          const uploadSpeed = calculateUploadSpeed(
            uploadObject.chunkSize,
            uploadObject.chunkPartTimeStart
          );

          dispatch(setUploadSpeed(uploadSpeed));
          dispatch(setUploadedChunk(uploadedChunk));

          const newUploadObject = createNewUploadObject(
            uploadObject,
            response,
            isFinalPart,
            uploadSpeed
          );
          dispatch(updateCurrentLargeUploadingFile(newUploadObject));

          if (isFinalPart) {
            dispatch(completeUpload(newUploadObject));
          } else {
            dispatch(uploadChunkPart(newUploadObject));
          }
        } else {
          dispatch(uploadChunkPart(uploadObject));
        }
      } catch (error) {
        dispatch(removeUploadCancelToken(uploadObject.id));
        dispatch(uploadChunkPart(uploadObject));
      }
    }
  }
);

export const completeUpload = createAsyncThunk(
  "files/uploadApp/completeUpload",
  async (completeObject, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/upload/completeUpload`;
    const formData = new FormData();
    formData.append("uploadId", completeObject.uploadId);
    formData.append("partEtags", JSON.stringify(completeObject.partETags));
    formData.append("uploadKey", completeObject.uploadKey);
    formData.append("objectType", completeObject.type);

    const response = axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    Promise.resolve(response)
      .then((response) => {
        if (response.status === 200) {
          dispatch(removeCurrentLargeUploadingFile(completeObject));
          dispatch(removeLargeUploadingFiles(completeObject));
          if (getState().files.uploadApp.largeUploadingFiles.length > 0) {
            let data = getState().files.uploadApp.largeUploadingFiles[0];
            data = { ...data, status: "Uploading" };
            dispatch(updateLargeUploadingFiles(data));
            dispatch(
              initializeUpload(
                getState().files.uploadApp.largeUploadingFiles[0]
              )
            );
          }

          if (
            getState().files.uploadApp.smallUploadingFiles.length === 0 &&
            getState().files.uploadApp.largeUploadingFiles.length === 0
          ) {
            dispatch(resetData());
            removeBeforeUnloadEvent();
            if (getState().admin.socketApp.showUploadPopup) {
              setShowUploadPopup(false);
              window.location.reload();
            }
          }
          if (
            completeObject.type === UPLOAD_TYPE.scanpotree &&
            completeObject.isPrimary
          ) {
            let data = {
              id: completeObject.typeId,
              modelUrl: decodeURIComponent(response.data.location),
            };
            dispatch(updateScanUrl(data));
          } else if (
            (completeObject.type === UPLOAD_TYPE.scanpanoramas &&
              completeObject.fileName === UPLOAD_TYPE.scanpanoramapose) ||
            completeObject.fileName === UPLOAD_TYPE.scangpspanoramapose
          ) {
            dispatch(insertImages(completeObject.typeId));
          } else if (completeObject.type === UPLOAD_TYPE.encryptedfile) {
            let deleteObj = {
              scanId: completeObject.typeId,
              fileKey: completeObject.uploadKey,
            };
            dispatch(deleteOldScanUploadFiles(deleteObj));
            if (!completeObject.isUpdate) {
              dispatch(startProcessingNewUploadedScans(completeObject.typeId));
            }
          } else if (completeObject.type === UPLOAD_TYPE.templatefile) {
            let data = {
              id: completeObject.typeId,
              filekey: completeObject.fileName,
            };
            dispatch(updateTemplateFileUrl(data));
          }
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {});
  }
);

export const uploadFile = createAsyncThunk(
  "files/uploadApp/uploadFile",
  async (uploadObject, { dispatch, getState }) => {
    if (
      getState().files.uploadApp.smallUploadingFiles.findIndex(
        (x) => x.id === uploadObject.id
      ) !== -1
    ) {
      dispatch(setCurrentSmallUploadingFile([uploadObject]));
      const url = APP_CONFIG.api + `v1/upload/uploadFile`;

      const cancelToken = new axios.CancelToken(function executor(c) {
        dispatch(
          setUploadCancelTokens({
            uploadObjectId: uploadObject.uploadObjectId,
            cancelSource: c,
          })
        );
      });
      const formData = new FormData();
      formData.append("file", uploadObject.file);
      formData.append("uploadKey", uploadObject.uploadKey);
      formData.append("objectType", uploadObject.type);

      const response = axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: cancelToken,
      });
      const uploadTimeStart = new Date();

      Promise.resolve(response)
        // eslint-disable-next-line complexity
        .then((response) => {
          dispatch(removeUploadCancelToken(uploadObject.id));
          if (
            response != null &&
            response.status === 200 &&
            response.data != null &&
            response.data != "" &&
            Object.keys(response.data).length > 0
          ) {
            const uploadedChunk =
              getState().files.uploadApp.uploadedChunk + uploadObject.file.size;
            dispatch(
              setUploadSpeed(
                calculateUploadSpeed(uploadObject.file.size, uploadTimeStart)
              )
            );
            dispatch(setUploadedChunk(uploadedChunk));
            dispatch(removeSmallUploadingFiles(uploadObject));
            dispatch(setCurrentSmallUploadingFile([]));
            if (getState().files.uploadApp.smallUploadingFiles.length > 0) {
              let data = getState().files.uploadApp.smallUploadingFiles[0];
              data = { ...data, status: "Uploading" };
              dispatch(updateSmallUploadingFiles(data));
              dispatch(
                uploadFile(getState().files.uploadApp.smallUploadingFiles[0])
              );
            }

            if (
              getState().files.uploadApp.smallUploadingFiles.length === 0 &&
              getState().files.uploadApp.largeUploadingFiles.length === 0
            ) {
              dispatch(resetData());
              removeBeforeUnloadEvent();
              if (getState().admin.socketApp.showUploadPopup) {
                setShowUploadPopup(false);
                window.location.reload();
              }
            }

            if (
              (uploadObject.type === UPLOAD_TYPE.scanpanoramas &&
                uploadObject.fileName === UPLOAD_TYPE.scanpanoramapose) ||
              uploadObject.fileName === UPLOAD_TYPE.scangpspanoramapose
            ) {
              dispatch(insertImages(uploadObject.typeId));
            } else if (uploadObject.type === UPLOAD_TYPE.encryptedfile) {
              let deleteObj = {
                scanId: uploadObject.typeId,
                fileKey: uploadObject.uploadKey,
              };
              dispatch(deleteOldScanUploadFiles(deleteObj));
              if (!uploadObject.isUpdate) {
                dispatch(startProcessingNewUploadedScans(uploadObject.typeId));
              }
            } else if (uploadObject.type === UPLOAD_TYPE.templatefile) {
              let data = {
                id: uploadObject.typeId,
                filekey: uploadObject.fileName,
              };
              dispatch(updateTemplateFileUrl(data));
            }
          } else {
            dispatch(uploadFile(uploadObject));
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(uploadFile(uploadObject));
        });
    }
  }
);

export const cancelLargeFileUpload = createAsyncThunk(
  "files/uploadApp/cancelLargeFileUpload",
  async (cancelObject, { dispatch, getState }) => {
    const cancelTokens = getState().files.uploadApp.uploadCancelTokens;
    const cancelIndex = cancelTokens.findIndex((x) => x.id === cancelObject.id);
    if (cancelIndex != -1) {
      const cancel = cancelTokens[cancelIndex].cancelSource;
      cancel();
    }

    const uploadedChunk =
      getState().files.uploadApp.uploadedChunk -
      cancelObject.beginningOfTheChunk;
    const uploadedTotalChunk =
      getState().files.uploadApp.filesTotalChunk - cancelObject.file.size;
    dispatch(setFilesTotalChunk(uploadedTotalChunk));
    dispatch(setUploadedChunk(uploadedChunk));

    dispatch(removeCurrentLargeUploadingFile(cancelObject));
    dispatch(removeLargeUploadingFiles(cancelObject));
    if (getState().files.uploadApp.largeUploadingFiles.length > 0) {
      let data = getState().files.uploadApp.largeUploadingFiles[0];
      data = { ...data, status: "Uploading" };
      dispatch(updateLargeUploadingFiles(data));
      dispatch(
        initializeUpload(getState().files.uploadApp.largeUploadingFiles[0])
      );
    }
    if (
      getState().files.uploadApp.smallUploadingFiles.length === 0 &&
      getState().files.uploadApp.largeUploadingFiles.length === 0
    ) {
      dispatch(resetData());
      removeBeforeUnloadEvent();
      if (getState().admin.socketApp.showUploadPopup) {
        setShowUploadPopup(false);
        window.location.reload();
      }
    }
    const url = APP_CONFIG.api + `v1/upload/cancelUpload`;
    const formData = new FormData();
    formData.append("uploadId", cancelObject.uploadObjectId);
    formData.append("uploadKey", cancelObject.uploadKey);
    axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
);

export const cancelSmallFileUpload = createAsyncThunk(
  "files/uploadApp/cancelSmallFileUpload",
  async (cancelObject, { dispatch, getState }) => {
    const cancelTokens = getState().files.uploadApp.uploadCancelTokens;
    const cancelIndex = cancelTokens.findIndex((x) => x.id === cancelObject.id);
    if (cancelIndex != -1) {
      const cancel = cancelTokens[cancelIndex].cancelSource;
      cancel();
    }
    const uploadedTotalChunk =
      getState().files.uploadApp.filesTotalChunk - cancelObject.file.size;
    dispatch(setFilesTotalChunk(uploadedTotalChunk));

    dispatch(removeSmallUploadingFiles(cancelObject));
    dispatch(setCurrentSmallUploadingFile([]));
    if (getState().files.uploadApp.smallUploadingFiles.length > 0) {
      let data = getState().files.uploadApp.smallUploadingFiles[0];
      data = { ...data, status: "Uploading" };
      dispatch(updateSmallUploadingFiles(data));
      dispatch(uploadFile(getState().files.uploadApp.smallUploadingFiles[0]));
    }

    if (
      getState().files.uploadApp.smallUploadingFiles.length === 0 &&
      getState().files.uploadApp.uploadObject.length === 0
    ) {
      dispatch(resetData());
      removeBeforeUnloadEvent();
      if (getState().admin.socketApp.showUploadPopup) {
        setShowUploadPopup(false);
        window.location.reload();
      }
    }
  }
);

const uploadSlice = createSlice({
  name: "files",
  initialState: {
    uploadCancelTokens: [],
    smallUploadingFiles: [],
    largeUploadingFiles: [],
    currentLargeUploadingFile: [],
    currentSmallUploadingFile: [],
    uploadSpeed: "",
    uploadedChunk: 0,
    filesTimeStart: new Date(),
    filesTotalChunk: 0,
  },
  reducers: {
    resetData: (state, action) => {
      state.uploadSpeed = "";
      state.uploadedChunk = 0;
      state.filesTimeStart = new Date();
      state.filesTotalChunk = 0;
    },
    setCurrentSmallUploadingFile: (state, action) => {
      state.currentSmallUploadingFile = action.payload;
    },
    setCurrentLargeUploadingFile: (state, action) => {
      state.currentLargeUploadingFile.push(action.payload);
    },
    updateCurrentLargeUploadingFile: (state, action) => {
      const updateObject = action.payload;
      const index = state.currentLargeUploadingFile.findIndex(
        (x) => x.id === updateObject.id
      );
      state.currentLargeUploadingFile[index] = updateObject;
    },
    removeCurrentLargeUploadingFile: (state, action) => {
      const removeObject = action.payload;
      const index = state.currentLargeUploadingFile.findIndex(
        (x) => x.id === removeObject.id
      );
      state.currentLargeUploadingFile.splice(index, 1);
    },

    removeUploadObject: (state, action) => {
      const obj = action.payload;
      const index = state.largeUploadingFiles.findIndex((x) => x.id === obj.id);
      if (index != -1) {
        state.filesTotalChunk = state.filesTotalChunk - obj.file.size;
        state.largeUploadingFiles.splice(index, 1);
      } else {
        const sindex = state.smallUploadingFiles.findIndex(
          (x) => x.id === obj.id
        );
        state.filesTotalChunk = state.filesTotalChunk - obj.file.size;
        state.smallUploadingFiles.splice(sindex, 1);
      }
    },
    setUploadSpeed: (state, action) => {
      state.uploadSpeed = action.payload;
    },
    setUploadedChunk: (state, action) => {
      state.uploadedChunk = action.payload;
    },
    setFilesTotalChunk: (state, action) => {
      state.filesTotalChunk = action.payload;
    },

    setSmallUploadingFiles: (state, action) => {
      state.smallUploadingFiles.push(action.payload);
    },
    setLargeUploadingFiles: (state, action) => {
      state.largeUploadingFiles.push(action.payload);
    },
    updateSmallUploadingFiles: (state, action) => {
      const updateObject = action.payload;
      const index = state.smallUploadingFiles.findIndex(
        (x) => x.id === updateObject.id
      );
      state.smallUploadingFiles[index] = updateObject;
    },
    updateLargeUploadingFiles: (state, action) => {
      const updateObject = action.payload;
      const index = state.largeUploadingFiles.findIndex(
        (x) => x.id === updateObject.id
      );
      state.largeUploadingFiles[index] = updateObject;
    },
    removeSmallUploadingFiles: (state, action) => {
      const removeObject = action.payload;
      const index = state.smallUploadingFiles.findIndex(
        (x) => x.id === removeObject.id
      );
      state.smallUploadingFiles.splice(index, 1);
    },
    removeLargeUploadingFiles: (state, action) => {
      const removeObject = action.payload;
      const index = state.largeUploadingFiles.findIndex(
        (x) => x.id === removeObject.id
      );
      state.largeUploadingFiles.splice(index, 1);
    },
    setUploadCancelTokens: (state, action) => {
      state.uploadCancelTokens.push(action.payload);
    },
    removeUploadCancelToken: (state, action) => {
      state.uploadCancelTokens = state.uploadCancelTokens.filter(
        (c) => c.id != action.payload
      );
    },
    removeAllUploadCancelTokens: (state, action) => {
      state.uploadCancelTokens = [];
    },
    cancelAllUploadTokens: (state, action) => {
      for (let i = 0; i < state.uploadCancelTokens.length; i++) {
        const cancel = state.uploadCancelTokens[i].cancelSource;
        cancel();
      }
      state.uploadCancelTokens = [];
    },
  },
  extraReducers: {},
});

export const {
  resetData,
  setCurrentSmallUploadingFile,
  setCurrentLargeUploadingFile,
  updateCurrentLargeUploadingFile,
  removeCurrentLargeUploadingFile,
  removeUploadObject,
  setUploadSpeed,
  setUploadedChunk,
  setFilesTotalChunk,
  setSmallUploadingFiles,
  setLargeUploadingFiles,
  updateSmallUploadingFiles,
  updateLargeUploadingFiles,
  removeSmallUploadingFiles,
  removeLargeUploadingFiles,
  setUploadCancelTokens,
  removeUploadCancelToken,
  removeAllUploadCancelTokens,
  cancelAllUploadTokens,
} = uploadSlice.actions;

export default uploadSlice.reducer;
