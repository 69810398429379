import React, { useState } from 'react';

import PropTypes from "prop-types";
import { Dialog, Tab } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from "@mui/icons-material/Close";
import SinglePointEditor from "./SinglePointEditor";
import MultiPointEditor from "./MultiPointEditor";
import { OuterPanel, Close } from './CoordinateEditor.style';

function CoordinateEditor(props) {

	const { addOrUpdateControlCoordinate, updateInfo, coordinate, onClose, index, clearModelCoordinate } = props;

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md">
			<Close>
				<CloseIcon onClick={() => onClose()} />
			</Close>
			<OuterPanel>
				<SinglePointEditor onDone={addOrUpdateControlCoordinate} updateInfo={updateInfo} coordinate={coordinate} index={index} clearModelCoordinate={clearModelCoordinate} />
			</OuterPanel>
		</Dialog>
	);
}

CoordinateEditor.propTypes = {
	addOrUpdateControlCoordinate: PropTypes.func.isRequired,
	updateInfo: PropTypes.func.isRequired,
	coordinate: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	index: PropTypes.number,
	clearModelCoordinate: PropTypes.func.isRequired
};

export default CoordinateEditor;