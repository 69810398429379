
import styled from "@emotion/styled";
import {
    Typography,
    Card,
    Link,
    LinearProgress
} from '@mui/material';
import { withStyles } from '@mui/styles';

const Description = styled.div`
  font-size: 15px;
`

const PercentageLinearProgressBar = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
        width: '100%',
        backgroundColor: '#000000'
    },
    colorPrimary: {
        backgroundColor: '#D3D3D3'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#59BEC9",
    },
    transform: [{ rotate: "90deg" }],
}))(LinearProgress);

function ScanWifiUploadNotificationCard(props) {
    const { notification } = props;

    const date = notification.createTime
    const dateObject = new Date(date);
    const dateTime = dateObject.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

    const getFileExactSize = (size) => {
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0; while (size > 900) { size /= 1024; i++; }
        var exactSize = (Math.round(size * 10) / 10) + ' ' + fSExt[i];
        return exactSize;
    }

    return (
        <Card
            className='flex items-center relative w-full rounded-16 p-20 min-h-64 shadow space-x-8 mb-8'
            elevation={0}
        >
            <div className="flex flex-col flex-auto">
                {notification.processingUpload.isPicked == true ?
                    <>
                        <Description>Scan <Link style={{ 'color': '#0D99FF', 'textDecoration': 'none' }}>{`${notification.processingUpload.scanName}(${getFileExactSize(notification.processingUpload.totalBytes)})`}</Link> copy in progress</Description>
                        <div className='flex flex-row items-center'>
                            <PercentageLinearProgressBar
                                variant="determinate"
                                value={notification.processingUpload.progress}
                            />
                            <p className="ml-4">
                                {`${notification.processingUpload.progress.toFixed(1)}%`}
                            </p>
                        </div>
                    </>
                    :
                    <>
                        <Description>Scan <Link style={{ 'color': '#0D99FF', 'textDecoration': 'none' }}>{`${notification.processingUpload.scanName}(${getFileExactSize(notification.processingUpload.totalBytes)})`}</Link> copy in queue</Description>
                    </>
                }
                <Typography className="mt-8 text-sm leading-none " color="text.secondary">
                    {dateTime}
                </Typography>
            </div>
        </Card>
    )
}
export default ScanWifiUploadNotificationCard;