import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import SERVICE_STATUS from '../../../../services/ServiceStatus';
import APP_CONFIG from 'app/AppConfig';


export const getAllProcessingConfigs = createAsyncThunk('admin/configApp/getAllProcessingConfigs', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/processingConfigSettings/getAllProcessingConfigSettings`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});


export const createConfig = createAsyncThunk(
    'admin/configApp/createConfig',
    async (createConfig, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/processingConfigSettings/createConfig`;
        const response = await axios.post(url, createConfig, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const updateConfig = createAsyncThunk(
    'admin/configApp/updateConfig',
    async (updateConfig, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/processingConfigSettings/updateConfig/${updateConfig.id}`;
        const response = await axios.put(url, updateConfig, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;

        return data;
    }
);

export const removeConfig = createAsyncThunk(
    'admin/configApp/removeConfig',
    async (configId, { dispatch }) => {

        const url = APP_CONFIG.api + `v1/processingConfigSettings/deleteConfig/${configId}`;

        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.data;
        return data;
    }
);

export const downloadConfigFiles = createAsyncThunk('admin/configApp/getConfigDownloadURL', async (configId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/processingConfigSettings/getConfigDownloadURL/${configId}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    Promise.resolve(response).then((response) => {
        if (response.status === 200) {
            if (response.data) {
                const a = document.createElement('a');
                a.download = "test.bin";
                a.href = response.data;
                a.style.display = 'none';
                document.body.append(a);
                a.click();
                a.remove();
            }
        }
    }).catch((error) => {

    });

});

export const getDeletedConfig = createAsyncThunk('admin/deviceApp/loadDeletedconfig', async (_, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/processingConfigSettings/getAllDeletedConfigs`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.data;
    return data;
});

export const getConfigFilesPath = createAsyncThunk(
    'admin/configApp/getConfigFilesPath',
    async (configId, { dispatch }) => {
        const url = APP_CONFIG.api + `v1/processingConfigSettings/getConfigFilesPathById/${configId}`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const data = await response.data;

        return data;
    }
);

export const updateDefaultConfig = (configId) => {
    const url = APP_CONFIG.api + `v1/processingConfigSettings/updateDefaultConfig/${configId}`;
    const response = axios.put(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response
};


export const getDefaultConfig = () => {
    const url = APP_CONFIG.api + `v1/processingConfigSettings/getDefaultConfig`;

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',

        },

    });
    return response;
};

export const updatecreateConfigState = (value) => async (dispatch) => {
    dispatch(setcreateConfigState(value));
}
export const updateupdateConfigState = (value) => async (dispatch) => {
    dispatch(setupdateConfigState(value));
}
export const updateremoveConfigState = (value) => async (dispatch) => {
    dispatch(setremoveConfigState(value));
}

const configSlice = createSlice({
    name: 'admin/configApp',
    initialState: {
        configs: [],
        loadingState: '',
        createConfigState: '',
        updateConfigState: '',
        removeConfigState: '',
        deletedConfigs: [],
        configFilePath: {},
        updateConfigFailure: '',
        createConfigFailure: '',
        defaultConfig: []
    },

    reducers: {
        setcreateConfigState: (state, action) => {
            state.createConfigState = action.payload;
        },
        setupdateConfigState: (state, action) => {
            state.updateConfigState = action.payload;
        },
        setConfigFilePath: (state, action) => {
            state.configFilePath = action.payload;
        },
        setremoveConfigState: (state, action) => {
            state.removeConfigState = action.payload;
        },
        setCreateConfigFailure: (state, action) => {
            state.createConfigFailure = action.payload;
        },
        setUpdateConfigFailure: (state, action) => {
            state.updateConfigFailure = action.payload;
        },
    },

    extraReducers: {
        [getAllProcessingConfigs.fulfilled]: (state, action) => {
            state.configs = action.payload;
            state.loadingState = SERVICE_STATUS.fulfilled;
        },
        [getAllProcessingConfigs.rejected]: (state, action) => {
            state.loadingState = SERVICE_STATUS.rejected;
        },
        [getAllProcessingConfigs.pending]: (state, action) => {
            state.loadingState = SERVICE_STATUS.pending;
        },
        [createConfig.fulfilled]: (state, action) => {
            state.configFilePath = action.payload;
            state.createConfigState = SERVICE_STATUS.fulfilled;
        },
        [createConfig.pending]: (state, action) => {
            state.createConfigState = SERVICE_STATUS.pending;
        },
        [createConfig.rejected]: (state, action) => {
            state.createConfigState = SERVICE_STATUS.rejected;
            state.createConfigFailure = action.error.message;
        },
        [getConfigFilesPath.fulfilled]: (state, action) => {
            state.configFilePath = action.payload
        },
        [updateConfig.fulfilled]: (state, action) => {
            state.updateConfigState = SERVICE_STATUS.fulfilled;
        },
        [updateConfig.pending]: (state, action) => {
            state.updateConfigState = SERVICE_STATUS.pending;
        },
        [updateConfig.rejected]: (state, action) => {
            state.updateConfigState = SERVICE_STATUS.rejected;
            state.updateConfigFailure = action.error.message;
        },
        [removeConfig.fulfilled]: (state, action) => {
            state.removeConfigState = SERVICE_STATUS.fulfilled;
        },
        [removeConfig.pending]: (state, action) => {
            state.removeConfigState = SERVICE_STATUS.pending;
        },
        [removeConfig.rejected]: (state, action) => {
            state.removeConfigState = SERVICE_STATUS.rejected;
        },
        [getDeletedConfig.fulfilled]: (state, action) => {
            state.deletedConfigs = action.payload;
        },
        [getDeletedConfig.pending]: (state, action) => {
            state.loadingState = SERVICE_STATUS.pending;
        },
        [getDeletedConfig.rejected]: (state, action) => {
            state.loadingState = SERVICE_STATUS.rejected;
        },
        [getDefaultConfig.fulfilled]: (state, action) => {
            state.defaultConfig = action.payload;
            state.loadingState = SERVICE_STATUS.fulfilled;
        },
        [getDefaultConfig.rejected]: (state, action) => {
            state.loadingState = SERVICE_STATUS.rejected;
        },
        [getDefaultConfig.pending]: (state, action) => {
            state.loadingState = SERVICE_STATUS.pending;
        },
    }
});

export const { setcreateConfigState, setupdateConfigState, setUpdateConfigFailure, setConfigFilePath, setCreateConfigFailure, setremoveConfigState } = configSlice.actions;

export default configSlice.reducer;