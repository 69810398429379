import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@mui/material';
import { loadJsonFileData } from "app/main/apps/scan/store/scansSlice";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import ReactJson from 'react-json-view';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TitlePanel = styled.div`
  display: flex;
`;

const IconButton = styled.div`
  color: #808080;
  font-size: 32px;
  &:hover {
    color: #3399ff;
	 cursor: pointer;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
`;

const Spacer = styled.div`
  flex-grow: ${props => props.space};
`;

function JSONFileViewer(props) {

  const { src, onClose } = props;
  const [jsonData, setJsonData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const fileName = src.split('/')[src.split('/').length - 1];

  useEffect(() => {
    setShowLoader(true);
    const loadJson = loadJsonFileData(src);
    Promise.resolve(loadJson).then((response) => {
      setJsonData(response.data);
      setShowLoader(false);
    });
  }, []);



  const downloadFile = () => {
    const blob = new Blob([JSON.stringify(jsonData, null, 4)], { type: 'application/json' })
    const a = document.createElement('a');
    a.download = fileName
    a.href = window.URL.createObjectURL(blob);
    a.style.display = 'none';
    document.body.append(a);
    a.click();
    a.remove();
  }



  return (
    <div>
      <Dialog
        fullScreen
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" open>
        <DialogTitle>
          <TitlePanel className='flex flex-row justify-between'>
            <div>{fileName}</div>
            <Spacer space={5} />
            {Object.keys(jsonData).length > 0 &&
              <IconButton onClick={() => downloadFile()}>
                <FileDownloadIcon fontSize="inherit" />
              </IconButton>
            }
            <IconButton onClick={onClose}><Close fontSize="inherit" /></IconButton>
          </TitlePanel>
        </DialogTitle>
        <DialogContent>
          {showLoader ?
            <ProgressBar>
              <CircularProgress color="inherit" />
            </ProgressBar>
            :
            <>
              {Object.keys(jsonData).length > 0 &&
                <ReactJson displayArrayKey={false} quotesOnKeys={false} displayDataTypes={false} enableClipboard={false} src={jsonData} ></ReactJson>
              }
            </>
          }
        </DialogContent>
      </Dialog>
    </div>


  )
}
export default JSONFileViewer;