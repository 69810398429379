import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent, Typography, Link } from "@mui/material";
import styled from "@emotion/styled";
import {
  setShowNotificationScanAlert,
  setSelectedNotification,
  updateScanNotificationDialog,
  updatePendingShowAlert,
} from "app/main/apps/admin/store/notificationSlice";

const Description = styled.div`
  font-size: 16px;
  color: #4d4d4f;
`;

// const CloseButton = styled.div`
//   color: #808080;
//   font-size: 32px;
//   &:hover {
//     color: #3399ff;
// 	cursor: pointer;
//   }
// `;
function ScanPendingNotificationAlertDialog() {
  const { showNotificationScanAlert, selectedNotification } = useSelector(
    ({ admin }) => admin.notificationsApp
  );
  const { customer } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowNotificationScanAlert(false));
    if (selectedNotification) {
      dispatch(updatePendingShowAlert(selectedNotification.id));
    }
    dispatch(setSelectedNotification(null));
  };

  const handleOnClick = () => {
    dispatch(setShowNotificationScanAlert(false));
    if (selectedNotification) {
      dispatch(updatePendingShowAlert(selectedNotification.id));
    }
    dispatch(updateScanNotificationDialog(true));
  };

  return (
    <>
      {showNotificationScanAlert && (
        <Dialog
          open={true}
          onClose={handleClose}
          // fullWidth
          // maxWidth="sm"
        >
          <div>
            {/* <TitlePanel>
                            <div></div>
                            <Spacer space={5} />
                            <CloseButton
                                onClick={() => handleClose()}
                            >
                                <Close fontSize="inherit" /></CloseButton>
                        </TitlePanel> */}
            <DialogContent
              className="flex flex-col justify-center w-full mb-8 mt-8"
              style={{ width: "450px" }}
            >
              <Typography
                style={{ fontSize: "16px", color: "#4D4D4F" }}
                className="mr-12"
              >
                Hi {customer.name}!
              </Typography>
              <br></br>
              <div className="flex flex-col flex-auto">
                <Description>
                  Your scan <b>{selectedNotification.name}</b> has been uploaded
                  successfully.{" "}
                  <Link
                    onClick={() => handleOnClick()}
                    style={{
                      color: "#0D99FF",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Click here
                  </Link>{" "}
                  to create a new site or assign it to an existing one.
                </Description>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default ScanPendingNotificationAlertDialog;
