/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "../../../../AppConfig";

export const loadScans = () => async (dispatch, getState) => {
  const url = APP_CONFIG.api + `v1/scan/scans`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  Promise.resolve(response)
    .then((response) => {
      if (response.status === 200) {
        return dispatch(setScans(response.data));
      }
    })
    .catch((error) => {
      console.warn("Cannot retrieve scans data", error);
    });
};

export const updateScanIsAgreed = createAsyncThunk(
  "scan/scanApp/updateScanIsAgreed",
  async (updateScan, { dispatch }) => {
    const url = APP_CONFIG.api + `v1/scan/updateScanIsAgreed/${updateScan.id}`;

    const response = await axios.put(url, updateScan, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    dispatch(loadScans());
  }
);

export const loadJsonFileData = (url) => {
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const getOrthoData = (scanId) => {
  const url = APP_CONFIG.api + `v1/scan/getScanOrthoData/${scanId}`;
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const getGPSImageScans = (scanIds) => {
  const url = APP_CONFIG.api + `v1/scan/getGPSImageScans`;
  const response = axios.post(url, scanIds, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const saveFolderDetails = (requestData) => {
  const url = APP_CONFIG.api + `v1/usersSiteFolders/saveFolderDetails`;

  const request = axios.post(url, requestData.data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  Promise.resolve(request)
    .then((response) => {
      if (response) {
        requestData.successCallback();
      }
    })
    .catch((error) => {
      var errorMessage = "";
      if (
        error &&
        error.response &&
        error.response.data &&
        typeof error.response.data == "string"
      ) {
        errorMessage = error.response.data;
      }
      requestData.failureCallback(errorMessage);
    });
};

export const getUsersSiteFoldersDetails = (siteId) => {
  const url =
    APP_CONFIG.api + `v1/usersSiteFolders/getUsersSiteFolders/${siteId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateFolderDetails = (requestData) => {
  const url =
    APP_CONFIG.api +
    `v1/usersSiteFolders/updateFolderDetails/${requestData.data.id}`;
  const request = axios.put(url, requestData.data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  Promise.resolve(request)
    .then((response) => {
      if (response) {
        requestData.successCallback();
      }
    })
    .catch((error) => {
      var errorMessage = "";
      if (
        error &&
        error.response &&
        error.response.data &&
        typeof error.response.data == "string"
      ) {
        errorMessage = error.response.data;
      }
      requestData.failureCallback(errorMessage);
    });
};

export const deleteFolderDetails = (folderId) => {
  const url =
    APP_CONFIG.api + `v1/usersSiteFolders/deleteFolderDetails/${folderId}`;
  const response = axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateFolderScanDetails = (requestData) => {
  const url =
    APP_CONFIG.api +
    `v1/usersSiteFolders/updateFolderScanDetails/${requestData.siteId}`;
  const response = axios.put(url, requestData.folders, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateFolderPriorityOrder = (requestData) => {
  const url =
    APP_CONFIG.api +
    `v1/usersSiteFolders/updateFolderPriorityOrder/${requestData.siteId}`;
  const response = axios.post(url, requestData.folders, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const downloadScanFolder = createAsyncThunk(
  "admin/scanApp/downloadScanFolder",
  async (requestData, { dispatch, getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/scan/getScanFolderDownloadLink/${requestData.scanId}/${requestData.type}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    multiDownload(data);
  }
);

export const downloadScanFile = createAsyncThunk(
  "admin/scanApp/downloadScanFile",
  async (requestData, { dispatch, getState }) => {
    const url =
      APP_CONFIG.api +
      `v1/scan/getScanFileDownloadLink/${requestData.scanId}/${requestData.type}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    multiDownload(data);
  }
);

export const checkFilesExistInFolder = (requestData) => {
  const url = APP_CONFIG.api + `v1/scan/checkFilesExistInFolder`;
  const response = axios.post(url, requestData.key, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const multiDownload = async (urls) => {
  for (const [index, url] of urls.entries()) {
    download(url);
    await delay(5000);
  }
};
const download = async (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();
  await delay(100);
  a.remove();
};

const initialState = [];

const scansSlice = createSlice({
  name: "scanApp/scans",
  initialState,
  reducers: {
    setScans: (state, action) => action.payload,
  },
  extraReducers: {},
});

export const { setScans } = scansSlice.actions;

export default scansSlice.reducer;
