import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rightDrawerOpen: false,
  dataPanel: "data",
  leftDrawerOpen: true,
  coordinates: [],
  selectedMarkerIndex: null,
  isOrthographicCamera: false,
  enableAlignToControl: false,
  loadedScanIds: [],
  isPotreeRefresh: false,
  isJustCrashed: false,
  showToolbarItems: false,
};

const potreeSlice = createSlice({
  name: "potree",
  initialState,
  reducers: {
    DataPanelSet(state, action) {
      state.dataPanel = action.payload;
    },
    LeftDrawerToggled(state) {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
    RightDrawerToggled(state) {
      state.rightDrawerOpen = !state.rightDrawerOpen;
    },
    RightDrawerOpened(state) {
      state.rightDrawerOpen = true;
    },
    RightDrawerClosed(state) {
      state.rightDrawerOpen = false;
    },
    LeftDrawerOpened(state) {
      state.leftDrawerOpen = true;
    },
    LeftDrawerClosed(state) {
      state.leftDrawerOpen = false;
    },
    CoordinatesSet(state, action) {
      state.coordinates = action.payload;
    },
    SelectedMarkerIndexSet(state, action) {
      state.selectedMarkerIndex = action.payload;
    },
    OrthographicCameraSet(state, action) {
      state.isOrthographicCamera = action.payload;
    },
    AlignToControlEnabled(state, action) {
      state.enableAlignToControl = action.payload;
    },
    AlignToControlDisabled(state) {
      state.enableAlignToControl = false;
    },
    LoadedScanIdsSet(state, action) {
      state.loadedScanIds = action.payload;
    },
    PotreeRefreshSet(state, action) {
      state.isPotreeRefresh = action.payload;
    },
    JustCrashedSet(state, action) {
      state.isJustCrashed = action.payload;
    },
    ToolbarItemsShown(state, action) {
      state.showToolbarItems = action.payload;
    },
  },
});

export const {
  DataPanelSet,
  RightDrawerOpened,
  RightDrawerClosed,
  LeftDrawerOpened,
  LeftDrawerClosed,
  RightDrawerToggled,
  LeftDrawerToggled,
  CoordinatesSet,
  SelectedMarkerIndexSet,
  OrthographicCameraSet,
  AlignToControlEnabled,
  AlignToControlDisabled,
  LoadedScanIdsSet,
  PotreeRefreshSet,
  JustCrashedSet,
  ToolbarItemsShown,
} = potreeSlice.actions;

export const selectRightDrawerOpen = (state) => state.potree.rightDrawerOpen;
export const selectLeftDrawerOpen = (state) => {
  return state.potree.potree.leftDrawerOpen;
};
export const selectCoordinates = (state) => state.potree.potree.coordinates;
export const selectSelectedMarkerIndex = (state) =>
  state.potree.potree.selectedMarkerIndex;
export const selectIsOrthographicCamera = (state) =>
  state.potree.isOrthographicCamera;
export const selectEnableAlignToControl = (state) =>
  state.potree.enableAlignToControl;
export const selectLoadedScanIds = (state) => state.potree.potree.loadedScanIds;
export const selectIsPotreeRefresh = (state) =>
  state.potree.potree.isPotreeRefresh;
export const selectIsJustCrashed = (state) => state.potree.potree.isJustCrashed;
export const selectShowToolbarItems = (state) =>
  state.potree.potree.showToolbarItems;
export const selectDataPanel = (state) => state.potree.potree.dataPanel;

export default potreeSlice.reducer;
