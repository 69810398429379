import styled from "@emotion/styled";

export const OuterPanel = styled.div`
  background: linear-gradient(to bottom right, #d9e2ec, white);
  display: flex;
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${window.screen.width <= 480
    ? "0px"
    : (props) => (props.open ? "0px" : "0px")};
  width: 100%;
`;

export const PotreePanel = styled.div`
  height: calc(100vh);
  width: 100%;
  flex-grow: 4;
`;

export const Header = styled.div`
  color: #ffffff;
  background-color: #9aa1a4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  height: 50px;
  z-index: 1;
  margin-top: 0px;
`;

export const HeaderAlignToControl = styled.div`
  color: #ffffff;
  background-color: #9aa1a4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  height: 50px;
  z-index: 1;
  margin-top: 60px;
  right: 13px;
`;
