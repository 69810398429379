import ScansAppConfig from './scan/ScansAppConfig';
import AdminAppConfig from './admin/AdminAppConfig';
import UploadAppConfig from './upload/UploadAppConfig';
import SitesDashboardAppConfig from './dashboards/site/SitesDashboardAppConfig';
import RegisterConfig from '../register/RegisterConfig';
import UserAppConfig from './user/UserAppConfig';
import VerificationConfig from '../verification/VerificationConfig';
import DashboardAppConfig from './demoUserDashboards/DashboardAppConfig';
import DemoUserScansAppConfig from './demoUserDashboards/site/DemoUserScansAppConfig';


const appsConfigs = [
  ScansAppConfig,
  AdminAppConfig,
  UploadAppConfig,
  SitesDashboardAppConfig,
  RegisterConfig,
  UserAppConfig,
  VerificationConfig,
  DashboardAppConfig,
  DemoUserScansAppConfig
];

export default appsConfigs;
