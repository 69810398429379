import {
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { registerUser } from "./store/registerSlice";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { decodeEmail } from "./store/registerSlice";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import swal from "sweetalert";
import { CheckPasswordValid } from "../apps/common/CheckPasswordValid";
import ErrorMessageConstants from "../apps/constants/ErrorMessageConstants";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import APP_CONFIG from "app/AppConfig";
import auth0 from 'auth0-js';

const onRedirectCallback = (appState) => {
  history.replace(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};

const providerConfig = {
  domain: APP_CONFIG.domain,
  clientID: APP_CONFIG.clientId,
  audience: APP_CONFIG.audience,
  scope: APP_CONFIG.scope,
  redirectUri: window.location.origin,
  // cacheLocation: APP_CONFIG.cacheLocation,
  // useRefreshTokens: APP_CONFIG.useRefreshTokens,
  responseType: 'token',
  onRedirectCallback,
};


const SignUp = () => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  const [isValidForm, setIsValidForm] = useState(true);
  const [isPasswordsMatch, setIsPasswordMatch] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [userData, setuserData] = useState({
    email: "",
    name: "",
    password: "",
    confirmpassword: "",
    showPassword: false,
  });

  const { email } = useSelector(({ register }) => register.registerApp);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (value) => {
    setIsChecked(value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirmShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    var Email = window.location.href.split("EmailId=")[1].toString();
    dispatch(decodeEmail(Email));
  }, [dispatch]);

  useEffect(() => {
    document.body.style.backgroundImage =
      "url(assets/images/logos/Rectangle-682.png)";
    document.body.style.backgroundSize = "cover";
  }, []);

  const [webAuth, setWebAuth] = useState(null);

  useEffect(() => {
    setWebAuth(new auth0.WebAuth(providerConfig));
  }, []);

  const handleOnChange = (e) => {
    setuserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmitClick = () => {
    setIsValidForm(true);
    setIsPasswordMatch(true);
    setIsValidPassword(true);
    if (validateForm()) {
      if (isValidUser()) {
        if (checkIsValidPassword()) {
          var data = {
            name: userData.name,
            email: email.toLowerCase(),
            password: userData.password,
            confirmpassword: userData.confirmpassword,
          };
          var requestData = {
            data: data,
            successCallback: onRegisterSuccess,
            failureCallback: onRegisterFailure,
          };
          dispatch(registerUser(requestData));
          dispatch(setLoaderProgress(true));
        } else {
          setIsValidPassword(false);
        }
      } else {
        setIsPasswordMatch(false);
      }
    } else {
      setIsValidForm(false);
    }
  };

  const onRegisterSuccess = () => {
    swal("", "Registered successfully", "success").then(() => {
      loginWithRedirect();
    });
  };
  const onRegisterFailure = (message) => {
    if (message) {
      swal("", message, "error");
      dispatch(setLoaderProgress(false));
    } else {
      swal("", ErrorMessageConstants.defaultErrormessage, "error");
      dispatch(setLoaderProgress(false));
    }
  };
  const validateForm = () => {
    if (
      !userData.name ||
      !userData.password ||
      !userData.confirmpassword ||
      !isChecked
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkIsValidPassword = () => {
    if (userData.password.length > 7) {
      if (CheckPasswordValid(userData.password)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isValidUser = () => {
    if (userData.password === userData.confirmpassword) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnGoogleClick = () => {
    webAuth.authorize({
      connection: 'google-oauth2'
    }, function (err) {
      if (err) displayError(err);
    });
  }

  const handleOnMicrosoftClick = () => {
    webAuth.authorize({
      connection: 'windowslive'
    }, function (err) {
      if (err) displayError(err);
    });
  }

  return (
    <>
      <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
        <div className="flex flex-col items-center justify-center w-full max-w-384">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              maxHeight: "auto",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              borderRadius: '5px',
            }}
            className="items-center justify-center my-16 sm:p-24 md:p-32"
          >
            <Grid container className="items-center justify-center">
              <div className="w-full">
                <div className="flex flex-row justify-center items-center w-full">
                  <img
                    className="mx:auto"
                    width="200px"
                    src="assets/images/logos/looq.png"
                  ></img>
                </div>
                <Typography
                  variant="h1"
                  className="mt-0 mb-24 font-normal text-18 sm:text-24 text-center"
                >
                  Welcome
                </Typography>
                {!isValidForm ? (
                  <p className="text-red mb-16 text-center">
                    All fields are mandatory
                  </p>
                ) : null}
                {!isPasswordsMatch ? (
                  <p className="text-red mb-16 text-center">
                    Password and Confirm Password should be same
                  </p>
                ) : null}
                {isValidPassword ? null : (
                  <p className="text-red mb-16 text-center">
                    Password should contain a minimum of 8 characters and at least 1 uppercase letter, number, and special character.
                  </p>
                )}
                <div className="relative flex flex-col items-center w-full">
                  <div className="flex flex-col w-full">
                    <TextField
                      id="outlined-basic 1"
                      variant="outlined"
                      label="Email ID"
                      name="email"
                      fullWidth
                      value={email}
                      onChange={(e) => handleOnChange(e)}
                      className="mb-16"
                      disabled
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <TextField
                      id="outlined-basic 2"
                      label="Name"
                      name="name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleOnChange(e)}
                      className="mb-16"
                      value={userData.name}
                      error={!isValidForm && userData.name == ""}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <FormControl variant="outlined" className="mb-16 w-full">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={userData.password}
                        onChange={(e) => handleOnChange(e)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        error={
                          (!isValidForm && userData.password == "") ||
                          !isPasswordsMatch ||
                          !isValidPassword
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="flex flex-col w-full">
                    <FormControl variant="outlined" className="mb-16 w-full">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmpassword"
                        value={userData.confirmpassword}
                        onChange={(e) => handleOnChange(e)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConfirmShowPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                        error={
                          (!isValidForm && userData.confirmpassword == "") ||
                          !isPasswordsMatch ||
                          !isValidPassword
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="flex flex-col w-full">
                    <h6
                      className="text-red text-8"
                      style={{ "font-size": "smaller" }}
                    >
                      Password should contain a minimum of 8 characters and at least 1 uppercase letter, number, and special character.
                    </h6>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div
                    className="mt-16 flex flex-row items-center"
                  // style={{
                  //   fontSize: "15px",
                  // }}
                  >
                    {isChecked ? (
                      <IconButton
                        style={{ color: "#5ecae5" }}
                        onClick={() => handleCheckboxChange(false)}
                      >
                        <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleCheckboxChange(true)}
                        style={{
                          color: !isValidForm ? "#ff0000" : "",
                        }}
                      >
                        <CheckBoxOutlineBlankOutlinedIcon></CheckBoxOutlineBlankOutlinedIcon>
                      </IconButton>
                    )}
                    <div>
                      I agree to the <a href="https://looq.ai/Looq Privacy Policy.pdf" target="_blank" style={{ background: "none", color: "blue", borderBottom: "none", }}> Privacy Policy </a> and
                      <a href="https://looq.ai/Looq Platform Services Agreement.pdf" target="_blank" style={{ background: "none", color: "blue", borderBottom: "none", }}> Platform Services Agreement </a>
                    </div>
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full mx-auto mt-16 py-12 font-16 font-normal"
                  aria-label="Submit"
                  type="button"
                  onClick={() => handleOnSubmitClick()}
                  style={{ backgroundColor: "#e74a3f", borderRadius: '5px' }}
                >
                  Sign Up
                </Button>
                <div class="cd36e8cd7 cd863c8f8">
                  <span>OR</span>
                </div>

                <Button
                  className="w-full mt-16 py-12 justify-start font-16 btn-ouline font-normal"
                  aria-label="Submit"
                  type="button"
                  onClick={() => handleOnGoogleClick()}
                  style={{ borderRadius: '5px' }}
                >
                  <img class="mx:auto mx-16" width="20px" src="assets/images/google.svg" /> Continue with Google
                </Button>
                <Button
                  className="w-full mt-16 py-12 justify-start font-16 btn-ouline font-normal"
                  aria-label="Submit"
                  type="button"
                  onClick={() => handleOnMicrosoftClick()}
                  style={{ borderRadius: '5px' }}
                >
                  <img class="mx:auto mx-16" width="20px" src="assets/images/microsoft.svg" /> Continue with Microsoft

                </Button>
              </div>
            </Grid>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default SignUp;
