import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "app/AppConfig";

export const insertImages = createAsyncThunk(
  "file/fileApp/insertImages",
  // eslint-disable-next-line no-unused-vars
  async (scanId, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/scans/${scanId}/insertImages`;
    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
);
