import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import APP_CONFIG from "../../../../AppConfig";
import swal from "sweetalert";
import { setLoaderProgress } from "app/store/fuse/loaderSlice";
import CustomAlert from "../../common/CustomAlert";

export const getScanProcessDetails = createAsyncThunk(
  "admin/scanProcess/getScanProcessDetails",
  async (id) => {
    const url = APP_CONFIG.api + `v1/stepfunction/getScanProcessDetails/${id}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const stopScanProcess = createAsyncThunk(
  "admin/scanProcess/stopScanProcess",
  async (scanId) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/stopProcessScan?scanId=${scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const runningProcessScan = createAsyncThunk(
  "admin/scanProcess/getRunningProcessScan",
  async () => {
    const url = APP_CONFIG.api + `v1/stepfunction/getProcessingScans`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;

    return data;
  }
);

export const getProcessingUploads = createAsyncThunk(
  "admin/scanProcess/getProcessingUploads",
  async () => {
    const url = APP_CONFIG.api + `v1/stepfunction/getProcessingUploads`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const getTestingRunningProcessScan = createAsyncThunk(
  "admin/scanProcess/getTestingRunningProcessScan",
  async () => {
    const url = APP_CONFIG.api + `v1/stepfunction/getTestingRunningProcessScan`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;

    return data;
  }
);

export const getScanProcessingQueueSettings = (queueId) => {
  const url =
    APP_CONFIG.api + `v1/processingQueue/getProcessingModelById/${queueId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const deleteScanProcessingQueue = createAsyncThunk(
  "admin/scanProcess/deleteProcessingQueue",
  async (deleteData) => {
    const url =
      APP_CONFIG.api +
      `v1/processingQueue/deleteProcessingQueue/${deleteData.id}`;
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          deleteData.successCallback();
        }
      })
      .catch(() => {});
  }
);

export const updateProessingQueueSettings = createAsyncThunk(
  "admin/scanProcess/updateProessingQueueSettings",
  async (updateData) => {
    const url =
      APP_CONFIG.api +
      `v1/processingQueue/updateProessingQueueSettings/${updateData.id}`;
    const response = await axios.put(url, updateData.processingModel, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const updatePriorityOrder = createAsyncThunk(
  "admin/scanProcess/updatePriorityOrder",
  async (updateData) => {
    const url = APP_CONFIG.api + `v1/processingQueue/updatePriorityOrder`;
    const response = await axios.post(url, updateData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          updateData.successCallback();
        }
      })
      .catch(() => {});
  }
);

export const startProcessingScans = createAsyncThunk(
  "admin/scanProcess/startprocessingScans",
  async (processingData) => {
    const url = APP_CONFIG.api + `v1/stepfunction/startProcessingScans`;
    const response = await axios.post(url, processingData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          processingData.successCallback();
        }
      })
      .catch(() => {});
  }
);

export const startProcessingNewUploadedScans = createAsyncThunk(
  "admin/scanProcess/startProcessingNewUploadedScans",
  async (scanId) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/startProcessingNewUploadedScans`;
    // eslint-disable-next-line no-unused-vars
    const response = axios.post(url, scanId, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
);

export const startProcessingAlignToControlScan = createAsyncThunk(
  "admin/scanProcess/startProcessingAlignToControlScan",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/stepfunction/startProcessingForAlignToControlScan/${requestData.scanId}`;
    const response = await axios.post(url, requestData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((_response) => {
        if (_response.status == 200) {
          requestData.successCallback(_response.data);
        }
      })
      .catch(() => {});
  }
);

export const checkGPSposesFileExistForScan = createAsyncThunk(
  "admin/scanProcess/checkGPSposesFileExistForScan",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/stepfunction/checkGPSFileExistOnAlignToControl/${requestData.scanId}`;
    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          if (!response.data) {
            CustomAlert.show({
              type: "error",
              message:
                "3D pano image location transformation failed. Your point cloud will be aligned correctly, and support has been notified. For questions, please email support@looq.ai",
              buttons: [],
            }).then(() => {});
            //swal("", "3D pano image location transformation failed. Your point cloud will be aligned correctly, and support has been notified. For questions, please email support@looq.ai", "warning");
          }
        }
      })
      .catch(() => {});
  }
);

export const updateScanProcessingCoordinate = createAsyncThunk(
  "admin/scanProcess/updateScanProcessingCoordinate",
  async (requestData) => {
    const url =
      APP_CONFIG.api +
      `v1/stepfunction/updateScanProcessingCoordinate/${requestData.scanId}`;
    const response = await axios.put(url, requestData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          requestData.successCallback(response.data);
        }
      })
      .catch(() => {});
  }
);

export const startTestingProcessingScans = createAsyncThunk(
  "admin/scanProcess/startTestingProcessingScans",
  async (processingData, { dispatch }) => {
    dispatch(setLoaderProgress(true));
    const url = APP_CONFIG.api + `v1/stepfunction/startTestingProcessingScans`;
    const response = await axios.post(url, processingData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        dispatch(setLoaderProgress(false));
        if (response.status == 200) {
          processingData.successCallback();
        }
      })
      .catch(() => {
        dispatch(setLoaderProgress(false));
      });
  }
);

export const downloadLogFileForScan = createAsyncThunk(
  "admin/scanProcess/downloadLogFileForScan",
  async (processingId) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/getDownloadScanLogFile/${processingId}`;
    const response = axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.resolve(response)
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            const a = document.createElement("a");
            a.download = "test.bin";
            a.href = response.data;
            a.style.display = "none";
            document.body.append(a);
            a.click();
            a.remove();
          } else {
            swal("", "Log File Not Found", "warning");
          }
        }
      })
      .catch(() => {
        swal("", "Failed to download", "error");
      });
  }
);

export const updateIsScanAutoProcessing = createAsyncThunk(
  "admin/scanProcess/updateIsScanAutoProcessing",
  async (requestData) => {
    const url = APP_CONFIG.api + `v1/stepfunction/updateIsScanAutoProcessing`;
    const response = await axios.post(url, requestData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((response) => {
        if (response.status == 200) {
          requestData.successCallback();
        }
      })
      .catch(() => {});
  }
);

export const getScanProcessingStatusUpdateLog = (processingId) => {
  const url =
    APP_CONFIG.api +
    `v1/stepfunction/getScanProcessingStatusUpdateLog/${processingId}`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getProcessingSettingsExtraTasksValue = () => {
  const url =
    APP_CONFIG.api + `v1/stepfunction/getProcessingSettingsExtraTasksValue`;

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const updateExtraTasksValue = (value) => {
  const url =
    APP_CONFIG.api + `v1/stepfunction/updateProcessingExtraTasksValue`;
  const response = axios.put(url, value, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getScanAlignToControlStatus = createAsyncThunk(
  "admin/scanProcess/getScanAlignToControlStatus",
  async (scanId) => {
    const url =
      APP_CONFIG.api + `v1/stepfunction/getAlignToControlStatus/${scanId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

const scanProcessSlice = createSlice({
  name: "admin/scanProcess",
  initialState: {
    scanCloudWatchLogs: [],
    scanProcessDetails: {},
    loadingState: "",
    stopScanProcessState: "",
    sites: [],
    runningScans: [],
    pendingScans: [],
    completedScans: [],
    scanProcessingHistory: [],
    scanProcessingTaskStatusHistories: [],
    testRunningScans: [],
    testingPendingScans: [],
    testingCompletedScans: [],
    queuedScans: [],
    onHoldScans: [],
    failedScans: [],
    testingQueuedScans: [],
    testingOnHoldScans: [],
    testingFailedScans: [],
    isScanAutoProcessing: false,
    notLinkedScans: [],
    processingUploads: [],
    pendingUploads: [],
    failedUploads: [],
    scanProcessingCoordinates: [],
    scanAlignToControlStatus: false,
  },
  reducers: {
    resetScanProcessState: (state) => {
      state.scanCloudWatchLogs = [];
      state.scanProcessDetails = {};
      state.stopScanProcessState = "";
      state.scanProcessingHistory = [];
      state.scanProcessingTaskStatusHistories = [];
      state.queuedScans = [];
    },
    updateQueuedScans: (state, action) => {
      state.queuedScans = action.payload;
    },
    setUpdateIsScanAutioProcessing: (state, action) => {
      state.isScanAutoProcessing = action.payload;
    },
  },
  extraReducers: {
    [getScanProcessDetails.fulfilled]: (state, action) => {
      state.scanProcessDetails = action.payload.scanProcessDetails;
      state.scanCloudWatchLogs = action.payload.scanCloudWatchLogs;
      state.scanProcessingHistory = action.payload.scanProcessingHistory;
      state.scanProcessingTaskStatusHistories =
        action.payload.scanProcessingTaskStatusHistories;
      state.loadingState = SERVICE_STATUS.fulfilled;
      state.scanProcessingCoordinates =
        action.payload.scanProcessingCoordinates;
    },
    [stopScanProcess.fulfilled]: (state) => {
      state.stopScanProcessState = SERVICE_STATUS.fulfilled;
    },
    [runningProcessScan.fulfilled]: (state, action) => {
      state.runningScans = action.payload.runningScan;
      state.pendingScans = action.payload.pendingScans;
      state.completedScans = action.payload.completedScans;
      state.sites = action.payload.sites;
      state.queuedScans = action.payload.queueScans;
      state.onHoldScans = action.payload.onholdScans;
      state.failedScans = action.payload.failedScans;
      state.isScanAutoProcessing = action.payload.isScanAutoProcessing;
      state.notLinkedScans = action.payload.notLinkedScans;
    },
    [getProcessingUploads.fulfilled]: (state, action) => {
      state.processingUploads = action.payload.processingUploads;
      state.pendingUploads = action.payload.pendingUploads;
      state.failedUploads = action.payload.failedUploads;
    },
    [getTestingRunningProcessScan.fulfilled]: (state, action) => {
      state.testRunningScans = action.payload.runningScan;
      state.testingPendingScans = action.payload.pendingScans;
      state.testingCompletedScans = action.payload.completedScans;
      state.sites = action.payload.sites;
      state.testingOnHoldScans = action.payload.onholdScans;
      state.testingFailedScans = action.payload.failedScans;
      state.testingQueuedScans = action.payload.queueScans;
    },
    [getScanAlignToControlStatus.fulfilled]: (state, action) => {
      state.scanAlignToControlStatus = action.payload;
    },
  },
});
export const {
  resetScanProcessState,
  updateQueuedScans,
  setUpdateIsScanAutioProcessing,
} = scanProcessSlice.actions;

export default scanProcessSlice.reducer;
