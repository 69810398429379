import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APP_CONFIG from "app/AppConfig";

export const deleteOldScanUploadFiles = createAsyncThunk('scan/scanApp/deleteOldScanUploadFiles', async (dataObj, { dispatch, getState }) => {
    const url = APP_CONFIG.api + `v1/scan/deleteOldScanUploadFiles`;
    var formData = new FormData();
    formData.append("scanId", dataObj.scanId);
    formData.append("fileKey", dataObj.fileKey)
    axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
})
