import React, { useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import Checkbox from '@mui/material/Checkbox';
import { ColorPicker, createColor } from 'material-ui-color';
import ClassificationActions from '../../common/ClassificationActions';


const OuterPanel = styled.div`
`;

const VisiblePanel = styled.div`
  width: 250px;
`;

const Classification = styled.div`
	display: flex;
`
// export const DEFAULT_CLASSIFICATIONS = {
// 	0: {visible: true, name: 'Never Classified', color: [0.5, 0.5, 0.5, 1.0]},
// 	1: {visible: true, name: 'Unclassified', color: [0.5, 0.5, 0.5, 1.0]},
// 	2: {visible: true, name: 'Ground', color: [0.63, 0.32, 0.18, 1.0]},
// 	5: {visible: true, name: 'Vegetation', color: [0.0, 1.0, 0.0, 1.0]},
// 	6: {visible: true, name: 'Building', color: [1.0, 0.66, 0.0, 1.0]},
// 	7: {visible: true, name: 'Low Point (noise)', color: [1.0, 0.0, 1.0, 1.0]},
// 	13: {visible: true, name: 'Fire Hydrant', color: [1.0, 0.6, 0.0, 1.0]},
// 	18: {visible: true, name: 'Pole', color: [0.7, 0.0, 1.0, 1.0]},
// 	21: {visible: true, name: 'Wire', color: [0.0, 0.0, 0.0, 1.0]},
// 	30: {visible: true, name: 'Sign', color: [0.7, 0.7, 0.7, 1.0]},
// 	31: {visible: true, name: 'Parking Meter', color: [0.5, 0.9, 1.0, 1.0]},
// 	DEFAULT: {visible: true, name: 'Default', color: [0.3, 0.6, 0.6, 0.5]}
// };
export const DEFAULT_CLASSIFICATIONS = {
	0: { visible: true, name: 'Never Classified', color: [0.5, 0.5, 0.5, 1.0] },
	1: { visible: true, name: 'Unclassified', color: [0.5, 0.5, 0.5, 1.0] },
	2: { visible: true, name: 'Ground', color: [0.63, 0.32, 0.18, 1.0] },
	5: { visible: true, name: 'Vegetation', color: [0.0, 1.0, 0.0, 1.0] },
	6: { visible: true, name: 'Building', color: [1.0, 0.66, 0.0, 1.0] },
	13: { visible: true, name: 'Distribution Structure', color: [0.5, 0.5, 0.5, 1.0] },
	14: { visible: true, name: 'Distribution Wire', color: [0.0, 0.0, 0.0, 1.0] },
	17: { visible: true, name: 'Conduit', color: [1.0, 1.0, 0.0, 1.0] },
	18: { visible: true, name: 'Fence', color: [0.5, 0.0, 0.7, 1.0] },
	19: { visible: true, name: 'Paved Surface', color: [0.3, 1.0, 1.0, 1.0] },
	20: { visible: true, name: 'Side Walk', color: [0.3, 0.5, 0.0, 1.0] },
	21: { visible: true, name: 'Misc Object', color: [0.2, 0.9, 0.1, 1.0] },
	22: { visible: true, name: 'Wall', color: [0.5, 0.5, 0.5, 1.0] },
	7: { visible: true, name: 'Low Point (noise)', color: [1.0, 0.0, 1.0, 1.0] },
	DEFAULT: { visible: true, name: 'Default', color: [0.3, 0.6, 0.6, 0.5] },
};



function SiteClassificationView(props) {

	const { classificationList, onColorChange, onToggleClassificationVisibility } = props;
	const classificationRef = useRef();

	useEffect(() => {
		ClassificationActions.removeBackgroundClass(classificationRef.current);
	}, []);


	function changeColor(color, i) {
		onColorChange(color, i);
	}


	return (
		<OuterPanel ref={classificationRef}>
			{classificationList.map((c, i) => (
				<Classification key={i} className='justify-between'>
					<VisiblePanel>
						<Checkbox checked={c.isChecked} sx={{
							color: '#6CCCD7',
							'&.Mui-checked': {
								color: '#6CCCD7',
							},
						}}
							onChange={() => onToggleClassificationVisibility(i)}
							id={"" + c.key} />
						<span>{c.classificationName}</span>
					</VisiblePanel>
					<ColorPicker
						value={c.colorCode}
						onChange={color => changeColor(color, i)}
						hideTextfield={true} />
				</Classification>
			))}
		</OuterPanel>
	);
}

export default SiteClassificationView;