// import styled from "styled-components";
//
// export const PageContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   align-items: center;
//   justify-content: space-around;
//   padding: 0 40px;
//   background-image: url(/banner-bg.png);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// `;
//
// export const Title = styled.h1`
//   color: #59bec9;
//   font-size: 150px;
//   margin-bottom: 6px;
// `;
//
// export const TopImage = styled.div`
//     position: "absolute",
//     top: 20,
//     left: 20,
//     width: "180px",
//     zIndex: 2,
// `;
//
// export const CTABox = styled.div`
//     textAlign: "left",
//     minWidth: "400px",
// `;
//
const styles = {
  pageContainer: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "space-around", // Ensures equal spacing between elements
    // position: "relative",
    padding: "0 40px",
    // background: "linear-gradient(to bottom right, #D9E2EC, white)",
    backgroundImage: `url(/banner-bg.png)`, // Set the background image
    backgroundSize: "cover", // Ensure the image covers the whole page
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat",
  },
  callToActionBox: {
    textAlign: "left",
    minWidth: "400px",
  },
  title: {
    color: "#59bec9",
    fontSize: "150px",
    marginBottom: 6,
  },
  subtitle: {
    color: "#333333",
    marginBottom: 4,
  },
  looqImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo512Image: {
    width: "750px",
    height: "auto",
  },
  buttonPrimary: {
    padding: "8px 16px",
    zIndex: 99,
    backgroundColor: "#59bec9",
    color: "white",
    "&:hover": {
      backgroundColor: "#4ea5b0",
    },
  },
  buttonSecondary: {
    padding: "8px 16px",
    zIndex: 99,
    backgroundColor: "#9d9d9d",
    color: "white",
    marginLeft: 2,
    "&:hover": {
      backgroundColor: "#8c8c8c",
    },
  },
  topLeftImage: {
    position: "absolute",
    top: 20,
    left: 20,
    width: "180px",
    zIndex: 2,
  },
};

export default styles;
