import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import clsx from "clsx";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { selectToolbarTheme } from "app/store/fuse/settingsSlice";
import CustomImage from "app/shared-components/image/CustomImage";
import { Typography } from "@mui/material";
import { useNavigate, Link as DomLink } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import { MenuItem, ListItemIcon } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AddScanDialog from "app/shared-components/dialogs/AddScanDialog";
import ScanUpdateNotificationDialog from "app/shared-components/dialogs/ScanUpdateNotificationDialog";
import ScanPendingNotificationAlertDialog from "app/shared-components/dialogs/ScanPendingNotificationAlertDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ToolbarAddButton from "app/main/apps/toolbar/ToolbarAddButton";

const useStyles = makeStyles(() =>
  createStyles({
    active: {
      backgroundColor: "#59BEC9",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#59BEC9",
        color: "#fff",
      },
      // pointerEvents: 'none'
    },
    addScanButton: {
      backgroundColor: "#7E7E7E",
      color: "#fff",
      borderRadius: "40px",
      fontSize: "11px",
      fontWeight: "600",
      width: "95px",
      height: "42px",
      marginLeft: "8px",
      "&:hover": {
        backgroundColor: "#7E7E7E",
        color: "#fff",
      },
    },
  })
);

// eslint-disable-next-line complexity
function ToolbarLayout3(props) {
  const toolbarTheme = useSelector(selectToolbarTheme);
  const { url } = useSelector(({ auth }) => auth.logo);
  const navigate = useNavigate();
  const { role } = useSelector(({ auth }) => auth.user);
  const user = useSelector(({ auth }) => auth.user);
  const customer = useSelector(({ auth }) => auth.customer);
  const { showSiteViewerAddScanButton } = useSelector(
    ({ auth }) => auth.customNavigation
  );

  const [showAddScanDialog, setShowAddScanDialog] = useState(false);

  const classes = useStyles();

  const handleOnCreateSiteClick = () => {
    setShowAddScanDialog(false);
    navigate(`/apps/site/sitecreation`);
  };

  const handleAddScanDialogClose = () => {
    setShowAddScanDialog(false);
  };

  const handleOnCreateScanClick = () => {
    setShowAddScanDialog(false);
    navigate(`/apps/scan/scancreation`);
  };

  const handleOnCreateProjectClick = () => {
    setShowAddScanDialog(false);
    navigate(`/apps/admin/projectcreateoredit`);
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md", props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="py-12 lg:px-16 min-h-48 md:min-h-64 justify-between">
          <div className="flex">
            <div className={clsx("flex shrink-0 items-center")}>
              {url ? (
                <div style={{ pointerEvents: "none" }}>
                  {" "}
                  <CustomImage
                    width="110px"
                    src={url}
                    resize={true}
                    renderWidth={110}
                    renderHeight={63}
                  ></CustomImage>{" "}
                </div>
              ) : (
                <img width="110px" src="assets/images/logos/looq.png"></img>
              )}
            </div>
            {user.role.includes("demouser") ? (
              <div className="flex mx-24 justify-center items-center">
                <MenuItem
                  style={{ borderRadius: "10px" }}
                  className={
                    window.location.pathname == "/apps/dashboard/demousersites"
                      ? classes.active
                      : ""
                  }
                  component={DomLink}
                  to="/apps/dashboard/demousersites"
                  role="button"
                >
                  <div className="flex-col text-center">
                    <ListItemIcon
                      className="min-w-40"
                      style={{ display: "block" }}
                    >
                      <HomeIcon
                        style={{
                          color:
                            window.location.pathname ==
                            "/apps/dashboard/demousersites"
                              ? "#fff"
                              : "#626262",
                        }}
                      >
                        {" "}
                      </HomeIcon>
                    </ListItemIcon>
                    {/* <ListItemText primary="Home" /> */}
                    <Typography
                      style={{
                        color:
                          window.location.pathname ==
                          "/apps/dashboard/demousersites"
                            ? "#fff"
                            : "#626262",
                        fontWeight: "600",
                        fontSize: "11px",
                      }}
                    >
                      Home
                    </Typography>
                  </div>
                </MenuItem>
              </div>
            ) : (
              <div className="flex mx-24 justify-center items-center">
                <MenuItem
                  style={{ borderRadius: "10px" }}
                  className={
                    window.location.pathname == "/apps/dashboards/sites"
                      ? classes.active
                      : ""
                  }
                  component={DomLink}
                  to="/apps/dashboards/sites"
                  role="button"
                >
                  <div className="flex-col text-center">
                    <ListItemIcon
                      className="min-w-40"
                      style={{ display: "block" }}
                    >
                      <HomeIcon
                        style={{
                          color:
                            window.location.pathname == "/apps/dashboards/sites"
                              ? "#fff"
                              : "#626262",
                        }}
                      >
                        {" "}
                      </HomeIcon>
                    </ListItemIcon>
                    {/* <ListItemText primary="Home" /> */}
                    <Typography
                      style={{
                        color:
                          window.location.pathname == "/apps/dashboards/sites"
                            ? "#fff"
                            : "#626262",
                        fontWeight: "600",
                        fontSize: "11px",
                      }}
                    >
                      Home
                    </Typography>
                  </div>
                </MenuItem>
                {(user.role.includes("organisationadmin") ||
                  (customer.accountingPermission &&
                    customer.accountCustomers.length > 0) ||
                  user.role.includes("admin") ||
                  user.role.includes("user")) && (
                  <MenuItem
                    style={{ borderRadius: "10px" }}
                    className={
                      window.location.pathname ==
                      "/apps/admin/organizationsettings"
                        ? classes.active
                        : ""
                    }
                    component={DomLink}
                    to="/apps/admin/organizationsettings"
                    role="button"
                  >
                    <div className="flex-col text-center">
                      <ListItemIcon
                        className="min-w-40"
                        style={{ display: "block" }}
                      >
                        <PeopleOutlinedIcon
                          style={{
                            color:
                              window.location.pathname ==
                              "/apps/admin/organizationsettings"
                                ? "#fff"
                                : "#626262",
                          }}
                        />
                      </ListItemIcon>
                      {/* <ListItemText primary="Organization" /> */}
                      <Typography
                        style={{
                          color:
                            window.location.pathname ==
                            "/apps/admin/organizationsettings"
                              ? "#fff"
                              : "#626262",
                          fontWeight: "600",
                          fontSize: "11px",
                        }}
                      >
                        Organization
                      </Typography>
                    </div>
                  </MenuItem>
                )}

                <MenuItem style={{ borderRadius: "10px" }} role="button">
                  <div className="flex-col text-center">
                    <a
                      href="https://www.looq.ai/docs2/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ListItemIcon
                        className="min-w-40"
                        style={{ display: "block" }}
                      >
                        <HelpOutlineIcon style={{ color: "#626262" }} />
                      </ListItemIcon>
                      <Typography
                        style={{
                          color: "#626262",
                          fontWeight: "600",
                          fontSize: "11px",
                        }}
                      >
                        Help
                      </Typography>
                    </a>
                  </div>
                </MenuItem>

                {showSiteViewerAddScanButton ? (
                  <ToolbarAddButton
                    role={role}
                    handleOnCreateScanClick={handleOnCreateScanClick}
                    handleOnCreateSiteClick={handleOnCreateSiteClick}
                    handleOnCreateProjectClick={handleOnCreateProjectClick}
                  />
                ) : (
                  // <Button
                  //   className={`${classes.addScanButton}`}
                  //   startIcon={<AddCircleOutlineIcon />}
                  //   onClick={handleOnScanCreationClick}
                  // >Add Scan</Button>
                  <>
                    {
                      (role.includes("admin") ||
                        role.includes("organisationadmin") ||
                        role.includes("projectmanager") ||
                        role.includes("user")) && (
                        <ToolbarAddButton
                          role={role}
                          handleOnCreateScanClick={handleOnCreateScanClick}
                          handleOnCreateSiteClick={handleOnCreateSiteClick}
                          handleOnCreateProjectClick={
                            handleOnCreateProjectClick
                          }
                        />
                      )
                      // <Button
                      //   className={`${classes.addScanButton}`}
                      //   startIcon={<AddCircleOutlineIcon />}
                      //   onClick={() => handleOnAdminScanCreateClick()}
                      // >Add Scan</Button>
                    }
                  </>
                )}
                {user.role.includes("admin") && (
                  <MenuItem
                    style={{ borderRadius: "10px", marginLeft: "8px" }}
                    className={
                      window.location.pathname.includes("/apps/appAdmin")
                        ? classes.active
                        : ""
                    }
                    component={DomLink}
                    to={
                      window.location.pathname.includes("/apps/appAdmin")
                        ? window.location.pathname
                        : "/apps/appAdmin/dashboard"
                    }
                    role="button"
                  >
                    <div className="flex-col text-center">
                      <ListItemIcon
                        className="min-w-40"
                        style={{ display: "block" }}
                      >
                        <AdminPanelSettingsIcon
                          style={{
                            color: window.location.pathname.includes(
                              "/apps/appAdmin"
                            )
                              ? "#fff"
                              : "#626262",
                          }}
                        >
                          {" "}
                        </AdminPanelSettingsIcon>
                      </ListItemIcon>
                      {/* <ListItemText
                      primary="Admin"
                      style={{
                        'color': window.location.pathname.includes("/apps/appAdmin") ? '#fff' : '#626262',
                        'fontWeight': '600'
                      }}
                    /> */}
                      <Typography
                        style={{
                          color: window.location.pathname.includes(
                            "/apps/appAdmin"
                          )
                            ? "#fff"
                            : "#626262",
                          fontWeight: "600",
                          fontSize: "11px",
                        }}
                      >
                        Admin
                      </Typography>
                    </div>
                  </MenuItem>
                )}
              </div>
            )}
            <div className="flex items-center">
              {/* <Navigation className="w-full" layout="horizontal" dense /> */}
            </div>
          </div>

          <div className="flex items-center px-8 md:px-0 h-full overflow-x-auto">
            <UserMenu />
          </div>

          {showAddScanDialog && (
            <AddScanDialog
              handleClose={handleAddScanDialogClose}
              handleOnCreateScanClick={handleOnCreateScanClick}
              handleOnCreateSiteClick={handleOnCreateSiteClick}
            />
          )}
          <ScanUpdateNotificationDialog />
          <ScanPendingNotificationAlertDialog />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout3);
