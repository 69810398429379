import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    url : ''

};

const logoSlice = createSlice({
    name: 'auth/logo',
    initialState,
    reducers: {
        setLogoUrl: (state, action) => action.payload,
    },
    extraReducers: {},
});

export const { setLogoUrl } = logoSlice.actions;

export default logoSlice.reducer;