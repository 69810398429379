import UPLOAD_TYPE from "../UploadType";

export const getChunkData = (uploadObject, isFinalPart) => {
  return isFinalPart
    ? uploadObject.file.slice(
        uploadObject.beginningOfTheChunk,
        uploadObject.file.size
      )
    : uploadObject.file.slice(
        uploadObject.beginningOfTheChunk,
        uploadObject.endOfTheChunk
      );
};

export const createFormData = (
  uploadObject,
  chunk,
  isFinalPart,
  currentChunkCount
) => {
  const formData = new FormData();
  formData.append("file", chunk);
  formData.append("uploadId", uploadObject.uploadId);
  formData.append("PartNum", currentChunkCount + 1);
  formData.append("isFinalPart", isFinalPart);
  formData.append("uploadKey", uploadObject.uploadKey);
  formData.append("objectType", uploadObject.type);
  return formData;
};

export const calculateUploadProgress = (
  uploadObject,
  isFinalPart,
  currentUploadedChunk
) => {
  return isFinalPart
    ? currentUploadedChunk +
        (uploadObject.file.size - uploadObject.beginningOfTheChunk)
    : currentUploadedChunk + uploadObject.chunkSize;
};

export const createNewUploadObject = (
  uploadObject,
  response,
  isFinalPart,
  uploadSpeed
) => ({
  ...uploadObject,
  currentChunkCount: uploadObject.currentChunkCount + 1,
  beginningOfTheChunk: isFinalPart
    ? uploadObject.beginningOfTheChunk
    : uploadObject.endOfTheChunk,
  endOfTheChunk: isFinalPart
    ? uploadObject.endOfTheChunk
    : uploadObject.endOfTheChunk + uploadObject.chunkSize,
  partETags: [...uploadObject.partETags, response.data],
  uploadSpeed,
  chunkPartTimeStart: new Date(),
});
