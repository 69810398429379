import React, { useState } from 'react';

import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    FormControl,
    TextField,
    DialogTitle,
    Button,
    DialogActions
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OuterPanel, Close } from './CoordinateEditor.style';

function CoordinateSave(props) {

    const { onSaveCoordinate, onClose, siteCoordinates } = props;

    const [saveModel, setSaveModel] = useState({
        name: '',
        description: ''
    })
    const [isValidForm, setIsValidForm] = useState(true)

    const [isValidName, setIsValidName] = useState(true)

    const handleOnChangeValues = (e) => {
        try {
            setSaveModel({ ...saveModel, [e.target.name]: e.target.value });
        } catch (error) {
            console.error(error);
        }
    }

    const onClickSave = () => {
        setIsValidForm(true);
        setIsValidName(true);
        if (validateForm()) {
            if (siteCoordinates.filter(x => x == saveModel.name).length == 0) {
                setIsValidName(true);
                onSaveCoordinate(saveModel);
            }
            else {
                setIsValidName(false);
            }
        }
    }

    const validateForm = () => {
        if (saveModel.name) {
            setIsValidForm(true);
            return true;
        } else {
            setIsValidForm(false);
            return false;
        }
    }

    return (
        <Dialog open={true} onClose={() => onClose()} maxWidth="md">
            <Close>
                <CloseIcon onClick={onClose} />
            </Close>
            <OuterPanel>
                <DialogTitle className="flex flex-col items-center justify-center w-full pb-4">Save Coordinates</DialogTitle>
                <div>
                    <DialogContent className="flex flex-col items-center justify-center w-full py-0">
                        {isValidForm == true ? null : <p className="text-red">All fields are mandatory</p>}
                        {isValidName == true ? null : <p className="text-red">Name already exists please provide different name</p>}
                        <FormControl className='mx-0 mt-12' sx={{ m: 1, minWidth: 300 }}>
                            <TextField
                                id="outlined-basic"
                                label="Name"
                                name="name"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleOnChangeValues(e)}
                                value={saveModel.name}
                                error={(!isValidForm && saveModel.name == '') || !isValidName}
                            />
                        </FormControl>
                        <FormControl className='mx-0 mt-12' sx={{ m: 1, minWidth: 300 }}>
                            <TextField
                                id="outlined-basic"
                                label="Description"
                                name="description"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleOnChangeValues(e)}
                                value={saveModel.description}
                            />
                        </FormControl>


                    </DialogContent>
                </div>

                <DialogActions className="flex flex-row items-center justify-center w-full">
                    <Button style={{
                        'width': '105px',
                        'height': '2',
                        'borderRadius': '17.5px',
                        'borderColor': '#E0604C',
                        color: '#E0604C'
                    }} variant='outlined' onClick={() => onClose()}>Cancel</Button>
                    <Button style={{
                        width: '105px',
                        height: '2',
                        borderRadius: '17.5px',
                        border: 'solid 1px',
                        color: '#58BEC9'
                    }} variant='outlined' onClick={() => onClickSave()}>Save</Button>
                </DialogActions>


            </OuterPanel>
        </Dialog>
    );
}

CoordinateSave.propTypes = {
    onSaveCoordinate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    siteCoordinates: PropTypes.array.isRequired
};

export default CoordinateSave;