import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  updateShowNotifications,
  getPendingNotifications,
} from "../admin/store/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import ScanNotificationCard from "./ScanNotificationCard";
import { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Tooltip,
  Button,
} from "@mui/material";
import { loadAllAdminAccounts } from "../admin/store/accountSlice";
import ScanWifiUploadNotificationCard from "./ScanWifiUploadNotificationCard";
import CachedIcon from "@mui/icons-material/Cached";
import ProjectInvoiceNotificationCard from "./ProjectInvoiceNotificationCard";
import NotificationConstants from "../constants/NotificationConstants";
import AlignToControlNotificationCard from "./AlignToControlNotificationCard";
import { clearAlignToControlNotifications } from "../admin/store/notificationSlice";
import RevertToOriginalNotificationCard from "./RevertToOriginalNotificationCard";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.default,
    width: 400,
  },
}));

function NotificationsApp() {
  const { showNotifications, notifications } = useSelector(
    ({ admin }) => admin.notificationsApp
  );
  const dispatch = useDispatch();
  const { accounts } = useSelector(({ admin }) => admin.accountApp);
  const { role } = useSelector(({ auth }) => auth.user);
  const [selectedOrganization, setSelectedOrganization] = useState(0);

  useEffect(() => {
    if (role.includes("admin")) {
      dispatch(loadAllAdminAccounts());
    }
  }, [dispatch]);
  useEffect(() => {
    if (showNotifications) {
      dispatch(getPendingNotifications());
    }
  }, [showNotifications]);

  const handleClose = () => {
    dispatch(updateShowNotifications(false));
  };

  const getFilteredNotificationData = () => {
    var notificationData = notifications.filter((s) =>
      selectedOrganization == 0
        ? true
        : s.organizationId == selectedOrganization
    );
    notificationData = stableSort(
      notificationData,
      getComparator("desc", "createTime")
    );
    return notificationData;
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] || a[orderBy]) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const stableSort = (array, comparator) => {
    const dataExistArray = array.filter((x) =>
      checkPropertyExist(x, "createTime")
    );
    const dataNotExistArray = array.filter(
      (x) => !checkPropertyExist(x, "createTime")
    );
    const stabilizedThis = dataExistArray.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    var finalArray = stabilizedThis.map((el) => el[0]);
    dataNotExistArray.forEach((obj) => {
      finalArray.push(obj);
    });

    return finalArray;
  };

  const checkPropertyExist = (obj, property) => {
    if (obj[property]) {
      return true;
    } else {
      return false;
    }
  };

  const handleRefreshNotifications = () => {
    setRunTimer(false);
    setCountDown(0);
    if (showNotifications) {
      dispatch(getPendingNotifications());
    }
  };

  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 1);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
      setRunTimer(true);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      handleRefreshNotifications();
    }
  }, [countDown, runTimer]);

  const showClearAllNotification = (types) =>{
  var count = 0;
    types.forEach(type => {
        count =count + notifications.filter(x => x.notificationType == type).length;
      });
    return count;
  }

  const GetNotificationCard = (notification) => {
    if (notification.notificationType == NotificationConstants.SCAN_PENDING) {
      return (
        <ScanNotificationCard
          notification={notification}
          handleClose={handleClose}
        />
      );
    }
    if (notification.notificationType == NotificationConstants.SCAN_WIFI_COPY) {
      return <ScanWifiUploadNotificationCard notification={notification} />;
    }
    if (
      notification.notificationType == NotificationConstants.INVOICE_PENDING
    ) {
      return <ProjectInvoiceNotificationCard notification={notification} />;
    }
    if (
      notification.notificationType == NotificationConstants.ALIGN_TO_CONTROL
    ) {
      return <AlignToControlNotificationCard notification={notification} handleRefreshNotifications={handleRefreshNotifications} />;
    }
    if(notification.notificationType == NotificationConstants.REVERT_TO_ORIGINAL){
      return <RevertToOriginalNotificationCard notification= {notification} handleRefreshNotifications={handleRefreshNotifications} />;
    }
  };

  const handleOnClearNotification = () => {
    var requestData = {
      successCallback : handleRefreshNotifications
    }
    dispatch(clearAlignToControlNotifications(requestData));
  };

  return (
    <>
      {showNotifications && (
        <StyledSwipeableDrawer
          open={showNotifications}
          anchor="right"
          onClose={() => dispatch(updateShowNotifications(false))}
          disableSwipeToOpen
        >
          <div className="p-16 flex flex-col">
            <div className="flex justify-between items-end mb-24">
              <Typography className="text-28 font-semibold leading-none">
                Notifications
              </Typography>
              <div className="m-4 absolute top-0 right-0 z-999">
                <Tooltip title="Refresh">
                  <IconButton
                    size="large"
                    onClick={() => handleRefreshNotifications()}
                  >
                    <CachedIcon style={{ color: "#000000" }} />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={() => handleClose()} size="large">
                  <Icon color="action">close</Icon>
                </IconButton>
              </div>
            </div>
            {role.includes("admin") && (
              <div className="flex justify-between items-end mb-24">
                <div className="mr-32 w-full">
                  <FormControl
                    sx={{
                      width: { sm: 30, md: 180 },
                      "& .MuiInputBase-root": { height: 30 },
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Organization
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedOrganization}
                      name="accountId"
                      onChange={(e) => {
                        setSelectedOrganization(e.target.value);
                      }}
                      className="form-control"
                      label="Organization"
                    >
                      <MenuItem key={0} value={0}>
                        All
                      </MenuItem>
                      {accounts.map((account) => (
                        <MenuItem value={account.id}>{account.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
          <FuseScrollbars className="p-16">
            <div className="flex flex-col">
              {getFilteredNotificationData().map((notification) => (
                <>{GetNotificationCard(notification)}</>
              ))}
            </div>
          </FuseScrollbars>
          {showClearAllNotification([NotificationConstants.ALIGN_TO_CONTROL,NotificationConstants.REVERT_TO_ORIGINAL]) > 0 && (
            <div className="flex justify-end">
              <Button
                style={{ position: "sticky" }}
                onClick={() => handleOnClearNotification()}
              >
                Clear All
              </Button>
            </div>
          )}
        </StyledSwipeableDrawer>
      )}
    </>
  );
}
export default NotificationsApp;
