import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Spacer = styled.div`
  width: 50px;
  display: inline-block;
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-bottom: 3px;
  height: 60px;
  color: #ffffff;
  font-size: 28px;
  border-radius: 5px;
`;

export const Control = styled.div`
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 10px 10px;
  width: 40px;
  height: 40px;
`;
