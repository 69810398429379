class KeyEventListeners {
  static callbackFunc = null;

  static addEventListener(callback) {
    KeyEventListeners.callbackFunc = callback;
    window.addEventListener("keydown", KeyEventListeners.handleonkeypress);
  }

  static removeEventListener() {
    window.removeEventListener("keydown", KeyEventListeners.handleonkeypress);
    KeyEventListeners.callbackFunc = null;
  }

  static handleonkeypress = (event) => {
    var element = event.target;
    var type = element.getAttribute("type");
    if (type != "text") {
      event.preventDefault();
      if (KeyEventListeners.callbackFunc) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "z") {
          KeyEventListeners.callbackFunc("undo");
        } else if ((event.ctrlKey || event.metaKey) && charCode === "y") {
          KeyEventListeners.callbackFunc("redo");
        } else if ((event.ctrlKey || event.metaKey) && charCode === "s") {
          KeyEventListeners.callbackFunc("save");
        }
      }
    }
  };
}

export default KeyEventListeners;
