import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { selectCurrLangDir } from "app/store/i18nSlice";
import withAppProviders from "./withAppProviders";
import { AuthFilter } from "./auth/AuthFilter";
import { AuthProvider } from "./auth/AuthProvider";
import LoadingScreen from "./LoadingScreen";
import { useSelector } from "react-redux";

const emotionCacheOptions = {
  rtl: {
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

const App = () => {
  const langDirection = useSelector(selectCurrLangDir);
  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <LoadingScreen />
      <AuthProvider>
        <BrowserRouter>
          <AuthFilter>
            <FuseAuthorization>
              <FuseTheme>
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  classes={{
                    containerRoot:
                      "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
                  }}
                >
                  <FuseLayout />
                </SnackbarProvider>
              </FuseTheme>
            </FuseAuthorization>
          </AuthFilter>
        </BrowserRouter>
      </AuthProvider>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
