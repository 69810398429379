class ClassificationActions {
    static removeBackgroundClass = (element) => {
        setTimeout(() => {
            const elements = document.querySelectorAll('[class^="jss"]');
            elements.forEach(ele => {
                ele.classList.forEach(cls => {
                    if (cls.startsWith('jss')) {
                        ele.classList.remove(cls);
                    }
                });
            });
        }, 200);
    }
}

export default ClassificationActions;