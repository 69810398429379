const ONE_DAY = 1000 * 60 * 60 * 24;

export const daysInAYear = (year) => {
  return isLeapYear(year) ? 366 : 365;
};

export const dayInAYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date - start;
  return Math.floor(diff / ONE_DAY);
};

const isLeapYear = (year) => {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
};
