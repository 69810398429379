import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import swal from 'sweetalert';
import APP_CONFIG from 'app/AppConfig';
import { getPendingNotifications } from './notificationSlice';


export const connectSocket = createAsyncThunk('admin/socketApp/connectSocket', async (_, { dispatch, getState }) => {

    if (getState().admin.socketApp.stompClient == null) {
        const onConnected = () => {
            getState().admin.socketApp.stompClient.subscribe('/message/beforeserviceupdate', onMessageReceived);
            getState().admin.socketApp.stompClient.subscribe('/message/afterserviceupdate', onMessageReceived);
            var userId = getState().auth.customer.id
            getState().admin.socketApp.stompClient.subscribe(`/message/notification/user/${userId}`, onNotitificationReceived)
            var role = getState().auth.user.role;
            if (role.includes('admin')) {
                getState().admin.socketApp.stompClient.subscribe(`/message/notification/scan/all`, onNotitificationReceived);
            } else if (role.includes('organisationadmin') || getState().auth.customer.accountingPermission) {
                var accountCustomers = getState().auth.customer.accountCustomers
                if (accountCustomers.length > 0) {
                    var organizationId = getState().auth.customer.accountCustomers[0].accountId;
                    getState().admin.socketApp.stompClient.subscribe(`/message/notification/organization/scan/${organizationId}`, onNotitificationReceived);
                }
            }
            dispatch(setIsSocketConnected(true));
        }
        const onError = (err) => {
            if (err.includes('Whoops! Lost connection')) {

                dispatch(setStompClient(null));
                dispatch(setIsSocketConnected(false));
                setTimeout(() => {
                    dispatch(connectSocket());
                }, 30000)
            }
            console.log(err);
        }

        const onMessageReceived = (payload) => {
            if (payload.body == 'true') {
                dispatch(setIsAppUpdate(true));
                dispatch(setUploadEnabled(false));
                if (getState().files.uploadApp.largeUploadingFiles.length != 0 || getState().files.uploadApp.smallUploadingFiles.length != 0) {
                    dispatch(setShowUploadPopup(true))
                }
            }
            else if (payload.body == 'false') {
                dispatch(setIsAppUpdate(false))
                dispatch(setUploadEnabled(true));
                if (getState().admin.socketApp.isTriedToUpload) {
                    dispatch(setIsTriedToUpload(false))
                    swal('Uploading files was now enabled start uploading')
                        .then((value) => {
                            window.location.reload();
                        });
                }
            }
        }

        const onNotitificationReceived = (scanNotification) => {
            if (scanNotification) {
                dispatch(getPendingNotifications());
            }
        }

        let Sock = new SockJS(`${APP_CONFIG.api}ws`);
        Sock.onclose = function () {
            console.log('conncetion closed')
        }
        dispatch(setStompClient(over(Sock)))
        getState().admin.socketApp.stompClient.connect({}, onConnected, onError);
        getState().admin.socketApp.stompClient.debug = null;

    }
});


export const sendBeforeUpdateMessage = createAsyncThunk('admin/socketApp/sendBeforeUpdateMessage', async (_, { dispatch, getState }) => {
    getState().admin.socketApp.stompClient.send("/app/beforemessage", {}, '');
});

export const sendAfterUpdateMessage = createAsyncThunk('admin/socketApp/sendAfterUpdateMessage', async (_, { dispatch, getState }) => {
    getState().admin.socketApp.stompClient.send("/app/aftermessage", {}, '');
});

const socketSlice = createSlice({
    name: 'admin/socketApp',
    initialState: {
        isAppUpdate: false,
        uploadEnabled: true,
        isTriedToUpload: false,
        showUploadPopup: false,
        stompClient: null,
        isSocketConnected: false
    },

    reducers: {
        setIsAppUpdate: (state, action) => {
            state.isAppUpdate = action.payload;
        },
        setUploadEnabled: (state, action) => {
            state.uploadEnabled = action.payload;
        },
        setIsTriedToUpload: (state, action) => {
            state.isTriedToUpload = action.payload;
        },
        setShowUploadPopup: (state, action) => {
            state.showUploadPopup = action.payload;
        },
        setStompClient: (state, action) => {
            state.stompClient = action.payload;
        },
        setIsSocketConnected: (state, action) => {
            state.isSocketConnected = action.payload;
        }
    },

});
export const { setIsAppUpdate, setUploadEnabled, setIsTriedToUpload, setShowUploadPopup, setStompClient, setIsSocketConnected } = socketSlice.actions;

export default socketSlice.reducer;


