import styled from "@emotion/styled";

export const OuterPanel = styled.div`
  width: 700px;
  background-color: #F8F8F8;
  height: 100%;
`;
export const Header = styled.div`
  color: #FFFFFF;
  background-color: #9AA1A4;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 14px;
`;
export const Spacer = styled.div`
  flex-grow: 3;
`;
export const CloseButton = styled.div`
  cursor: pointer;
  padding-right: 10px;
`;
export const Row = styled.div`
  display: flex;
  font-size: 12px;
  &:hover {
    background-color: #C0C0C0;
	color: #ffffff;
    cursor: pointer;
  }
`;
export const ColumnHeader = styled.div`
  padding: 10px;
  width: ${props => props.width ? props.width + 'px' : '100' + 'px'};
  background-color: #e0e0e0;
  color: #404040;
  border-color: #7A8184;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;
  margin: 15px 10px;
`;
export const Label = styled.div`
  padding-top: 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  border-color: #7A8184;
  border-width: 0 0 1px 0;
`;
export const LabelItem = styled.div`
  text-align: center;
  font-weight: bold;
`;
export const Data = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;
export const DataRow = styled.div`
	display: flex;
`;
export const DataHeader = styled.div`
  padding: 5px;
  width: 100px;
  border: 1px solid #7A8184;
  background-color: #e0e0e0;
  color: #404040;
  text-align: center;
  font-weight: bold;
`;
export const DataItem = styled.div`
  padding: 5px;
  width: 100px;
  border: 1px solid #7A8184;
  text-align: center;
`;
export const LinkItem = styled.div`
  padding: 5px;
  width: 300px;
  border: 1px solid #7A8184;
  text-align: center;
  cursor: pointer;
`;
export const Error = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #7A8184;
  width: 100px;
`;
export const ErrorItem = styled.div`
  padding-top: 30px;
  text-align: center;
`;
export const Units = styled.div`
  padding: 10px;
  background-color: #e0e0e0;
  border-bottom: 1px solid #7A8184;
  color: #ffffff;
  display: flex;
  width: 100%;
`;
