import { TextField, Grid, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendContent } from "./SupportSlice";
import swal from "sweetalert";

function UserSupport() {
  const dispatch = useDispatch();
  const [contentText, setContentText] = useState("");

  const handleOnChange = (e) => {
    setContentText(e.target.value);
  };

  const sendContentText = () => {
    dispatch(sendContent(contentText));
    swal("", "Thank you for contacting Looq support!", "success");
    setContentText("");
  };

  return (
    <div className="flex items-center justify-center">
      <div
        style={{ width: "70%" }}
        className="items-center justify-center my-16 sm:p-24 md:p-32"
      >
        <Grid container className="items-center justify-center">
          <div className="w-full">
            <Typography
              className="h2 text-30 my-16 font-700 text-center"
              color="textPrimary"
            >
              Support
            </Typography>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <div className="flex w-full">
                    <TextField
                      id="outlined-multiline-static"
                      label="Issue"
                      placeholder="Please describe the issue you are having"
                      multiline
                      rows={12}
                      className="mx-0 w-full mb-16"
                      value={contentText}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleOnChange}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <div className="flex flex-col items-end justify-end w-full pb-4">
                    <Button
                      style={{
                        boxSizing: "border-box",
                        width: "124px",
                        background: "#6CCCD7",
                        border: "1px solid #6CCCD7",
                        borderRadius: "50px",
                        color: "#ffffff",
                        opacity:
                          !contentText || contentText.trim() === ""
                            ? "0.5"
                            : "1",
                      }}
                      disabled={!contentText || contentText.trim() === ""}
                      aria-label="Submit"
                      type="button"
                      onClick={() => sendContentText()}
                    >
                      {"Submit"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default UserSupport;
