import DropZone from "./DropZone";
import swal from "sweetalert";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  Checkbox,
  Paper,
  Divider,
} from "@mui/material";

const generateColor = (index) => {
  const colors = [
    "#d0f0fd",
    "#e6f9d7",
    "#fbdce5",
    "#f5e3d7",
    "#e0d8f7",
    "#f9e3d0",
    "#d0f5d9",
    "#f5d0d0",
    "#d0d9f5",
    "#e3d0f5",
    "#f5f5d0",
  ];
  return colors[index % colors.length];
};

const FileImportModal = ({ open, onClose, onFileData }) => {
  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState("");
  const [columnMappings, setColumnMappings] = useState({
    name: "",
    easting: "",
    northing: "",
    elevation: "",
    description: "",
  });
  const [headers, setHeaders] = useState([]);
  const [csvRows, setCsvRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [highlightedColumns, setHighlightedColumns] = useState(new Map());
  const [previewData, setPreviewData] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [hasHeader, setHasHeader] = useState(true);

  const autoDetectMappings = (headers) => {
    const fieldNames = {
      name: /\b(model[\s-_]?id)\b/i,
      description: /\b(code)\b/i,
      easting: /\beasting\b/i,
      northing: /\bnorthing\b/i,
      elevation: /\belevation\b/i,
    };

    const newMappings = {};

    Object.keys(fieldNames).forEach((field) => {
      const regex = fieldNames[field];
      const matchedHeaders = headers.filter((header) =>
        regex.test(header.name)
      );

      if (matchedHeaders.length > 1) {
        newMappings[field] = matchedHeaders[1];
      } else if (matchedHeaders.length === 1) {
        newMappings[field] = matchedHeaders[0];
      }
    });

    return newMappings;
  };

  const parseCSVHeaders = (csvData, hasHeader) => {
    const lines = csvData.split("\n");

    if (lines.length === 0) return;

    let headerLine = lines[0].split(",");
    let dataStartIndex = 1;

    const isNumericHeader = headerLine.every((value) =>
      value?.trim().match(/^[-+]?[0-9]*\.?[0-9]+$/)
    );
    if (typeof hasHeader === "undefined") {
      hasHeader = !isNumericHeader;
    }
    let uniqueHeaders;
    if (!hasHeader) {
      uniqueHeaders = headerLine.map((_, index) => ({
        name: `Column ${index + 1}`,
        index,
        uniqueId: `Column_${index + 1}`,
      }));
      dataStartIndex = 0;
    } else {
      const headerCount = {};
      uniqueHeaders = headerLine.map((header, index) => {
        headerCount[header] = (headerCount[header] || 0) + 1;
        return {
          name:
            headerCount[header] > 1
              ? `${header} (${headerCount[header] - 1})`
              : header,
          index,
          uniqueId: `${header}_${headerCount[header]}`,
        };
      });
    }

    const parsedRows = lines
      .slice(dataStartIndex)
      .map((line) => line.split(","))
      .filter((row) => row.some((cell) => cell.trim() !== ""));

    setHeaders(uniqueHeaders);
    setCsvRows(parsedRows);

    setCheckedRows(parsedRows.map(() => true));

    // Auto-detect mappings after headers are parsed
    const autoMappings = autoDetectMappings(uniqueHeaders);
    setColumnMappings(autoMappings);

    // Automatically highlight matched columns
    const newHighlightedColumns = new Map();
    Object.values(autoMappings).forEach((header) => {
      if (header) {
        newHighlightedColumns.set(header.uniqueId, generateColor(header.index));
      }
    });
    setHighlightedColumns(newHighlightedColumns);
  };

  useEffect(() => {
    if (csvData) {
      parseCSVHeaders(csvData, hasHeader);
    }
  }, [hasHeader, csvData]);

  const handleFileChange = (importedFile) => {
    const file = importedFile[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;
        parseCSVHeaders(csvData, hasHeader);
        setCsvData(csvData);
      };
      reader.readAsText(file);
    }
  };

  const handleColumnChange = (e) => {
    console.log("inhere");

    const columnName = e.target.name;
    const selectedHeader = e.target.value;

    const currentMapping = columnMappings[columnName];

    const newHighlightedColumns = new Map(highlightedColumns);

    const isColumnAlreadyChosen = Object.values(columnMappings).some(
      (mapping) => mapping && mapping.uniqueId === selectedHeader.uniqueId
    );

    if (selectedHeader === currentMapping) {
      newHighlightedColumns.delete(selectedHeader.uniqueId);
      setColumnMappings({
        ...columnMappings,
        [columnName]: "",
      });
    } else if (isColumnAlreadyChosen) {
      return;
    } else {
      if (currentMapping) {
        newHighlightedColumns.delete(currentMapping.uniqueId);
      }

      newHighlightedColumns.set(
        selectedHeader.uniqueId,
        generateColor(headers.indexOf(selectedHeader))
      );

      setColumnMappings({
        ...columnMappings,
        [columnName]: selectedHeader,
      });
    }

    setHighlightedColumns(newHighlightedColumns);
  };

  const handleRowCheck = (index) => {
    const updatedCheckedRows = [...checkedRows];
    updatedCheckedRows[index] = !updatedCheckedRows[index];
    setCheckedRows(updatedCheckedRows);
  };

  const handleImport = () => {
    const selectedRows = csvRows.filter((_, index) => checkedRows[index]);

    const finalData = selectedRows.map((row) => {
      let rowData = {};
      Object.keys(columnMappings).forEach((key) => {
        const columnId = columnMappings[key]?.uniqueId;
        const columnIndex = headers.find((h) => h.uniqueId === columnId)?.index;
        if (columnIndex !== undefined) {
          rowData[key] = row[columnIndex] || null;
        }
      });
      return rowData;
    });

    setPreviewData({
      columnMappings,
      selectedRows: finalData,
    });
    if (Object.keys(finalData[0]).length === 0) {
      swal("No data selected", "Please select some data to import", "warning");
    } else {
      setConfirmationOpen(true);
    }
  };

  const handleConfirm = () => {
    if (previewData) {
      onFileData(previewData);
    }
    setConfirmationOpen(false);
  };

  const handleCancelPreview = () => {
    setConfirmationOpen(false);
  };

  const handleCancel = () => {
    setFileName("");
    setCsvData("");
    setColumnMappings({
      name: "",
      easting: "",
      northing: "",
      elevation: "",
      description: "",
    });
    setHeaders([]);
    setCsvRows([]);
    setCheckedRows([]);
    setHighlightedColumns(new Map());
    setPreviewData(null);

    setConfirmationOpen(false);
    onClose();
  };

  const handleReset = () => {
    setColumnMappings({
      name: "",
      easting: "",
      northing: "",
      elevation: "",
      description: "",
    });
    setHighlightedColumns(new Map());
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={fileName ? "lg" : "sm"}
        TransitionComponent={Fade}
        transitionDuration={300}
        sx={{
          "& .MuiDialog-paper": {
            background: "linear-gradient(to bottom right, #D9E2EC, white)",
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
          },
        }}
        fullWidth
      >
        <DialogTitle style={{ fontWeight: 600 }}>
          Import Control Points
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
            Import your csv file below to begin
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Checkbox
              checked={hasHeader}
              onChange={() => setHasHeader(!hasHeader)}
              sx={{
                color: "#59bec9",
                "&.Mui-checked": {
                  color: "#59bec9",
                },
              }}
            />
            <Typography style={{ fontWeight: 500 }}>Include headers</Typography>
          </Box>
          <Box sx={{ mb: 2, padding: 2 }}>
            <Box alignItems="center">
              <Typography
                style={{ fontWeight: 400 }}
                sx={{
                  mb: 3,
                  backgroundColor: "#FBFCF8",
                  borderRadius: "8px",
                  border: fileName ? "1px solid #59bec9" : "none",
                  p: 2,
                }}
              >
                {fileName || "No file imported"}
              </Typography>
              <DropZone
                handleFileChange={handleFileChange}
                fileName={fileName}
              />
            </Box>

            {headers.length > 0 && (
              <>
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ mb: 2 }} variant="h6">
                    Map Columns To Coordinate Fields
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={2} sx={{ mb: 3 }}>
                    {[
                      "name",
                      "easting",
                      "northing",
                      "elevation",
                      "description",
                    ].map((key) => (
                      <TextField
                        key={key}
                        label={key.toUpperCase()}
                        name={key}
                        value={columnMappings[key] || ""}
                        onChange={handleColumnChange}
                        fullWidth
                        select
                        size="small"
                        sx={{
                          flexBasis: "18%",
                          backgroundColor: columnMappings[key]
                            ? generateColor(
                                headers.find(
                                  (h) =>
                                    h.uniqueId === columnMappings[key]?.uniqueId
                                )?.index
                              )
                            : "#FBFCF8",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        {headers.map((header) => (
                          <MenuItem
                            key={header.uniqueId}
                            value={header}
                            style={{
                              backgroundColor:
                                columnMappings[key]?.uniqueId ===
                                header.uniqueId
                                  ? generateColor(header.index)
                                  : "transparent",
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            {header.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ))}
                  </Box>
                </Box>

                <Paper sx={{ mt: 3, mb: 2, backgroundColor: "#FBFCF8" }}>
                  <Typography variant="h6" sx={{ p: 2 }}>
                    Control Point File Preview
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Included</TableCell>
                        {headers.map((header) => (
                          <TableCell
                            key={header.uniqueId}
                            style={{
                              backgroundColor:
                                highlightedColumns.get(header.uniqueId) ||
                                "transparent",
                            }}
                          >
                            {header.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvRows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell>
                            <Checkbox
                              sx={{
                                color: "#59bec9",
                                "&.Mui-checked": {
                                  color: "#59bec9",
                                },
                              }}
                              checked={checkedRows[rowIndex] || false}
                              onChange={() => handleRowCheck(rowIndex)}
                            />
                          </TableCell>
                          {row.map((cell, colIndex) => {
                            const columnHeader = headers[colIndex];
                            const columnId = columnHeader.uniqueId;
                            return (
                              <TableCell
                                key={colIndex}
                                style={{
                                  backgroundColor:
                                    checkedRows[rowIndex] &&
                                    highlightedColumns.get(columnId)
                                      ? highlightedColumns.get(columnId)
                                      : "transparent",
                                  transition: "background-color 0.3s ease",
                                }}
                              >
                                {cell}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mr: 2 }}>
          {fileName && (
            <>
              <Button onClick={handleReset} color="secondary">
                Reset Columns
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: "#59bec9", color: "white", ml: 2 }}
                onClick={handleImport}
              >
                Preview
              </Button>
            </>
          )}
          {!fileName && (
            <>
              <Button onClick={onClose}>Cancel</Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmationOpen}
        onClose={handleCancelPreview}
        TransitionComponent={Fade}
        n
        transitionDuration={300}
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            background: "linear-gradient(to bottom right, #D9E2EC, white)",
          },
        }}
        fullWidth
      >
        <DialogTitle sx={{ fontSize: "22px" }}>Confirm Import</DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ mb: 2, padding: 2 }}>
            <Typography variant="h6">Preview of Imported Data</Typography>
            <Paper sx={{ mt: 3, mb: 2 }}>
              <Table>
                <TableHead sx={{ backgroundColor: "rgb(89, 190, 201, 0.2)" }}>
                  <TableRow>
                    {Object.keys(columnMappings).map((key) => (
                      <TableCell key={key}>
                        {columnMappings[key]?.name || key}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData?.selectedRows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.keys(columnMappings).map((key) => (
                        <TableCell key={key}>{row[key] || ""}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: "14px 32px" }}>
          <Button onClick={handleCancelPreview}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ backgroundColor: "#59bec9", color: "white", ml: 2 }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileImportModal;
