import { authRoles } from 'app/auth';
import Register from './Register';
import DemoRegister from './DemoRegister';
import SignUp from './SignUp';
const RegisterConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'register/',
      element: <SignUp  />,
    },
    {
      path: 'demoregister',
      element: <DemoRegister />,
    }
    
  ],
};

export default RegisterConfig;
