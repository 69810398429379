import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
import customer from './customerSlice';
import account from './accountSlice';
import logo from './logoSlice';
import verification from './verificationSlice';
import multifactor from './multifactorSlice';
import customNavigation from './customNavigationSlice';

const authReducers = combineReducers({
  user,
  login,
  register,
  customer,
  account,
  logo,
  verification,
  multifactor,
  customNavigation
});

export default authReducers;
