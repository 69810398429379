/* eslint-disable no-unused-vars */
import axios from "axios";
import SERVICE_STATUS from "../../../../services/ServiceStatus";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import APP_CONFIG from "../../../../AppConfig";

export const loadProjects = createAsyncThunk(
  "admin/projectApp/loadProjects",
  async (organizationId) => {
    const url = APP_CONFIG.api + `v1/project/getAllProjects/${organizationId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const getOrganizationProjects = createAsyncThunk(
  "admin/projectApp/getOrganizationProjects",
  async (orgId, { dispatch }) => {
    const url =
      APP_CONFIG.api + `v1/project/getOrgnizationProjects/${orgId || 0}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    dispatch(setOrganizationProject(data));
  }
);

export const createProject = createAsyncThunk(
  "admin/projectApp/createProject",
  async (projectData) => {
    const url = APP_CONFIG.api + `v1/project/createProject`;
    const response = await axios.post(url, projectData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    var data = response.data;
    return data;
  }
);

export const updateProject = createAsyncThunk(
  "admin/projectApp/updateProject",
  async (projectData) => {
    const url =
      APP_CONFIG.api + `v1/project/updateProject/${projectData.data.id}`;
    const response = await axios.put(url, projectData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((res) => {
        projectData.successCallBack();
      })
      .catch((error) => {
        projectData.onFailureCallBack();
      });
  }
);

export const deleteProject = createAsyncThunk(
  "admin/projectApp/deleteProject",
  async (projectData) => {
    const url = APP_CONFIG.api + `v1/project/deleteProject/${projectData.id}`;
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((res) => {
        projectData.updateDeleteSuccess();
      })
      .catch((error) => {});
  }
);

export const updateProjectExcludBillling = createAsyncThunk(
  "admin/projectApp/updateProjectExcludBillling",
  async (projectData, { dispatch }) => {
    const url =
      APP_CONFIG.api +
      `v1/project/updateProjectExcludeBilling/${projectData.data.id}`;
    const response = await axios.put(url, projectData.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    Promise.resolve(response)
      .then((res) => {
        projectData.successCallBack();
        dispatch(setProjectFilePath(res.data.project));
      })
      .catch((error) => {});
  }
);

export const getProjectFilesPath = createAsyncThunk(
  "admin/projectApp/getProjectFilesPath",
  async (projectId) => {
    const url = APP_CONFIG.api + `v1/project/getProjectFilePath/${projectId}`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await response.data;
    return data;
  }
);

export const createNoProject = createAsyncThunk(
  "admin/projectApp/createNoProject",
  async (organizationId) => {
    const url = APP_CONFIG.api + `v1/project/createProject/${organizationId}`;
    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    var data = response.data;
    return data;
  }
);

const projectSlice = createSlice({
  name: "admin/projectApp",
  initialState: {
    projects: [],
    createProjectState: "",
    updateProjectState: "",
    loadingState: "",
    organizationProject: [],
    projectFilePath: {},
    noProject: {},
  },

  reducers: {
    setOrganizationProject: (state, action) => {
      state.organizationProject = action.payload;
    },
    setProjectFilePath: (state, action) => {
      state.projectFilePath = action.payload;
    },
    setUpdateCreateProjectState: (state, action) => {
      state.createProjectState = action.payload;
    },
    setUpdateUpdateProjectState: (state, action) => {
      state.updateProjectState = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
  },
  extraReducers: {
    [loadProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
    },
    [loadProjects.pending]: (state, action) => {
      state.loadingState = action.payload;
    },
    [loadProjects.rejected]: (state, action) => {
      state.loadingState = action.payload;
    },
    [createProject.fulfilled]: (state, action) => {
      state.createProjectState = SERVICE_STATUS.fulfilled;
      state.projectFilePath = action.payload;
    },
    [createProject.rejected]: (state, action) => {
      state.createProjectState = SERVICE_STATUS.rejected;
    },
    [updateProject.fulfilled]: (state, action) => {
      state.updateProjectState = SERVICE_STATUS.fulfilled;
    },
    [getProjectFilesPath.fulfilled]: (state, action) => {
      state.projectFilePath = action.payload;
    },
    [createNoProject.fulfilled]: (state, action) => {
      state.noProject = action.payload;
    },
  },
});

export const {
  setOrganizationProject,
  setProjectFilePath,
  setUpdateCreateProjectState,
  setUpdateUpdateProjectState,
  setProjects,
} = projectSlice.actions;

export default projectSlice.reducer;
