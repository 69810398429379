import { useState, useRef, useEffect } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Paper,
    TableRow,
    TablePagination,
    TableContainer,
    Checkbox
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';


function TableView(props) {

    const tableRef = useRef();
    const { files, isMoveFilesEnabled, isCopyFilesEnabled, moveFilesList, copyFilesList, fileIds } = props;
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (tableRef.current != undefined) {
            let topHeight = tableRef.current.getBoundingClientRect().top + 80;
            tableRef.current.style.maxHeight = `calc(100vh - ${topHeight}px)`;
            tableRef.current.style.height = `calc(100vh - ${topHeight}px)`;
        }
    }, [])


    return (
        <div>
            <TableContainer component={Paper} ref={tableRef}>
                <Table stickyHeader aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={20}>
                                {(!isMoveFilesEnabled && !isCopyFilesEnabled) &&
                                    files.length != 0 &&
                                    <Checkbox
                                        onChange={(e) => props.checkAll(e)}
                                        checked={files.length != 0 && (fileIds.length == files.length - props.getCount())}
                                    />
                                }
                            </TableCell>
                            {/* } */}
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Size</b></TableCell>
                            <TableCell><b>Last Modified</b></TableCell>
                        </TableRow>
                    </TableHead>

                    {files.length == 0 ?
                        <TableBody>
                            <TableRow key={0}><TableCell width={100}>No Files</TableCell></TableRow>
                        </TableBody>
                        : files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((a) => (
                            <TableBody key={a.id}>
                                {
                                    a.type == "folder" ?
                                        <TableRow>
                                            <TableCell width={20}>
                                                {(isCopyFilesEnabled && !fileIds.includes(a.id)) || (isMoveFilesEnabled && !fileIds.includes(a.id)) ? null :
                                                    <Checkbox
                                                        value={a.id}
                                                        onChange={(e) => props.checkedFiles(e, a)}
                                                        checked={fileIds.filter(x => x.id == a.id).length > 0}
                                                        disabled={moveFilesList.filter(x => x.fileKey == a.fileKey).length != 0 || copyFilesList.filter(x => x.fileKey == a.fileKey).length != 0}
                                                    />}
                                            </TableCell>
                                            <TableCell >
                                                <FolderIcon color="secondary"></FolderIcon>
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => props.handleOnFileClickGetData(a, 0)}
                                                    style={{ justifyContent: "flex-start" }}
                                                    disabled={moveFilesList.filter(x => x.fileKey == a.fileKey).length != 0 || copyFilesList.filter(x => x.fileKey == a.fileKey).length != 0}
                                                >
                                                    {a.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell>Folder</TableCell>
                                            <TableCell>--</TableCell>
                                            <TableCell>--</TableCell>
                                        </TableRow>
                                        :
                                        <TableRow key={a.id} >
                                            <TableCell width={20}>
                                                {(isCopyFilesEnabled && !fileIds.includes(a.id)) || (isMoveFilesEnabled && !fileIds.includes(a.id)) ? null :
                                                    <Checkbox
                                                        value={a.id}
                                                        onChange={(e) => props.checkedFiles(e, a)}
                                                        checked={fileIds.filter(x => x.id == a.id).length > 0}
                                                        disabled={moveFilesList.filter(x => x.fileKey == a.fileKey).length != 0 || copyFilesList.filter(x => x.fileKey == a.fileKey).length != 0}
                                                    />}
                                            </TableCell>
                                            <TableCell>{a.name}</TableCell>
                                            <TableCell>{a.extension}</TableCell>
                                            <TableCell>{props.getFileExactSize(a.contentLength)}</TableCell>
                                            <TableCell>{a.lastModified}</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        ))}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={files.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default TableView;