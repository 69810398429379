import { memo } from 'react';
import NotificationsApp from 'app/main/apps/notification/NotificationsApp';

function RightSideLayout3() {
  return (
    <>
      <NotificationsApp />
    </>
  );
}

export default memo(RightSideLayout3);
