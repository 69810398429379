import KeyBoardConstants from "../constants/KeyBoardConstants";

class HandleKeyBoardSelect {

    static HandleUpKeyPress(type, files, selectedFiles, firstSelectedKey, identityProperty, rowListLength, firstFieldValue) {
        var firstFieldValue = selectedFiles[selectedFiles.length - 1];
        var setFirstSelectedKey = false;
        if (type === KeyBoardConstants.up && Math.ceil(selectedFiles[0][identityProperty] == rowListLength ? selectedFiles[0][identityProperty] + 1 : selectedFiles[0][identityProperty] / rowListLength) > 1) {
            if (selectedFiles.length == 1) {
                for (var i = 0; i < rowListLength; i++) {
                    if (selectedFiles[0][identityProperty] > 0) {
                        var id = selectedFiles[0][identityProperty] - 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)

                        }
                    }
                }
            }

            else {
                if (firstSelectedKey == KeyBoardConstants.down) {
                    if (firstFieldValue) {
                        for (var i = 0; i < rowListLength; i++) {
                            if (firstFieldValue[identityProperty] < selectedFiles[0][identityProperty]) {
                                selectedFiles.splice(0, 1);

                            }
                            else if (firstFieldValue[identityProperty] >= selectedFiles[0][identityProperty]) {
                                var Id = selectedFiles[0][identityProperty] - 1;
                                var file = files.find(x => x[identityProperty] == Id);
                                if (file) {
                                    selectedFiles.unshift(file);

                                }
                            }
                        }
                    }
                    setFirstSelectedKey = true;

                }
                else {
                    for (var i = 0; i < rowListLength; i++) {
                        if (firstFieldValue[identityProperty] < selectedFiles[0][identityProperty]) {
                            selectedFiles.splice(0, 1);

                        }
                        else if (firstFieldValue[identityProperty] >= selectedFiles[0][identityProperty]) {
                            var id = selectedFiles[0][identityProperty] - 1;
                            var file = files.find(x => x[identityProperty] == id);
                            if (file) {
                                selectedFiles.unshift(file)

                            }
                        }
                    }
                }
            }
        }
        return {
            selectedFiles: selectedFiles,
            setFirstSelectedKey: setFirstSelectedKey
        }
    }
    static HandleDownKeyPress(type, files, selectedFiles, firstSelectedKey, identityProperty, rowListLength, noOfRows, ctrlKey) {
        var firstFieldValue = selectedFiles[selectedFiles.length - 1];
        var setFirstSelectedKey = false;
        if (type === KeyBoardConstants.down && Math.ceil((selectedFiles[0][identityProperty] + 1) / rowListLength) != noOfRows) {
            if (selectedFiles.length == 1) {
                for (var i = 0; i < rowListLength; i++) {
                    if (selectedFiles[0][identityProperty] >= 0) {
                        var id = selectedFiles[0][identityProperty] + 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)
                            //updateFileIds(selectedFiles);
                        }
                    }
                }
            }
            else {
                if (firstSelectedKey == KeyBoardConstants.up) {
                    if (firstFieldValue) {
                        for (var i = 0; i < rowListLength; i++) {
                            if (firstFieldValue[identityProperty] > selectedFiles[0][identityProperty]) {
                                selectedFiles.splice(0, 1);
                            }
                            else if (firstFieldValue[identityProperty] <= selectedFiles[0][identityProperty]) {
                                var Id = selectedFiles[0][identityProperty] + 1;
                                var file = files.find(x => x[identityProperty] == Id);
                                if (file) {
                                    selectedFiles.unshift(file);
                                }
                            }
                        }
                        if (firstFieldValue < selectedFiles[selectedFiles.length - 1][identityProperty]) {
                            selectedFiles.splice(0, 1);
                        }
                        setFirstSelectedKey = true;
                    }
                }
                else {

                    for (var i = 0; i < rowListLength; i++) {
                        if (firstFieldValue[identityProperty] > selectedFiles[0][identityProperty]) {
                            selectedFiles.splice(0, 1);
                        }
                        else if (selectedFiles[selectedFiles.length - 1][identityProperty] >= 0) {
                            var id = selectedFiles[0][identityProperty] + 1;
                            var file = files.find(x => x[identityProperty] == id);
                            if (file) {
                                selectedFiles.unshift(file)
                            }
                        }
                    }
                }
            }
        }
        return {
            selectedFiles: selectedFiles,
            setFirstSelectedKey: setFirstSelectedKey
        }
    }
    static HandleLeftKeyPress(type, files, selectedFiles, firstSelectedKey, identityProperty) {
        var firstFieldValue = selectedFiles[selectedFiles.length - 1];
        if (type === KeyBoardConstants.left) {
            if (selectedFiles.length == 1) {
                if (selectedFiles[0][identityProperty] >= 1) {
                    var id = selectedFiles[0][identityProperty] - 1;
                    var file = files.find(x => x[identityProperty] == id);
                    if (file) {
                        selectedFiles.unshift(file)
                    }
                }
            }
            else {
                if (type == KeyBoardConstants.up && firstFieldValue[identityProperty] < selectedFiles[0][identityProperty]) {
                    selectedFiles.splice(0, 1);
                }
                else if (firstSelectedKey == KeyBoardConstants.down && firstFieldValue[identityProperty] > selectedFiles[0][identityProperty]) {
                    if (selectedFiles[0][identityProperty] > 0) {
                        var id = selectedFiles[0][identityProperty] - 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)
                        }
                    }
                }
                else if (firstSelectedKey == KeyBoardConstants.up && firstFieldValue[identityProperty] < selectedFiles[0][identityProperty]) {
                    selectedFiles.splice(0, 1);
                }
                else if (firstSelectedKey == KeyBoardConstants.right || firstSelectedKey == KeyBoardConstants.down) {
                    selectedFiles.splice(0, 1);
                }
                else {
                    if (selectedFiles[0][identityProperty] > 0) {
                        var id = selectedFiles[0][identityProperty] - 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)
                        }
                    }
                }
            }
        }
        return {
            selectedFiles: selectedFiles,
        }
    }
    static HandleRightKeyPress(type, files, selectedFiles, firstSelectedKey, identityProperty) {
        var firstFieldValue = selectedFiles[selectedFiles.length - 1];
        if (type === KeyBoardConstants.right) {

            if (selectedFiles.length == 1) {
                if (selectedFiles[0][identityProperty] >= 0) {
                    var id = selectedFiles[0][identityProperty] + 1;
                    var file = files.find(x => x[identityProperty] == id);
                    if (file) {
                        selectedFiles.unshift(file)
                    }
                }
            }
            else {
                if (firstSelectedKey == KeyBoardConstants.down && firstFieldValue[identityProperty] > selectedFiles[0][identityProperty]) {
                    selectedFiles.splice(0, 1);
                }
                else if (firstSelectedKey == KeyBoardConstants.down && firstFieldValue[identityProperty] < selectedFiles[selectedFiles.length - 1][identityProperty]) {
                    if (selectedFiles[0][identityProperty] > 0) {
                        var id = selectedFiles[0][identityProperty] + 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)
                        }
                    }
                }
                else if (firstSelectedKey == KeyBoardConstants.left || firstSelectedKey == KeyBoardConstants.up && firstFieldValue[identityProperty] > selectedFiles[0][identityProperty]) {
                    selectedFiles.splice(0, 1);
                }
                else {
                    if (selectedFiles[0][identityProperty] > 0) {
                        var id = selectedFiles[0][identityProperty] + 1;
                        var file = files.find(x => x[identityProperty] == id);
                        if (file) {
                            selectedFiles.unshift(file)
                        }
                    }
                }
            }
        }
        return {
            selectedFiles: selectedFiles,
        }
    }


}

export default HandleKeyBoardSelect;