import { useAuth0 } from "@auth0/auth0-react";
import { setUserDataAuth0 } from "./store/userSlice";
import { loginSuccess } from "./store/loginSlice";
import { registerSuccess } from "./store/registerSlice";
import { getLoginUserDetails, setCustomer } from "./store/customerSlice";
import { sendOtp } from "./store/verificationSlice";
import { setLogoUrl } from "./store/logoSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import appRoles from "./appRoles";
import APP_CONFIG from "app/AppConfig";
import { sendAccessRestrictMail } from "../main/apps/admin/store/customerSlice";
import { connectSocket } from "app/main/apps/admin/store/socketSlice";
import {
  setUploadEnabled,
  setIsAppUpdate,
} from "app/main/apps/admin/store/socketSlice";
import { logoutUser } from "./store/userSlice";
import { getPendingNotifications } from "app/main/apps/admin/store/notificationSlice";
import MixPanelIntegration from "app/mixpanel/MixPanelIntegration";

// eslint-disable-next-line complexity
const AuthFilter = (props) => {
  const dispatch = useDispatch();
  if (sessionStorage.getItem("demousertoken")) {
    var newUser = {};
    newUser.roles = ["demouser"];
    //token for axios----
    axios.interceptors.request.use(
      async (config) => {
        if (config.url.includes(APP_CONFIG.api)) {
          var headers = config.headers;
          headers = {
            ...headers,
            limartoken: sessionStorage.getItem("demousertoken"),
          };

          config.headers = headers;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //token for fetch----
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      let [resource, config] = args;
      if (args != undefined) {
        if (typeof resource == "string" && resource.includes(APP_CONFIG.api)) {
          if (config == undefined) {
            config = {};
            config.headers = {};
          }
          var headers = config.headers;
          headers = {
            ...headers,
            limartoken: sessionStorage.getItem("demousertoken"),
          };

          config.headers = headers;
        }
      }
      return originalFetch(resource, config);
    };
    dispatch(setUserDataAuth0(newUser));
  } else {
    const {
      isLoading,
      error,
      isAuthenticated,
      loginWithRedirect,
      user,
      getIdTokenClaims,
      getAccessTokenSilently,
      logout,
    } = useAuth0();

    // if we have an error display
    if (error) {
      return <div>Oops... {error.message}</div>;
    }
    // while we are loading display loading screen
    if (isLoading) {
      return <p>Is Loading </p>;
    }
    // if not authenticated call login

    if (!isAuthenticated) {
      if (!window.location.pathname.toLowerCase().includes("register")) {
        Promise.resolve(loginWithRedirect()).then(() =>
          console.log("loginWithRedirect() Done")
        );
        return <>{this.props.children}</>;
      }
    }

    if (localStorage.getItem("isLogout") == undefined) {
      localStorage.setItem("isLogout", false);
    }

    if (isAuthenticated && localStorage.getItem("isLogout") == "true") {
      logout();
      dispatch(logoutUser());
      localStorage.setItem("isLogout", false);
    }

    // if Authenticated then display the rest of the web application
    if (isAuthenticated && localStorage.getItem("isLogout") == "false") {
      const IdToken = getIdTokenClaims();
      Promise.resolve(IdToken).then((token) => {
        localStorage.setItem("id_token", token.__raw);
      });
      let userRoles = [];

      const AccessToken = getAccessTokenSilently();

      Promise.resolve(AccessToken).then((token) => {
        //token for axios----
        axios.interceptors.request.use(
          async (config) => {
            if (config.url.includes(APP_CONFIG.api)) {
              const accesstoken = await getAccessTokenSilently({
                audience: APP_CONFIG.audience,
                scope: APP_CONFIG.scope,
              });
              localStorage.setItem("access_token", accesstoken);
              var headers = config.headers;
              headers = {
                ...headers,
                authorization: `Bearer ${accesstoken}`,
                limartoken: localStorage.getItem("limartoken"),
              };
              // config.headers.authorization = `Bearer ${accesstoken}`;
              // config.headers.limartoken =  localStorage.getItem("limartoken");
              config.headers = headers;
            }
            return config;
          },
          (error) => {
            Promise.reject(error);
          }
        );
        //token for fetch----
        const { fetch: originalFetch } = window;
        window.fetch = async (...args) => {
          let [resource, config] = args;
          if (args != undefined) {
            if (
              typeof resource == "string" &&
              resource.includes(APP_CONFIG.api)
            ) {
              const accesstoken = await getAccessTokenSilently({
                audience: APP_CONFIG.audience,
                scope: APP_CONFIG.scope,
              });
              localStorage.setItem("access_token", accesstoken);
              if (config == undefined) {
                config = {};
                config.headers = {};
              }
              var headers = config.headers;
              headers = {
                ...headers,
                Authorization: `Bearer ${accesstoken}`,
                limartoken: localStorage.getItem("limartoken"),
              };
              // config.headers.Authorization = `Bearer ${accesstoken}`;
              // config.headers.limartoken =  localStorage.getItem("limartoken");
              config.headers = headers;
            }
            // else if (typeof resource == 'object' && resource instanceof Request && resource.url.includes(APP_CONFIG.api)) {
            //     const accesstoken = await getAccessTokenSilently(
            //         {
            //             audience: APP_CONFIG.audience,
            //             scope: APP_CONFIG.scope
            //         }
            //     );
            //     localStorage.setItem('access_token', accesstoken);
            //     resource.headers.append('Authorization', `Bearer ${accesstoken}`);
            //     resource.headers.append('limartoken', localStorage.getItem("limartoken"));
            // }
          }
          return originalFetch(resource, config);
        };
        let updatedUser = user;

        MixPanelIntegration.loginUserDetails(user);

        localStorage.setItem("access_token", token);

        const getApiInfo = getLoginUserDetails(user);

        Promise.resolve(getApiInfo)
        // eslint-disable-next-line complexity
          .then((response) => {
            if (response.status === 200 && response.data != "") {
              // Store Org ID in sessionStorage right after login so we don't have to use redux
              if (response.data?.customer?.accounts) {
                sessionStorage.setItem(
                  "orgId",
                  response.data.customer.accounts[0].id
                );
              } else {
                sessionStorage.removeItem("orgId");
              }

              if (response.data.customer.isDeleted) {
                if (
                  sessionStorage.getItem("isRestrictMailSending") == undefined
                ) {
                  sessionStorage.setItem("isRestrictMailSending", false);
                }
                if (
                  sessionStorage.getItem("isRestrictMailSending") == "false"
                ) {
                  sessionStorage.setItem("isRestrictMailSending", true);
                  alert("Access is restricted ");
                  dispatch(sendAccessRestrictMail(user));
                  setTimeout(() => {
                    sessionStorage.setItem("isRestrictMailSending", false);
                    logout();
                    dispatch(logoutUser());
                  }, 200);
                }
              } else {
                sessionStorage.removeItem("isRestrictMailSending");
                //dispatch(connectSocket());

                const appRoleId = response.data.customer.applicationroleid;
                const accountAdmin = response.data.accountAdmin.length;
                const accountManager = response.data.accountManager.length;
                localStorage.setItem("app_roleId", appRoleId);

                // Application Admin

                if (appRoles.adminId == appRoleId) userRoles = ["admin"];
                else if (appRoles.staff == appRoleId) userRoles = ["staff"];
                else userRoles = ["user"];

                // Acccount Admin
                response.data.customer.userId = response.data.customer.id;
                if (userRoles.includes("admin")) {
                  response.data.customer.userId = 0;
                }
                if (!userRoles.includes("admin") && accountAdmin > 0) {
                  userRoles = ["organisationadmin"];
                }
                if (
                  accountManager > 0 &&
                  accountAdmin == 0 &&
                  !userRoles.includes("admin")
                ) {
                  userRoles = ["projectmanager"];
                }

                updatedUser.roles = userRoles;

                if (response.data.customer.isVerified) {
                  localStorage.setItem("limartoken", response.data.limartoken);
                  // if (userRoles.includes("admin") || userRoles.includes("organisationadmin") || (response.data.customer.accountingPermission && response.data.customer.accountCustomers.length > 0)) {
                  //     var updateNavigaterConfig = [...navigationConfig];
                  //     var organizationNaviagation = {
                  //         id: 'organization_naviagation',
                  //         title: '',
                  //         type: 'item',
                  //         icon: 'peopleicon',
                  //         url: 'apps/admin/organizationsettings',
                  //         label: 'orgnization',
                  //     }
                  //     updateNavigaterConfig.splice(1, 0, organizationNaviagation);
                  //     dispatch(setNavigation(updateNavigaterConfig));
                  // }
                } else {
                  if (
                    window.location.pathname.toLowerCase() !=
                      "/apps/dashboards/sites" &&
                    window.location.pathname.toLowerCase() !=
                      "/apps/user/verification"
                  ) {
                    window.location.href = window.location.origin;
                  } else {
                    dispatch(sendOtp());
                  }
                }

                dispatch(setUserDataAuth0(updatedUser));
                dispatch(setCustomer(response.data.customer));
                dispatch(setLogoUrl({ url: response.data.accountImageUrl }));
                dispatch(
                  setUploadEnabled(
                    response.data.uploadEnabled == "true" ? true : false
                  )
                );
                dispatch(
                  setIsAppUpdate(
                    response.data.uploadEnabled == "true" ? false : true
                  )
                );
                dispatch(loginSuccess(true));
                dispatch(registerSuccess(true));
                dispatch(connectSocket());
                dispatch(getPendingNotifications());
              }
            } else {
              logout();
              dispatch(logoutUser());
            }
          })
          .catch((error) => {
            console.warn("Cannot retrieve customer data", error);
          });
      });
    }
  }
  return <>{props.children}</>;
};

export { AuthFilter };
